<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAisReports';
    read: 'bbraunBavReportingFeatureAisReports.showReportInfoComponent'
  "
>
  <ng-container *ngIf="reportInfo$ | async as reportInfo">
    <div class="bav-reporting-page-header full-width">
      {{ reportInfo?.center }} / {{ reportInfo?.date.year }} -
      {{ reportInfo.date.month | bbraunUiLanguageLocalizedNumber: '2.0' }}
    </div>
  </ng-container>
</ng-container>
