import { Inject, Injectable, Injector } from '@angular/core';
import {
  LocaleService,
  SUPPORTED_LOCALES,
  TranslationLoaderFunction,
} from '@bbraun/shared/data-access-locale';
import { MessageService } from '@bbraun/shared/util-message-ng';
import {
  Translation,
  TranslocoLoader,
  TranslocoService,
} from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { fromPairs, omitBy } from 'lodash';
import { Observable, defer, of } from 'rxjs';

import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import loaderServiceFallbackTranslation from '../../i18n/en.json';
import { I18N_CONFIGURATION } from '../injection-tokens';

const missingTranslationPrefix = '!MISSING TRANSLATION!';

const bbraunSharedUtilTranslocoNgScope = 'bbraunSharedUtilTranslocoNg';
const missingTranslationMessageId = Symbol(
  'bbraunSharedUtilTranslocoNg.module.missingTranslation',
);

@Injectable({ providedIn: 'root' })
export class TranslocoLoaderService implements TranslocoLoader {
  private readonly isFallbackEnabled: boolean;
  private readonly translationLoaders = new Map<
    string,
    Observable<Translation> | Promise<Translation>
  >();

  constructor(
    @Inject(SUPPORTED_LOCALES)
    private readonly supportedLocales: string[],
    @Inject(I18N_CONFIGURATION)
    {
      isFallbackEnabled,
    }: {
      isFallbackEnabled: boolean;
    },
    private readonly localeService: LocaleService,
    private readonly messageService: MessageService,
    private readonly injector: Injector,
  ) {
    this.isFallbackEnabled = isFallbackEnabled;
    this.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../../i18n/${lang}.json`).then((v) => v.default)),
      loaderServiceFallbackTranslation,
      bbraunSharedUtilTranslocoNgScope,
    );
  }

  getTranslation(key: string) {
    const translationLoader = this.translationLoaders.get(key);
    if (translationLoader) {
      return translationLoader;
    } else {
      // this loader is a dependency of the transloco service itself, so we need to retrieve the transloco service from the injector
      const translocoService =
        this.injector.get<TranslocoService>(TranslocoService);

      return this.localeService
        .getLocale$()
        .pipe(first())
        .pipe(
          switchMap((lang) => {
            this.messageService.message(
              (values) =>
                translocoService
                  .load(`${bbraunSharedUtilTranslocoNgScope}/${lang}`)
                  .pipe(take(1))
                  .pipe(map(() => lang))
                  .pipe(catchError(() => of(lang)))
                  .pipe(
                    map((messageLang) => ({
                      title: translocoService.translate(
                        i18n(
                          'bbraunSharedUtilTranslocoNg.module.missingTranslation.title',
                        ),
                        undefined,
                        messageLang,
                      ),
                      message: translocoService.translate(
                        i18n(
                          'bbraunSharedUtilTranslocoNg.module.missingTranslation.text',
                        ),
                        values,
                        messageLang,
                      ),
                    })),
                  ),
              'error',
              {
                id: missingTranslationMessageId,
                actions: [{ type: 'push-or-count' }],
                timeOut: false,
                enableHtml: true,
                values: {
                  missingKey: key,
                  availableKeys: Array.from(this.translationLoaders.keys()),
                },
              },
            );
            return of(new Map());
          }),
        );
    }
  }

  addTranslation(
    translation: Observable<Translation> | Promise<Translation>,
    lang: string,
    scope?: string,
  ) {
    const key = this.createTranslationKey(lang, scope || undefined);
    this.translationLoaders.set(key, translation);
  }

  createTranslationKey(lang: string, scope?: string) {
    return scope ? `${scope}/${lang}` : lang;
  }

  addTranslationLoader(
    loader: TranslationLoaderFunction,
    fallbackTranslation: Translation,
    scope?: string,
  ) {
    const normalizedFallback = normalizeKeys(fallbackTranslation, scope);

    const loaderWithFallback = (lang: string) =>
      loader(lang)
        .pipe(catchError(() => of({})))
        .pipe(
          map((translation) =>
            this.isFallbackEnabled
              ? omitBy(
                  translation,
                  (value) =>
                    typeof value === 'string' &&
                    value.startsWith(missingTranslationPrefix),
                )
              : translation,
          ),
        )
        .pipe(map((translation) => normalizeKeys(translation, scope)))
        .pipe(
          map((translation) =>
            this.isFallbackEnabled
              ? {
                  ...normalizedFallback,
                  ...translation,
                }
              : translation,
          ),
        );

    for (const lang of this.supportedLocales) {
      this.addTranslation(loaderWithFallback(lang), lang, scope);
    }
  }
}

function normalizeKeys(translation: Translation, scope?: string) {
  if (scope) {
    const prefix = `${scope}.`;

    return fromPairs(
      Object.entries(translation).map(([key, value]) => [
        key.startsWith(prefix) ? key.substring(prefix.length) : key,
        value,
      ]),
    );
  } else {
    return translation;
  }
}
