import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  OnChanges,
} from '@angular/core';

@Directive({
  selector: '[bbraunUiFormAutofocus]',
})
export class AutofocusDirective implements AfterViewInit, OnChanges {
  constructor(
    private readonly el: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  ngOnChanges() {
    this.setFocus();
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  private setFocus() {
    if (
      this.document.activeElement !== this.el.nativeElement &&
      !hasFocusManagingParent(this.el.nativeElement)
    ) {
      this.el.nativeElement.focus();
    }
  }
}

function hasFocusManagingParent(element: Element) {
  let found = false;
  let parent: Element | undefined = element;

  while (!found && parent) {
    found = parent.hasAttribute('bbraunDipUiCommonConfiguration');
    if (!found) {
      parent = parent.parentElement || undefined;
    }
  }

  return parent || found;
}
