import { Inject, Injectable, InjectionToken } from '@angular/core';
import { or } from '@bbraun/shared/util-rxjs';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { defaultIfEmpty, map, scan, switchMap } from 'rxjs/operators';

export const INTERRUPT_LOADING_SIGNAL = new InjectionToken<Subject<boolean>>(
  'INTERRUPT_LOADING_SIGNAL',
);

export const LOAD_PANEL_VISIBLE_TRIGGERS = new InjectionToken<
  ReadonlyArray<boolean>
>('LOAD_PANEL_VISIBLE_TRIGGERS');

@Injectable({
  providedIn: 'root',
})
export class LoadPanelVisibleService {
  readonly isLoadPanelVisible$: Observable<boolean>;

  private readonly visibilityControls = new BehaviorSubject(
    new Set<Observable<boolean>>(),
  );

  constructor(
    @Inject(INTERRUPT_LOADING_SIGNAL)
    interruptLoadingSignal$: Subject<boolean>,
    @Inject(LOAD_PANEL_VISIBLE_TRIGGERS)
    loadPanelVisibleTriggers: ReadonlyArray<Observable<boolean>>,
  ) {
    this.isLoadPanelVisible$ = combineLatest([
      or([
        ...loadPanelVisibleTriggers,
        this.visibilityControls.pipe(
          switchMap((controlSet) =>
            or(Array.from(controlSet)).pipe(defaultIfEmpty(false as boolean)),
          ),
        ),
      ]),
      concat(of(false), interruptLoadingSignal$),
    ])
      .pipe(
        scan(
          ({ interruptCount }, [isLoading, isInterrupted]) => ({
            isLoading,
            interruptCount: Math.max(
              0,
              interruptCount + (isInterrupted ? 1 : -1),
            ),
          }),
          { isLoading: false, interruptCount: 0 },
        ),
      )
      .pipe(
        map(
          ({ isLoading, interruptCount }) => isLoading && interruptCount <= 0,
        ),
      );
  }

  add(observable: Observable<boolean>) {
    this.visibilityControls.next(this.visibilityControls.value.add(observable));
  }

  remove(observable: Observable<boolean>) {
    const observableSet = this.visibilityControls.value;
    const observableDeleted = observableSet.delete(observable);
    if (observableDeleted) {
      this.visibilityControls.next(observableSet);
    }
  }
}
