import { InjectionToken } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LocalizedDatePipeErrorHandler } from '@bbraun/shared/ui-language';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';

export const DEFAULT_ROUTE = new InjectionToken<string>('DEFAULT_ROUTE');

export const START_ROUTE = new InjectionToken<string>('START_ROUTE');

export const LOGIN_ROUTE = new InjectionToken<string>('LOGIN_ROUTE');

export const ENVIRONMENT = new InjectionToken<any>('ENVIRONMENT');

export const LOGIN_GUARD = new InjectionToken<CanActivate>('LOGIN_GUARD');

export const DATE_PARSE_ERROR_HANDLER =
  new InjectionToken<LocalizedDatePipeErrorHandler>(
    'NEXADIA_UI_COMMON_DATE_PARSE_ERROR',
  );

export const MESSAGE_SERVICE = new InjectionToken<TranslocoMessageService>(
  'MESSAGE_SERVICE',
);

export interface BavReportingConfig {
  readonly testing?: {
    readonly fixedCurrentDate?: string;
  };
}

export const BAV_REPORTING_CONFIG = new InjectionToken<BavReportingConfig>(
  'BAV_REPORTING_CONFIG',
);
