import { DayOfWeek } from '@bbraun/shared/util-lang';
import { addDays } from 'date-fns';

const EPOCH_START_UTC = new Date('1970-01-01T00:00:00Z');

const EPOCH_START_DAY_UTC = 4;

export type WeekdayFormat = 'narrow' | 'short' | 'long';

const utcDatesForDayOfWeek: { [day: number]: Date } = {
  [DayOfWeek.MON]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.MON - EPOCH_START_DAY_UTC,
  ),
  [DayOfWeek.TUE]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.TUE - EPOCH_START_DAY_UTC,
  ),
  [DayOfWeek.WED]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.WED - EPOCH_START_DAY_UTC,
  ),
  [DayOfWeek.THU]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.THU - EPOCH_START_DAY_UTC,
  ),
  [DayOfWeek.FRI]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.FRI - EPOCH_START_DAY_UTC,
  ),
  [DayOfWeek.SAT]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.SAT - EPOCH_START_DAY_UTC,
  ),
  [DayOfWeek.SUN]: addDays(
    EPOCH_START_UTC,
    DayOfWeek.SUN - EPOCH_START_DAY_UTC,
  ),
};

export function localizeDayOfWeek(
  dayOfWeek: DayOfWeek,
  locale: string,
  format: WeekdayFormat,
): string {
  const utcDateForDayOfWeek = utcDatesForDayOfWeek[dayOfWeek];

  if (utcDateForDayOfWeek) {
    return new Intl.DateTimeFormat(locale, {
      weekday: format,
      timeZone: 'UTC',
    }).format(utcDateForDayOfWeek);
  }

  throw new Error(
    `Calculation of UTC date for day of week ${dayOfWeek} failed!`,
  );
}
