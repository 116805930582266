import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { ErrorCodeIgnore, ErrorCodeLookup } from '@bbraun/shared/util-error';
import {
  ERROR_CODE_IGNORE,
  ERROR_CODE_LOOKUP,
  GlobalErrorHandler,
} from '@bbraun/shared/util-error-ng';
import { defer, of } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { scope } from './constants';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { JwtConfig } from './interfaces/jwt-config.type';
import { SECURITY_PUBLIC_ROUTES } from './interfaces/public-routes';
import { SecurityConfig, SECURITY_CONFIG } from './interfaces/security-config';
import {
  createIsLoginRunningTrigger,
  createIsLogoutRunningTrigger,
} from './provider-factories';
import {
  IS_LOGIN_IN_PROGRESS_TOKEN,
  IS_LOGOUT_IN_PROGRESS_TOKEN,
  LOGIN_SERVICE_TOKEN,
} from './services/injection-tokens';
import { LoginWithPrincipalService } from './services/login-with-principal.service';
import { SecurityConfigService } from './services/security-config.service';
import { SecurityErrorCodeIgnoreService } from './services/security-error-code-ignore.service';
import { SecurityErrorCodeLookupService } from './services/security-error-code-lookup.service';

@NgModule({
  providers: [
    {
      provide: LOGIN_SERVICE_TOKEN,
      useClass: LoginWithPrincipalService,
    },
    SecurityConfigService,
    { provide: SECURITY_PUBLIC_ROUTES, useValue: { routes: [] }, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: RefreshTokenInterceptor,
      multi: true,
    },

    {
      provide: ERROR_CODE_LOOKUP,
      multi: true,
      useExisting: SecurityErrorCodeLookupService,
    },
    {
      provide: ERROR_CODE_IGNORE,
      multi: true,
      useExisting: SecurityErrorCodeIgnoreService,
    },
    {
      provide: IS_LOGIN_IN_PROGRESS_TOKEN,
      useFactory: createIsLoginRunningTrigger,
      deps: [LOGIN_SERVICE_TOKEN],
    },
    {
      provide: IS_LOGOUT_IN_PROGRESS_TOKEN,
      useFactory: createIsLogoutRunningTrigger,
      deps: [LOGIN_SERVICE_TOKEN],
    },
  ],
})
export class DataAccessSecurityModule {
  static forRoot(
    config: SecurityConfig & JwtConfig,
  ): ModuleWithProviders<DataAccessSecurityModule> {
    return {
      ngModule: DataAccessSecurityModule,
      providers: [{ provide: SECURITY_CONFIG, useValue: config }],
    };
  }

  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
    @Inject(ERROR_CODE_LOOKUP)
    errorCodeLookups: ReadonlyArray<ErrorCodeLookup>,
    @Inject(ERROR_CODE_IGNORE)
    errorCodeIgnores: ReadonlyArray<ErrorCodeIgnore>,
    globalErrorHandler: GlobalErrorHandler,
    @Optional() @SkipSelf() parentModule: DataAccessSecurityModule | null,
  ) {
    if (parentModule) {
      throw new Error(
        'DataAccessSecurityModule is already loaded. Import it in AppModule only!',
      );
    } else {
      globalErrorHandler.registerErrorCodeLookups(errorCodeLookups);
      globalErrorHandler.registerErrorCodeIgnores(errorCodeIgnores);

      translationLoaderRegistry.addTranslationLoader(() => of({}), {});
      translationLoaderRegistry.addTranslationLoader(
        (lang: string) =>
          defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
        fallbackTranslation,
        scope,
      );
    }
  }
}
