import { isDefined, toKeys, toPairs } from '@bbraun/shared/util-lang';
import { isInteger as _isInteger } from 'lodash-es';
import { Validator } from '../validator/validator.type';

/**
 * A function that validates a complete object. Returns true if the object is
 * valid, false otherwise.
 */
export type ObjectValidatorFn<T extends {}> = (object: T) => boolean;

/**
 * An object validator
 */
export interface ObjectValidator<T extends {} = string> {
  readonly id: symbol;
  readonly message: string;
  readonly validate: ObjectValidatorFn<T>;
}

/**
 * A property validator
 */
export interface PropertyValidator<T extends {}, K extends keyof T> {
  readonly id: symbol;
  readonly message: string;
  readonly validate: Validator<T, K>;
}

/**
 * A map of property validator functions for a specific type
 */
export type PropertyValidatorMap<T extends {} = string> = {
  readonly [P in keyof T]?: ReadonlyArray<PropertyValidator<T, P>>;
};

/**
 * A map of failed validators (identified by their symbols) for given type
 */
export type PropertyValidationFailureMap<T extends {}> = {
  readonly [P in keyof T]?: ReadonlyArray<symbol>;
};

/**
 * Defines all object and property validators for a specific type
 */
export interface ObjectValidation<T extends {} = string> {
  readonly object: ReadonlyArray<ObjectValidator<T>>;
  readonly properties: PropertyValidatorMap<T>;
}

/**
 * Includes all failed validators for a given type
 */
export interface ObjectValidationResult<T extends {} = string> {
  readonly object: ReadonlyArray<{ message: string }>;
  readonly properties: PropertyValidationFailureMap<T>;
}

/**
 * Function that validates complete object with the given validation
 *
 * @param value - the object to validate
 * @param validation - the validation rules
 * @param properties - properties to validate (default: all properties)
 */
export function validate<T extends {}>(
  value: T,
  validation: ObjectValidation<T>,
  properties?: Iterable<keyof T>,
): ObjectValidationResult<T> {
  const object = validation.object.reduce((failed, validator) => {
    if (!validator.validate(value)) {
      failed.push({ message: validator.message });
    }
    return failed;
  }, [] as { message: string }[]);

  const failedValidationsByProperty: {
    [P in keyof T]?: readonly symbol[];
  } = {};
  for (const key of properties || toKeys(validation.properties)) {
    const propertyValidators = validation.properties[key];
    if (propertyValidators) {
      failedValidationsByProperty[key] = propertyValidators.reduce(
        (failed, validator) => {
          if (
            !validator.validate({
              property: key,
              value: value[key],
              formData: value,
            })
          ) {
            failed.push(validator.id);
          }
          return failed;
        },
        [] as symbol[],
      );
    }
  }

  return { object, properties: failedValidationsByProperty };
}

/**
 * Shortcut function to check if a validation result contains errors
 *
 * @param result - the validation result
 */
export function isValid<T extends {}>(
  result?: ObjectValidationResult<T>,
): boolean {
  if (result) {
    const propertiesValid = toKeys(result.properties).every((prop) =>
      isPropertyValid(prop as keyof T, result),
    );
    return propertiesValid && result.object.length === 0;
  }
  return true;
}

export function isPropertyValid<T extends {}>(
  property: keyof T,
  result: ObjectValidationResult<T> | undefined,
): boolean {
  return !result || !result?.properties[property]?.length;
}

export function getValidationMessagesForProperty<T>(
  property: keyof T,
  validation: ObjectValidation<T>,
  result: ObjectValidationResult<T> | undefined,
): ReadonlyArray<string> {
  const messageByValidator = new Map(
    validation.properties[property]?.map(({ id, message }) => [id, message]),
  );
  return (
    result?.properties[property]
      ?.map((validatorId) => messageByValidator.get(validatorId))
      .filter(isDefined) || []
  );
}

export function getInvalidProperties<T>(
  result: ObjectValidationResult<T>,
): ReadonlyArray<keyof T> {
  return Object.keys(result.properties).filter(
    (key) => !isPropertyValid<T>(key as keyof T, result),
  ) as (keyof T)[];
}

export function getValidationMessagesForInvalidProperties<T extends {}>(
  validation: ObjectValidation<T>,
  validationResults: ObjectValidationResult<T> | undefined,
): {
  [key in keyof T]?: ReadonlyArray<string>;
} {
  const errorProperties = validationResults?.properties;
  if (errorProperties) {
    return toPairs(errorProperties).reduce((acc, [key, errorValue]) => {
      if (errorValue && errorValue.length !== 0) {
        const messages = getValidationMessagesForProperty(
          key,
          validation,
          validationResults,
        );

        return {
          ...acc,
          [key]: messages,
        };
      } else {
        return acc;
      }
    }, {});
  } else {
    return {};
  }
}
