import { CommonModule } from '@angular/common';
import { ErrorHandler, Inject, NgModule } from '@angular/core';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { TranslocoMessageServiceFactory } from '@bbraun/shared/util-transloco-message';
import { defer, of } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { ERROR_MESSAGE_SERVICE } from './injection-tokens';
import { createMessageService, scope } from './provider-factories';
import { GlobalErrorHandler } from './services/global-error-handler';
import { OnNavigationErrorHandlerService } from './services/on-navigation-error-handler.service';
import { OnNavigationErrorService } from './services/on-navigation-error.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: ERROR_MESSAGE_SERVICE,
      useFactory: createMessageService,
      deps: [TranslocoMessageServiceFactory],
    },
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandler,
    },
    OnNavigationErrorService,
    OnNavigationErrorHandlerService,
  ],
})
export class UtilErrorNgModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(() => of({}), {});
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      scope,
    );
  }
}
