<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingUiCommon';
    read: 'bbraunBavReportingUiCommon.errorComponent'
  "
>
  <mat-card class="bbraun-flat">
    <mat-card-header>
      <mat-card-title class="bbraun-subheader">BAV-Reporting</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ translate('error.message') }}
      </p>
    </mat-card-content>
  </mat-card>
</ng-container>
