import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bbraun-ui-discard-changes-discard-changes-dialog',
  templateUrl: './discard-changes-dialog.component.html',
  styleUrls: ['./discard-changes-dialog.component.scss'],
})
export class DiscardChangesDialogComponent {
  constructor(
    private readonly matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { discardMessage?: string },
  ) {}

  discard() {
    this.matDialogRef.close({ continue: true });
  }

  remain() {
    this.matDialogRef.close({ continue: false });
  }
}
