import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocaleState, LOCALE_FEATURE_KEY } from './locale.reducer';

const getLocaleState = createFeatureSelector<LocaleState>(LOCALE_FEATURE_KEY);

const getLocale = createSelector(
  getLocaleState,
  (state: LocaleState) => state.locale,
);

export const localeQuery = {
  getLocale,
};
