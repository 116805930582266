import {
  ID,
  INCLUDED,
  LINKS,
  META,
  RELATIONSHIPS,
  REFERENCES,
  TYPE,
} from './symbols.type';
import { ApiDefinition, ApiTypes } from './typed/api-types';
import {
  MultiRelationshipKeys,
  SingleRelationshipKeys,
} from './typed/resource-keys.type';

type TypeWithInstanceMetaData<TApiDefinition extends ApiDefinition<ApiTypes>> =
  keyof TApiDefinition['resourceTypes'] & keyof TApiDefinition['resources'];

type ExtractType<T> = T extends Readonly<{
  [TYPE]: infer type;
}>
  ? type
  : T extends ReadonlyArray<{ [TYPE]: infer type }>
  ? type
  : never;

export type MutableReferenceMap<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends TypeWithInstanceMetaData<TApiDefinition>,
  TRelationship extends keyof TApiDefinition['resources'][TType]['relationships'] &
    keyof TApiDefinition['resourceTypes'][TType],
> =
  | WeakMap<
      Readonly<{
        [TYPE]: ExtractType<
          Exclude<
            TApiDefinition['resourceTypes'][TType][TRelationship],
            undefined | null
          >
        >;
      }>,
      {
        readonly [META]: TApiDefinition['resources'][TType]['relationships'][TRelationship]['references']['meta'];
      }
    >
  | Map<
      Readonly<{
        [TYPE]: ExtractType<
          Exclude<
            TApiDefinition['resourceTypes'][TType][TRelationship],
            undefined | null
          >
        >;
      }>,
      {
        readonly [META]: TApiDefinition['resources'][TType]['relationships'][TRelationship]['references']['meta'];
      }
    >;

export type MutableRelationships<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends keyof TApiDefinition['resourceTypes'],
> = Partial<{
  [relationship in (
    | SingleRelationshipKeys<TApiDefinition['resourceTypes'][TType]>
    | MultiRelationshipKeys<TApiDefinition['resourceTypes'][TType]>
  ) &
    keyof TApiDefinition['resources'][TType]['relationships']]: {
    [META]: TApiDefinition['resources'][TType]['relationships'][relationship]['meta'];
    [LINKS]: TApiDefinition['resources'][TType]['relationships'][relationship]['links'];
    [REFERENCES]: MutableReferenceMap<TApiDefinition, TType, relationship>;
  };
}>;

export function isMutableModelObjectValue<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends keyof TApiDefinition['resourceTypes'],
>(
  value: MutableModelObject<TApiDefinition, TType>,
): value is MutableModelObject<TApiDefinition, TType> &
  MutableIsIncluded<TApiDefinition, TType> {
  return value[INCLUDED];
}

export interface MutableIsIncluded<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends keyof TApiDefinition['resourceTypes'],
> {
  [INCLUDED]: true;
  [RELATIONSHIPS]: MutableRelationships<TApiDefinition, TType>;
}

export interface MutableIsNotIncluded {
  [INCLUDED]: false;
}

export type MutableModelObject<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends keyof TApiDefinition['resourceTypes'],
> = {
  [ID]: string;
  [TYPE]: TType;
  [META]: TApiDefinition['resources'][TType]['meta'];
  [LINKS]: TApiDefinition['resources'][TType]['links'];
} & (MutableIsIncluded<TApiDefinition, TType> | MutableIsNotIncluded);

export type MutableDataModelObject<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends keyof TApiDefinition['resourceTypes'],
> = MutableModelObject<TApiDefinition, TType> & {
  [key: string]: unknown;
} & (MutableIsIncluded<TApiDefinition, TType> | MutableIsNotIncluded);

export type MutableObjectsByTypeAndId<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TType extends keyof TApiDefinition['resourceTypes'],
> = Map<string, Map<string, MutableDataModelObject<TApiDefinition, TType>>>;
