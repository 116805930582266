import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_INTEGER_ID } from '../../../../validation/factories/is-number-integer-validator-factory';
import { VALUE_AND_LINKED_PROPERTY_ARE_NUMBER_OR_BOTH_NOT_NUMBER_VALIDATOR_ID } from '../../../../validation/factories/is-property-and-value-number-or-both-not-number-validator-factory';
import { ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ID } from '../../../../validation/factories/one-heparin-patients-is-set-in-february-and-august-validator-factory';

export function createHeparinStandardPatientsProperty(
  reportMonth: number,
): Property {
  return {
    title: 'Heparin (sodium/calcium)',
    technicalName: 'heparinStandardPatients',
    dataType: 'number',
    unit: null,
    highlighted: false,
    subName: null,
    formula: null,
    validation: [
      {
        validator: ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ID,
        options: {
          config: {
            reportMonth,
          },
        },
      },
      {
        validator:
          VALUE_AND_LINKED_PROPERTY_ARE_NUMBER_OR_BOTH_NOT_NUMBER_VALIDATOR_ID,
        options: {
          config: {
            linkedProperty: 'heparinStandard',
          },
          messageKey: i18n(
            'bbraunBavReportingFeatureAisReports.validation.heparinStandardPatientsOrHeparinStandard.notSet.whenOtherIsSet.error.message',
          ),
        },
      },
      { validator: IS_NUMBER_INTEGER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
    ],
  };
}
