import { Inject, Injectable } from '@angular/core';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { map, Observable } from 'rxjs';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import {
  ValidatorFactory,
  ValidatorFactoryFunction,
} from '@bbraun/bav-reporting/ui-report-editor';
import { createIsZeroOrEqualToNumberOfChronicHdPatientsValidator } from '../create-validators';
import { FeatureAisReportsScopedTranslationService } from '../../../services/feature-ais-reports-scoped-translation.service';

export const IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ID =
  'IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS';
export const IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ERROR = Symbol(
  IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ID,
);

@Injectable({
  providedIn: 'root',
})
export class IsZeroOrEqualToNumberOfChronicHdPatientsValidatorFactory
  implements ValidatorFactory<undefined>
{
  readonly id = IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ID;
  readonly validatorFn: Observable<ValidatorFactoryFunction<undefined>>;

  constructor(
    @Inject(FeatureAisReportsScopedTranslationService)
    private readonly scopedTranslationService: {
      loadedLang$: Observable<{ translate: TranslateFunction }>;
    },
  ) {
    this.validatorFn = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) => () => ({
        id: IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ERROR,
        validate: createIsZeroOrEqualToNumberOfChronicHdPatientsValidator(),
        message: translate(
          i18n(
            'bbraunBavReportingFeatureAisReports.validation.property.zeroOrEqual.numberOfChronicHdPatients.error.message',
          ),
        ),
      })),
    );
  }
}
