<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingUiCommon';
    read: 'bbraunBavReportingUiCommon.startDataTransferButtonComponent'
  "
>
  <div
    [matTooltip]="translate('noPermissions.tooltip')"
    matTooltipPosition="below"
    matTooltipClass="tooltip"
    [matTooltipDisabled]="canActivate"
  >
    <button
      mat-button
      color="primary"
      class="full-size"
      [disabled]="!canActivate || (isDataTransferRunning$ | async)"
      (click)="startDataTransfer()"
    >
      {{ translate('startDataTransferButton.text') }}
      <mat-icon *ngIf="!(isDataTransferRunning$ | async)">
        drive_folder_upload
      </mat-icon>
      <mat-icon *ngIf="isDataTransferRunning$ | async">
        <mat-spinner [diameter]="20"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</ng-container>
