import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { defer, of, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { LoginService } from '../interfaces/login-service';
import { AccessTokenService } from '../services/access-token.service';
import { DataAccessSecurityErrorCodes } from '../services/data-access-security-error-codes';
import { LOGIN_SERVICE_TOKEN } from '../services/injection-tokens';
import { IsWhitelistedRouteService } from './is-whitelisted-route.service';

const loginTokenGuardErrorMessageId = Symbol('loginTokenGuardErrorMessageId');

@Injectable({ providedIn: 'root' })
export class LoginTokenGuard implements CanActivateChild {
  private readonly jwtHelper = new JwtHelperService();

  constructor(
    private readonly isWhitelistedRouteService: IsWhitelistedRouteService,
    private readonly accessTokenService: AccessTokenService,
    @Inject(LOGIN_SERVICE_TOKEN)
    private readonly loginService: Pick<LoginService, 'logout'>,
    private readonly messageService: MessageService,
  ) {}

  canActivateChild(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;

    return (
      this.isWhitelistedRouteService.isWhitelistedRoute(url) ||
      this.accessTokenService.token$
        .pipe(first())
        .pipe(
          switchMap((tokens) => {
            if (!tokens) {
              return defer(() =>
                this.loginService
                  .logout(DataAccessSecurityErrorCodes.UNAUTHORIZED)
                  .then(() => true),
              );
            } else if (this.jwtHelper.isTokenExpired(tokens.token)) {
              return defer(() =>
                this.loginService
                  .logout(DataAccessSecurityErrorCodes.SESSION_EXPIRED)
                  .then(() => true),
              );
            } else if (!isLoginToken(tokens)) {
              return defer(() =>
                this.loginService
                  .logout(DataAccessSecurityErrorCodes.LOGIN_SESSION_EXPIRED)
                  .then(() => true),
              );
            } else {
              return of(true);
            }
          }),
        )
        .pipe(
          catchError((error) => {
            this.messageService.message(
              'Login token check for route failed with error.',
              'debug',
              {
                id: loginTokenGuardErrorMessageId,
                actions: [{ type: 'update' }],
                values: { url },
                error,
              },
            );
            return defer(() =>
              this.loginService.logout().then(() => true),
            ).pipe(switchMap(() => throwError(() => error)));
          }),
        )
    );
  }
}

function isLoginToken(
  token: { token: string; decoded?: { [key: string]: unknown } },
  typePropertyName = 'http://bbraun.com/token_type',
  loginTokenType = 'login_token',
) {
  const type = token.decoded ? token.decoded[typePropertyName] : undefined;
  if (type) {
    return (Array.isArray(type) ? type : [type]).indexOf(loginTokenType) >= 0;
  } else {
    return false;
  }
}
