import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SecurityConfigService } from '../services/security-config.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private readonly messageService: MessageService,
    private readonly securityConfigService: SecurityConfigService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !this.securityConfigService.isWhitelistedDomain(req.url) ||
      this.securityConfigService.isBlacklistedRoute(req.url)
    ) {
      return next.handle(req);
    } else {
      return next
        .handle(req)
        .pipe(tap({ error: this.onUnauthorized.bind(this) }));
    }
  }

  private onUnauthorized(value: unknown) {
    if (value instanceof HttpErrorResponse && value.status === 401) {
      this.messageService.message(
        'Authentication failed. You may have to log out and log in again.',
        'error',
      );
    }
  }
}
