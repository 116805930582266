export function isNull(value: unknown): value is null {
  return value === null;
}

export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isSymbol(value: unknown): value is symbol {
  return typeof value === 'symbol';
}

export function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object';
}

export function isUnknown(_value: unknown): _value is unknown {
  return true;
}

export function isDefined<T>(value: T): value is Exclude<T, undefined> {
  return !isUndefined(value);
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function is<T>(value: T): (v: unknown) => v is T {
  return (v: unknown): v is T => v === value;
}
