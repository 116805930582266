import { IndexResult } from '../../../../util-jsonapi/src/lib/index/index-provider';
import { LinkObject } from '../json-api.types';
import { ApiDefinition, ApiTypes } from '../typed/api-types';

export function getResourceLinks<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TResourceType extends keyof TApiDefinition['index']['meta']['resources'],
>(
  resourceType: TResourceType,
  result: IndexResult<TApiDefinition> | undefined,
): Partial<
  TApiDefinition['index']['meta']['resources'][TResourceType]['links']
> {
  if (result && result.meta && result.meta.resources) {
    const resourceLinks = result.meta.resources[resourceType];
    return resourceLinks?.links || {};
  } else {
    return {};
  }
}

export function getResourceLink<
  TLink extends string,
  TResourceType extends string,
  TApiDefinition extends ApiDefinition<ApiTypes> & {
    index: {
      meta: {
        resources: {
          [key in TResourceType]: { links: { [link in TLink]?: LinkObject } };
        };
      };
    };
  },
>(
  resourceType: TResourceType,
  linkName: TLink,
  result: IndexResult<TApiDefinition> | undefined,
):
  | TApiDefinition['index']['meta']['resources'][TResourceType]['links'][TLink]
  | undefined {
  const links = getResourceLinks(resourceType, result);
  return links[linkName];
}

export function hasResourceLink<
  TLink extends string,
  TResourceType extends string,
  TApiDefinition extends ApiDefinition<ApiTypes> & {
    index: {
      meta: {
        resources: {
          [key in TResourceType]: { links: { [link in TLink]?: LinkObject } };
        };
      };
    };
  },
>(
  resourceType: TResourceType,
  linkName: TLink,
  result: IndexResult<TApiDefinition> | undefined,
): boolean {
  return !!getResourceLink(resourceType, linkName, result);
}
