export function getErrorMessageCode(errorResponse: {
  error?: { errors?: ReadonlyArray<{ code?: string }> };
}): string | undefined {
  if (
    errorResponse &&
    errorResponse.error &&
    errorResponse.error.errors &&
    errorResponse.error.errors[0] &&
    errorResponse.error.errors[0].code
  ) {
    return errorResponse.error.errors[0].code;
  }
  return undefined;
}
