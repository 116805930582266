import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SubmitCancelFormComponent } from './components/submit-cancel-form/submit-cancel-form.component';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SubmitCancelFormComponent, AutofocusDirective],
  exports: [SubmitCancelFormComponent, AutofocusDirective],
  providers: [],
})
export class BBraunUiFormModule {}
