// THIS FILE IS GENERATED - DO NOT EDIT!

import { TYPE } from '@bbraun/shared/util-jsonapi-symbols';

// @ts-ignore

export type AisCalculatedReportModel = {
  [TYPE]: 'ais-calculated-report';
  readonly id: string;
} & {
  ageMean: null | number;
  ageSd: null | number;
  albuminBe25AndL35: null | number;
  albuminBe35: null | number;
  albuminGPerLBcGreenMean: null | number;
  albuminGPerLBcGreenSd: null | number;
  albuminGPerLBcPurpleMean: null | number;
  albuminGPerLBcPurpleSd: null | number;
  albuminL25: null | number;
  albuminNotDone: null | number;
  bloodFlowMean: null | number;
  bloodFlowSd: null | number;
  bloodPressureDiastolicBe90: null | number;
  bloodPressureDiastolicL90: null | number;
  bloodPressureDiastolicNotDone: null | number;
  bloodPressureSystolicBe140: null | number;
  bloodPressureSystolicL140: null | number;
  bloodPressureSystolicNotDone: null | number;
  bmiBe20AndL25: null | number;
  bmiBe25AndL30: null | number;
  bmiBe30: null | number;
  bmiL20: null | number;
  bmiMean: null | number;
  bmiNotDone: null | number;
  bmiSd: null | number;
  calciumB10p5: null | number;
  calciumBe8p4AndLe10p5: null | number;
  calciumL8p4: null | number;
  calciumMgPerDlMean: null | number;
  calciumMgPerDlSd: null | number;
  calciumMmolPerLMean: null | number;
  calciumMmolPerLSd: null | number;
  calciumNotDone: null | number;
  centerId: null | string;
  charlsonIndexBe8Patient: null | number;
  charlsonIndexBe8Sum: null | number;
  charlsonIndexL8Patient: null | number;
  charlsonIndexL8Sum: null | number;
  comorbiditiesCerebrovascularDisease: null | number;
  comorbiditiesCongestiveHeartFailure: null | number;
  comorbiditiesDiabetesChronicComplication: null | number;
  comorbiditiesMalignancy: null | number;
  comorbiditiesMetastaticSolidTumor: null | number;
  comorbiditiesModerateSevereLiverDisease: null | number;
  comorbiditiesMyocardialInfarction: null | number;
  comorbiditiesPeripheralVascularDisease: null | number;
  convectiveVolumeBe23: null | number;
  convectiveVolumeL23: null | number;
  convectiveVolumeMean: null | number;
  convectiveVolumeNotDone: null | number;
  convectiveVolumeSd: null | number;
  crpElevated: null | number;
  crpNotDone: null | number;
  crpWithinLimits: null | number;
  dialysisFreqBe3: null | number;
  dialysisFreqL3: null | number;
  dialysisFreqMean: null | number;
  dialysisFreqNotDone: null | number;
  dialysisFreqSd: null | number;
  dryBodyWeightMean: null | number;
  dryBodyWeightSd: null | number;
  ektvBe1AndL1p2: null | number;
  ektvBe1p2: null | number;
  ektvL1: null | number;
  ektvMean: null | number;
  ektvNotDone: null | number;
  ektvSd: null | number;
  epoDose: null | number;
  ferritinB500: null | number;
  ferritinBe200AndLe500: null | number;
  ferritinL200: null | number;
  ferritinMean: null | number;
  ferritinNotDone: null | number;
  ferritinSd: null | number;
  genderFemale: number;
  genderMale: number;
  handHygieneScore: null | number;
  hdInfectionAvfBacteriaMrsaInfection: null | number;
  hdInfectionAvfBacteriaOtherInfection: null | number;
  hdInfectionAvfExitSiteMrsaInfection: null | number;
  hdInfectionAvfExitSiteOtherInfection: null | number;
  hdInfectionAvfPatientNum: null | number;
  hdInfectionCvcBacteriaMrsaInfection: null | number;
  hdInfectionCvcBacteriaOtherInfection: null | number;
  hdInfectionCvcExitSiteMrsaInfection: null | number;
  hdInfectionCvcExitSiteOtherInfection: null | number;
  hdInfectionCvcPatientNum: null | number;
  hdInfectionGraftBacteriaMrsaInfection: null | number;
  hdInfectionGraftBacteriaOtherInfection: null | number;
  hdInfectionGraftExitSiteMrsaInfection: null | number;
  hdInfectionGraftExitSiteOtherInfection: null | number;
  hdInfectionGraftPatientNum: null | number;
  hdInfectionHbvPlus: null | number;
  hdInfectionHcvPlus: null | number;
  hdInfectionHivPlus: null | number;
  hdTransplantWaitingPatient: null | number;
  heightMean: null | number;
  heightSd: null | number;
  hemoglobinB12: null | number;
  hemoglobinBe10AndLe12: null | number;
  hemoglobinGPerDlMean: null | number;
  hemoglobinGPerDlSd: null | number;
  hemoglobinL10: null | number;
  hemoglobinMmolPerLMean: null | number;
  hemoglobinMmolPerLSd: null | number;
  hemoglobinNotDone: null | number;
  heparinBemi: null | number;
  heparinBemiPatients: null | number;
  heparinDalt: null | number;
  heparinDaltPatients: null | number;
  heparinEnoxA: null | number;
  heparinEnoxAPatients: null | number;
  heparinEnoxC: null | number;
  heparinEnoxCPatients: null | number;
  heparinEnoxE: null | number;
  heparinEnoxEPatients: null | number;
  heparinEnoxG: null | number;
  heparinEnoxGPatients: null | number;
  heparinEnoxL: null | number;
  heparinEnoxLPatients: null | number;
  heparinHeparinlessTreatment: null | number;
  heparinNadr: null | number;
  heparinNadrPatients: null | number;
  heparinStandard: null | number;
  heparinStandardPatients: null | number;
  heparinTinz: null | number;
  heparinTinzPatients: null | number;
  hospCardiologyDays: null | number;
  hospCardiologyHosp: null | number;
  hospCardiologyMissedSessions: null | number;
  hospCardiologyPatient: null | number;
  hospInfectionsDays: null | number;
  hospInfectionsHosp: null | number;
  hospInfectionsMissedSessions: null | number;
  hospInfectionsPatient: null | number;
  hospOtherDays: null | number;
  hospOtherHosp: null | number;
  hospOtherMissedSessions: null | number;
  hospOtherPatient: null | number;
  hospVascularAccessDays: null | number;
  hospVascularAccessHosp: null | number;
  hospVascularAccessMissedSessions: null | number;
  hospVascularAccessPatient: null | number;
  intactParathormonB800: null | number;
  intactParathormonBe100AndLe800: null | number;
  intactParathormonL100: null | number;
  intactParathormonNotDone: null | number;
  intactParathormonPgPerMlMean: null | number;
  intactParathormonPgPerMlSd: null | number;
  intactParathormonPmolPerLMean: null | number;
  intactParathormonPmolPerLSd: null | number;
  medicationEsa: null | number;
  medicationIviron: null | number;
  month: number;
  nutritionEiEnteral: null | number;
  nutritionEiIdpn: null | number;
  nutritionEiNotTreated: null | number;
  nutritionSgaA: null | number;
  nutritionSgaB: null | number;
  nutritionSgaC: null | number;
  nutritionSgaNotDone: null | number;
  ownPatientAllLastMonth: null | number;
  ownPatientHemoDialysis3Month: null | number;
  ownPatientNewAdmission: null | number;
  ownPatientNewBBraunAvitumCenter: null | number;
  ownPatientNewBackTransp: null | number;
  ownPatientNewOtherCenter: null | number;
  ownPatientNewPdToHd: null | number;
  ownPatientResignedBBraunAvitumCenter: null | number;
  ownPatientResignedExitusChronicCardiovascular: null | number;
  ownPatientResignedExitusChronicCerebrovascular: null | number;
  ownPatientResignedExitusChronicInfection: null | number;
  ownPatientResignedExitusChronicMalignancy: null | number;
  ownPatientResignedExitusChronicOther: null | number;
  ownPatientResignedExitusNonchronic: null | number;
  ownPatientResignedHdToPd: null | number;
  ownPatientResignedOtherCenter: null | number;
  ownPatientResignedQuitted: null | number;
  ownPatientResignedTransp: null | number;
  phosphateBe3p5AndL5p5: null | number;
  phosphateBe5p5: null | number;
  phosphateL3p5: null | number;
  phosphateMgPerDlMean: null | number;
  phosphateMgPerDlSd: null | number;
  phosphateMmolPerLMean: null | number;
  phosphateMmolPerLSd: null | number;
  phosphateNotDone: null | number;
  primaryRenalDiseaseDiabetesNephropathy: null | number;
  primaryRenalDiseaseGlomerulonephritis: null | number;
  primaryRenalDiseaseHypertension: null | number;
  primaryRenalDiseaseOther: null | number;
  primaryRenalDiseasePolycystic: null | number;
  primaryRenalDiseaseTubulointerstitial: null | number;
  primaryRenalDiseaseVascular: null | number;
  seroconversionHbvChronic: null | number;
  seroconversionHbvNew: null | number;
  seroconversionHcvChronic: null | number;
  seroconversionHcvNew: null | number;
  seroconversionHivChronic: null | number;
  seroconversionHivNew: null | number;
  serumCreatinineBe8: null | number;
  serumCreatinineL8: null | number;
  serumCreatinineMgPerDlMean: null | number;
  serumCreatinineMgPerDlSd: null | number;
  serumCreatinineNotDone: null | number;
  serumCreatinineUmolPerLMean: null | number;
  serumCreatinineUmolPerLSd: null | number;
  tempPatientHoliday: null | number;
  tempPatientTemporary: null | number;
  timeOnDialysisMean: null | number;
  timeOnDialysisSd: null | number;
  timeTreatmentBe10p5AndL12: null | number;
  timeTreatmentBe12: null | number;
  timeTreatmentBe9AndL10p5: null | number;
  timeTreatmentL9: null | number;
  timeTreatmentMean: null | number;
  timeTreatmentNotDone: null | number;
  timeTreatmentSd: null | number;
  transferrinSaturationB30: null | number;
  transferrinSaturationBe20AndLe30: null | number;
  transferrinSaturationL20: null | number;
  transferrinSaturationMean: null | number;
  transferrinSaturationNotDone: null | number;
  transferrinSaturationSd: null | number;
  transmissionId: number;
  treatmentModalityHd: null | number;
  treatmentModalityHdf: null | number;
  treatmentModalityHdfPost: null | number;
  treatmentModalityHdfPre: null | number;
  treatmentsOwnPatients: null | number;
  treatmentsTempPatients: null | number;
  waterQualityBacteriologyFirstResult: null | number;
  waterQualityBacteriologyNotDone: null | boolean;
  waterQualityEndotoxinFirstResult: null | number;
  waterQualityEndotoxinNotDone: null | boolean;
  year: number;
};

export type AisReportModel = {
  [TYPE]: 'ais-report';
  readonly id: string;
} & {
  ageMean: null | number;
  ageSd: null | number;
  albuminBe25AndL35: null | number;
  albuminBe35: null | number;
  albuminGPerLBcGreenMean: null | number;
  albuminGPerLBcGreenSd: null | number;
  albuminGPerLBcPurpleMean: null | number;
  albuminGPerLBcPurpleSd: null | number;
  albuminL25: null | number;
  albuminNotDone: null | number;
  bloodFlowMean: null | number;
  bloodFlowSd: null | number;
  bloodPressureDiastolicBe90: null | number;
  bloodPressureDiastolicL90: null | number;
  bloodPressureDiastolicNotDone: null | number;
  bloodPressureSystolicBe140: null | number;
  bloodPressureSystolicL140: null | number;
  bloodPressureSystolicNotDone: null | number;
  bmiBe20AndL25: null | number;
  bmiBe25AndL30: null | number;
  bmiBe30: null | number;
  bmiL20: null | number;
  bmiMean: null | number;
  bmiNotDone: null | number;
  bmiSd: null | number;
  calciumB10p5: null | number;
  calciumBe8p4AndLe10p5: null | number;
  calciumL8p4: null | number;
  calciumMgPerDlMean: null | number;
  calciumMgPerDlSd: null | number;
  calciumMmolPerLMean: null | number;
  calciumMmolPerLSd: null | number;
  calciumNotDone: null | number;
  centerId: string;
  charlsonIndexBe8Patient: null | number;
  charlsonIndexBe8Sum: null | number;
  charlsonIndexL8Patient: null | number;
  charlsonIndexL8Sum: null | number;
  comorbiditiesCerebrovascularDisease: null | number;
  comorbiditiesCongestiveHeartFailure: null | number;
  comorbiditiesDiabetesChronicComplication: null | number;
  comorbiditiesMalignancy: null | number;
  comorbiditiesMetastaticSolidTumor: null | number;
  comorbiditiesModerateSevereLiverDisease: null | number;
  comorbiditiesMyocardialInfarction: null | number;
  comorbiditiesPeripheralVascularDisease: null | number;
  convectiveVolumeBe23: null | number;
  convectiveVolumeL23: null | number;
  convectiveVolumeMean: null | number;
  convectiveVolumeNotDone: null | number;
  convectiveVolumeSd: null | number;
  crpElevated: null | number;
  crpNotDone: null | number;
  crpWithinLimits: null | number;
  dialysisFreqBe3: null | number;
  dialysisFreqL3: null | number;
  dialysisFreqMean: null | number;
  dialysisFreqNotDone: null | number;
  dialysisFreqSd: null | number;
  dryBodyWeightMean: null | number;
  dryBodyWeightSd: null | number;
  ektvBe1AndL1p2: null | number;
  ektvBe1p2: null | number;
  ektvL1: null | number;
  ektvMean: null | number;
  ektvNotDone: null | number;
  ektvSd: null | number;
  epoDose: null | number;
  ferritinB500: null | number;
  ferritinBe200AndLe500: null | number;
  ferritinL200: null | number;
  ferritinMean: null | number;
  ferritinNotDone: null | number;
  ferritinSd: null | number;
  genderFemale: null | number;
  genderMale: null | number;
  handHygieneScore: null | number;
  hdInfectionAvfBacteriaMrsaInfection: null | number;
  hdInfectionAvfBacteriaOtherInfection: null | number;
  hdInfectionAvfExitSiteMrsaInfection: null | number;
  hdInfectionAvfExitSiteOtherInfection: null | number;
  hdInfectionAvfPatientNum: null | number;
  hdInfectionCvcBacteriaMrsaInfection: null | number;
  hdInfectionCvcBacteriaOtherInfection: null | number;
  hdInfectionCvcExitSiteMrsaInfection: null | number;
  hdInfectionCvcExitSiteOtherInfection: null | number;
  hdInfectionCvcPatientNum: null | number;
  hdInfectionGraftBacteriaMrsaInfection: null | number;
  hdInfectionGraftBacteriaOtherInfection: null | number;
  hdInfectionGraftExitSiteMrsaInfection: null | number;
  hdInfectionGraftExitSiteOtherInfection: null | number;
  hdInfectionGraftPatientNum: null | number;
  hdInfectionHbvPlus: null | number;
  hdInfectionHcvPlus: null | number;
  hdInfectionHivPlus: null | number;
  hdTransplantWaitingPatient: null | number;
  heightMean: null | number;
  heightSd: null | number;
  hemoglobinB12: null | number;
  hemoglobinBe10AndLe12: null | number;
  hemoglobinGPerDlMean: null | number;
  hemoglobinGPerDlSd: null | number;
  hemoglobinL10: null | number;
  hemoglobinMmolPerLMean: null | number;
  hemoglobinMmolPerLSd: null | number;
  hemoglobinNotDone: null | number;
  heparinBemi: null | number;
  heparinBemiPatients: null | number;
  heparinDalt: null | number;
  heparinDaltPatients: null | number;
  heparinEnoxA: null | number;
  heparinEnoxAPatients: null | number;
  heparinEnoxC: null | number;
  heparinEnoxCPatients: null | number;
  heparinEnoxE: null | number;
  heparinEnoxEPatients: null | number;
  heparinEnoxG: null | number;
  heparinEnoxGPatients: null | number;
  heparinEnoxL: null | number;
  heparinEnoxLPatients: null | number;
  heparinHeparinlessTreatment: null | number;
  heparinNadr: null | number;
  heparinNadrPatients: null | number;
  heparinStandard: null | number;
  heparinStandardPatients: null | number;
  heparinTinz: null | number;
  heparinTinzPatients: null | number;
  hospCardiologyDays: null | number;
  hospCardiologyHosp: null | number;
  hospCardiologyMissedSessions: null | number;
  hospCardiologyPatient: null | number;
  hospInfectionsDays: null | number;
  hospInfectionsHosp: null | number;
  hospInfectionsMissedSessions: null | number;
  hospInfectionsPatient: null | number;
  hospOtherDays: null | number;
  hospOtherHosp: null | number;
  hospOtherMissedSessions: null | number;
  hospOtherPatient: null | number;
  hospVascularAccessDays: null | number;
  hospVascularAccessHosp: null | number;
  hospVascularAccessMissedSessions: null | number;
  hospVascularAccessPatient: null | number;
  intactParathormonB800: null | number;
  intactParathormonBe100AndLe800: null | number;
  intactParathormonL100: null | number;
  intactParathormonNotDone: null | number;
  intactParathormonPgPerMlMean: null | number;
  intactParathormonPgPerMlSd: null | number;
  intactParathormonPmolPerLMean: null | number;
  intactParathormonPmolPerLSd: null | number;
  medicationEsa: null | number;
  medicationIviron: null | number;
  month: number;
  nutritionEiEnteral: null | number;
  nutritionEiIdpn: null | number;
  nutritionEiNotTreated: null | number;
  nutritionSgaA: null | number;
  nutritionSgaB: null | number;
  nutritionSgaC: null | number;
  nutritionSgaNotDone: null | number;
  ownPatientAllLastMonth: null | number;
  ownPatientHemoDialysis3Month: null | number;
  ownPatientNewAdmission: null | number;
  ownPatientNewBBraunAvitumCenter: null | number;
  ownPatientNewBackTransp: null | number;
  ownPatientNewOtherCenter: null | number;
  ownPatientNewPdToHd: null | number;
  ownPatientResignedBBraunAvitumCenter: null | number;
  ownPatientResignedExitusChronicCardiovascular: null | number;
  ownPatientResignedExitusChronicCerebrovascular: null | number;
  ownPatientResignedExitusChronicInfection: null | number;
  ownPatientResignedExitusChronicMalignancy: null | number;
  ownPatientResignedExitusChronicOther: null | number;
  ownPatientResignedExitusNonchronic: null | number;
  ownPatientResignedHdToPd: null | number;
  ownPatientResignedOtherCenter: null | number;
  ownPatientResignedQuitted: null | number;
  ownPatientResignedTransp: null | number;
  phosphateBe3p5AndL5p5: null | number;
  phosphateBe5p5: null | number;
  phosphateL3p5: null | number;
  phosphateMgPerDlMean: null | number;
  phosphateMgPerDlSd: null | number;
  phosphateMmolPerLMean: null | number;
  phosphateMmolPerLSd: null | number;
  phosphateNotDone: null | number;
  primaryRenalDiseaseDiabetesNephropathy: null | number;
  primaryRenalDiseaseGlomerulonephritis: null | number;
  primaryRenalDiseaseHypertension: null | number;
  primaryRenalDiseaseOther: null | number;
  primaryRenalDiseasePolycystic: null | number;
  primaryRenalDiseaseTubulointerstitial: null | number;
  primaryRenalDiseaseVascular: null | number;
  seroconversionHbvChronic: null | number;
  seroconversionHbvNew: null | number;
  seroconversionHcvChronic: null | number;
  seroconversionHcvNew: null | number;
  seroconversionHivChronic: null | number;
  seroconversionHivNew: null | number;
  serumCreatinineBe8: null | number;
  serumCreatinineL8: null | number;
  serumCreatinineMgPerDlMean: null | number;
  serumCreatinineMgPerDlSd: null | number;
  serumCreatinineNotDone: null | number;
  serumCreatinineUmolPerLMean: null | number;
  serumCreatinineUmolPerLSd: null | number;
  state: 'Draft' | 'Completed';
  tempPatientHoliday: null | number;
  tempPatientTemporary: null | number;
  timeOnDialysisMean: null | number;
  timeOnDialysisSd: null | number;
  timeTreatmentBe10p5AndL12: null | number;
  timeTreatmentBe12: null | number;
  timeTreatmentBe9AndL10p5: null | number;
  timeTreatmentL9: null | number;
  timeTreatmentMean: null | number;
  timeTreatmentNotDone: null | number;
  timeTreatmentSd: null | number;
  transferrinSaturationB30: null | number;
  transferrinSaturationBe20AndLe30: null | number;
  transferrinSaturationL20: null | number;
  transferrinSaturationMean: null | number;
  transferrinSaturationNotDone: null | number;
  transferrinSaturationSd: null | number;
  treatmentModalityHd: null | number;
  treatmentModalityHdf: null | number;
  treatmentModalityHdfPost: null | number;
  treatmentModalityHdfPre: null | number;
  treatmentsOwnPatients: null | number;
  treatmentsTempPatients: null | number;
  waterQualityBacteriologyFirstResult: null | number;
  waterQualityBacteriologyNotDone: null | boolean;
  waterQualityEndotoxinFirstResult: null | number;
  waterQualityEndotoxinNotDone: null | boolean;
  year: number;
};

export interface AisReportModelCreate {
  attributes?: Partial<{
    ageMean: BavReportingJsonApiTypes['ais-report']['ageMean'];
    ageSd: BavReportingJsonApiTypes['ais-report']['ageSd'];
    albuminBe25AndL35: BavReportingJsonApiTypes['ais-report']['albuminBe25AndL35'];
    albuminBe35: BavReportingJsonApiTypes['ais-report']['albuminBe35'];
    albuminGPerLBcGreenMean: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcGreenMean'];
    albuminGPerLBcGreenSd: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcGreenSd'];
    albuminGPerLBcPurpleMean: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcPurpleMean'];
    albuminGPerLBcPurpleSd: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcPurpleSd'];
    albuminL25: BavReportingJsonApiTypes['ais-report']['albuminL25'];
    albuminNotDone: BavReportingJsonApiTypes['ais-report']['albuminNotDone'];
    bloodFlowMean: BavReportingJsonApiTypes['ais-report']['bloodFlowMean'];
    bloodFlowSd: BavReportingJsonApiTypes['ais-report']['bloodFlowSd'];
    bloodPressureDiastolicBe90: BavReportingJsonApiTypes['ais-report']['bloodPressureDiastolicBe90'];
    bloodPressureDiastolicL90: BavReportingJsonApiTypes['ais-report']['bloodPressureDiastolicL90'];
    bloodPressureDiastolicNotDone: BavReportingJsonApiTypes['ais-report']['bloodPressureDiastolicNotDone'];
    bloodPressureSystolicBe140: BavReportingJsonApiTypes['ais-report']['bloodPressureSystolicBe140'];
    bloodPressureSystolicL140: BavReportingJsonApiTypes['ais-report']['bloodPressureSystolicL140'];
    bloodPressureSystolicNotDone: BavReportingJsonApiTypes['ais-report']['bloodPressureSystolicNotDone'];
    bmiBe20AndL25: BavReportingJsonApiTypes['ais-report']['bmiBe20AndL25'];
    bmiBe25AndL30: BavReportingJsonApiTypes['ais-report']['bmiBe25AndL30'];
    bmiBe30: BavReportingJsonApiTypes['ais-report']['bmiBe30'];
    bmiL20: BavReportingJsonApiTypes['ais-report']['bmiL20'];
    bmiMean: BavReportingJsonApiTypes['ais-report']['bmiMean'];
    bmiNotDone: BavReportingJsonApiTypes['ais-report']['bmiNotDone'];
    bmiSd: BavReportingJsonApiTypes['ais-report']['bmiSd'];
    calciumB10p5: BavReportingJsonApiTypes['ais-report']['calciumB10p5'];
    calciumBe8p4AndLe10p5: BavReportingJsonApiTypes['ais-report']['calciumBe8p4AndLe10p5'];
    calciumL8p4: BavReportingJsonApiTypes['ais-report']['calciumL8p4'];
    calciumMgPerDlMean: BavReportingJsonApiTypes['ais-report']['calciumMgPerDlMean'];
    calciumMgPerDlSd: BavReportingJsonApiTypes['ais-report']['calciumMgPerDlSd'];
    calciumMmolPerLMean: BavReportingJsonApiTypes['ais-report']['calciumMmolPerLMean'];
    calciumMmolPerLSd: BavReportingJsonApiTypes['ais-report']['calciumMmolPerLSd'];
    calciumNotDone: BavReportingJsonApiTypes['ais-report']['calciumNotDone'];
    charlsonIndexBe8Patient: BavReportingJsonApiTypes['ais-report']['charlsonIndexBe8Patient'];
    charlsonIndexBe8Sum: BavReportingJsonApiTypes['ais-report']['charlsonIndexBe8Sum'];
    charlsonIndexL8Patient: BavReportingJsonApiTypes['ais-report']['charlsonIndexL8Patient'];
    charlsonIndexL8Sum: BavReportingJsonApiTypes['ais-report']['charlsonIndexL8Sum'];
    comorbiditiesCerebrovascularDisease: BavReportingJsonApiTypes['ais-report']['comorbiditiesCerebrovascularDisease'];
    comorbiditiesCongestiveHeartFailure: BavReportingJsonApiTypes['ais-report']['comorbiditiesCongestiveHeartFailure'];
    comorbiditiesDiabetesChronicComplication: BavReportingJsonApiTypes['ais-report']['comorbiditiesDiabetesChronicComplication'];
    comorbiditiesMalignancy: BavReportingJsonApiTypes['ais-report']['comorbiditiesMalignancy'];
    comorbiditiesMetastaticSolidTumor: BavReportingJsonApiTypes['ais-report']['comorbiditiesMetastaticSolidTumor'];
    comorbiditiesModerateSevereLiverDisease: BavReportingJsonApiTypes['ais-report']['comorbiditiesModerateSevereLiverDisease'];
    comorbiditiesMyocardialInfarction: BavReportingJsonApiTypes['ais-report']['comorbiditiesMyocardialInfarction'];
    comorbiditiesPeripheralVascularDisease: BavReportingJsonApiTypes['ais-report']['comorbiditiesPeripheralVascularDisease'];
    convectiveVolumeBe23: BavReportingJsonApiTypes['ais-report']['convectiveVolumeBe23'];
    convectiveVolumeL23: BavReportingJsonApiTypes['ais-report']['convectiveVolumeL23'];
    convectiveVolumeMean: BavReportingJsonApiTypes['ais-report']['convectiveVolumeMean'];
    convectiveVolumeNotDone: BavReportingJsonApiTypes['ais-report']['convectiveVolumeNotDone'];
    convectiveVolumeSd: BavReportingJsonApiTypes['ais-report']['convectiveVolumeSd'];
    crpElevated: BavReportingJsonApiTypes['ais-report']['crpElevated'];
    crpNotDone: BavReportingJsonApiTypes['ais-report']['crpNotDone'];
    crpWithinLimits: BavReportingJsonApiTypes['ais-report']['crpWithinLimits'];
    dialysisFreqBe3: BavReportingJsonApiTypes['ais-report']['dialysisFreqBe3'];
    dialysisFreqL3: BavReportingJsonApiTypes['ais-report']['dialysisFreqL3'];
    dialysisFreqMean: BavReportingJsonApiTypes['ais-report']['dialysisFreqMean'];
    dialysisFreqNotDone: BavReportingJsonApiTypes['ais-report']['dialysisFreqNotDone'];
    dialysisFreqSd: BavReportingJsonApiTypes['ais-report']['dialysisFreqSd'];
    dryBodyWeightMean: BavReportingJsonApiTypes['ais-report']['dryBodyWeightMean'];
    dryBodyWeightSd: BavReportingJsonApiTypes['ais-report']['dryBodyWeightSd'];
    ektvBe1AndL1p2: BavReportingJsonApiTypes['ais-report']['ektvBe1AndL1p2'];
    ektvBe1p2: BavReportingJsonApiTypes['ais-report']['ektvBe1p2'];
    ektvL1: BavReportingJsonApiTypes['ais-report']['ektvL1'];
    ektvMean: BavReportingJsonApiTypes['ais-report']['ektvMean'];
    ektvNotDone: BavReportingJsonApiTypes['ais-report']['ektvNotDone'];
    ektvSd: BavReportingJsonApiTypes['ais-report']['ektvSd'];
    epoDose: BavReportingJsonApiTypes['ais-report']['epoDose'];
    ferritinB500: BavReportingJsonApiTypes['ais-report']['ferritinB500'];
    ferritinBe200AndLe500: BavReportingJsonApiTypes['ais-report']['ferritinBe200AndLe500'];
    ferritinL200: BavReportingJsonApiTypes['ais-report']['ferritinL200'];
    ferritinMean: BavReportingJsonApiTypes['ais-report']['ferritinMean'];
    ferritinNotDone: BavReportingJsonApiTypes['ais-report']['ferritinNotDone'];
    ferritinSd: BavReportingJsonApiTypes['ais-report']['ferritinSd'];
    genderFemale: BavReportingJsonApiTypes['ais-report']['genderFemale'];
    genderMale: BavReportingJsonApiTypes['ais-report']['genderMale'];
    handHygieneScore: BavReportingJsonApiTypes['ais-report']['handHygieneScore'];
    hdInfectionAvfBacteriaMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfBacteriaMrsaInfection'];
    hdInfectionAvfBacteriaOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfBacteriaOtherInfection'];
    hdInfectionAvfExitSiteMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfExitSiteMrsaInfection'];
    hdInfectionAvfExitSiteOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfExitSiteOtherInfection'];
    hdInfectionAvfPatientNum: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfPatientNum'];
    hdInfectionCvcBacteriaMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcBacteriaMrsaInfection'];
    hdInfectionCvcBacteriaOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcBacteriaOtherInfection'];
    hdInfectionCvcExitSiteMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcExitSiteMrsaInfection'];
    hdInfectionCvcExitSiteOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcExitSiteOtherInfection'];
    hdInfectionCvcPatientNum: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcPatientNum'];
    hdInfectionGraftBacteriaMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftBacteriaMrsaInfection'];
    hdInfectionGraftBacteriaOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftBacteriaOtherInfection'];
    hdInfectionGraftExitSiteMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftExitSiteMrsaInfection'];
    hdInfectionGraftExitSiteOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftExitSiteOtherInfection'];
    hdInfectionGraftPatientNum: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftPatientNum'];
    hdInfectionHbvPlus: BavReportingJsonApiTypes['ais-report']['hdInfectionHbvPlus'];
    hdInfectionHcvPlus: BavReportingJsonApiTypes['ais-report']['hdInfectionHcvPlus'];
    hdInfectionHivPlus: BavReportingJsonApiTypes['ais-report']['hdInfectionHivPlus'];
    hdTransplantWaitingPatient: BavReportingJsonApiTypes['ais-report']['hdTransplantWaitingPatient'];
    heightMean: BavReportingJsonApiTypes['ais-report']['heightMean'];
    heightSd: BavReportingJsonApiTypes['ais-report']['heightSd'];
    hemoglobinB12: BavReportingJsonApiTypes['ais-report']['hemoglobinB12'];
    hemoglobinBe10AndLe12: BavReportingJsonApiTypes['ais-report']['hemoglobinBe10AndLe12'];
    hemoglobinGPerDlMean: BavReportingJsonApiTypes['ais-report']['hemoglobinGPerDlMean'];
    hemoglobinGPerDlSd: BavReportingJsonApiTypes['ais-report']['hemoglobinGPerDlSd'];
    hemoglobinL10: BavReportingJsonApiTypes['ais-report']['hemoglobinL10'];
    hemoglobinMmolPerLMean: BavReportingJsonApiTypes['ais-report']['hemoglobinMmolPerLMean'];
    hemoglobinMmolPerLSd: BavReportingJsonApiTypes['ais-report']['hemoglobinMmolPerLSd'];
    hemoglobinNotDone: BavReportingJsonApiTypes['ais-report']['hemoglobinNotDone'];
    heparinBemi: BavReportingJsonApiTypes['ais-report']['heparinBemi'];
    heparinBemiPatients: BavReportingJsonApiTypes['ais-report']['heparinBemiPatients'];
    heparinDalt: BavReportingJsonApiTypes['ais-report']['heparinDalt'];
    heparinDaltPatients: BavReportingJsonApiTypes['ais-report']['heparinDaltPatients'];
    heparinEnoxA: BavReportingJsonApiTypes['ais-report']['heparinEnoxA'];
    heparinEnoxAPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxAPatients'];
    heparinEnoxC: BavReportingJsonApiTypes['ais-report']['heparinEnoxC'];
    heparinEnoxCPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxCPatients'];
    heparinEnoxE: BavReportingJsonApiTypes['ais-report']['heparinEnoxE'];
    heparinEnoxEPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxEPatients'];
    heparinEnoxG: BavReportingJsonApiTypes['ais-report']['heparinEnoxG'];
    heparinEnoxGPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxGPatients'];
    heparinEnoxL: BavReportingJsonApiTypes['ais-report']['heparinEnoxL'];
    heparinEnoxLPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxLPatients'];
    heparinHeparinlessTreatment: BavReportingJsonApiTypes['ais-report']['heparinHeparinlessTreatment'];
    heparinNadr: BavReportingJsonApiTypes['ais-report']['heparinNadr'];
    heparinNadrPatients: BavReportingJsonApiTypes['ais-report']['heparinNadrPatients'];
    heparinStandard: BavReportingJsonApiTypes['ais-report']['heparinStandard'];
    heparinStandardPatients: BavReportingJsonApiTypes['ais-report']['heparinStandardPatients'];
    heparinTinz: BavReportingJsonApiTypes['ais-report']['heparinTinz'];
    heparinTinzPatients: BavReportingJsonApiTypes['ais-report']['heparinTinzPatients'];
    hospCardiologyDays: BavReportingJsonApiTypes['ais-report']['hospCardiologyDays'];
    hospCardiologyHosp: BavReportingJsonApiTypes['ais-report']['hospCardiologyHosp'];
    hospCardiologyMissedSessions: BavReportingJsonApiTypes['ais-report']['hospCardiologyMissedSessions'];
    hospCardiologyPatient: BavReportingJsonApiTypes['ais-report']['hospCardiologyPatient'];
    hospInfectionsDays: BavReportingJsonApiTypes['ais-report']['hospInfectionsDays'];
    hospInfectionsHosp: BavReportingJsonApiTypes['ais-report']['hospInfectionsHosp'];
    hospInfectionsMissedSessions: BavReportingJsonApiTypes['ais-report']['hospInfectionsMissedSessions'];
    hospInfectionsPatient: BavReportingJsonApiTypes['ais-report']['hospInfectionsPatient'];
    hospOtherDays: BavReportingJsonApiTypes['ais-report']['hospOtherDays'];
    hospOtherHosp: BavReportingJsonApiTypes['ais-report']['hospOtherHosp'];
    hospOtherMissedSessions: BavReportingJsonApiTypes['ais-report']['hospOtherMissedSessions'];
    hospOtherPatient: BavReportingJsonApiTypes['ais-report']['hospOtherPatient'];
    hospVascularAccessDays: BavReportingJsonApiTypes['ais-report']['hospVascularAccessDays'];
    hospVascularAccessHosp: BavReportingJsonApiTypes['ais-report']['hospVascularAccessHosp'];
    hospVascularAccessMissedSessions: BavReportingJsonApiTypes['ais-report']['hospVascularAccessMissedSessions'];
    hospVascularAccessPatient: BavReportingJsonApiTypes['ais-report']['hospVascularAccessPatient'];
    intactParathormonB800: BavReportingJsonApiTypes['ais-report']['intactParathormonB800'];
    intactParathormonBe100AndLe800: BavReportingJsonApiTypes['ais-report']['intactParathormonBe100AndLe800'];
    intactParathormonL100: BavReportingJsonApiTypes['ais-report']['intactParathormonL100'];
    intactParathormonNotDone: BavReportingJsonApiTypes['ais-report']['intactParathormonNotDone'];
    intactParathormonPgPerMlMean: BavReportingJsonApiTypes['ais-report']['intactParathormonPgPerMlMean'];
    intactParathormonPgPerMlSd: BavReportingJsonApiTypes['ais-report']['intactParathormonPgPerMlSd'];
    intactParathormonPmolPerLMean: BavReportingJsonApiTypes['ais-report']['intactParathormonPmolPerLMean'];
    intactParathormonPmolPerLSd: BavReportingJsonApiTypes['ais-report']['intactParathormonPmolPerLSd'];
    medicationEsa: BavReportingJsonApiTypes['ais-report']['medicationEsa'];
    medicationIviron: BavReportingJsonApiTypes['ais-report']['medicationIviron'];
    nutritionEiEnteral: BavReportingJsonApiTypes['ais-report']['nutritionEiEnteral'];
    nutritionEiIdpn: BavReportingJsonApiTypes['ais-report']['nutritionEiIdpn'];
    nutritionEiNotTreated: BavReportingJsonApiTypes['ais-report']['nutritionEiNotTreated'];
    nutritionSgaA: BavReportingJsonApiTypes['ais-report']['nutritionSgaA'];
    nutritionSgaB: BavReportingJsonApiTypes['ais-report']['nutritionSgaB'];
    nutritionSgaC: BavReportingJsonApiTypes['ais-report']['nutritionSgaC'];
    nutritionSgaNotDone: BavReportingJsonApiTypes['ais-report']['nutritionSgaNotDone'];
    ownPatientAllLastMonth: BavReportingJsonApiTypes['ais-report']['ownPatientAllLastMonth'];
    ownPatientHemoDialysis3Month: BavReportingJsonApiTypes['ais-report']['ownPatientHemoDialysis3Month'];
    ownPatientNewAdmission: BavReportingJsonApiTypes['ais-report']['ownPatientNewAdmission'];
    ownPatientNewBBraunAvitumCenter: BavReportingJsonApiTypes['ais-report']['ownPatientNewBBraunAvitumCenter'];
    ownPatientNewBackTransp: BavReportingJsonApiTypes['ais-report']['ownPatientNewBackTransp'];
    ownPatientNewOtherCenter: BavReportingJsonApiTypes['ais-report']['ownPatientNewOtherCenter'];
    ownPatientNewPdToHd: BavReportingJsonApiTypes['ais-report']['ownPatientNewPdToHd'];
    ownPatientResignedBBraunAvitumCenter: BavReportingJsonApiTypes['ais-report']['ownPatientResignedBBraunAvitumCenter'];
    ownPatientResignedExitusChronicCardiovascular: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicCardiovascular'];
    ownPatientResignedExitusChronicCerebrovascular: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicCerebrovascular'];
    ownPatientResignedExitusChronicInfection: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicInfection'];
    ownPatientResignedExitusChronicMalignancy: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicMalignancy'];
    ownPatientResignedExitusChronicOther: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicOther'];
    ownPatientResignedExitusNonchronic: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusNonchronic'];
    ownPatientResignedHdToPd: BavReportingJsonApiTypes['ais-report']['ownPatientResignedHdToPd'];
    ownPatientResignedOtherCenter: BavReportingJsonApiTypes['ais-report']['ownPatientResignedOtherCenter'];
    ownPatientResignedQuitted: BavReportingJsonApiTypes['ais-report']['ownPatientResignedQuitted'];
    ownPatientResignedTransp: BavReportingJsonApiTypes['ais-report']['ownPatientResignedTransp'];
    phosphateBe3p5AndL5p5: BavReportingJsonApiTypes['ais-report']['phosphateBe3p5AndL5p5'];
    phosphateBe5p5: BavReportingJsonApiTypes['ais-report']['phosphateBe5p5'];
    phosphateL3p5: BavReportingJsonApiTypes['ais-report']['phosphateL3p5'];
    phosphateMgPerDlMean: BavReportingJsonApiTypes['ais-report']['phosphateMgPerDlMean'];
    phosphateMgPerDlSd: BavReportingJsonApiTypes['ais-report']['phosphateMgPerDlSd'];
    phosphateMmolPerLMean: BavReportingJsonApiTypes['ais-report']['phosphateMmolPerLMean'];
    phosphateMmolPerLSd: BavReportingJsonApiTypes['ais-report']['phosphateMmolPerLSd'];
    phosphateNotDone: BavReportingJsonApiTypes['ais-report']['phosphateNotDone'];
    primaryRenalDiseaseDiabetesNephropathy: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseDiabetesNephropathy'];
    primaryRenalDiseaseGlomerulonephritis: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseGlomerulonephritis'];
    primaryRenalDiseaseHypertension: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseHypertension'];
    primaryRenalDiseaseOther: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseOther'];
    primaryRenalDiseasePolycystic: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseasePolycystic'];
    primaryRenalDiseaseTubulointerstitial: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseTubulointerstitial'];
    primaryRenalDiseaseVascular: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseVascular'];
    seroconversionHbvChronic: BavReportingJsonApiTypes['ais-report']['seroconversionHbvChronic'];
    seroconversionHbvNew: BavReportingJsonApiTypes['ais-report']['seroconversionHbvNew'];
    seroconversionHcvChronic: BavReportingJsonApiTypes['ais-report']['seroconversionHcvChronic'];
    seroconversionHcvNew: BavReportingJsonApiTypes['ais-report']['seroconversionHcvNew'];
    seroconversionHivChronic: BavReportingJsonApiTypes['ais-report']['seroconversionHivChronic'];
    seroconversionHivNew: BavReportingJsonApiTypes['ais-report']['seroconversionHivNew'];
    serumCreatinineBe8: BavReportingJsonApiTypes['ais-report']['serumCreatinineBe8'];
    serumCreatinineL8: BavReportingJsonApiTypes['ais-report']['serumCreatinineL8'];
    serumCreatinineMgPerDlMean: BavReportingJsonApiTypes['ais-report']['serumCreatinineMgPerDlMean'];
    serumCreatinineMgPerDlSd: BavReportingJsonApiTypes['ais-report']['serumCreatinineMgPerDlSd'];
    serumCreatinineNotDone: BavReportingJsonApiTypes['ais-report']['serumCreatinineNotDone'];
    serumCreatinineUmolPerLMean: BavReportingJsonApiTypes['ais-report']['serumCreatinineUmolPerLMean'];
    serumCreatinineUmolPerLSd: BavReportingJsonApiTypes['ais-report']['serumCreatinineUmolPerLSd'];
    tempPatientHoliday: BavReportingJsonApiTypes['ais-report']['tempPatientHoliday'];
    tempPatientTemporary: BavReportingJsonApiTypes['ais-report']['tempPatientTemporary'];
    timeOnDialysisMean: BavReportingJsonApiTypes['ais-report']['timeOnDialysisMean'];
    timeOnDialysisSd: BavReportingJsonApiTypes['ais-report']['timeOnDialysisSd'];
    timeTreatmentBe10p5AndL12: BavReportingJsonApiTypes['ais-report']['timeTreatmentBe10p5AndL12'];
    timeTreatmentBe12: BavReportingJsonApiTypes['ais-report']['timeTreatmentBe12'];
    timeTreatmentBe9AndL10p5: BavReportingJsonApiTypes['ais-report']['timeTreatmentBe9AndL10p5'];
    timeTreatmentL9: BavReportingJsonApiTypes['ais-report']['timeTreatmentL9'];
    timeTreatmentMean: BavReportingJsonApiTypes['ais-report']['timeTreatmentMean'];
    timeTreatmentNotDone: BavReportingJsonApiTypes['ais-report']['timeTreatmentNotDone'];
    timeTreatmentSd: BavReportingJsonApiTypes['ais-report']['timeTreatmentSd'];
    transferrinSaturationB30: BavReportingJsonApiTypes['ais-report']['transferrinSaturationB30'];
    transferrinSaturationBe20AndLe30: BavReportingJsonApiTypes['ais-report']['transferrinSaturationBe20AndLe30'];
    transferrinSaturationL20: BavReportingJsonApiTypes['ais-report']['transferrinSaturationL20'];
    transferrinSaturationMean: BavReportingJsonApiTypes['ais-report']['transferrinSaturationMean'];
    transferrinSaturationNotDone: BavReportingJsonApiTypes['ais-report']['transferrinSaturationNotDone'];
    transferrinSaturationSd: BavReportingJsonApiTypes['ais-report']['transferrinSaturationSd'];
    treatmentModalityHd: BavReportingJsonApiTypes['ais-report']['treatmentModalityHd'];
    treatmentModalityHdf: BavReportingJsonApiTypes['ais-report']['treatmentModalityHdf'];
    treatmentModalityHdfPost: BavReportingJsonApiTypes['ais-report']['treatmentModalityHdfPost'];
    treatmentModalityHdfPre: BavReportingJsonApiTypes['ais-report']['treatmentModalityHdfPre'];
    treatmentsOwnPatients: BavReportingJsonApiTypes['ais-report']['treatmentsOwnPatients'];
    treatmentsTempPatients: BavReportingJsonApiTypes['ais-report']['treatmentsTempPatients'];
    waterQualityBacteriologyFirstResult: BavReportingJsonApiTypes['ais-report']['waterQualityBacteriologyFirstResult'];
    waterQualityBacteriologyNotDone: BavReportingJsonApiTypes['ais-report']['waterQualityBacteriologyNotDone'];
    waterQualityEndotoxinFirstResult: BavReportingJsonApiTypes['ais-report']['waterQualityEndotoxinFirstResult'];
    waterQualityEndotoxinNotDone: BavReportingJsonApiTypes['ais-report']['waterQualityEndotoxinNotDone'];
  }> & {
    centerId: BavReportingJsonApiTypes['ais-report']['centerId'];
    month: BavReportingJsonApiTypes['ais-report']['month'];
    state: BavReportingJsonApiTypes['ais-report']['state'];
    year: BavReportingJsonApiTypes['ais-report']['year'];
  };
  relationships?: undefined;
}

const aisReportModelCreateProperties = {
  attributes: [
    'ageMean',
    'ageSd',
    'albuminBe25AndL35',
    'albuminBe35',
    'albuminGPerLBcGreenMean',
    'albuminGPerLBcGreenSd',
    'albuminGPerLBcPurpleMean',
    'albuminGPerLBcPurpleSd',
    'albuminL25',
    'albuminNotDone',
    'bloodFlowMean',
    'bloodFlowSd',
    'bloodPressureDiastolicBe90',
    'bloodPressureDiastolicL90',
    'bloodPressureDiastolicNotDone',
    'bloodPressureSystolicBe140',
    'bloodPressureSystolicL140',
    'bloodPressureSystolicNotDone',
    'bmiBe20AndL25',
    'bmiBe25AndL30',
    'bmiBe30',
    'bmiL20',
    'bmiMean',
    'bmiNotDone',
    'bmiSd',
    'calciumB10p5',
    'calciumBe8p4AndLe10p5',
    'calciumL8p4',
    'calciumMgPerDlMean',
    'calciumMgPerDlSd',
    'calciumMmolPerLMean',
    'calciumMmolPerLSd',
    'calciumNotDone',
    'charlsonIndexBe8Patient',
    'charlsonIndexBe8Sum',
    'charlsonIndexL8Patient',
    'charlsonIndexL8Sum',
    'comorbiditiesCerebrovascularDisease',
    'comorbiditiesCongestiveHeartFailure',
    'comorbiditiesDiabetesChronicComplication',
    'comorbiditiesMalignancy',
    'comorbiditiesMetastaticSolidTumor',
    'comorbiditiesModerateSevereLiverDisease',
    'comorbiditiesMyocardialInfarction',
    'comorbiditiesPeripheralVascularDisease',
    'convectiveVolumeBe23',
    'convectiveVolumeL23',
    'convectiveVolumeMean',
    'convectiveVolumeNotDone',
    'convectiveVolumeSd',
    'crpElevated',
    'crpNotDone',
    'crpWithinLimits',
    'dialysisFreqBe3',
    'dialysisFreqL3',
    'dialysisFreqMean',
    'dialysisFreqNotDone',
    'dialysisFreqSd',
    'dryBodyWeightMean',
    'dryBodyWeightSd',
    'ektvBe1AndL1p2',
    'ektvBe1p2',
    'ektvL1',
    'ektvMean',
    'ektvNotDone',
    'ektvSd',
    'epoDose',
    'ferritinB500',
    'ferritinBe200AndLe500',
    'ferritinL200',
    'ferritinMean',
    'ferritinNotDone',
    'ferritinSd',
    'genderFemale',
    'genderMale',
    'handHygieneScore',
    'hdInfectionAvfBacteriaMrsaInfection',
    'hdInfectionAvfBacteriaOtherInfection',
    'hdInfectionAvfExitSiteMrsaInfection',
    'hdInfectionAvfExitSiteOtherInfection',
    'hdInfectionAvfPatientNum',
    'hdInfectionCvcBacteriaMrsaInfection',
    'hdInfectionCvcBacteriaOtherInfection',
    'hdInfectionCvcExitSiteMrsaInfection',
    'hdInfectionCvcExitSiteOtherInfection',
    'hdInfectionCvcPatientNum',
    'hdInfectionGraftBacteriaMrsaInfection',
    'hdInfectionGraftBacteriaOtherInfection',
    'hdInfectionGraftExitSiteMrsaInfection',
    'hdInfectionGraftExitSiteOtherInfection',
    'hdInfectionGraftPatientNum',
    'hdInfectionHbvPlus',
    'hdInfectionHcvPlus',
    'hdInfectionHivPlus',
    'hdTransplantWaitingPatient',
    'heightMean',
    'heightSd',
    'hemoglobinB12',
    'hemoglobinBe10AndLe12',
    'hemoglobinGPerDlMean',
    'hemoglobinGPerDlSd',
    'hemoglobinL10',
    'hemoglobinMmolPerLMean',
    'hemoglobinMmolPerLSd',
    'hemoglobinNotDone',
    'heparinBemi',
    'heparinBemiPatients',
    'heparinDalt',
    'heparinDaltPatients',
    'heparinEnoxA',
    'heparinEnoxAPatients',
    'heparinEnoxC',
    'heparinEnoxCPatients',
    'heparinEnoxE',
    'heparinEnoxEPatients',
    'heparinEnoxG',
    'heparinEnoxGPatients',
    'heparinEnoxL',
    'heparinEnoxLPatients',
    'heparinHeparinlessTreatment',
    'heparinNadr',
    'heparinNadrPatients',
    'heparinStandard',
    'heparinStandardPatients',
    'heparinTinz',
    'heparinTinzPatients',
    'hospCardiologyDays',
    'hospCardiologyHosp',
    'hospCardiologyMissedSessions',
    'hospCardiologyPatient',
    'hospInfectionsDays',
    'hospInfectionsHosp',
    'hospInfectionsMissedSessions',
    'hospInfectionsPatient',
    'hospOtherDays',
    'hospOtherHosp',
    'hospOtherMissedSessions',
    'hospOtherPatient',
    'hospVascularAccessDays',
    'hospVascularAccessHosp',
    'hospVascularAccessMissedSessions',
    'hospVascularAccessPatient',
    'intactParathormonB800',
    'intactParathormonBe100AndLe800',
    'intactParathormonL100',
    'intactParathormonNotDone',
    'intactParathormonPgPerMlMean',
    'intactParathormonPgPerMlSd',
    'intactParathormonPmolPerLMean',
    'intactParathormonPmolPerLSd',
    'medicationEsa',
    'medicationIviron',
    'nutritionEiEnteral',
    'nutritionEiIdpn',
    'nutritionEiNotTreated',
    'nutritionSgaA',
    'nutritionSgaB',
    'nutritionSgaC',
    'nutritionSgaNotDone',
    'ownPatientAllLastMonth',
    'ownPatientHemoDialysis3Month',
    'ownPatientNewAdmission',
    'ownPatientNewBBraunAvitumCenter',
    'ownPatientNewBackTransp',
    'ownPatientNewOtherCenter',
    'ownPatientNewPdToHd',
    'ownPatientResignedBBraunAvitumCenter',
    'ownPatientResignedExitusChronicCardiovascular',
    'ownPatientResignedExitusChronicCerebrovascular',
    'ownPatientResignedExitusChronicInfection',
    'ownPatientResignedExitusChronicMalignancy',
    'ownPatientResignedExitusChronicOther',
    'ownPatientResignedExitusNonchronic',
    'ownPatientResignedHdToPd',
    'ownPatientResignedOtherCenter',
    'ownPatientResignedQuitted',
    'ownPatientResignedTransp',
    'phosphateBe3p5AndL5p5',
    'phosphateBe5p5',
    'phosphateL3p5',
    'phosphateMgPerDlMean',
    'phosphateMgPerDlSd',
    'phosphateMmolPerLMean',
    'phosphateMmolPerLSd',
    'phosphateNotDone',
    'primaryRenalDiseaseDiabetesNephropathy',
    'primaryRenalDiseaseGlomerulonephritis',
    'primaryRenalDiseaseHypertension',
    'primaryRenalDiseaseOther',
    'primaryRenalDiseasePolycystic',
    'primaryRenalDiseaseTubulointerstitial',
    'primaryRenalDiseaseVascular',
    'seroconversionHbvChronic',
    'seroconversionHbvNew',
    'seroconversionHcvChronic',
    'seroconversionHcvNew',
    'seroconversionHivChronic',
    'seroconversionHivNew',
    'serumCreatinineBe8',
    'serumCreatinineL8',
    'serumCreatinineMgPerDlMean',
    'serumCreatinineMgPerDlSd',
    'serumCreatinineNotDone',
    'serumCreatinineUmolPerLMean',
    'serumCreatinineUmolPerLSd',
    'tempPatientHoliday',
    'tempPatientTemporary',
    'timeOnDialysisMean',
    'timeOnDialysisSd',
    'timeTreatmentBe10p5AndL12',
    'timeTreatmentBe12',
    'timeTreatmentBe9AndL10p5',
    'timeTreatmentL9',
    'timeTreatmentMean',
    'timeTreatmentNotDone',
    'timeTreatmentSd',
    'transferrinSaturationB30',
    'transferrinSaturationBe20AndLe30',
    'transferrinSaturationL20',
    'transferrinSaturationMean',
    'transferrinSaturationNotDone',
    'transferrinSaturationSd',
    'treatmentModalityHd',
    'treatmentModalityHdf',
    'treatmentModalityHdfPost',
    'treatmentModalityHdfPre',
    'treatmentsOwnPatients',
    'treatmentsTempPatients',
    'waterQualityBacteriologyFirstResult',
    'waterQualityBacteriologyNotDone',
    'waterQualityEndotoxinFirstResult',
    'waterQualityEndotoxinNotDone',
    'centerId',
    'month',
    'state',
    'year',
  ],
  relationships: undefined,
} as const;

export interface AisReportModelUpdate {
  attributes?: Partial<{
    ageMean: BavReportingJsonApiTypes['ais-report']['ageMean'];
    ageSd: BavReportingJsonApiTypes['ais-report']['ageSd'];
    albuminBe25AndL35: BavReportingJsonApiTypes['ais-report']['albuminBe25AndL35'];
    albuminBe35: BavReportingJsonApiTypes['ais-report']['albuminBe35'];
    albuminGPerLBcGreenMean: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcGreenMean'];
    albuminGPerLBcGreenSd: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcGreenSd'];
    albuminGPerLBcPurpleMean: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcPurpleMean'];
    albuminGPerLBcPurpleSd: BavReportingJsonApiTypes['ais-report']['albuminGPerLBcPurpleSd'];
    albuminL25: BavReportingJsonApiTypes['ais-report']['albuminL25'];
    albuminNotDone: BavReportingJsonApiTypes['ais-report']['albuminNotDone'];
    bloodFlowMean: BavReportingJsonApiTypes['ais-report']['bloodFlowMean'];
    bloodFlowSd: BavReportingJsonApiTypes['ais-report']['bloodFlowSd'];
    bloodPressureDiastolicBe90: BavReportingJsonApiTypes['ais-report']['bloodPressureDiastolicBe90'];
    bloodPressureDiastolicL90: BavReportingJsonApiTypes['ais-report']['bloodPressureDiastolicL90'];
    bloodPressureDiastolicNotDone: BavReportingJsonApiTypes['ais-report']['bloodPressureDiastolicNotDone'];
    bloodPressureSystolicBe140: BavReportingJsonApiTypes['ais-report']['bloodPressureSystolicBe140'];
    bloodPressureSystolicL140: BavReportingJsonApiTypes['ais-report']['bloodPressureSystolicL140'];
    bloodPressureSystolicNotDone: BavReportingJsonApiTypes['ais-report']['bloodPressureSystolicNotDone'];
    bmiBe20AndL25: BavReportingJsonApiTypes['ais-report']['bmiBe20AndL25'];
    bmiBe25AndL30: BavReportingJsonApiTypes['ais-report']['bmiBe25AndL30'];
    bmiBe30: BavReportingJsonApiTypes['ais-report']['bmiBe30'];
    bmiL20: BavReportingJsonApiTypes['ais-report']['bmiL20'];
    bmiMean: BavReportingJsonApiTypes['ais-report']['bmiMean'];
    bmiNotDone: BavReportingJsonApiTypes['ais-report']['bmiNotDone'];
    bmiSd: BavReportingJsonApiTypes['ais-report']['bmiSd'];
    calciumB10p5: BavReportingJsonApiTypes['ais-report']['calciumB10p5'];
    calciumBe8p4AndLe10p5: BavReportingJsonApiTypes['ais-report']['calciumBe8p4AndLe10p5'];
    calciumL8p4: BavReportingJsonApiTypes['ais-report']['calciumL8p4'];
    calciumMgPerDlMean: BavReportingJsonApiTypes['ais-report']['calciumMgPerDlMean'];
    calciumMgPerDlSd: BavReportingJsonApiTypes['ais-report']['calciumMgPerDlSd'];
    calciumMmolPerLMean: BavReportingJsonApiTypes['ais-report']['calciumMmolPerLMean'];
    calciumMmolPerLSd: BavReportingJsonApiTypes['ais-report']['calciumMmolPerLSd'];
    calciumNotDone: BavReportingJsonApiTypes['ais-report']['calciumNotDone'];
    centerId: BavReportingJsonApiTypes['ais-report']['centerId'];
    charlsonIndexBe8Patient: BavReportingJsonApiTypes['ais-report']['charlsonIndexBe8Patient'];
    charlsonIndexBe8Sum: BavReportingJsonApiTypes['ais-report']['charlsonIndexBe8Sum'];
    charlsonIndexL8Patient: BavReportingJsonApiTypes['ais-report']['charlsonIndexL8Patient'];
    charlsonIndexL8Sum: BavReportingJsonApiTypes['ais-report']['charlsonIndexL8Sum'];
    comorbiditiesCerebrovascularDisease: BavReportingJsonApiTypes['ais-report']['comorbiditiesCerebrovascularDisease'];
    comorbiditiesCongestiveHeartFailure: BavReportingJsonApiTypes['ais-report']['comorbiditiesCongestiveHeartFailure'];
    comorbiditiesDiabetesChronicComplication: BavReportingJsonApiTypes['ais-report']['comorbiditiesDiabetesChronicComplication'];
    comorbiditiesMalignancy: BavReportingJsonApiTypes['ais-report']['comorbiditiesMalignancy'];
    comorbiditiesMetastaticSolidTumor: BavReportingJsonApiTypes['ais-report']['comorbiditiesMetastaticSolidTumor'];
    comorbiditiesModerateSevereLiverDisease: BavReportingJsonApiTypes['ais-report']['comorbiditiesModerateSevereLiverDisease'];
    comorbiditiesMyocardialInfarction: BavReportingJsonApiTypes['ais-report']['comorbiditiesMyocardialInfarction'];
    comorbiditiesPeripheralVascularDisease: BavReportingJsonApiTypes['ais-report']['comorbiditiesPeripheralVascularDisease'];
    convectiveVolumeBe23: BavReportingJsonApiTypes['ais-report']['convectiveVolumeBe23'];
    convectiveVolumeL23: BavReportingJsonApiTypes['ais-report']['convectiveVolumeL23'];
    convectiveVolumeMean: BavReportingJsonApiTypes['ais-report']['convectiveVolumeMean'];
    convectiveVolumeNotDone: BavReportingJsonApiTypes['ais-report']['convectiveVolumeNotDone'];
    convectiveVolumeSd: BavReportingJsonApiTypes['ais-report']['convectiveVolumeSd'];
    crpElevated: BavReportingJsonApiTypes['ais-report']['crpElevated'];
    crpNotDone: BavReportingJsonApiTypes['ais-report']['crpNotDone'];
    crpWithinLimits: BavReportingJsonApiTypes['ais-report']['crpWithinLimits'];
    dialysisFreqBe3: BavReportingJsonApiTypes['ais-report']['dialysisFreqBe3'];
    dialysisFreqL3: BavReportingJsonApiTypes['ais-report']['dialysisFreqL3'];
    dialysisFreqMean: BavReportingJsonApiTypes['ais-report']['dialysisFreqMean'];
    dialysisFreqNotDone: BavReportingJsonApiTypes['ais-report']['dialysisFreqNotDone'];
    dialysisFreqSd: BavReportingJsonApiTypes['ais-report']['dialysisFreqSd'];
    dryBodyWeightMean: BavReportingJsonApiTypes['ais-report']['dryBodyWeightMean'];
    dryBodyWeightSd: BavReportingJsonApiTypes['ais-report']['dryBodyWeightSd'];
    ektvBe1AndL1p2: BavReportingJsonApiTypes['ais-report']['ektvBe1AndL1p2'];
    ektvBe1p2: BavReportingJsonApiTypes['ais-report']['ektvBe1p2'];
    ektvL1: BavReportingJsonApiTypes['ais-report']['ektvL1'];
    ektvMean: BavReportingJsonApiTypes['ais-report']['ektvMean'];
    ektvNotDone: BavReportingJsonApiTypes['ais-report']['ektvNotDone'];
    ektvSd: BavReportingJsonApiTypes['ais-report']['ektvSd'];
    epoDose: BavReportingJsonApiTypes['ais-report']['epoDose'];
    ferritinB500: BavReportingJsonApiTypes['ais-report']['ferritinB500'];
    ferritinBe200AndLe500: BavReportingJsonApiTypes['ais-report']['ferritinBe200AndLe500'];
    ferritinL200: BavReportingJsonApiTypes['ais-report']['ferritinL200'];
    ferritinMean: BavReportingJsonApiTypes['ais-report']['ferritinMean'];
    ferritinNotDone: BavReportingJsonApiTypes['ais-report']['ferritinNotDone'];
    ferritinSd: BavReportingJsonApiTypes['ais-report']['ferritinSd'];
    genderFemale: BavReportingJsonApiTypes['ais-report']['genderFemale'];
    genderMale: BavReportingJsonApiTypes['ais-report']['genderMale'];
    handHygieneScore: BavReportingJsonApiTypes['ais-report']['handHygieneScore'];
    hdInfectionAvfBacteriaMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfBacteriaMrsaInfection'];
    hdInfectionAvfBacteriaOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfBacteriaOtherInfection'];
    hdInfectionAvfExitSiteMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfExitSiteMrsaInfection'];
    hdInfectionAvfExitSiteOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfExitSiteOtherInfection'];
    hdInfectionAvfPatientNum: BavReportingJsonApiTypes['ais-report']['hdInfectionAvfPatientNum'];
    hdInfectionCvcBacteriaMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcBacteriaMrsaInfection'];
    hdInfectionCvcBacteriaOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcBacteriaOtherInfection'];
    hdInfectionCvcExitSiteMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcExitSiteMrsaInfection'];
    hdInfectionCvcExitSiteOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcExitSiteOtherInfection'];
    hdInfectionCvcPatientNum: BavReportingJsonApiTypes['ais-report']['hdInfectionCvcPatientNum'];
    hdInfectionGraftBacteriaMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftBacteriaMrsaInfection'];
    hdInfectionGraftBacteriaOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftBacteriaOtherInfection'];
    hdInfectionGraftExitSiteMrsaInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftExitSiteMrsaInfection'];
    hdInfectionGraftExitSiteOtherInfection: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftExitSiteOtherInfection'];
    hdInfectionGraftPatientNum: BavReportingJsonApiTypes['ais-report']['hdInfectionGraftPatientNum'];
    hdInfectionHbvPlus: BavReportingJsonApiTypes['ais-report']['hdInfectionHbvPlus'];
    hdInfectionHcvPlus: BavReportingJsonApiTypes['ais-report']['hdInfectionHcvPlus'];
    hdInfectionHivPlus: BavReportingJsonApiTypes['ais-report']['hdInfectionHivPlus'];
    hdTransplantWaitingPatient: BavReportingJsonApiTypes['ais-report']['hdTransplantWaitingPatient'];
    heightMean: BavReportingJsonApiTypes['ais-report']['heightMean'];
    heightSd: BavReportingJsonApiTypes['ais-report']['heightSd'];
    hemoglobinB12: BavReportingJsonApiTypes['ais-report']['hemoglobinB12'];
    hemoglobinBe10AndLe12: BavReportingJsonApiTypes['ais-report']['hemoglobinBe10AndLe12'];
    hemoglobinGPerDlMean: BavReportingJsonApiTypes['ais-report']['hemoglobinGPerDlMean'];
    hemoglobinGPerDlSd: BavReportingJsonApiTypes['ais-report']['hemoglobinGPerDlSd'];
    hemoglobinL10: BavReportingJsonApiTypes['ais-report']['hemoglobinL10'];
    hemoglobinMmolPerLMean: BavReportingJsonApiTypes['ais-report']['hemoglobinMmolPerLMean'];
    hemoglobinMmolPerLSd: BavReportingJsonApiTypes['ais-report']['hemoglobinMmolPerLSd'];
    hemoglobinNotDone: BavReportingJsonApiTypes['ais-report']['hemoglobinNotDone'];
    heparinBemi: BavReportingJsonApiTypes['ais-report']['heparinBemi'];
    heparinBemiPatients: BavReportingJsonApiTypes['ais-report']['heparinBemiPatients'];
    heparinDalt: BavReportingJsonApiTypes['ais-report']['heparinDalt'];
    heparinDaltPatients: BavReportingJsonApiTypes['ais-report']['heparinDaltPatients'];
    heparinEnoxA: BavReportingJsonApiTypes['ais-report']['heparinEnoxA'];
    heparinEnoxAPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxAPatients'];
    heparinEnoxC: BavReportingJsonApiTypes['ais-report']['heparinEnoxC'];
    heparinEnoxCPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxCPatients'];
    heparinEnoxE: BavReportingJsonApiTypes['ais-report']['heparinEnoxE'];
    heparinEnoxEPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxEPatients'];
    heparinEnoxG: BavReportingJsonApiTypes['ais-report']['heparinEnoxG'];
    heparinEnoxGPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxGPatients'];
    heparinEnoxL: BavReportingJsonApiTypes['ais-report']['heparinEnoxL'];
    heparinEnoxLPatients: BavReportingJsonApiTypes['ais-report']['heparinEnoxLPatients'];
    heparinHeparinlessTreatment: BavReportingJsonApiTypes['ais-report']['heparinHeparinlessTreatment'];
    heparinNadr: BavReportingJsonApiTypes['ais-report']['heparinNadr'];
    heparinNadrPatients: BavReportingJsonApiTypes['ais-report']['heparinNadrPatients'];
    heparinStandard: BavReportingJsonApiTypes['ais-report']['heparinStandard'];
    heparinStandardPatients: BavReportingJsonApiTypes['ais-report']['heparinStandardPatients'];
    heparinTinz: BavReportingJsonApiTypes['ais-report']['heparinTinz'];
    heparinTinzPatients: BavReportingJsonApiTypes['ais-report']['heparinTinzPatients'];
    hospCardiologyDays: BavReportingJsonApiTypes['ais-report']['hospCardiologyDays'];
    hospCardiologyHosp: BavReportingJsonApiTypes['ais-report']['hospCardiologyHosp'];
    hospCardiologyMissedSessions: BavReportingJsonApiTypes['ais-report']['hospCardiologyMissedSessions'];
    hospCardiologyPatient: BavReportingJsonApiTypes['ais-report']['hospCardiologyPatient'];
    hospInfectionsDays: BavReportingJsonApiTypes['ais-report']['hospInfectionsDays'];
    hospInfectionsHosp: BavReportingJsonApiTypes['ais-report']['hospInfectionsHosp'];
    hospInfectionsMissedSessions: BavReportingJsonApiTypes['ais-report']['hospInfectionsMissedSessions'];
    hospInfectionsPatient: BavReportingJsonApiTypes['ais-report']['hospInfectionsPatient'];
    hospOtherDays: BavReportingJsonApiTypes['ais-report']['hospOtherDays'];
    hospOtherHosp: BavReportingJsonApiTypes['ais-report']['hospOtherHosp'];
    hospOtherMissedSessions: BavReportingJsonApiTypes['ais-report']['hospOtherMissedSessions'];
    hospOtherPatient: BavReportingJsonApiTypes['ais-report']['hospOtherPatient'];
    hospVascularAccessDays: BavReportingJsonApiTypes['ais-report']['hospVascularAccessDays'];
    hospVascularAccessHosp: BavReportingJsonApiTypes['ais-report']['hospVascularAccessHosp'];
    hospVascularAccessMissedSessions: BavReportingJsonApiTypes['ais-report']['hospVascularAccessMissedSessions'];
    hospVascularAccessPatient: BavReportingJsonApiTypes['ais-report']['hospVascularAccessPatient'];
    intactParathormonB800: BavReportingJsonApiTypes['ais-report']['intactParathormonB800'];
    intactParathormonBe100AndLe800: BavReportingJsonApiTypes['ais-report']['intactParathormonBe100AndLe800'];
    intactParathormonL100: BavReportingJsonApiTypes['ais-report']['intactParathormonL100'];
    intactParathormonNotDone: BavReportingJsonApiTypes['ais-report']['intactParathormonNotDone'];
    intactParathormonPgPerMlMean: BavReportingJsonApiTypes['ais-report']['intactParathormonPgPerMlMean'];
    intactParathormonPgPerMlSd: BavReportingJsonApiTypes['ais-report']['intactParathormonPgPerMlSd'];
    intactParathormonPmolPerLMean: BavReportingJsonApiTypes['ais-report']['intactParathormonPmolPerLMean'];
    intactParathormonPmolPerLSd: BavReportingJsonApiTypes['ais-report']['intactParathormonPmolPerLSd'];
    medicationEsa: BavReportingJsonApiTypes['ais-report']['medicationEsa'];
    medicationIviron: BavReportingJsonApiTypes['ais-report']['medicationIviron'];
    month: BavReportingJsonApiTypes['ais-report']['month'];
    nutritionEiEnteral: BavReportingJsonApiTypes['ais-report']['nutritionEiEnteral'];
    nutritionEiIdpn: BavReportingJsonApiTypes['ais-report']['nutritionEiIdpn'];
    nutritionEiNotTreated: BavReportingJsonApiTypes['ais-report']['nutritionEiNotTreated'];
    nutritionSgaA: BavReportingJsonApiTypes['ais-report']['nutritionSgaA'];
    nutritionSgaB: BavReportingJsonApiTypes['ais-report']['nutritionSgaB'];
    nutritionSgaC: BavReportingJsonApiTypes['ais-report']['nutritionSgaC'];
    nutritionSgaNotDone: BavReportingJsonApiTypes['ais-report']['nutritionSgaNotDone'];
    ownPatientAllLastMonth: BavReportingJsonApiTypes['ais-report']['ownPatientAllLastMonth'];
    ownPatientHemoDialysis3Month: BavReportingJsonApiTypes['ais-report']['ownPatientHemoDialysis3Month'];
    ownPatientNewAdmission: BavReportingJsonApiTypes['ais-report']['ownPatientNewAdmission'];
    ownPatientNewBBraunAvitumCenter: BavReportingJsonApiTypes['ais-report']['ownPatientNewBBraunAvitumCenter'];
    ownPatientNewBackTransp: BavReportingJsonApiTypes['ais-report']['ownPatientNewBackTransp'];
    ownPatientNewOtherCenter: BavReportingJsonApiTypes['ais-report']['ownPatientNewOtherCenter'];
    ownPatientNewPdToHd: BavReportingJsonApiTypes['ais-report']['ownPatientNewPdToHd'];
    ownPatientResignedBBraunAvitumCenter: BavReportingJsonApiTypes['ais-report']['ownPatientResignedBBraunAvitumCenter'];
    ownPatientResignedExitusChronicCardiovascular: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicCardiovascular'];
    ownPatientResignedExitusChronicCerebrovascular: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicCerebrovascular'];
    ownPatientResignedExitusChronicInfection: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicInfection'];
    ownPatientResignedExitusChronicMalignancy: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicMalignancy'];
    ownPatientResignedExitusChronicOther: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusChronicOther'];
    ownPatientResignedExitusNonchronic: BavReportingJsonApiTypes['ais-report']['ownPatientResignedExitusNonchronic'];
    ownPatientResignedHdToPd: BavReportingJsonApiTypes['ais-report']['ownPatientResignedHdToPd'];
    ownPatientResignedOtherCenter: BavReportingJsonApiTypes['ais-report']['ownPatientResignedOtherCenter'];
    ownPatientResignedQuitted: BavReportingJsonApiTypes['ais-report']['ownPatientResignedQuitted'];
    ownPatientResignedTransp: BavReportingJsonApiTypes['ais-report']['ownPatientResignedTransp'];
    phosphateBe3p5AndL5p5: BavReportingJsonApiTypes['ais-report']['phosphateBe3p5AndL5p5'];
    phosphateBe5p5: BavReportingJsonApiTypes['ais-report']['phosphateBe5p5'];
    phosphateL3p5: BavReportingJsonApiTypes['ais-report']['phosphateL3p5'];
    phosphateMgPerDlMean: BavReportingJsonApiTypes['ais-report']['phosphateMgPerDlMean'];
    phosphateMgPerDlSd: BavReportingJsonApiTypes['ais-report']['phosphateMgPerDlSd'];
    phosphateMmolPerLMean: BavReportingJsonApiTypes['ais-report']['phosphateMmolPerLMean'];
    phosphateMmolPerLSd: BavReportingJsonApiTypes['ais-report']['phosphateMmolPerLSd'];
    phosphateNotDone: BavReportingJsonApiTypes['ais-report']['phosphateNotDone'];
    primaryRenalDiseaseDiabetesNephropathy: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseDiabetesNephropathy'];
    primaryRenalDiseaseGlomerulonephritis: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseGlomerulonephritis'];
    primaryRenalDiseaseHypertension: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseHypertension'];
    primaryRenalDiseaseOther: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseOther'];
    primaryRenalDiseasePolycystic: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseasePolycystic'];
    primaryRenalDiseaseTubulointerstitial: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseTubulointerstitial'];
    primaryRenalDiseaseVascular: BavReportingJsonApiTypes['ais-report']['primaryRenalDiseaseVascular'];
    seroconversionHbvChronic: BavReportingJsonApiTypes['ais-report']['seroconversionHbvChronic'];
    seroconversionHbvNew: BavReportingJsonApiTypes['ais-report']['seroconversionHbvNew'];
    seroconversionHcvChronic: BavReportingJsonApiTypes['ais-report']['seroconversionHcvChronic'];
    seroconversionHcvNew: BavReportingJsonApiTypes['ais-report']['seroconversionHcvNew'];
    seroconversionHivChronic: BavReportingJsonApiTypes['ais-report']['seroconversionHivChronic'];
    seroconversionHivNew: BavReportingJsonApiTypes['ais-report']['seroconversionHivNew'];
    serumCreatinineBe8: BavReportingJsonApiTypes['ais-report']['serumCreatinineBe8'];
    serumCreatinineL8: BavReportingJsonApiTypes['ais-report']['serumCreatinineL8'];
    serumCreatinineMgPerDlMean: BavReportingJsonApiTypes['ais-report']['serumCreatinineMgPerDlMean'];
    serumCreatinineMgPerDlSd: BavReportingJsonApiTypes['ais-report']['serumCreatinineMgPerDlSd'];
    serumCreatinineNotDone: BavReportingJsonApiTypes['ais-report']['serumCreatinineNotDone'];
    serumCreatinineUmolPerLMean: BavReportingJsonApiTypes['ais-report']['serumCreatinineUmolPerLMean'];
    serumCreatinineUmolPerLSd: BavReportingJsonApiTypes['ais-report']['serumCreatinineUmolPerLSd'];
    state: BavReportingJsonApiTypes['ais-report']['state'];
    tempPatientHoliday: BavReportingJsonApiTypes['ais-report']['tempPatientHoliday'];
    tempPatientTemporary: BavReportingJsonApiTypes['ais-report']['tempPatientTemporary'];
    timeOnDialysisMean: BavReportingJsonApiTypes['ais-report']['timeOnDialysisMean'];
    timeOnDialysisSd: BavReportingJsonApiTypes['ais-report']['timeOnDialysisSd'];
    timeTreatmentBe10p5AndL12: BavReportingJsonApiTypes['ais-report']['timeTreatmentBe10p5AndL12'];
    timeTreatmentBe12: BavReportingJsonApiTypes['ais-report']['timeTreatmentBe12'];
    timeTreatmentBe9AndL10p5: BavReportingJsonApiTypes['ais-report']['timeTreatmentBe9AndL10p5'];
    timeTreatmentL9: BavReportingJsonApiTypes['ais-report']['timeTreatmentL9'];
    timeTreatmentMean: BavReportingJsonApiTypes['ais-report']['timeTreatmentMean'];
    timeTreatmentNotDone: BavReportingJsonApiTypes['ais-report']['timeTreatmentNotDone'];
    timeTreatmentSd: BavReportingJsonApiTypes['ais-report']['timeTreatmentSd'];
    transferrinSaturationB30: BavReportingJsonApiTypes['ais-report']['transferrinSaturationB30'];
    transferrinSaturationBe20AndLe30: BavReportingJsonApiTypes['ais-report']['transferrinSaturationBe20AndLe30'];
    transferrinSaturationL20: BavReportingJsonApiTypes['ais-report']['transferrinSaturationL20'];
    transferrinSaturationMean: BavReportingJsonApiTypes['ais-report']['transferrinSaturationMean'];
    transferrinSaturationNotDone: BavReportingJsonApiTypes['ais-report']['transferrinSaturationNotDone'];
    transferrinSaturationSd: BavReportingJsonApiTypes['ais-report']['transferrinSaturationSd'];
    treatmentModalityHd: BavReportingJsonApiTypes['ais-report']['treatmentModalityHd'];
    treatmentModalityHdf: BavReportingJsonApiTypes['ais-report']['treatmentModalityHdf'];
    treatmentModalityHdfPost: BavReportingJsonApiTypes['ais-report']['treatmentModalityHdfPost'];
    treatmentModalityHdfPre: BavReportingJsonApiTypes['ais-report']['treatmentModalityHdfPre'];
    treatmentsOwnPatients: BavReportingJsonApiTypes['ais-report']['treatmentsOwnPatients'];
    treatmentsTempPatients: BavReportingJsonApiTypes['ais-report']['treatmentsTempPatients'];
    waterQualityBacteriologyFirstResult: BavReportingJsonApiTypes['ais-report']['waterQualityBacteriologyFirstResult'];
    waterQualityBacteriologyNotDone: BavReportingJsonApiTypes['ais-report']['waterQualityBacteriologyNotDone'];
    waterQualityEndotoxinFirstResult: BavReportingJsonApiTypes['ais-report']['waterQualityEndotoxinFirstResult'];
    waterQualityEndotoxinNotDone: BavReportingJsonApiTypes['ais-report']['waterQualityEndotoxinNotDone'];
    year: BavReportingJsonApiTypes['ais-report']['year'];
  }>;
  relationships?: undefined;
}

const aisReportModelUpdateProperties = {
  attributes: [
    'ageMean',
    'ageSd',
    'albuminBe25AndL35',
    'albuminBe35',
    'albuminGPerLBcGreenMean',
    'albuminGPerLBcGreenSd',
    'albuminGPerLBcPurpleMean',
    'albuminGPerLBcPurpleSd',
    'albuminL25',
    'albuminNotDone',
    'bloodFlowMean',
    'bloodFlowSd',
    'bloodPressureDiastolicBe90',
    'bloodPressureDiastolicL90',
    'bloodPressureDiastolicNotDone',
    'bloodPressureSystolicBe140',
    'bloodPressureSystolicL140',
    'bloodPressureSystolicNotDone',
    'bmiBe20AndL25',
    'bmiBe25AndL30',
    'bmiBe30',
    'bmiL20',
    'bmiMean',
    'bmiNotDone',
    'bmiSd',
    'calciumB10p5',
    'calciumBe8p4AndLe10p5',
    'calciumL8p4',
    'calciumMgPerDlMean',
    'calciumMgPerDlSd',
    'calciumMmolPerLMean',
    'calciumMmolPerLSd',
    'calciumNotDone',
    'centerId',
    'charlsonIndexBe8Patient',
    'charlsonIndexBe8Sum',
    'charlsonIndexL8Patient',
    'charlsonIndexL8Sum',
    'comorbiditiesCerebrovascularDisease',
    'comorbiditiesCongestiveHeartFailure',
    'comorbiditiesDiabetesChronicComplication',
    'comorbiditiesMalignancy',
    'comorbiditiesMetastaticSolidTumor',
    'comorbiditiesModerateSevereLiverDisease',
    'comorbiditiesMyocardialInfarction',
    'comorbiditiesPeripheralVascularDisease',
    'convectiveVolumeBe23',
    'convectiveVolumeL23',
    'convectiveVolumeMean',
    'convectiveVolumeNotDone',
    'convectiveVolumeSd',
    'crpElevated',
    'crpNotDone',
    'crpWithinLimits',
    'dialysisFreqBe3',
    'dialysisFreqL3',
    'dialysisFreqMean',
    'dialysisFreqNotDone',
    'dialysisFreqSd',
    'dryBodyWeightMean',
    'dryBodyWeightSd',
    'ektvBe1AndL1p2',
    'ektvBe1p2',
    'ektvL1',
    'ektvMean',
    'ektvNotDone',
    'ektvSd',
    'epoDose',
    'ferritinB500',
    'ferritinBe200AndLe500',
    'ferritinL200',
    'ferritinMean',
    'ferritinNotDone',
    'ferritinSd',
    'genderFemale',
    'genderMale',
    'handHygieneScore',
    'hdInfectionAvfBacteriaMrsaInfection',
    'hdInfectionAvfBacteriaOtherInfection',
    'hdInfectionAvfExitSiteMrsaInfection',
    'hdInfectionAvfExitSiteOtherInfection',
    'hdInfectionAvfPatientNum',
    'hdInfectionCvcBacteriaMrsaInfection',
    'hdInfectionCvcBacteriaOtherInfection',
    'hdInfectionCvcExitSiteMrsaInfection',
    'hdInfectionCvcExitSiteOtherInfection',
    'hdInfectionCvcPatientNum',
    'hdInfectionGraftBacteriaMrsaInfection',
    'hdInfectionGraftBacteriaOtherInfection',
    'hdInfectionGraftExitSiteMrsaInfection',
    'hdInfectionGraftExitSiteOtherInfection',
    'hdInfectionGraftPatientNum',
    'hdInfectionHbvPlus',
    'hdInfectionHcvPlus',
    'hdInfectionHivPlus',
    'hdTransplantWaitingPatient',
    'heightMean',
    'heightSd',
    'hemoglobinB12',
    'hemoglobinBe10AndLe12',
    'hemoglobinGPerDlMean',
    'hemoglobinGPerDlSd',
    'hemoglobinL10',
    'hemoglobinMmolPerLMean',
    'hemoglobinMmolPerLSd',
    'hemoglobinNotDone',
    'heparinBemi',
    'heparinBemiPatients',
    'heparinDalt',
    'heparinDaltPatients',
    'heparinEnoxA',
    'heparinEnoxAPatients',
    'heparinEnoxC',
    'heparinEnoxCPatients',
    'heparinEnoxE',
    'heparinEnoxEPatients',
    'heparinEnoxG',
    'heparinEnoxGPatients',
    'heparinEnoxL',
    'heparinEnoxLPatients',
    'heparinHeparinlessTreatment',
    'heparinNadr',
    'heparinNadrPatients',
    'heparinStandard',
    'heparinStandardPatients',
    'heparinTinz',
    'heparinTinzPatients',
    'hospCardiologyDays',
    'hospCardiologyHosp',
    'hospCardiologyMissedSessions',
    'hospCardiologyPatient',
    'hospInfectionsDays',
    'hospInfectionsHosp',
    'hospInfectionsMissedSessions',
    'hospInfectionsPatient',
    'hospOtherDays',
    'hospOtherHosp',
    'hospOtherMissedSessions',
    'hospOtherPatient',
    'hospVascularAccessDays',
    'hospVascularAccessHosp',
    'hospVascularAccessMissedSessions',
    'hospVascularAccessPatient',
    'intactParathormonB800',
    'intactParathormonBe100AndLe800',
    'intactParathormonL100',
    'intactParathormonNotDone',
    'intactParathormonPgPerMlMean',
    'intactParathormonPgPerMlSd',
    'intactParathormonPmolPerLMean',
    'intactParathormonPmolPerLSd',
    'medicationEsa',
    'medicationIviron',
    'month',
    'nutritionEiEnteral',
    'nutritionEiIdpn',
    'nutritionEiNotTreated',
    'nutritionSgaA',
    'nutritionSgaB',
    'nutritionSgaC',
    'nutritionSgaNotDone',
    'ownPatientAllLastMonth',
    'ownPatientHemoDialysis3Month',
    'ownPatientNewAdmission',
    'ownPatientNewBBraunAvitumCenter',
    'ownPatientNewBackTransp',
    'ownPatientNewOtherCenter',
    'ownPatientNewPdToHd',
    'ownPatientResignedBBraunAvitumCenter',
    'ownPatientResignedExitusChronicCardiovascular',
    'ownPatientResignedExitusChronicCerebrovascular',
    'ownPatientResignedExitusChronicInfection',
    'ownPatientResignedExitusChronicMalignancy',
    'ownPatientResignedExitusChronicOther',
    'ownPatientResignedExitusNonchronic',
    'ownPatientResignedHdToPd',
    'ownPatientResignedOtherCenter',
    'ownPatientResignedQuitted',
    'ownPatientResignedTransp',
    'phosphateBe3p5AndL5p5',
    'phosphateBe5p5',
    'phosphateL3p5',
    'phosphateMgPerDlMean',
    'phosphateMgPerDlSd',
    'phosphateMmolPerLMean',
    'phosphateMmolPerLSd',
    'phosphateNotDone',
    'primaryRenalDiseaseDiabetesNephropathy',
    'primaryRenalDiseaseGlomerulonephritis',
    'primaryRenalDiseaseHypertension',
    'primaryRenalDiseaseOther',
    'primaryRenalDiseasePolycystic',
    'primaryRenalDiseaseTubulointerstitial',
    'primaryRenalDiseaseVascular',
    'seroconversionHbvChronic',
    'seroconversionHbvNew',
    'seroconversionHcvChronic',
    'seroconversionHcvNew',
    'seroconversionHivChronic',
    'seroconversionHivNew',
    'serumCreatinineBe8',
    'serumCreatinineL8',
    'serumCreatinineMgPerDlMean',
    'serumCreatinineMgPerDlSd',
    'serumCreatinineNotDone',
    'serumCreatinineUmolPerLMean',
    'serumCreatinineUmolPerLSd',
    'state',
    'tempPatientHoliday',
    'tempPatientTemporary',
    'timeOnDialysisMean',
    'timeOnDialysisSd',
    'timeTreatmentBe10p5AndL12',
    'timeTreatmentBe12',
    'timeTreatmentBe9AndL10p5',
    'timeTreatmentL9',
    'timeTreatmentMean',
    'timeTreatmentNotDone',
    'timeTreatmentSd',
    'transferrinSaturationB30',
    'transferrinSaturationBe20AndLe30',
    'transferrinSaturationL20',
    'transferrinSaturationMean',
    'transferrinSaturationNotDone',
    'transferrinSaturationSd',
    'treatmentModalityHd',
    'treatmentModalityHdf',
    'treatmentModalityHdfPost',
    'treatmentModalityHdfPre',
    'treatmentsOwnPatients',
    'treatmentsTempPatients',
    'waterQualityBacteriologyFirstResult',
    'waterQualityBacteriologyNotDone',
    'waterQualityEndotoxinFirstResult',
    'waterQualityEndotoxinNotDone',
    'year',
  ],
  relationships: undefined,
} as const;

export type CenterModel = {
  [TYPE]: 'center';
  readonly id: string;
} & {
  calculatedReports: ReadonlyArray<
    BavReportingJsonApiTypes['ais-calculated-report']
  >;
  centerId: string;
  reports: ReadonlyArray<BavReportingJsonApiTypes['ais-report']>;
};

export type UserLogModel = {
  [TYPE]: 'user-log';
  readonly id: string;
} & {
  createdAt: string;
  jobIdentifier: null | number;
  jobType: 'Undefined' | 'Report' | 'ClinicalData' | 'Slice';
  message: string;
  messageCode: string;
  severity: 'Undefined' | 'Information' | 'Warning' | 'Error' | 'Fatal';
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type BavReportingJsonApiTypesMeta = {
  ['ais-calculated-report']: {
    create: never;
    update: never;
  };
  ['ais-report']: {
    create: AisReportModelCreate;
    update: AisReportModelUpdate;
  };
  ['center']: {
    create: never;
    update: never;
  };
  ['user-log']: {
    create: never;
    update: never;
  };
};

export const bavReportingJsonApiTypesProperties = {
  ['ais-calculated-report']: {
    create: undefined,
    update: undefined,
  },
  ['ais-report']: {
    create: aisReportModelCreateProperties,
    update: aisReportModelUpdateProperties,
  },
  ['center']: {
    create: undefined,
    update: undefined,
  },
  ['user-log']: {
    create: undefined,
    update: undefined,
  },
} as const;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type BavReportingJsonApiTypes = {
  ['ais-calculated-report']: AisCalculatedReportModel;
  ['ais-report']: AisReportModel;
  ['center']: CenterModel;
  ['user-log']: UserLogModel;
};

export const bavReportingResourceNames = [
  'ais-calculated-report',
  'ais-report',
  'center',
  'user-log',
] as const;

export type BavReportingResourceNames =
  typeof bavReportingResourceNames[number];

export const bavReportingMeta = {
  id: 'bavreporting-service',
  version: '12.1.0',
  links: [...bavReportingResourceNames],
} as const;

export type BavReportingLinkNames = typeof bavReportingMeta.links[number];
