import { Injectable } from '@angular/core';
import { createReportModel } from '../report-model/report-model';

@Injectable({
  providedIn: 'root',
})
export class ReportModelService {
  constructor() {}

  getReportModel(reportMonth: number) {
    return createReportModel(reportMonth);
  }
}
