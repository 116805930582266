import { Inject, Injectable } from '@angular/core';
import {
  concatMap,
  first,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import {
  ExpertLoaderJsonApi,
  EXPERT_LOADER_API_SERVICE,
  ExpertLoaderJsonApiTypesMeta,
  labTestMappingResponseModelReadParameters,
  CanSynchronizeLabTestMappingGuard,
  ExpertLoaderJsonApiTypes,
} from '@bbraun/bav-reporting/data-access-administration';
import { HttpClient } from '@angular/common/http';
import {
  getHrefFromLink,
  getResourceLink,
  isJsonApiDataObjectCollectionResponseBody,
  JSON_API_CONTENT_TYPE_HEADER,
  JsonApiDataResponseBody,
  JsonApiErrorResponseBody,
  JsonApiFetchResponseBody,
  TypedJsonApiService,
  UpdateObjectType,
} from '@bbraun/shared/util-jsonapi';
import {
  createPropertyPathBuilder,
  createSortSpecs,
} from '@bbraun/shared/util-lang';

const parameterNameSelector =
  createPropertyPathBuilder<ExpertLoaderJsonApiTypes['lab-test-mapping']>().add(
    'parameterName',
  );

const labTestMappingSortQueryParams = createSortSpecs().add({
  property: parameterNameSelector,
  desc: false,
  comparator: 'string',
} as const);

@Injectable({
  providedIn: 'root',
})
export class LabTestMappingService {
  constructor(
    @Inject(EXPERT_LOADER_API_SERVICE)
    private readonly typedApiService: TypedJsonApiService<ExpertLoaderJsonApi>,
    private readonly http: HttpClient,
    private readonly canSynchronizeLabTestMappingGuard: CanSynchronizeLabTestMappingGuard,
  ) {}

  list(): Observable<JsonApiFetchResponseBody> {
    {
      return this.canSynchronizeLabTestMappingGuard.canActivate$.pipe(
        switchMap((canActivate) =>
          (canActivate
            ? this.typedApiService
                .index()
                .pipe(
                  map((indexResponse) =>
                    getResourceLink(
                      'lab-test-mapping',
                      'synchronize',
                      indexResponse,
                    ),
                  ),
                )
                .pipe(
                  mergeMap((linkObject) => {
                    const link = linkObject
                      ? getHrefFromLink(linkObject)
                      : undefined;

                    return link
                      ? this.http.post<
                          JsonApiErrorResponseBody | JsonApiDataResponseBody
                        >(link, {
                          headers: JSON_API_CONTENT_TYPE_HEADER,
                        })
                      : of(false);
                  }),
                )
            : this.typedApiService.resource('lab-test-mapping').queryRaw({
                sort: labTestMappingSortQueryParams,
              })
          ).pipe(
            concatMap((response) =>
              response && isJsonApiDataObjectCollectionResponseBody(response)
                ? of(response)
                : throwError(
                    () =>
                      new Error('Failed to load resource lab-test-mappings'),
                  ),
            ),
          ),
        ),
      );
    }
  }

  update(
    entry: { id: string } & UpdateObjectType<
      ExpertLoaderJsonApiTypesMeta,
      'lab-test-mapping'
    >,
  ): Observable<JsonApiFetchResponseBody> {
    return this.typedApiService
      .resource('lab-test-mapping')
      .updateRaw(entry.id, entry, labTestMappingResponseModelReadParameters)
      .pipe(first());
  }
}
