import { Observable, Subscriber } from 'rxjs';

/**
 * This Subject behaves just like a BehaviorSubject, but resets its value to the initial value
 * as soon as all subscribers have unsubscribed.
 */
export class BehaviorSubjectWithRefCount<T> extends Observable<T> {
  private readonly subscribers = new Set<Subscriber<T>>();
  private _value: T;

  constructor(initialValue: T) {
    super((subscriber) => {
      this.subscribers.add(subscriber);

      subscriber.next(this.value);

      return () => {
        this.subscribers.delete(subscriber);

        if (this.subscribers.size === 0) {
          this._value = initialValue;
        }
      };
    });

    this._value = initialValue;
  }

  get value() {
    return this._value;
  }

  next(value: T) {
    this._value = value;

    for (const subscriber of this.subscribers) {
      subscriber.next(value);
    }
  }
}
