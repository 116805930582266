import { FiqlQuery } from '@bbraun/shared/util-fiql';
import {
  JsonApiFetchResponseBody,
  JsonApiPaging,
  JsonApiSort,
  readJsonApiCollectionResponseUnsafe,
  readJsonApiObjectResponseUnsafe,
} from '@bbraun/shared/util-jsonapi';
import { ScopedTranslationService } from '@bbraun/shared/util-transloco-ng';
import { TranslocoService } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { CustomStoreOptions } from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { UpdateObject } from 'libs/shared/util-jsonapi/src/lib/json-api-adpater.type';
import { isEqual } from 'lodash-es';
import {
  firstValueFrom,
  Observable,
  of,
  OperatorFunction,
  Subject,
} from 'rxjs';
import { catchError, map, tap, takeUntil } from 'rxjs/operators';
import { ConvertOptions } from '../filter/convert-options.type';
import { DEFAULT_CONVERT_OPTIONS } from '../filter/default-convert-options';
import {
  convertDxLoadOptionsToJsonApi,
  DxLoadOptionsSplitSearch,
} from './convert-dx-load-options-to-json-api';
import { DataSourceCache, NoOpCache } from './data-source-cache';
import { DxLoadOptions } from './dx-load-options';
import { DxPagedResult } from './dx-paged-result';
import { DxResult, DxResultItem } from './dx-result';

export type ErrorHandling = Readonly<
  {
    catchError: boolean;
  } & (
    | {
        reportError: (error: unknown) => void;
      }
    | {
        reportError?: undefined;
        reporter: ErrorHandler;
      }
    | {
        reportError: false;
      }
  )
>;

interface DxJsonApiDataSource2Options {
  key: string;
  searchProperties?: string[];
  splitSearch?: DxLoadOptionsSplitSearch;
  dxDataSourceOptions?: {};
  converters?: ConvertOptions;
  resultFilter?: (item: unknown) => boolean;
  mapping?: {
    collection: (document: JsonApiFetchResponseBody) => unknown[];
    object: (document: JsonApiFetchResponseBody) => unknown;
  };
  errorHandling: ErrorHandling;
}

export interface ErrorHandler {
  onError(error: { error: unknown; message: () => Observable<string> }): void;
}

export class DxJsonApiDataSource2 extends DataSource {
  private readonly cancelSubject = new Subject<void>();

  constructor(
    translocoService: TranslocoService,
    jsonApi: {
      query(queryParameter: {
        filter?: FiqlQuery;
        sort?: JsonApiSort[];
        page?: JsonApiPaging;
      }): Observable<JsonApiFetchResponseBody>;
      read?(id: string): Observable<JsonApiFetchResponseBody>;
      update?(changes: UpdateObject): Observable<JsonApiFetchResponseBody>;
    },
    options: DxJsonApiDataSource2Options,
    cache: DataSourceCache<unknown, unknown> = new NoOpCache(),
  ) {
    super(
      createDataSourceOptions(
        new ScopedTranslationService(
          'bbraunSharedUtilDevexpress',
          translocoService,
        ),
        jsonApi,
        options,
        () => this.cancelSubject,
        cache,
      ),
    );
  }

  load() {
    this.cancelSubject.next();
    return super.load();
  }
}

function createDataSourceOptions(
  translationService: ScopedTranslationService,
  jsonApi: {
    query(queryParameter: {
      filter?: FiqlQuery;
      sort?: JsonApiSort[];
      page?: JsonApiPaging;
    }): Observable<JsonApiFetchResponseBody>;
    read?(id: string): Observable<JsonApiFetchResponseBody>;
    update?(changes: UpdateObject): Observable<JsonApiFetchResponseBody>;
  },
  {
    key,
    searchProperties,
    dxDataSourceOptions = {},
    converters = DEFAULT_CONVERT_OPTIONS,
    resultFilter,
    mapping = {
      collection: (document) =>
        readJsonApiCollectionResponseUnsafe(document).result || [],
      object: (document) =>
        readJsonApiObjectResponseUnsafe(document).result || undefined,
    },
    splitSearch = {
      splitter: /\s+/,
      combiner: (fiqlQueries) => ({ and: fiqlQueries }),
    },
    errorHandling,
  }: DxJsonApiDataSource2Options,
  cancelSubject: () => Subject<void>,
  cache: DataSourceCache<unknown, unknown>,
): CustomStoreOptions | DataSourceOptions {
  const updateFn = jsonApi.update;
  return {
    key,
    ...(updateFn
      ? {
          update: (
            id: string,
            changes: UpdateObject,
          ): Promise<DxResultItem<unknown> | undefined> =>
            firstValueFrom(
              updateFn({ ...changes, id })
                .pipe(
                  map((result) => ({
                    data: mapping.object(result),
                  })),
                )
                .pipe(tap(({ data }) => cache.put(data)))
                .pipe(
                  catchAndReportError<{
                    data: unknown;
                  }>(errorHandling, translationService, {
                    data: undefined,
                  }),
                ),
            ),
        }
      : {}),
    load: (
      opts: DxLoadOptions,
    ): Promise<DxResult<unknown> | DxPagedResult<unknown> | undefined> => {
      if (opts.filter && Array.isArray(opts.filter)) {
        if (isEqual(opts.filter.slice(0, 2), [key, '='])) {
          if (cache.has(opts.filter[2])) {
            const item = cache.get(opts.filter[2]);
            return Promise.resolve({
              data: !resultFilter || resultFilter(item) ? [item] : [],
            });
          }
        }
      }

      if (
        !searchProperties &&
        opts &&
        opts.searchExpr &&
        opts.searchExpr.length > 0
      ) {
        const searchOptions = opts.searchExpr as string[] | string;
        if (searchOptions.length > 0) {
          searchProperties =
            typeof searchOptions === 'string' ? [searchOptions] : searchOptions;
        }
      }

      const queryParameter = convertDxLoadOptionsToJsonApi(
        { ...opts },
        searchProperties,
        {
          converters,
          splitSearch,
        },
      );

      return firstValueFrom(
        jsonApi
          .query(queryParameter)
          .pipe(
            map((result) => ({
              data: mapping.collection(result),
              totalCount:
                result.meta &&
                result.meta.pagination &&
                result.meta.pagination.count &&
                opts.take
                  ? result.meta.pagination.count * opts.take
                  : undefined,
            })),
          )
          .pipe(tap(({ data }) => cache.put(data)))
          .pipe(
            map((result) => {
              if (resultFilter) {
                const filteredData = result.data.filter(resultFilter);
                return {
                  ...result,
                  data: filteredData,
                  totalCount: result.totalCount
                    ? result.totalCount -
                      (result.data.length - filteredData.length)
                    : undefined,
                };
              } else {
                return result;
              }
            }),
          )
          .pipe(
            catchAndReportError<{
              data: unknown[];
              totalCount: number | undefined;
            }>(errorHandling, translationService, {
              data: [],
              totalCount: 0,
            }),
          )
          .pipe(takeUntil(cancelSubject())),
      );
    },
    byKey: (id: string): Promise<unknown> => {
      if (cache.has(id)) {
        const item = cache.get(id);
        if (!resultFilter || resultFilter(item)) {
          return Promise.resolve(item);
        }
      }

      if (jsonApi.read) {
        return firstValueFrom(
          jsonApi
            .read(id)
            .pipe(map((result) => mapping.object(result)))
            .pipe(tap((result) => cache.put(result)))
            .pipe(
              catchAndReportError<unknown>(
                errorHandling,
                translationService,
                undefined,
              ),
            )
            .pipe(
              map((result) =>
                !resultFilter || resultFilter(result) ? result : undefined,
              ),
            ),
        );
      } else {
        throw new Error('This data source does not provide a read operation.');
      }
    },
    ...dxDataSourceOptions,
  };
}

function catchAndReportError<T>(
  errorHandling: ErrorHandling,
  translationService: ScopedTranslationService,
  errorResult: T,
): OperatorFunction<T, T> {
  return (o) => {
    const errorReporting = o.pipe(
      tap({
        error: (error) => {
          if (errorHandling.reportError) {
            errorHandling.reportError(error);
          } else if (errorHandling.reportError !== false) {
            errorHandling.reporter.onError({
              error,
              message: () =>
                translationService.translate(
                  i18n('bbraunSharedUtilDevexpress.dataSource.requestFailure'),
                ),
            });
          }
        },
      }),
    );

    return errorHandling.catchError
      ? errorReporting.pipe(catchError(() => of(errorResult)))
      : errorReporting;
  };
}
