import { Inject, Injectable, Optional } from '@angular/core';
import {
  TypedJsonApiServiceResourceGuard,
  TypedJsonApiServiceResourceGuardOptions,
  TYPED_JSON_API_SERVICE_RESOURCE_GUARD_OPTIONS,
} from '@bbraun/shared/util-jsonapi-ng';
import { BavReportingJsonApi } from '../bav-reporting-json-api.type';
import {
  BavReportingApiService,
  BAV_REPORTING_API_SERVICE,
} from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class CanCreateAisReportsGuard extends TypedJsonApiServiceResourceGuard<
  BavReportingJsonApi,
  'ais-report'
> {
  constructor(
    @Inject(BAV_REPORTING_API_SERVICE)
    apiService: BavReportingApiService,
    @Optional()
    @Inject(TYPED_JSON_API_SERVICE_RESOURCE_GUARD_OPTIONS)
    options?: TypedJsonApiServiceResourceGuardOptions,
  ) {
    super(apiService, 'ais-report', options, 'create');
  }
}
