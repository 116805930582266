import { Directive, HostListener, Input } from '@angular/core';
import { HideableContainerComponent } from '../components/hideable-container/hideable-container.component';

@Directive({
  selector: '[bbraunUiHideableContainerToggle]',
})
export class ToggleContainerDirective {
  @Input('bbraunUiHideableContainerToggle')
  target?: HideableContainerComponent;

  @HostListener('click')
  clicked() {
    if (this.target) {
      this.target.toggle();
    }
  }
}
