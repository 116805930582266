import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import {
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
  concat,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '@bbraun/shared/util-message-ng';
import {
  connectLoadingBar,
  connectToLoadBarService,
  LoadBarService,
} from '@bbraun/shared/util-navigation';
import { MatDialog } from '@angular/material/dialog';
import { translate } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { asRequired } from '@bbraun/shared/util-lang';
import {
  CalculatedReportDetailsResponseModel,
  CanCreateAisReportsGuard,
  ReportDetailsResponseModel,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import { ConfirmDialogComponent } from '@bbraun/bav-reporting/ui-common';
import moment from 'moment';
import {
  BlockingOperationHandler,
  createObservables,
  expectSingleElementArray,
  fromQueryList,
  hasValue,
  isDefined,
  NEXT,
  pickDistinctUntilChanged,
} from '@bbraun/shared/util-rxjs';
import {
  ReportEditorComponent,
  ReportEditorFormModel,
  ReportEditorViewMode,
  ReportModel,
  WithIsChangedSupportComponent,
  WithIsResetSupportComponent,
} from '@bbraun/bav-reporting/ui-report-editor';
import { DiscardChangesGuard } from '@bbraun/shared/ui-discard-changes';
import { ReportInfo } from '../../types/types';
import { ReportingService } from '../../services/reporting.service';
import { ReportModelService } from '../../services/report-model.service';
import { createSaveReportHandler } from './create-save-report-handler';
import { createUpdateCalculatedReportValuesHandler } from './create-update-calculated-report-values-handler';
import {
  createExportXmlHandler,
  EXPORT_ERROR_ID,
} from './create-export-xml-handler';

export type ReportDetailsState = {
  reportDetails: Partial<ReportDetailsResponseModel>;
  reportInfo: ReportInfo;
} & (
  | {
      viewMode: 'create';
    }
  | {
      viewMode: 'view' | 'edit';
      id: string;
      exportXmlLink: string | undefined;
      isLastMonthReport: boolean;
      doUpdateCalculatedReportValuesExist: boolean;
    }
);

const SAVE_WAITING = Symbol('edit.save');
const MODEL_LOADING = Symbol('edit.model');
const UPDATE_CALCULATED_REPORT_VALUES_LOADING = Symbol(
  'UPDATE_CALCULATED_REPORT_VALUES_LOADING',
);
const EXPORT_XML_ID = Symbol('exportXmlId');

async function defer(action: TimerHandler) {
  setTimeout(action, 0);
}

@Component({
  selector: 'bav-reporting-feature-ais-reports-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportDetailsComponent
  implements OnInit, OnDestroy, AfterViewInit, WithIsChangedSupportComponent
{
  get changed(): boolean {
    return !!this.withIsChangedSupportComponents?.some(
      ({ changed }) => changed,
    );
  }

  @Input() set initialState(value: ReportDetailsState | null | undefined) {
    this.observables[NEXT]('initialState', value || undefined);
  }

  @Output() readonly setReportStateTriggered = new EventEmitter<{
    reportId: string;
    state: ReportDetailsResponseModel['state'];
  }>();

  @ViewChildren('reportEditor')
  reportEditorComponent?: QueryList<ReportEditorComponent>;

  @ViewChildren('withIsResetSupport')
  private withIsResetSupportComponents?: QueryList<WithIsResetSupportComponent>;

  @ViewChildren('withIsChangedSupport')
  private withIsChangedSupportComponents?: QueryList<WithIsChangedSupportComponent>;

  accordionAction: 'expand' | 'collapse' = 'expand';
  updatedReportEditorFormData?: ReportEditorFormModel;

  readonly saveHandler = createSaveReportHandler(this.messageService, () => {
    this.viewModeChanged.next('view');
  });
  readonly updateCalculatedReportValuesHandler: BlockingOperationHandler<
    false | CalculatedReportDetailsResponseModel
  >;

  readonly exportHandler = createExportXmlHandler(this.messageService);
  readonly state$: Observable<ReportDetailsState>;
  readonly reportModel$: Observable<ReportModel>;
  readonly currentReportInfo$: Observable<ReportInfo>;
  readonly viewMode$: Observable<ReportEditorViewMode>;

  private readonly viewModeChanged = new Subject<ReportEditorViewMode>();
  private readonly reportEditorComponentSubject = new Subject<
    ReportEditorComponent | undefined
  >();

  private readonly observables = createObservables<{
    initialState: ReportDetailsState | undefined;
  }>({
    initialState: undefined,
  });
  private readonly subscriptions = new Subscription();
  private readonly triggerUpdateCalculatedReportValuesSubject =
    new Subject<ReportInfo>();

  constructor(
    private readonly reportingService: ReportingService,
    private readonly messageService: MessageService,
    private readonly loadbarService: LoadBarService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly discardChangesGuard: DiscardChangesGuard,
    public readonly canCreateAisReportsGuard: CanCreateAisReportsGuard,
    reportModelService: ReportModelService,
  ) {
    const initialReportDetailsState$ = this.observables.initialState.pipe(
      isDefined(),
    );

    const saveHandlerValue$ = this.saveHandler.results
      .pipe(filter(hasValue))
      .pipe(map(({ value }) => value));

    this.state$ = initialReportDetailsState$
      .pipe(
        switchMap((initialReportDetailsState) =>
          concat(
            of(initialReportDetailsState),
            saveHandlerValue$.pipe(
              map((reportDetails) => ({
                ...initialReportDetailsState,
                reportDetails,
              })),
            ),
          ),
        ),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.currentReportInfo$ = this.state$
      .pipe(map((state) => state.reportInfo))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.reportModel$ = this.currentReportInfo$
      .pipe(
        map((reportInfo) =>
          reportModelService.getReportModel(reportInfo.date.month),
        ),
      )
      .pipe(first())
      .pipe(connectLoadingBar(this.loadbarService, MODEL_LOADING))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.viewMode$ = merge(
      this.state$
        .pipe(pickDistinctUntilChanged(['viewMode']))
        .pipe(map(({ viewMode }) => viewMode)),
      this.viewModeChanged,
    )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.updateCalculatedReportValuesHandler =
      createUpdateCalculatedReportValuesHandler({
        messageService,
        matDialog: this.dialog,
        reportingService,
        triggerUpdateCalculatedReportValuesSubject:
          this.triggerUpdateCalculatedReportValuesSubject,
      });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.exportHandler.running
        .pipe(map((isRunning) => ({ id: EXPORT_XML_ID, value: isRunning })))
        .pipe(connectToLoadBarService(this.loadbarService))
        .subscribe(),
    );

    this.subscriptions.add(
      this.updateCalculatedReportValuesHandler.running
        .pipe(
          map((isRunning) => ({
            id: UPDATE_CALCULATED_REPORT_VALUES_LOADING,
            value: isRunning,
          })),
        )
        .pipe(connectToLoadBarService(this.loadbarService))
        .subscribe(),
    );

    this.subscriptions.add(
      this.exportHandler.results.subscribe((result) => {
        if (result.hasValue) {
          const { exportData, reportInfo } = result.value;
          const filename = createFilename(reportInfo);
          openDownload({
            data: exportData,
            filename,
          });
        }
      }),
    );

    this.subscriptions.add(
      this.saveHandler.running.subscribe((running) => {
        if (running) {
          this.loadbarService.addLoadingProcess(SAVE_WAITING);
        } else {
          this.loadbarService.stopLoadingProcess(SAVE_WAITING);
        }
      }),
    );

    this.subscriptions.add(
      this.reportEditorComponentSubject
        .pipe(
          switchMap((reportEditorComponent) =>
            this.updateCalculatedReportValuesHandler.results
              .pipe(filter(hasValue))
              .pipe(
                map(({ value: updateCalculatedReportValue }) => ({
                  updateCalculatedReportValue,
                  reportEditorComponent,
                })),
              ),
          ),
        )
        .subscribe(({ updateCalculatedReportValue, reportEditorComponent }) => {
          if (reportEditorComponent) {
            if (updateCalculatedReportValue) {
              reportEditorComponent.updateCalculatedReportValues(
                updateCalculatedReportValue,
              );
            }
          } else {
            this.messageService.message(
              translate(
                i18n(
                  'bbraunBavReportingFeatureAisReports.reportDetailsComponent.reportEditorComponent.notDefined.error.message',
                ),
              ),
              'error',
              {
                error: 'Report editor is not defined',
              },
            );
          }
        }),
    );
  }

  ngAfterViewInit() {
    if (this.reportEditorComponent) {
      const reportEditorComponent$ = fromQueryList(this.reportEditorComponent)
        .pipe(expectSingleElementArray())
        .pipe(distinctUntilChanged())
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));

      this.subscriptions.add(
        reportEditorComponent$.subscribe(this.reportEditorComponentSubject),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.messageService.remove([EXPORT_ERROR_ID]);
  }

  updateCalculatedReportValues(reportInfo: ReportInfo) {
    this.triggerUpdateCalculatedReportValuesSubject.next(reportInfo);
  }

  editReport() {
    this.viewModeChanged.next('edit');
  }

  onCancelEditMode() {
    const changed = !!this.withIsChangedSupportComponents?.some(
      (component) => component.changed,
    );

    this.subscriptions.add(
      this.discardChangesGuard
        .canDiscardForm({ changed })
        .subscribe((canDiscard) => {
          if (canDiscard) {
            this.withIsResetSupportComponents?.forEach((component) =>
              component.reset(),
            );
            this.viewModeChanged.next('view');
          }
        }),
    );
  }

  createReport(
    reportInfo: ReportInfo,
    createData: ReportEditorFormModel | undefined,
    reportEditorComponent: ReportEditorComponent,
  ) {
    const confirmResult = this.dialog
      .open(ConfirmDialogComponent, {
        data: reportInfo,
      })
      .afterClosed()
      .pipe(first());

    this.subscriptions.add(
      confirmResult.subscribe((confirmation) => {
        if (confirmation) {
          const data = reportInfo && asRequired(reportInfo, ['center', 'date']);
          const saveReport =
            createData && data
              ? {
                  ...createData,
                  month: data.date.month,
                  year: data.date.year,
                  centerId: data.center,
                  state: 'Draft' as const,
                  waterQualityBacteriologyNotDone:
                    !!createData.waterQualityBacteriologyNotDone,
                  waterQualityEndotoxinNotDone:
                    !!createData.waterQualityEndotoxinNotDone,
                }
              : undefined;
          this.saveHandler.next(() =>
            saveReport
              ? this.reportingService.createReport(saveReport).pipe(
                  tap((result) => {
                    reportEditorComponent.markClean();
                    this.router.navigate([`/app/reports/${result.id}`]);
                  }),
                  first(),
                )
              : throwError(() =>
                  of(
                    'Could not create report because date, center information or report data was not provided.',
                  ),
                ),
          );
        }
      }),
    );
  }

  updateReport(
    updateData: ReportEditorFormModel | undefined,
    reportId: string | undefined,
  ) {
    this.saveHandler.next(() =>
      updateData
        ? reportId
          ? this.reportingService
              .updateReport(reportId, { ...updateData, state: 'Draft' })
              .pipe(first())
          : throwError(() => 'Cannot update report. Report id is not defined.')
        : throwError(
            () => 'Cannot update report. Updated report is not defined.',
          ),
    );
  }

  setReportState(
    reportId: string | undefined,
    state: ReportDetailsResponseModel['state'],
  ) {
    if (reportId) {
      this.setReportStateTriggered.next({ reportId, state });
    } else {
      this.messageService.message(
        'Cannot set report state. Report id is not defined',
        'error',
      );
    }
  }

  fold(reportEditorComponent: ReportEditorComponent): void {
    if (reportEditorComponent.accordion) {
      if (this.accordionAction === 'collapse') {
        reportEditorComponent.accordion.closeAll();
      } else {
        reportEditorComponent.expansionPanels
          ?.toArray()
          .forEach((panel) => defer(() => panel.open()));
      }
    }
  }

  onExpandedChange(reportEditorComponent: ReportEditorComponent): void {
    if (reportEditorComponent.expansionPanels) {
      let openCounter = 0;
      for (const expasionPanel of reportEditorComponent.expansionPanels) {
        if (expasionPanel.expanded === true) {
          openCounter += 1;
        }
      }
      if (openCounter === reportEditorComponent.expansionPanels.length) {
        this.accordionAction = 'collapse';
      } else if (openCounter === 0) {
        this.accordionAction = 'expand';
      }
    }
  }

  exportXml(exportLink: string | undefined, reportInfo: ReportInfo) {
    if (exportLink) {
      this.exportHandler.next(() =>
        this.reportingService
          .exportXml(exportLink)
          .pipe(map((exportData) => ({ exportData, reportInfo }))),
      );
    } else {
      this.messageService.message(
        translate(
          i18n(
            'bbraunBavReportingFeatureAisReports.reportDetailsComponent.exportUnavailable.message',
          ),
        ),
        'error',
      );
    }
  }

  onFormChanged(event: ReportEditorFormModel) {
    this.updatedReportEditorFormData = event;
  }
}

function openDownload(file: { data: string; filename: string }): void {
  const blob = new Blob([file.data], { type: 'text/xml' });
  const downloadURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;
  link.download = file.filename;
  link.click();
}

function createFilename(reportInfo: Partial<ReportInfo>): string {
  const reportDate = moment(reportInfo.date).format('YYYY_MM');
  const timestamp = moment(new Date()).format('YYYYMMDD-HHmmss-SSS');

  return `Report_${reportInfo.center}_${reportDate}_exported_${timestamp}.xml`;
}
