import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../interfaces/login-service';
import { LoginServiceConnector } from '../interfaces/login-service-connector';
import { Principal } from '../interfaces/principal';
import { RefreshTokenConfiguration } from '../interfaces/token-configuration';

export const AUTHENTICATION_SERVICE_BASE_URL_TOKEN = new InjectionToken<string>(
  'AUTHENTICATION_SERVICE_BASE_URL_TOKEN',
);

export const LOGIN_SERVICE_TOKEN = new InjectionToken<LoginService>(
  'LOGIN_SERVICE_TOKEN',
);

export const LOGIN_SERVICE_CONNECTOR_TOKEN =
  new InjectionToken<LoginServiceConnector>('LOGIN_SERVICE_CONNECTOR_TOKEN');

export const PRINCIPAL_PROVIDER_TOKEN = new InjectionToken<
  (decoded?: any) => Observable<Principal>
>('PRINCIPAL_PROVIDER_TOKEN');

export const REFRESH_TOKEN_CONFIGURATION_TOKEN =
  new InjectionToken<RefreshTokenConfiguration>(
    'REFRESH_TOKEN_CONFIGURATION_TOKEN',
  );

export const IS_LOGIN_IN_PROGRESS_TOKEN = new InjectionToken<
  Observable<boolean>
>('IS_LOGIN_IN_PROGRESS_TOKEN');

export const IS_LOGOUT_IN_PROGRESS_TOKEN = new InjectionToken<
  Observable<boolean>
>('IS_LOGOUT_IN_PROGRESS_TOKEN');
