import {
  isObjectType,
  KeyType,
  MergeResult,
  ObjectType,
  Options,
  References,
} from '../merge-immutable.type';
import { mergeKeyValue } from './merge-key-value';

export function mergeObject<TMerge extends ObjectType>(
  base: unknown,
  mergeObj: TMerge,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
): MergeResult<TMerge> {
  return mergeKeyValue(
    base,
    mergeObj,
    references,
    path,
    diff,
    startTime,
    options,
    {
      getValue: (data, key) => data[key],
      setValue: (data, key, value) => {
        data[key as keyof TMerge] = value as any;
        return data;
      },
      getKeys: (data) => Reflect.ownKeys(data),
      isSameType: (data): data is TMerge => isObjectType(data),
      createInstance: () => ({} as TMerge),
    },
  );
}
