import { isArray, toPairs } from '@bbraun/shared/util-lang';
import { JsonApiHttpParams } from './http.type';
import {
  QueryParameter,
  TypedQueryParameters,
} from './typed/typed-query-parameters';

export function mapQueryParameterToHttpParams<
  TQueryParameters extends
    | QueryParameter
    | TypedQueryParameters<any, any, any, any, any, TCustom>,
  TCustom extends Record<string, string> = { [key: string]: string },
>(
  { filter, sort, page, fieldsets, include, custom }: TQueryParameters,
  fiqlSerializer: (
    parameters: Exclude<TQueryParameters['filter'], undefined | string>,
    encoder: (value: string | number | boolean) => string,
  ) => string | false,
  sortSerializer: (
    sort: Exclude<TQueryParameters['sort'], undefined>,
  ) => string,
) {
  let httpParams: JsonApiHttpParams = {};
  httpParams =
    include && include.length > 0
      ? {
          ...httpParams,
          include: encodeURIComponent(
            include.map((path) => path.join('.')).join(','),
          ),
        }
      : httpParams;
  httpParams = fieldsets
    ? Object.entries(fieldsets).reduce(
        (params, [key, values]: [string, ReadonlyArray<string>]) => ({
          ...params,
          [`fields[${encodeURIComponent(key)}]`]: encodeURIComponent(
            values.join(','),
          ),
        }),
        httpParams,
      )
    : httpParams;

  const filterHttpParams =
    filter &&
    (typeof filter === 'string'
      ? encodeURIComponent(filter)
      : fiqlSerializer(filter, encodeURIComponent));

  if (filterHttpParams !== false) {
    httpParams = filterHttpParams
      ? { ...httpParams, filter: filterHttpParams }
      : httpParams;

    const sortValueString =
      sort &&
      sortSerializer(sort as Exclude<TQueryParameters['sort'], undefined>);
    httpParams = sortValueString
      ? { ...httpParams, sort: encodeURIComponent(sortValueString) }
      : httpParams;
    httpParams = page
      ? { ...httpParams, 'page[size]': encodeURIComponent(`${page.size}`) }
      : httpParams;
    httpParams = page
      ? {
          ...httpParams,
          'page[number]': encodeURIComponent(`${page.number || 1}`),
        }
      : httpParams;

    httpParams = custom
      ? toPairs(custom).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [encodeURIComponent(key)]: isArray(value)
              ? value.map((param: any) => encodeURIComponent(param))
              : encodeURIComponent(value),
          }),
          httpParams,
        )
      : httpParams;

    return httpParams;
  } else {
    throw new Error('Failed to serialize fiql query object.');
  }
}
