import { removeUndefineds } from '../remove-undefineds';
import { CreateObjectType, TypeMeta } from './api-type-meta.type';
import { ApiTypes } from './api-types';
import { JsonApiResourceMeta } from './typed-json-api.types';

export function mapToCreateParameters<
  TTypes extends ApiTypes,
  TTypeMeta extends TypeMeta<any>,
  TTypeName extends keyof TTypeMeta,
>(
  v: CreateObjectType<TTypeMeta, TTypeName>,
  attributes: ReadonlyArray<
    keyof Exclude<TTypeMeta[TTypeName]['create']['attributes'], undefined>
  >,
  relationships:
    | Record<
        keyof Exclude<
          TTypeMeta[TTypeName]['create']['relationships'],
          undefined
        >,
        keyof TTypes
      >
    | undefined,
): TTypeMeta[TTypeName]['create'] {
  return (removeUndefineds({
    attributes: attributes.reduce(
      (acc, key) => ({ ...acc, [key]: v[key] }),
      {},
    ),
    relationships:
      relationships &&
      Object.entries(relationships)
        .filter(([key]) => v[key] !== undefined)
        .map(([key, type]) => {
          const value = v[key];

          return [
            key,
            value !== undefined &&
              (Array.isArray(value)
                ? {
                    data: value.map((item) => ({
                      id: (item as { id: string }).id,
                      type,
                    })),
                  }
                : {
                    data: v[key] && {
                      id: (v[key] as { id: string }).id,
                      type,
                    },
                  }),
          ] as const;
        })
        .reduce(
          (acc, [key, relationship]) => ({ ...acc, [key]: relationship }),
          {},
        ),
  }) || {}) as TTypeMeta[TTypeName]['create'];
}

export function createMapToCreateParameters<
  TTypes extends ApiTypes,
  TTypeName extends keyof TTypes & string,
  TTypeMeta extends TypeMeta<keyof TTypes> = never,
>(type: TTypeName, meta: JsonApiResourceMeta<TTypes, TTypeMeta>) {
  return (value: CreateObjectType<TTypeMeta, TTypeName>) =>
    mapToCreateParameters(
      value,
      meta[type].create?.attributes || [],
      meta[type].create?.relationships,
    );
}
