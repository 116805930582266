type NumericValueData = Readonly<
  { isValid: true; value: number } | { isValid: false; error: string }
>;

export class NumericValue {
  static from(value: number): NumericValue {
    return new NumericValue({ isValid: true, value });
  }

  static invalid(error: string) {
    return new NumericValue({ isValid: false, error });
  }

  private constructor(private readonly data: NumericValueData) {}

  toValue(): NumericValueData;
  toValue<TInvalidValue>(invalidValue: TInvalidValue): number | TInvalidValue;
  toValue<TInvalidValue>(
    ...invalidValue: Array<TInvalidValue>
  ): number | TInvalidValue | NumericValueData {
    if (invalidValue.length > 0) {
      return this.data.isValid ? this.data.value : invalidValue.length;
    } else {
      return this.data;
    }
  }

  multiplyBy(value: number): NumericValue {
    if (!this.data.isValid) {
      return this;
    } else {
      return new NumericValue({
        isValid: true,
        value: this.data.value * value,
      });
    }
  }

  isValid(): boolean {
    return this.data.isValid;
  }
}

export const isNumericValue = (value: unknown): value is NumericValue =>
  value instanceof NumericValue;
