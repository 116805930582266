import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccessTokenService,
  RefreshTokenInterceptor,
  UnauthorizedInterceptor,
} from '@bbraun/shared/data-access-security';
import { HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  EXPERT_LOADER_API_BASE_URL,
  EXPERT_LOADER_API_SERVICE,
} from './injection-tokens';
import { createTypedJsonApiService } from './provider-factories';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: EXPERT_LOADER_API_SERVICE,
      useFactory: createTypedJsonApiService,
      deps: [
        EXPERT_LOADER_API_BASE_URL,
        AccessTokenService,
        HttpBackend,
        HTTP_INTERCEPTORS,
      ],
    },
  ],
})
export class BavReportingDataAccessAdministrationModule {}
