import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { SECURITY_CONFIG } from '../interfaces/security-config';
import { LoginWithPrincipalService } from '../services/login-with-principal.service';

@Injectable({ providedIn: 'root' })
export class StartGuard implements CanActivate {
  readonly canActivate$: Observable<true | UrlTree>;

  constructor(
    private readonly principalService: LoginWithPrincipalService<unknown>,
    private readonly router: Router,
    @Inject(SECURITY_CONFIG)
    private readonly config: {
      routes: {
        default: string;
      };
    },
  ) {
    const defaultRoute = this.router.parseUrl(this.config.routes.default);
    this.canActivate$ = this.principalService.isPrincipalLoggedIn$
      .pipe(map((isLoggedIn) => (isLoggedIn ? defaultRoute : (true as const))))
      .pipe(catchError(() => of(true as const)))
      .pipe(distinctUntilChanged());
  }

  canActivate() {
    return this.canActivate$.pipe(first());
  }
}
