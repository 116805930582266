import { Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { isDefined } from '@bbraun/shared/util-rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OnNavigationErrorService {
  readonly error$: Observable<{ error: unknown }>;

  constructor(private readonly router: Router) {
    this.error$ = this.router.events
      .pipe(
        map((event) => {
          if (event instanceof NavigationError) {
            return {
              error: event.error,
            };
          } else {
            return undefined;
          }
        }),
      )
      .pipe(isDefined());
  }
}
