import { Injectable } from '@angular/core';
import { ScopedTranslationService } from '@bbraun/shared/util-transloco-ng';
import { TranslocoService } from '@ngneat/transloco';
import { SCOPE } from '../constants';

@Injectable({ providedIn: 'root' })
export class FeatureAisReportsScopedTranslationService extends ScopedTranslationService {
  constructor(translocoService: TranslocoService) {
    super(SCOPE, translocoService);
  }
}
