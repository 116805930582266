import { mergeImmutableInternal } from '../merge-immutable-internal';
import {
  Error,
  KeyType,
  MergeResult,
  ObjectType,
  Options,
  References,
} from '../merge-immutable.type';

const NOT_SAME_TYPE_SYMBOL = Symbol('NOT_SAME_TYPE');
const KEY_NOT_FOUND_SYMBOL = Symbol('KEY_NOT_FOUND');

export function mergeKeyValue<T extends Map<any, any> | ObjectType>(
  base: unknown,
  mergeObj: T,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
  config: {
    isSameType: (data: unknown) => data is T;
    createInstance: () => T;
    getValue: (data: T, key: any) => unknown;
    setValue: (data: T, key: any, value: unknown) => T;
    getKeys: (data: T) => ReadonlyArray<unknown>;
  },
): MergeResult<T> {
  const identityCheck = options.identityCheck;
  references = new Map([[mergeObj, base], ...references]);

  const entryKeys = config.getKeys(mergeObj);
  const baseKeySet = new Set(
    (config.isSameType(base) && config.getKeys(base)) || [],
  );

  let hasChanges = false;
  let isSame =
    config.isSameType(base) &&
    config.getKeys(base).length === config.getKeys(mergeObj).length;

  let error: Error | false = false;
  let result: T = config.createInstance();
  const paths: Array<ReadonlyArray<KeyType>> | false = options.paths && [];
  const diffs: Array<ReadonlyArray<KeyType>> | false = options.diffs && [];

  for (let index = 0; !error && index < entryKeys.length; index++) {
    const key = entryKeys[index];

    const value = config.getValue(mergeObj, key);

    const baseVal = config.isSameType(base)
      ? baseKeySet.has(key)
        ? config.getValue(base, key)
        : KEY_NOT_FOUND_SYMBOL
      : NOT_SAME_TYPE_SYMBOL;

    if (identityCheck(value, baseVal)) {
      result = config.setValue(result, key, baseVal);

      if (paths) {
        paths.push([key as KeyType]);
      }
    } else if (typeof value === 'object' && value && references.has(value)) {
      // cyclic reference in object to merge detected
      result = config.setValue(result, key, value);
      const isBaseCyclicReference = identityCheck(
        baseVal,
        references.get(value as object),
      );
      isSame = isSame && isBaseCyclicReference;

      if (isSame && paths) {
        paths.push([key as KeyType]);
      }
      if (!isSame && diffs) {
        diffs.push([key as KeyType]);
      }
    } else {
      let updatedReferences = references;
      if (typeof value === 'object' && value) {
        updatedReferences = new Map([[value, baseVal], ...references]);
      }
      const merged = mergeImmutableInternal(
        baseVal,
        value,
        updatedReferences,
        [...path, key as KeyType],
        [...diff, key as KeyType],
        startTime,
        options,
      );

      if (!merged.hasError) {
        const isMergedSame = identityCheck(merged.result, baseVal);

        hasChanges = hasChanges || merged.result !== value;
        isSame = isSame && isMergedSame;
        result = config.setValue(result, key, merged.result);

        if (paths) {
          if (isMergedSame) {
            paths.push([key as KeyType]);
          } else if (merged.paths) {
            paths.push(...merged.paths.map((p) => [key as KeyType, ...p]));
          }
        }

        if (
          diffs &&
          !isMergedSame &&
          base !== undefined &&
          baseVal !== NOT_SAME_TYPE_SYMBOL
        ) {
          const diffsToPush =
            !merged.diffs || merged.diffs.length <= 0
              ? [[key as KeyType]]
              : merged.diffs.map((p) => [key as KeyType, ...p]);
          diffs.push(...diffsToPush);
        }
      } else {
        error = merged.error;
      }
    }
  }
  if (diffs && !error) {
    diffs.push(...getDeletedPropertiesFromBase(base, mergeObj, { ...config }));
  }

  return error
    ? {
        hasError: true,
        error,
      }
    : {
        hasError: false,
        result: isSame
          ? (base as unknown as T)
          : hasChanges
          ? (result as T)
          : mergeObj,
        ...(paths ? { paths: isSame ? [] : paths } : {}),
        ...(diffs ? { diffs: isSame ? [] : diffs } : {}),
      };
}

function getDeletedPropertiesFromBase<T>(
  base: unknown,
  mergeObj: T,
  config: {
    isSameType: (data: unknown) => data is T;
    getKeys: (data: T) => ReadonlyArray<unknown>;
  },
): (readonly KeyType[])[] {
  const diffs: Array<ReadonlyArray<KeyType>> = [];
  const baseEntries = config.isSameType(base) ? config.getKeys(base) : [];
  const mergeEntries = config.getKeys(mergeObj);

  for (let index = 0; index < baseEntries.length; index++) {
    const entryKey = baseEntries[index];
    const hasMergeObjectBaseKey = mergeEntries.find((key) => key === entryKey);

    if (hasMergeObjectBaseKey === undefined) {
      diffs.push([entryKey as KeyType]);
    }
  }
  return diffs;
}
