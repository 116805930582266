import { Inject, Injectable } from '@angular/core';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { map, Observable } from 'rxjs';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import {
  ValidatorFactory,
  ValidatorFactoryFunction,
} from '@bbraun/bav-reporting/ui-report-editor';
import { createIsGreaterThanZeroWhenOneOfPropertyIsGreaterZeroValidator } from '../create-validators';
import { FeatureAisReportsScopedTranslationService } from '../../../services/feature-ais-reports-scoped-translation.service';

export const IS_GREATER_THAN_ZERO_WHEN_ONE_PROPERTY_IS_GREATER_ZERO_ID =
  'IS_GREATER_THAN_ZERO_WHEN_ONE_PROPERTY_IS_GREATER_ZERO';
export const IS_GREATER_THAN_ZERO_WHEN_ONE_PROPERTY_IS_GREATER_ZERO_ERROR =
  Symbol(IS_GREATER_THAN_ZERO_WHEN_ONE_PROPERTY_IS_GREATER_ZERO_ID);

@Injectable({
  providedIn: 'root',
})
export class IsGreaterThanZeroWhenOnePropertyIsNumberValidatorFactory
  implements ValidatorFactory<{ properties: ReadonlyArray<string> }>
{
  readonly id = IS_GREATER_THAN_ZERO_WHEN_ONE_PROPERTY_IS_GREATER_ZERO_ID;
  readonly validatorFn: Observable<
    ValidatorFactoryFunction<{ properties: ReadonlyArray<string> }>
  >;

  constructor(
    @Inject(FeatureAisReportsScopedTranslationService)
    private readonly scopedTranslationService: {
      loadedLang$: Observable<{ translate: TranslateFunction }>;
    },
  ) {
    this.validatorFn = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) => ({ properties }) => ({
        id: IS_GREATER_THAN_ZERO_WHEN_ONE_PROPERTY_IS_GREATER_ZERO_ERROR,
        validate:
          createIsGreaterThanZeroWhenOneOfPropertyIsGreaterZeroValidator(
            properties,
          ),
        message: translate(
          i18n(
            'bbraunBavReportingFeatureAisReports.validation.property.greaterZero.error.message',
          ),
        ),
      })),
    );
  }
}
