import { Component } from '@angular/core';
import { Buildinfo, BuildinfoService } from '@bbraun/shared/util-buildinfo';
import { Observable } from 'rxjs';

@Component({
  selector: 'bav-reporting-ui-common-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent {
  readonly buildinfo$: Observable<Buildinfo>;

  constructor(buildinfoService: BuildinfoService) {
    this.buildinfo$ = buildinfoService.get();
  }
}
