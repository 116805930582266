import { Injectable } from '@angular/core';
import { animationFrameScheduler, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  sampleTime,
  shareReplay,
  startWith,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoadBarService {
  public readonly isLoading$: Observable<boolean>;

  private readonly subject = new Subject<void>();

  private readonly isRunningSet = new Set<unknown>();

  constructor() {
    this.isLoading$ = this.subject
      .pipe(map(() => this.isRunningSet.size !== 0))
      .pipe(distinctUntilChanged())
      .pipe(sampleTime(1, animationFrameScheduler))
      .pipe(startWith(this.isRunningSet.size !== 0))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  addLoadingProcess(id: unknown) {
    this.isRunningSet.add(id);
    this.subject.next();
  }

  stopLoadingProcess(id: unknown) {
    this.isRunningSet.delete(id);
    this.subject.next();
  }
}
