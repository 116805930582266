import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { withSharedValue } from '@bbraun/shared/util-rxjs';
import { AccessTokenService } from './access-token.service';
import { PRINCIPAL_PROVIDER_TOKEN } from './injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class PrincipalService<TPrincipal = unknown> {
  readonly principal$: Observable<TPrincipal | undefined>;

  constructor(
    accessTokenService: AccessTokenService,
    @Inject(PRINCIPAL_PROVIDER_TOKEN)
    principalProvider: (decoded?: {
      [key: string]: unknown;
    }) => Observable<TPrincipal>,
  ) {
    this.principal$ = withSharedValue(
      accessTokenService.token$,
      (tokens) =>
        tokens ? principalProvider(tokens && tokens.decoded) : of(undefined),
      {
        onError: () => of(undefined),
      },
    );
  }
}
