export function findFirstInArray<TValue, TResult>(
  values: ReadonlyArray<TValue>,
  match: (value: TValue) => TResult | undefined,
): TResult | undefined {
  let value: TResult | undefined;
  for (let i = 0; !value && i < values.length; i++) {
    value = match(values[i]);
  }
  return value;
}
