import { Inject, Injectable } from '@angular/core';
import {
  CalculatedReportDetailsResponseModel,
  ReportDetailsResponseModel,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import { DxFormDataHandler } from '@bbraun/shared/util-devexpress';
import {
  ErrorCodeLookup,
  ErrorObject,
  lookUpError,
} from '@bbraun/shared/util-error';
import { ERROR_CODE_LOOKUP } from '@bbraun/shared/util-error-ng';
import { PickByDiscriminator } from '@bbraun/shared/util-lang';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { pickBy } from 'lodash-es';
import { calculateReportEditorFormDataHandlerState } from '../../../functions/calculate-report-editor-form-data-handler-state';
import {
  BlockIdentifiableReportModel,
  ReportEditorFormFields,
} from '../../../types/report-editor.types';
import { FormulaProperties } from '../../../types/types';
import { ReportEditorFormModel } from '../types/report-editor-form-model.type';
import { ReportEditorViewModel } from '../types/report-editor-view-model.type';

export type FormDataHandlerAction =
  | { action: 'reset' }
  | {
      action: 'updateCalculatedValues';
      data: CalculatedReportDetailsResponseModel;
    }
  | {
      action: 'updateValue';
      data: {
        value: ReportEditorFormFields[keyof ReportEditorFormFields];
        key: keyof ReportEditorFormFields;
      };
    }
  | {
      action: 'init';
      data: {
        reportModel: BlockIdentifiableReportModel;
        values: Partial<ReportDetailsResponseModel>;
        propertiesToBeCalculated: FormulaProperties;
        technicalNameToBlockIdentifierLookup: {
          [technicalName: string]: string;
        };
      };
    };

const CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_ID = Symbol(
  'CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_ID',
);

type ActionFunction<TAction extends FormDataHandlerAction['action']> = (
  {
    formDataHandler,
    viewModel,
    action,
  }: {
    formDataHandler: DxFormDataHandler<ReportEditorFormModel>;
    viewModel: ReportEditorViewModel;
    action: PickByDiscriminator<FormDataHandlerAction, 'action', TAction>;
  },
  {
    messageService,
    errorCodeLookup,
  }: {
    messageService: MessageService;
    errorCodeLookup: ReadonlyArray<ErrorCodeLookup>;
  },
) => void;

const ACTIONS_LOOKUP: Readonly<{
  [action in FormDataHandlerAction['action']]: ActionFunction<action>;
}> = {
  init: initAction,
  reset: resetAction,
  updateValue: updateValueAction,
  updateCalculatedValues: updateCalculatedValuesAction,
};

@Injectable({ providedIn: 'root' })
export class UpdateReportEditorFormDataHandlerStateFunctionFactory {
  private readonly services: {
    messageService: MessageService;
    errorCodeLookup: ReadonlyArray<ErrorCodeLookup>;
  };

  constructor(
    messageService: MessageService,
    @Inject(ERROR_CODE_LOOKUP)
    errorCodeLookup: ReadonlyArray<ErrorCodeLookup>,
  ) {
    this.services = {
      messageService,
      errorCodeLookup,
    };
  }

  create() {
    return <TAction extends FormDataHandlerAction['action']>(params: {
      formDataHandler: DxFormDataHandler<ReportEditorFormModel>;
      viewModel: ReportEditorViewModel;
      action: FormDataHandlerAction & { action: TAction };
    }) => {
      const action: TAction = params.action.action;
      const actionFn = ACTIONS_LOOKUP[action];
      actionFn(params, this.services);
    };
  }
}

function createOnCalculateFormDataHandlerStateError({
  errorCodeLookup,
  messageService,
}: {
  errorCodeLookup: ReadonlyArray<ErrorCodeLookup>;
  messageService: MessageService;
}) {
  return (error: ErrorObject) => {
    const errorLookupResult = lookUpError(error, errorCodeLookup);
    const genericErrorMessage =
      'An unknown error occurred while updating form data handler state';

    messageService.message(
      errorLookupResult?.message || genericErrorMessage,
      'error',
      {
        id: CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_ID,
        actions: [{ type: 'update' }],
        error,
      },
    );
  };
}

function initAction(
  {
    formDataHandler,
    action,
  }: {
    formDataHandler: DxFormDataHandler<ReportEditorFormModel>;
    action: PickByDiscriminator<FormDataHandlerAction, 'action', 'init'>;
  },
  {
    messageService,
    errorCodeLookup,
  }: {
    messageService: MessageService;
    errorCodeLookup: ReadonlyArray<ErrorCodeLookup>;
  },
) {
  const { formData } = calculateReportEditorFormDataHandlerState({
    updatedData: {},
    viewModel: action.data,
    formDataHandlerState: formDataHandler.state,
    onError: createOnCalculateFormDataHandlerStateError({
      errorCodeLookup,
      messageService,
    }),
  });

  formDataHandler.initData(formData);
}

function updateValueAction(
  {
    formDataHandler,
    viewModel,
    action,
  }: {
    formDataHandler: DxFormDataHandler<ReportEditorFormModel>;
    viewModel: ReportEditorViewModel;
    action: PickByDiscriminator<FormDataHandlerAction, 'action', 'updateValue'>;
  },
  {
    messageService,
    errorCodeLookup,
  }: {
    messageService: MessageService;
    errorCodeLookup: ReadonlyArray<ErrorCodeLookup>;
  },
) {
  const { key, value } = action.data;
  const { formData, changedProperties, dirtyProperties, errors } =
    calculateReportEditorFormDataHandlerState({
      updatedData: { [key]: value },
      viewModel,
      formDataHandlerState: formDataHandler.state,
      onError: createOnCalculateFormDataHandlerStateError({
        errorCodeLookup,
        messageService,
      }),
    });

  formDataHandler.initState(formData, {
    ...formDataHandler.state,
    changedProperties,
    dirtyProperties,
    errors,
  });
}

function updateCalculatedValuesAction(
  {
    formDataHandler,
    viewModel,
    action,
  }: {
    formDataHandler: DxFormDataHandler<ReportEditorFormModel>;
    viewModel: ReportEditorViewModel;
    action: PickByDiscriminator<
      FormDataHandlerAction,
      'action',
      'updateCalculatedValues'
    >;
  },
  {
    messageService,
    errorCodeLookup,
  }: {
    messageService: MessageService;
    errorCodeLookup: ReadonlyArray<ErrorCodeLookup>;
  },
) {
  const updatedData = pickBy(action.data, (value) => value !== null);
  const { formData, changedProperties, dirtyProperties, errors } =
    calculateReportEditorFormDataHandlerState({
      updatedData,
      viewModel,
      formDataHandlerState: formDataHandler.state,
      onError: createOnCalculateFormDataHandlerStateError({
        errorCodeLookup,
        messageService,
      }),
    });

  formDataHandler.initState(formData, {
    ...formDataHandler.state,
    changedProperties,
    dirtyProperties,
    errors,
  });
}

function resetAction({
  formDataHandler,
}: {
  formDataHandler: DxFormDataHandler<ReportEditorFormModel>;
}) {
  formDataHandler.initData(formDataHandler.state.original);
}
