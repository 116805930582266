import { hasProperty } from './has-property';
import { isOneTypeOf } from './is-one-type-of';
import { propertyIsOfType } from './property-is-of-type';
import { isUndefined } from './type-guards';

export function hasOptionalPropertyOfType<
  TPropertyName extends string | number | symbol,
  TPropertyType,
>(
  propertyName: TPropertyName,
  isPropertyType: (propertyValue: unknown) => propertyValue is TPropertyType,
): <T>(value: T) => value is T & { [key in TPropertyName]?: TPropertyType } {
  return <T>(
    value: T,
  ): value is T & { [key in TPropertyName]: TPropertyType } =>
    !hasProperty(propertyName)(value) ||
    propertyIsOfType(
      propertyName,
      isOneTypeOf(isUndefined, isPropertyType),
    )(value);
}
