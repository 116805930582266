import { mergeImmutableInternal } from '../merge-immutable-internal';
import {
  References,
  Options,
  MergeResult,
  KeyType,
  Error,
} from '../merge-immutable.type';

export function mergeArray<TMerge extends ReadonlyArray<unknown>>(
  base: unknown,
  mergeObj: TMerge,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
): MergeResult<TMerge> {
  const identityCheck = options.identityCheck;
  references = new Map([[mergeObj, base], ...references]);

  let hasChanges = false;
  let isSame = Array.isArray(base) && base.length === mergeObj.length;
  let error: Error | false = false;
  const result: unknown[] = [];
  const paths: Array<ReadonlyArray<KeyType>> | false = options.paths && [];
  const diffs: Array<ReadonlyArray<KeyType>> | false = options.diffs && [];

  if (Array.isArray(base)) {
    for (let index = 0; !error && index < mergeObj.length; index++) {
      const baseVal = base[index];
      const value = mergeObj[index];
      if (typeof value === 'object' && value && references.has(value)) {
        // cyclic reference in object to merge detected
        result[index] = value;
        const isBaseCyclicReference = identityCheck(
          baseVal,
          references.get(value),
        );
        isSame = isSame && isBaseCyclicReference;
        if (isSame && paths) {
          paths.push([index]);
        }
        if (!isSame && diffs) {
          diffs.push([index]);
        }
      } else {
        let updatedReferences = references;
        if (typeof value === 'object' && value) {
          updatedReferences = new Map([[value, baseVal], ...references]);
        }

        const merged = mergeImmutableInternal(
          baseVal,
          value,
          updatedReferences,
          [...path, index],
          [...diff, index],
          startTime,
          options,
        );

        if (!merged.hasError) {
          const isMergedSame = identityCheck(merged.result, baseVal);

          hasChanges = hasChanges || merged.result !== value;
          isSame = isSame && isMergedSame;
          result[index] = merged.result;

          if (paths) {
            if (isMergedSame) {
              paths.push([index]);
            } else if (merged.paths) {
              paths.push(...merged.paths.map((p) => [index, ...p]));
            }
          }
          if (diffs && !isMergedSame) {
            const diffsToPush =
              !baseVal || !merged.diffs || merged.diffs.length <= 0
                ? [[index]]
                : merged.diffs.map((p) => [index, ...p]);
            diffs.push(...diffsToPush);
          }
        } else {
          error = merged.error;
        }
      }
    }
    if (diffs) {
      diffs.push(...getDeletedPropertiesFromBaseArray(base, mergeObj, error));
    }
  }

  return error
    ? {
        hasError: true,
        error,
      }
    : isSame
    ? {
        hasError: false,
        result: base as unknown as TMerge,
        ...(paths ? { paths: [] } : {}),
        ...(diffs ? { diffs: [] } : {}),
      }
    : hasChanges
    ? {
        hasError: false,
        result: result as unknown as TMerge,
        ...(paths ? { paths } : {}),
        ...(diffs ? { diffs } : {}),
      }
    : {
        hasError: false,
        result: mergeObj,
        ...(paths ? { paths } : {}),
        ...(diffs ? { diffs } : {}),
      };
}

function getDeletedPropertiesFromBaseArray<
  TMerge extends ReadonlyArray<unknown>,
>(base: any[], mergeObj: TMerge, error: Error | false) {
  const diffs: Array<ReadonlyArray<KeyType>> = [];
  for (let index = 0; !error && index < base.length; index++) {
    const value = mergeObj[index];
    if (value === undefined) {
      if (diffs) {
        diffs.push([index]);
      }
    }
  }
  return diffs;
}
