import { SortSpecsBuilder } from '@bbraun/shared/util-lang';
import { combineFiqlFilter } from '../combine-fiql-filter';
import { ApiTypes } from './api-types';
import { Fieldsets } from './fieldsets';
import { TypedQueryParameters } from './typed-query-parameters';

export function combineTypedQueryParameters<
  TTypes extends ApiTypes,
  TResource extends keyof TTypes,
  TFieldsets extends Partial<Fieldsets<TTypes>>,
>(
  params1: TypedQueryParameters<TTypes, TFieldsets, TResource>,
  params2: TypedQueryParameters<TTypes, TFieldsets, TResource>,
): TypedQueryParameters<TTypes, TFieldsets, TResource> {
  const sort =
    params1.sort && params2.sort
      ? combineSort(params1.sort, params2.sort)
      : params1.sort
      ? params1.sort
      : params2.sort;
  const filter = combineFiqlFilter(params1.filter, params2.filter);
  const fieldsets =
    params1.fieldsets && params2.fieldsets
      ? { ...params1.fieldsets, ...params2.fieldsets }
      : params1.fieldsets
      ? params1.fieldsets
      : params2.fieldsets;

  return {
    filter,
    sort,
    page: params2.page ? params2.page : params1.page,
    fieldsets,
    include: [...(params1.include || []), ...(params2.include || [])],
    custom: {
      ...params1.custom,
      ...params2.custom,
    },
  };
}

function combineSort<T>(
  sort1: SortSpecsBuilder<T>,
  sort2: SortSpecsBuilder<T>,
): SortSpecsBuilder<T> {
  return sort2.specs.reduce((specs, spec) => specs.add(spec), sort1);
}
