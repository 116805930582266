import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { AccessTokenService } from '@bbraun/shared/data-access-security';
import {
  ApiDefinition,
  ApiTypes,
  JsonApiMeta,
  JsonApiResourceMeta,
  TypedJsonApiHttpClientFactory,
  TypedJsonApiServiceImpl,
} from '@bbraun/shared/util-jsonapi';
import { API_BASE_URL } from './jsonapi-ng.service';
import { createHttpClientConnector } from './jsonapi-http-client-connector';

export class TypedJsonApiNgService<
  TApiDefinition extends ApiDefinition<ApiTypes>,
> extends TypedJsonApiServiceImpl<TApiDefinition> {
  constructor(
    apiMeta: JsonApiMeta,
    jsonApiResourceMeta: JsonApiResourceMeta<
      TApiDefinition['resourceTypes'],
      TApiDefinition['resourceTypesMeta']
    >,
    accessTokenService: AccessTokenService,
    httpClientFactory: TypedJsonApiHttpClientFactory<
      TApiDefinition['resourceTypes'],
      HttpClient
    >,
    @Inject(API_BASE_URL) baseUrl: string,
  ) {
    super(
      apiMeta,
      jsonApiResourceMeta,
      accessTokenService,
      (type) => createHttpClientConnector(httpClientFactory(type)),
      baseUrl,
    );
  }
}
