import {
  DataObject,
  DataObjectCollection,
  MetaObject,
  ResourceIdentifierObject,
  ResourceLinkage,
  ResourceLinkageCollection,
  ResourceObject,
} from './json-api.types';
import {
  isMutableModelObjectValue,
  MutableDataModelObject,
  MutableObjectsByTypeAndId,
  MutableReferenceMap,
  MutableRelationships,
} from './model-object-mutable.type';
import {
  IsIncluded,
  ModelObject,
  ObjectsByTypeAndId,
} from './model-object.type';
import {
  ID,
  INCLUDED,
  LINKS,
  META,
  RELATIONSHIPS,
  REFERENCES,
  TYPE,
} from './symbols.type';
import { ApiDefinition, ApiTypes } from './typed/api-types';
import {
  MultiRelationshipKeys,
  SingleRelationshipKeys,
} from './typed/resource-keys.type';

type ReferenceMap<TApiDefinition extends ApiDefinition<ApiTypes>> =
  MutableReferenceMap<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes'],
    (
      | SingleRelationshipKeys<
          TApiDefinition['resourceTypes'][keyof TApiDefinition['resourceTypes']]
        >
      | MultiRelationshipKeys<
          TApiDefinition['resourceTypes'][keyof TApiDefinition['resourceTypes']]
        >
    ) &
      keyof TApiDefinition['resources'][keyof TApiDefinition['resourceTypes']]['relationships']
  >;

export function mapDataObjectOrDataObjectCollection<
  TApiDefinition extends ApiDefinition<ApiTypes>,
>(
  data: DataObject,
  included: ResourceObject[],
  callbacks?: {
    updateCallback?: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
    createCallback?: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
  },
  createReferenceMap?: () => ReferenceMap<TApiDefinition>,
): {
  result?: ModelObject<TApiDefinition, keyof TApiDefinition['resourceTypes']> &
    IsIncluded<TApiDefinition, keyof TApiDefinition['resourceTypes']>;
  objects: ObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >;
};
export function mapDataObjectOrDataObjectCollection<
  TApiDefinition extends ApiDefinition<ApiTypes>,
>(
  data: DataObjectCollection,
  included: ResourceObject[],
  callbacks?: {
    updateCallback?: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
    createCallback?: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
  },
  createReferenceMap?: () => ReferenceMap<TApiDefinition>,
): {
  result?: Array<
    ModelObject<TApiDefinition, keyof TApiDefinition['resourceTypes']> &
      IsIncluded<TApiDefinition, keyof TApiDefinition['resourceTypes']>
  >;
  objects: ObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >;
};
export function mapDataObjectOrDataObjectCollection<
  TApiDefinition extends ApiDefinition<ApiTypes>,
>(
  data: DataObject | DataObjectCollection,
  included: ResourceObject[],
  callbacks?: {
    updateCallback?: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
    createCallback?: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
  },
  createReferenceMap: () => ReferenceMap<TApiDefinition> = () => new WeakMap(),
): {
  result?:
    | (ModelObject<TApiDefinition, keyof TApiDefinition['resourceTypes']> &
        IsIncluded<TApiDefinition, keyof TApiDefinition['resourceTypes']>)
    | Array<
        ModelObject<TApiDefinition, keyof TApiDefinition['resourceTypes']> &
          IsIncluded<TApiDefinition, keyof TApiDefinition['resourceTypes']>
      >;
  objects: ObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >;
} {
  const objects: MutableObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  > = new Map();
  const options = {
    updateCallback: (callbacks && callbacks.updateCallback) || (() => {}),
    createCallback: (callbacks && callbacks.createCallback) || (() => {}),
    createReferenceMap,
  };

  if (data !== null) {
    const dataObjects = Array.isArray(data) ? data : [data];
    const result = addNewObjects(dataObjects, objects, options);
    addNewObjects(included, objects, options);

    dataObjects.forEach((dataObject) =>
      updateObject(dataObject, objects, options),
    );
    included.forEach((dataObject) =>
      updateObject(dataObject, objects, options),
    );

    return {
      result: Array.isArray(data)
        ? (result as Array<
            ModelObject<TApiDefinition, keyof TApiDefinition['resourceTypes']> &
              IsIncluded<TApiDefinition, keyof TApiDefinition['resourceTypes']>
          >)
        : (result[0] as ModelObject<
            TApiDefinition,
            keyof TApiDefinition['resourceTypes']
          > &
            IsIncluded<TApiDefinition, keyof TApiDefinition['resourceTypes']>),
      objects,
    };
  } else {
    return { result: undefined, objects };
  }
}

function mapLinks(links: {
  [rel: string]: string | { href: string; meta?: MetaObject };
}): { [rel: string]: { href: string; meta: MetaObject } } {
  const initial: { [rel: string]: { href: string; meta: MetaObject } } = {};

  return Object.entries(links).reduce((result, [key, value]) => {
    result[key] =
      typeof value === 'string'
        ? { href: value, meta: {} }
        : { href: value.href, meta: value.meta || {} };
    return result;
  }, initial);
}

function updateObject<TApiDefinition extends ApiDefinition<ApiTypes>>(
  resourceObject: ResourceObject,
  objects: MutableObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >,
  {
    createCallback,
    updateCallback,
    createReferenceMap,
  }: {
    updateCallback: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
    createCallback: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
    createReferenceMap: () => ReferenceMap<TApiDefinition>;
  },
) {
  const object = lookupObject(resourceObject, objects, {
    create: false,
  });

  object[INCLUDED] = true;

  if (isMutableModelObjectValue(object)) {
    object[META] = resourceObject.meta || {};
    object[LINKS] = mapLinks(resourceObject.links || {});
    object[RELATIONSHIPS] = object[RELATIONSHIPS] || {};

    Object.entries(resourceObject.attributes || {}).forEach(
      ([key, value]) => (object[key] = value),
    );

    Object.entries(resourceObject.relationships || {}).forEach(
      ([keyAsString, { data, links, meta }]) => {
        const key = keyAsString as keyof MutableRelationships<
          TApiDefinition,
          keyof TApiDefinition['resourceTypes']
        >;
        const relationships = object[RELATIONSHIPS] || {};
        const relationship = relationships[key] || {
          [META]: {},
          [LINKS]: {},
          [REFERENCES]: createReferenceMap(),
        };
        relationships[key] = relationship;

        if (relationship) {
          relationship[META] = meta || {};
          relationship[LINKS] = mapLinks(links || {});
          relationship[REFERENCES] =
            relationship[REFERENCES] || createReferenceMap();

          const value = isResourceLinkageCollection(data)
            ? data.map((linkage) => {
                const o = lookupObject(linkage, objects, {
                  create: true,
                  createCallback,
                });

                relationship[REFERENCES].set(o as any, {
                  [META]: linkage.meta || {},
                });

                return o;
              })
            : data
            ? lookupObject(data, objects, { create: true, createCallback })
            : undefined;

          if (value) {
            (object as unknown as Record<string | number | symbol, unknown>)[
              key
            ] = value;
          }
        }
      },
    );
  }

  updateCallback(object);
}

function addNewObjects<TApiDefinition extends ApiDefinition<ApiTypes>>(
  dataObjects: ResourceIdentifierObject[],
  objects: MutableObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >,
  {
    createCallback,
  }: {
    createCallback: (
      object: MutableDataModelObject<
        TApiDefinition,
        keyof TApiDefinition['resourceTypes']
      >,
    ) => void;
  },
) {
  const results: MutableDataModelObject<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >[] = [];

  dataObjects.forEach((dataObject) => {
    const result = lookupObject(dataObject, objects, {
      create: true,
      createCallback,
    });
    results.push(result);
  });

  return results;
}

function lookupObject<TApiDefinition extends ApiDefinition<ApiTypes>>(
  { type, id }: ResourceIdentifierObject,
  objects: MutableObjectsByTypeAndId<
    TApiDefinition,
    keyof TApiDefinition['resourceTypes']
  >,
  options:
    | { create: false }
    | {
        create: true;
        createCallback: (
          object: MutableDataModelObject<
            TApiDefinition,
            keyof TApiDefinition['resourceTypes']
          >,
        ) => void;
      },
) {
  let objectsForType = objects.get(type);

  if (!objectsForType) {
    if (options.create) {
      objectsForType = new Map();
      objects.set(type, objectsForType);
    } else {
      throw new Error(
        `Failed to look up id: <${id}> type: <${type}>. No objects of type found.`,
      );
    }
  }

  const object = objectsForType.get(id);

  if (!object) {
    if (options.create) {
      const newObject = {
        [ID]: id,
        [TYPE]: type,
        [INCLUDED]: false as const,
        [META]: {},
        [LINKS]: {},
      };
      options.createCallback(newObject);
      objectsForType.set(id, newObject);

      return newObject;
    } else {
      throw new Error(
        `Failed to look up id: <${id}> type: <${type}>. No such id for type.`,
      );
    }
  } else {
    return object;
  }
}

function isResourceLinkageCollection(
  v: ResourceLinkage | ResourceLinkageCollection | undefined,
): v is ResourceLinkageCollection {
  return Array.isArray(v);
}
