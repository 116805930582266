// enum corresponding to the number returned by Date.prototype.getDay()
export enum DayOfWeek {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 0,
}
