import {
  Inject,
  InjectionToken,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';

const AS_TYPE_PIPE_DUMMY_INJECTION_TOKEN = new InjectionToken<any>(
  'LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN',
);

export const FAILED_TYPE_CONVERSION_SYMBOL = Symbol('FAILED_TYPE_CONVERSION');

/**
 * This is a pipe base class that is not intended to be used as a pipe directly.
 * The pipe annotation is required by angular (empty Directive would be enough) and angular TestBed (fails without selector name).
 * This also forces us to define injection tokens for each parameter, even if we never ever want this to be injected anywhere.
 * https://angular.io/guide/migration-undecorated-classes
 * https://github.com/angular/angular/issues/36427
 */
@Pipe({ name: 'bbraunUtilLangNgAsTypeBase', pure: false })
export class AsTypePipeBase<T> implements PipeTransform {
  constructor(
    @Optional()
    @Inject(AS_TYPE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly typeGuard: (value: unknown) => value is T,
    @Optional()
    @Inject(AS_TYPE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly skipCheck: boolean,
    @Optional()
    @Inject(AS_TYPE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly onTypeMismatch: (value: unknown) => T,
  ) {}

  transform(value: unknown): T {
    if (this.skipCheck || this.typeGuard(value)) {
      return value as T;
    } else {
      return this.onTypeMismatch(value);
    }
  }
}
