import { DxFormComponent } from 'devextreme-angular';
import Editor from 'devextreme/ui/editor/editor';
import dxForm from 'devextreme/ui/form';

export function callWithDxFormEditorOrFail<R, TEditor extends Editor = Editor>(
  form: dxForm,
  dataField: string,
  fn: (editor: TEditor) => R,
): R {
  if (!form) {
    throw new Error('DxForm is not defined or null!');
  }
  const editor = form.getEditor(dataField);
  if (!editor) {
    throw new Error(`Editor for dataField '${dataField}' is not defined!`);
  }
  return fn(editor as TEditor);
}

export function callWithDxFormComponentEditorOrFail<
  R,
  TEditor extends Editor = Editor,
>(
  component: DxFormComponent | undefined,
  dataField: string,
  fn: (editor: TEditor) => R,
): R {
  if (!component) {
    throw new Error('DxFormComponent is not defined or null!');
  }
  return callWithDxFormEditorOrFail(component.instance, dataField, fn);
}

export function callWithDxFormComponentEditor<
  R,
  TEditor extends Editor = Editor,
>(
  component: DxFormComponent | undefined,
  dataField: string,
  fn: (editor: TEditor) => R,
): R | undefined {
  if (!component) {
    return undefined;
  }
  const editor = component.instance.getEditor(dataField);
  if (!editor) {
    return undefined;
  }
  return fn(editor as TEditor);
}
