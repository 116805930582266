import { Directive, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Directive({
  /* eslint-disable-next-line @angular-eslint/directive-selector */
  selector: 'bbraun-ui-discard-changes-changed-guard-router-outlet',
})
export class ChangedGuardRouterOutletDirective extends RouterOutlet {
  @HostListener('window:beforeunload', ['$event'])
  beforeunload(event: BeforeUnloadEvent) {
    const component =
      this.isActivated && (this.component as Readonly<{ changed?: boolean }>);

    if (
      component &&
      typeof component.changed === 'boolean' &&
      component.changed
    ) {
      event.preventDefault();
      event.returnValue = '';
    }
  }
}
