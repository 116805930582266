import { isBoolean, isNumber } from '@bbraun/shared/util-lang';

interface FormData {
  [technicalName: string]: string | number | boolean | null | undefined;
}

interface ValidationData {
  value: string | number | boolean | null | undefined;
  formData?: FormData;
}

export function createIsZeroOrEqualToNumberOfChronicHdPatientsValidator() {
  return ({ value, formData }: ValidationData) => {
    const precondition =
      formData &&
      isNumber(value) &&
      isNumber(formData.ownPatientHemoDialysis3Month);

    return (
      !precondition ||
      value === 0 ||
      value === formData.ownPatientHemoDialysis3Month
    );
  };
}

export function createIsLowerOrEqualToPropertyValidator(property: string) {
  return ({ value, formData }: ValidationData) => {
    const propertyValue = formData?.[property];
    const precondition = isNumber(propertyValue) && isNumber(value);

    return !precondition || value <= propertyValue;
  };
}

export function createIsGreaterThanZeroWhenOneOfPropertyIsGreaterZeroValidator(
  properties: ReadonlyArray<string>,
) {
  return ({ value, formData }: ValidationData) => {
    const precondition =
      formData && isOnePropertyGreaterZero(formData, properties);

    return !precondition || (isNumber(value) && value > 0);
  };
}

export function createOneHeparinPatientsIsSetWhenMonthFebruaryOrAugustValidator(
  month: number,
) {
  return ({ formData }: ValidationData) => {
    const precondition = month === 2 || month === 8;
    return (
      !precondition ||
      isNumber(formData?.heparinStandardPatients) ||
      isNumber(formData?.heparinEnoxLPatients) ||
      isNumber(formData?.heparinEnoxGPatients) ||
      isNumber(formData?.heparinEnoxAPatients) ||
      isNumber(formData?.heparinEnoxCPatients) ||
      isNumber(formData?.heparinEnoxEPatients) ||
      isNumber(formData?.heparinNadrPatients) ||
      isNumber(formData?.heparinDaltPatients) ||
      isNumber(formData?.heparinTinzPatients) ||
      isNumber(formData?.heparinBemiPatients)
    );
  };
}

export function createIsPropertyAndValueNumberOrBothNotNumberValidator(
  property: string,
) {
  return ({ value, formData }: ValidationData) => {
    const isFormulaCounterDefined = !!(
      formData && isNumber(formData[property])
    );

    const isValueDefined = isNumber(value);

    return isFormulaCounterDefined === isValueDefined;
  };
}

export function createIsBooleanValidator() {
  return ({ value }: ValidationData) => isBoolean(value);
}

function isOnePropertyGreaterZero(
  formData: FormData,
  properties: ReadonlyArray<string>,
): boolean {
  return properties.some((propertyName) => {
    const property = formData[propertyName];
    return (
      property !== null &&
      property !== undefined &&
      typeof property === 'number' &&
      property > 0
    );
  });
}
