import { EMPTY, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  concatMap,
  endWith,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  MessageServiceOptions,
  MessageSeverity,
  ReducedToastrService,
  Toast,
} from './message.service.type';
import { showMessage } from './show-message';

export class ReactiveToast {
  private readonly pushMessage$ = new Subject<void>();
  private readonly toasts$: Observable<Toast>;
  private subscription?: Subscription;

  constructor(
    message$: Observable<Readonly<{ message: string; title?: string }>>,
    type: MessageSeverity,
    enableHtml: boolean,
    timeOut: boolean | number,
    isSkipped$: Observable<boolean>,
    options: MessageServiceOptions,
    isDebugEnabled: boolean,
    toasterService: ReducedToastrService,
    onTapFn?: () => Observable<void>,
  ) {
    const messageReplay$ = message$.pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.toasts$ = this.pushMessage$
      .pipe(filter((_, index) => index < options.count.max))
      .pipe(
        concatMap((_, index) =>
          messageReplay$.pipe(map((v) => ({ ...v, count: index + 1 }))),
        ),
      )
      .pipe(
        switchMap((data) =>
          isSkipped$.pipe(
            switchMap((isSkipped) => (isSkipped ? EMPTY : of(data))),
          ),
        ),
      )
      .pipe(
        switchMap(({ message, title, count }) => {
          let hidden = false;

          return new Observable<Toast>((subscriber) => {
            const toast = showMessage(
              {
                isDebugEnabled,
                type,
                title: options.count.title(
                  count,
                  title || options.titles[type],
                ),
                message,
                enableHtml,
                timeOut:
                  timeOut === false || typeof timeOut === 'number'
                    ? timeOut
                    : type === 'info' || type === 'success'
                    ? options.timeouts.short
                    : type === 'debug' &&
                      (options.timeouts.debug === false ||
                        typeof options.timeouts.debug === 'number')
                    ? options.timeouts.debug
                    : options.timeouts.medium,
              },
              toasterService,
            );

            subscriber.next(toast);

            return () => {
              if (!hidden) {
                // Only remove the toast, if it has not been hidden yet, i.e. no notification on onHidden.
                // This check is necessary, because a onHidden notification is triggered by the removal of a message
                // and results in a unsubscribe which results again in the removal of the hidden message.
                // This triggers a bug in toastr and an additional toast is removed from the internal data structure
                // but not removed from DOM.
                //
                // issue: https://github.com/scttcper/ngx-toastr/issues/863
                //
                // see: frontend\node_modules\ngx-toastr\bundles\ngx-toastr.umd.js#ToastrService.prototype.remove
                //
                // ToastrService.prototype.remove = function (toastId) {
                //    // (1) the toast gets hidden and is found in this.toasts at index X
                //    // (3) if onHidden triggers the removal we reenter this method and the toast has not yet been removed
                //    //     from this.toasts and the toast is found again at index X
                //    var found = this._findToast(toastId);
                //    if (!found) {
                //      return false;
                //    }
                //    // (2) this triggers onHidden before this.toasts has been updated
                //    found.activeToast.toastRef.close();
                //    // (4) now in our second call the code is removing the toast at index X in this.toasts, which is the removed toast
                //    // (5) now in our initial call the code is removing the toast at index X AGAIN, which is some other toast
                //    this.toasts.splice(found.index, 1);
                // ...
                toasterService.remove(toast.toastId);
              }
            };
          }).pipe(
            concatMap((toast) =>
              merge(
                of(toast),
                toast.onHidden
                  .pipe(take(1))
                  .pipe(
                    switchMap(() => {
                      hidden = true;
                      return EMPTY;
                    }),
                  )
                  .pipe(
                    catchError(() => {
                      hidden = true;
                      return EMPTY;
                    }),
                  )
                  .pipe(endWith(false as const)),
                isDebugEnabled && onTapFn
                  ? toast.onTap
                      .pipe(switchMap(onTapFn))
                      .pipe(switchMap(() => EMPTY))
                  : EMPTY,
              ),
            ),
          );
        }),
      )
      .pipe(takeWhile((toast) => !!toast))
      .pipe(concatMap((v) => (!v ? EMPTY : of(v))))
      .pipe(tap({ complete: () => this.remove() }))
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  remove() {
    const subscription = this.subscription;

    if (subscription) {
      subscription.unsubscribe();
    }

    return subscription;
  }

  push(): Subscription {
    if (!this.subscription) {
      this.subscription = this.toasts$.subscribe();
      this.subscription.add(() => (this.subscription = undefined));
    }

    this.pushMessage$.next();

    return this.subscription;
  }
}
