export type DxFilterOperator = typeof operators[number];

const operators = [
  '=',
  '<>',
  '>',
  '>=',
  '<',
  '<=',
  'startswith',
  'endswith',
  'contains',
  'notcontains',
] as const;

export function isDxFilterOperator(
  element: unknown,
): element is DxFilterOperator {
  const operatorsAsStrings: ReadonlyArray<string> = operators;
  return typeof element === 'string' && operatorsAsStrings.includes(element);
}
