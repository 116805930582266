import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { TranslocoModule } from '@ngneat/transloco';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';

@NgModule({
  imports: [CommonModule, TranslocoModule],
})
export class UtilTranslocoMessageModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      'bbraunSharedUtilTranslocoMessage',
    );
  }
}
