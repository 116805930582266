/**
 * Supports readonly array.
 * @param value
 * @returns
 */
export function isArray<T extends ReadonlyArray<unknown>>(
  value: T | unknown,
): value is T {
  return Array.isArray(value);
}
