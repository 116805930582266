import {
  AfterViewInit,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { callWithDxFormComponentEditor } from '@bbraun/shared/util-devexpress';
import { DxFormComponent } from 'devextreme-angular';
import { LoginRequest } from '../../interfaces/login-request';
import { LOGIN_DIALOG_DATA_TEST } from './login-dialog.data-test';

@Component({
  selector: 'bbraun-ui-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements AfterViewInit {
  @ViewChild(DxFormComponent)
  loginForm?: DxFormComponent;

  @Output()
  loginRequested = new EventEmitter<LoginRequest>();

  loginRequest: LoginRequest = {
    username: '',
    password: '',
  };

  private dirty = false;

  readonly dataTest = LOGIN_DIALOG_DATA_TEST;

  ngAfterViewInit() {
    callWithDxFormComponentEditor(this.loginForm, 'username', (e) => e.focus());
  }

  onFieldDataChanged() {
    this.dirty = true;
  }

  validate(options: { value?: string | number }) {
    if (this.dirty === true) {
      return this.isValid(options.value);
    } else {
      return true;
    }
  }

  submit() {
    this.dirty = true;
    const loginRequest = this.loginRequest;
    if (this.loginForm) {
      const result = this.loginForm.instance.validate();
      if (result.isValid) {
        this.loginRequested.emit(loginRequest);
        this.loginForm.instance.updateData('password', '');
        this.dirty = false;
      }
    }
  }

  private isValid(value?: string | number) {
    if (value && typeof value === 'string') {
      if (value.trim() !== '') {
        return true;
      }
    }

    return false;
  }
}
