import { MessageService } from '@bbraun/shared/util-message-ng';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { combineLatest, defer, Observable } from 'rxjs';
import { first, map, shareReplay, tap } from 'rxjs/operators';

export type PolyfillLoadResult =
  | { polyfill: string; shouldPolyfill: false }
  | {
      polyfill: string;
      shouldPolyfill: true;
      loaded: boolean;
      error?: any;
    };

function loadPolyfill(
  polyfill: string,
  shouldPolyfillTest: () => boolean,
  importer: () => Promise<unknown>,
): Observable<PolyfillLoadResult> {
  const base = { shouldPolyfill: true };
  return defer(() => {
    if (shouldPolyfillTest()) {
      return importer()
        .then(() => ({ ...base, loaded: true }))
        .catch((error) => ({ ...base, loaded: false, error }));
    } else {
      return Promise.resolve({ shouldPolyfill: false as const });
    }
  }).pipe(
    map((result) => ({
      ...result,
      polyfill,
    })),
  );
}

const getCanonicalLocalesPolyfillLoaded$ = loadPolyfill(
  '@formatjs/intl-getcanonicallocales/polyfill',
  shouldPolyfillGetCanonicalLocales,
  () => import('@formatjs/intl-getcanonicallocales/polyfill'),
);

const localePolyfillLoadded$ = loadPolyfill(
  '@formatjs/intl-locale/polyfill',
  shouldPolyfillLocale,
  () => import('@formatjs/intl-locale/polyfill'),
);

const dateTimeFormatPolyfillLoaded$ = loadPolyfill(
  '@formatjs/intl-datetimeformat/polyfill',
  () => !!shouldPolyfillDateTimeFormat(),
  () => import('@formatjs/intl-datetimeformat/polyfill'),
);

const debugIntlPolyfillsMessage = Symbol('debugIntlPolyfillsMessage');

const polyfillsLoaded$ = combineLatest([
  getCanonicalLocalesPolyfillLoaded$,
  localePolyfillLoadded$,
  dateTimeFormatPolyfillLoaded$,
])
  .pipe(first())
  .pipe(shareReplay({ bufferSize: 1, refCount: false }));

export function loadIntlPolyfills(
  messageService: MessageService,
): Observable<Array<PolyfillLoadResult>> {
  return polyfillsLoaded$.pipe(
    tap((results) => {
      messageService.message('Intl polyfills processed', 'debug', {
        id: debugIntlPolyfillsMessage,
        actions: [{ type: 'update' }],
        values: results,
        timeOut: false,
      });
    }),
  );
}
