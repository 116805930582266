import { InjectionToken } from '@angular/core';
import { TypedJsonApiService } from '@bbraun/shared/util-jsonapi';
import { BavReportingJsonApi } from './bav-reporting-json-api.type';

export type BavReportingApiService = TypedJsonApiService<BavReportingJsonApi>;

export const BAV_REPORTING_API_SERVICE =
  new InjectionToken<BavReportingApiService>('BAV_REPORTING_API_SERVICE');

export const BAV_REPORTING_API_BASE_URL = new InjectionToken<
  string | undefined
>('BAV_REPORTING_API_BASE_URL');
