const base = 'bbraun-ui-toolbar';

export const TOOLBAR_DATA_TEST = {
  sidebarToggle: { selector: `${base}:sidebarToggle` },
  isLoading: {
    selector: `${base}:isLoading`,
  },
  isNotLoading: {
    selector: `${base}:isNotLoading`,
  },
};
