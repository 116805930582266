<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiLanguage';
    read: 'bbraunSharedUiLanguage.languageComponent'
  "
>
  <h1 class="page-title">{{ translate('title.text') }}</h1>

  <bbraun-ui-language-dialog
    [supportedLanguages]="supportedLanguages"
    [currentLanguage]="currentLanguage$ | async"
    (languageSelected)="languageSelected($event)"
    (aborted)="aborted()"
  >
  </bbraun-ui-language-dialog>
</ng-container>
