import { Nullable } from '@bbraun/shared/util-lang';
import { isInteger as _isInteger, isString as _isString } from 'lodash-es';
import { Validator } from '../validator/validator.type';

/**
 * Property validator function that checks if the value is a number. Does not
 * fail if the value is null or undefined
 *
 * @param value - the value
 * @return true if the value is number
 */
export function isNumber<T extends {}, K extends keyof T>({
  value,
}: Parameters<Validator<T, K>>[0]): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  return typeof value === 'number';
}

/**
 * Property validator function that checks if the value is not null, undefined
 * or an empty string
 *
 * @param value - the value
 * @return true if the value is not null, undefined or an empty string
 */
export function isDefined<T extends {}, K extends keyof T>({
  value,
}: Parameters<Validator<T, K>>[0]): boolean {
  if (value === null || value === undefined || (value as any) === '') {
    return false;
  }

  return true;
}

/**
 * Creates a property validator function that checks if the value is not null, undefined
 * or an empty string conditional on the selector being set
 *
 * @param selector - the property whose has to be truthy in order to activate validation
 * @return true if the selector is false or if the selector is true and the property is not null, undefined
 * or an empty string
 */
export function createConditionalRequiredValidatorFn<
  T extends {},
  KSelector extends keyof T,
>(
  selector: KSelector,
): <KProperty extends keyof T>(data: {
  value: Partial<Nullable<T>>[KProperty];
  formData?: Partial<Nullable<T>>;
}) => boolean {
  return ({ value, formData }) => {
    if (formData && formData[selector]) {
      if (value === null || value === undefined || (value as any) === '') {
        return false;
      }
    }
    return true;
  };
}

/**
 * Property validator function that checks if the value is an integer value. Does
 * not fail if the value is null or undefined.

 * @param value - the value
 * @return true - if the value is an integer
 */
export function isInteger<T extends {}, K extends keyof T>({
  value,
}: Parameters<Validator<T, K>>[0]): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  return _isInteger(value);
}

/**
 * Property validator function that checks if the value is a string. Does not
 * fail if the value is null or undefined
 *
 * @param value - the value
 * @return true if the value is string
 */
export function isString<T extends {}, K extends keyof T>({
  value,
}: Parameters<Validator<T, K>>[0]): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  return _isString(value);
}

/**
 * Property validator function factory that creates a validation function
 * that checks if a given value is greater or equal to a given number.
 * Null, undefined and non-number values are deemed valid.
 *
 * @param min - the minimum valid value
 * @return true if the value is greater or equal to the min value
 */
export function createIsNumberGreaterOrEqualTo<T extends {}, K extends keyof T>(
  min: number,
): Validator<T, K> {
  return ({ value }) => {
    if (value === null || value === undefined || !(typeof value === 'number')) {
      return true;
    }

    return value >= min;
  };
}

/**
 * Property validator function factory that creates a validation function
 * that checks if a given value is smalller or equal to a given number.
 * Null, undefined and non-number values are deemed valid.
 *
 * @param max - the maximum valid value
 * @return true if the value is smaller or equal to the max value
 */
export function createIsNumberLessOrEqualTo<T extends {}, K extends keyof T>(
  max: number,
): Validator<T, K> {
  return ({ value }) => {
    if (value === null || value === undefined || !(typeof value === 'number')) {
      return true;
    }

    return value <= max;
  };
}

/**
 * Property validator function factory that creates a validation function
 * that checks if a given string value is longer than a given number.
 * Null, undefined and non-number values are deemed valid.
 *
 * @param maxLength - the maximum valid string length
 * @return true if the length of value is smaller or equal to the max length value
 */
export function createIsStringLengthLessOrEqualTo<
  T extends {},
  K extends keyof T,
>(maxLength: number): Validator<T, K> {
  return ({ value }) =>
    typeof value === 'string' ? value.length <= maxLength : true;
}
