import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { fromPairs, merge } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';

interface Config {
  delay?: number;
  error?: unknown;
  response?:
    | ({ type: 'response' } & {
        body?: unknown | null;
        headers?: HttpHeaders;
        status?: number;
        statusText?: string;
        url?: string;
      })
    | ({ type: 'error' } & {
        error?: unknown;
        headers?: HttpHeaders;
        status?: number;
        statusText?: string;
        url?: string;
      });
}

const globalServiceConfigs: {
  [service: string]: {
    [resource: string]: Config;
  };
} = {};
(window as any).bbraun = { testing: { services: globalServiceConfigs } };

// https://indepth.dev/posts/1455/how-to-split-http-interceptors-between-multiple-backends
class InterceptorHandler implements HttpHandler {
  constructor(
    private next: HttpHandler,
    private interceptor: HttpInterceptor,
  ) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}

export class InterceptingHandler implements HttpHandler {
  private readonly chain: HttpHandler;

  constructor(backend: HttpHandler, interceptors: HttpInterceptor[]) {
    this.chain = interceptors.reduceRight(
      (next, interceptor) => new InterceptorHandler(next, interceptor),
      backend,
    );
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.chain.handle(req);
  }
}

export function createTestingHttpClientsForTypedJsonApiService<
  T extends string,
>(
  serviceName: string,
  resourceNames: ReadonlyArray<T>,
  handler: HttpHandler,
  interceptors: HttpInterceptor[],
): { [resource in T | 'index']: HttpClient } {
  const configs = createConfigsForService(serviceName, resourceNames);
  const httpClientsByResourceName = fromPairs(
    Object.entries(configs).map(([resourceName, config]) => [
      resourceName,
      createHttpClient(config, handler, interceptors),
    ]),
  );
  return httpClientsByResourceName as { [resource in T | 'index']: HttpClient };
}

function createConfigsForService<T extends string>(
  serviceName: string,
  resourceNames: ReadonlyArray<T>,
): { [key in T | 'index']: Config } {
  const c = {
    ...fromPairs(
      resourceNames.map((name) => {
        const resourceConfig: Config = {};
        return [name, resourceConfig];
      }),
    ),
    index: {},
  };
  globalServiceConfigs[serviceName] = merge(
    globalServiceConfigs[serviceName],
    c,
  );
  Object.freeze(globalServiceConfigs[serviceName]);

  return globalServiceConfigs[serviceName] as { [key in T | 'index']: Config };
}

function createHttpClient(
  config: Config,
  handler: HttpHandler,
  interceptors: HttpInterceptor[],
): HttpClient {
  const interceptingHandler = new InterceptingHandler(
    {
      handle: (originalRequest) => {
        let request$ = of(originalRequest);

        if (config && config.delay) {
          request$ = request$.pipe(delay(config.delay));
        }

        if (config && config.error) {
          request$ = request$.pipe(
            concatMap(() => throwError(() => config.error)),
          );
        }

        const responseConfig = config.response;

        return responseConfig
          ? request$.pipe(
              concatMap(() =>
                responseConfig.type === 'error'
                  ? throwError(
                      () =>
                        new HttpErrorResponse({
                          ...responseConfig,
                          url: responseConfig.url || originalRequest.url,
                        }),
                    )
                  : of(
                      new HttpResponse({
                        ...responseConfig,
                        url: responseConfig.url || originalRequest.url,
                      }),
                    ),
              ),
            )
          : request$.pipe(concatMap((request) => handler.handle(request)));
      },
    },
    interceptors,
  );
  return new HttpClient(interceptingHandler);
}
