import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { UiLanguageModule } from '@bbraun/shared/ui-language';
import { TranslocoModule } from '@ngneat/transloco';
import {
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { defer } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import {
  BavReportingUiReportEditorModule,
  REPORT_EDITOR_PROPERTY_VALIDATION_LOOKUP_SERVICE,
} from '@bbraun/bav-reporting/ui-report-editor';
import {
  TranslocoMessageService,
  TranslocoMessageServiceFactory,
} from '@bbraun/shared/util-transloco-message';
import { UiConfirmInformationDialogModule } from '@bbraun/shared/ui-confirm-information-dialog';
import { BBraunUiDiscardChangesModule } from '@bbraun/shared/ui-discard-changes';
import { BavReportingUiCommonModule } from '@bbraun/bav-reporting/ui-common';
import fallbackTranslation from '../i18n/en.json';
import { ReportListComponent } from './containers/report-list/report-list.component';
import { ReportingService } from './services/reporting.service';
import { ShowReportInfoComponent } from './components/show-report-info/show-report-info.component';
import { ReportListFilterComponent } from './components/report-list-filter/report-list-filter.component';
import { BAV_REPORTING_FEATURE_AIS_REPORTS_TRANSLOCO_MESSAGE_SERVICE } from './injection-tokens';
import { CreateReportComponent } from './containers/create-report/create-report.component';
import { EditReportComponent } from './containers/edit-report/edit-report.component';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import { CurrentDateService } from './services/current-date.service';
import { ReportListResolver } from './resolvers/report-list.resolver';
import { ConfirmUpdateCalculatedValuesDialogComponent } from './components/confirm-update-calculated-values-dialog/confirm-update-calculated-values-dialog.component';
import { EditReportResolver } from './resolvers/edit-report.resolver';
import { ReportModelService } from './services/report-model.service';
import { VALIDATION_PROVIDER } from './validator-factory-provider';
import { SCOPE } from './constants';
import { PropertyValidationLookupService } from './services/property-validation-lookup.service';

function createMessageService(
  factory: TranslocoMessageServiceFactory,
): TranslocoMessageService {
  return factory.createForScope(SCOPE);
}

@NgModule({
  declarations: [
    ReportListComponent,
    ReportDetailsComponent,
    ShowReportInfoComponent,
    ReportListFilterComponent,
    CreateReportComponent,
    EditReportComponent,
    ConfirmUpdateCalculatedValuesDialogComponent,
  ],
  imports: [
    CommonModule,
    UiLanguageModule,
    TranslocoModule,
    DxSelectBoxModule,
    DxFormModule,
    DxDateBoxModule,
    DxDataGridModule,
    DxLoadIndicatorModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatChipsModule,
    BavReportingUiReportEditorModule,
    BavReportingUiCommonModule,
    UiConfirmInformationDialogModule,
    BBraunUiDiscardChangesModule,
  ],
  exports: [ReportListComponent, ReportDetailsComponent],
  providers: [
    ReportingService,
    {
      provide: BAV_REPORTING_FEATURE_AIS_REPORTS_TRANSLOCO_MESSAGE_SERVICE,
      useFactory: createMessageService,
      deps: [TranslocoMessageServiceFactory],
    },
    {
      provide: REPORT_EDITOR_PROPERTY_VALIDATION_LOOKUP_SERVICE,
      useClass: PropertyValidationLookupService,
    },
    ...VALIDATION_PROVIDER,
    ReportModelService,
    CurrentDateService,
    ReportListResolver,
    EditReportResolver,
  ],
})
export class BavReportingFeatureAisReportsModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );
  }
}
