import { Observable, of, throwError } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { CreateObject, UpdateObject } from './json-api-adpater.type';
import { JSON_API_CONTENT_TYPE } from './json-api-content-type';
import {
  CreateResourceRequestBody,
  isJsonApiDataObjectCollectionResponseBody,
  isJsonApiDataObjectResponseBody,
  isJsonApiDataResponseBody,
  isJsonApiErrorResponseBody,
  isJsonApiMetaResponseBody,
  JsonApiDataObjectCollectionResponseBody,
  JsonApiDataObjectResponseBody,
  JsonApiErrorResponseBody,
  JsonApiMetaResponseBody,
  UpdateResourceRequestBody,
} from './json-api.types';
import { mapQueryParameterToHttpParams } from './map-query-parameter-to-http-params';
import { JsonApiMeta, setApiMetaHeaders } from './set-api-meta-headers';
import {
  QueryParameter,
  TypedQueryParameters,
} from './typed/typed-query-parameters';
import {
  JsonApiHttpClient,
  JsonApiHttpHeaders,
  JsonApiHttpParams,
} from './http.type';
import { appendFilterToHttpParams } from './append-filter-to-http-params';

export class JsonApiUrlAdapter<
  TQueryParameters extends
    | QueryParameter
    | TypedQueryParameters<any, any, any, any, any> = QueryParameter,
> {
  constructor(
    private readonly apiMeta: JsonApiMeta,
    private readonly type: string,
    private readonly httpClient: JsonApiHttpClient,
    private readonly fiqlSerializer: (
      parameters: Exclude<TQueryParameters['filter'], undefined | string>,
      encoder: (value: string | number | boolean) => string,
    ) => string | false,
    private readonly sortSerializer: (
      sort: Exclude<TQueryParameters['sort'], undefined>,
    ) => string,
    private readonly queryParametersCombiner: (
      param1: TQueryParameters,
      param2: TQueryParameters,
    ) => TQueryParameters,
    private readonly queryParameter?: TQueryParameters,
  ) {}

  public query(
    resourceUrl: string,
    queryParameter: TQueryParameters,
  ): Observable<
    JsonApiErrorResponseBody | JsonApiDataObjectCollectionResponseBody
  > {
    const params: TQueryParameters = this.queryParameter
      ? this.queryParametersCombiner(this.queryParameter, queryParameter)
      : queryParameter;

    const basicHttpParams = mapQueryParameterToHttpParams(
      params,
      this.fiqlSerializer,
      this.sortSerializer,
    );

    const { rawResourceUrl, httpParams } = appendFilterToHttpParams(
      resourceUrl,
      basicHttpParams,
    );

    return this.httpClient
      .get(rawResourceUrl, {
        params: httpParams,
        headers: setApiMetaHeaders(
          { Accept: JSON_API_CONTENT_TYPE },
          this.apiMeta,
        ),
      })
      .pipe(
        concatMap((responseBody) =>
          isJsonApiDataObjectCollectionResponseBody(responseBody) ||
          isJsonApiErrorResponseBody(responseBody)
            ? of(responseBody)
            : throwError(
                () =>
                  new Error(
                    'unexpected json api response - expected error or data object list response',
                  ),
              ),
        ),
      )
      .pipe(
        tap({
          error: (error) =>
            console.error('error querying json api endpoint', error),
        }),
      );
  }

  public read(
    resourceUrl: string,
    queryParameter: TQueryParameters = {} as TQueryParameters,
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    const params = this.queryParameter
      ? this.queryParametersCombiner(this.queryParameter, queryParameter)
      : queryParameter;

    const basicHttpParams = mapQueryParameterToHttpParams(
      params,
      this.fiqlSerializer,
      this.sortSerializer,
    );

    const { rawResourceUrl, httpParams } = appendFilterToHttpParams(
      resourceUrl,
      basicHttpParams,
    );

    return this.httpClient
      .get(rawResourceUrl, {
        params: httpParams,
        headers: setApiMetaHeaders(
          { Accept: JSON_API_CONTENT_TYPE },
          this.apiMeta,
        ),
      })
      .pipe(
        concatMap((responseBody) =>
          isJsonApiDataObjectResponseBody(responseBody) ||
          isJsonApiErrorResponseBody(responseBody)
            ? of(responseBody)
            : throwError(
                () =>
                  new Error(
                    'unexpected json api response - expected error or data object response',
                  ),
              ),
        ),
      )
      .pipe(
        tap({
          error: (error) =>
            console.error('error reading json api endpoint', error),
        }),
      );
  }

  public addToRelationship(
    resourceUrl: string,
    data: ReadonlyArray<{
      readonly id: string;
      readonly type: string;
    }>,
  ): Observable<
    JsonApiErrorResponseBody | JsonApiDataObjectCollectionResponseBody
  > {
    return this.createInternal(
      resourceUrl,
      { data },
      isJsonApiDataObjectCollectionResponseBody,
      {
        params: mapDataToFieldsets(
          data,
          this.fiqlSerializer,
          this.sortSerializer,
        ),
        headers: setApiMetaHeaders(
          {
            Accept: JSON_API_CONTENT_TYPE,
            'Content-Type': JSON_API_CONTENT_TYPE,
          },
          this.apiMeta,
        ),
      },
    );
  }

  public create(
    resourceUrl: string,
    data: CreateObject,
    queryParameter: TQueryParameters = {} as TQueryParameters,
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    const body: CreateResourceRequestBody = {
      data: { ...data, type: this.type },
    };
    const params = this.queryParameter
      ? this.queryParametersCombiner(this.queryParameter, queryParameter)
      : queryParameter;

    const basicHttpParams = mapQueryParameterToHttpParams(
      // TODO kdraba: make this type safe
      {
        include: params.include,
        fieldsets: params.fieldsets,
      } as TQueryParameters,
      this.fiqlSerializer,
      this.sortSerializer,
    );

    const { rawResourceUrl, httpParams } = appendFilterToHttpParams(
      resourceUrl,
      basicHttpParams,
    );

    return this.createInternal(
      rawResourceUrl,
      body,
      isJsonApiDataObjectResponseBody,
      {
        params: httpParams,
        headers: setApiMetaHeaders(
          {
            Accept: JSON_API_CONTENT_TYPE,
            'Content-Type': JSON_API_CONTENT_TYPE,
          },
          this.apiMeta,
        ),
      },
    );
  }

  private createInternal<TJsonApiResponseBody>(
    url: string,
    body: unknown,
    isExpectedJsonApiResponseBody: (
      response: unknown,
    ) => response is TJsonApiResponseBody,
    options?: {
      params?: JsonApiHttpParams | undefined;
      headers?: JsonApiHttpHeaders | undefined;
      responseType?: 'json' | undefined;
    },
  ) {
    return this.httpClient
      .post(url, body, options)
      .pipe(
        concatMap((responseBody) =>
          isExpectedJsonApiResponseBody(responseBody) ||
          isJsonApiErrorResponseBody(responseBody)
            ? of(responseBody)
            : throwError(
                () =>
                  new Error(
                    'unexpected json api response - expected error or data object response',
                  ),
              ),
        ),
      )
      .pipe(
        tap({
          error: (error) =>
            console.error('error creating json api endpoint', error),
        }),
      );
  }

  public update(
    resourceUrl: string,
    data: UpdateObject,
    queryParameter: TQueryParameters = {} as TQueryParameters,
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    const body: UpdateResourceRequestBody = {
      data: { ...data, type: this.type },
    };
    const params = this.queryParameter
      ? this.queryParametersCombiner(this.queryParameter, queryParameter)
      : queryParameter;

    const basicHttpParams = mapQueryParameterToHttpParams(
      // TODO kdraba: make this type safe
      {
        include: params.include,
        fieldsets: params.fieldsets,
      } as TQueryParameters,
      this.fiqlSerializer,
      this.sortSerializer,
    );

    const { rawResourceUrl, httpParams } = appendFilterToHttpParams(
      resourceUrl,
      basicHttpParams,
    );

    return this.httpClient
      .patch(rawResourceUrl, body, {
        params: httpParams,
        headers: setApiMetaHeaders(
          {
            Accept: JSON_API_CONTENT_TYPE,
            'Content-Type': JSON_API_CONTENT_TYPE,
          },
          this.apiMeta,
        ),
      })
      .pipe(
        concatMap((responseBody) =>
          isJsonApiDataObjectResponseBody(responseBody) ||
          isJsonApiErrorResponseBody(responseBody)
            ? of(responseBody)
            : throwError(
                () =>
                  new Error(
                    'unexpected json api response - expected error or data object response',
                  ),
              ),
        ),
      )
      .pipe(
        tap({
          error: (error) =>
            console.error('error updating json api endpoint', error),
        }),
      );
  }

  private deleteInternal(
    resourceUrl: string,
    data?: ReadonlyArray<{
      readonly id: string;
      readonly type: string;
    }>,
  ): Observable<JsonApiErrorResponseBody | JsonApiMetaResponseBody | void> {
    return this.httpClient
      .delete(resourceUrl, {
        // FIXME bweber fieldsets shouldn't be required NEXNG-1104
        params: mapDataToFieldsets(
          data ? data : [],
          this.fiqlSerializer,
          this.sortSerializer,
        ),
        headers: setApiMetaHeaders(
          {
            Accept: JSON_API_CONTENT_TYPE,
            'Content-Type': JSON_API_CONTENT_TYPE,
          },
          this.apiMeta,
        ),
        body: { data },
      })
      .pipe(
        concatMap((responseBody) =>
          isJsonApiMetaResponseBody(responseBody) ||
          isJsonApiErrorResponseBody(responseBody)
            ? of(responseBody)
            : isJsonApiDataResponseBody(responseBody)
            ? of(undefined)
            : throwError(
                () =>
                  new Error(
                    'unexpected json api response - expected error or meta object response',
                  ),
              ),
        ),
      )
      .pipe(
        tap({
          error: (error) =>
            console.error('error deleting json api endpoint', error),
        }),
      );
  }

  public deleteFromRelationship(
    resourceUrl: string,
    data: ReadonlyArray<{
      readonly id: string;
      readonly type: string;
    }>,
  ): Observable<JsonApiErrorResponseBody | JsonApiMetaResponseBody | void> {
    return this.deleteInternal(resourceUrl, data);
  }

  public delete(
    resourceUrl: string,
  ): Observable<JsonApiErrorResponseBody | JsonApiMetaResponseBody | void> {
    return this.deleteInternal(resourceUrl);
  }
}

function mapDataToFieldsets(
  data: ReadonlyArray<{ type: string; id: string }>,
  fiqlSerializer: (
    parameters: any,
    encoder: (value: string | number | boolean) => string,
  ) => string | false,
  sortSerializer: (sort: any) => string,
): JsonApiHttpParams {
  const initialValue: any = {};
  const queryParameters: QueryParameter = {
    fieldsets: data.reduce((init, { type }) => {
      init[type] = ['id'];
      return init;
    }, initialValue),
  };

  const result = mapQueryParameterToHttpParams(
    queryParameters,
    fiqlSerializer,
    sortSerializer,
  );

  return result;
}
