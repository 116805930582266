import {
  ChangeDetectorRef,
  Inject,
  InjectionToken,
  OnDestroy,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

const LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN = new InjectionToken<any>(
  'LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN',
);

/**
 * This is a pipe base class that is not intended to be used as a pipe directly.
 * The pipe annotation is required by angular (empty Directive would be enough) and angular TestBed (fails without selector name).
 * This also forces us to define injection tokens for each parameter, even if we never ever want this to be injected anywhere.
 * https://angular.io/guide/migration-undecorated-classes
 * https://github.com/angular/angular/issues/36427
 */
@Pipe({ name: 'bbraunUiLanguageLocalizedBase', pure: false })
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LocalizedPipeBase<TArgs extends any[]>
  implements PipeTransform, OnDestroy
{
  private subscription?: Subscription;
  private locale?: string;

  constructor(
    @Optional()
    @Inject(LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly cdr: Pick<ChangeDetectorRef, 'markForCheck'>,
    @Optional()
    @Inject(LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly angularLocale: Observable<string>,
    @Optional()
    @Inject(LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly pipe: (
      locale: string | undefined,
      value: any,
      ...args: TArgs
    ) => string | null | undefined,
  ) {}

  transform(value: any, ...args: TArgs): string | null | undefined {
    if (!this.subscription) {
      this.subscription = this.angularLocale.subscribe((locale) => {
        this.locale = locale;
        this.cdr.markForCheck();
      });
    }

    return this.pipe(this.locale, value, ...args);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
