<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAisReports';
    read: 'bbraunBavReportingFeatureAisReports.reportListComponent'
  "
>
  <ng-container *ngIf="reportListState$ | async as reportListState">
    <div class="flex-box full-width space-between flex-wrap gap-1 mb-1 mt-1">
      <span *ngIf="reportListState.center" class="bav-reporting-page-header">
        {{ translate('header.text') }} {{ reportListState.center.id }}</span
      >
      <div class="buttons flex-box flex-wrap justify-content-flex-end gap-1">
        <bav-reporting-ui-common-start-data-transfer-button
          class="button flex-item no-flex-grow"
          [canActivate]="canStartTransferGuard.canActivate$ | async"
        ></bav-reporting-ui-common-start-data-transfer-button>
        <div
          class="button flex-item no-flex-grow"
          [matTooltip]="
            translate(
              !reportListState.center
                ? 'addButton.noDefaultCenterExists.tooltip'
                : (canCreateAisReportsGuard.canActivate$ | async)
                ? 'addButton.reportAlreadyExists.tooltip'
                : 'addButton.noPermissions.tooltip'
            )
          "
          matTooltipPosition="below"
          matTooltipClass="tooltip"
          [matTooltipDisabled]="
            reportListState.center &&
            !reportListState.doesReportForLastMonthExist &&
            (canCreateAisReportsGuard.canActivate$ | async)
          "
        >
          <button
            mat-button
            color="accent"
            [routerLink]="['/app/reports/create']"
            [disabled]="
              !reportListState.center ||
              reportListState.doesReportForLastMonthExist ||
              (canCreateAisReportsGuard.canActivate$ | async) === false
            "
          >
            {{ translate('addButton.text') }} <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <mat-card class="bbraun-flat">
      <div class="flex-box space-between flex-wrap align-items-center mb-0-5">
        <div class="flex-item flex-box justify-content-flex-end">
          <bav-reporting-feature-ais-reports-report-list-filter
            (filterChanged)="onFilterChanged($event, reportListState.center)"
          ></bav-reporting-feature-ais-reports-report-list-filter>
        </div>
      </div>
      <dx-data-grid
        [dataSource]="reportListState.reports || []"
        class="bav-reporting-datagrid-table bbraun-clickable-cursor"
        [showRowLines]="true"
        [showColumnLines]="false"
        [noDataText]="
          translate(
            (canCreateAisReportsGuard.canActivate$ | async)
              ? 'gridNoData.text'
              : 'noPermissions.text'
          )
        "
        (onRowClick)="onRowClick($event.data.id)"
        (onRowPrepared)="onRowPrepared($event.rowElement, $event.data?.id)"
      >
        <dxo-load-panel
          [enabled]="true"
          [showPane]="true"
          [showIndicator]="true"
        >
        </dxo-load-panel>

        <dxi-column
          dataField="date"
          width="45%"
          alignment="left"
          cellTemplate="dateCellTemplate"
          sortOrder="desc"
          [sortIndex]="1"
          [sortingMethod]="dateColumnSortingMethod"
          [caption]="translate('month.caption')"
        ></dxi-column>
        <dxi-column
          dataField="state"
          width="45%"
          dataType="string"
          alignment="left"
          cellTemplate="stateCellTemplate"
          [caption]="translate('state.caption')"
        ></dxi-column>

        <dxi-column
          dataField=""
          cellTemplate="iconCellTemplate"
          width="10%"
          alignment="right"
        ></dxi-column>

        <div *dxTemplate="let cell of 'dateCellTemplate'">
          {{ cell.value.year }} -
          {{ cell.value.month | bbraunUiLanguageLocalizedNumber: '2.0' }}
        </div>

        <mat-chip-list *dxTemplate="let cell of 'stateCellTemplate'">
          <mat-chip
            *ngIf="cell.text"
            [color]="cell.text === 'Completed' ? 'primary' : 'accent'"
            selected
            disabled
            class="status-chip"
          >
            {{ cell.text }}</mat-chip
          >
        </mat-chip-list>

        <div *dxTemplate="let data of 'iconCellTemplate'">
          <mat-icon>navigate_next</mat-icon>
        </div>
      </dx-data-grid>
    </mat-card>
  </ng-container>
</ng-container>
