import { translocoConfig } from '@ngneat/transloco';
import { I18nConfiguration } from '../i18n-configuration.type';

export function createTranslocoConfig(
  { defaultLocale, supportedLocales }: I18nConfiguration,
  production: boolean,
) {
  return translocoConfig({
    availableLangs: Array.from(supportedLocales),
    defaultLang: defaultLocale,
    reRenderOnLangChange: true,
    prodMode: production,
  });
}
