import { hasPropertyOfType, isNumber } from '@bbraun/shared/util-lang';
import { withSharedValue } from '@bbraun/shared/util-rxjs';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessTokenService } from '../access-token-service.type';
import { JsonApiHttpClient } from '../http.type';
import { isJsonApiDataObjectResponseBody, LinkObject } from '../json-api.types';
import { mapDataObjectOrDataObjectCollection } from '../map-data-object-or-data-object-collection';
import { JsonApiMeta, setApiMetaHeaders } from '../set-api-meta-headers';
import { ID } from '../symbols.type';
import { ApiDefinition, ApiTypes } from '../typed/api-types';

export type ResourceLinkAction = 'create' | 'list' | 'read';

export type ResourceLinks = Partial<{
  [action in ResourceLinkAction]: LinkObject;
}>;

export interface IndexResult<TApiDefinition extends ApiDefinition<ApiTypes>> {
  readonly links: Readonly<Partial<TApiDefinition['index']['links']>>;
  readonly data?: Readonly<TApiDefinition['index']['data']>;
  readonly meta?: Readonly<TApiDefinition['index']['meta']>;
}

export class IndexProvider<TApiDefinition extends ApiDefinition<ApiTypes>> {
  public readonly index$: Observable<IndexResult<TApiDefinition> | undefined>;

  constructor(
    httpClient: JsonApiHttpClient,
    accessTokenService: AccessTokenService,
    apiMeta: JsonApiMeta,
    baseUrl: string,
  ) {
    this.index$ = withSharedValue(
      accessTokenService.token$,
      (token) =>
        indexResult<TApiDefinition>(token, apiMeta, baseUrl, httpClient),
      {
        onError: (error) => {
          if (
            hasPropertyOfType('status', isNumber)(error) &&
            [404, 401, 403].includes(error.status)
          ) {
            return of(undefined);
          } else {
            return throwError(() => error);
          }
        },
      },
    );
  }
}

function indexResult<TApiDefinition extends ApiDefinition<ApiTypes>>(
  principal: unknown,
  apiMeta: JsonApiMeta,
  baseUrl: string,
  httpClient: JsonApiHttpClient,
): Observable<IndexResult<TApiDefinition> | undefined> {
  return principal
    ? httpClient
        .get(baseUrl, {
          headers: setApiMetaHeaders({}, apiMeta),
        })
        .pipe(
          map((document: any) => ({
            principal,
            links: document.links as TApiDefinition['index']['links'],
            ...(isJsonApiDataObjectResponseBody(document)
              ? {
                  data: mapDataObjectOrDataObjectCollection(
                    document.data,
                    document.included || [],
                    {
                      createCallback: (v) => {
                        v.id = v[ID];
                      },
                    },
                  ).result as unknown as TApiDefinition['index']['data'],
                  meta: document.meta as TApiDefinition['index']['meta'],
                }
              : {}),
          })),
        )
    : of(undefined);
}
