import { Injectable } from '@angular/core';
import {
  ErrorCodeLookup,
  ErrorCodeLookupResult,
  ErrorObject,
} from '@bbraun/shared/util-error';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { DataAccessSecurityErrorCodes } from './data-access-security-error-codes';
import { SecurityTranslationService } from './security-translation.service';

@Injectable({ providedIn: 'root' })
export class SecurityErrorCodeLookupService implements ErrorCodeLookup {
  private readonly lookup: {
    [code: string]: undefined | ((error: ErrorObject) => ErrorCodeLookupResult);
  };

  constructor(translationService: SecurityTranslationService) {
    const lookUpEntries: ReadonlyArray<{
      code: string;
      messageId?: symbol;
      fn: (error: ErrorObject) => ErrorCodeLookupResult;
    }> = [
      {
        code: DataAccessSecurityErrorCodes.UNAUTHORIZED.toString(10),
        fn: (error: ErrorObject) => ({
          error,
          message: () =>
            translationService.translate(
              i18n('bbraunSharedDataAccessSecurity.errors.unauthorizedError'),
            ),
        }),
      },
      {
        code: DataAccessSecurityErrorCodes.SESSION_EXPIRED.toString(10),
        fn: (error: ErrorObject) => ({
          error,
          message: () =>
            translationService.translate(
              i18n('bbraunSharedDataAccessSecurity.errors.sessionExpiredError'),
            ),
        }),
      },
      {
        code: DataAccessSecurityErrorCodes.LOGIN_SESSION_EXPIRED.toString(10),
        fn: (error: ErrorObject) => ({
          error,
          message: () =>
            translationService.translate(
              i18n(
                'bbraunSharedDataAccessSecurity.errors.loginSessionExpiredError',
              ),
            ),
        }),
      },
      {
        code: DataAccessSecurityErrorCodes.LOGOUT_INCOMPLETE.toString(10),
        messageId: Symbol('logoutIncompleteMessageId'),
        fn: (error: ErrorObject) => ({
          error,
          message: () =>
            translationService.translate(
              i18n('bbraunSharedDataAccessSecurity.errors.logoutError'),
            ),
        }),
      },
    ];

    this.lookup = lookUpEntries.reduce((acc, { code, messageId, fn }) => {
      if (!acc[code]) {
        acc[code] = (...args) => ({
          messageId,
          ...fn(...args),
        });
        return acc;
      } else {
        throw new Error(
          `Failed to create error code lookup. Duplicate entry for error code <${code}> found.`,
        );
      }
    }, {} as { [code: string]: (error: ErrorObject) => ErrorCodeLookupResult });
  }

  get(code: string) {
    return this.lookup[code];
  }
}
