import { combineFiqlFilter } from './combine-fiql-filter';
import { QueryParameter } from './typed/typed-query-parameters';

export function combineQueryParameters(
  params1: QueryParameter,
  params2: QueryParameter,
): QueryParameter {
  return {
    filter: combineFiqlFilter(params1.filter, params2.filter),
    sort: [...(params1.sort || []), ...(params2.sort || [])],
    page: params2.page ? params2.page : params1.page,
    fieldsets: { ...(params1.fieldsets || {}), ...(params2.fieldsets || {}) },
    include: [...(params1.include || []), ...(params2.include || [])],
  };
}
