import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { InfoBoxItem } from '@bbraun/shared/ui-info-box';
import { Observable, map, throwError } from 'rxjs';
import { CanStartTransferGuard } from '@bbraun/bav-reporting/data-access-administration';
import DataSource from 'devextreme/data/data_source';
import { FiqlQuery } from '@bbraun/shared/util-fiql';
import { JsonApiPaging, JsonApiSort } from '@bbraun/shared/util-jsonapi';
import { DxJsonApiDataSource2 } from '@bbraun/shared/util-devexpress';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';
import { FeatureAdministrationScopedTranslationService } from '../../services/feature-administration-scoped-translation.service';
import { BAV_REPORTING_ADMINISTRATION_MESSAGE_SERVICE } from '../../injection-tokens';
import { UserLogsService } from '../../services/user-logs.service';

const contactSupportInfoBoxItems: InfoBoxItem[] = [
  {
    title: '',
    type: 'text',
    text: i18n('bbraunBavReportingFeatureAdministration.userLogs.info.text'),
  },
];

@Component({
  selector: 'bav-reporting-feature-administration-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLogsComponent {
  readonly dataSource: DataSource;

  readonly infoBoxItems$: Observable<InfoBoxItem[]>;

  constructor(
    private readonly userLogsService: UserLogsService,
    @Inject(BAV_REPORTING_ADMINISTRATION_MESSAGE_SERVICE)
    private readonly translocoMessageService: TranslocoMessageService,
    private readonly translocoService: TranslocoService,
    private readonly scopedTranslationService: FeatureAdministrationScopedTranslationService,
    public readonly canStartTransferGuard: CanStartTransferGuard,
  ) {
    const query = (dataSourceQueryParams: {
      filter?: FiqlQuery;
      sort?: JsonApiSort[];
      page?: JsonApiPaging;
    }) => {
      if (dataSourceQueryParams.filter || dataSourceQueryParams.sort) {
        return throwError(
          () => new Error('Filter and sort parameter are not supported.'),
        );
      } else {
        return this.userLogsService.getUserLogs(dataSourceQueryParams.page);
      }
    };

    this.dataSource = new DxJsonApiDataSource2(
      this.translocoService,
      {
        query,
      },
      {
        key: 'id',
        errorHandling: {
          catchError: false,
          reporter: {
            onError: (error) => {
              this.translocoMessageService.message(
                (_, lang, translationService) => ({
                  message: translationService.translate(
                    i18n(
                      'bbraunBavReportingFeatureAdministration.userLogs.entries.loading.error.message',
                    ),
                    {},
                    lang,
                  ),
                }),
                'error',
                {
                  id: Symbol('ERROR_ID'),
                  error,
                  actions: [{ type: 'update' }],
                },
              );
            },
          },
        },
      },
    );

    this.infoBoxItems$ = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) =>
        contactSupportInfoBoxItems.map((item) => ({
          ...item,
          text: translate(item.text),
        })),
      ),
    );
  }
}
