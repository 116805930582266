import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PickByDiscriminator, toPairs } from '@bbraun/shared/util-lang';
import { MessageService } from '@bbraun/shared/util-message-ng';
import {
  connectToLoadBarService,
  LoadBarService,
} from '@bbraun/shared/util-navigation';
import { translate } from '@ngneat/transloco';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  of,
  shareReplay,
  Subscription,
} from 'rxjs';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import {
  blockingOperationHandler,
  CallableOperationFn,
  hasValue,
} from '@bbraun/shared/util-rxjs';
import { ErrorCodeLookup, lookUpError } from '@bbraun/shared/util-error';
import { ERROR_CODE_LOOKUP } from '@bbraun/shared/util-error-ng';
import { DefaultCenterResolver } from '@bbraun/bav-reporting/data-access-ais-reports';
import { ActivatedRoute } from '@angular/router';
import { FromResolver } from '@bbraun/shared/util-lang-ng';
import { WithIsChangedSupportComponent } from '@bbraun/bav-reporting/ui-report-editor';
import {
  ReportDetailsComponent,
  ReportDetailsState,
} from '../../components/report-details/report-details.component';
import { ReportingService } from '../../services/reporting.service';
import { getPreviousMonth } from '../../functions/get-previous-month';
import { CurrentDateService } from '../../services/current-date.service';

type CreateReportState = PickByDiscriminator<
  ReportDetailsState,
  'viewMode',
  'create'
>;

const INITIAL_DATA_LOADING = Symbol('INITIAL_DATA_LOADING');
const INITIAL_DATA_LOADING_ERROR_ID = Symbol('INITIAL_DATA_LOADING_ERROR_ID');

@Component({
  selector: 'bav-reporting-feature-ais-reports-create-report',
  templateUrl: './create-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateReportComponent
  implements OnDestroy, OnInit, WithIsChangedSupportComponent
{
  get changed(): boolean {
    return !!this.withIsChangedSupportComponents?.some(
      ({ changed }) => changed,
    );
  }

  @ViewChildren(ReportDetailsComponent)
  reportDetailsComponents?: QueryList<ReportDetailsComponent>;

  @ViewChildren('withIsChangedSupport')
  private withIsChangedSupportComponents?: QueryList<WithIsChangedSupportComponent>;

  readonly initialState$: Observable<CreateReportState | undefined>;

  private readonly isCreateReportHandlerRunning$: Observable<boolean>;
  private readonly subscriptions = new Subscription();

  constructor(
    reportingService: ReportingService,
    messageService: MessageService,
    private readonly loadbarService: LoadBarService,
    @Inject(ERROR_CODE_LOOKUP)
    errorCodeLookups: ReadonlyArray<ErrorCodeLookup>,
    currentDateService: CurrentDateService,
    route: ActivatedRoute,
  ) {
    const center = route.snapshot.data
      .center as FromResolver<DefaultCenterResolver>;

    const initialData$ = reportingService
      .getCalculatedReport(
        center.id,
        getPreviousMonth(currentDateService.currentDate()),
      )
      .pipe(
        map((updatedCalculatedData) => {
          if (updatedCalculatedData) {
            return toPairs(updatedCalculatedData).reduce(
              (acc, [key, value]) =>
                value !== null ? { ...acc, [key]: value } : acc,
              {},
            );
          } else {
            messageService.message(
              translate(
                i18n(
                  'bbraunBavReportingFeatureAisReports.createReportComponent.noCalculatedDataFound.warning.message',
                ),
              ),
              'warning',
              {
                timeOut: false,
              },
            );
            return {};
          }
        }),
      )
      .pipe(first());

    const initialState$ = initialData$.pipe(
      map(
        (reportDetails) =>
          ({
            viewMode: 'create',
            reportDetails,
            reportInfo: {
              center: center.id,
              date: getPreviousMonth(currentDateService.currentDate()),
            },
          } as const),
      ),
    );

    const createReportBlockingOperationHandler =
      createCreateReportBlockingOperationHandler(
        of(() => initialState$),
        messageService,
        errorCodeLookups,
      );

    this.initialState$ = createReportBlockingOperationHandler.results
      .pipe(filter(hasValue))
      .pipe(map(({ value }) => value))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.isCreateReportHandlerRunning$ =
      createReportBlockingOperationHandler.running;
  }

  ngOnInit() {
    this.subscriptions.add(
      this.isCreateReportHandlerRunning$
        .pipe(
          map((isRunning) => ({
            id: INITIAL_DATA_LOADING,
            value: isRunning,
          })),
        )
        .pipe(connectToLoadBarService(this.loadbarService))
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

function createCreateReportBlockingOperationHandler(
  source: Observable<CallableOperationFn<CreateReportState>>,
  messageService: MessageService,
  errorCodeLookup: ReadonlyArray<ErrorCodeLookup>,
) {
  return blockingOperationHandler<CreateReportState>(
    {
      error: (error) => {
        const errorLookupResult = lookUpError(error, errorCodeLookup);

        if (errorLookupResult) {
          messageService.message(errorLookupResult.message, 'error', {
            id: INITIAL_DATA_LOADING_ERROR_ID,
            actions: [{ type: 'update' }],
            timeOut: false,
          });
        } else {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingFeatureAisReports.createReportComponent.createReportStateBlockingOperationHandler.error.message',
              ),
            ),
            'error',
            {
              timeOut: false,
              error,
            },
          );
        }
      },
    },
    { strategy: 'single', autoConnectResult: false, source },
  );
}
