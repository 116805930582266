import { FiqlQuery } from '@bbraun/shared/util-fiql';
import { DxFilter } from '../filter/dx-filter';
import { ConvertOptions } from './convert-options.type';
import { isDxFilterGroup } from './dx-filter-group';
import { isDxFilterOperation } from './dx-filter-operation';
import { isDxFilterUnaryOperation } from './dx-filter-unary-operation';

export function convertDxFilterToFiql(
  dxFilter: DxFilter,
  options: ConvertOptions,
): FiqlQuery {
  const { operationToConstraint, groupToExpression } = options;

  // cannot extract this code (avoid cyclic dependencies)
  if (isDxFilterOperation(dxFilter)) {
    return operationToConstraint(dxFilter, false);
  } else if (isDxFilterGroup(dxFilter)) {
    return groupToExpression(dxFilter, options);
  } else if (isDxFilterUnaryOperation(dxFilter)) {
    const operation = dxFilter[1];
    return operationToConstraint(operation, true);
  } else {
    throw new Error(`Filter not supported: ${JSON.stringify(dxFilter)}!`);
  }
}
