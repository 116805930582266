<mat-toolbar
  color="primary"
  class="bbraun-toolbar"
  [class.has-toolbar-text]="toolbarText"
>
  <div class="container">
    <button
      (click)="doToggleSidebar()"
      class="toolbar-button toggle-sidebar"
      [ngClass]="{ open: isSidebarOpen }"
      [attr.data-test]="dataTest.sidebarToggle.selector"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <button
      class="toolbar-button"
      (click)="back()"
      [class.hidden]="hideBackButton"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <div class="app-name" *ngIf="logoUrl">
      <ng-container *ngIf="routerLinkString">
        <a (click)="handleTitleClick()" [routerLink]="routerLinkString"
          ><img [src]="logoUrl" class="bbraun-app-name" [title]="appName"
        /></a>
      </ng-container>
      <ng-container *ngIf="!routerLinkString">
        <img [src]="logoUrl" class="bbraun-app-name" [title]="appName" />
      </ng-container>
    </div>
    <div class="toolbar-text">
      {{ toolbarText }}
    </div>
    <div *ngIf="principal && isPrincipalLoggedIn" class="principal-container">
      <mat-icon inline="true" class="principal-icon">person</mat-icon>
      <span class="principal-name">{{ principal.name }}</span>
    </div>
    <div *ngIf="helpLink" class="help-container">
      <mat-icon (click)="openLinkInNewTab(helpLink)" class="help-icon mr-1"
        >help</mat-icon
      >
    </div>
  </div>
  <mat-progress-bar
    [mode]="isLoading ? 'indeterminate' : 'determinate'"
    value="0"
    class="bbraun-progress-bar"
    [class.hidden]="!enableLoadingIndicator"
    [attr.data-test]="
      isLoading ? dataTest.isLoading.selector : dataTest.isNotLoading.selector
    "
  ></mat-progress-bar>
</mat-toolbar>
