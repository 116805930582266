// THIS FILE IS GENERATED - DO NOT EDIT!

import {
  ExtractSparseFieldTypes,
  TypedQueryParameters,
} from '@bbraun/shared/util-jsonapi';
// eslint-disable-next-line no-unused-expressions
// @ts-ignore
import { createPropertyPathBuilder } from '@bbraun/shared/util-lang';

import { BavReportingJsonApiTypes } from '../bav-reporting-json-api-types.json-api.generated';

export const userLogsResponseModelFieldsets = {
  'user-log': [
    createPropertyPathBuilder<BavReportingJsonApiTypes['user-log']>().add(
      'severity',
    ).path[0] as 'severity',
    createPropertyPathBuilder<BavReportingJsonApiTypes['user-log']>().add(
      'createdAt',
    ).path[0] as 'createdAt',
    createPropertyPathBuilder<BavReportingJsonApiTypes['user-log']>().add(
      'message',
    ).path[0] as 'message',
    createPropertyPathBuilder<BavReportingJsonApiTypes['user-log']>().add(
      'messageCode',
    ).path[0] as 'messageCode',
    createPropertyPathBuilder<BavReportingJsonApiTypes['user-log']>().add(
      'jobIdentifier',
    ).path[0] as 'jobIdentifier',
    createPropertyPathBuilder<BavReportingJsonApiTypes['user-log']>().add(
      'jobType',
    ).path[0] as 'jobType',
  ],
} as const;

type UserLogsResponseModelFieldsets = ExtractSparseFieldTypes<
  typeof userLogsResponseModelFieldsets
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type UserLogsResponseModel = {
  readonly id: string;
  readonly createdAt: string;
  readonly jobIdentifier: null | number;
  readonly jobType: 'Undefined' | 'Report' | 'ClinicalData' | 'Slice';
  readonly message: string;
  readonly messageCode: string;
  readonly severity:
    | 'Undefined'
    | 'Information'
    | 'Warning'
    | 'Error'
    | 'Fatal';
};

export const userLogsResponseModelIncludes = undefined;

export const userLogsResponseModelReadParameters: TypedQueryParameters<
  BavReportingJsonApiTypes,
  UserLogsResponseModelFieldsets,
  'user-log'
> = {
  fieldsets: userLogsResponseModelFieldsets,
  include: userLogsResponseModelIncludes,
};
