import {
  createSimpleStoreWithInitialState,
  SimpleStoreService,
  StoreEventHandler,
} from '@bbraun/shared/util-rxjs';
import { isEqual } from 'lodash-es';

export interface ReportListFilterModel {
  date: {
    from: Date | undefined;
    to: Date | undefined;
  };
}

type ReportingFilterStoreEventHandlers = Readonly<{
  'select-from-date': StoreEventHandler<
    ReportListFilterModel,
    { from: Date | undefined }
  >;
  'select-to-date': StoreEventHandler<
    ReportListFilterModel,
    { to: Date | undefined }
  >;
}>;

export type ReportListFilterStore = SimpleStoreService<
  ReportListFilterModel,
  ReportingFilterStoreEventHandlers
>;

export function createReportListFilterStore(): ReportListFilterStore {
  return createSimpleStoreWithInitialState<ReportListFilterModel>({
    date: {
      from: undefined,
      to: undefined,
    },
  })
    .withEventConfiguration<ReportingFilterStoreEventHandlers>((builder) =>
      builder
        .registerEvent(
          'select-from-date',
          (options: { from: Date | undefined }, state) =>
            isEqual(state.date.from, options.from)
              ? state
              : {
                  ...state,
                  date: {
                    ...state.date,
                    from: options.from,
                  },
                },
        )
        .registerEvent(
          'select-to-date',
          (options: { to: Date | undefined }, state) =>
            isEqual(state.date.to, options.to)
              ? state
              : {
                  ...state,
                  date: {
                    ...state.date,
                    to: options.to,
                  },
                },
        ),
    )
    .fromEmpty();
}
