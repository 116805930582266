export function mapSetToObject<
  TKey extends string | number | symbol,
  TObjectValue,
>(
  set: ReadonlySet<TKey>,
  valueFunc: (key: TKey) => TObjectValue,
): Record<TKey, TObjectValue> {
  return Array.from(set).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: valueFunc(curr),
    }),
    {} as Record<TKey, TObjectValue>,
  );
}
