import { concat, defer, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

export const visibilityState$ = concat(
  defer(() => document.visibilityState),
  defer(() => fromEvent(document, 'visibilitychange')),
)
  .pipe(map(() => document.visibilityState))
  .pipe(distinctUntilChanged())
  .pipe(shareReplay({ bufferSize: 1, refCount: true }));
