<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiLogin';
    read: 'bbraunSharedUiLogin.loginDialogComponent'
  "
>
  <mat-card class="bbraun-flat">
    <mat-card-subtitle>{{ translate('title.text') }}</mat-card-subtitle>
    <mat-card-content>
      <form (submit)="submit()">
        <dx-form
          labelLocation="left"
          [formData]="loginRequest"
          (onFieldDataChanged)="onFieldDataChanged()"
        >
          <dxi-item
            dataField="username"
            [editorOptions]="{
              validationMessageMode: 'always',
              elementAttr: { 'data-test': dataTest.username.selector }
            }"
          >
            <dxo-label
              text="{{ translate('usernameInput.label') }}"
            ></dxo-label>
            <dxi-validation-rule
              type="custom"
              [reevaluate]="true"
              [validationCallback]="validate.bind(this)"
              message="{{ translate('usernameInputRequired.text') }}"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            dataField="password"
            [editorOptions]="{
              mode: 'password',
              validationMessageMode: 'always',
              elementAttr: { 'data-test': dataTest.password.selector }
            }"
          >
            <dxo-label
              text="{{ translate('passwordInput.label') }}"
            ></dxo-label>
            <dxi-validation-rule
              type="custom"
              [reevaluate]="true"
              [validationCallback]="validate.bind(this)"
              message="{{ translate('passwordInputRequired.text') }}"
            ></dxi-validation-rule>
          </dxi-item>
        </dx-form>
        <div class="text-right pt-1">
          <button
            type="submit"
            mat-button
            color="accent"
            [attr.data-test]="dataTest.loginButton.selector"
            class="align-right"
          >
            {{ translate('loginButton.text') }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
