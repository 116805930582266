import { Inject, Injectable } from '@angular/core';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import { createIsNumberGreaterOrEqualValidator } from '@bbraun/shared/util-validation';
import { map, Observable } from 'rxjs';
import {
  ValidatorFactory,
  ValidatorFactoryFunction,
} from '@bbraun/bav-reporting/ui-report-editor';
import { FeatureAisReportsScopedTranslationService } from '../../../services/feature-ais-reports-scoped-translation.service';

export const IS_GREATER_OR_EQUAL_NUMBER_ID = 'IS_GREATER_OR_EQUAL_NUMBER';
export const IS_GREATER_OR_EQUAL_NUMBER_ERROR = Symbol(
  IS_GREATER_OR_EQUAL_NUMBER_ID,
);

@Injectable({
  providedIn: 'root',
})
export class IsGreaterOrEqualNumberValidatorFactory
  implements ValidatorFactory<{ minimum: number }>
{
  readonly id = IS_GREATER_OR_EQUAL_NUMBER_ID;
  readonly validatorFn: Observable<
    ValidatorFactoryFunction<{ minimum: number }>
  >;

  constructor(
    @Inject(FeatureAisReportsScopedTranslationService)
    private readonly scopedTranslationService: {
      loadedLang$: Observable<{ translate: TranslateFunction }>;
    },
  ) {
    this.validatorFn = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) => ({ minimum }) => ({
        id: IS_GREATER_OR_EQUAL_NUMBER_ERROR,
        validate: createIsNumberGreaterOrEqualValidator(minimum),
        message: translate(
          i18n(
            'bbraunBavReportingFeatureAisReports.validation.property.notGreaterOrEqual.number.error.message',
          ),
          { minimum },
        ),
      })),
    );
  }
}
