import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  getHrefFromLink,
  getResourceLink,
  isJsonApiDataObjectResponseBody,
  JsonApiDataResponseBody,
  JsonApiErrorResponseBody,
  JSON_API_CONTENT_TYPE_HEADER,
  TypedJsonApiService,
} from '@bbraun/shared/util-jsonapi';
import {
  hasPropertyOfType,
  is,
  isAllTypesOf,
  isNull,
  isOneTypeOf,
  isString,
} from '@bbraun/shared/util-lang';
import { first, map, Observable, of, switchMap, throwError } from 'rxjs';
import { BavReportingJsonApi } from '../bav-reporting-json-api.type';
import { BAV_REPORTING_API_SERVICE } from '../injection-tokens';
import { CenterConfiguration } from '../types';

@Injectable({
  providedIn: 'root',
})
export class DefaultCenterService {
  constructor(
    @Inject(BAV_REPORTING_API_SERVICE)
    private readonly typedApiService: TypedJsonApiService<BavReportingJsonApi>,
    private readonly http: HttpClient,
  ) {}

  getDefaultCenter(): Observable<CenterConfiguration | undefined> {
    return this.typedApiService
      .index()
      .pipe(
        map((indexResult) =>
          getResourceLink('center-configuration', 'instance', indexResult),
        ),
      )
      .pipe(
        switchMap((linkObject) => {
          const link = linkObject ? getHrefFromLink(linkObject) : undefined;

          return link
            ? this.http.get<JsonApiErrorResponseBody | JsonApiDataResponseBody>(
                link,
                {
                  headers: JSON_API_CONTENT_TYPE_HEADER,
                },
              )
            : throwError(
                () =>
                  new Error(
                    'Center configuration instance link is not defined.',
                  ),
              );
        }),
      )
      .pipe(
        switchMap((response) =>
          response &&
          isJsonApiDataObjectResponseBody(response) &&
          isCenterConfiguration(response.data)
            ? of(response.data.relationships.center.data || undefined)
            : throwError(
                () => new Error('Center configuration format is invalid.'),
              ),
        ),
      )
      .pipe(first());
  }
}

const isCenterConfiguration = isAllTypesOf(
  hasPropertyOfType('type', is('center-configuration' as const)),
  hasPropertyOfType(
    'relationships',
    hasPropertyOfType(
      'center',
      hasPropertyOfType(
        'data',
        isOneTypeOf(
          isNull,
          isAllTypesOf(
            hasPropertyOfType('type', is('center' as const)),
            hasPropertyOfType('id', isString),
          ),
        ),
      ),
    ),
  ),
);
