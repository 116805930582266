import { FormDataHandlerState } from '@bbraun/shared/util-devexpress';
import { toPairs } from '@bbraun/shared/util-lang';

export function computeBlocksLookup<T, TKey extends keyof T & string>({
  errors,
  changedProperties,
  blockIdentifierByTechnicalNameLookup,
}: {
  errors: FormDataHandlerState<T>['errors'];
  changedProperties: false | ReadonlySet<TKey>;
  blockIdentifierByTechnicalNameLookup: {
    [technicalName: string]: string;
  };
}): { changedBlocks: ReadonlySet<string>; invalidBlocks: ReadonlySet<string> } {
  const changedBlocks = changedProperties
    ? Array.from(changedProperties).reduce((acc, propertyKey) => {
        const blockIdentifier =
          blockIdentifierByTechnicalNameLookup[propertyKey];
        if (blockIdentifier) {
          acc.add(blockIdentifier);
          return acc;
        } else {
          return acc;
        }
      }, new Set<string>())
    : new Set<string>();

  const validationErrors = errors?.properties;

  const invalidBlocks = validationErrors
    ? toPairs(validationErrors).reduce((acc, [key, value]) => {
        const blockIdentifier =
          value &&
          value.length !== 0 &&
          blockIdentifierByTechnicalNameLookup[key];
        if (blockIdentifier) {
          acc.add(blockIdentifier);
          return acc;
        } else {
          return acc;
        }
      }, new Set<string>())
    : new Set<string>();

  return { changedBlocks, invalidBlocks };
}
