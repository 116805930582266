// TechnicalNames of calculated properties refer to the excel-document "AIS-export_HD_v.08.06.2018" in https://confluence.bbraun-cis.com/display/AD/Requirements+Draft
import { createOwnPatientHemoDialysisThreeMonthsReferenceProperty } from '../functions/create-own-patient-hemo-dialysis-three-months-reference-property';
import { createHeparinStandardPatientsProperty } from './blocks/heparin/sections/standard-heparin/create-heparin-standard-patients-property';
import { createHeparinStandardProperty } from './blocks/heparin/sections/standard-heparin/create-heparin-standard-property';
import { createHeparinEnoxLPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-l-patients-property';
import { createHeparinEnoxGPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-g-patients-property';
import { createHeparinEnoxAPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-a-patients-property';
import { createHeparinEnoxCPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-c-patients-property';
import { createHeparinEnoxEPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-e-patients-property';
import { createHeparinNadrPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-nadr-patients-property';
import { createHeparinDaltPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-dalt-patients-property';
import { createHeparinTinzPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-tinz-patients-property';
import { createHeparinBemiPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-bemi-patients-property';
import { createSumOfHeparinPatientsProperty } from './blocks/heparin/sections/low-molecular-heparin/create-sum-of-heparin-patients-property';
import { createHeparinEnoxLProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-l-property';
import { createHeparinEnoxGProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-g-property';
import { createHeparinEnoxAProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-a-property';
import { createHeparinEnoxCProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-c-property';
import { createHeparinEnoxEProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-enox-e-property';
import { createHeparinNadrProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-nadr-property';
import { createHeparinDaltProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-dalt-property';
import { createHeparinTinzProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-tinz-property';
import { createHeparinBemiProperty } from './blocks/heparin/sections/low-molecular-heparin/create-heparin-bemi-property';
import { createSumOfAlbuminPatientsProperty } from './blocks/nutrition-inflammation/sections/albumin/create-sum-of-albumin-patients-property';
import { createSumOfBloodPressureDiastolicPatientsProperty } from './blocks/blood-pressure/sections/diastolic-bp/create-sum-of-blood-pressure-diastolic-patients-property';
import { createSumOfBloodPressureSystolicPatientsProperty } from './blocks/blood-pressure/sections/systolic-bp/create-sum-of-blood-pressure-systolic-patients-property';
import { createSumOfBmiPatientsProperty } from './blocks/other-parameters/sections/bmi/create-sum-of-bmi-patients-property';
import { createSumOfCalciumPatientsProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-sum-of-calcium-patients-property';
import { createSumOfChronicHdPatientsProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-sum-of-chronic-hd-patients-property';
import { createSumOfconvectiveVolumePatientsProperty } from './blocks/dialysis-adequacy/sections/convective-volume/create-sum-of-convective-volume-patients-property';
import { createSumOfSerumCreatininePatientsProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-sum-of-creatinine-patients-property';
import { createSumOfCrpPatientsProperty } from './blocks/nutrition-inflammation/sections/crp/create-sum-of-crp-patients-property';
import { createSumOfDialysisFreqPatientsProperty } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-sum-of-dialysis-freq-patients-property';
import { createSumOfEktvPatientsProperty } from './blocks/dialysis-adequacy/sections/ektv/create-sum-of-ektv-patients-property';
import { createSumOfFerritinPatientsProperty } from './blocks/anemia/sections/ferritin/create-sum-of-ferritin-patients-property';
import { createSumOfGenderPatientsProperty } from './blocks/other-parameters/sections/gender/create-sum-of-gender-patients-property';
import { createSumOfHemoglobinPatientsProperty } from './blocks/anemia/sections/hemoglobin/create-sum-of-hemoglobin-patients-property';
import { createSumOfNutritionEiPatientsProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-sum-of-nutrition-ei-patients-property';
import { createSumOfNutritionSgaPatientsProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-sum-of-nutrition-sga-patients-property';
import { createSumOfPhosphatePatientsProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-sum-of-phosphate-patients-property';
import { createSumOfPrimaryRenalDiseasePatientsProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-sum-of-primary-renal-disease-patients-property';
import { createSumOfTimeTreatmentPatientsProperty } from './blocks/dialysis-adequacy/sections/treatment-time/create-sum-of-time-treatment-patients-property';
import { createSumOfTransferrinSaturationPatientsProperty } from './blocks/anemia/sections/transferrin-saturation/create-sum-of-transferrin-saturation-patients-property';
import { createSumOfTreatmentModalityProperty } from './blocks/dialysis-adequacy/sections/treatment-modality/create-sum-of-treatment-modality-property';
import { createHdInfectionGraftPatientNumProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-hd-infection-graft-patient-num-property';
import { createHdInfectionAvfPatientNumProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-hd-infection-avf-patient-num-property';
import { createHdInfectionCvcPatientNumProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-hd-infection-cvc-patient-num-property';
import { createOwnPatientHemoDialysis3MonthProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/number-of-chronic-hd-patients/create-own-patient-hemo-dialysis-3-month-property';
import { createHdTransplantWaitingPatientProperty } from './blocks/number-of-patients-on-waiting-list/sections/number-of-patients-on-waiting-list/create-hd-transplant-waiting-patient-property';
import { createOwnPatientAllLastMonthProperty } from './blocks/own-hd-patients/sections/own-hd-patients/create-own-patient-all-last-month-property';
import { createOwnPatientNewAdmissionProperty } from './blocks/own-hd-patients/sections/new/create-own-patient-new-admission-property';
import { createOwnPatientNewBBraunAvitumCenterProperty } from './blocks/own-hd-patients/sections/new/create-own-patient-new-bbraun-avitum-center-property';
import { createOwnPatientNewOtherCenterProperty } from './blocks/own-hd-patients/sections/new/create-own-patient-new-other-center-property';
import { createOwnPatientNewBackTranspProperty } from './blocks/own-hd-patients/sections/new/create-own-patient-new-back-transp-property';
import { createOwnPatientNewPdToHdProperty } from './blocks/own-hd-patients/sections/new/create-own-patient-new-pd-to-hd-property';
import { createSumOfNewPatientsProperty } from './blocks/own-hd-patients/sections/new/create-sum-of-new-patients-property';
import { createOwnPatientResignedTranspProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-transp-property';
import { createOwnPatientResignedExitusChronicCardiovascularProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-exitus-chronic-cardiovascular-property';
import { createOwnPatientResignedExitusChronicCerebrovascularProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-exitus-chronic-cerebrovascular-property';
import { createOwnPatientResignedExitusChronicInfectionProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-exitus-chronic-infection-property';
import { createOwnPatientResignedExitusChronicMalignancyProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-exitus-chronic-malignancy-property';
import { createOwnPatientResignedExitusChronicOtherProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-exitus-chronic-other-property';
import { createSumOfExitusChronicProperty } from './blocks/own-hd-patients/sections/resigned/create-sum-of-exitus-chronic-property';
import { createOwnPatientResignedExitusNonchronicProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-exitus-nonchronic-property';
import { createOwnPatientResignedQuittedProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-quitted-property';
import { createOwnPatientResignedBBraunAvitumCenterProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-bbraun-avitum-center-property';
import { createOwnPatientResignedOtherCenterProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-other-center-property';
import { createOwnPatientResignedHdToPdProperty } from './blocks/own-hd-patients/sections/resigned/create-own-patient-resigned-hd-to-pd-property';
import { createSumOfResignedProperty } from './blocks/own-hd-patients/sections/resigned/create-sum-of-resigned-property';
import { createSumOfPatientsByTheEndOfThisMonthProperty } from './blocks/own-hd-patients/sections/own-hd-patients/create-sum-of-patients-by-the-end-of-this-month-property';
import { createConvectiveVolumeBe23Property } from './blocks/dialysis-adequacy/sections/convective-volume/create-convective-volume-be23-property';
import { createConvectiveVolumeL23Property } from './blocks/dialysis-adequacy/sections/convective-volume/create-convective-volume-l23-property';
import { createConvectiveVolumeMeanProperty } from './blocks/dialysis-adequacy/sections/convective-volume/create-convective-volume-mean-property';
import { createConvectiveVolumeNotDoneProperty } from './blocks/dialysis-adequacy/sections/convective-volume/create-convective-volume-not-done-property';
import { createConvectiveVolumeSdProperty } from './blocks/dialysis-adequacy/sections/convective-volume/create-convective-volume-sd-property';
import { createBloodFlowMeanProperty } from './blocks/dialysis-adequacy/sections/blood-flow/create-blood-flow-mean-property';
import { createBloodFlowSdProperty } from './blocks/dialysis-adequacy/sections/blood-flow/create-blood-flow-sd-property';
import { createDialysisFreqBe3Property } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-dialysis-freq-be3-property';
import { createDialysisFreqL3Property } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-dialysis-freq-l3-property';
import { createDialysisFreqMeanProperty } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-dialysis-freq-mean-property';
import { createDialysisFreqNotDoneProperty } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-dialysis-freq-not-done-property';
import { createDialysisFreqSdProperty } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-dialysis-freq-sd-property';
import { createEktvBe1AndL1p2Property } from './blocks/dialysis-adequacy/sections/ektv/create-ektv-be1-and-l1p2-property';
import { createEktvBe1p2Property } from './blocks/dialysis-adequacy/sections/ektv/create-ektv-be1p2-property';
import { createEktvL1Property } from './blocks/dialysis-adequacy/sections/ektv/create-ektv-l1-property';
import { createEktvMeanProperty } from './blocks/dialysis-adequacy/sections/ektv/create-ektv-mean-property';
import { createEktvNotDoneProperty } from './blocks/dialysis-adequacy/sections/ektv/create-ektv-not-done-property';
import { createEktvSdProperty } from './blocks/dialysis-adequacy/sections/ektv/create-ektv-sd-property';
import { createTreatmentModalityHdfPostProperty } from './blocks/dialysis-adequacy/sections/hdf-modality/create-treatment-modality-hdf-post-property';
import { createTreatmentModalityHdfPreProperty } from './blocks/dialysis-adequacy/sections/hdf-modality/create-treatment-modality-hdf-pre-property';
import { createTreatmentModalityHdProperty } from './blocks/dialysis-adequacy/sections/treatment-modality/create-treatment-modality-hd-property';
import { createTreatmentModalityHdfProperty } from './blocks/dialysis-adequacy/sections/treatment-modality/create-treatment-modality-hdf-property';
import { createTimeTreatmentBe10p5AndL12Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-be10p5-and-l12-property';
import { createTimeTreatmentBe12Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-be12-property';
import { createTimeTreatmentBe9AndL10p5Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-be9-and-l10p5-property';
import { createTimeTreatmentL9Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-l9-property';
import { createTimeTreatmentMeanProperty } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-mean-property';
import { createTimeTreatmentNotDoneProperty } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-not-done-property';
import { createTimeTreatmentSdProperty } from './blocks/dialysis-adequacy/sections/treatment-time/create-time-treatment-sd-property';
import { createNumberOfPatientsTreatmentModalityHdfPostProperty } from './blocks/dialysis-adequacy/sections/convective-volume/create-number-of-patients-treatment-modality-hdf-post-property';
import { createPercentageConvectiveVolumeBe23Property } from './blocks/dialysis-adequacy/sections/convective-volume/create-percentage-convective-volume-be23-property';
import { createPercentageConvectiveVolumeL23Property } from './blocks/dialysis-adequacy/sections/convective-volume/create-percentage-convective-volume-l23-property';
import { createPercentageConvectiveVolumeNotDoneProperty } from './blocks/dialysis-adequacy/sections/convective-volume/create-percentage-convective-volume-not-done-property';
import { createPercentageDialysisFrequencyBe3Property } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-percentage-dialysis-frequency-be3-property';
import { createPercentageDialysisFrequencyL3Property } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-percentage-dialysis-frequency-l3-property';
import { createPercentageDialysisFrequencyNotDoneProperty } from './blocks/dialysis-adequacy/sections/dialysis-frequency/create-percentage-dialysis-frequency-not-done-property';
import { createPercentageEktvBe1AndL1p2Property } from './blocks/dialysis-adequacy/sections/ektv/create-percentage-ektv-be1-and-l1p2-property';
import { createPercentageEktvBe1p2Property } from './blocks/dialysis-adequacy/sections/ektv/create-percentage-ektv-be1p2-property';
import { createPercentageEktvL1Property } from './blocks/dialysis-adequacy/sections/ektv/create-percentage-ektv-l1-property';
import { createPercentageEktvNotDoneProperty } from './blocks/dialysis-adequacy/sections/ektv/create-percentage-ektv-not-done-property';
import { createPercentageTreatmentModalityHdfPostProperty } from './blocks/dialysis-adequacy/sections/hdf-modality/create-percentage-treatment-modality-hdf-post-property';
import { createPercentageTreatmentModalityHdfPreProperty } from './blocks/dialysis-adequacy/sections/hdf-modality/create-percentage-treatment-modality-hdf-pre-property';
import { createPercentageTreatmentModalityHdProperty } from './blocks/dialysis-adequacy/sections/treatment-modality/create-percentage-treatment-modality-hd-property';
import { createPercentageTreatmentModalityHdfProperty } from './blocks/dialysis-adequacy/sections/treatment-modality/create-percentage-treatment-modality-hdf-property';
import { createPercentageTimeTreatmentBe10p5AndL12Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-percentage-time-treatment-be10p5-and-l12-property';
import { createPercentageTimeTreatmentBe12Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-percentage-time-treatment-be12-property';
import { createPercentageTimeTreatmentBe9AndL10p5Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-percentage-time-treatment-be9-and-l10p5-property';
import { createPercentageTimeTreatmentL9Property } from './blocks/dialysis-adequacy/sections/treatment-time/create-percentage-time-treatment-l9-property';
import { createPercentageTimeTreatmentNotDoneProperty } from './blocks/dialysis-adequacy/sections/treatment-time/create-percentage-time-treatment-not-done-property';
import { createWaterQualityBacteriologyFirstResultProperty } from './blocks/end-of-loop-water-result/sections/result/create-water-quality-bacteriology-first-result-property';
import { createWaterQualityEndotoxinFirstResultProperty } from './blocks/end-of-loop-water-result/sections/result/create-water-quality-endotoxin-first-result-property';
import { createHandHygieneScoreProperty } from './blocks/hand-hygiene-audit-result/sections/hand-hygiene-audit-result/create-hand-hygiene-score-property';
import { createSeroconversionHbvChronicProperty } from './blocks/seroconversion/sections/seroconversion/create-seroconversion-hbv-chronic-property';
import { createSeroconversionHbvNewProperty } from './blocks/seroconversion/sections/seroconversion/create-seroconversion-hbv-new-property';
import { createSeroconversionHcvChronicProperty } from './blocks/seroconversion/sections/seroconversion/create-seroconversion-hcv-chronic-property';
import { createSeroconversionHcvNewProperty } from './blocks/seroconversion/sections/seroconversion/create-seroconversion-hcv-new-property';
import { createSeroconversionHivChronicProperty } from './blocks/seroconversion/sections/seroconversion/create-seroconversion-hiv-chronic-property';
import { createSeroconversionHivNewProperty } from './blocks/seroconversion/sections/seroconversion/create-seroconversion-hiv-new-property';
import { createSumOfTemporaryPatientsProperty } from './blocks/temporary-hd-patients/sections/temporary-hd-patients/create-sum-of-temporary-patients-property';
import { createSumOfHdTreatmentsProperty } from './blocks/number-of-hd-treatments/sections/number-of-hd-treatments/create-sum-of-hd-treatments-property';
import { createSumOfScoreSumProperty } from './blocks/charlson-index/sections/charlson-index/create-sum-of-score-sum-property';
import { createSumOfNumberOfPatientsProperty } from './blocks/charlson-index/sections/charlson-index/create-sum-of-number-of-patients-property';
import { createPercentageOfNumberOfPatientsLowerEightProperty } from './blocks/charlson-index/sections/charlson-index/create-percentage-of-number-of-patients-lower-eight-property';
import { createPercentageOfNumberOfPatientsGreaterOrEqualEightProperty } from './blocks/charlson-index/sections/charlson-index/create-percentage-of-number-of-patients-greater-or-equal-eight-property';
import { createScoreSumPerPatientProperty } from './blocks/charlson-index/sections/charlson-index/create-score-sum-per-patient-property';
import { createCharlsonIndexBe8PatientProperty } from './blocks/charlson-index/sections/charlson-index/create-charlson-index-be8-patient-property';
import { createCharlsonIndexBe8SumProperty } from './blocks/charlson-index/sections/charlson-index/create-charlson-index-be8-sum-property';
import { createCharlsonIndexL8PatientProperty } from './blocks/charlson-index/sections/charlson-index/create-charlson-index-l8-patient-property';
import { createCharlsonIndexL8SumProperty } from './blocks/charlson-index/sections/charlson-index/create-charlson-index-l8-sum-property';
import { createTreatmentsOwnPatientsProperty } from './blocks/number-of-hd-treatments/sections/number-of-hd-treatments/create-treatments-own-patients-property';
import { createTreatmentsTempPatientsProperty } from './blocks/number-of-hd-treatments/sections/number-of-hd-treatments/create-treatments-temp-patients-property';
import { createTempPatientHolidayProperty } from './blocks/temporary-hd-patients/sections/temporary-hd-patients/create-temp-patient-holiday-property';
import { createTempPatientTemporaryProperty } from './blocks/temporary-hd-patients/sections/temporary-hd-patients/create-temp-patient-temporary-property';
import { createHdHospitalInfectionsH22Property } from './blocks/infections/sections/avf/create-hd-hospital-infections-h22-property';
import { createHdHospitalInfectionsH23Property } from './blocks/infections/sections/avf/create-hd-hospital-infections-h23-property';
import { createHdInfectionAvfBacteriaMrsaInfectionProperty } from './blocks/infections/sections/avf/create-hd-infection-avf-bacteria-mrsa-infection-property';
import { createHdInfectionAvfExitSiteMrsaInfectionProperty } from './blocks/infections/sections/avf/create-hd-infection-avf-exit-site-mrsa-infection-property';
import { createHdInfectionAvfBacteriaOtherInfectionProperty } from './blocks/infections/sections/avf/create-hd-infection-avf-bacteria-other-infection-property';
import { createHdInfectionAvfExitSiteOtherInfectionProperty } from './blocks/infections/sections/avf/create-hd-infection-avf-exit-site-other-infection-property';
import { createHdHospitalInfectionsE22Property } from './blocks/infections/sections/cvc/create-hd-hospital-infections-e22-property';
import { createHdHospitalInfectionsE23Property } from './blocks/infections/sections/cvc/create-hd-hospital-infections-e23-property';
import { createHdInfectionCvcBacteriaMrsaInfectionProperty } from './blocks/infections/sections/cvc/create-hd-infection-cvc-bacteria-mrsa-infection-property';
import { createHdInfectionCvcBacteriaOtherInfectionProperty } from './blocks/infections/sections/cvc/create-hd-infection-cvc-bacteria-other-infection-property';
import { createHdInfectionCvcExitSiteMrsaInfectionProperty } from './blocks/infections/sections/cvc/create-hd-infection-cvc-exit-site-mrsa-infection-property';
import { createHdInfectionCvcExitSiteOtherInfectionProperty } from './blocks/infections/sections/cvc/create-hd-infection-cvc-exit-site-other-infection-property';
import { createHdHospitalInfectionsK22Property } from './blocks/infections/sections/graft/create-hd-hospital-infections-k22-property';
import { createHdHospitalInfectionsK23Property } from './blocks/infections/sections/graft/create-hd-hospital-infections-k23-property';
import { createHdInfectionGraftBacteriaMrsaInfectionProperty } from './blocks/infections/sections/graft/create-hd-infection-graft-bacteria-mrsa-infection-property';
import { createHdInfectionGraftBacteriaOtherInfectionProperty } from './blocks/infections/sections/graft/create-hd-infection-graft-bacteria-other-infection-property';
import { createHdInfectionGraftExitSiteMrsaInfectionProperty } from './blocks/infections/sections/graft/create-hd-infection-graft-exit-site-mrsa-infection-property';
import { createHdInfectionGraftExitSiteOtherInfectionProperty } from './blocks/infections/sections/graft/create-hd-infection-graft-exit-site-other-infection-property';
import { createHdHospitalInfectionsD27Property } from './blocks/infections/sections/infections/create-hd-hospital-infections-d27-property';
import { createHdHospitalInfectionsD30Property } from './blocks/infections/sections/infections/create-hd-hospital-infections-d30-property';
import { createHdHospitalInfectionsD33Property } from './blocks/infections/sections/infections/create-hd-hospital-infections-d33-property';
import { createHdInfectionHbvPlusProperty } from './blocks/infections/sections/infections/create-hd-infection-hbv-plus-property';
import { createHdInfectionHcvPlusProperty } from './blocks/infections/sections/infections/create-hd-infection-hcv-plus-property';
import { createHdInfectionHivPlusProperty } from './blocks/infections/sections/infections/create-hd-infection-hiv-plus-property';
import { createHeparinHeparinlessTreatmentProperty } from './blocks/heparin/sections/heparinless-treatment/create-heparin-heparinless-treatment-property';
import { createHeparinNotReportedPatientsProperty } from './blocks/heparin/sections/heparinless-treatment/create-heparin-not-reported-patients-property';
import { createPercentageHeparinlessTreatmentProperty } from './blocks/heparin/sections/heparinless-treatment/create-percentage-heparinless-treatment-property';
import { createPercentageHeparinNotReportedPatientsProperty } from './blocks/heparin/sections/heparinless-treatment/create-percentage-heparin-not-reported-patients-property';
import { createHospVascularAccessMissedSessionsProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-vascular-access-missed-sessions-property';
import { createHospCardiologyMissedSessionsProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-cardiology-missed-sessions-property';
import { createHospInfectionsMissedSessionsProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-infections-missed-sessions-property';
import { createSumOfHospitalizationHospCategoriesProperty } from './blocks/hospitalizations/sections/hospitalization/create-sum-of-hospitalization-hosp-categories-property';
import { createSumOfHospitalizationPatientCategoriesProperty } from './blocks/hospitalizations/sections/hospitalization/create-sum-of-hospitalization-patient-categories-property';
import { createSumOfHospitalizationDaysCategoriesProperty } from './blocks/hospitalizations/sections/hospitalization/create-sum-of-hospitalization-days-categories-property';
import { createSumOfHospitalizationMissedSessionsCategoriesProperty } from './blocks/hospitalizations/sections/hospitalization/create-sum-of-hospitalization-missed-sessions-categories-property';
import { createAgeMeanProperty } from './blocks/other-parameters/sections/age/create-age-mean-property';
import { createAgeSdProperty } from './blocks/other-parameters/sections/age/create-age-sd-property';
import { createTimeOnDialysisMeanProperty } from './blocks/other-parameters/sections/time-on-dialysis/create-time-on-dialysis-mean-property';
import { createTimeOnDialysisSdProperty } from './blocks/other-parameters/sections/time-on-dialysis/create-time-on-dialysis-sd-property';
import { createGenderFemaleProperty } from './blocks/other-parameters/sections/gender/create-gender-female-property';
import { createGenderMaleProperty } from './blocks/other-parameters/sections/gender/create-gender-male-property';
import { createPercentageGenderFemaleProperty } from './blocks/other-parameters/sections/gender/create-percentage-gender-female-property';
import { createPercentageGenderMaleProperty } from './blocks/other-parameters/sections/gender/create-percentage-gender-male-property';
import { createHeightMeanProperty } from './blocks/other-parameters/sections/height/create-height-mean-property';
import { createHeightSdProperty } from './blocks/other-parameters/sections/height/create-height-sd-property';
import { createDryBodyWeightMeanProperty } from './blocks/other-parameters/sections/dry-body-weight/create-dry-body-weight-mean-property';
import { createDryBodyWeightSdProperty } from './blocks/other-parameters/sections/dry-body-weight/create-dry-body-weight-sd-property';
import { createBmiL20Property } from './blocks/other-parameters/sections/bmi/create-bmi-l20-property';
import { createBmiBe20AndL25Property } from './blocks/other-parameters/sections/bmi/create-bmi-be20-and-l25-property';
import { createBmiBe25AndL30Property } from './blocks/other-parameters/sections/bmi/create-bmi-be25-and-l30-property';
import { createBmiBe30Property } from './blocks/other-parameters/sections/bmi/create-bmi-be30-property';
import { createBmiNotDoneProperty } from './blocks/other-parameters/sections/bmi/create-bmi-not-done-property';
import { createBmiMeanProperty } from './blocks/other-parameters/sections/bmi/create-bmi-mean-property';
import { createBmiSdProperty } from './blocks/other-parameters/sections/bmi/create-bmi-sd-property';
import { createPercentageBmiL20Property } from './blocks/other-parameters/sections/bmi/create-percentage-bmi-l20-property';
import { createPercentageBmiBe20AndL25Property } from './blocks/other-parameters/sections/bmi/create-percentage-bmi-be20-and-l25-property';
import { createPercentageBmiBe25AndL30Property } from './blocks/other-parameters/sections/bmi/create-percentage-bmi-be25-and-l30-property';
import { createPercentageBmiBe30Property } from './blocks/other-parameters/sections/bmi/create-percentage-bmi-be30-property';
import { createPercentageBmiNotDoneProperty } from './blocks/other-parameters/sections/bmi/create-percentage-bmi-not-done-property';
import { createPercentageMedicationEsaProperty } from './blocks/other-parameters/sections/medication/create-percentage-medication-esa-property';
import { createPercentageMedicationIvironProperty } from './blocks/other-parameters/sections/medication/create-percentage-medication-iviron-property';
import { createPrimaryRenalDiseaseDiabetesNephropathyProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-diabetes-nephropathy-property';
import { createPrimaryRenalDiseaseVascularProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-vascular-property';
import { createPrimaryRenalDiseaseHypertensionProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-hypertension-property';
import { createPrimaryRenalDiseaseGlomerulonephritisProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-glomerulonephritis-property';
import { createPrimaryRenalDiseaseTubulointerstitialProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-tubulointerstitial-property';
import { createPrimaryRenalDiseasePolycysticProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-polycystic-property';
import { createPrimaryRenalDiseaseOtherProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-primary-renal-disease-other-property';
import { createPercentagePrimaryRenalDiseaseDiabetesNephropathyProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-diabetes-nephropathy-property';
import { createPercentagePrimaryRenalDiseaseVascularProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-vascular-property';
import { createPercentagePrimaryRenalDiseaseHypertensionProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-hypertension-property';
import { createPercentagePrimaryRenalDiseaseGlomerulonephritisProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-glomerulonephritis-property';
import { createPercentagePrimaryRenalDiseaseTubulointerstitialProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-tubulointerstitial-property';
import { createPercentagePrimaryRenalDiseasePolycysticProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-polycystic-property';
import { createPercentagePrimaryRenalDiseaseOtherProperty } from './blocks/other-parameters/sections/primary-renal-disease/create-percentage-primary-renal-disease-other-property';
import { createPercentageComorbiditiesMyocardialInfarctionProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-myocardial-infarction-property';
import { createPercentageComorbiditiesCongestiveHeartFailureProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-congestive-heart-failure-property';
import { createPercentageComorbiditiesPeripheralVascularDiseaseProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-peripheral-vascular-disease-property';
import { createPercentageComorbiditiesCerebrovascularDiseaseProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-cerebrovascular-disease-property';
import { createPercentageComorbiditiesDiabetesChronicComplicationProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-diabetes-chronic-complication-property';
import { createPercentageComorbiditiesMalignancyProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-malignancy-property';
import { createPercentageComorbiditiesMetastaticSolidTumorProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-metastatic-solid-tumor-property';
import { createPercentageComorbiditiesModerateSevereLiverDiseaseProperty } from './blocks/other-parameters/sections/comorbidities/create-percentage-comorbidities-moderate-severe-liver-disease-property';
import { createAlbuminBe25AndL35Property } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-be25-and-l35-property';
import { createAlbuminBe35Property } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-be35-property';
import { createAlbuminL25Property } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-l25-property';
import { createAlbuminGPerLBcPurpleMeanProperty } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-g-per-l-bc-purple-mean-property';
import { createAlbuminGPerLBcPurpleSdProperty } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-g-per-l-bc-purple-sd-property';
import { createAlbuminGPerLBcGreenMeanProperty } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-g-per-l-bc-green-mean-property';
import { createAlbuminGPerLBcGreenSdProperty } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-g-per-l-bc-green-sd-property';
import { createPercentageAlbuminL25Property } from './blocks/nutrition-inflammation/sections/albumin/create-percentage-albumin-l25-property';
import { createPercentageAlbuminBe25AndL35Property } from './blocks/nutrition-inflammation/sections/albumin/create-percentage-albumin-be25-and-l35-property';
import { createPercentageAlbuminBe35Property } from './blocks/nutrition-inflammation/sections/albumin/create-percentage-albumin-be35-property';
import { createPercentageAlbuminNotDoneProperty } from './blocks/nutrition-inflammation/sections/albumin/create-percentage-albumin-not-done-property';
import { createAlbuminNotDoneProperty } from './blocks/nutrition-inflammation/sections/albumin/create-albumin-not-done-property';
import { createCrpWithinLimitsProperty } from './blocks/nutrition-inflammation/sections/crp/create-crp-within-limits-property';
import { createCrpElevatedProperty } from './blocks/nutrition-inflammation/sections/crp/create-crp-elevated-property';
import { createCrpNotDoneProperty } from './blocks/nutrition-inflammation/sections/crp/create-crp-not-done-property';
import { createPercentageCrpWithinLimitsProperty } from './blocks/nutrition-inflammation/sections/crp/create-percentage-crp-within-limits-property';
import { createPercentageCrpElevatedProperty } from './blocks/nutrition-inflammation/sections/crp/create-percentage-crp-elevated-property';
import { createPercentageCrpNotDoneProperty } from './blocks/nutrition-inflammation/sections/crp/create-percentage-crp-not-done-property';
import { createSerumCreatinineL8Property } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-l8-property';
import { createSerumCreatinineBe8Property } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-be8-property';
import { createPercentageSerumCreatinineL8Property } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-percentage-serum-creatinine-l8-property';
import { createPercentageSerumCreatinineBe8Property } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-percentage-serum-creatinine-be8-property';
import { createPercentageSerumCreatinineNotDoneProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-percentage-serum-creatinine-not-done-property';
import { createSerumCreatinineMgPerDlMeanProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-mg-per-dl-mean-property';
import { createSerumCreatinineMgPerDlSdProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-mg-per-dl-sd-property';
import { createSerumCreatinineUmolPerLMeanProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-umol-per-l-mean-property';
import { createSerumCreatinineUmolPerLSdProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-umol-per-l-sd-property';
import { createSerumCreatinineNotDoneProperty } from './blocks/nutrition-inflammation/sections/serum-creatinine/create-serum-creatinine-not-done-property';
import { createNutritionSgaAProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-nutrition-sga-a-property';
import { createNutritionSgaBProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-nutrition-sga-b-property';
import { createNutritionSgaCProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-nutrition-sga-c-property';
import { createNutritionSgaNotDoneProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-nutrition-sga-not-done-property';
import { createPercentageNutritionSgaAProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-percentage-nutrition-sga-a-property';
import { createPercentageNutritionSgaBProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-percentage-nutrition-sga-b-property';
import { createPercentageNutritionSgaCProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-percentage-nutrition-sga-c-property';
import { createPercentageNutritionSgaNotDoneProperty } from './blocks/nutrition-inflammation/sections/subjective-global-assessment/create-percentage-nutrition-sga-not-done-property';
import { createNutritionEiEnteralProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-nutrition-ei-enteral-property';
import { createNutritionEiIdpnProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-nutrition-ei-idpn-property';
import { createNutritionEiNotTreatedProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-nutrition-ei-not-treated-property';
import { createPercentageNutritionEiEnternalProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-percentage-nutrition-ei-enternal-property';
import { createPercentageNutritionEiIdpnProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-percentage-nutrition-ei-idpn-property';
import { createPercentageNutritionEiNotTreatedProperty } from './blocks/nutrition-inflammation/sections/en-and-idpn/create-percentage-nutrition-ei-not-treated-property';
import { createPercentageHemoglobinL10Property } from './blocks/anemia/sections/hemoglobin/create-percentage-hemoglobin-l10-property';
import { createHemoglobinL10Property } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-l10-property';
import { createHemoglobinB12Property } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-b12-property';
import { createHemoglobinBe10AndLe12Property } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-be10-and-le12-property';
import { createHemoglobinNotDoneProperty } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-not-done-property';
import { createHemoglobinGPerDlMeanProperty } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-g-per-dl-mean-property';
import { createHemoglobinGPerDlSdProperty } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-g-per-dl-sd-property';
import { createHemoglobinMmolPerLSdProperty } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-mmol-per-l-sd-property';
import { createHemoglobinMmolPerLMeanProperty } from './blocks/anemia/sections/hemoglobin/create-hemoglobin-mmol-per-l-mean-property';
import { createPercentageHemoglobinB12Property } from './blocks/anemia/sections/hemoglobin/create-percentage-hemoglobin-b12-property';
import { createPercentageHemoglobinBe10AndLe12Property } from './blocks/anemia/sections/hemoglobin/create-percentage-hemoglobin-be10-and-le12-property';
import { createPercentageHemoglobinNotDoneProperty } from './blocks/anemia/sections/hemoglobin/create-percentage-hemoglobin-not-done-property';
import { createFerritinB500Property } from './blocks/anemia/sections/ferritin/create-ferritin-b500-property';
import { createFerritinBe200AndLe500Property } from './blocks/anemia/sections/ferritin/create-ferritin-be200-and-le500-property';
import { createFerritinL200Property } from './blocks/anemia/sections/ferritin/create-ferritin-l200-property';
import { createFerritinNotDoneProperty } from './blocks/anemia/sections/ferritin/create-ferritin-not-done-property';
import { createFerritinMeanProperty } from './blocks/anemia/sections/ferritin/create-ferritin-mean-property';
import { createFerritinSdProperty } from './blocks/anemia/sections/ferritin/create-ferritin-sd-property';
import { createPercentageFerritinNotDoneProperty } from './blocks/anemia/sections/ferritin/create-percentage-ferritin-not-done-property';
import { createPercentageFerritinB500Property } from './blocks/anemia/sections/ferritin/create-percentage-ferritin-b500-property';
import { createPercentageFerritinBe200AndLe500Property } from './blocks/anemia/sections/ferritin/create-percentage-ferritin-be200-and-le500-property';
import { createPercentageFerritinL200Property } from './blocks/anemia/sections/ferritin/create-percentage-ferritin-l200-property';
import { createTransferrinSaturationL20Property } from './blocks/anemia/sections/transferrin-saturation/create-transferrin-saturation-l20-property';
import { createTransferrinSaturationB30Property } from './blocks/anemia/sections/transferrin-saturation/create-transferrin-saturation-b30-property';
import { createTransferrinSaturationNotDoneProperty } from './blocks/anemia/sections/transferrin-saturation/create-transferrin-saturation-not-done-property';
import { createTransferrinSaturationBe20AndLe30Property } from './blocks/anemia/sections/transferrin-saturation/create-transferrin-saturation-be20-and-le30-property';
import { createTransferrinSaturationSdProperty } from './blocks/anemia/sections/transferrin-saturation/create-transferrin-saturation-sd-property';
import { createPercentageTransferrinSaturationB30Property } from './blocks/anemia/sections/transferrin-saturation/create-percentage-transferrin-saturation-b30-property';
import { createPercentageTransferrinSaturationL20Property } from './blocks/anemia/sections/transferrin-saturation/create-percentage-transferrin-saturation-l20-property';
import { createPercentageTransferrinSaturationNotDoneProperty } from './blocks/anemia/sections/transferrin-saturation/create-percentage-transferrin-saturation-not-done-property';
import { createPercentageTransferrinSaturationBe20AndLe30Property } from './blocks/anemia/sections/transferrin-saturation/create-percentage-transferrin-saturation-be20-and-le30-property';
import { createEpoDoseProperty } from './blocks/anemia/sections/epo-dose/create-epo-dose-property';
import { createBloodPressureSystolicL140Property } from './blocks/blood-pressure/sections/systolic-bp/create-blood-pressure-systolic-l140-property';
import { createBloodPressureSystolicBe140Property } from './blocks/blood-pressure/sections/systolic-bp/create-blood-pressure-systolic-be140-property';
import { createBloodPressureSystolicNotDoneProperty } from './blocks/blood-pressure/sections/systolic-bp/create-blood-pressure-systolic-not-done-property';
import { createPercentageBloodPressureSystolicL140Property } from './blocks/blood-pressure/sections/systolic-bp/create-percentage-blood-pressure-systolic-l140-property';
import { createPercentageBloodPressureSystolicBe140Property } from './blocks/blood-pressure/sections/systolic-bp/create-percentage-blood-pressure-systolic-be140-property';
import { createPercentageBloodPressureSystolicNotDoneProperty } from './blocks/blood-pressure/sections/systolic-bp/create-percentage-blood-pressure-systolic-not-done-property';
import { createBloodPressureDiastolicNotDoneProperty } from './blocks/blood-pressure/sections/diastolic-bp/create-blood-pressure-diastolic-not-done-property';
import { createBloodPressureDiastolicBe90Property } from './blocks/blood-pressure/sections/diastolic-bp/create-blood-pressure-diastolic-be90-property';
import { createBloodPressureDiastolicL90Property } from './blocks/blood-pressure/sections/diastolic-bp/create-blood-pressure-diastolic-l90-property';
import { createPercentageBloodPressureDiastolicL90Property } from './blocks/blood-pressure/sections/diastolic-bp/create-percentage-blood-pressure-diastolic-l90-property';
import { createPercentageBloodPressureDiastolicNotDoneProperty } from './blocks/blood-pressure/sections/diastolic-bp/create-percentage-blood-pressure-diastolic-not-done-property';
import { createPercentageBloodPressureDiastolicBe90Property } from './blocks/blood-pressure/sections/diastolic-bp/create-percentage-blood-pressure-diastolic-be90-property';
import { createPercentagePhosphateL3p5Property } from './blocks/calcium-and-phosphate/sections/phosphate/create-percentage-phosphate-l3p5-property';
import { createPercentagePhosphateBe3p5AndL5p5Property } from './blocks/calcium-and-phosphate/sections/phosphate/create-percentage-phosphate-be3p5-and-l5p5-property';
import { createPhosphateL3p5Property } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-l3p5-property';
import { createPhosphateBe3p5AndL5p5Property } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-be3p5-and-l5p5-property';
import { createPhosphateBe5p5Property } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-be5p5-property';
import { createPhosphateNotDoneProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-not-done-property';
import { createPhosphateMgPerDlMeanProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-mg-per-dl-mean-property';
import { createPhosphateMgPerDlSdProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-mg-per-dl-sd-property';
import { createPhosphateMmolPerLMeanProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-mmol-per-l-mean-property';
import { createPhosphateMmolPerLSdProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-phosphate-mmol-per-l-sd-property';
import { createPercentagePhosphateBe5p5Property } from './blocks/calcium-and-phosphate/sections/phosphate/create-percentage-phosphate-be5p5-property';
import { createPercentagePhosphateNotDoneProperty } from './blocks/calcium-and-phosphate/sections/phosphate/create-percentage-phosphate-not-done-property';
import { createCalciumL8p4Property } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-l8p4-property';
import { createCalciumBe8p4AndLe10p5Property } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-be8p4-and-le10p5-property';
import { createCalciumB10p5Property } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-b10p5-property';
import { createCalciumNotDoneProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-not-done-property';
import { createCalciumMgPerDlMeanProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-mg-per-dl-mean-property';
import { createCalciumMgPerDlSdProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-mg-per-dl-sd-property';
import { createCalciumMmolPerLMeanProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-mmol-per-l-mean-property';
import { createCalciumMmolPerLSdProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-calcium-mmol-per-l-sd-property';
import { createPercentageCalciumL8p4Property } from './blocks/calcium-and-phosphate/sections/calcium/create-percentage-calcium-l8p4-property';
import { createPercentageCalciumBe8p4AndLe10p5Property } from './blocks/calcium-and-phosphate/sections/calcium/create-percentage-calcium-be8p4-and-le10p5-property';
import { createPercentageCalciumB10p5Property } from './blocks/calcium-and-phosphate/sections/calcium/create-percentage-calcium-b10p5-property';
import { createPercentageCalciumNotDoneProperty } from './blocks/calcium-and-phosphate/sections/calcium/create-percentage-calcium-not-done-property';
import { createIntactParathormonL100Property } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-l100-property';
import { createIntactParathormonBe100AndLe800Property } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-be100-and-le800-property';
import { createIntactParathormonB800Property } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-b800-property';
import { createIntactParathormonNotDoneProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-not-done-property';
import { createIntactParathormonPgPerMlMeanProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-pg-per-ml-mean-property';
import { createIntactParathormonPgPerMlSdProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-pg-per-ml-sd-property';
import { createIntactParathormonPmolPerLMeanProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-pmol-per-l-mean-property';
import { createIntactParathormonPmolPerLSdProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-intact-parathormon-pmol-per-l-sd-property';
import { createPercentageIpthL100Property } from './blocks/calcium-and-phosphate/sections/ipth/create-percentage-ipth-l100-property';
import { createPercentageIpthBe100AndLe800Property } from './blocks/calcium-and-phosphate/sections/ipth/create-percentage-ipth-be100-and-le800-property';
import { createPercentageIpthB800Property } from './blocks/calcium-and-phosphate/sections/ipth/create-percentage-ipth-b800-property';
import { createPercentageIpthNotDoneProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-percentage-ipth-not-done-property';
import { createPercentageOfPatientsAvfProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-percentage-of-patients-avf-property';
import { createPercentageOfPatientsCvcProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-percentage-of-patients-cvc-property';
import { createPercentageOfPatientsGraftProperty } from './blocks/number-of-chronic-hd-patients-and-kind-of-vascular-access/sections/center-hd-patients-greater-three-months/create-percentage-of-patients-graft-property';
import { createTransferrinSaturationMeanProperty } from './blocks/anemia/sections/transferrin-saturation/create-transferrin-saturation-mean-property';
import { createMedicationEsaProperty } from './blocks/other-parameters/sections/medication/create-medication-esa-property';
import { createMedicationIvironProperty } from './blocks/other-parameters/sections/medication/create-medication-iviron-property';
import { createHospCardiologyHospProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-cardiology-hosp-property';
import { createHospCardiologyPatientProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-cardiology-patient-property';
import { createHospCardiologyDaysProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-cardiology-days-property';
import { createHospVascularAccessHospProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-vascular-access-hosp-property';
import { createHospVascularAccessPatientProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-vascular-access-patient-property';
import { createHospVascularAccessDaysProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-vascular-access-days-property';
import { createHospInfectionsHospProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-infections-hosp-property';
import { createHospInfectionsPatientProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-infections-patient-property';
import { createHospInfectionsDaysProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-infections-days-property';
import { createHospOtherHospProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-other-hosp-property';
import { createHospOtherPatientProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-other-patient-property';
import { createHospOtherDaysProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-other-days-property';
import { createComorbiditiesMyocardialInfarctionProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-myocardial-infarction-property';
import { createComorbiditiesCongestiveHeartFailureProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-congestive-heart-failure-property';
import { createComorbiditiesPeripheralVascularDiseaseProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-peripheral-vascular-disease-property';
import { createComorbiditiesCerebrovascularDiseaseProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-cerebrovascular-disease-property';
import { createComorbiditiesDiabetesChronicComplicationProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-diabetes-chronic-complication-property';
import { createComorbiditiesMalignancyProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-malignancy-property';
import { createComorbiditiesMetastaticSolidTumorProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-metastatic-solid-tumor-property';
import { createComorbiditiesModerateSevereLiverDiseaseProperty } from './blocks/other-parameters/sections/comorbidities/create-comorbidities-moderate-severe-liver-disease-property';
import { createWaterQualityBacteriologyNotDoneProperty } from './blocks/end-of-loop-water-result/sections/result/create-water-quality-bacteriology-not-done-property';
import { createWaterQualityEndotoxinNotDoneProperty } from './blocks/end-of-loop-water-result/sections/result/create-water-quality-endotoxin-not-done-property';
import { createHospOtherMissedSessionsProperty } from './blocks/hospitalizations/sections/hospitalization/create-hosp-other-missed-sessions-property';
import { createSumOfIntactParathormonPatientsProperty } from './blocks/calcium-and-phosphate/sections/ipth/create-sum-of-intact-parathormon-patients-property';

export const createReportModel = (reportMonth: number) =>
  ({
    blocks: [
      {
        tooltip:
          'patients by the end of the month: number of all HD patients (both chronic and non-chronic)',
        title: 'OWN HD PATIENTS',
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createOwnPatientAllLastMonthProperty()],
              },
            ],
          },
          {
            title: 'New',
            tooltip: 'new HD patients during the month',
            propertyGroups: [
              {
                title: null,
                properties: [
                  createOwnPatientNewAdmissionProperty(),
                  createOwnPatientNewBBraunAvitumCenterProperty(),
                  createOwnPatientNewOtherCenterProperty(),
                  createOwnPatientNewBackTranspProperty(),
                  createOwnPatientNewPdToHdProperty(),
                  createSumOfNewPatientsProperty(),
                ],
              },
            ],
          },
          {
            title: 'Resigned',
            tooltip:
              'resigned HD patients, who quit dialysis due to various reasons during the month',
            propertyGroups: [
              {
                title: null,
                properties: [
                  createOwnPatientResignedTranspProperty(),
                  createOwnPatientResignedExitusChronicCardiovascularProperty(),
                  createOwnPatientResignedExitusChronicCerebrovascularProperty(),
                  createOwnPatientResignedExitusChronicInfectionProperty(),
                  createOwnPatientResignedExitusChronicMalignancyProperty(),
                  createOwnPatientResignedExitusChronicOtherProperty(),
                  createSumOfExitusChronicProperty(),
                  createOwnPatientResignedExitusNonchronicProperty(),
                  createOwnPatientResignedQuittedProperty(),
                  createOwnPatientResignedBBraunAvitumCenterProperty(),
                  createOwnPatientResignedOtherCenterProperty(),
                  createOwnPatientResignedHdToPdProperty(),
                  createSumOfResignedProperty(),
                ],
              },
            ],
          },
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createSumOfPatientsByTheEndOfThisMonthProperty()],
              },
            ],
          },
        ],
      },
      {
        title: 'NUMBER OF HD PATIENTS ON WAITING LIST',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createHdTransplantWaitingPatientProperty()],
              },
            ],
          },
        ],
      },
      {
        title: 'NUMBER OF CHRONIC HD PATIENTS AND KIND OF VASCULAR ACCESS',
        tooltip: null,
        sections: [
          {
            title: 'Number of chronic HD patients',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createOwnPatientHemoDialysis3MonthProperty()],
              },
            ],
          },
          {
            title: 'Center HD patients > 3 months with *',
            tooltip: 'please report access actually used',
            propertyGroups: [
              {
                title: null,
                properties: [
                  createHdInfectionCvcPatientNumProperty(),
                  createHdInfectionAvfPatientNumProperty(),
                  createHdInfectionGraftPatientNumProperty(),
                  createSumOfChronicHdPatientsProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageOfPatientsCvcProperty(),
                  createPercentageOfPatientsAvfProperty(),
                  createPercentageOfPatientsGraftProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'TEMPORARY HD PATIENTS',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createTempPatientHolidayProperty(),
                  createTempPatientTemporaryProperty(),
                  createSumOfTemporaryPatientsProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'NUMBER OF HD TREATMENTS',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createTreatmentsOwnPatientsProperty(),
                  createTreatmentsTempPatientsProperty(),
                  createSumOfHdTreatmentsProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'CHARLSON INDEX',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: 'Score Sum',
                properties: [
                  createCharlsonIndexL8SumProperty(),
                  createCharlsonIndexBe8SumProperty(),
                  createSumOfScoreSumProperty(),
                ],
              },
              {
                title: 'Nr. of patients',
                properties: [
                  createCharlsonIndexL8PatientProperty(),
                  createCharlsonIndexBe8PatientProperty(),
                  createSumOfNumberOfPatientsProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageOfNumberOfPatientsLowerEightProperty(),
                  createPercentageOfNumberOfPatientsGreaterOrEqualEightProperty(),
                ],
              },
            ],
          },
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createScoreSumPerPatientProperty()],
              },
            ],
          },
        ],
      },
      {
        title: 'DIALYSIS ADEQUACY',
        tooltip: null,
        sections: [
          {
            title: 'eKt/V',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 1.2',
                properties: [
                  createEktvL1Property(),
                  createEktvBe1AndL1p2Property(),
                  createEktvBe1p2Property(),
                  createEktvNotDoneProperty(),
                  createSumOfEktvPatientsProperty(),
                  createEktvMeanProperty(),
                  createEktvSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageEktvL1Property(),
                  createPercentageEktvBe1AndL1p2Property(),
                  createPercentageEktvBe1p2Property(),
                  createPercentageEktvNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Blood flow',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createBloodFlowMeanProperty(),
                  createBloodFlowSdProperty(),
                ],
              },
            ],
          },
          {
            title: 'Treatment modality',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createTreatmentModalityHdProperty(),
                  createTreatmentModalityHdfProperty(),
                  createSumOfTreatmentModalityProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageTreatmentModalityHdProperty(),
                  createPercentageTreatmentModalityHdfProperty(),
                ],
              },
            ],
          },
          {
            title: 'HDF modality',
            tooltip: 'only for HDF patients',
            propertyGroups: [
              {
                title: null,
                properties: [
                  createTreatmentModalityHdfPostProperty(),
                  createTreatmentModalityHdfPreProperty(),
                ],
              },
              {
                title: '% of HDF Patients',
                properties: [
                  createPercentageTreatmentModalityHdfPostProperty(),
                  createPercentageTreatmentModalityHdfPreProperty(),
                ],
              },
            ],
          },
          {
            title: 'Convective volume',
            tooltip: 'only for post-dilution HDF patients',
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 23 l/session',
                properties: [
                  createConvectiveVolumeBe23Property(),
                  createConvectiveVolumeL23Property(),
                  createConvectiveVolumeNotDoneProperty(),
                  createSumOfconvectiveVolumePatientsProperty(),
                  createNumberOfPatientsTreatmentModalityHdfPostProperty(),
                  createConvectiveVolumeMeanProperty(),
                  createConvectiveVolumeSdProperty(),
                ],
              },
              {
                title: '% of post-dilution HDF Patients',
                properties: [
                  createPercentageConvectiveVolumeBe23Property(),
                  createPercentageConvectiveVolumeL23Property(),
                  createPercentageConvectiveVolumeNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Dialysis Frequency',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 3x/week',
                properties: [
                  createDialysisFreqL3Property(),
                  createDialysisFreqBe3Property(),
                  createDialysisFreqNotDoneProperty(),
                  createSumOfDialysisFreqPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'dialysisFrequency',
                  ),
                  createDialysisFreqMeanProperty(),
                  createDialysisFreqSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageDialysisFrequencyL3Property(),
                  createPercentageDialysisFrequencyBe3Property(),
                  createPercentageDialysisFrequencyNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Treatment Time',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 12 hours',
                properties: [
                  createTimeTreatmentL9Property(),
                  createTimeTreatmentBe9AndL10p5Property(),
                  createTimeTreatmentBe10p5AndL12Property(),
                  createTimeTreatmentBe12Property(),
                  createTimeTreatmentNotDoneProperty(),
                  createSumOfTimeTreatmentPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'treatmentTime',
                  ),
                  createTimeTreatmentMeanProperty(),
                  createTimeTreatmentSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageTimeTreatmentL9Property(),
                  createPercentageTimeTreatmentBe9AndL10p5Property(),
                  createPercentageTimeTreatmentBe10p5AndL12Property(),
                  createPercentageTimeTreatmentBe12Property(),
                  createPercentageTimeTreatmentNotDoneProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'CALCIUM AND PHOSPHATE',
        tooltip: null,
        sections: [
          {
            title: 'Phosphate',
            tooltip: null,
            propertyGroups: [
              {
                title:
                  'Avitum Target: ≥ 3.5 < 5.5 mg/dl (≥ 1.10 < 1.78 mmol/l)',
                properties: [
                  createPhosphateL3p5Property(),
                  createPhosphateBe3p5AndL5p5Property(),
                  createPhosphateBe5p5Property(),
                  createPhosphateNotDoneProperty(),
                  createSumOfPhosphatePatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'phosphate',
                  ),
                  createPhosphateMgPerDlMeanProperty(),
                  createPhosphateMgPerDlSdProperty(),
                  createPhosphateMmolPerLMeanProperty(),
                  createPhosphateMmolPerLSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentagePhosphateL3p5Property(),
                  createPercentagePhosphateBe3p5AndL5p5Property(),
                  createPercentagePhosphateBe5p5Property(),
                  createPercentagePhosphateNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Calcium',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 8.4 ≤ 10.5 mg/dl (≥ 2.1 ≤ 2.6 mmol/l)',
                properties: [
                  createCalciumL8p4Property(),
                  createCalciumBe8p4AndLe10p5Property(),
                  createCalciumB10p5Property(),
                  createCalciumNotDoneProperty(),
                  createSumOfCalciumPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'calcium',
                  ),
                  createCalciumMgPerDlMeanProperty(),
                  createCalciumMgPerDlSdProperty(),
                  createCalciumMmolPerLMeanProperty(),
                  createCalciumMmolPerLSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageCalciumL8p4Property(),
                  createPercentageCalciumBe8p4AndLe10p5Property(),
                  createPercentageCalciumB10p5Property(),
                  createPercentageCalciumNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'iPTH',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum target: ≥ 100 ≤ 800 pg/ml (≥ 10.5 ≤ 85 pmol/l)',
                properties: [
                  createIntactParathormonL100Property(),
                  createIntactParathormonBe100AndLe800Property(),
                  createIntactParathormonB800Property(),
                  createIntactParathormonNotDoneProperty(),
                  createSumOfIntactParathormonPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'intactParathormon',
                  ),
                  createIntactParathormonPgPerMlMeanProperty(),
                  createIntactParathormonPgPerMlSdProperty(),
                  createIntactParathormonPmolPerLMeanProperty(),
                  createIntactParathormonPmolPerLSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageIpthL100Property(),
                  createPercentageIpthBe100AndLe800Property(),
                  createPercentageIpthB800Property(),
                  createPercentageIpthNotDoneProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'ANEMIA',
        tooltip: null,
        sections: [
          {
            title: 'Hemoglobin',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 10 ≤ 12 g/dl (≥ 6.2 ≤ 7.4 mmol/l)',
                properties: [
                  createHemoglobinL10Property(),
                  createHemoglobinBe10AndLe12Property(),
                  createHemoglobinB12Property(),
                  createHemoglobinNotDoneProperty(),
                  createSumOfHemoglobinPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'hemoglobin',
                  ),
                  createHemoglobinGPerDlMeanProperty(),
                  createHemoglobinGPerDlSdProperty(),
                  createHemoglobinMmolPerLMeanProperty(),
                  createHemoglobinMmolPerLSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageHemoglobinL10Property(),
                  createPercentageHemoglobinBe10AndLe12Property(),
                  createPercentageHemoglobinB12Property(),
                  createPercentageHemoglobinNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Ferritin',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 200 ≤ 500 µg/l',
                properties: [
                  createFerritinL200Property(),
                  createFerritinBe200AndLe500Property(),
                  createFerritinB500Property(),
                  createFerritinNotDoneProperty(),
                  createSumOfFerritinPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'ferritin',
                  ),
                  createFerritinMeanProperty(),
                  createFerritinSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageFerritinL200Property(),
                  createPercentageFerritinBe200AndLe500Property(),
                  createPercentageFerritinB500Property(),
                  createPercentageFerritinNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Transferrin saturation',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: ≥ 20 ≤ 30 %',
                properties: [
                  createTransferrinSaturationL20Property(),
                  createTransferrinSaturationBe20AndLe30Property(),
                  createTransferrinSaturationB30Property(),
                  createTransferrinSaturationNotDoneProperty(),
                  createSumOfTransferrinSaturationPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'transferrinSaturation',
                  ),
                  createTransferrinSaturationMeanProperty(),
                  createTransferrinSaturationSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageTransferrinSaturationL20Property(),
                  createPercentageTransferrinSaturationBe20AndLe30Property(),
                  createPercentageTransferrinSaturationB30Property(),
                  createPercentageTransferrinSaturationNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'EPO-dose',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createEpoDoseProperty()],
              },
            ],
          },
        ],
      },
      {
        title: 'BLOOD PRESSURE',
        tooltip: null,
        sections: [
          {
            title: 'Systolic BP',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: < 140 mmHg',
                properties: [
                  createBloodPressureSystolicL140Property(),
                  createBloodPressureSystolicBe140Property(),
                  createBloodPressureSystolicNotDoneProperty(),
                  createSumOfBloodPressureSystolicPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'bloodPressureSystolic',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageBloodPressureSystolicL140Property(),
                  createPercentageBloodPressureSystolicBe140Property(),
                  createPercentageBloodPressureSystolicNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Diastolic BP',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: < 90 mmHg',
                properties: [
                  createBloodPressureDiastolicL90Property(),
                  createBloodPressureDiastolicBe90Property(),
                  createBloodPressureDiastolicNotDoneProperty(),
                  createSumOfBloodPressureDiastolicPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'bloodPressureDiastolic',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageBloodPressureDiastolicL90Property(),
                  createPercentageBloodPressureDiastolicBe90Property(),
                  createPercentageBloodPressureDiastolicNotDoneProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'NUTRITION / INFLAMMATION',
        tooltip: null,
        sections: [
          {
            title: 'Albumin',
            tooltip: 'BCGreen | BCPurple',
            propertyGroups: [
              {
                title: 'BCGreen Target ≥34g/l | BCPurple Target ≥28.5g/l',
                properties: [
                  createAlbuminL25Property(),
                  createAlbuminBe25AndL35Property(),
                  createAlbuminBe35Property(),
                  createAlbuminNotDoneProperty(),
                  createSumOfAlbuminPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'albumin',
                  ),
                  createAlbuminGPerLBcPurpleMeanProperty(),
                  createAlbuminGPerLBcPurpleSdProperty(),
                  createAlbuminGPerLBcGreenMeanProperty(),
                  createAlbuminGPerLBcGreenSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageAlbuminL25Property(),
                  createPercentageAlbuminBe25AndL35Property(),
                  createPercentageAlbuminBe35Property(),
                  createPercentageAlbuminNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'CRP',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum Target: Within Limits',
                properties: [
                  createCrpWithinLimitsProperty(),
                  createCrpElevatedProperty(),
                  createCrpNotDoneProperty(),
                  createSumOfCrpPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'crp',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageCrpWithinLimitsProperty(),
                  createPercentageCrpElevatedProperty(),
                  createPercentageCrpNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Serum creatinine',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Avitum target: > 8 mg/dL (≥ 707.2 Mol/L)',
                properties: [
                  createSerumCreatinineL8Property(),
                  createSerumCreatinineBe8Property(),
                  createSerumCreatinineNotDoneProperty(),
                  createSumOfSerumCreatininePatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'serumCreatinine',
                  ),
                  createSerumCreatinineMgPerDlMeanProperty(),
                  createSerumCreatinineMgPerDlSdProperty(),
                  createSerumCreatinineUmolPerLMeanProperty(),
                  createSerumCreatinineUmolPerLSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageSerumCreatinineL8Property(),
                  createPercentageSerumCreatinineBe8Property(),
                  createPercentageSerumCreatinineNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Subjective Global Assessment ',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createNutritionSgaAProperty(),
                  createNutritionSgaBProperty(),
                  createNutritionSgaCProperty(),
                  createNutritionSgaNotDoneProperty(),
                  createSumOfNutritionSgaPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'nutiritionSga',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageNutritionSgaAProperty(),
                  createPercentageNutritionSgaBProperty(),
                  createPercentageNutritionSgaCProperty(),
                  createPercentageNutritionSgaNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title:
              'Enteral Nutrition (EN) and Intra Dialysis Parenteral Nutrition (IDPN)',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createNutritionEiEnteralProperty(),
                  createNutritionEiIdpnProperty(),
                  createNutritionEiNotTreatedProperty(),
                  createSumOfNutritionEiPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'nutritionEi',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageNutritionEiEnternalProperty(),
                  createPercentageNutritionEiIdpnProperty(),
                  createPercentageNutritionEiNotTreatedProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'HEPARIN',
        tooltip: null,
        sections: [
          {
            title: 'Standard heparin',
            tooltip: 'heparin formulation',
            propertyGroups: [
              {
                title:
                  'Number of chronic patients treated with relevant heparin form.',
                properties: [
                  createHeparinStandardPatientsProperty(reportMonth),
                ],
              },
              {
                title: 'Mean IU /treatment/patient',
                properties: [createHeparinStandardProperty()],
              },
            ],
          },
          {
            title: 'Low molecular heparin',
            tooltip: 'heparin formulation | brand name',
            propertyGroups: [
              {
                title:
                  'Number of chronic patients treated with relevant heparin form.',
                properties: [
                  createHeparinEnoxLPatientsProperty(reportMonth),
                  createHeparinEnoxGPatientsProperty(reportMonth),
                  createHeparinEnoxAPatientsProperty(reportMonth),
                  createHeparinEnoxCPatientsProperty(reportMonth),
                  createHeparinEnoxEPatientsProperty(reportMonth),
                  createHeparinNadrPatientsProperty(reportMonth),
                  createHeparinDaltPatientsProperty(reportMonth),
                  createHeparinTinzPatientsProperty(reportMonth),
                  createHeparinBemiPatientsProperty(reportMonth),
                  createSumOfHeparinPatientsProperty(),
                ],
              },
              {
                title: 'Mean IU /treatment/patient',
                properties: [
                  createHeparinEnoxLProperty(),
                  createHeparinEnoxGProperty(),
                  createHeparinEnoxAProperty(),
                  createHeparinEnoxCProperty(),
                  createHeparinEnoxEProperty(),
                  createHeparinNadrProperty(),
                  createHeparinDaltProperty(),
                  createHeparinTinzProperty(),
                  createHeparinBemiProperty(),
                ],
              },
            ],
          },
          {
            title: 'Heparinless treatment',
            tooltip: null,
            propertyGroups: [
              {
                title: 'patients treated',
                properties: [
                  createHeparinHeparinlessTreatmentProperty(),
                  createHeparinNotReportedPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'heparinlessTreatment',
                  ),
                ],
              },
              {
                title: null,
                properties: [
                  createPercentageHeparinlessTreatmentProperty(),
                  createPercentageHeparinNotReportedPatientsProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'OTHER PARAMETERS',
        tooltip: null,
        sections: [
          {
            title: 'Age',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [createAgeMeanProperty(), createAgeSdProperty()],
              },
            ],
          },
          {
            title: 'Time on dialysis',
            tooltip: 'from the first day of dialysis',
            propertyGroups: [
              {
                title: null,
                properties: [
                  createTimeOnDialysisMeanProperty(),
                  createTimeOnDialysisSdProperty(),
                ],
              },
            ],
          },
          {
            title: 'Gender',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createGenderFemaleProperty(),
                  createGenderMaleProperty(),
                  createSumOfGenderPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'gender',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageGenderFemaleProperty(),
                  createPercentageGenderMaleProperty(),
                ],
              },
            ],
          },
          {
            title: 'Height',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createHeightMeanProperty(),
                  createHeightSdProperty(),
                ],
              },
            ],
          },
          {
            title: 'Dry body weight',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createDryBodyWeightMeanProperty(),
                  createDryBodyWeightSdProperty(),
                ],
              },
            ],
          },
          {
            title: 'BMI',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createBmiL20Property(),
                  createBmiBe20AndL25Property(),
                  createBmiBe25AndL30Property(),
                  createBmiBe30Property(),
                  createBmiNotDoneProperty(),
                  createSumOfBmiPatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'bmi',
                  ),
                  createBmiMeanProperty(),
                  createBmiSdProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageBmiL20Property(),
                  createPercentageBmiBe20AndL25Property(),
                  createPercentageBmiBe25AndL30Property(),
                  createPercentageBmiBe30Property(),
                  createPercentageBmiNotDoneProperty(),
                ],
              },
            ],
          },
          {
            title: 'Medication',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createMedicationEsaProperty(),
                  createMedicationIvironProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageMedicationEsaProperty(),
                  createPercentageMedicationIvironProperty(),
                ],
              },
            ],
          },
          {
            title: 'Primary renal disease',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createPrimaryRenalDiseaseDiabetesNephropathyProperty(),
                  createPrimaryRenalDiseaseVascularProperty(),
                  createPrimaryRenalDiseaseHypertensionProperty(),
                  createPrimaryRenalDiseaseGlomerulonephritisProperty(),
                  createPrimaryRenalDiseaseTubulointerstitialProperty(),
                  createPrimaryRenalDiseasePolycysticProperty(),
                  createPrimaryRenalDiseaseOtherProperty(),
                  createSumOfPrimaryRenalDiseasePatientsProperty(),
                  createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
                    'primaryRenalDisease',
                  ),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentagePrimaryRenalDiseaseDiabetesNephropathyProperty(),
                  createPercentagePrimaryRenalDiseaseVascularProperty(),
                  createPercentagePrimaryRenalDiseaseHypertensionProperty(),
                  createPercentagePrimaryRenalDiseaseGlomerulonephritisProperty(),
                  createPercentagePrimaryRenalDiseaseTubulointerstitialProperty(),
                  createPercentagePrimaryRenalDiseasePolycysticProperty(),
                  createPercentagePrimaryRenalDiseaseOtherProperty(),
                ],
              },
            ],
          },
          {
            title: 'Comorbidities',
            tooltip: null,
            propertyGroups: [
              {
                title: null,
                properties: [
                  createComorbiditiesMyocardialInfarctionProperty(),
                  createComorbiditiesCongestiveHeartFailureProperty(),
                  createComorbiditiesPeripheralVascularDiseaseProperty(),
                  createComorbiditiesCerebrovascularDiseaseProperty(),
                  createComorbiditiesDiabetesChronicComplicationProperty(),
                  createComorbiditiesMalignancyProperty(),
                  createComorbiditiesMetastaticSolidTumorProperty(),
                  createComorbiditiesModerateSevereLiverDiseaseProperty(),
                ],
              },
              {
                title: '% Patients',
                properties: [
                  createPercentageComorbiditiesMyocardialInfarctionProperty(),
                  createPercentageComorbiditiesCongestiveHeartFailureProperty(),
                  createPercentageComorbiditiesPeripheralVascularDiseaseProperty(),
                  createPercentageComorbiditiesCerebrovascularDiseaseProperty(),
                  createPercentageComorbiditiesDiabetesChronicComplicationProperty(),
                  createPercentageComorbiditiesMalignancyProperty(),
                  createPercentageComorbiditiesMetastaticSolidTumorProperty(),
                  createPercentageComorbiditiesModerateSevereLiverDiseaseProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'HOSPITALIZATIONS',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: 'Vascular Access',
                properties: [
                  createHospVascularAccessHospProperty(),
                  createHospVascularAccessPatientProperty(),
                  createHospVascularAccessDaysProperty(),
                  createHospVascularAccessMissedSessionsProperty(),
                ],
              },
              {
                title: 'Cardiology',
                properties: [
                  createHospCardiologyHospProperty(),
                  createHospCardiologyPatientProperty(),
                  createHospCardiologyDaysProperty(),
                  createHospCardiologyMissedSessionsProperty(),
                ],
              },
              {
                title: 'Infections',
                properties: [
                  createHospInfectionsHospProperty(),
                  createHospInfectionsPatientProperty(),
                  createHospInfectionsDaysProperty(),
                  createHospInfectionsMissedSessionsProperty(),
                ],
              },
              {
                title: 'Other',
                properties: [
                  createHospOtherHospProperty(),
                  createHospOtherPatientProperty(),
                  createHospOtherDaysProperty(),
                  createHospOtherMissedSessionsProperty(),
                ],
              },
              {
                title: 'Total',
                properties: [
                  createSumOfHospitalizationHospCategoriesProperty(),
                  createSumOfHospitalizationPatientCategoriesProperty(),
                  createSumOfHospitalizationDaysCategoriesProperty(),
                  createSumOfHospitalizationMissedSessionsCategoriesProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'INFECTIONS',
        tooltip: null,
        sections: [
          {
            title: 'CVC',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Number of MRSA infections',
                properties: [
                  createHdInfectionCvcBacteriaMrsaInfectionProperty(),
                  createHdInfectionCvcExitSiteMrsaInfectionProperty(),
                ],
              },
              {
                title: 'Number of other infections',
                properties: [
                  createHdInfectionCvcBacteriaOtherInfectionProperty(),
                  createHdInfectionCvcExitSiteOtherInfectionProperty(),
                ],
              },
              {
                title: 'Total CVC infections',
                properties: [
                  createHdHospitalInfectionsE22Property(),
                  createHdHospitalInfectionsE23Property(),
                ],
              },
            ],
          },
          {
            title: 'AVF',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Number of MRSA infections',
                properties: [
                  createHdInfectionAvfBacteriaMrsaInfectionProperty(),
                  createHdInfectionAvfExitSiteMrsaInfectionProperty(),
                ],
              },
              {
                title: 'Number of other infections',
                properties: [
                  createHdInfectionAvfBacteriaOtherInfectionProperty(),
                  createHdInfectionAvfExitSiteOtherInfectionProperty(),
                ],
              },
              {
                title: 'Total AVF infections',
                properties: [
                  createHdHospitalInfectionsH22Property(),
                  createHdHospitalInfectionsH23Property(),
                ],
              },
            ],
          },
          {
            title: 'Graft',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Number of MRSA infections',
                properties: [
                  createHdInfectionGraftBacteriaMrsaInfectionProperty(),
                  createHdInfectionGraftExitSiteMrsaInfectionProperty(),
                ],
              },
              {
                title: 'Number of other infections',
                properties: [
                  createHdInfectionGraftBacteriaOtherInfectionProperty(),
                  createHdInfectionGraftExitSiteOtherInfectionProperty(),
                ],
              },
              {
                title: 'Total Graft infections',
                properties: [
                  createHdHospitalInfectionsK22Property(),
                  createHdHospitalInfectionsK23Property(),
                ],
              },
            ],
          },
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: 'HD',
                properties: [
                  createHdInfectionHcvPlusProperty(),
                  createHdHospitalInfectionsD27Property(),
                ],
              },
            ],
          },
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: 'HD',
                properties: [
                  createHdInfectionHbvPlusProperty(),
                  createHdHospitalInfectionsD30Property(),
                ],
              },
            ],
          },
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: 'HD',
                properties: [
                  createHdInfectionHivPlusProperty(),
                  createHdHospitalInfectionsD33Property(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'SEROCONVERSION',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: 'HCV',
                properties: [
                  createSeroconversionHcvNewProperty(),
                  createSeroconversionHcvChronicProperty(),
                ],
              },
              {
                title: 'HBV',
                properties: [
                  createSeroconversionHbvNewProperty(),
                  createSeroconversionHbvChronicProperty(),
                ],
              },
              {
                title: 'HIV',
                properties: [
                  createSeroconversionHivNewProperty(),
                  createSeroconversionHivChronicProperty(),
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'HAND HYGIENE AUDIT RESULT',
        tooltip: null,
        sections: [
          {
            title: null,
            tooltip: null,
            propertyGroups: [
              {
                title: '%',
                properties: [createHandHygieneScoreProperty()],
              },
            ],
          },
        ],
      },
      {
        title: 'END OF LOOP WATER RESULTS',
        tooltip: null,
        sections: [
          {
            title: 'Result',
            tooltip: null,
            propertyGroups: [
              {
                title: 'Result',
                properties: [
                  createWaterQualityEndotoxinFirstResultProperty(),
                  createWaterQualityBacteriologyFirstResultProperty(),
                ],
              },
              {
                title: null,
                properties: [
                  createWaterQualityEndotoxinNotDoneProperty(),
                  createWaterQualityBacteriologyNotDoneProperty(),
                ],
              },
            ],
          },
        ],
      },
    ],
  } as const);
