import {
  hasOptionalPropertyOfType,
  isAllTypesOf,
  isOneTypeOf,
  isRecord,
  isString,
  isSymbol,
} from '@bbraun/shared/util-lang';

export const isErrorObject = isAllTypesOf(
  hasOptionalPropertyOfType('code', isOneTypeOf(isString, isSymbol)),
  hasOptionalPropertyOfType(
    'meta',
    isAllTypesOf(
      isRecord<string, unknown>(),
      hasOptionalPropertyOfType('values', isRecord<string, unknown>()),
    ),
  ),
);
