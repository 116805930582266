export function loadConfig<TEnvironment>(
  production: boolean,
  {
    path = 'config.json',
    validator,
  }: {
    path?: string;
    validator?: ((data: unknown) => boolean) & { errors?: unknown };
  } = {},
): Promise<TEnvironment | undefined> {
  if (!production) {
    return Promise.resolve(undefined);
  }

  return fetch(path, { headers: { 'cache-control': 'no-cache' } })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.error(
          'Fetching configuration did not return a successful response',
        );
        return undefined;
      }
    })
    .then((config) => {
      if (!validator || validator(config)) {
        return config;
      } else {
        console.error(
          'The loaded configuration is invalid.',
          validator.errors,
          config,
        );
        return undefined;
      }
    })
    .catch((error) => {
      console.error('Error loading configuration', { error });
      return undefined;
    });
}
