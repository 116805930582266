import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { refreshTokenName } from '../../constants';
import { LoginError } from '../../interfaces/login-error';
import {
  isTokenResponse,
  TokenResponse,
} from '../../interfaces/token-response';
import { TokenResult } from '../../interfaces/token-result';
import { DataAccessSecurityErrorCodes } from '../data-access-security-error-codes';
import { createFullUrl } from './create-full-url';

export function refreshAccessToken(
  refreshToken: string | undefined,
  {
    baseUrl,
    http,
  }: {
    baseUrl: string;
    http: HttpClient;
  },
): Observable<TokenResult> {
  const currentUrl = new URL(window.location.href);
  const refreshTokenFromSearchParams =
    currentUrl.searchParams.get(refreshTokenName);
  currentUrl.searchParams.delete(refreshTokenName);

  history.replaceState(history.state, '', currentUrl.href);

  const refreshTokenToUse = refreshTokenFromSearchParams || refreshToken;

  return refreshTokenToUse
    ? http
        .post<TokenResponse | LoginError>(
          createFullUrl(baseUrl, 'token', {
            refreshToken: refreshTokenToUse,
            refreshTokenName,
          }),
          null,
          {
            withCredentials: true,
          },
        )
        .pipe(
          map((response) =>
            isTokenResponse(response) && response.token
              ? ({
                  success: true,
                  token: response.token,
                  refreshToken: response.refreshToken,
                } as const)
              : ({
                  success: false,
                  error: {
                    code: DataAccessSecurityErrorCodes.UNAUTHORIZED,
                    message: 'No access token',
                  },
                } as const),
          ),
        )
        .pipe(
          catchError((error) =>
            of({
              success: false,
              error: {
                code: error.status,
                message: error.statusText,
              },
            } as const),
          ),
        )
    : of({
        success: false,
        error: {
          code: DataAccessSecurityErrorCodes.SESSION_EXPIRED,
          message: 'No refresh token',
        },
      });
}
