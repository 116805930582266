import { EMPTY, of, OperatorFunction, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export function isDefined<T>({
  throwError: throwErrorOption = false,
}: {
  throwError?: boolean;
} = {}): OperatorFunction<T | undefined, T> {
  return concatMap((value) =>
    value !== undefined
      ? of(value)
      : throwErrorOption
      ? throwError(() => new Error('Value is not defined'))
      : EMPTY,
  );
}
