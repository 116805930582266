import { Inject, Injectable } from '@angular/core';
import { RefreshTokenConfiguration } from '../interfaces/token-configuration';
import { REFRESH_TOKEN_CONFIGURATION_TOKEN } from './injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    @Inject(REFRESH_TOKEN_CONFIGURATION_TOKEN)
    private readonly configuration: RefreshTokenConfiguration,
  ) {}

  getToken(): string | undefined {
    return localStorage.getItem(this.configuration.storageKey) || undefined;
  }

  setToken(token: string | undefined) {
    if (token) {
      localStorage.setItem(this.configuration.storageKey, token);
    } else {
      localStorage.removeItem(this.configuration.storageKey);
    }
  }
}
