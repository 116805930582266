import { Inject, Injectable } from '@angular/core';
import {
  Message,
  MessageOptions,
  MessageSender,
  MessageService,
  MessageSeverity,
} from '@bbraun/shared/util-message-ng';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, isObservable, merge, of } from 'rxjs';
import {
  catchError,
  concatMap,
  endWith,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { MessageObject } from './message-object';
import { TranslocoMessageService } from './transloco-message-service.type';

@Injectable({ providedIn: 'root' })
export class TranslocoMessageServiceFactory {
  constructor(
    @Inject(MessageService) private readonly messageService: MessageSender,
    private readonly translocoService: TranslocoService,
  ) {}

  createForScope(scope: string): TranslocoMessageService {
    const translocoService = this.translocoService;
    const messageService = this.messageService;

    const loadedLang$ = translocoService.langChanges$
      .pipe(
        switchMap((lang) =>
          merge(
            translocoService
              .load(`${scope}/${lang}`)
              .pipe(take(1))
              .pipe(catchError(() => EMPTY))
              .pipe(concatMap(() => EMPTY)),
            translocoService
              .load(`bbraunSharedUtilTranslocoMessage/${lang}`)
              .pipe(take(1))
              .pipe(catchError(() => EMPTY))
              .pipe(concatMap(() => EMPTY)),
          ).pipe(endWith(lang)),
        ),
      )
      .pipe(
        map((lang) => ({
          lang,
          defaultTitles: createDefaultTitles(lang, translocoService),
        })),
      );

    return {
      message<TValues>(
        messageOrFnOrMessageObject:
          | Message<TValues>
          | ((
              values: TValues | undefined,
              lang: string,
              translocoService: TranslocoService,
            ) => string | { message: string; title: string })
          | MessageObject<TValues>,
        severityOrUndefined?: MessageSeverity,
        optionsOrUndefined?: MessageOptions<TValues>,
      ): void {
        const messageOrFn =
          messageOrFnOrMessageObject instanceof MessageObject
            ? messageOrFnOrMessageObject.messageOrFn
            : messageOrFnOrMessageObject;
        const severity =
          messageOrFnOrMessageObject instanceof MessageObject
            ? messageOrFnOrMessageObject.severity
            : severityOrUndefined || 'info';
        const options =
          messageOrFnOrMessageObject instanceof MessageObject
            ? messageOrFnOrMessageObject.options
            : optionsOrUndefined;

        const fn = (values: TValues | undefined) =>
          loadedLang$
            .pipe(
              concatMap(({ lang, defaultTitles }) => {
                if (typeof messageOrFn === 'function') {
                  const messageOrObservable = messageOrFn(
                    values,
                    lang,
                    translocoService,
                  );
                  return isObservable(messageOrObservable)
                    ? messageOrObservable.pipe(
                        map((messageOrString) => ({
                          defaultTitles,
                          messageOrString,
                        })),
                      )
                    : of({
                        defaultTitles,
                        messageOrString: messageOrObservable,
                      });
                } else {
                  return of({ defaultTitles, messageOrString: messageOrFn });
                }
              }),
            )
            .pipe(
              map(({ defaultTitles, messageOrString }) =>
                typeof messageOrString === 'string'
                  ? {
                      defaultTitles,
                      title: undefined,
                      message: messageOrString,
                    }
                  : { ...messageOrString, defaultTitles },
              ),
            )
            .pipe(
              map(({ defaultTitles, message, title }) => ({
                message,
                title: title || defaultTitles[severity],
              })),
            );
        messageService.message(fn, severity, options);
      },
      remove(messageIds: ReadonlyArray<Symbol>) {
        messageService.remove(messageIds);
      },
    } as TranslocoMessageService;
  }
}

function createDefaultTitles(
  lang: string,
  translocoService: TranslocoService,
): { [key in MessageSeverity]: string } {
  return {
    info: translocoService.translate(
      'bbraunSharedUtilTranslocoMessage.translocoMessageService.information.title',
      undefined,
      lang,
    ),
    warning: translocoService.translate(
      'bbraunSharedUtilTranslocoMessage.translocoMessageService.warning.title',
      undefined,
      lang,
    ),
    error: translocoService.translate(
      'bbraunSharedUtilTranslocoMessage.translocoMessageService.error.title',
      undefined,
      lang,
    ),
    success: translocoService.translate(
      'bbraunSharedUtilTranslocoMessage.translocoMessageService.success.title',
      undefined,
      lang,
    ),
    debug: translocoService.translate(
      'bbraunSharedUtilTranslocoMessage.translocoMessageService.debug.title',
      undefined,
      lang,
    ),
  };
}
