type Constructor<T> = new (...args: any[]) => T;

export class MixinBuilder<TBase extends Constructor<any>> {
  constructor(public readonly result: TBase) {}

  public addMixin<TResult extends Constructor<any>>(
    mixin: (base: TBase) => TBase & TResult,
  ): MixinBuilder<TBase & TResult> {
    return new MixinBuilder(mixin(this.result));
  }
}
