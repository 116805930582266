import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import {
  CanSynchronizeLabTestMappingGuard,
  LabTestMappingResponseModel,
} from '@bbraun/bav-reporting/data-access-administration';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';
import { TranslocoService } from '@ngneat/transloco';
import DataSource from 'devextreme/data/data_source';
import { DxJsonApiDataSource2 } from '@bbraun/shared/util-devexpress';
import { FiqlQuery } from '@bbraun/shared/util-fiql';
import {
  JsonApiPaging,
  JsonApiSort,
  UpdateObject,
} from '@bbraun/shared/util-jsonapi';
import { InfoBoxItem } from '@bbraun/shared/ui-info-box';
import { WithIsChangedSupportComponent } from '@bbraun/bav-reporting/ui-common';
import { DxDataGridComponent } from 'devextreme-angular';
import { FeatureAdministrationScopedTranslationService } from '../../services/feature-administration-scoped-translation.service';
import { LabTestMappingService } from '../../services/lab-test-mapping.service';
import { BAV_REPORTING_ADMINISTRATION_MESSAGE_SERVICE } from '../../injection-tokens';

type ParameterDisplayNameMap = ReadonlyArray<{
  parameterName: LabTestMappingResponseModel['labTestIdClinicalData'];
  displayName: string;
}>;

const dropdownSelectionWidth = 300;

const parameterSelectionOptions = [
  {
    parameterName: 'HemoglobinGPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.hemoglobinGPerDl.text',
    ),
  },
  {
    parameterName: 'HemoglobinGPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.hemoglobinGPerL.text',
    ),
  },
  {
    parameterName: 'HemoglobinMmolPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.hemoglobinMmolPerL.text',
    ),
  },
  {
    parameterName: 'FerritinUgPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.ferritinUgPerL.text',
    ),
  },
  {
    parameterName: 'FerritinNgPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.ferritinNgPerDl.text',
    ),
  },
  {
    parameterName: 'TransferrinSaturationPercent',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.transferrinSaturationPercent.text',
    ),
  },
  {
    parameterName: 'CalciumInSerumMgPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.calciumInSerumMgPerDl.text',
    ),
  },
  {
    parameterName: 'CalciumInSerumMmolPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.calciumInSerumMmolPerL.text',
    ),
  },
  {
    parameterName: 'PhosphateInSerumMgPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.phosphateInSerumMgPerDl.text',
    ),
  },
  {
    parameterName: 'PhosphateInSerumMmolPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.phosphateInSerumMmolPerL.text',
    ),
  },
  {
    parameterName: 'IntactParathormonPgPerMl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.intactParathormonPgPerMl.text',
    ),
  },
  {
    parameterName: 'IntactParathormonNgPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.intactParathormonNgPerL.text',
    ),
  },
  {
    parameterName: 'IntactParathormonPmolPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.intactParathormonPmolPerL.text',
    ),
  },
  {
    parameterName: 'AlbuminBromocresolGreenGPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.albuminBromocresolGreenGPerL.text',
    ),
  },
  {
    parameterName: 'AlbuminBromocresolGreenGPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.albuminBromocresolGreenGPerDl.text',
    ),
  },
  {
    parameterName: 'AlbuminBromocresolPurpleGPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.albuminBromocresolPurpleGPerL.text',
    ),
  },
  {
    parameterName: 'AlbuminBromocresolPurpleGPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.albuminBromocresolPurpleGPerDl.text',
    ),
  },
  {
    parameterName: 'CReactiveProteinMgPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.cReactiveProteinMgPerL.text',
    ),
  },
  {
    parameterName: 'CReactiveProteinMgPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.cReactiveProteinMgPerDl.text',
    ),
  },
  {
    parameterName: 'CReactiveProteinNmolPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.cReactiveProteinNmolPerL.text',
    ),
  },
  {
    parameterName: 'SerumCreatinineMgPerDl',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.serumCreatinineMgPerDl.text',
    ),
  },
  {
    parameterName: 'SerumCreatinineUmolPerL',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.serumCreatinineUmolPerL.text',
    ),
  },
  {
    parameterName: 'NotApplicable',
    displayName: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.notApplicable.text',
    ),
  },
] as const;

const contextDescriptionInfoBoxItems: InfoBoxItem[] = [
  {
    title: '',
    type: 'text',
    text: i18n(
      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.info.text',
    ),
  },
];

@Component({
  selector: 'bav-reporting-feature-administration-lab-data-test-configuration',
  templateUrl: './lab-data-test-configuration.component.html',
  styleUrls: ['./lab-data-test-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabDataTestConfigurationComponent
  implements WithIsChangedSupportComponent
{
  get changed(): boolean {
    return this.isEditing;
  }

  readonly dataSource: DataSource;

  readonly parameterSelectionOptions$: Observable<ParameterDisplayNameMap>;

  readonly infoBoxItems$: Observable<InfoBoxItem[]>;

  private isEditing = false;

  constructor(
    private readonly mappingService: LabTestMappingService,
    @Inject(BAV_REPORTING_ADMINISTRATION_MESSAGE_SERVICE)
    private readonly translocoMessageService: TranslocoMessageService,
    private readonly translocoService: TranslocoService,
    private readonly scopedTranslationService: FeatureAdministrationScopedTranslationService,
    public readonly canSynchronizeLabTestMappingGuard: CanSynchronizeLabTestMappingGuard,
  ) {
    const query = (dataSourceQueryParams: {
      filter?: FiqlQuery;
      sort?: JsonApiSort[];
      page?: JsonApiPaging;
    }) => {
      if (
        dataSourceQueryParams.filter ||
        dataSourceQueryParams.sort ||
        dataSourceQueryParams.page
      ) {
        return throwError(
          () =>
            new Error('Filter, sort and paging parameter are not supported.'),
        );
      } else {
        return this.mappingService.list();
      }
    };

    const update = (entry: UpdateObject) => this.mappingService.update(entry);

    this.dataSource = new DxJsonApiDataSource2(
      this.translocoService,
      {
        query,
        update,
      },
      {
        key: 'id',
        errorHandling: {
          catchError: false,
          reporter: {
            onError: (error) => {
              this.startEditing();
              this.translocoMessageService.message(
                (_, lang, translationService) => ({
                  message: translationService.translate(
                    i18n(
                      'bbraunBavReportingFeatureAdministration.labDataTestConfiguration.parameterMapping.loading.error.message',
                    ),
                    {},
                    lang,
                  ),
                }),
                'error',
                {
                  id: Symbol('ERROR_ID'),
                  error,
                  actions: [{ type: 'update' }],
                },
              );
            },
          },
        },
      },
    );

    this.parameterSelectionOptions$ =
      this.scopedTranslationService.loadedLang$.pipe(
        map(({ translate }) =>
          parameterSelectionOptions.map((option) => ({
            ...option,
            displayName: translate(option.displayName),
          })),
        ),
      );

    this.infoBoxItems$ = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) =>
        contextDescriptionInfoBoxItems.map((item) => ({
          ...item,
          text: translate(item.text),
        })),
      ),
    );
  }

  startEditing() {
    this.isEditing = true;
  }

  saveChanges(dataGrid: DxDataGridComponent) {
    dataGrid.instance.saveEditData();
    this.isEditing = false;
  }

  cancelEditing(dataGrid: DxDataGridComponent) {
    dataGrid.instance.cancelEditData();
    this.isEditing = false;
  }

  cancelEditingWithEscape(
    keyDownEvent: { event: { key: string } },
    dataGrid: DxDataGridComponent,
  ) {
    if (keyDownEvent.event.key === 'Escape') {
      this.cancelEditing(dataGrid);
    }
  }

  setDropdownWidth(event: any) {
    event.editorOptions.dropDownOptions = { width: dropdownSelectionWidth };
  }
}
