import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Inject,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ANGULAR_LOCALE } from '@bbraun/shared/data-access-locale';
import { Observable } from 'rxjs';
import {
  convertLocalizedNumberToString,
  LocalizedNumberFormatErrorHandler,
  LocalizedNumberOptions,
} from '../functions/convert-localized-number-to-string';
import { LOCALIZED_NUMBER_FORMAT_ERROR_HANDLER } from '../injection-tokens';
import { LocalizedPipeBase } from './localized-pipe-base';

export type LocalizedNumberPipeOptions =
  | string
  | (LocalizedNumberOptions & {
      locale?: string;
      onError?: LocalizedNumberFormatErrorHandler;
    });

@Pipe({ name: 'bbraunUiLanguageLocalizedNumber', pure: false })
export class LocalizedNumberPipe
  extends LocalizedPipeBase<[LocalizedNumberPipeOptions | undefined]>
  implements PipeTransform
{
  constructor(
    @Inject(ChangeDetectorRef) cdr: Pick<ChangeDetectorRef, 'markForCheck'>,
    @Inject(DecimalPipe) decimalPipe: Pick<DecimalPipe, 'transform'>,
    @Inject(ANGULAR_LOCALE) angularLocale: Observable<string>,
    @Inject(LOCALIZED_NUMBER_FORMAT_ERROR_HANDLER)
    @Optional()
    numberFormatErrorHandler?:
      | LocalizedNumberFormatErrorHandler
      | null
      | undefined,
  ) {
    super(
      cdr,
      angularLocale,
      (currentLocale, value: any, options?: LocalizedNumberPipeOptions) => {
        const actualLocale =
          (typeof options !== 'string' && options?.locale) || currentLocale;

        if (actualLocale === undefined) {
          return null;
        } else if (value === undefined) {
          return undefined;
        } else if (!options || typeof options === 'string') {
          return decimalPipe.transform(value, options, actualLocale);
        } else {
          return convertLocalizedNumberToString(
            actualLocale,
            value,
            options,
            options.onError || numberFormatErrorHandler || (() => null),
          );
        }
      },
    );
  }

  /**
   * @param value The number to be formatted.
   * @param options Complex option or the decimal representation options as a string
   * in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `0`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `3`.
   * When not supplied, uses the current value of `ANGULAR_LOCALE`.
   */
  transform(
    value: number | null | undefined,
    options?: LocalizedNumberPipeOptions,
  ): string | null | undefined {
    return super.transform(value, options);
  }
}
