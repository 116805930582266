<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiStartView';
    read: 'bbraunSharedUiStartView.startViewComponent'
  "
>
  <mat-card class="bbraun-flat start-card" data-test="start">
    <div class="mat-card-image start-image"></div>
    <div *ngIf="productName">
      <mat-card-header>
        <mat-card-title> {{ productName }} </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <ng-content class="description"></ng-content>
      <div class="buildinfo-container">
        <p *ngIf="buildinfo$ | async as buildinfo" class="buildinfo mat-small">
          Version: {{ buildinfo.hash ? buildinfo.hash + '@' : ''
          }}{{ buildinfo.name || '' }} (#{{ buildinfo.id }})
        </p>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-button
        color="accent"
        [routerLink]="['/app/login']"
        data-test="start:login"
      >
        {{ translate('loginButton.text') }}
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>
