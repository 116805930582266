import { Injectable } from '@angular/core';
import { ErrorCodeIgnore } from '@bbraun/shared/util-error';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataAccessSecurityErrorCodes } from './data-access-security-error-codes';
import { LoginWithPrincipalService } from './login-with-principal.service';

const IS_IGNORE_SECURITY_ERROR_CODE: Record<
  string | symbol,
  ((isLoggedIn: boolean) => boolean) | undefined
> = {
  [DataAccessSecurityErrorCodes.UNAUTHORIZED.toString(10)]: (
    isLoggedIn: boolean,
  ) => isLoggedIn,
  [DataAccessSecurityErrorCodes.SESSION_EXPIRED.toString(10)]: (
    isLoggedIn: boolean,
  ) => isLoggedIn,
  [DataAccessSecurityErrorCodes.LOGIN_SESSION_EXPIRED.toString(10)]: (
    isLoggedIn: boolean,
  ) => isLoggedIn,
};

export function isIgnoreErrorCode(code: string, isLoggedIn: boolean): boolean {
  const isIgnore = IS_IGNORE_SECURITY_ERROR_CODE[code];
  return !!isIgnore && isIgnore(isLoggedIn);
}

@Injectable({ providedIn: 'root' })
export class SecurityErrorCodeIgnoreService implements ErrorCodeIgnore {
  constructor(
    private readonly loginService: LoginWithPrincipalService<unknown>,
  ) {}

  isIgnore(errorCode: string | symbol): Observable<boolean> {
    const isIgnore = IS_IGNORE_SECURITY_ERROR_CODE[errorCode];
    return isIgnore
      ? this.loginService.isPrincipalLoggedIn$.pipe(
          map((isLoggedIn) => isIgnore(isLoggedIn)),
        )
      : of(false);
  }
}
