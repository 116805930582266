import { Observable, of, OperatorFunction, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ModelObject } from '../model-object.type';
import { readJsonApiObjectResponseUnsafe } from '../read-json-api-object-response-unsafe';
import { ApiDefinition, ApiTypes } from '../typed/api-types';

export function mapToJsonApiObjectResponseUnsafe<
  T,
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
>(): OperatorFunction<{} | null, T & ModelObject<TApiDefinition, TTypeName>> {
  return (o) =>
    o.pipe(
      concatMap((v) =>
        mapDocumentToJsonApiObjectResponseUnsafe<T, TApiDefinition, TTypeName>(
          v,
        ),
      ),
    );
}

export function mapDocumentToJsonApiObjectResponseUnsafe<
  T,
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
>(value: {} | null): Observable<T & ModelObject<TApiDefinition, TTypeName>> {
  try {
    return of(
      readJsonApiObjectResponseUnsafe<T, TApiDefinition, TTypeName>(value)
        .result,
    );
  } catch (exception) {
    return throwError(() => ({ document: value, exception }));
  }
}
