import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_ID } from '../../../../validation/factories/is-number-validator-factory';
import { VALUE_AND_LINKED_PROPERTY_ARE_NUMBER_OR_BOTH_NOT_NUMBER_VALIDATOR_ID } from '../../../../validation/factories/is-property-and-value-number-or-both-not-number-validator-factory';

export function createHeparinEnoxLProperty(): Property {
  return {
    title: 'Heparin (sodium/calcium)',
    technicalName: 'heparinEnoxL',
    dataType: 'number',
    unit: null,
    highlighted: false,
    subName: null,
    formula: null,
    validation: [
      {
        validator:
          VALUE_AND_LINKED_PROPERTY_ARE_NUMBER_OR_BOTH_NOT_NUMBER_VALIDATOR_ID,
        options: {
          config: {
            linkedProperty: 'heparinEnoxLPatients',
          },
          messageKey: i18n(
            'bbraunBavReportingFeatureAisReports.validation.heparinEnoxLPatientsOrHeparinEnoxL.notSet.whenOtherIsSet.error.message',
          ),
        },
      },
      { validator: IS_NUMBER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
    ],
  };
}
