export function isOneTypeOf<T, T1 extends T, T2 extends T>(
  guard1: (value: T) => value is T1,
  guard2: (value: T) => value is T2,
): (value: T) => value is T1 | T2;
export function isOneTypeOf<T, T1 extends T, T2 extends T, T3 extends T>(
  guard1: (value: T) => value is T1,
  guard2: (value: T) => value is T2,
  guard3: (value: T) => value is T3,
): (value: T) => value is T1 | T2 | T3;
export function isOneTypeOf<
  T,
  T1 extends T,
  T2 extends T,
  T3 extends T,
  T4 extends T,
>(
  guard1: (value: T) => value is T1,
  guard2: (value: T) => value is T2,
  guard3: (value: T) => value is T3,
  guard4: (value: T) => value is T4,
): (value: T) => value is T1 | T2 | T3 | T4;
export function isOneTypeOf<
  T,
  T1 extends T,
  T2 extends T,
  T3 extends T,
  T4 extends T,
  T5 extends T,
>(
  guard1: (value: T) => value is T1,
  guard2: (value: T) => value is T2,
  guard3: (value: T) => value is T3,
  guard4: (value: T) => value is T4,
  guard5: (value: T) => value is T5,
): (value: T) => value is T1 | T2 | T3 | T4 | T5;
export function isOneTypeOf(
  ...guards: ReadonlyArray<(value: unknown) => boolean>
): (value: unknown) => boolean {
  return (value) => guards.some((guard) => guard(value));
}
