import { EMPTY, of, OperatorFunction } from 'rxjs';
import { scan, switchMap } from 'rxjs/operators';

export function reduceChunked<TValue, TResult>(
  update: (acc: TResult, next: TValue) => TResult,
  initial: TResult,
): OperatorFunction<
  { state: 'start' | 'done' } | { state: 'value'; value: TValue },
  TResult
> {
  return (o) =>
    o
      .pipe(
        scan(
          (
            acc: {
              done: boolean;
              value: TResult;
            },
            next,
          ) =>
            next.state === 'value'
              ? {
                  done: false,
                  value: update(acc.value, next.value),
                }
              : next.state === 'start'
              ? { done: false, value: initial }
              : { done: true, value: acc.value },
          { done: false, value: initial },
        ),
      )
      .pipe(switchMap((result) => (result.done ? of(result.value) : EMPTY)));
}
