import { MessageService } from '@bbraun/shared/util-message-ng';
import { TranslocoService } from '@ngneat/transloco';
import {
  combineLatest,
  defer,
  MonoTypeOperatorFunction,
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { sendUnsupportedLocaleMessage } from '../services/send-unsupported-locale-message';

const tzLoaded$ = defer(
  () =>
    // @ts-ignore - no type definitions exist and no type definitions are required
    import('@formatjs/intl-datetimeformat/add-all-tz'),
)
  .pipe(map(() => true))
  .pipe(first())
  .pipe(shareReplay({ bufferSize: 1, refCount: false }));

const loadedIntlLocales = new Set();

export function loadIntlDateTimeFormatLocaleData(
  messageService: MessageService,
  translocoService: TranslocoService,
): MonoTypeOperatorFunction<string> {
  return (source: Observable<string>) =>
    source.pipe(
      switchMap((locale) => {
        if (!loadedIntlLocales.has(locale)) {
          const isSupportedLocale =
            Intl.DateTimeFormat.supportedLocalesOf(locale).length > 0;

          return (
            isSupportedLocale
              ? of(locale)
              : combineLatest([
                  tzLoaded$,
                  defer(
                    () =>
                      import(
                        /* webpackExclude: /\.d\.ts$/ */ `@formatjs/intl-datetimeformat/locale-data/${locale}`
                      ),
                  ),
                ])
                  .pipe(tap(() => loadedIntlLocales.add(locale)))
                  .pipe(map(() => locale))
          ).pipe(
            catchError((error) => {
              sendUnsupportedLocaleMessage(
                locale,
                error,
                messageService,
                translocoService,
              );
              return of(locale);
            }),
          );
        } else {
          return of(locale);
        }
      }),
    );
}
