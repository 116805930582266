import { Observable } from 'rxjs';
import { LoginWithPrincipalService } from './services/login-with-principal.service';

export function createIsLoginRunningTrigger(
  loginService: LoginWithPrincipalService<unknown>,
): Observable<boolean> {
  return loginService.isLoginInProgress$;
}

export function createIsLogoutRunningTrigger(
  loginService: LoginWithPrincipalService<unknown>,
): Observable<boolean> {
  return loginService.isLogoutInProgress$;
}
