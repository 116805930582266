import { EMPTY, Observable } from 'rxjs';
import { SimpleStoreConfigurationBuilder } from './simple-store-configuration-builder';
import { SimpleStoreService } from './simple-store.service';
import { StoreEvent, StoreEventConfiguration } from './simple-store.type';

export interface CreateSimpleStoreWithInitialStateFrom<
  TState,
  TStoreEventConfiguration extends StoreEventConfiguration<TState, unknown>,
> {
  fromEventSource(
    eventSource: Observable<StoreEvent<TStoreEventConfiguration>>,
    tearDownLogic?: (state: TState) => TState,
  ): SimpleStoreService<TState, TStoreEventConfiguration>;
  fromEmpty(
    tearDownLogic?: (state: TState) => TState,
  ): SimpleStoreService<TState, TStoreEventConfiguration>;
}

class CreateSimpleStoreWithInitialState<TState> {
  constructor(private readonly initialState: TState) {}

  withEventConfiguration<
    TStoreEventConfiguration extends StoreEventConfiguration<TState, unknown>,
  >(
    configure: (
      configurationBuilder: SimpleStoreConfigurationBuilder<
        TState,
        StoreEventConfiguration<TState, unknown>
      >,
    ) => SimpleStoreConfigurationBuilder<TState, TStoreEventConfiguration>,
  ): CreateSimpleStoreWithInitialStateFrom<TState, TStoreEventConfiguration> {
    return {
      fromEventSource: (
        eventSource: Observable<StoreEvent<TStoreEventConfiguration>>,
        tearDownLogic?: (state: TState) => TState,
      ) =>
        new SimpleStoreService(
          this.initialState,
          configure(
            new SimpleStoreConfigurationBuilder<
              TState,
              StoreEventConfiguration<TState, unknown>
            >({}),
          ).configuration,
          eventSource,
          tearDownLogic,
        ),
      fromEmpty: (tearDownLogic?: (state: TState) => TState) =>
        new SimpleStoreService(
          this.initialState,
          configure(
            new SimpleStoreConfigurationBuilder<
              TState,
              StoreEventConfiguration<TState, unknown>
            >({}),
          ).configuration,
          EMPTY,
          tearDownLogic,
        ),
    };
  }
}

export function createSimpleStoreWithInitialState<TState>(
  initialState: TState,
) {
  return new CreateSimpleStoreWithInitialState(initialState);
}
