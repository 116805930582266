import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { IS_BOOLEAN_ID } from '../../../../validation/factories/is-boolean-validator-factory';

export function createWaterQualityEndotoxinNotDoneProperty(): Property {
  return {
    title: 'Endotoxin',
    technicalName: 'waterQualityEndotoxinNotDone',
    dataType: 'boolean',
    unit: null,
    highlighted: false,
    subName: 'Test not done',
    formula: null,
    validation: [{ validator: IS_BOOLEAN_ID }],
  };
}
