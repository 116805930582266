import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ExpertLoaderApiService,
  EXPERT_LOADER_API_SERVICE,
} from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class CanStartTransferGuard {
  readonly canActivate$: Observable<boolean>;

  constructor(
    @Inject(EXPERT_LOADER_API_SERVICE)
    apiService: ExpertLoaderApiService,
  ) {
    this.canActivate$ = apiService
      .index()
      .pipe(map((result) => !!result?.links.startLoad));
  }
}
