import { ApiTypes } from './typed/api-types';
import { Fieldsets } from './typed/fieldsets';
import {
  QueryParameter,
  TypedQueryParameters,
} from './typed/typed-query-parameters';

export function combineFiqlFilter<
  TTypes extends ApiTypes,
  TResource extends keyof TTypes,
  TFieldsets extends Partial<Fieldsets<TTypes>>,
>(
  filter1: TypedQueryParameters<TTypes, TFieldsets, TResource>['filter'],
  filter2: TypedQueryParameters<TTypes, TFieldsets, TResource>['filter'],
): TypedQueryParameters<TTypes, TFieldsets, TResource>['filter'];
export function combineFiqlFilter(
  filter1: QueryParameter['filter'],
  filter2: QueryParameter['filter'],
): QueryParameter['filter'];
export function combineFiqlFilter<
  TTypes extends ApiTypes,
  TResource extends keyof TTypes,
  TFieldsets extends Partial<Fieldsets<TTypes>>,
>(
  filter1:
    | TypedQueryParameters<TTypes, TFieldsets, TResource>['filter']
    | QueryParameter['filter'],
  filter2:
    | TypedQueryParameters<TTypes, TFieldsets, TResource>['filter']
    | QueryParameter['filter'],
):
  | TypedQueryParameters<TTypes, TFieldsets, TResource>['filter']
  | QueryParameter['filter'] {
  if (
    filter1 &&
    filter2 &&
    (typeof filter1 === 'string' || typeof filter2 === 'string')
  ) {
    throw new Error('Can not combine string filter query parameters.');
  }

  return filter1 && filter2
    ? ({ and: [filter1, filter2] } as
        | TypedQueryParameters<TTypes, TFieldsets, TResource>['filter']
        | QueryParameter['filter'])
    : filter1
    ? filter1
    : filter2;
}
