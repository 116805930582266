export function extractSearchParams(
  resourceUrlSearch: string,
): Array<Array<string>> {
  const matchResult = resourceUrlSearch.match(/^\??(.*)$/);
  const str = matchResult ? matchResult[1] : '';

  return str
    .split('&')
    .map((v) => v.match(/^([^=]+)(?:=(.*))?$/))
    .map((match) => (match ? [match[1], match[2]] : []));
}
