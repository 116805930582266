import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { LocaleEffects } from './+state/locale.effects';
import { LocaleFacade } from './+state/locale.facade';
import { localeReducer, LOCALE_FEATURE_KEY } from './+state/locale.reducer';
import {
  APP_DEFAULT_LOCALE,
  LocaleService,
} from './initializers/locale.service';
import {
  ANGULAR_LOCALE,
  NGRX_CONFIG_TOKEN,
  SUPPORTED_LOCALES,
  TRANSLATION_LOADER_REGISTRY,
} from './injection-tokens';
import {
  createLocaleObservable,
  createNgrxConfig,
  initLocale,
} from './provider-factories';
import { AngularLocaleObservableFactory } from './services/angular-locale-observable-factory';
import { InMemoryStatePersistenceStrategy } from './state-persistence/in-memory-state-persistence-strategy';
import { LocalStorageStatePersistenceStrategy } from './state-persistence/local-storage-state-persistence-strategy';
import {
  APP_STATE_PERSISTENCE,
  StatePersistenceStrategyType,
} from './state-persistence/state-persistence-strategy';
import { TranslationLoaderRegistry } from './translation-loader-registry.type';

const scope = 'bbraunSharedDataAccessLocale';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      LOCALE_FEATURE_KEY,
      localeReducer,
      NGRX_CONFIG_TOKEN,
    ),
    EffectsModule.forFeature([LocaleEffects]),
  ],
  providers: [
    {
      provide: ANGULAR_LOCALE,
      useFactory: createLocaleObservable,
      deps: [AngularLocaleObservableFactory],
    },
    {
      provide: NGRX_CONFIG_TOKEN,
      useFactory: createNgrxConfig,
    },
    LocaleFacade,
    LocaleService,
    {
      provide: APP_INITIALIZER,
      useFactory: initLocale,
      deps: [LocaleService],
      multi: true,
    },
  ],
})
export class DataAccessLocaleModule {
  static forRoot(
    config: {
      defaultLocale: string;
      supportedLocales: string[];
      statePersistence: StatePersistenceStrategyType;
    } = {
      supportedLocales: ['en'],
      defaultLocale: 'en',
      statePersistence: 'localStorage',
    },
  ): ModuleWithProviders<DataAccessLocaleModule> {
    return {
      ngModule: DataAccessLocaleModule,
      providers: [
        { provide: APP_DEFAULT_LOCALE, useValue: config.defaultLocale },
        {
          provide: SUPPORTED_LOCALES,
          useValue: config.supportedLocales,
        },
        config.statePersistence === 'localStorage'
          ? {
              provide: APP_STATE_PERSISTENCE,
              useClass: LocalStorageStatePersistenceStrategy,
            }
          : {
              provide: APP_STATE_PERSISTENCE,
              useClass: InMemoryStatePersistenceStrategy,
            },
      ],
    };
  }

  constructor(
    @Optional() @SkipSelf() parent: DataAccessLocaleModule,
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    if (parent) {
      throw new Error(
        'DataAccessLocaleModule is already loaded. Import it in AppModule only!',
      );
    }

    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      scope,
    );
  }
}
