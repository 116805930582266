import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AccessTokenService } from '../services/access-token.service';
import { DataAccessSecurityErrorCodes } from '../services/data-access-security-error-codes';
import { SecurityConfigService } from '../services/security-config.service';

export const UNAUTHORIZED_ERROR_OBJECT = {
  code: DataAccessSecurityErrorCodes.UNAUTHORIZED,
  message: 'No valid access token.',
};

@Injectable({ providedIn: 'root' })
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AccessTokenService)
    private readonly accessTokenService: Pick<AccessTokenService, 'token$'>,
    @Inject(SecurityConfigService)
    private readonly securityConfigService: Pick<
      SecurityConfigService,
      'isWhitelistedDomain' | 'isBlacklistedRoute'
    >,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !this.securityConfigService.isWhitelistedDomain(req.url) ||
      this.securityConfigService.isBlacklistedRoute(req.url)
    ) {
      return next.handle(req);
    }

    return this.accessTokenService.token$
      .pipe(first())
      .pipe(
        switchMap((tokens) =>
          tokens ? of(tokens) : throwError(() => UNAUTHORIZED_ERROR_OBJECT),
        ),
      )
      .pipe(
        switchMap((tokens) => {
          const cloned = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${tokens.token}`),
          });
          return next.handle(cloned);
        }),
      );
  }
}
