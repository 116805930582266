import { ExtractFromUnion } from '../types/extract-from-union.type';

export function hasProperty<TPropertyName extends string | number | symbol>(
  propertyName: TPropertyName,
): <T>(value: T) => value is T & {
  [key in TPropertyName]: key extends (T extends infer U ? keyof U : never)
    ? ExtractFromUnion<T, key>
    : never;
} {
  return ((value: unknown) =>
    typeof value === 'object' &&
    value !== null &&
    propertyName in value) as any;
}
