import {
  DxFilterGroupOperator,
  isDxFilterGroupOperator,
} from './dx-filter-group-operator';
import { DxFilterOperation, isDxFilterOperation } from './dx-filter-operation';
import {
  DxFilterUnaryOperation,
  isDxFilterUnaryOperation,
} from './dx-filter-unary-operation';

export type DxFilterGroup = Array<
  | DxFilterOperation
  | DxFilterUnaryOperation
  | DxFilterGroupOperator
  | Array<DxFilterOperation | DxFilterUnaryOperation | DxFilterGroupOperator>
>;

export function isDxFilterGroup(element: any): element is DxFilterGroup {
  if (!Array.isArray(element)) {
    return false;
  }

  if (element.length < 2) {
    return false;
  }

  if (
    element.filter(
      (e) =>
        isDxFilterOperation(e) ||
        isDxFilterUnaryOperation(e) ||
        isDxFilterGroup(e),
    ).length === element.length
  ) {
    return true;
  }

  let valid = true;
  for (let i = 0; i < element.length; i++) {
    if (i % 2 === 0) {
      valid =
        isDxFilterOperation(element[i]) ||
        isDxFilterUnaryOperation(element[i]) ||
        isDxFilterGroup(element[i]);
    } else {
      valid = isDxFilterGroupOperator(element[i]);
    }
    if (!valid) {
      break;
    }
  }

  return valid;
}
