import { PropertyPathBuilder } from '@bbraun/shared/util-lang';
import {
  FiqlAndExpression,
  FiqlEqualsConstraint,
  FiqlGreaterThanConstraint,
  FiqlGreaterThanOrEqualConstraint,
  FiqlLessThanConstraint,
  FiqlLessThanOrEqualConstraint,
  FiqlNotEqualsConstraint,
  FiqlOrExpression,
  TypedFiqlQuery,
  ValueType,
} from './fiql.types';
import { FiqlQuery } from './untyped-fiql.type';

const operatorList: ReadonlyArray<
  (
    fiqlQuery: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) => string | false
> = [
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<FiqlEqualsConstraint<PropertyPathBuilder<unknown>>>(
      input,
      'equals',
    )
      ? `${encoder(toString(input.equals.selector))}==${encoder(
          toString(input.equals.args),
        )}`
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<FiqlNotEqualsConstraint<PropertyPathBuilder<unknown>>>(
      input,
      'not_equals',
    )
      ? `${encoder(toString(input.not_equals.selector))}!=${encoder(
          toString(input.not_equals.args),
        )}`
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<FiqlLessThanConstraint<PropertyPathBuilder<unknown>>>(
      input,
      'less_than',
    )
      ? `${encoder(toString(input.less_than.selector))}=lt=${encoder(
          toString(input.less_than.args),
        )}`
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<
      FiqlLessThanOrEqualConstraint<PropertyPathBuilder<unknown>>
    >(input, 'less_than_or_equal')
      ? `${encoder(toString(input.less_than_or_equal.selector))}=le=${encoder(
          toString(input.less_than_or_equal.args),
        )}`
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<FiqlGreaterThanConstraint<PropertyPathBuilder<unknown>>>(
      input,
      'greater_than',
    )
      ? `${encoder(toString(input.greater_than.selector))}=gt=${encoder(
          toString(input.greater_than.args),
        )}`
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<
      FiqlGreaterThanOrEqualConstraint<PropertyPathBuilder<unknown>>
    >(input, 'greater_than_or_equal')
      ? `${encoder(
          toString(input.greater_than_or_equal.selector),
        )}=ge=${encoder(toString(input.greater_than_or_equal.args))}`
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<FiqlAndExpression<PropertyPathBuilder<unknown>>>(
      input,
      'and',
    )
      ? resolveChildQueries(input, input.and, ';', encoder)
      : false,
  (
    input: TypedFiqlQuery<PropertyPathBuilder<unknown>> | FiqlQuery,
    encoder: Encoder,
  ) =>
    isFiqlConstraint<FiqlOrExpression<PropertyPathBuilder<unknown>>>(
      input,
      'or',
    )
      ? resolveChildQueries(input, input.or, ',', encoder)
      : false,
];

function toString(input: ValueType | PropertyPathBuilder<unknown>): string {
  return isPropertyPathBuilder(input) ? input.join('.') : `${input}`;
}

type Encoder = (v: string) => string;

function isPropertyPathBuilder(
  input: ValueType | PropertyPathBuilder<unknown>,
): input is PropertyPathBuilder<unknown> {
  return !!input && typeof input === 'object';
}

export function serializeTypedFiqlQuery<
  TPath extends PropertyPathBuilder<unknown>,
>(
  fiqlQuery: TypedFiqlQuery<TPath> | FiqlQuery,
  encoder: Encoder = encodeURIComponent,
): string | false {
  let result: string | false = false;

  for (let i = 0; i < operatorList.length && result === false; i++) {
    result = operatorList[i](fiqlQuery, (s) =>
      encoder(s).replace(/\(/g, '%28').replace(/\)/g, '%29'),
    );
  }
  return result;
}

function resolveChildQueries<TPath extends PropertyPathBuilder<unknown>>(
  parent: TypedFiqlQuery<TPath>,
  fiqlQueries: ReadonlyArray<TypedFiqlQuery<TPath> | FiqlQuery>,
  seperator: string,
  encoder: Encoder,
): string {
  const allEqual = allQueriesSameExpression(parent, fiqlQueries);

  return fiqlQueries
    .map((childQuery) => {
      const innerResult = serializeTypedFiqlQuery(childQuery, encoder);
      if (
        (isFiqlConstraint(childQuery, 'and') ||
          isFiqlConstraint(childQuery, 'or')) &&
        !allEqual
      ) {
        return `(${innerResult})`;
      } else {
        return innerResult;
      }
    })
    .join(seperator);
}

function allQueriesSameExpression<TPath extends PropertyPathBuilder<unknown>>(
  parent: TypedFiqlQuery<TPath>,
  fiqlQueries: ReadonlyArray<TypedFiqlQuery<TPath> | FiqlQuery>,
): boolean {
  return (
    (isFiqlConstraint(parent, 'and') &&
      fiqlQueries.every((query) => isFiqlConstraint(query, 'and'))) ||
    (isFiqlConstraint(parent, 'or') &&
      fiqlQueries.every((query) => isFiqlConstraint(query, 'or')))
  );
}

function isFiqlConstraint<TFiqlQuery extends TypedFiqlQuery | FiqlQuery>(
  element: TypedFiqlQuery | FiqlQuery,
  key: keyof TFiqlQuery,
): element is TFiqlQuery {
  return element.hasOwnProperty(key);
}
