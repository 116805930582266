import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DxSelectBoxModule } from 'devextreme-angular';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { LanguageDialogComponent } from './components/language-dialog/language-dialog.component';
import { LanguageComponent } from './components/language/language.component';
import { UI_LANGUAGE_TRANSLOCO_SERVICE } from './injection-tokens';
import { LocalizedCurrencyFormatPipe } from './pipes/localized-currency-format.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocalizedDayOfWeekPipe } from './pipes/localized-day-of-week.pipe';
import { LocalizedMoneyPipe } from './pipes/localized-money.pipe';
import { LocalizedNumberPipe } from './pipes/localized-number.pipe';
import { scope } from './services/shared-ui-language-translation.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    DxSelectBoxModule,
    TranslocoModule,
  ],
  declarations: [
    LanguageDialogComponent,
    LanguageComponent,
    LocalizedCurrencyFormatPipe,
    LocalizedMoneyPipe,
    LocalizedDatePipe,
    LocalizedNumberPipe,
    LocalizedDayOfWeekPipe,
  ],
  exports: [
    LanguageDialogComponent,
    LanguageComponent,
    LocalizedCurrencyFormatPipe,
    LocalizedMoneyPipe,
    LocalizedDatePipe,
    LocalizedNumberPipe,
    LocalizedDayOfWeekPipe,
  ],
  providers: [
    { provide: UI_LANGUAGE_TRANSLOCO_SERVICE, useClass: TranslocoService },
    LocalizedMoneyPipe,
  ],
})
export class UiLanguageModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      scope,
    );
  }
}
