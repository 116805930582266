import { fromPairs } from './from-pairs';

export function pick<TIn, TProperty extends keyof TIn>(
  value: TIn,
  properties: ReadonlyArray<TProperty>,
): Pick<TIn, TProperty> {
  return fromPairs(
    properties.reduce(
      (acc: ReadonlyArray<[TProperty, TIn[TProperty]]>, property) => [
        ...acc,
        [property, value[property]],
      ],
      [],
    ),
  ) as Pick<TIn, TProperty>;
}
