import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSidenav, MatDrawerToggleResult } from '@angular/material/sidenav';
import { SIDEBAR_DATA_TEST } from './sidebar.data-test';

@Component({
  selector: 'bbraun-ui-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // TODO kdraba: The sidebar does not open on click, if the strategy is set to OnPush (tested in nexadia).
  // This should be fixed or justified/explained/documented here!
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SidebarComponent {
  @ViewChild(MatSidenav)
  sidenav?: MatSidenav;

  @Input()
  isPrincipalLoggedIn = false;

  @Input()
  isLinkEnabled = true;

  @Input()
  principal: { id?: string; name: string } | null | undefined;

  @Input()
  hideBackButton = false;

  @Output()
  readonly toggled = new EventEmitter<MatDrawerToggleResult>();

  @Output()
  readonly logoutRequested = new EventEmitter<void>();

  @Output()
  readonly loginRequested = new EventEmitter<void>();

  readonly dataTest = SIDEBAR_DATA_TEST;

  toggle() {
    if (this.sidenav) {
      this.sidenav.toggle().then((r) => {
        this.toggled.next(r);
      });
    }
  }

  close() {
    if (this.sidenav) {
      this.sidenav.close().then((r) => {
        this.toggled.next(r);
      });
    }
  }

  logout() {
    this.logoutRequested.next();
  }

  login() {
    this.loginRequested.next();
  }
}
