import { serializeFiqlQuery } from '@bbraun/shared/util-fiql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessTokenService } from './access-token-service.type';
import { combineQueryParameters } from './combine-query-parameters';
import { JsonApiHttpClient } from './http.type';
import { IndexProvider, IndexResult } from './index/index-provider';
import { JsonApiAdapter2 } from './json-api-adapter2';
import { LinkObject } from './json-api.types';
import { serializeSort } from './serialize-sort';
import { JsonApiMeta } from './set-api-meta-headers';
import { ApiDefinition, ApiTypes } from './typed/api-types';
import { QueryParameter } from './typed/typed-query-parameters';

export type KeysWithValueType<TObject, TValue> = {
  [key in keyof TObject]: TObject[key] extends TValue ? key : never;
}[keyof TObject];

export type Identifiable<T = unknown> = T & { id: string };

export function isIdentifiable<T extends { id?: unknown }>(
  value: T | null | undefined,
): value is Identifiable<T> {
  return typeof value?.id === 'string';
}

export class JsonApiService<
  TApiDefinition extends ApiDefinition<ApiTypes> & {
    index: {
      meta: { features?: ReadonlyArray<string> };
      links: Partial<{
        [key: string]: LinkObject;
      }>;
    };
  },
> {
  private readonly indexProvider: IndexProvider<TApiDefinition>;

  constructor(
    private readonly httpClient: JsonApiHttpClient,
    accessTokenService: AccessTokenService,
    private readonly baseUrl: string,
    private readonly apiMeta: JsonApiMeta = {},
  ) {
    this.indexProvider = new IndexProvider(
      httpClient,
      accessTokenService,
      this.apiMeta,
      baseUrl,
    );
  }

  public index(): Observable<IndexResult<TApiDefinition> | undefined> {
    return this.indexProvider.index$;
  }

  public features(): Observable<TApiDefinition['index']['meta']['features']> {
    return this.index().pipe(
      map((index) => (index && index.meta && index.meta.features) || []),
    );
  }

  public resource<
    TResourceType extends keyof TApiDefinition['index']['links'] & string,
  >(
    resourceName: TResourceType,
    params: QueryParameter & {
      readonly force?: boolean;
    } = {
      force: false,
    },
  ) {
    const { force, ...queryParameter } = params;

    return new JsonApiAdapter2(
      this.apiMeta,
      resourceName,
      this.index().pipe(
        map((result) => {
          const link = result?.links[resourceName];
          const resourceLink = typeof link === 'string' ? link : link?.href;
          const url =
            resourceLink || (force && `${this.baseUrl}${resourceName}`);

          if (url) {
            return url;
          } else {
            throw new Error(
              `Can not access unknown or restricted resource <${resourceName}>`,
            );
          }
        }),
      ),
      this.httpClient,
      serializeFiqlQuery,
      serializeSort,
      combineQueryParameters,
      queryParameter,
    );
  }
}
