import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StatePersistenceStrategy } from './state-persistence-strategy';

@Injectable()
export class LocalStorageStatePersistenceStrategy
  implements StatePersistenceStrategy
{
  get<V>(key: string): Observable<V | undefined> {
    const stored = localStorage.getItem(key);
    if (stored != null) {
      return of(JSON.parse(stored));
    }
    return of(undefined);
  }

  store<V>(key: string, value?: V) {
    if (value === null || value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}
