export function showStaticContent(
  rootSelector: string,
  outOfServiceSelector = '#bbraun-loading-out-of-service',
  loadingSelector = '#bbraun-loading-content-container',
) {
  const desktopRootElement = document.querySelector(rootSelector);
  if (!desktopRootElement) {
    console.warn(`Could not find root element <${rootSelector}>`);
    return;
  }
  desktopRootElement.remove();

  const contentContainerElement = document.querySelector(loadingSelector);
  if (!contentContainerElement) {
    console.warn('Could not find target container element');
    return;
  }

  const templateElement = document.querySelector(
    outOfServiceSelector,
  ) as HTMLTemplateElement;
  if (!templateElement) {
    console.warn(`Could not find content element ${outOfServiceSelector}`);
    return;
  }

  contentContainerElement.appendChild(templateElement.content);
}
