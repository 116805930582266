import { concat, Observable } from 'rxjs';
import { endWith, map, startWith } from 'rxjs/operators';

export function fromChunks<T>(
  values: ReadonlyArray<Observable<T>>,
): Observable<{ state: 'start' | 'done' } | { state: 'value'; value: T }> {
  return concat(...values)
    .pipe(map((value) => ({ state: 'value', value } as const)))
    .pipe(startWith({ state: 'start' } as const))
    .pipe(endWith({ state: 'done' } as const));
}
