import { Defined } from '@bbraun/shared/util-lang';
import { LinkRelationship } from '../link-relationship';
import { LINKS } from '../symbols.type';
import { getFromModelObject } from './get-from-model-object';

export function getObjectLink<
  TValue extends {
    readonly [LINKS]?: Partial<{
      readonly [link: string | symbol | number]: unknown;
    }>;
  },
  TLink extends keyof Defined<TValue[typeof LINKS]>,
>(
  value: TValue,
  linkName: TLink,
): Defined<TValue[typeof LINKS]>[TLink] | undefined {
  return getFromModelObject(LINKS, value, linkName);
}

export function hasObjectLink<
  TValue extends {
    readonly [LINKS]?: Partial<{
      readonly [key: string | symbol | number]: unknown;
    }>;
  },
  TLink extends keyof Defined<TValue[typeof LINKS]>,
>(value: TValue, linkName: TLink): boolean {
  return !!getObjectLink(value, linkName);
}

export function canUpdate(value: {
  readonly [LINKS]: { readonly [LinkRelationship.UPDATE]?: unknown };
}) {
  return hasObjectLink(value, LinkRelationship.UPDATE);
}

export function canDelete(value: {
  readonly [LINKS]: { readonly [LinkRelationship.DELETE]?: unknown };
}) {
  return hasObjectLink(value, LinkRelationship.DELETE);
}
