<div class="info-box" *ngIf="infoBoxItems?.length !== 0">
  <div class="bbraun-close-icon-container">
    <mat-icon class="bbraun-close-icon" (click)="closeInfoBox()"
      >close</mat-icon
    >
  </div>
  <div class="info-box-text-container">
    <div
      *ngIf="infoBoxItems?.length === 1"
      class="info-box-column single-column"
    >
      <div class="info-item-container">
        <div class="title">{{ infoBoxItems[0].title }}</div>
        <div [innerHTML]="infoBoxItems[0].text"></div>
      </div>
    </div>
    <ng-container *ngIf="infoBoxItems.length >= 2">
      <ng-container
        *ngFor="let index of [0, 1]; first as firstColumn; last as lastColumn"
      >
        <div
          class="info-box-column"
          [class.first-column]="firstColumn"
          [class.last-column]="lastColumn"
        >
          <ng-container *ngIf="getInfoBoxItemsForColumn(index)">
            <ng-container *ngFor="let item of getInfoBoxItemsForColumn(index)">
              <div class="info-item-container">
                <div class="title">{{ item.title }}</div>
                <div [innerHTML]="item.text"></div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
