import { Inject, Injectable } from '@angular/core';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import { combineLatest, map, Observable, scan, switchMap } from 'rxjs';
import {
  ValidatorFactory,
  ValidatorFactoryFunction,
} from '@bbraun/bav-reporting/ui-report-editor';
import { REPORT_MODEL_VALIDATORS } from '../injection-tokens';
import { FeatureAisReportsScopedTranslationService } from './feature-ais-reports-scoped-translation.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyValidationLookupService {
  readonly validatorLookup: Observable<{
    [id: string]: ValidatorFactoryFunction<unknown>;
  }>;

  constructor(
    @Inject(REPORT_MODEL_VALIDATORS)
    validatorFactories: ReadonlyArray<ValidatorFactory<unknown>>,
    @Inject(FeatureAisReportsScopedTranslationService)
    private readonly scopedTranslationService: {
      loadedLang$: Observable<{ translate: TranslateFunction }>;
    },
  ) {
    this.validatorLookup = combineLatest(
      validatorFactories.map((validatorFactory) =>
        validatorFactory.validatorFn.pipe(
          switchMap((fn) =>
            this.scopedTranslationService.loadedLang$.pipe(
              map(({ translate }) => ({
                [validatorFactory.id]: (options?: {
                  config: unknown;
                  messageKey?: string;
                }) => ({
                  ...fn(options?.config),
                  ...(options?.messageKey
                    ? { message: translate(options.messageKey) }
                    : {}),
                }),
              })),
            ),
          ),
        ),
      ),
    ).pipe(
      scan(
        (acc, validatorFactory) => ({
          ...acc,
          ...validatorFactory.reduce(
            (validatorList, validator) => ({
              ...validatorList,
              ...validator,
            }),
            {},
          ),
        }),
        {},
      ),
    );
  }
}
