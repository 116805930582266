<ng-container *ngIf="isChangedProperties$ | async as isChangedProperties">
  <ng-container *ngIf="viewModel$ | async as viewModel">
    <mat-accordion multi class="bbraun-flat table-content">
      <mat-expansion-panel
        #panel
        *ngFor="
          let block of viewModel.reportModel.blocks;
          let blockIndex = index
        "
        class="block"
        [ngClass]="{
          'is-changed': viewModel.changedBlocks.has(block.identifier),
          'is-invalid': viewModel.invalidBlocks.has(block.identifier)
        }"
        (expandedChange)="onExpandedChange()"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="flex-box align-items-center">
            <div>{{ block.title }}</div>
            <mat-icon
              *ngIf="block.tooltip"
              class="info-icon"
              [matTooltip]="block.tooltip"
              matTooltipPosition="right"
              matTooltipClass="tooltip"
              >info outlined</mat-icon
            >
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="bav-reporting-editor-table">
          <div *ngFor="let section of block.sections; let sectionIndex = index">
            <div
              *ngIf="section.title"
              class="section-title flex-box align-items-center"
            >
              {{ section.title }}
              <mat-icon
                *ngIf="section.tooltip"
                class="info-icon"
                [matTooltip]="section.tooltip"
                matTooltipPosition="right"
                matTooltipClass="tooltip"
                >info</mat-icon
              >
            </div>
            <div class="section-body">
              <div
                class="column-header flex-box"
                [ngClass]="{
                  empty:
                    viewModel.isHeaderEmptyBySectionIndex[blockIndex][
                      sectionIndex
                    ]
                }"
              >
                <div class="property-group-title"></div>
                <ng-container
                  *ngFor="let propertyGroup of section.propertyGroups"
                >
                  <div
                    class="property-group-title flex-box align-items-center justify-content-center"
                  >
                    <b>
                      {{ propertyGroup.title }}
                    </b>
                  </div>
                </ng-container>
              </div>
              <div class="table-content flex-box">
                <div class="property-group-column">
                  <div
                    *ngFor="
                      let property of section.propertyGroups[0].properties
                    "
                    class="property-title flex-box align-items-center"
                    [ngClass]="{
                      calculated: property.dataType === 'calculated'
                    }"
                  >
                    <span>
                      {{ property.title }}
                      <ng-container *ngIf="property.unit"
                        >&nbsp;({{ property.unit }})</ng-container
                      >
                    </span>
                  </div>
                </div>
                <div
                  *ngFor="let propertyGroup of section.propertyGroups"
                  class="property-group-column"
                >
                  <ng-container *bavReportingUiReportEditorExpandedOnce="panel">
                    <div
                      *ngFor="let property of propertyGroup.properties"
                      class="property-value-container"
                      [ngClass]="{
                        highlighted: property.highlighted,
                        'is-changed':
                          isChangedProperties[property.technicalName],
                        'is-invalid':
                          viewModel.invalidFields[property.technicalName]
                            ?.isInvalid
                      }"
                      [matTooltip]="
                        viewModel.invalidFields[property.technicalName]
                          ?.tooltipErrorMessage
                      "
                      matTooltipPosition="right"
                      matTooltipClass="tooltip"
                    >
                      <mat-icon
                        *ngIf="
                          viewModel.invalidFields[property.technicalName]
                            ?.isInvalid
                        "
                        class="invalid-icon"
                        [ngClass]="{
                          'is-icon-in-input-field':
                            property.dataType === 'number'
                        }"
                        svgIcon="bbraun:invalid"
                      ></mat-icon>
                      <dx-number-box
                        *ngIf="property.dataType === 'number'"
                        class="align-content-right number-input full-size"
                        [step]="0"
                        [showClearButton]="true"
                        [format]="
                          (viewMode$ | async) === 'view'
                            ? {
                                maximumFractionDigits: 2
                              }
                            : null
                        "
                        [value]="
                          (viewMode$ | async) === 'view'
                            ? viewModel.viewValues[property.technicalName]
                            : viewModel.values[property.technicalName]
                        "
                        stylingMode="filled"
                        (onValueChanged)="
                          updateValue({
                            event: $event.event,
                            key: property.technicalName,
                            value: $event.value
                          })
                        "
                        [readOnly]="(viewMode$ | async) === 'view'"
                      >
                      </dx-number-box>

                      <div
                        *ngIf="property.dataType === 'boolean'"
                        class="check-box"
                      >
                        <dx-check-box
                          [value]="
                            viewModel.values &&
                            viewModel.values[property.technicalName]
                          "
                          (onValueChanged)="
                            updateValue({
                              event: $event.event,
                              key: property.technicalName,
                              value: $event.value
                            })
                          "
                          [text]="property.subName ? property.subName : ''"
                          [readOnly]="(viewMode$ | async) === 'view'"
                        >
                        </dx-check-box>
                      </div>
                      <div
                        *ngIf="property.dataType === 'calculated'"
                        class="flex-box calculated justify-content-flex-end"
                      >
                        <ng-container
                          *ngIf="viewModel.values[property.technicalName]"
                        >
                          {{ viewModel.values[property.technicalName] }}
                          {{ property.unit }}
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</ng-container>
