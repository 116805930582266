import { Inject, Injectable } from '@angular/core';
import { concatMap, first, Observable, of, throwError } from 'rxjs';
import {
  isJsonApiDataObjectCollectionResponseBody,
  JsonApiFetchResponseBody,
  JsonApiPaging,
  TypedJsonApiService,
} from '@bbraun/shared/util-jsonapi';
import {
  BAV_REPORTING_API_SERVICE,
  BavReportingJsonApi,
  UserLogsResponseModel,
  userLogsResponseModelReadParameters,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import {
  createPropertyPathBuilder,
  createSortSpecs,
} from '@bbraun/shared/util-lang';

const createdAtPropertyPathBuilder =
  createPropertyPathBuilder<UserLogsResponseModel>().add('createdAt');

const userLogsSortQueryParams = createSortSpecs().add({
  property: createdAtPropertyPathBuilder,
  desc: true,
  comparator: 'number',
} as const);

@Injectable({
  providedIn: 'root',
})
export class UserLogsService {
  constructor(
    @Inject(BAV_REPORTING_API_SERVICE)
    private readonly typedApiService: TypedJsonApiService<BavReportingJsonApi>,
  ) {}

  getUserLogs(
    page: JsonApiPaging | undefined,
  ): Observable<JsonApiFetchResponseBody> {
    return this.typedApiService
      .resource('user-log')
      .queryRaw({
        ...userLogsResponseModelReadParameters,
        sort: userLogsSortQueryParams,
        page,
      })
      .pipe(
        concatMap((response) =>
          response && isJsonApiDataObjectCollectionResponseBody(response)
            ? of(response)
            : throwError(() => new Error('Failed to load resource user-log')),
        ),
      )
      .pipe(first());
  }
}
