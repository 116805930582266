import { FiqlQuery } from '@bbraun/shared/util-fiql';
import { JsonApiPaging, JsonApiSort } from '@bbraun/shared/util-jsonapi';
import { convertDxFilterToFiql } from '../filter/convert-dx-filter-to-fiql';
import { ConvertOptions } from '../filter/convert-options.type';
import { isDxFilterOperator } from '../filter/dx-filter-operator';
import { DxLoadOptions } from './dx-load-options';

export interface DxLoadOptionsSplitSearch {
  splitter: RegExp;
  combiner: (fiqlQueries: Array<FiqlQuery>) => FiqlQuery;
}

export function convertDxLoadOptionsToJsonApi(
  opts: DxLoadOptions,
  searchProperties: string[] | undefined,
  {
    converters,
    splitSearch,
  }: { converters: ConvertOptions; splitSearch?: DxLoadOptionsSplitSearch },
): { filter?: FiqlQuery; sort?: JsonApiSort[]; page?: JsonApiPaging } {
  let filter: FiqlQuery | undefined;
  if (opts.filter) {
    filter = convertDxFilterToFiql(opts.filter, converters);
  }

  if (opts.searchValue) {
    const searchOperation = opts.searchOperation || ('=' as const);

    if (
      searchProperties &&
      searchProperties.length > 0 &&
      isDxFilterOperator(searchOperation)
    ) {
      const searchValues = splitSearch
        ? opts.searchValue.split(splitSearch.splitter).filter((value) => value)
        : [opts.searchValue];
      const fiqlQueries = searchValues.map((searchValue) => {
        const operations = searchProperties.map(
          (prop) => [prop, searchOperation, searchValue] as const,
        );

        if (operations.length === 1) {
          return convertDxFilterToFiql(operations[0], converters);
        } else {
          return {
            or: operations.map((operation) =>
              convertDxFilterToFiql(operation, converters),
            ),
          };
        }
      });

      const searchFilter =
        fiqlQueries.length > 1
          ? splitSearch && splitSearch.combiner(fiqlQueries)
          : fiqlQueries[0];

      if (searchFilter) {
        if (filter) {
          filter = { and: [filter, searchFilter] };
        } else {
          filter = searchFilter;
        }
      }
    } else {
      console.warn(
        `Datasource queried with search value, but no search properties are configured or the used search operation is unsupported.`,
        opts,
      );
    }
  }

  let sort;
  if (opts.sort) {
    sort = (Array.isArray(opts.sort) ? opts.sort : [opts.sort]).map((s) => ({
      property: s.selector,
      desc: s.desc,
    }));
  }

  let page;
  if (opts.take) {
    page = {
      size: opts.take,
      ...(opts.skip ? { number: opts.skip / opts.take + 1 } : {}),
    };
  }

  return { filter, sort, page };
}
