import {
  isDefined,
  isString,
  mergeImmutable,
  Nullable,
  ReplacePropertyType,
  toPairs,
  updatePropertiesImmutable,
  updatePropertyImmutable,
} from '@bbraun/shared/util-lang';
import {
  isValid,
  ObjectValidation,
  ObjectValidationResult,
  validate,
  Validator,
} from '@bbraun/shared/util-validation';
import { isEqual as isEqualFn } from 'lodash-es';
import { BehaviorSubject, concat, defer, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import {
  resolveValidationModeFunction,
  ValidationMode,
  ValidationModeFunction,
} from './form-data-handler-validation';
import {
  FormDataChangedEvent,
  FormDataHandlerOptions,
  FormDataHandlerState,
  IsChangedStrategy,
  NormalizeValueStrategy,
} from './form-data-handler.type';
import { normalizeValue as normalizeValueFn } from './normalize-value';
import { PartialFormData } from './partial-form-data.type';
import { updateFormDataHandlerState } from './update-form-data-handler-state';

export type DxValidationCallback = (data: { value: unknown }) => boolean;

export type DxValidationRules<TFormData extends {}> = {
  readonly [TKey in keyof TFormData]?: {
    [rule: string]: Validator<TFormData, TKey>;
  };
};

export function getDefaultIsChangedStrategy<
  TFormData extends {},
>(): IsChangedStrategy<TFormData, keyof TFormData> {
  return (_v1, _v2, { isEqual }) => !isEqual;
}

export function addFormDataHandlerDefaultOptions<
  TFormData,
  TOption extends ReplacePropertyType<
    FormDataHandlerOptions<TFormData>,
    'validationMode',
    ValidationModeFunction | ValidationMode
  >,
>(
  options: Partial<TOption> = {},
): Partial<
  Omit<TOption, 'validationMode' | keyof FormDataHandlerOptions<TFormData>>
> &
  FormDataHandlerOptions<TFormData> {
  const {
    isEqual = isEqualFn,
    isChanged = getDefaultIsChangedStrategy(),
    normalizeValue = normalizeValueFn,
    updateState = ({ formData: data }) => data,
    validationMode = resolveValidationModeFunction('changed'),
  } = options;

  return {
    ...options,
    isEqual,
    isChanged,
    normalizeValue,
    updateState,
    validationMode: isString(validationMode)
      ? resolveValidationModeFunction(validationMode)
      : validationMode,
  };
}

export class DxFormDataHandler<
  TFormData extends {} = Record<string | number | symbol, unknown>,
> {
  private currentState: FormDataHandlerState<TFormData>;

  get state() {
    return this.currentState;
  }

  readonly formData$: Observable<PartialFormData<TFormData>>;
  readonly state$: Observable<FormDataHandlerState<TFormData>>;

  private readonly changes = new Subject<FormDataChangedEvent<TFormData>>();

  private readonly options: FormDataHandlerOptions<TFormData>;

  private readonly stateSubject: BehaviorSubject<
    FormDataHandlerState<TFormData>
  >;

  get validators(): ObjectValidation<PartialFormData<TFormData>> {
    return this.currentState.validators;
  }

  set validators(validators: ObjectValidation<PartialFormData<TFormData>>) {
    if (validators !== this.currentState.validators) {
      const validationState = {
        ...this.currentState,
        validators,
      };

      const validationResult =
        validationState.validators &&
        validate<PartialFormData<TFormData>>(
          validationState.formData,
          validationState.validators,
          this.options.validationMode(validationState),
        );

      this.initState(validationState.original, {
        // TODO kdraba: NEXNG-960 this results in a duplicate normalization
        ...validationState,
        errors: validationResult,
      });
    }
  }

  /**
   *
   * @param formData
   * @param changedProperties Set to false, in order to disable changed property management and validate all values.
   * @param dirtyProperties
   * @param validators
   * @param options
   */
  constructor(
    formData: PartialFormData<TFormData>,
    changedProperties: ReadonlyArray<keyof TFormData> | false,
    dirtyProperties: ReadonlyArray<keyof TFormData>,
    validators: ObjectValidation<PartialFormData<TFormData>>,
    options: Partial<FormDataHandlerOptions<TFormData>> = {},
  ) {
    this.options = addFormDataHandlerDefaultOptions(options);

    const original = normalizeAllProperties(
      formData,
      this.options.normalizeValue,
    );

    const changedPropertiesSet =
      changedProperties && new Set(changedProperties);

    const currentState: FormDataHandlerState<TFormData> = {
      original,
      formData: original,
      changed: changedPropertiesSet && changedPropertiesSet.size > 0,
      changedProperties: changedPropertiesSet,
      dirtyProperties: new Set(dirtyProperties),
      valid: true,
      validators,
    };

    this.currentState = currentState;

    this.stateSubject = new BehaviorSubject(currentState);

    this.state$ = this.stateSubject
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.initData(original, {
      keepChangedProperties: true,
      keepDirtyProperties: true,
    });

    this.formData$ = concat(
      defer(() => of(original)),
      this.changes$().pipe(
        map(
          ({ currentState: { formData: updatedFormData } }) => updatedFormData,
        ),
      ),
    )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  changes$(): Observable<FormDataChangedEvent<TFormData>> {
    return concat(
      of({
        type: 'state',
        currentState: this.currentState,
        previousState: false,
      } as const),
      this.changes.asObservable(),
    );
  }

  changeProperty<TKey extends keyof TFormData>(
    key: TKey,
    value: TFormData[TKey] | null,
  ): boolean {
    const previousState = this.currentState;

    const updatedState = updateFormDataHandlerState(
      [[key, value]],
      previousState,
      this.options,
    );

    if (updatedState !== previousState) {
      const oldValue = this.options.normalizeValue(previousState.formData[key]);

      this.currentState = updatedState;

      this.emitChange({
        type: 'property',
        property: key,
        currentValue: this.options.normalizeValue(updatedState.formData[key]),
        oldValue,
        currentState: this.currentState,
        previousState,
      });

      this.stateSubject.next(this.currentState);

      return true;
    } else {
      return false;
    }
  }

  initData(
    formData: PartialFormData<TFormData>,
    {
      keepDirtyProperties = false,
      keepChangedProperties = false,
    }: {
      keepDirtyProperties?: boolean;
      keepChangedProperties?: boolean;
    } = {},
  ) {
    const newOriginal = normalizeAllProperties(
      formData,
      this.options.normalizeValue,
    );

    const dirtyProperties = keepDirtyProperties
      ? this.currentState.dirtyProperties
      : new Set([]);

    const changedProperties = keepChangedProperties
      ? this.currentState.changedProperties
      : this.currentState.changedProperties && new Set([]);

    const validationState = {
      original: newOriginal,
      formData: newOriginal,
      changedProperties,
      dirtyProperties,
      validators: this.validators,
      errors: this.currentState.errors,
    };

    const validationResult =
      this.currentState.validators &&
      validate<PartialFormData<TFormData>>(
        newOriginal,
        this.currentState.validators,
        this.options.validationMode(validationState),
      );

    this.initState(newOriginal, {
      // TODO kdraba: NEXNG-960 this results in a duplicate normalization
      ...validationState,
      errors: validationResult,
    });
  }

  initState(
    formData: PartialFormData<TFormData>,
    {
      original,
      changedProperties,
      dirtyProperties,
      errors,
    }: {
      original: PartialFormData<TFormData>;
      changedProperties: ReadonlySet<keyof TFormData> | false;
      dirtyProperties: ReadonlySet<keyof TFormData>;
      errors?: ObjectValidationResult<TFormData>;
    },
  ) {
    const normalizedData = normalizeAllProperties(
      formData,
      this.options.normalizeValue,
    );

    const previousState = this.currentState;
    this.currentState = updatePropertiesImmutable(previousState, {
      original: () => original,
      formData: () => normalizedData,
      changed: () => changedProperties && changedProperties.size > 0,
      dirtyProperties: (prevDirtyProperties) =>
        mergeImmutable(prevDirtyProperties, dirtyProperties, { mode: 'throw' })
          .result,
      changedProperties: (prevChangedProperties) =>
        mergeImmutable(prevChangedProperties, changedProperties, {
          mode: 'throw',
        }).result,
      valid: () => isValid(errors),
      errors: (prevErrors) =>
        mergeImmutable(prevErrors, errors, { mode: 'throw' }).result,
      validators: () => this.currentState.validators,
    });

    this.stateSubject.next(this.currentState);

    this.emitChange({
      type: 'state',
      currentState: this.currentState,
      previousState,
    });
  }

  /**
   * A forced validation will also validate initial properties and mark all invalid properties as changed.
   *
   * @param force
   * @returns
   */
  validate(force = true): boolean {
    if (force) {
      const previousState = this.currentState;

      const validationResult = validate(
        previousState.formData,
        this.validators,
      );

      const invalidProperties = new Set([
        ...toPairs(validationResult.properties)
          .map(([property, errors]) => (errors?.length ? property : undefined))
          .filter(isDefined),
      ]);

      const changedProperties =
        previousState.changedProperties && invalidProperties.size !== 0
          ? new Set([
              ...Array.from(previousState.changedProperties),
              ...invalidProperties,
            ])
          : previousState.changedProperties;

      this.currentState = {
        ...previousState,
        valid:
          validationResult === previousState.errors
            ? previousState.valid
            : isValid(validationResult),
        errors: validationResult,
        changed: changedProperties && changedProperties.size > 0,
        changedProperties,
      };

      this.stateSubject.next(this.currentState);

      this.emitChange({
        type: 'state',
        currentState: this.currentState,
        previousState,
      });
    }

    return isValid(this.currentState.errors);
  }

  clearChanged() {
    this.initData(this.currentState.formData);
  }

  private emitChange(event: FormDataChangedEvent<TFormData>) {
    if (event.currentState !== event.previousState) {
      this.changes.next(event);
    }
  }
}

function normalizeAllProperties<TFormData>(
  o: TFormData,
  normalizeValue: NormalizeValueStrategy,
): Nullable<TFormData> {
  return Object.keys(o).reduce(
    (prev: Nullable<TFormData>, key) =>
      updatePropertyImmutable(prev, key as keyof TFormData, (value) =>
        normalizeValue(value),
      ),
    o,
  );
}
