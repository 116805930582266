import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoginService,
  LOGIN_SERVICE_TOKEN,
  StartGuard,
} from '@bbraun/shared/data-access-security';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { Subscription } from 'rxjs';
import { UI_LOGIN_MESSAGE_SERVICE } from '../../injection-tokens';
import { LoginRequest } from '../../interfaces/login-request';

const loginSuccessMessageId = Symbol('loginSuccessMessageId');
const loginFailureMessageId = Symbol('loginFailureMessageId');

@Component({
  selector: 'bbraun-ui-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy, OnInit {
  private readonly subscriptions = new Subscription();

  constructor(
    @Inject(LOGIN_SERVICE_TOKEN) private readonly loginService: LoginService,
    private readonly startGuard: StartGuard,
    @Inject(UI_LOGIN_MESSAGE_SERVICE)
    private readonly messageService: TranslocoMessageService,
    private readonly router: Router,
  ) {}

  loginRequested(request: LoginRequest) {
    this.loginService.login(request.username, request.password);
  }

  ngOnInit() {
    this.subscriptions.add(
      this.loginService.loginResult$.subscribe((result) => {
        if (!result.success) {
          const error = result.error;

          if (error) {
            this.messageService.message(
              (values, lang, translocoService) =>
                translocoService.translate(
                  i18n('bbraunSharedUiLogin.loginComponent.errorMessage.text'),
                  values,
                  lang,
                ),
              'error',
              {
                id: loginFailureMessageId,
                actions: [
                  { type: 'update' },
                  { type: 'remove', messageId: loginSuccessMessageId },
                ],
                values: {
                  errorCode: error.code,
                  errorMessage: error.message,
                },
              },
            );
          } else {
            this.messageService.message(
              (_values, lang, translocoService) =>
                translocoService.translate(
                  i18n(
                    'bbraunSharedUiLogin.loginComponent.failureMessage.text',
                  ),
                  undefined,
                  lang,
                ),
              'error',
              {
                id: loginFailureMessageId,
                actions: [
                  { type: 'update' },
                  { type: 'remove', messageId: loginSuccessMessageId },
                ],
              },
            );
          }
        }
      }),
    );

    this.subscriptions.add(
      this.startGuard.canActivate$.subscribe((canActivate) => {
        if (canActivate !== true) {
          const finalUrl = window.history.state.finalUrl || canActivate;
          if (finalUrl) {
            this.messageService.message(
              (_values, lang, translocoService) =>
                translocoService.translate(
                  i18n(
                    'bbraunSharedUiLogin.loginComponent.successMessage.text',
                  ),
                  undefined,
                  lang,
                ),
              'success',
              {
                id: loginSuccessMessageId,
                actions: [
                  { type: 'update' },
                  { type: 'remove', messageId: loginFailureMessageId },
                ],
              },
            );

            this.router.navigateByUrl(finalUrl);
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
