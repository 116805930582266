import { NavigationEnd, Router } from '@angular/router';
import { Constructor } from '@bbraun/shared/util-lang';
import { isInstanceOf } from '@bbraun/shared/util-rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface WithHideBackButton {
  readonly hideBackButton$: Observable<boolean>;
}

export interface WithHideBackButtonMixinBase {
  readonly router: Router;
}

export function withHideBackButtonMixin<
  TBase extends Constructor<WithHideBackButtonMixinBase>,
>(
  base: TBase,
  hideBackButtonUrls: ReadonlyArray<string>,
): TBase & Constructor<WithHideBackButtonMixinBase & WithHideBackButton> {
  return class extends base {
    readonly hideBackButton$: Observable<boolean>;

    constructor(...args: any[]) {
      super(...args);

      this.hideBackButton$ = this.router.events
        .pipe(isInstanceOf(NavigationEnd))
        .pipe(
          map((event) => hideBackButtonUrls.includes(event.urlAfterRedirects)),
        );
    }
  };
}
