<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAisReports';
    read: 'bbraunBavReportingFeatureAisReports.reportDetailsComponent'
  "
>
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="currentReportInfo$ | async as reportInfo">
      <div class="flex-box align-items-center flex-wrap gap-1 mb-1 mt-1">
        <div class="flex-box align-items-center flex-wrap gap-1">
          <bav-reporting-feature-ais-reports-show-report-info
            [reportInfo]="reportInfo"
          >
          </bav-reporting-feature-ais-reports-show-report-info>
          <mat-chip-list *ngIf="state.reportDetails.state">
            <mat-chip
              [color]="
                state.reportDetails.state === 'Completed' ? 'primary' : 'accent'
              "
              selected
              disabled
              class="status-chip"
            >
              {{ state.reportDetails.state }}</mat-chip
            >
          </mat-chip-list>
        </div>
        <div class="buttons flex-box flex-wrap justify-content-flex-end gap-1">
          <button mat-button color="secondary" (click)="fold(reportEditor)">
            {{
              accordionAction === 'collapse'
                ? translate('foldAll.text')
                : translate('unfoldAll.text')
            }}

            <mat-icon *ngIf="accordionAction === 'expand'" class="icon"
              >expand_more</mat-icon
            >
            <mat-icon *ngIf="accordionAction === 'collapse'" class="icon"
              >expand_less</mat-icon
            >
          </button>
          <ng-container [ngSwitch]="viewMode$ | async">
            <div *ngSwitchCase="'create'">
              <button
                mat-button
                color="accent"
                [disabled]="saveHandler.running | async"
                (click)="
                  createReport(
                    reportInfo,
                    updatedReportEditorFormData,
                    reportEditor
                  )
                "
              >
                {{ translate('createButton.text') }}
                <mat-icon class="icon">done</mat-icon>
              </button>
            </div>
            <div *ngSwitchCase="'edit'" class="flex-box flex-wrap gap-1">
              <div
                [matTooltip]="
                  translate(
                    'updateCalculatedValuesButton.disabled.tooltip.text'
                  )
                "
                matTooltipPosition="below"
                matTooltipClass="tooltip"
                [matTooltipDisabled]="state.doUpdateCalculatedReportValuesExist"
              >
                <button
                  mat-button
                  color="primary"
                  [disabled]="
                    (updateCalculatedReportValuesHandler.running | async) ||
                    !state.doUpdateCalculatedReportValuesExist
                  "
                  (click)="updateCalculatedReportValues(reportInfo)"
                >
                  {{ translate('updateCalculatedValuesButton.text') }}
                  <mat-icon class="icon">file_download</mat-icon>
                </button>
              </div>

              <button
                mat-button
                color="accent"
                [disabled]="saveHandler.running | async"
                (click)="updateReport(updatedReportEditorFormData, state.id)"
              >
                {{ translate('saveButton.text') }}
                <mat-icon class="icon">done</mat-icon>
              </button>
              <button mat-button color="primary" (click)="onCancelEditMode()">
                {{ translate('cancelButton.text') }}
                <mat-icon class="icon">close</mat-icon>
              </button>
            </div>
            <div *ngSwitchCase="'view'" class="flex-box flex-wrap gap-1">
              <div
                *ngIf="
                  state.isLastMonthReport &&
                  state.reportDetails.state !== 'Completed'
                "
                [matTooltip]="
                  translate(
                    (canCreateAisReportsGuard.canActivate$ | async)
                      ? 'completeButton.invalidReportError.tooltip'
                      : 'completeButton.noPermissions.tooltip'
                  )
                "
                matTooltipPosition="below"
                matTooltipClass="tooltip"
                [matTooltipDisabled]="
                  reportEditor.isValid &&
                  (canCreateAisReportsGuard.canActivate$ | async)
                "
              >
                <button
                  mat-button
                  color="primary"
                  [disabled]="
                    !reportEditor.isValid ||
                    (canCreateAisReportsGuard.canActivate$ | async) === false
                  "
                  (click)="setReportState(state.id, 'Completed')"
                >
                  {{ translate('completeButton.text') }}
                  <mat-icon class="icon">check</mat-icon>
                </button>
              </div>
              <div
                *ngIf="
                  state.isLastMonthReport &&
                  state.reportDetails.state === 'Completed'
                "
                [matTooltip]="
                  translate('resetToDraftButton.noPermissions.tooltip')
                "
                matTooltipPosition="below"
                matTooltipClass="tooltip"
                [matTooltipDisabled]="
                  canCreateAisReportsGuard.canActivate$ | async
                "
              >
                <button
                  mat-button
                  color="primary"
                  [disabled]="
                    (saveHandler.running | async) ||
                    (canCreateAisReportsGuard.canActivate$ | async) === false
                  "
                  (click)="setReportState(state.id, 'Draft')"
                >
                  {{ translate('resetToDraftButton.text') }}
                  <mat-icon class="icon">undo</mat-icon>
                </button>
              </div>
              <button
                *ngIf="
                  state.exportXmlLink &&
                  state.reportDetails.state === 'Completed'
                "
                mat-button
                color="primary"
                (click)="exportXml(state.exportXmlLink, reportInfo)"
                [disabled]="exportHandler.running | async"
              >
                {{ translate('exportButton.text') }}
                <mat-icon
                  *ngIf="
                    !(exportHandler.running | async);
                    else exportRequestLoadIndicator
                  "
                  class="icon"
                  >file_download</mat-icon
                >
                <ng-template #exportRequestLoadIndicator>
                  <dx-load-indicator
                    class="load-indicator bbraun-load-indicator white ml-1"
                  ></dx-load-indicator
                ></ng-template>
              </button>
              <div
                [matTooltip]="
                  translate(
                    (canCreateAisReportsGuard.canActivate$ | async)
                      ? 'editButton.isNotLastMonthReport.tooltip'
                      : 'editButton.noPermissions.tooltip'
                  )
                "
                matTooltipPosition="below"
                matTooltipClass="tooltip"
                [matTooltipDisabled]="
                  state.isLastMonthReport &&
                  (canCreateAisReportsGuard.canActivate$ | async)
                "
              >
                <button
                  mat-button
                  color="accent"
                  (click)="editReport()"
                  [disabled]="
                    !state.isLastMonthReport ||
                    (canCreateAisReportsGuard.canActivate$ | async) === false
                  "
                >
                  {{ translate('editButton.text') }}
                  <mat-icon class="icon">edit</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <bav-reporting-ui-report-editor-report-editor
        #reportEditor
        #withIsResetSupport
        #withIsChangedSupport
        [reportModel]="reportModel$ | async"
        [staticValues]="state.reportDetails"
        [viewMode]="viewMode$ | async"
        (expandedChanged)="onExpandedChange(reportEditor)"
        (formChanged)="onFormChanged($event)"
      >
      </bav-reporting-ui-report-editor-report-editor>
    </ng-container>
  </ng-container>
</ng-container>
