import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  TRANSLATION_LOADER_REGISTRY,
  TRANSLATION_SERVICE_CONNECTOR,
} from '@bbraun/shared/data-access-locale';
import { IS_PRODUCTION } from '@bbraun/shared/util-lang-ng';
import {
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { I18N_CONFIGURATION } from './injection-tokens';
import { createTranslocoConfig } from './services/transloco-config-factory';
import { TranslocoLoaderService } from './services/transloco-loader.service';
import { TranslocoTranslationServiceConnectorService } from './services/transloco-translation-service-connector.service';

@NgModule({
  exports: [TranslocoModule],
  imports: [TranslocoMessageFormatModule.forRoot()],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      deps: [I18N_CONFIGURATION, IS_PRODUCTION],
      useFactory: createTranslocoConfig,
    },
    { provide: TRANSLOCO_LOADER, useExisting: TranslocoLoaderService },
    {
      provide: TRANSLATION_LOADER_REGISTRY,
      useExisting: TranslocoLoaderService,
    },
    {
      provide: TRANSLATION_SERVICE_CONNECTOR,
      useExisting: TranslocoTranslationServiceConnectorService,
    },
  ],
})
export class UtilTranslocoModule {
  constructor(@Optional() @SkipSelf() parentModule: UtilTranslocoModule) {
    if (parentModule) {
      throw new Error(
        'UtilTranslocoModule is already loaded. Import it in AppModule only!',
      );
    }
  }
}
