import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  handleMissingConfiguration,
  loadConfig,
  showStaticContent,
} from '@bbraun/shared/util-bootstrap';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ENVIRONMENT } from './app/injection-token';
import { Environment } from './environments/environment.type';

if (environment.production) {
  enableProdMode();
}

loadConfig<Environment>(environment.production).then((loadedEnvironment) => {
  const ROOT_ELEMENT = 'bav-reporting-desktop-root';
  if (environment.production && !loadedEnvironment) {
    handleMissingConfiguration(ROOT_ELEMENT);
    return;
  }

  platformBrowserDynamic([
    {
      provide: ENVIRONMENT,
      useValue: loadedEnvironment ? loadedEnvironment : environment,
    },
  ])
    .bootstrapModule(AppModule)
    .catch((err) => {
      console.error('error bootstrapping angular application', err);
      showStaticContent(ROOT_ELEMENT);
    });
});
