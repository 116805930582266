import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { createObservables, NEXT, VALUES } from '@bbraun/shared/util-rxjs';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import { TranslocoService } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UI_LANGUAGE_TRANSLOCO_SERVICE } from '../../injection-tokens';
import { ShareUiLanguageTranslationService } from '../../services/shared-ui-language-translation.service';

const languageTranslationKeys: { [key: string]: string | undefined } = {
  de: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.de'),
  en: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.en'),
  es: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.es'),
  et: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.et'),
  fr: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.fr'),
  it: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.it'),
  lt: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.lt'),
  nl: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.nl'),
  pt: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.pt'),
  ru: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.ru'),
  sv: i18n('bbraunSharedUiLanguage.languageDialogComponent.language.sv'),
  'zh-CN': i18n(
    'bbraunSharedUiLanguage.languageDialogComponent.language.zh-CN',
  ),
};

@Component({
  selector: 'bbraun-ui-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TranslocoService,
      useExisting: UI_LANGUAGE_TRANSLOCO_SERVICE,
    },
  ],
})
export class LanguageDialogComponent {
  @Output()
  languageSelected = new EventEmitter<string>();

  @Output()
  aborted = new EventEmitter<void>();

  readonly viewModel$: Observable<Array<{ code: string; name: string }>>;
  readonly currentLanguage$: Observable<string | undefined>;

  private readonly observables = createObservables<{
    currentLanguage: string;
    supportedLanguages: string[];
  }>({
    currentLanguage: this.translocoService.getDefaultLang(),
    supportedLanguages: [],
  });

  constructor(
    private readonly scopeTranslationService: ShareUiLanguageTranslationService,
    @Inject(UI_LANGUAGE_TRANSLOCO_SERVICE)
    private readonly translocoService: TranslocoService,
  ) {
    this.currentLanguage$ = this.observables.currentLanguage;
    this.viewModel$ = getLanguageSelectBoxValues(
      this.observables.currentLanguage,
      this.observables.supportedLanguages,
      this.scopeTranslationService,
    );
  }
  @Input()
  set supportedLanguages(languages: string[] | undefined | null) {
    this.observables[NEXT]('supportedLanguages', languages || []);
  }

  @Input()
  set currentLanguage(lang: string | undefined | null) {
    this.observables[NEXT](
      'currentLanguage',
      lang || this.translocoService.getDefaultLang(),
    );
    this.translocoService.setActiveLang(
      lang || this.translocoService.getDefaultLang(),
    );
  }

  abort() {
    this.aborted.next();
  }

  apply() {
    this.languageSelected.next(this.observables[VALUES].currentLanguage);
  }

  selectLanguage(event: { value?: string }) {
    this.currentLanguage = event.value;
  }
}

function getTranslation(
  langCode: string,
  translate: TranslateFunction | undefined,
): { code: string; name: string | undefined } {
  const translationKey = languageTranslationKeys[langCode];
  return {
    code: langCode,
    name: translate && translationKey ? translate(translationKey) : undefined,
  };
}

function getLanguageOriginalTranslation(
  supportedLanguages: Array<string>,
  scopeTranslationService: ShareUiLanguageTranslationService,
): Array<Observable<{ code: string; name: string | undefined }>> {
  return supportedLanguages.map((langCode) =>
    scopeTranslationService
      .forLang(langCode)
      .pipe(map(({ translate }) => getTranslation(langCode, translate))),
  );
}

function getLanguageOriginal(
  supportedLanguages$: Observable<string[]>,
  scopeTranslationService: ShareUiLanguageTranslationService,
): Observable<{ [code: string]: string | undefined }> {
  const languageOriginalNamesObservables$: Observable<
    Array<Observable<{ code: string; name: string | undefined }>>
  > = supportedLanguages$.pipe(
    map((supportedLanguages) =>
      getLanguageOriginalTranslation(
        supportedLanguages,
        scopeTranslationService,
      ),
    ),
  );

  const combinedOriginalNames$ = languageOriginalNamesObservables$.pipe(
    switchMap((languageOriginalNamesObservables) =>
      combineLatest(languageOriginalNamesObservables).pipe(
        map(
          (
            languageOriginalNames: Array<{
              code: string;
              name: string | undefined;
            }>,
          ) => transformArrayToObject(languageOriginalNames),
        ),
      ),
    ),
  );
  return combinedOriginalNames$;
}

function transformArrayToObject(
  languageOriginalNames: Array<{
    code: string;
    name: string | undefined;
  }>,
): { [code: string]: string | undefined } {
  const result: { [code: string]: string | undefined } = {};
  languageOriginalNames.forEach((languageOriginalName) => {
    result[languageOriginalName.code] = languageOriginalName.name;
  });
  return result;
}

function getLanguageCurrentNames(
  currentLangCode$: Observable<string | undefined>,
  supportedLanguages$: Observable<string[]>,
  scopeTranslationService: ShareUiLanguageTranslationService,
): Observable<Array<{ code: string; name: string | undefined }>> {
  const currentLangTranslate$ = currentLangCode$.pipe(
    switchMap((currentLangCode) =>
      currentLangCode
        ? scopeTranslationService
            .forLang(currentLangCode)
            .pipe(map(({ translate }) => translate))
        : of(undefined),
    ),
  );

  const result = combineLatest([
    supportedLanguages$,
    currentLangTranslate$,
  ]).pipe(
    map(([supportedLanguages, currentLangTranslate]) =>
      supportedLanguages.map((langCode) =>
        getTranslation(langCode, currentLangTranslate),
      ),
    ),
  );

  return result;
}

function getLanguageSelectBoxValues(
  currentLangCode: Observable<string | undefined>,
  supportedLanguages: Observable<string[]>,
  scopeTranslationService: ShareUiLanguageTranslationService,
): Observable<Array<{ code: string; name: string }>> {
  const selectBoxValues$ = combineLatest([
    getLanguageCurrentNames(
      currentLangCode,
      supportedLanguages,
      scopeTranslationService,
    ),
    getLanguageOriginal(supportedLanguages, scopeTranslationService),
  ]).pipe(
    map(([languageCurrentNames, languageOriginalNames]) =>
      getShowLanguageNames(languageCurrentNames, languageOriginalNames),
    ),
  );
  return selectBoxValues$;
}

function getShowLanguageNames(
  languageCurrentNames: Array<{ code: string; name: string | undefined }>,
  languageOriginalNames: { [code: string]: string | undefined },
): Array<{ code: string; name: string }> {
  return languageCurrentNames
    .map((languageCurrentName) => {
      const currentCode = languageCurrentName.code;
      const currentName = languageCurrentName.name;
      const originalName = languageOriginalNames[currentCode];
      const name = [
        originalName,
        currentName && originalName !== currentName
          ? `(${currentName})`
          : undefined,
        originalName || currentName ? '-' : undefined,
        currentCode,
      ]
        .filter((namePart) => !!namePart)
        .join(' ');
      return {
        code: currentCode,
        name,
      };
    })
    .sort((a, b) => a.code.localeCompare(b.code));
}
