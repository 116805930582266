import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '@bbraun/shared/util-message-ng';
import {
  BlockingOperationHandler,
  CallableOperationFn,
  blockingOperationHandler,
} from '@bbraun/shared/util-rxjs';
import { translate } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { Observable, Subject, Subscription, map } from 'rxjs';
import {
  DataTransferResponse,
  DataTransferService,
} from '@bbraun/bav-reporting/data-access-administration';
import {
  connectToLoadBarService,
  LoadBarService,
} from '@bbraun/shared/util-navigation';
import { getErrorMessageCode } from '@bbraun/shared/util-jsonapi';

const DATA_TRANSFER_STARTING = Symbol('DATA_TRANSFER_STARTING');
const DATA_TRANSFER_MESSAGE = Symbol('DATA_TRANSFER_MESSAGE');

@Component({
  selector: 'bav-reporting-ui-common-start-data-transfer-button',
  templateUrl: './start-data-transfer-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartDataTransferButtonComponent implements OnInit, OnDestroy {
  @Input()
  canActivate: boolean | null | undefined;

  readonly isDataTransferRunning$: Observable<boolean>;

  private readonly subscriptions = new Subscription();

  private readonly startDataTransferButtonClicked = new Subject<void>();
  private readonly dataTransferHandler: BlockingOperationHandler<DataTransferResponse>;

  constructor(
    private messageService: MessageService,
    private dataTransferService: DataTransferService,
    private loadbarService: LoadBarService,
  ) {
    const startDataTransferTask$ = this.startDataTransferButtonClicked.pipe(
      map(() => () => this.dataTransferService.start()),
    );

    this.dataTransferHandler = createBlockingOperationHandler(
      this.messageService,
      startDataTransferTask$,
    );

    this.isDataTransferRunning$ = this.dataTransferHandler.running;
  }

  ngOnInit() {
    this.subscriptions.add(
      this.dataTransferHandler.running
        .pipe(
          map((isRunning) => ({
            id: DATA_TRANSFER_STARTING,
            value: isRunning,
          })),
        )
        .pipe(connectToLoadBarService(this.loadbarService))
        .subscribe(),
    );

    this.subscriptions.add(this.dataTransferHandler.results.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  startDataTransfer(): void {
    this.messageService.message(
      translate(
        i18n(
          'bbraunBavReportingUiCommon.startDataTransferButtonComponent.startDataTransfer.info.message',
        ),
      ),
      'info',
      {
        id: DATA_TRANSFER_MESSAGE,
        timeOut: false,
        enableHtml: true,
        actions: [{ type: 'update' }],
      },
    );
    this.startDataTransferButtonClicked.next();
  }
}

function createBlockingOperationHandler(
  messageService: MessageService,
  source: Observable<CallableOperationFn<DataTransferResponse>>,
) {
  return blockingOperationHandler<DataTransferResponse>(
    {
      next: (response) => {
        if (response.status !== 'success') {
          if (response.status === 'error') {
            messageService.message(
              translate(
                i18n(
                  'bbraunBavReportingUiCommon.startDataTransferButtonComponent.startDataTransfer.general.error.message',
                ),
              ),
              'error',
              {
                id: DATA_TRANSFER_MESSAGE,
                timeOut: false,
                error: response.message,
                actions: [{ type: 'update' }],
              },
            );
          } else {
            throw new Error('Unexpected response');
          }
        }
      },
      error: (error) => {
        const errorCode = getErrorMessageCode(error);
        if (
          error instanceof HttpErrorResponse &&
          error.status === 400 &&
          errorCode ===
            'CONSTRAINT_VIOLATION_NO_PARALLEL_STARTLOAD_EXECUTION_ALLOWED'
        ) {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingUiCommon.startDataTransferButtonComponent.startDataTransfer.alreadyRunning.info.message',
              ),
            ),
            'info',
            {
              id: DATA_TRANSFER_MESSAGE,
              timeOut: false,
              enableHtml: true,
              error,
              actions: [{ type: 'update' }],
            },
          );
        } else {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingUiCommon.startDataTransferButtonComponent.startDataTransfer.general.error.message',
              ),
            ),
            'error',
            {
              id: DATA_TRANSFER_MESSAGE,
              timeOut: false,
              error,
              actions: [{ type: 'update' }],
            },
          );
        }
      },
    },
    { strategy: 'single', autoConnectResult: false, source },
  );
}
