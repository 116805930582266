import {
  ChangeDetectorRef,
  Inject,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ANGULAR_LOCALE } from '@bbraun/shared/data-access-locale';
import { Money } from '@bbraun/shared/util-lang';
import { Observable } from 'rxjs';
import { LOCALIZED_NUMBER_FORMAT_ERROR_HANDLER } from '../injection-tokens';
import {
  convertLocalizedNumberToString,
  LocalizedNumberOptions,
  LocalizedNumberFormatErrorHandler,
} from '../functions/convert-localized-number-to-string';
import { LocalizedPipeBase } from './localized-pipe-base';

export type LocalizedMoneyPipeOptions = Omit<
  LocalizedNumberOptions,
  'style'
> & {
  defaultCurrency?: string;
  hideDefaultCurrency?: boolean;
  locale?: string;
  onError?: LocalizedNumberFormatErrorHandler;
};

@Pipe({ name: 'bbraunUiLanguageLocalizedMoney', pure: false })
export class LocalizedMoneyPipe
  extends LocalizedPipeBase<[LocalizedMoneyPipeOptions | undefined]>
  implements PipeTransform
{
  constructor(
    @Inject(ChangeDetectorRef) cdr: Pick<ChangeDetectorRef, 'markForCheck'>,
    @Inject(ANGULAR_LOCALE) angularLocale: Observable<string>,
    @Inject(LOCALIZED_NUMBER_FORMAT_ERROR_HANDLER)
    @Optional()
    numberFormatErrorHandler?:
      | LocalizedNumberFormatErrorHandler
      | null
      | undefined,
  ) {
    super(
      cdr,
      angularLocale,
      (
        currentLocale,
        money: Money | undefined | null,
        options?: LocalizedMoneyPipeOptions,
      ): string | null | undefined => {
        const actualLocale = options?.locale || currentLocale;

        if (actualLocale) {
          const onError =
            options?.onError || numberFormatErrorHandler || (() => null);
          if (!money || money?.isValid()) {
            const amount = money?.value || 0;
            const currency = money?.currency || options?.defaultCurrency;
            const showCurrency =
              currency &&
              (!options?.hideDefaultCurrency ||
                currency !== options?.defaultCurrency)
                ? currency
                : undefined;

            if (!showCurrency || (showCurrency && currency)) {
              return convertLocalizedNumberToString(
                actualLocale,
                amount,
                {
                  ...options,
                  ...(showCurrency && currency
                    ? ({
                        style: 'currency',
                        currency,
                      } as const)
                    : ({ style: 'decimal' } as const)),
                },
                onError,
              );
            } else {
              return onError({
                value: undefined,
                locale: actualLocale,
                options: { ...options, style: 'currency' },
                error: 'a currency code is required',
              });
            }
          } else {
            return onError({
              value: undefined,
              locale: actualLocale,
              options: { ...options, style: 'currency' },
              error: money.error,
            });
          }
        } else {
          return null;
        }
      },
    );
  }

  transform(
    money: Money | null | undefined,
    options?: LocalizedMoneyPipeOptions,
  ): string | null | undefined {
    return super.transform(money, options);
  }
}
