// THIS FILE IS GENERATED - DO NOT EDIT!

import {
  ExtractSparseFieldTypes,
  TypedQueryParameters,
} from '@bbraun/shared/util-jsonapi';
// eslint-disable-next-line no-unused-expressions
// @ts-ignore
import { createPropertyPathBuilder } from '@bbraun/shared/util-lang';

import { BavReportingJsonApiTypes } from '../bav-reporting-json-api-types.json-api.generated';

export const calculatedReportDetailsResponseModelFieldsets = {
  'ais-calculated-report': [
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ageMean').path[0] as 'ageMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ageSd').path[0] as 'ageSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminBe25AndL35').path[0] as 'albuminBe25AndL35',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminBe35').path[0] as 'albuminBe35',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminGPerLBcGreenMean').path[0] as 'albuminGPerLBcGreenMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminGPerLBcGreenSd').path[0] as 'albuminGPerLBcGreenSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminGPerLBcPurpleMean').path[0] as 'albuminGPerLBcPurpleMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminGPerLBcPurpleSd').path[0] as 'albuminGPerLBcPurpleSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminL25').path[0] as 'albuminL25',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('albuminNotDone').path[0] as 'albuminNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodFlowMean').path[0] as 'bloodFlowMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodFlowSd').path[0] as 'bloodFlowSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodPressureDiastolicBe90')
      .path[0] as 'bloodPressureDiastolicBe90',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodPressureDiastolicL90').path[0] as 'bloodPressureDiastolicL90',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodPressureDiastolicNotDone')
      .path[0] as 'bloodPressureDiastolicNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodPressureSystolicBe140')
      .path[0] as 'bloodPressureSystolicBe140',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodPressureSystolicL140').path[0] as 'bloodPressureSystolicL140',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bloodPressureSystolicNotDone')
      .path[0] as 'bloodPressureSystolicNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiBe20AndL25').path[0] as 'bmiBe20AndL25',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiBe25AndL30').path[0] as 'bmiBe25AndL30',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiBe30').path[0] as 'bmiBe30',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiL20').path[0] as 'bmiL20',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiMean').path[0] as 'bmiMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiNotDone').path[0] as 'bmiNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('bmiSd').path[0] as 'bmiSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumBe8p4AndLe10p5').path[0] as 'calciumBe8p4AndLe10p5',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumB10p5').path[0] as 'calciumB10p5',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumL8p4').path[0] as 'calciumL8p4',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumMgPerDlMean').path[0] as 'calciumMgPerDlMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumMgPerDlSd').path[0] as 'calciumMgPerDlSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumMmolPerLMean').path[0] as 'calciumMmolPerLMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumMmolPerLSd').path[0] as 'calciumMmolPerLSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('calciumNotDone').path[0] as 'calciumNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('charlsonIndexBe8Patient').path[0] as 'charlsonIndexBe8Patient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('charlsonIndexBe8Sum').path[0] as 'charlsonIndexBe8Sum',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('charlsonIndexL8Patient').path[0] as 'charlsonIndexL8Patient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('charlsonIndexL8Sum').path[0] as 'charlsonIndexL8Sum',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesCerebrovascularDisease')
      .path[0] as 'comorbiditiesCerebrovascularDisease',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesCongestiveHeartFailure')
      .path[0] as 'comorbiditiesCongestiveHeartFailure',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesDiabetesChronicComplication')
      .path[0] as 'comorbiditiesDiabetesChronicComplication',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesMalignancy').path[0] as 'comorbiditiesMalignancy',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesMetastaticSolidTumor')
      .path[0] as 'comorbiditiesMetastaticSolidTumor',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesModerateSevereLiverDisease')
      .path[0] as 'comorbiditiesModerateSevereLiverDisease',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesMyocardialInfarction')
      .path[0] as 'comorbiditiesMyocardialInfarction',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('comorbiditiesPeripheralVascularDisease')
      .path[0] as 'comorbiditiesPeripheralVascularDisease',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('convectiveVolumeBe23').path[0] as 'convectiveVolumeBe23',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('convectiveVolumeL23').path[0] as 'convectiveVolumeL23',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('convectiveVolumeMean').path[0] as 'convectiveVolumeMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('convectiveVolumeNotDone').path[0] as 'convectiveVolumeNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('convectiveVolumeSd').path[0] as 'convectiveVolumeSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('crpElevated').path[0] as 'crpElevated',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('crpNotDone').path[0] as 'crpNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('crpWithinLimits').path[0] as 'crpWithinLimits',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dialysisFreqBe3').path[0] as 'dialysisFreqBe3',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dialysisFreqL3').path[0] as 'dialysisFreqL3',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dialysisFreqMean').path[0] as 'dialysisFreqMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dialysisFreqNotDone').path[0] as 'dialysisFreqNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dialysisFreqSd').path[0] as 'dialysisFreqSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dryBodyWeightMean').path[0] as 'dryBodyWeightMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('dryBodyWeightSd').path[0] as 'dryBodyWeightSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ektvBe1AndL1p2').path[0] as 'ektvBe1AndL1p2',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ektvBe1p2').path[0] as 'ektvBe1p2',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ektvL1').path[0] as 'ektvL1',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ektvMean').path[0] as 'ektvMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ektvNotDone').path[0] as 'ektvNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ektvSd').path[0] as 'ektvSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('epoDose').path[0] as 'epoDose',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ferritinBe200AndLe500').path[0] as 'ferritinBe200AndLe500',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ferritinB500').path[0] as 'ferritinB500',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ferritinL200').path[0] as 'ferritinL200',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ferritinMean').path[0] as 'ferritinMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ferritinNotDone').path[0] as 'ferritinNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ferritinSd').path[0] as 'ferritinSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('genderFemale').path[0] as 'genderFemale',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('genderMale').path[0] as 'genderMale',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinBe10AndLe12').path[0] as 'hemoglobinBe10AndLe12',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinB12').path[0] as 'hemoglobinB12',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinGPerDlMean').path[0] as 'hemoglobinGPerDlMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinGPerDlSd').path[0] as 'hemoglobinGPerDlSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinL10').path[0] as 'hemoglobinL10',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinMmolPerLMean').path[0] as 'hemoglobinMmolPerLMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinMmolPerLSd').path[0] as 'hemoglobinMmolPerLSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hemoglobinNotDone').path[0] as 'hemoglobinNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('handHygieneScore').path[0] as 'handHygieneScore',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionAvfBacteriaMrsaInfection')
      .path[0] as 'hdInfectionAvfBacteriaMrsaInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionAvfBacteriaOtherInfection')
      .path[0] as 'hdInfectionAvfBacteriaOtherInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionAvfExitSiteMrsaInfection')
      .path[0] as 'hdInfectionAvfExitSiteMrsaInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionAvfExitSiteOtherInfection')
      .path[0] as 'hdInfectionAvfExitSiteOtherInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionAvfPatientNum').path[0] as 'hdInfectionAvfPatientNum',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionCvcBacteriaMrsaInfection')
      .path[0] as 'hdInfectionCvcBacteriaMrsaInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionCvcBacteriaOtherInfection')
      .path[0] as 'hdInfectionCvcBacteriaOtherInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionCvcExitSiteMrsaInfection')
      .path[0] as 'hdInfectionCvcExitSiteMrsaInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionCvcExitSiteOtherInfection')
      .path[0] as 'hdInfectionCvcExitSiteOtherInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionCvcPatientNum').path[0] as 'hdInfectionCvcPatientNum',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionGraftBacteriaMrsaInfection')
      .path[0] as 'hdInfectionGraftBacteriaMrsaInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionGraftBacteriaOtherInfection')
      .path[0] as 'hdInfectionGraftBacteriaOtherInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionGraftExitSiteMrsaInfection')
      .path[0] as 'hdInfectionGraftExitSiteMrsaInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionGraftExitSiteOtherInfection')
      .path[0] as 'hdInfectionGraftExitSiteOtherInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionGraftPatientNum')
      .path[0] as 'hdInfectionGraftPatientNum',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionHbvPlus').path[0] as 'hdInfectionHbvPlus',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionHcvPlus').path[0] as 'hdInfectionHcvPlus',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdInfectionHivPlus').path[0] as 'hdInfectionHivPlus',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hdTransplantWaitingPatient')
      .path[0] as 'hdTransplantWaitingPatient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heightMean').path[0] as 'heightMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heightSd').path[0] as 'heightSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinBemi').path[0] as 'heparinBemi',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinBemiPatients').path[0] as 'heparinBemiPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinDalt').path[0] as 'heparinDalt',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinDaltPatients').path[0] as 'heparinDaltPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxA').path[0] as 'heparinEnoxA',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxAPatients').path[0] as 'heparinEnoxAPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxC').path[0] as 'heparinEnoxC',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxCPatients').path[0] as 'heparinEnoxCPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxE').path[0] as 'heparinEnoxE',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxEPatients').path[0] as 'heparinEnoxEPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxG').path[0] as 'heparinEnoxG',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxGPatients').path[0] as 'heparinEnoxGPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxL').path[0] as 'heparinEnoxL',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinEnoxLPatients').path[0] as 'heparinEnoxLPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinHeparinlessTreatment')
      .path[0] as 'heparinHeparinlessTreatment',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinNadr').path[0] as 'heparinNadr',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinNadrPatients').path[0] as 'heparinNadrPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinStandard').path[0] as 'heparinStandard',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinStandardPatients').path[0] as 'heparinStandardPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinTinz').path[0] as 'heparinTinz',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('heparinTinzPatients').path[0] as 'heparinTinzPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospCardiologyDays').path[0] as 'hospCardiologyDays',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospCardiologyHosp').path[0] as 'hospCardiologyHosp',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospCardiologyMissedSessions')
      .path[0] as 'hospCardiologyMissedSessions',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospCardiologyPatient').path[0] as 'hospCardiologyPatient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospInfectionsDays').path[0] as 'hospInfectionsDays',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospInfectionsHosp').path[0] as 'hospInfectionsHosp',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospInfectionsMissedSessions')
      .path[0] as 'hospInfectionsMissedSessions',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospInfectionsPatient').path[0] as 'hospInfectionsPatient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospOtherDays').path[0] as 'hospOtherDays',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospOtherHosp').path[0] as 'hospOtherHosp',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospOtherMissedSessions').path[0] as 'hospOtherMissedSessions',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospOtherPatient').path[0] as 'hospOtherPatient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospVascularAccessDays').path[0] as 'hospVascularAccessDays',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospVascularAccessHosp').path[0] as 'hospVascularAccessHosp',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospVascularAccessMissedSessions')
      .path[0] as 'hospVascularAccessMissedSessions',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('hospVascularAccessPatient').path[0] as 'hospVascularAccessPatient',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonBe100AndLe800')
      .path[0] as 'intactParathormonBe100AndLe800',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonB800').path[0] as 'intactParathormonB800',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonL100').path[0] as 'intactParathormonL100',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonNotDone').path[0] as 'intactParathormonNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonPgPerMlMean')
      .path[0] as 'intactParathormonPgPerMlMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonPgPerMlSd')
      .path[0] as 'intactParathormonPgPerMlSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonPmolPerLMean')
      .path[0] as 'intactParathormonPmolPerLMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('intactParathormonPmolPerLSd')
      .path[0] as 'intactParathormonPmolPerLSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('medicationEsa').path[0] as 'medicationEsa',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('medicationIviron').path[0] as 'medicationIviron',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionEiEnteral').path[0] as 'nutritionEiEnteral',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionEiIdpn').path[0] as 'nutritionEiIdpn',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionEiNotTreated').path[0] as 'nutritionEiNotTreated',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionSgaA').path[0] as 'nutritionSgaA',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionSgaB').path[0] as 'nutritionSgaB',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionSgaC').path[0] as 'nutritionSgaC',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('nutritionSgaNotDone').path[0] as 'nutritionSgaNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientAllLastMonth').path[0] as 'ownPatientAllLastMonth',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientHemoDialysis3Month')
      .path[0] as 'ownPatientHemoDialysis3Month',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientNewAdmission').path[0] as 'ownPatientNewAdmission',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientNewBackTransp').path[0] as 'ownPatientNewBackTransp',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientNewBBraunAvitumCenter')
      .path[0] as 'ownPatientNewBBraunAvitumCenter',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientNewOtherCenter').path[0] as 'ownPatientNewOtherCenter',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientNewPdToHd').path[0] as 'ownPatientNewPdToHd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedBBraunAvitumCenter')
      .path[0] as 'ownPatientResignedBBraunAvitumCenter',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedExitusChronicCardiovascular')
      .path[0] as 'ownPatientResignedExitusChronicCardiovascular',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedExitusChronicCerebrovascular')
      .path[0] as 'ownPatientResignedExitusChronicCerebrovascular',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedExitusChronicInfection')
      .path[0] as 'ownPatientResignedExitusChronicInfection',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedExitusChronicMalignancy')
      .path[0] as 'ownPatientResignedExitusChronicMalignancy',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedExitusChronicOther')
      .path[0] as 'ownPatientResignedExitusChronicOther',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedExitusNonchronic')
      .path[0] as 'ownPatientResignedExitusNonchronic',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedHdToPd').path[0] as 'ownPatientResignedHdToPd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedOtherCenter')
      .path[0] as 'ownPatientResignedOtherCenter',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedQuitted').path[0] as 'ownPatientResignedQuitted',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('ownPatientResignedTransp').path[0] as 'ownPatientResignedTransp',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateBe3p5AndL5p5').path[0] as 'phosphateBe3p5AndL5p5',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateBe5p5').path[0] as 'phosphateBe5p5',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateL3p5').path[0] as 'phosphateL3p5',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateMgPerDlMean').path[0] as 'phosphateMgPerDlMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateMgPerDlSd').path[0] as 'phosphateMgPerDlSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateMmolPerLMean').path[0] as 'phosphateMmolPerLMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateMmolPerLSd').path[0] as 'phosphateMmolPerLSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('phosphateNotDone').path[0] as 'phosphateNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseaseDiabetesNephropathy')
      .path[0] as 'primaryRenalDiseaseDiabetesNephropathy',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseaseGlomerulonephritis')
      .path[0] as 'primaryRenalDiseaseGlomerulonephritis',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseaseHypertension')
      .path[0] as 'primaryRenalDiseaseHypertension',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseaseOther').path[0] as 'primaryRenalDiseaseOther',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseasePolycystic')
      .path[0] as 'primaryRenalDiseasePolycystic',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseaseTubulointerstitial')
      .path[0] as 'primaryRenalDiseaseTubulointerstitial',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('primaryRenalDiseaseVascular')
      .path[0] as 'primaryRenalDiseaseVascular',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('seroconversionHbvChronic').path[0] as 'seroconversionHbvChronic',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('seroconversionHbvNew').path[0] as 'seroconversionHbvNew',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('seroconversionHcvChronic').path[0] as 'seroconversionHcvChronic',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('seroconversionHcvNew').path[0] as 'seroconversionHcvNew',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('seroconversionHivChronic').path[0] as 'seroconversionHivChronic',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('seroconversionHivNew').path[0] as 'seroconversionHivNew',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineBe8').path[0] as 'serumCreatinineBe8',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineL8').path[0] as 'serumCreatinineL8',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineMgPerDlMean')
      .path[0] as 'serumCreatinineMgPerDlMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineMgPerDlSd').path[0] as 'serumCreatinineMgPerDlSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineNotDone').path[0] as 'serumCreatinineNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineUmolPerLMean')
      .path[0] as 'serumCreatinineUmolPerLMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('serumCreatinineUmolPerLSd').path[0] as 'serumCreatinineUmolPerLSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentBe10p5AndL12').path[0] as 'timeTreatmentBe10p5AndL12',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentBe9AndL10p5').path[0] as 'timeTreatmentBe9AndL10p5',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentBe12').path[0] as 'timeTreatmentBe12',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentL9').path[0] as 'timeTreatmentL9',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentMean').path[0] as 'timeTreatmentMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentNotDone').path[0] as 'timeTreatmentNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeTreatmentSd').path[0] as 'timeTreatmentSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('tempPatientHoliday').path[0] as 'tempPatientHoliday',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('tempPatientTemporary').path[0] as 'tempPatientTemporary',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeOnDialysisMean').path[0] as 'timeOnDialysisMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('timeOnDialysisSd').path[0] as 'timeOnDialysisSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('treatmentModalityHd').path[0] as 'treatmentModalityHd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('treatmentModalityHdf').path[0] as 'treatmentModalityHdf',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('treatmentModalityHdfPost').path[0] as 'treatmentModalityHdfPost',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('treatmentModalityHdfPre').path[0] as 'treatmentModalityHdfPre',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('treatmentsOwnPatients').path[0] as 'treatmentsOwnPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('treatmentsTempPatients').path[0] as 'treatmentsTempPatients',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('transferrinSaturationBe20AndLe30')
      .path[0] as 'transferrinSaturationBe20AndLe30',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('transferrinSaturationB30').path[0] as 'transferrinSaturationB30',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('transferrinSaturationL20').path[0] as 'transferrinSaturationL20',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('transferrinSaturationMean').path[0] as 'transferrinSaturationMean',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('transferrinSaturationNotDone')
      .path[0] as 'transferrinSaturationNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('transferrinSaturationSd').path[0] as 'transferrinSaturationSd',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('waterQualityBacteriologyFirstResult')
      .path[0] as 'waterQualityBacteriologyFirstResult',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('waterQualityBacteriologyNotDone')
      .path[0] as 'waterQualityBacteriologyNotDone',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('waterQualityEndotoxinFirstResult')
      .path[0] as 'waterQualityEndotoxinFirstResult',
    createPropertyPathBuilder<
      BavReportingJsonApiTypes['ais-calculated-report']
    >().add('waterQualityEndotoxinNotDone')
      .path[0] as 'waterQualityEndotoxinNotDone',
  ],
} as const;

type CalculatedReportDetailsResponseModelFieldsets = ExtractSparseFieldTypes<
  typeof calculatedReportDetailsResponseModelFieldsets
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CalculatedReportDetailsResponseModel = {
  readonly id: string;
  readonly ageMean: null | number;
  readonly ageSd: null | number;
  readonly albuminBe25AndL35: null | number;
  readonly albuminBe35: null | number;
  readonly albuminGPerLBcGreenMean: null | number;
  readonly albuminGPerLBcGreenSd: null | number;
  readonly albuminGPerLBcPurpleMean: null | number;
  readonly albuminGPerLBcPurpleSd: null | number;
  readonly albuminL25: null | number;
  readonly albuminNotDone: null | number;
  readonly bloodFlowMean: null | number;
  readonly bloodFlowSd: null | number;
  readonly bloodPressureDiastolicBe90: null | number;
  readonly bloodPressureDiastolicL90: null | number;
  readonly bloodPressureDiastolicNotDone: null | number;
  readonly bloodPressureSystolicBe140: null | number;
  readonly bloodPressureSystolicL140: null | number;
  readonly bloodPressureSystolicNotDone: null | number;
  readonly bmiBe20AndL25: null | number;
  readonly bmiBe25AndL30: null | number;
  readonly bmiBe30: null | number;
  readonly bmiL20: null | number;
  readonly bmiMean: null | number;
  readonly bmiNotDone: null | number;
  readonly bmiSd: null | number;
  readonly calciumB10p5: null | number;
  readonly calciumBe8p4AndLe10p5: null | number;
  readonly calciumL8p4: null | number;
  readonly calciumMgPerDlMean: null | number;
  readonly calciumMgPerDlSd: null | number;
  readonly calciumMmolPerLMean: null | number;
  readonly calciumMmolPerLSd: null | number;
  readonly calciumNotDone: null | number;
  readonly charlsonIndexBe8Patient: null | number;
  readonly charlsonIndexBe8Sum: null | number;
  readonly charlsonIndexL8Patient: null | number;
  readonly charlsonIndexL8Sum: null | number;
  readonly comorbiditiesCerebrovascularDisease: null | number;
  readonly comorbiditiesCongestiveHeartFailure: null | number;
  readonly comorbiditiesDiabetesChronicComplication: null | number;
  readonly comorbiditiesMalignancy: null | number;
  readonly comorbiditiesMetastaticSolidTumor: null | number;
  readonly comorbiditiesModerateSevereLiverDisease: null | number;
  readonly comorbiditiesMyocardialInfarction: null | number;
  readonly comorbiditiesPeripheralVascularDisease: null | number;
  readonly convectiveVolumeBe23: null | number;
  readonly convectiveVolumeL23: null | number;
  readonly convectiveVolumeMean: null | number;
  readonly convectiveVolumeNotDone: null | number;
  readonly convectiveVolumeSd: null | number;
  readonly crpElevated: null | number;
  readonly crpNotDone: null | number;
  readonly crpWithinLimits: null | number;
  readonly dialysisFreqBe3: null | number;
  readonly dialysisFreqL3: null | number;
  readonly dialysisFreqMean: null | number;
  readonly dialysisFreqNotDone: null | number;
  readonly dialysisFreqSd: null | number;
  readonly dryBodyWeightMean: null | number;
  readonly dryBodyWeightSd: null | number;
  readonly ektvBe1AndL1p2: null | number;
  readonly ektvBe1p2: null | number;
  readonly ektvL1: null | number;
  readonly ektvMean: null | number;
  readonly ektvNotDone: null | number;
  readonly ektvSd: null | number;
  readonly epoDose: null | number;
  readonly ferritinB500: null | number;
  readonly ferritinBe200AndLe500: null | number;
  readonly ferritinL200: null | number;
  readonly ferritinMean: null | number;
  readonly ferritinNotDone: null | number;
  readonly ferritinSd: null | number;
  readonly genderFemale: number;
  readonly genderMale: number;
  readonly handHygieneScore: null | number;
  readonly hdInfectionAvfBacteriaMrsaInfection: null | number;
  readonly hdInfectionAvfBacteriaOtherInfection: null | number;
  readonly hdInfectionAvfExitSiteMrsaInfection: null | number;
  readonly hdInfectionAvfExitSiteOtherInfection: null | number;
  readonly hdInfectionAvfPatientNum: null | number;
  readonly hdInfectionCvcBacteriaMrsaInfection: null | number;
  readonly hdInfectionCvcBacteriaOtherInfection: null | number;
  readonly hdInfectionCvcExitSiteMrsaInfection: null | number;
  readonly hdInfectionCvcExitSiteOtherInfection: null | number;
  readonly hdInfectionCvcPatientNum: null | number;
  readonly hdInfectionGraftBacteriaMrsaInfection: null | number;
  readonly hdInfectionGraftBacteriaOtherInfection: null | number;
  readonly hdInfectionGraftExitSiteMrsaInfection: null | number;
  readonly hdInfectionGraftExitSiteOtherInfection: null | number;
  readonly hdInfectionGraftPatientNum: null | number;
  readonly hdInfectionHbvPlus: null | number;
  readonly hdInfectionHcvPlus: null | number;
  readonly hdInfectionHivPlus: null | number;
  readonly hdTransplantWaitingPatient: null | number;
  readonly heightMean: null | number;
  readonly heightSd: null | number;
  readonly hemoglobinB12: null | number;
  readonly hemoglobinBe10AndLe12: null | number;
  readonly hemoglobinGPerDlMean: null | number;
  readonly hemoglobinGPerDlSd: null | number;
  readonly hemoglobinL10: null | number;
  readonly hemoglobinMmolPerLMean: null | number;
  readonly hemoglobinMmolPerLSd: null | number;
  readonly hemoglobinNotDone: null | number;
  readonly heparinBemi: null | number;
  readonly heparinBemiPatients: null | number;
  readonly heparinDalt: null | number;
  readonly heparinDaltPatients: null | number;
  readonly heparinEnoxA: null | number;
  readonly heparinEnoxAPatients: null | number;
  readonly heparinEnoxC: null | number;
  readonly heparinEnoxCPatients: null | number;
  readonly heparinEnoxE: null | number;
  readonly heparinEnoxEPatients: null | number;
  readonly heparinEnoxG: null | number;
  readonly heparinEnoxGPatients: null | number;
  readonly heparinEnoxL: null | number;
  readonly heparinEnoxLPatients: null | number;
  readonly heparinHeparinlessTreatment: null | number;
  readonly heparinNadr: null | number;
  readonly heparinNadrPatients: null | number;
  readonly heparinStandard: null | number;
  readonly heparinStandardPatients: null | number;
  readonly heparinTinz: null | number;
  readonly heparinTinzPatients: null | number;
  readonly hospCardiologyDays: null | number;
  readonly hospCardiologyHosp: null | number;
  readonly hospCardiologyMissedSessions: null | number;
  readonly hospCardiologyPatient: null | number;
  readonly hospInfectionsDays: null | number;
  readonly hospInfectionsHosp: null | number;
  readonly hospInfectionsMissedSessions: null | number;
  readonly hospInfectionsPatient: null | number;
  readonly hospOtherDays: null | number;
  readonly hospOtherHosp: null | number;
  readonly hospOtherMissedSessions: null | number;
  readonly hospOtherPatient: null | number;
  readonly hospVascularAccessDays: null | number;
  readonly hospVascularAccessHosp: null | number;
  readonly hospVascularAccessMissedSessions: null | number;
  readonly hospVascularAccessPatient: null | number;
  readonly intactParathormonB800: null | number;
  readonly intactParathormonBe100AndLe800: null | number;
  readonly intactParathormonL100: null | number;
  readonly intactParathormonNotDone: null | number;
  readonly intactParathormonPgPerMlMean: null | number;
  readonly intactParathormonPgPerMlSd: null | number;
  readonly intactParathormonPmolPerLMean: null | number;
  readonly intactParathormonPmolPerLSd: null | number;
  readonly medicationEsa: null | number;
  readonly medicationIviron: null | number;
  readonly nutritionEiEnteral: null | number;
  readonly nutritionEiIdpn: null | number;
  readonly nutritionEiNotTreated: null | number;
  readonly nutritionSgaA: null | number;
  readonly nutritionSgaB: null | number;
  readonly nutritionSgaC: null | number;
  readonly nutritionSgaNotDone: null | number;
  readonly ownPatientAllLastMonth: null | number;
  readonly ownPatientHemoDialysis3Month: null | number;
  readonly ownPatientNewAdmission: null | number;
  readonly ownPatientNewBBraunAvitumCenter: null | number;
  readonly ownPatientNewBackTransp: null | number;
  readonly ownPatientNewOtherCenter: null | number;
  readonly ownPatientNewPdToHd: null | number;
  readonly ownPatientResignedBBraunAvitumCenter: null | number;
  readonly ownPatientResignedExitusChronicCardiovascular: null | number;
  readonly ownPatientResignedExitusChronicCerebrovascular: null | number;
  readonly ownPatientResignedExitusChronicInfection: null | number;
  readonly ownPatientResignedExitusChronicMalignancy: null | number;
  readonly ownPatientResignedExitusChronicOther: null | number;
  readonly ownPatientResignedExitusNonchronic: null | number;
  readonly ownPatientResignedHdToPd: null | number;
  readonly ownPatientResignedOtherCenter: null | number;
  readonly ownPatientResignedQuitted: null | number;
  readonly ownPatientResignedTransp: null | number;
  readonly phosphateBe3p5AndL5p5: null | number;
  readonly phosphateBe5p5: null | number;
  readonly phosphateL3p5: null | number;
  readonly phosphateMgPerDlMean: null | number;
  readonly phosphateMgPerDlSd: null | number;
  readonly phosphateMmolPerLMean: null | number;
  readonly phosphateMmolPerLSd: null | number;
  readonly phosphateNotDone: null | number;
  readonly primaryRenalDiseaseDiabetesNephropathy: null | number;
  readonly primaryRenalDiseaseGlomerulonephritis: null | number;
  readonly primaryRenalDiseaseHypertension: null | number;
  readonly primaryRenalDiseaseOther: null | number;
  readonly primaryRenalDiseasePolycystic: null | number;
  readonly primaryRenalDiseaseTubulointerstitial: null | number;
  readonly primaryRenalDiseaseVascular: null | number;
  readonly seroconversionHbvChronic: null | number;
  readonly seroconversionHbvNew: null | number;
  readonly seroconversionHcvChronic: null | number;
  readonly seroconversionHcvNew: null | number;
  readonly seroconversionHivChronic: null | number;
  readonly seroconversionHivNew: null | number;
  readonly serumCreatinineBe8: null | number;
  readonly serumCreatinineL8: null | number;
  readonly serumCreatinineMgPerDlMean: null | number;
  readonly serumCreatinineMgPerDlSd: null | number;
  readonly serumCreatinineNotDone: null | number;
  readonly serumCreatinineUmolPerLMean: null | number;
  readonly serumCreatinineUmolPerLSd: null | number;
  readonly tempPatientHoliday: null | number;
  readonly tempPatientTemporary: null | number;
  readonly timeOnDialysisMean: null | number;
  readonly timeOnDialysisSd: null | number;
  readonly timeTreatmentBe10p5AndL12: null | number;
  readonly timeTreatmentBe12: null | number;
  readonly timeTreatmentBe9AndL10p5: null | number;
  readonly timeTreatmentL9: null | number;
  readonly timeTreatmentMean: null | number;
  readonly timeTreatmentNotDone: null | number;
  readonly timeTreatmentSd: null | number;
  readonly transferrinSaturationB30: null | number;
  readonly transferrinSaturationBe20AndLe30: null | number;
  readonly transferrinSaturationL20: null | number;
  readonly transferrinSaturationMean: null | number;
  readonly transferrinSaturationNotDone: null | number;
  readonly transferrinSaturationSd: null | number;
  readonly treatmentModalityHd: null | number;
  readonly treatmentModalityHdf: null | number;
  readonly treatmentModalityHdfPost: null | number;
  readonly treatmentModalityHdfPre: null | number;
  readonly treatmentsOwnPatients: null | number;
  readonly treatmentsTempPatients: null | number;
  readonly waterQualityBacteriologyFirstResult: null | number;
  readonly waterQualityBacteriologyNotDone: null | boolean;
  readonly waterQualityEndotoxinFirstResult: null | number;
  readonly waterQualityEndotoxinNotDone: null | boolean;
};

export const calculatedReportDetailsResponseModelIncludes = undefined;

export const calculatedReportDetailsResponseModelReadParameters: TypedQueryParameters<
  BavReportingJsonApiTypes,
  CalculatedReportDetailsResponseModelFieldsets,
  'ais-calculated-report'
> = {
  fieldsets: calculatedReportDetailsResponseModelFieldsets,
  include: calculatedReportDetailsResponseModelIncludes,
};
