import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { createObservables, NEXT } from '@bbraun/shared/util-rxjs';
import { Observable } from 'rxjs';
import { ReportInfo } from '../../types/types';

@Component({
  selector: 'bav-reporting-feature-ais-reports-show-report-info',
  templateUrl: './show-report-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowReportInfoComponent {
  @Input() set reportInfo(value: ReportInfo | undefined | null) {
    this.observables[NEXT]('reportInfo', value || undefined);
  }

  private readonly observables = createObservables<{
    reportInfo: ReportInfo | undefined;
  }>({
    reportInfo: undefined,
  });

  readonly reportInfo$: Observable<ReportInfo | undefined>;

  constructor() {
    this.reportInfo$ = this.observables.reportInfo;
  }
}
