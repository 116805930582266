import { toKeys } from '@bbraun/shared/util-lang';
import { PartialFormData } from './partial-form-data.type';

export type ValidationMode = 'all' | 'dirty' | 'changed';

export type ValidationModeFunction = <T>(
  state: Readonly<{
    formData: PartialFormData<Readonly<T>>;
    changedProperties: Iterable<keyof PartialFormData<T>> | false;
    dirtyProperties: Iterable<keyof PartialFormData<T>>;
  }>,
) => Iterable<keyof PartialFormData<T>>;

export const VALIDATION_MODES: {
  [key in 'all' | 'dirty' | 'changed']: ValidationModeFunction;
} = {
  all: (state) => toKeys(state.formData),
  changed: (state) => state.changedProperties || toKeys(state.formData),
  dirty: (state) => state.dirtyProperties,
};

export function resolveValidationModeFunction(
  validationMode: ValidationMode | ValidationModeFunction,
) {
  return typeof validationMode === 'string'
    ? VALIDATION_MODES[validationMode]
    : validationMode;
}
