import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubjectWithRefCount,
  blockingOperationHandler,
  isDefined,
  mapToVoid,
  onSubscription,
} from '@bbraun/shared/util-rxjs';
import {
  firstValueFrom,
  Observable,
  of,
  OperatorFunction,
  Subject,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  exhaustMap,
  filter,
  first,
  map,
  scan,
  share,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { TranslocoService } from '@ngneat/transloco';
import { isLoginError } from '../interfaces/login-error';
import { LoginResult } from '../interfaces/login-result';
import { LoginService } from '../interfaces/login-service';
import { LoginServiceConnector } from '../interfaces/login-service-connector';
import { DataAccessSecurityErrorCodes } from './data-access-security-error-codes';
import { LOGIN_SERVICE_CONNECTOR_TOKEN } from './injection-tokens';
import { PrincipalService } from './principal.service';
import { TokenService } from './token.service';

const UNKNOWN_LOGIN_ERROR_CODE = 0;

@Injectable({ providedIn: 'root' })
export class LoginWithPrincipalService<TPrincipal = unknown>
  implements LoginService
{
  readonly isLoginInProgress$: Observable<boolean>;
  readonly loginResult$: Observable<LoginResult>;
  readonly isLogoutInProgress$: Observable<boolean>;
  readonly isPrincipalLoggedIn$: Observable<boolean>;
  readonly principalLoggedOut$: Observable<void>;
  readonly principal$: Observable<TPrincipal | undefined>;

  private readonly loginOperationHandler =
    blockingOperationHandler<LoginResult>(
      {},
      { strategy: 'single', autoConnectResult: false },
    );

  private readonly logoutSubject = new Subject<{
    errorCode?: number;
    refreshToken?: string;
  }>();
  private readonly logout$: Observable<void>;

  constructor(
    @Inject(LOGIN_SERVICE_CONNECTOR_TOKEN)
    private readonly connector: Pick<LoginServiceConnector, 'login' | 'logout'>,
    private readonly principalService: PrincipalService<TPrincipal>,
    @Inject(TokenService)
    private readonly tokenService: Pick<TokenService, 'setToken' | 'getToken'>,
    private translocoService: TranslocoService,
  ) {
    this.loginResult$ = this.loginOperationHandler.results
      .pipe(map((result) => (result.hasValue ? result.value : undefined)))
      .pipe(isDefined());

    this.isPrincipalLoggedIn$ = this.principalService.principal$
      .pipe(map((principal) => !!principal))
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.isLoginInProgress$ = this.loginOperationHandler.running
      .pipe(startWith(false))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.principal$ = this.principalService.principal$;

    this.principalLoggedOut$ = this.principal$
      .pipe(principalHasBeenLoggedOut())
      .pipe(mapToVoid());

    const logoutInProgressSubject = new BehaviorSubjectWithRefCount<boolean>(
      false,
    );

    this.logout$ = this.logoutSubject
      .pipe(
        exhaustMap(({ errorCode, refreshToken }) => {
          logoutInProgressSubject.next(true);
          return this.connector.logout(errorCode, refreshToken).then((url) => {
            try {
              window.location.assign(url);
              return firstValueFrom(
                timer(5000)
                  .pipe(
                    concatMap(() =>
                      throwError(() => 'timeout: could not redirect to logout'),
                    ),
                  )
                  .pipe(tap(() => logoutInProgressSubject.next(false))),
              );
            } catch (error) {
              logoutInProgressSubject.next(false);
              return Promise.reject(error);
            }
          });
        }),
      )
      .pipe(share());

    this.isLogoutInProgress$ = logoutInProgressSubject.pipe(
      distinctUntilChanged(),
    );
  }

  login(username: string, password: string): void {
    this.loginOperationHandler.next(() =>
      this.connector
        .login(username, password)
        .pipe(
          tap((tokens) => {
            this.tokenService.setToken(tokens.refreshToken);
          }),
        )
        .pipe(map(() => ({ success: true } as const)))
        .pipe(
          switchMap((result) => {
            if (result.success) {
              return this.principalService.principal$.pipe(first()).pipe(
                map((principal) => {
                  if (principal) {
                    return result;
                  } else {
                    return {
                      success: false,
                      error: {
                        code: DataAccessSecurityErrorCodes.NO_PRINCIPAL,
                        message: this.translocoService.translate(
                          i18n(
                            'bbraunSharedDataAccessSecurity.errors.failedToLoadPrincipal',
                          ),
                        ),
                      },
                    };
                  }
                }),
              );
            } else {
              return of(result);
            }
          }),
        )
        .pipe(
          catchError((error: unknown) =>
            of({
              success: false,
              error: isLoginError(error)
                ? error
                : {
                    code: UNKNOWN_LOGIN_ERROR_CODE,
                    message: this.translocoService.translate(
                      i18n(
                        'bbraunSharedDataAccessSecurity.errors.unknownError',
                      ),
                    ),
                  },
            } as const),
          ),
        )
        .pipe(
          tap(({ success }) => {
            if (!success) {
              this.tokenService.setToken(undefined);
            }
          }),
        ),
    );
  }

  logout(errorCode?: number | undefined): Promise<void> {
    const refreshToken = this.tokenService.getToken() || undefined;
    this.tokenService.setToken(undefined);

    return firstValueFrom(
      this.logout$.pipe(
        onSubscription({
          afterSubscribe: () =>
            this.logoutSubject.next({ errorCode, refreshToken }),
        }),
      ),
    );
  }
}

function principalHasBeenLoggedOut(): OperatorFunction<unknown, boolean> {
  return (o) =>
    o
      .pipe(
        scan(
          (
            { wasLoggedIn }: { wasLoggedIn: boolean; hasLoggedOut: boolean },
            isLoggedIn,
          ) => ({
            wasLoggedIn: !!isLoggedIn,
            hasLoggedOut: wasLoggedIn && !isLoggedIn,
          }),
          { wasLoggedIn: false, hasLoggedOut: false },
        ),
      )
      .pipe(map(({ hasLoggedOut }) => hasLoggedOut))
      .pipe(filter((hasLoggedOut) => hasLoggedOut));
}
