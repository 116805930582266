import { registerLocaleData } from '@angular/common';
import fallbackLocaleData from '@angular/common/locales/en';
import { Injectable } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { TranslocoService } from '@ngneat/transloco';
import { hasProperty } from '@bbraun/shared/util-lang';
import { LocaleService } from '../initializers/locale.service';
import { LocaleObservableFactory } from './locale-observable-factory';

const LOCALE_TO_FILE_LOCALE_MAPPING = {
  'zh-CN': 'zh-Hans',
};

@Injectable({ providedIn: 'root' })
export class AngularLocaleObservableFactory extends LocaleObservableFactory {
  constructor(
    localeService: LocaleService,
    messageService: MessageService,
    translocoService: TranslocoService,
  ) {
    super(localeService, messageService, translocoService, {
      createImport: (lang) =>
        import(
          `../../../../../../node_modules/@angular/common/locales/${
            hasProperty(lang)(LOCALE_TO_FILE_LOCALE_MAPPING)
              ? LOCALE_TO_FILE_LOCALE_MAPPING[lang]
              : lang
          }.mjs`
        ).then((v) => v.default),
      createFallback: (lang) => [lang, ...fallbackLocaleData.slice(1)],
      registerData: registerLocaleData,
    });
  }
}
