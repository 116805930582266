import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_LOWER_OR_EQUAL_TO_NUMBER_ID } from '../../../../validation/factories/is-lower-or-equal-to-number-validator-factory';
import { IS_NUMBER_ID } from '../../../../validation/factories/is-number-validator-factory';

export function createPercentageCalciumBe8p4AndLe10p5Property(): Property {
  return {
    title: null,
    technicalName: 'hdKpi_e76',
    dataType: 'calculated',
    unit: '%',
    highlighted: true,
    subName: null,
    formula: 'calciumBe8p4AndLe10p5 * 100 / ownPatientHemoDialysis3Month',
    validation: [
      { validator: IS_NUMBER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
      {
        validator: IS_LOWER_OR_EQUAL_TO_NUMBER_ID,
        options: {
          config: { maximum: 100 },
        },
      },
    ],
  };
}
