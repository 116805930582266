import { Component, Input } from '@angular/core';
import { Buildinfo, BuildinfoService } from '@bbraun/shared/util-buildinfo';
import { Observable } from 'rxjs';

@Component({
  selector: 'bbraun-ui-start-view',
  templateUrl: './start-view.component.html',
  styleUrls: ['./start-view.component.scss'],
})
export class StartViewComponent {
  readonly buildinfo$: Observable<Buildinfo>;

  @Input()
  productName?: string;

  constructor(buildinfoService: BuildinfoService) {
    this.buildinfo$ = buildinfoService.get();
  }
}
