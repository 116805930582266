import { Inject, Injectable } from '@angular/core';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { isNumber } from '@bbraun/shared/util-validation';
import { map, Observable } from 'rxjs';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import {
  ValidatorFactory,
  ValidatorFactoryFunction,
} from '@bbraun/bav-reporting/ui-report-editor';
import { FeatureAisReportsScopedTranslationService } from '../../../services/feature-ais-reports-scoped-translation.service';

export const IS_NUMBER_ID = 'IS_NUMBER';
export const IS_NUMBER_ERROR = Symbol(IS_NUMBER_ID);

@Injectable({
  providedIn: 'root',
})
export class IsNumberValidatorFactory implements ValidatorFactory<undefined> {
  readonly id = IS_NUMBER_ID;
  readonly validatorFn: Observable<ValidatorFactoryFunction<undefined>>;

  constructor(
    @Inject(FeatureAisReportsScopedTranslationService)
    private readonly scopedTranslationService: {
      loadedLang$: Observable<{ translate: TranslateFunction }>;
    },
  ) {
    this.validatorFn = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) => () => ({
        id: IS_NUMBER_ERROR,
        validate: isNumber,
        message: translate(
          i18n(
            'bbraunBavReportingFeatureAisReports.validation.property.notNumber.error.message',
          ),
        ),
      })),
    );
  }
}
