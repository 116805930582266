import { Inject, Injectable } from '@angular/core';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { map, Observable } from 'rxjs';
import { TranslateFunction } from '@bbraun/shared/util-transloco-ng';
import {
  ValidatorFactory,
  ValidatorFactoryFunction,
} from '@bbraun/bav-reporting/ui-report-editor';
import { createOneHeparinPatientsIsSetWhenMonthFebruaryOrAugustValidator } from '../create-validators';
import { FeatureAisReportsScopedTranslationService } from '../../../services/feature-ais-reports-scoped-translation.service';

export const ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ID =
  'ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST';
export const ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ERROR = Symbol(
  ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ID,
);

@Injectable({
  providedIn: 'root',
})
export class OneHeparinPatientsIsSetInFebruaryAndAugustValidatorFactory
  implements ValidatorFactory<{ reportMonth: number }>
{
  readonly id = ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ID;
  readonly validatorFn: Observable<
    ValidatorFactoryFunction<{ reportMonth: number }>
  >;

  constructor(
    @Inject(FeatureAisReportsScopedTranslationService)
    private readonly scopedTranslationService: {
      loadedLang$: Observable<{ translate: TranslateFunction }>;
    },
  ) {
    this.validatorFn = this.scopedTranslationService.loadedLang$.pipe(
      map(({ translate }) => ({ reportMonth }) => ({
        id: ONE_HEPARIN_PATIENTS_IS_SET_IN_FEBRUARY_AND_AUGUST_ERROR,
        validate:
          createOneHeparinPatientsIsSetWhenMonthFebruaryOrAugustValidator(
            reportMonth,
          ),
        message: translate(
          i18n(
            'bbraunBavReportingFeatureAisReports.validation.oneHeparinPatients.notSet.februaryAndAugust.error.message',
          ),
        ),
      })),
    );
  }
}
