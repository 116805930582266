export interface ObjectType {
  [key: string]: unknown;
}

export type KeyType = string | number | symbol;

export type References = ReadonlyMap<object, unknown>;

export type MergeErrorMode = 'unsafe' | 'safe' | 'throw';

export interface Error {
  message: string;
  values: unknown;
}

export interface Options {
  mode: MergeErrorMode;
  paths: boolean;
  diffs: boolean;
  depth: number;
  timeoutInMs: number;
  now: () => number;
  identityCheck: (value1: unknown, value2: unknown) => boolean;
  mergeFunctions: ReadonlyArray<MergeFunctionEntry<unknown>>;
}

type MergeFunction<T> = (
  base: T,
  mergeObj: T,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
) => MergeResult<T>;

export class MergeFunctionEntry<T> {
  constructor(
    readonly accepts: (value: T) => value is T,
    readonly merge: MergeFunction<T>,
  ) {}
}

export type MergeResult<T> =
  | {
      hasError: false;
      result: T;
      paths?: ReadonlyArray<ReadonlyArray<KeyType>>;
      diffs?: ReadonlyArray<ReadonlyArray<KeyType>>;
    }
  | {
      hasError: true;
      error: Error;
    };

export function isObjectType(value: unknown): value is ObjectType {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function isPlainObjectType(value: unknown): value is ObjectType {
  return isObjectType(value) && value.constructor === Object;
}
