export function range(length: number): Array<number>;
export function range<TValue>(
  length: number,
  fn: (index: number) => TValue,
): Array<TValue>;
export function range<TValue>(
  length: number,
  fn: (index: number) => TValue | number = (index) => index,
): Array<TValue | number> {
  const array: Array<TValue | number> = [];

  for (let index = 0; index < Math.max(length, 0); index++) {
    array[index] = fn(index);
  }

  return array;
}
