import { FiqlQuery, TypedFiqlQuery } from '@bbraun/shared/util-fiql';
import {
  PropertyPathBuilder,
  SortSpecsBuilder,
} from '@bbraun/shared/util-lang';
import { Observable, of, throwError, switchMap } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { JsonApiUrlAdapter } from '../json-api-url-adapter';
import {
  isJsonApiErrorResponseBody,
  JsonApiDataObjectCollectionResponseBody,
  JsonApiErrorResponseBody,
} from '../json-api.types';
import {
  mapDocumentToJsonApiCollectionResponseUnsafe,
  mapToJsonApiCollectionResponseUnsafe,
} from '../rxjs/map-to-json-api-collection-response-unsafe';
import { mapToJsonApiObjectResponseUnsafe } from '../rxjs/map-to-json-api-object-response-unsafe';
import {
  CreateObjectType,
  TypeMeta,
  UpdateObjectType,
} from './api-type-meta.type';
import { ApiDefinition, ApiTypes } from './api-types';
import { Fieldsets } from './fieldsets';
import { ResultItem } from './result-item.type';
import { JsonApiDocumentData, SortedResult } from './typed-json-api-adapter';
import { TypedQueryParameters } from './typed-query-parameters';

export class TypedJsonApiLinkedAdapter<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
  TTypeMeta extends TypeMeta<TTypeName>,
> {
  private readonly resourceUrl$: Observable<string>;

  constructor(
    resourceUrl: string | Observable<string>,
    private readonly adapter: JsonApiUrlAdapter<
      TypedQueryParameters<
        TApiDefinition['resourceTypes'],
        Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
        TTypeName,
        any,
        SortSpecsBuilder<any>
      >
    >,
    private readonly mapToCreateParameters: (
      v: CreateObjectType<TTypeMeta, TTypeName>,
    ) => TTypeMeta[TTypeName]['create'],

    private readonly mapToUpdateParameters: (
      id: string,
      v: UpdateObjectType<TTypeMeta, TTypeName>,
    ) => { id: string } & TTypeMeta[TTypeName]['update'],
  ) {
    this.resourceUrl$ =
      typeof resourceUrl === 'string' ? of(resourceUrl) : resourceUrl;
  }

  queryRaw<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder> | FiqlQuery | string,
      TSortSpecs
    >,
  ): Observable<
    JsonApiErrorResponseBody | JsonApiDataObjectCollectionResponseBody
  > {
    return this.resourceUrl$.pipe(
      switchMap((resourceUrl) =>
        this.adapter.query(resourceUrl, queryParameter),
      ),
    );
  }

  query<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder>,
      TSortSpecs
    >,
  ): Observable<Array<ResultItem<TApiDefinition, TFieldsets, TTypeName>>> {
    return this.queryRaw(queryParameter).pipe(
      mapToJsonApiCollectionResponseUnsafe(),
    );
  }

  queryObject<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder>,
      TSortSpecs
    >,
  ): Observable<
    SortedResult<TApiDefinition, TFieldsets, TTypeName> & JsonApiDocumentData
  > {
    return this.resourceUrl$.pipe(
      switchMap((resourceUrl) =>
        this.adapter.query(resourceUrl, queryParameter).pipe(
          concatMap((document) =>
            mapDocumentToJsonApiCollectionResponseUnsafe<
              ResultItem<TApiDefinition, TFieldsets, TTypeName>,
              TApiDefinition,
              TTypeName
            >(document).pipe(
              map((result) => ({
                data: result,
                queryParameter,
                links: document.links,
                meta: document.meta,
                jsonapi: document.jsonapi,
              })),
            ),
          ),
        ),
      ),
    );
  }

  read<TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>>(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName
    > = {},
  ): Observable<ResultItem<TApiDefinition, TFieldsets, TTypeName>> {
    return this.resourceUrl$.pipe(
      switchMap((resourceUrl) =>
        this.adapter
          .read(resourceUrl, queryParameter)
          .pipe(
            mapToJsonApiObjectResponseUnsafe<
              ResultItem<TApiDefinition, TFieldsets, TTypeName>,
              TApiDefinition,
              TTypeName
            >(),
          ),
      ),
    );
  }

  update<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>> = {},
  >(
    id: string,
    resourceObject: UpdateObjectType<TTypeMeta, TTypeName>,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName
    > = {},
  ): Observable<ResultItem<TApiDefinition, TFieldsets, TTypeName>> {
    try {
      return this.resourceUrl$.pipe(
        switchMap((resourceUrl) =>
          this.adapter
            .update(
              resourceUrl,
              this.mapToUpdateParameters(id, resourceObject),
              queryParameter,
            )
            .pipe(
              mapToJsonApiObjectResponseUnsafe<
                ResultItem<TApiDefinition, TFieldsets, TTypeName>,
                TApiDefinition,
                TTypeName
              >(),
            ),
        ),
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  create<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>> = {},
  >(
    resourceObject: CreateObjectType<TTypeMeta, TTypeName>,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName
    > = {},
  ): Observable<ResultItem<TApiDefinition, TFieldsets, TTypeName>> {
    try {
      return this.resourceUrl$.pipe(
        switchMap((resourceUrl) =>
          this.adapter
            .create(
              resourceUrl,
              this.mapToCreateParameters(resourceObject),
              queryParameter,
            )
            .pipe(
              mapToJsonApiObjectResponseUnsafe<
                ResultItem<TApiDefinition, TFieldsets, TTypeName>,
                TApiDefinition,
                TTypeName
              >(),
            ),
        ),
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  delete(): Observable<true> {
    try {
      return this.resourceUrl$.pipe(
        switchMap((resourceUrl) =>
          this.adapter
            .delete(resourceUrl)
            .pipe(
              concatMap((v) =>
                v && isJsonApiErrorResponseBody(v)
                  ? throwError(() => 'Failed to delete item.')
                  : of(true as const),
              ),
            ),
        ),
      );
    } catch (error) {
      return throwError(() => error);
    }
  }
}
