import { of, OperatorFunction, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function expectSingleElementArray<T>(): OperatorFunction<
  ReadonlyArray<T>,
  T | undefined
> {
  return (o) =>
    o.pipe(
      switchMap((arr) =>
        arr.length === 0
          ? of(undefined)
          : arr.length === 1
          ? of(arr[0])
          : throwError(() => 'Multiple elements found. Expected one element.'),
      ),
    );
}
