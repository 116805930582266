import { isJsonApiDataObjectResponseBody } from './json-api.types';
import { mapDataObjectOrDataObjectCollection } from './map-data-object-or-data-object-collection';
import { ModelObject, ObjectsByTypeAndId } from './model-object.type';
import { ID } from './symbols.type';
import { ApiDefinition, ApiTypes } from './typed/api-types';

export function readJsonApiObjectResponseUnsafe<
  T,
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
>(
  document: {} | null,
): {
  result: T & ModelObject<TApiDefinition, TTypeName>;
  objects: ObjectsByTypeAndId<TApiDefinition, TTypeName>;
} {
  if (document && isJsonApiDataObjectResponseBody(document)) {
    return mapDataObjectOrDataObjectCollection(
      document.data,
      document.included || [],
      {
        createCallback: (v) => {
          v.id = v[ID];
        },
      },
    ) as any;
  } else {
    throw new Error('Failed to load json api response document.');
  }
}
