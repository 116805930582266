import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { isFinite, merge } from 'lodash';
import * as moment from 'moment';

interface VersionedState<T> {
  version: number;
  state: T;
}

export function replacer(this: any, key: string, value: any): any {
  if (key !== undefined && key !== null) {
    const originalValue = this[key];

    if (originalValue instanceof Date) {
      return { value, ___jsType: 'date' };
    } else if (moment.isMoment(originalValue)) {
      return { value, ___jsType: 'moment' };
    }
  }

  return value;
}

export function reviver(_key: string, value: any): any {
  if (
    value &&
    typeof value === 'object' &&
    value.hasOwnProperty('value') &&
    value.hasOwnProperty('___jsType')
  ) {
    const type = value.___jsType;
    switch (type) {
      case 'date':
        return new Date(value.value);
      case 'moment':
        return moment(value.value);
      default:
        console.warn(
          `Unsupported ___jsType in JSON.parse reviver function: '${type}'`,
        );
        return value;
    }
  } else {
    return value;
  }
}

function saveInLocalStorage<S>(
  versionedState: VersionedState<S>,
  localStorageKey: string,
) {
  localStorage.setItem(
    localStorageKey,
    JSON.stringify(versionedState, replacer),
  );
}

function retrieveFromLocalStorage<S>(
  localStorageKey: string,
): VersionedState<S> | undefined {
  const stored = localStorage.getItem(localStorageKey);
  if (stored !== null) {
    // Try-catch to make sure that an unparsable stored value does not crash the application
    try {
      return JSON.parse(stored, reviver) as VersionedState<S>;
    } catch (e) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

/**
 * State persistence meta reducer that retrieves previously persisted state
 * from localStorage upon initialization using the given key, but only if the
 * persisted version matches the given version. Persisted state is merged
 * with initial state (if any). After applying the actions, state is again
 * persisted to localStorage.
 *
 * @param storageKey key to save
 * @param version
 */
export function statePersistenceMetaReducer<S, A extends Action = Action>(
  storageKey: string,
  version = 1,
): MetaReducer<S, A> {
  let stateToRestore: S;
  let onInit = true;

  const raw = retrieveFromLocalStorage<S>(storageKey);
  if (raw) {
    if (isFinite(raw.version)) {
      if (raw.version === version) {
        stateToRestore = raw.state;
      }
    }
  }

  return (reducer: ActionReducer<S, A>) =>
    function (state: S | undefined, action: A): S {
      let nextState = state;

      if (onInit) {
        onInit = false;
        if (stateToRestore) {
          nextState = merge(nextState, stateToRestore);
        }
      }

      nextState = reducer(nextState, action);
      saveInLocalStorage({ version, state: nextState }, storageKey);

      return nextState;
    };
}
