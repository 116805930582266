import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

export const NEXT = Symbol('Next');
export const VALUES = Symbol('Values');

export function createObservables<TValue>(
  initial: Readonly<{ [key in keyof TValue]: TValue[key] }>,
  op: <T>(o: Observable<T>) => Observable<T> = distinctUntilChanged(),
) {
  const { values, subjects } = Object.entries<any>(initial).reduce(
    ({ values: accValues, subjects: accSubjects }, [key, value]) => {
      accValues[key as keyof TValue] = value;
      const subject = new BehaviorSubject<TValue[keyof TValue]>(value);
      accSubjects[key as keyof TValue] = subject;

      return { values: accValues, subjects: accSubjects };
    },
    {
      values: {} as { [key in keyof TValue]: TValue[key] },
      subjects: {} as { [key in keyof TValue]: Subject<TValue[key]> },
    },
  );

  const observables = Object.entries(subjects).reduce((acc, [key, subject]) => {
    acc[key as keyof TValue] = (subject as Subject<TValue[keyof TValue]>)
      .pipe(op)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    return acc;
  }, {} as { [key in keyof TValue]: Observable<TValue[key]> });

  return {
    ...observables,
    [NEXT]<T extends keyof TValue>(key: T, value: TValue[T]): void {
      values[key] = value;
      const subject: Subject<TValue[T]> = subjects[key];

      if (subject) {
        subject.next(value);
      }
    },
    [VALUES]: values as Readonly<{ [key in keyof TValue]: TValue[key] }>,
  };
}
