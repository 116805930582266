import { FiqlConstraint } from '@bbraun/shared/util-fiql';
import { DxFilterOperation, isDxFilterOperation } from './dx-filter-operation';
import { DxFilterOperator } from './dx-filter-operator';

function negateOperator(dxFilterOperator: DxFilterOperator): DxFilterOperator {
  switch (dxFilterOperator) {
    case '=':
      return '<>';
    case '<>':
      return '=';
    case '<':
      return '>=';
    case '<=':
      return '>';
    case '>':
      return '<=';
    case '>=':
      return '<';
    case 'contains':
      return 'notcontains';
    case 'notcontains':
      return 'contains';
    default:
      return dxFilterOperator;
  }
}

export function convertDxFilterOperationToFiql(
  dxFilterOperation: DxFilterOperation,
  negate = false,
): FiqlConstraint {
  if (!isDxFilterOperation(dxFilterOperation)) {
    throw new Error(`Filter operation is invalid!`);
  }

  const [selector, initialOperator, args] = dxFilterOperation;
  const operator = negate ? negateOperator(initialOperator) : initialOperator; // startswith and endswith cannot be negated here

  switch (operator) {
    case '=':
      return { equals: { selector, args } };
    case '<>':
      return { not_equals: { selector, args } };
    case '<':
      return { less_than: { selector, args } };
    case '<=':
      return { less_than_or_equal: { selector, args } };
    case '>':
      return { greater_than: { selector, args } };
    case '>=':
      return { greater_than_or_equal: { selector, args } };
    case 'startswith':
      if (negate) {
        return { not_equals: { selector, args: `${args}*` } };
      } else {
        return { equals: { selector, args: `${args}*` } };
      }
    case 'endswith':
      if (negate) {
        return { not_equals: { selector, args: `*${args}` } };
      } else {
        return { equals: { selector, args: `*${args}` } };
      }
    case 'contains':
      return { equals: { selector, args: `*${args}*` } };
    case 'notcontains':
      return { not_equals: { selector, args: `*${args}*` } };
    default:
      throw new Error(
        `Filter operation not supported: ${JSON.stringify(dxFilterOperation)}!`,
      );
  }
}
