// THIS FILE IS GENERATED - DO NOT EDIT!

import { TYPE } from '@bbraun/shared/util-jsonapi-symbols';

// @ts-ignore

export type LabTestMappingModel = {
  [TYPE]: 'lab-test-mapping';
  readonly id: string;
} & {
  labTestIdClinicalData:
    | null
    | (
        | 'NotApplicable'
        | 'HemoglobinGPerDl'
        | 'HemoglobinGPerL'
        | 'HemoglobinMmolPerL'
        | 'FerritinUgPerL'
        | 'FerritinNgPerDl'
        | 'TransferrinSaturationPercent'
        | 'CalciumInSerumMgPerDl'
        | 'CalciumInSerumMmolPerL'
        | 'PhosphateInSerumMgPerDl'
        | 'PhosphateInSerumMmolPerL'
        | 'IntactParathormonPgPerMl'
        | 'IntactParathormonNgPerL'
        | 'IntactParathormonPmolPerL'
        | 'CReactiveProteinMgPerL'
        | 'CReactiveProteinMgPerDl'
        | 'CReactiveProteinNmolPerL'
        | 'AlbuminBromocresolGreenGPerL'
        | 'AlbuminBromocresolGreenGPerDl'
        | 'AlbuminBromocresolPurpleGPerL'
        | 'AlbuminBromocresolPurpleGPerDl'
        | 'SerumCreatinineMgPerDl'
        | 'SerumCreatinineUmolPerL'
      );
  labTestIdExtern: null | string;
  laboratoryName: string;
  parameterName: string;
  parameterUnit: null | string;
};

export interface LabTestMappingModelUpdate {
  attributes?: Partial<{
    labTestIdClinicalData: ExpertLoaderJsonApiTypes['lab-test-mapping']['labTestIdClinicalData'];
  }>;
  relationships?: undefined;
}

const labTestMappingModelUpdateProperties = {
  attributes: ['labTestIdClinicalData'],
  relationships: undefined,
} as const;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ExpertLoaderJsonApiTypesMeta = {
  ['lab-test-mapping']: {
    create: never;
    update: LabTestMappingModelUpdate;
  };
};

export const expertLoaderJsonApiTypesProperties = {
  ['lab-test-mapping']: {
    create: undefined,
    update: labTestMappingModelUpdateProperties,
  },
} as const;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ExpertLoaderJsonApiTypes = {
  ['lab-test-mapping']: LabTestMappingModel;
};

export const expertLoaderResourceNames = ['lab-test-mapping'] as const;

export type ExpertLoaderResourceNames =
  typeof expertLoaderResourceNames[number];

export const expertLoaderMeta = {
  id: 'expertloader-service',
  version: '4.1.0',
  links: [...expertLoaderResourceNames],
} as const;

export type ExpertLoaderLinkNames = typeof expertLoaderMeta.links[number];
