import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_INTEGER_ID } from '../../../../validation/factories/is-number-integer-validator-factory';
import { IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ID } from '../../../../validation/factories/is-zero-or-equal-to-number-of-chronic-hd-patients-validator-factory';

export function createSumOfCrpPatientsProperty(): Property {
  return {
    title: 'Total',
    technicalName: 'sumOfCrpPatients',
    dataType: 'calculated',
    unit: null,
    highlighted: false,
    subName: null,
    formula: 'crpWithinLimits + crpElevated + crpNotDone',
    validation: [
      { validator: IS_NUMBER_INTEGER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
      {
        validator: IS_ZERO_OR_EQUAL_TO_NUMBER_OF_CHRONIC_HD_PATIENTS_ID,
        options: {
          messageKey: i18n(
            'bbraunBavReportingFeatureAisReports.validation.sumOfCrpPatients.notEqual.numberOfChronicHdPatients.error.message',
          ),
        },
      },
    ],
  };
}
