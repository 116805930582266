import { HttpErrorResponse } from '@angular/common/http';
import { ReportDetailsResponseModel } from '@bbraun/bav-reporting/data-access-ais-reports';
import { getErrorMessageCode } from '@bbraun/shared/util-jsonapi';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { blockingOperationHandler } from '@bbraun/shared/util-rxjs';
import { translate } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';

export function createSaveReportHandler(
  messageService: MessageService,
  onSuccessCallback: () => void,
) {
  return blockingOperationHandler<ReportDetailsResponseModel>(
    {
      next: () => {
        messageService.message(
          translate(
            i18n(
              'bbraunBavReportingFeatureAisReports.reportDetailsComponent.savingComplete.message',
            ),
          ),
          'success',
        );
        onSuccessCallback();
      },
      error: (error) => {
        const errorCode = getErrorMessageCode(error);
        if (error instanceof HttpErrorResponse) {
          if (errorCode === 'NOT_UNIQUE') {
            messageService.message(
              translate(
                i18n(
                  'bbraunBavReportingFeatureAisReports.reportDetailsComponent.notUniqueSavingError.message',
                ),
              ),
              'error',
              { error },
            );
          } else if (errorCode) {
            messageService.message(
              translate(
                i18n(
                  'bbraunBavReportingFeatureAisReports.reportDetailsComponent.unexpectedSavingError.message',
                ),
                { errorCode },
              ),
              'error',
              { error },
            );
          }
        } else {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingFeatureAisReports.reportDetailsComponent.savingError.message',
              ),
            ),
            'error',
            { error },
          );
        }
      },
    },
    { strategy: 'single', autoConnectResult: false },
  );
}
