<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiLogin';
    read: 'bbraunSharedUiLogin.loginComponent'
  "
>
  <h1 class="page-title">{{ translate('title.text') }}</h1>

  <bbraun-ui-login-dialog
    (loginRequested)="loginRequested($event)"
  ></bbraun-ui-login-dialog>
</ng-container>
