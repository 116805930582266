<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiLanguage';
    read: 'bbraunSharedUiLanguage.languageDialogComponent'
  "
>
  <mat-card class="bbraun-flat">
    <mat-card-subtitle translate>{{
      translate('title.text')
    }}</mat-card-subtitle>
    <mat-card-content>
      <div fxLayout="column">
        <div>
          <dx-select-box
            [dataSource]="(viewModel$ | async) || []"
            [value]="currentLanguage$ | async"
            valueExpr="code"
            displayExpr="name"
            (onValueChanged)="selectLanguage($event)"
          >
          </dx-select-box>
        </div>
        <div class="text-right pt-1">
          <button mat-button color="accent" (click)="apply()" translate>
            {{ translate('applyButton.text') }}
          </button>
          <button
            mat-button
            color="primary"
            class="ml-1"
            (click)="abort()"
            translate
          >
            {{ translate('abortButton.text') }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
