export function propertyIsOfType<
  TPropertyName extends string | number | symbol,
  TPropertyType,
>(
  propertyName: TPropertyName,
  isPropertyType: (propertyValue: unknown) => propertyValue is TPropertyType,
): (value: { [key in TPropertyName]: unknown }) => value is {
  [key in TPropertyName]: TPropertyType;
} {
  return (value): value is { [key in TPropertyName]: TPropertyType } =>
    isPropertyType(value[propertyName]);
}
