import { JsonApiPaging } from '@bbraun/shared/util-jsonapi';
import {
  createSortSpecs,
  isEqualSortSpecs,
  SortSpecsBuilder,
} from '@bbraun/shared/util-lang';
import {
  isDefined,
  pickArrayPropertiesDistinctUntilChanged,
  pickDistinctUntilChanged,
} from '@bbraun/shared/util-rxjs';
import DataSource from 'devextreme/data/data_source';
import dxDataGrid from 'devextreme/ui/data_grid';
import { concat, defer, EMPTY, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { computeSortInformation } from '../functions/compute-sort-information';
import { configureSort } from '../functions/configure-sort';
import { DxDataGridSort, fromSort } from '../functions/from-sort';
import { withUpdate } from '../functions/with-update';
import { DxPagedResult } from './dx-paged-result';
import { DxSortOption } from './dx-sort-option';
import {
  DxDataGridSortMapping,
  GridComponentForJsonApiUpdatableDxDataSource,
  PageRequest,
  SortedValues,
  SortRequest,
} from './json-api-updateable-dx-data-grid-data-source.type';

const EMPTY_SORT_SPECS = createSortSpecs();

export class JsonApiUpdateableDxDataGridDataSource<TViewModel, TModel> {
  readonly dataSource: DataSource;

  private data: SortedValues<TViewModel> = {
    values: [],
    pagination: false,
  };

  private isSortSynced = true;
  private isPagingSynced = true;

  readonly sortRequested$: Observable<SortRequest<TModel>>;
  readonly pagingRequested$: Observable<PageRequest>;

  constructor(
    initialData: SortedValues<TViewModel>,
    private readonly sortMapping: DxDataGridSortMapping<TViewModel, TModel>,
    private readonly updateableDataSourceGrid: GridComponentForJsonApiUpdatableDxDataSource,
  ) {
    this.data = initialData;

    withUpdate(updateableDataSourceGrid.instance, (gridInstance) => {
      configureSort(
        Object.entries<SortSpecsBuilder<unknown>>(this.sortMapping)
          .filter(([, value]) => value.specs.length > 0)
          .map(([key]) => key),
        gridInstance,
      );

      updateGrid(this.data, sortMapping, gridInstance);
    });

    this.sortRequested$ = (
      sortMapping
        ? createSortRequestedEvent(sortMapping, updateableDataSourceGrid)
        : EMPTY
    )
      .pipe(
        map((sortSpec) => {
          this.isSortSynced =
            this.isSortSynced &&
            isEqualSortSpecs(sortSpec, this.data.sort || EMPTY_SORT_SPECS);

          return {
            isSynced: this.isSortSynced,
            sort: sortSpec,
          };
        }),
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.pagingRequested$ = createPagingRequestedEvent(updateableDataSourceGrid)
      .pipe(
        map((pagination) => {
          this.isPagingSynced =
            this.isPagingSynced &&
            isEqualPagination(pagination, this.data.pagination);

          return {
            isSynced: this.isPagingSynced,
            page: pagination,
          };
        }),
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    const jsonApiUpdateableDxDataGridDataSource = this;
    this.dataSource = new DataSource({
      key: 'id',
      load: (): Promise<DxPagedResult<TViewModel>> | TViewModel[] =>
        !jsonApiUpdateableDxDataGridDataSource.data.pagination
          ? Array.from(jsonApiUpdateableDxDataGridDataSource.data.values)
          : Promise.resolve({
              data: Array.from(
                jsonApiUpdateableDxDataGridDataSource.data.values,
              ),
              totalCount:
                jsonApiUpdateableDxDataGridDataSource.data.pagination.count *
                jsonApiUpdateableDxDataGridDataSource.data.pagination.size,
            }),
    });
  }

  update(sortedValues: SortedValues<TViewModel>) {
    withUpdate(this.updateableDataSourceGrid.instance, (gridInstance) => {
      this.isSortSynced = true;
      this.isPagingSynced = true;
      this.data = sortedValues;
      updateGrid(this.data, this.sortMapping, gridInstance);
    });

    this.dataSource.reload();
  }
}

function readSortOptionsFromGrid(
  gridInstance: GridComponentForJsonApiUpdatableDxDataSource['instance'],
) {
  const dxSortOptions: DxSortOption[] = [];

  const columnCount = gridInstance.columnCount();

  for (let i = 0; i < columnCount; i++) {
    const columnOption = gridInstance.columnOption(i);

    if (columnOption.sortOrder) {
      dxSortOptions[columnOption.sortIndex] = {
        selector: columnOption.dataField,
        desc: columnOption.sortOrder === 'desc',
      };
    }
  }

  return dxSortOptions;
}

function readPagingOptionsFromGrid(
  gridInstance: GridComponentForJsonApiUpdatableDxDataSource['instance'],
): JsonApiPaging | undefined {
  const pageIndex = gridInstance.pageIndex();
  const pageSize = gridInstance.pageSize();

  return pageSize
    ? {
        size: pageSize,
        number: pageIndex + 1,
      }
    : undefined;
}

function createSortRequestedEvent<TViewModel, TModel>(
  sortMapping: DxDataGridSortMapping<TViewModel, TModel>,
  updateableDataSourceGrid: GridComponentForJsonApiUpdatableDxDataSource,
): Observable<SortSpecsBuilder<TModel>> {
  const gridOnContentReadyEvent = updateableDataSourceGrid.onContentReady;

  const initialValue: Observable<DxSortOption[]> = defer(() =>
    of(readSortOptionsFromGrid(updateableDataSourceGrid.instance)),
  );

  const changedValue: Observable<DxSortOption[]> = gridOnContentReadyEvent.pipe(
    map(({ component }: { component: dxDataGrid }) =>
      readSortOptionsFromGrid(component),
    ),
  );

  return concat(initialValue, changedValue)
    .pipe(pickArrayPropertiesDistinctUntilChanged(['desc', 'selector']))
    .pipe(
      map((sortOptions) => computeSortInformation(sortOptions, sortMapping)),
    );
}

function createPagingRequestedEvent(
  updateableDataSourceGrid: GridComponentForJsonApiUpdatableDxDataSource,
) {
  const initialPagingOptions = readPagingOptionsFromGrid(
    updateableDataSourceGrid.instance,
  );
  const initialValue = defer(() => of(initialPagingOptions));

  const changedValue = updateableDataSourceGrid.onContentReady.pipe(
    map(({ component }: { component: dxDataGrid }) =>
      readPagingOptionsFromGrid(component),
    ),
  );

  return concat(initialValue, changedValue)
    .pipe(pickDistinctUntilChanged(['number', 'size']))
    .pipe(isDefined());
}

function isEqualPagination(
  pagination: {
    number?: number | undefined;
    size: number;
  },
  pagination2: false | Readonly<JsonApiPaging>,
): boolean {
  return (
    pagination2 &&
    pagination.number === pagination2.number &&
    pagination.size === pagination2.size
  );
}

function updateGrid<TViewModel, TModel>(
  sortedValue: SortedValues<TViewModel>,
  sortMapping: DxDataGridSortMapping<TViewModel, TModel>,
  gridInstance: Parameters<typeof updatePaging>[1] &
    Parameters<typeof updateSort>[2],
) {
  updateSort(sortedValue.sort, sortMapping, gridInstance);
  updatePaging(sortedValue.pagination, gridInstance);
}

function updatePaging(
  pagination: { number?: number; size: number } | false,
  gridInstance: {
    pageIndex: (index: number) => void;
    pageSize: (size: number) => void;
  },
) {
  gridInstance.pageIndex((pagination ? pagination.number || 1 : 1) - 1);
  gridInstance.pageSize(
    pagination ? pagination?.size : Number.MAX_SAFE_INTEGER,
  );
}

function updateSort<TViewModel, TModel>(
  sort: SortSpecsBuilder<unknown> | undefined,
  sortMapping: DxDataGridSortMapping<TViewModel, TModel>,
  gridInstance: {
    columnCount: () => number;
    columnOption(index: number): {
      dataField: string;
      sortOrder: 'desc' | 'asc';
      sortIndex: number;
    };
    columnOption(dataField: string, sort: DxDataGridSort): void;
  },
) {
  if (sort) {
    const columnCount = gridInstance.columnCount();

    for (let i = 0; i < columnCount; i++) {
      const column = gridInstance.columnOption(i);
      const dataField = column.dataField;

      const mapping = sortMapping[dataField as keyof TViewModel & string];
      gridInstance.columnOption(dataField, fromSort(mapping, sort));
    }
  }
}
