<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiDiscardChanges';
    read: 'bbraunSharedUiDiscardChanges.discardChangesDialogComponent'
  "
>
  <div
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    style="height: 100%"
  >
    <bbraun-ui-form-submit-cancel-form (submit)="remain()" (cancel)="remain()">
      <div class="flex-box flex-column full-height space-between">
        <div>
          <p class="mat-body" translate>
            <ng-container *ngIf="data.discardMessage">
              {{ data.discardMessage }}
            </ng-container>
            <ng-container *ngIf="!data.discardMessage">
              {{ translate('discardPage.text') }}
            </ng-container>
          </p>
          <p class="mat-body">
            {{ translate('confirm.changesWillBeDiscarded.text') }}
          </p>
        </div>

        <p class="text-right mb-0">
          <button
            bbraunUiFormAutofocus
            type="submit"
            mat-raised-button
            color="accent"
          >
            {{ translate('keepChanges.button.text') }}
          </button>
          <button
            type="button"
            mat-raised-button
            color="warn"
            class="ml-1"
            (click)="discard()"
          >
            {{ translate('discard.button.text') }}
          </button>
        </p>
      </div>
    </bbraun-ui-form-submit-cancel-form>
  </div>
</ng-container>
