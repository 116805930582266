import { KeyType, MergeResult, References } from '../merge-immutable.type';

export function mergeSet<T>(
  base: unknown,
  mergeObj: Set<T>,
  _references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
): MergeResult<Set<T>> {
  if (areBaseAndMergeObjEqual(base, mergeObj)) {
    return {
      hasError: false as const,
      result: base as Set<T>,
      paths: path ? [] : undefined,
      diffs: diff ? [] : undefined,
    };
  } else {
    return {
      hasError: false as const,
      result: mergeObj,
      paths: path ? [] : undefined,
      diffs: diff ? [] : undefined,
    };
  }
}

function areBaseAndMergeObjEqual<T>(base: unknown, mergeObj: Set<T>): boolean {
  return base instanceof Set
    ? base.size === mergeObj.size &&
        [...mergeObj].every((entry) => base.has(entry))
    : false;
}
