import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_INTEGER_ID } from '../../../../validation/factories/is-number-integer-validator-factory';

export function createSumOfNewPatientsProperty(): Property {
  return {
    title: 'TOTAL NEW',
    technicalName: 'census_d24',
    dataType: 'calculated',
    unit: null,
    highlighted: false,
    subName: null,
    validation: [
      { validator: IS_NUMBER_INTEGER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
    ],
    formula:
      'ownPatientNewAdmission + ownPatientNewBBraunAvitumCenter + ownPatientNewOtherCenter + ownPatientNewBackTransp + ownPatientNewPdToHd',
  };
}
