import {
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  Optional,
} from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  blockingOperationHandler,
  onSubscription,
} from '@bbraun/shared/util-rxjs';
import { EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LoginServiceConnector } from '../interfaces/login-service-connector';
import { LOGIN_SERVICE_CONNECTOR_TOKEN } from './injection-tokens';
import { TokenService } from './token.service';

const INITIAL_ACCESS_TOKEN_TOKEN = new InjectionToken<Token>(
  'INITIAL_ACCESS_TOKEN_TOKEN',
);

export interface Token {
  token: string;
  decoded?: { [key: string]: unknown };
}

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService implements OnDestroy {
  readonly token$: Observable<Token | undefined>;

  private readonly subscriptions: Subscription = new Subscription();
  private readonly jwtHelper: Pick<
    JwtHelperService,
    'isTokenExpired' | 'decodeToken'
  >;

  private latestTokenResponse?: { refreshToken: string; token: Token };
  private readonly requestTokenSubject = new Subject<void>();
  private readonly tokenRequestOperationHandler = blockingOperationHandler<
    Token | undefined
  >(
    {},
    {
      strategy: 'concat',
      autoConnectResult: false,
      source: this.requestTokenSubject.pipe(
        map(() => () => {
          const refreshToken = this.tokenService.getToken();
          const token =
            this.latestTokenResponse &&
            this.latestTokenResponse.refreshToken === refreshToken
              ? this.latestTokenResponse.token
              : undefined;

          const isValid =
            token && token.token && !this.jwtHelper.isTokenExpired(token.token);

          return !isValid
            ? this.connector
                .refresh(refreshToken)
                .pipe(first())
                .pipe(
                  tap((result) =>
                    this.tokenService.setToken(
                      (result.success && result.refreshToken) || undefined,
                    ),
                  ),
                )
                .pipe(
                  map((result) => {
                    if (result.success) {
                      const updatedToken =
                        token && token.token === result.token
                          ? token
                          : result.token
                          ? {
                              token: result.token,
                              decoded:
                                result.token &&
                                this.jwtHelper.decodeToken(result.token),
                            }
                          : undefined;
                      this.latestTokenResponse =
                        (result.refreshToken &&
                          updatedToken && {
                            refreshToken: result.refreshToken,
                            token: updatedToken,
                          }) ||
                        undefined;
                      return updatedToken;
                    } else {
                      this.latestTokenResponse = undefined;
                      return undefined;
                    }
                  }),
                )
            : of(token);
        }),
      ),
    },
  );

  constructor(
    @Inject(LOGIN_SERVICE_CONNECTOR_TOKEN)
    private readonly connector: Pick<LoginServiceConnector, 'refresh'>,
    @Inject(TokenService)
    private readonly tokenService: Pick<TokenService, 'getToken' | 'setToken'>,
    @Optional()
    @Inject(JwtHelperService)
    jwtHelper?: Pick<JwtHelperService, 'isTokenExpired' | 'decodeToken'> | null,
    @Optional()
    @Inject(INITIAL_ACCESS_TOKEN_TOKEN)
    initialToken?: { refreshToken: string; token: Token } | null,
  ) {
    this.latestTokenResponse = initialToken || undefined;
    this.jwtHelper = jwtHelper || new JwtHelperService();

    // always keep a subscription alive, this implies that we are finishing token request even if there is no other subscriber
    this.subscriptions.add(
      this.tokenRequestOperationHandler.results.subscribe(),
    );

    this.token$ = this.tokenRequestOperationHandler.results
      .pipe(switchMap((result) => (result.hasValue ? of(result.value) : EMPTY)))
      .pipe(distinctUntilChanged())
      .pipe(
        onSubscription({
          afterSubscribe: () => this.requestTokenSubject.next(),
        }),
      );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
