import { HttpClient, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AccessTokenService } from '@bbraun/shared/data-access-security';
import { TypedJsonApiService } from '@bbraun/shared/util-jsonapi';
import {
  InterceptingHandler,
  TypedJsonApiNgService,
} from '@bbraun/shared/util-jsonapi-ng';
import {
  expertLoaderJsonApiTypesProperties,
  expertLoaderMeta,
} from './expert-loader-json-api-types.json-api.generated';
import { ExpertLoaderJsonApi } from './expert-loader-json-api.type';

export function createTypedJsonApiService(
  baseUrl: string,
  accessTokenService: AccessTokenService,
  httpHandler: HttpHandler,
  httpInterceptors: HttpInterceptor[],
): TypedJsonApiService<ExpertLoaderJsonApi> {
  const httpClient = new HttpClient(
    new InterceptingHandler(httpHandler, httpInterceptors),
  );

  return new TypedJsonApiNgService<ExpertLoaderJsonApi>(
    expertLoaderMeta,
    expertLoaderJsonApiTypesProperties,
    accessTokenService,
    () => httpClient,
    baseUrl,
  );
}
