export function createFullUrl(
  baseUrl: string,
  relativeUrl: string,
  options?:
    | {
        errorCode?: number;
        errorCodes?: ReadonlyArray<number>;
      }
    | {
        errorCode?: number;
        errorCodes?: ReadonlyArray<number>;
        refreshToken: string;
        refreshTokenName: string;
      },
): string;
export function createFullUrl(
  baseUrl: string,
  relativeUrl: string,
  {
    errorCode,
    errorCodes,
    refreshToken,
    refreshTokenName,
  }: {
    refreshToken?: string;
    refreshTokenName?: string;
    errorCode?: number;
    errorCodes?: ReadonlyArray<number>;
  } = {},
): string {
  const url = relativeUrl.trim().startsWith('/')
    ? relativeUrl.trim().substring(1)
    : relativeUrl.trim();
  const completeErrorCodes = [
    ...(errorCode !== undefined ? [errorCode] : []),
    ...(errorCodes || []),
  ];
  const searchParamsArray: string[][] = [
    ...completeErrorCodes.map((code) => ['error', code.toString(10)]),
    ...(refreshToken && refreshTokenName
      ? [[refreshTokenName, refreshToken]]
      : []),
  ];

  const params =
    searchParamsArray.length > 0 && new URLSearchParams(searchParamsArray);
  const fullUrl = `${[baseUrl, url].join('/')}${params ? `?${params}` : ``}`;
  return fullUrl;
}
