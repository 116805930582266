import { HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import {
  AccessTokenService,
  RefreshTokenInterceptor,
  UnauthorizedInterceptor,
} from '@bbraun/shared/data-access-security';
import { API_BASE_URL } from '@bbraun/shared/util-jsonapi-ng';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { BAV_REPORTING_API_SERVICE } from './injection-tokens';
import { createTypedJsonApiService } from './provider-factories';
import { DefaultCenterResolver } from './resolvers/default-center.resolver';
import { DefaultCenterService } from './services/default-center.service';

export const SCOPE = 'bbraunBavReportingDataAccessAisReports';

@NgModule({
  imports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: BAV_REPORTING_API_SERVICE,
      useFactory: createTypedJsonApiService,
      deps: [API_BASE_URL, AccessTokenService, HttpBackend, HTTP_INTERCEPTORS],
    },
    DefaultCenterResolver,
    DefaultCenterService,
  ],
})
export class BavReportingDataAccessAisReports {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );
  }
}
