import { Inject, Injectable } from '@angular/core';
import {
  PublicRoutes,
  SECURITY_PUBLIC_ROUTES,
} from '../interfaces/public-routes';
import { SecurityConfig, SECURITY_CONFIG } from '../interfaces/security-config';

@Injectable({ providedIn: 'root' })
export class IsWhitelistedRouteService {
  private readonly whitelistedRoutes: string[];

  constructor(
    @Inject(SECURITY_CONFIG)
    config: {
      routes: Pick<SecurityConfig['routes'], 'start' | 'login'>;
    },
    @Inject(SECURITY_PUBLIC_ROUTES) publicRoutes: PublicRoutes[],
  ) {
    const { start, login } = config.routes;
    const specialRoutes = [start, login].map((value) => stripSlash(value));
    const normalizedPublicRoutes = publicRoutes
      .reduce((prev, cur: PublicRoutes) => {
        if (cur !== undefined) {
          prev = prev.concat(
            cur.routes.filter(
              (url) => url !== undefined && prev.indexOf(url) === -1,
            ),
          );
        }
        return prev;
      }, [] as string[])
      .map(stripSlash);

    // all whitelisted routes
    this.whitelistedRoutes = normalizedPublicRoutes.concat(specialRoutes);
  }

  isWhitelistedRoute(route: string) {
    return this.whitelistedRoutes.indexOf(stripSlash(route)) !== -1;
  }
}

function stripSlash(input: string) {
  if (input.indexOf('/') === 0) {
    return input.substring(1);
  } else {
    return input;
  }
}
