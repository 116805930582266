import { MessageService } from '@bbraun/shared/util-message-ng';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

const unsupportedLocaleMessageId = Symbol(
  'bbraunSharedDataAccessLocale.module.unsupportedLocale',
);

const scope = 'bbraunSharedDataAccessLocale';

export class ExtractTranslocoKey {
  constructor(public readonly and: TranslocoService) {}
}

export function sendUnsupportedLocaleMessage(
  lang: string,
  error: unknown,
  messageService: MessageService,
  translocoService: TranslocoService,
) {
  messageService.message(
    (values) =>
      translocoService
        .load(`${scope}/${lang}`)
        .pipe(take(1))
        .pipe(map(() => lang))
        .pipe(catchError(() => of(lang)))
        .pipe(
          map((messageLang) => ({
            title: new ExtractTranslocoKey(translocoService).and.translate(
              'bbraunSharedDataAccessLocale.module.unsupportedLocale.title',
              undefined,
              messageLang,
            ),
            message: new ExtractTranslocoKey(translocoService).and.translate(
              'bbraunSharedDataAccessLocale.module.unsupportedLocale.text',
              values,
              messageLang,
            ),
          })),
        ),
    'warning',
    {
      id: unsupportedLocaleMessageId,
      actions: [{ type: 'push-or-count' }],
      timeOut: false,
      error,
      enableHtml: true,
      values: { locale: lang },
    },
  );
}
