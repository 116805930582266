import { DatePipe, DecimalPipe, Location } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Route, RouterModule } from '@angular/router';
import {
  APP_DEFAULT_LOCALE,
  APP_STATE_PERSISTENCE,
  DataAccessLocaleModule,
  LANGUAGE_SELECTION_DISABLED,
  LocalStorageStatePersistenceStrategy,
  SUPPORTED_LOCALES,
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import {
  AUTHENTICATION_SERVICE_BASE_URL_TOKEN,
  AuthGuard,
  DataAccessSecurityModule,
  LOGIN_SERVICE_CONNECTOR_TOKEN,
  PRINCIPAL_PROVIDER_TOKEN,
  REFRESH_TOKEN_CONFIGURATION_TOKEN,
  SECURITY_CONFIG,
  SECURITY_PUBLIC_ROUTES,
  SsoLoginGuard,
  StartGuard,
} from '@bbraun/shared/data-access-security';
import { UiAppModule } from '@bbraun/shared/ui-app';
import {
  LanguageComponent,
  LOCALIZED_DATE_PIPE_ERROR_HANDLER,
  UI_LANGUAGE_DISPLAY_TIMEZONE,
} from '@bbraun/shared/ui-language';
import { LoginComponent, UiLoginModule } from '@bbraun/shared/ui-login';
import { UiSidebarModule } from '@bbraun/shared/ui-sidebar';
import { UiToolbarModule } from '@bbraun/shared/ui-toolbar';
import { UtilErrorNgModule } from '@bbraun/shared/util-error-ng';
import { IS_PRODUCTION } from '@bbraun/shared/util-lang-ng';
import {
  LocationService,
  UtilNavigationModule,
} from '@bbraun/shared/util-navigation';
import {
  I18N_CONFIGURATION,
  UtilTranslocoModule,
} from '@bbraun/shared/util-transloco-ng';
import {
  TranslocoMessageServiceFactory,
  UtilTranslocoMessageModule,
} from '@bbraun/shared/util-transloco-message';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { defer, of } from 'rxjs';
import { API_BASE_URL, JsonApiNgService } from '@bbraun/shared/util-jsonapi-ng';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { TranslocoModule } from '@ngneat/transloco';
import { createMessageService } from '@bbraun/shared/util-devexpress';
import {
  BavReportingFeatureAisReportsModule,
  CreateReportComponent,
  FIXED_CURRENT_DATE,
  EditReportComponent,
  ReportListComponent,
  ReportListResolver,
  EditReportResolver,
} from '@bbraun/bav-reporting/feature-ais-reports';
import {
  BavReportingDataAccessAisReports,
  BAV_REPORTING_API_SERVICE,
  CanCreateAisReportsGuard,
  CanReadAisReportsGuard,
  CanReadUserLogsGuard,
  DefaultCenterResolver,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import {
  BavReportingUiCommonModule,
  StartComponent,
} from '@bbraun/bav-reporting/ui-common';
import {
  BavReportingFeatureAdministrationModule,
  LabDataTestConfigurationComponent,
  UserLogsComponent,
} from '@bbraun/bav-reporting/feature-administration';
import { DxLoadPanelModule } from 'devextreme-angular';
import {
  BBraunUiDiscardChangesModule,
  DiscardChangesGuard,
} from '@bbraun/shared/ui-discard-changes';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconRegistry } from '@angular/material/icon';
import {
  BavReportingDataAccessAdministrationModule,
  CanReadLabTestMappingGuard,
  EXPERT_LOADER_API_BASE_URL,
} from '@bbraun/bav-reporting/data-access-administration';
import fallbackTranslation from '../i18n/en.json';
import { AppComponent } from './app.component';
import {
  BAV_REPORTING_CONFIG,
  DEFAULT_ROUTE,
  ENVIRONMENT,
  LOGIN_GUARD,
  LOGIN_ROUTE,
  START_ROUTE,
} from './injection-token';
import {
  createAuthenticationServiceBaseUrlForEnvironment,
  createApiBaseUrlForEnvironment,
  createDefaultLocale,
  createI18nConfiguration,
  createIsProduction,
  createLanguageSelectionDisabledFlag,
  createLoginGuardForEnvironment,
  createLoginServiceConnectorForEnvironment,
  createPrincipalProvider,
  createRefreshTokenConfiguration,
  createSecurityModuleConfigForEnvironment,
  createSupportedLocales,
  createDisplayTimeZone,
  createDateParseErrorHandler,
  createFixedCurrentDate,
  createBavReportingConfig,
  createExpertLoaderApiBaseUrlForEnvironment,
} from './provider-factories';
import { DATE_PARSE_ERROR_HANDLER, MESSAGE_SERVICE } from './injection-token';

const startRoute = 'app/start';
const defaultRoute = 'app/reports';
const loginRoute = 'app/login';

const coreRoutes: Route[] = [
  { path: startRoute, component: StartComponent, canActivate: [StartGuard] },
  {
    path: loginRoute,
    component: LoginComponent,
    canActivate: [LOGIN_GUARD],
    data: {
      replaceUrl: true,
    },
  },
  {
    path: 'app',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'language',
        component: LanguageComponent,
      },
      {
        path: 'reports',
        component: ReportListComponent,
        resolve: {
          reports: ReportListResolver,
        },
      },
      {
        path: 'reports/create',
        component: CreateReportComponent,
        canActivate: [CanCreateAisReportsGuard],
        canDeactivate: [DiscardChangesGuard],
        resolve: {
          center: DefaultCenterResolver,
        },
        data: {
          replaceUrl: true,
        },
      },
      {
        path: 'reports/:id',
        component: EditReportComponent,
        resolve: {
          editReportData: EditReportResolver,
        },
        canActivate: [CanReadAisReportsGuard],
        canDeactivate: [DiscardChangesGuard],
      },
      {
        path: 'administration/lab-data-test-configuration',
        component: LabDataTestConfigurationComponent,
        canActivate: [CanReadLabTestMappingGuard],
        canDeactivate: [DiscardChangesGuard],
      },
      {
        path: 'administration/user-logs',
        component: UserLogsComponent,
        canActivate: [CanReadUserLogsGuard],
      },
      { path: '', pathMatch: 'full', redirectTo: startRoute },
      { path: '**', redirectTo: startRoute },
    ],
  },
  { path: '', pathMatch: 'full', redirectTo: startRoute },
  { path: '**', redirectTo: startRoute },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BavReportingDataAccessAisReports,
    BavReportingDataAccessAdministrationModule,
    UiToolbarModule,
    UiLoginModule,
    HttpClientModule,
    UiSidebarModule,
    RouterModule.forRoot(coreRoutes, {
      anchorScrolling: 'enabled',
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
    ToastrModule.forRoot({
      maxOpened: 6,
      newestOnTop: true,
      closeButton: true,
      positionClass: 'toast-bottom-right',
    }),
    StoreModule.forRoot(
      { router: routerReducer },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    EffectsModule.forRoot([]),
    DataAccessSecurityModule,
    DataAccessLocaleModule,
    UtilTranslocoModule,
    UtilTranslocoMessageModule,
    UiAppModule,
    TranslocoModule,
    UtilErrorNgModule,
    UtilNavigationModule,
    BavReportingFeatureAisReportsModule,
    BavReportingFeatureAdministrationModule,
    BavReportingUiCommonModule,
    DxLoadPanelModule,
    BBraunUiDiscardChangesModule,
    MatExpansionModule,
  ],
  providers: [
    { provide: DEFAULT_ROUTE, useValue: defaultRoute },
    { provide: START_ROUTE, useValue: startRoute },
    { provide: LOGIN_ROUTE, useValue: loginRoute },
    {
      provide: DATE_PARSE_ERROR_HANDLER,
      useFactory: createDateParseErrorHandler,
      deps: [MessageService],
    },
    {
      provide: LOCALIZED_DATE_PIPE_ERROR_HANDLER,
      useExisting: DATE_PARSE_ERROR_HANDLER,
    },
    {
      provide: MESSAGE_SERVICE,
      useFactory: createMessageService,
      deps: [TranslocoMessageServiceFactory],
    },
    {
      provide: LOGIN_SERVICE_CONNECTOR_TOKEN,
      useFactory: createLoginServiceConnectorForEnvironment,
      deps: [
        ENVIRONMENT,
        HttpClient,
        AUTHENTICATION_SERVICE_BASE_URL_TOKEN,
        SECURITY_CONFIG,
      ],
    },
    {
      provide: API_BASE_URL,
      useFactory: createApiBaseUrlForEnvironment,
      deps: [ENVIRONMENT],
    },
    {
      provide: EXPERT_LOADER_API_BASE_URL,
      useFactory: createExpertLoaderApiBaseUrlForEnvironment,
      deps: [ENVIRONMENT],
    },
    {
      provide: AUTHENTICATION_SERVICE_BASE_URL_TOKEN,
      useFactory: createAuthenticationServiceBaseUrlForEnvironment,
      deps: [ENVIRONMENT],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill', float: 'always' },
    },
    {
      provide: LANGUAGE_SELECTION_DISABLED,
      useFactory: createLanguageSelectionDisabledFlag,
      deps: [ENVIRONMENT],
    },
    {
      provide: SECURITY_PUBLIC_ROUTES,
      useValue: { routes: ['app/language', startRoute, loginRoute] },
      multi: true,
    },
    {
      provide: Location,
      useExisting: LocationService,
    },
    {
      provide: JsonApiNgService,
      useExisting: BAV_REPORTING_API_SERVICE,
    },
    {
      provide: LOGIN_GUARD,
      useFactory: createLoginGuardForEnvironment,
      deps: [ENVIRONMENT, SsoLoginGuard, StartGuard],
    },
    {
      provide: SECURITY_CONFIG,
      useFactory: createSecurityModuleConfigForEnvironment,
      deps: [ENVIRONMENT, START_ROUTE, LOGIN_ROUTE, DEFAULT_ROUTE],
    },
    {
      provide: REFRESH_TOKEN_CONFIGURATION_TOKEN,
      useFactory: createRefreshTokenConfiguration,
      deps: [ENVIRONMENT],
    },
    {
      provide: PRINCIPAL_PROVIDER_TOKEN,
      useFactory: createPrincipalProvider,
      deps: [BAV_REPORTING_API_SERVICE],
    },
    {
      provide: I18N_CONFIGURATION,
      useFactory: createI18nConfiguration,
      deps: [ENVIRONMENT],
    },
    {
      provide: IS_PRODUCTION,
      useFactory: createIsProduction,
      deps: [ENVIRONMENT],
    },
    {
      provide: APP_DEFAULT_LOCALE,
      useFactory: createDefaultLocale,
      deps: [I18N_CONFIGURATION],
    },
    {
      provide: SUPPORTED_LOCALES,
      useFactory: createSupportedLocales,
      deps: [I18N_CONFIGURATION],
    },
    {
      provide: APP_STATE_PERSISTENCE,
      useClass: LocalStorageStatePersistenceStrategy,
    },
    {
      provide: UI_LANGUAGE_DISPLAY_TIMEZONE,
      useFactory: createDisplayTimeZone,
      deps: [ENVIRONMENT],
    },
    {
      provide: BAV_REPORTING_CONFIG,
      useFactory: createBavReportingConfig,
      deps: [ENVIRONMENT],
    },
    DatePipe,
    {
      provide: FIXED_CURRENT_DATE,
      useFactory: createFixedCurrentDate,
      deps: [BAV_REPORTING_CONFIG],
    },
    DefaultCenterResolver,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
    sanitizer: DomSanitizer,
    matIconRegistry: MatIconRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(() => of({}), {});
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      'bbraunBavReportingDesktop',
    );

    matIconRegistry.addSvgIconInNamespace(
      'bbraun',
      'lab-value',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/lab-value_v2021-01-05.svg',
      ),
    );
  }
}
