import { isPropertyEqual, pick } from '@bbraun/shared/util-lang';
import type { OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

type Out<TIn, TProperty extends keyof Exclude<TIn, undefined>> =
  | {
      [property in TProperty]: Exclude<TIn, undefined>[property];
    }
  | (TIn extends undefined
      ? undefined
      : {
          [property in TProperty]: Exclude<TIn, undefined>[property];
        });

export function pickDistinctUntilChanged<
  TIn,
  TProperty extends keyof Exclude<TIn, undefined>,
>(
  properties: ReadonlyArray<TProperty>,
): OperatorFunction<TIn, Out<TIn, TProperty>> {
  return (o) =>
    o
      .pipe(distinctUntilChanged(isPropertyEqual(properties)))
      .pipe(
        map(
          (value) =>
            value && pick(value as Exclude<TIn, undefined>, properties),
        ),
      )
      .pipe(distinctUntilChanged());
}
