export function updateSetImmutable<TValue>({
  originalSet,
  action,
  value,
}: {
  originalSet: ReadonlySet<TValue>;
  action: 'add' | 'delete';
  value: TValue;
}): ReadonlySet<TValue> {
  const modifiedSet = new Set(originalSet);

  if (action === 'add' && !originalSet.has(value)) {
    modifiedSet.add(value);
    return modifiedSet;
  } else if (action === 'delete' && originalSet.has(value)) {
    modifiedSet.delete(value);
    return modifiedSet;
  } else {
    return originalSet;
  }
}
