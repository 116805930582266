import { Injectable, Injector } from '@angular/core';
import { TranslationServiceConnector } from '@bbraun/shared/data-access-locale';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoTranslationServiceConnectorService
  implements TranslationServiceConnector
{
  constructor(private readonly injector: Injector) {}

  getBrowserLang() {
    return getBrowserLang();
  }

  use(lang: string) {
    // TODO kdraba: for some reasons unknown to me the LocaleService does not get injected with an TranslocoTranslationServiceConnector
    // as soon as this service depends on the TranslocoService and the TranslocoLoaderService depends on the MessageService
    return this.injector
      .get<TranslocoService>(TranslocoService)
      .setActiveLang(lang);
  }
}
