import { showStaticContent } from './show-static-content';

export function handleMissingConfiguration(rootSelector: string) {
  showStaticContent(rootSelector);
  const loadingIndicatorElement = document.getElementById(
    'bbraun-loading-indicator',
  );
  if (loadingIndicatorElement) {
    loadingIndicatorElement.style.display = 'none';
  } else {
    console.warn('Could not find loading indicator element');
  }
}
