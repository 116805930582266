import { Location, LocationStrategy, PlatformLocation } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LocationService extends Location {
  constructor(
    platformStrategy: LocationStrategy,
    platformLocation: PlatformLocation,
    private readonly injector: Injector,
  ) {
    super(platformStrategy, platformLocation);
  }

  hasPreviousNavigation() {
    return history.state && history.state.previousNavigation;
  }

  forward() {
    history.forward();
  }

  back() {
    if (this.hasPreviousNavigation()) {
      super.back();
    } else {
      const router = this.injector.get<Router>(Router);
      router.navigate(['/']);
    }
  }

  // this one is implemented in future versions of angular: https://angular.io/api/common/Location#getState
  getState() {
    return history.state || undefined;
  }
}
