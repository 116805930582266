import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { INTERRUPT_LOADING_SIGNAL } from '@bbraun/shared/ui-app';
import { onSubscription } from '@bbraun/shared/util-rxjs';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { DiscardChangesDialogComponent } from '../components/discard-changes-dialog/discard-changes-dialog.component';
import { DiscardChangesTranslationService } from './discard-changes-translation.service';

export const DISCARD_MESSAGE = Symbol('DISCARD_MESSAGE');

export interface WithIsChangedSupportComponent {
  readonly changed: boolean;
  readonly markClean: () => void;
}

export interface IsChanged {
  readonly changed: boolean;
  readonly [DISCARD_MESSAGE]?: string;
  discardChanges?(): void;
}

const discarded = new WeakSet<IsChanged>();

@Injectable()
export class DiscardChangesGuard implements CanDeactivate<IsChanged> {
  constructor(
    @Inject(INTERRUPT_LOADING_SIGNAL)
    public interruptLoadingSignal$: Subject<boolean>,
    private readonly matDialog: MatDialog,
    private readonly scopedTranslationService: DiscardChangesTranslationService,
  ) {}

  canDiscardForm(component: IsChanged): Observable<boolean> {
    return this.scopedTranslationService
      .translate(
        i18n(
          'bbraunSharedUiDiscardChanges.discardChangesGuard.discardForm.text',
        ),
      )
      .pipe(
        switchMap((localizedText) =>
          this.canDeactivate({
            ...component,
            [DISCARD_MESSAGE]: localizedText,
          }),
        ),
      );
  }

  canLeaveEditMode(component: IsChanged): Observable<boolean> {
    return this.scopedTranslationService
      .translate(
        i18n(
          'bbraunSharedUiDiscardChanges.discardChangesGuard.discardEditMode.text',
        ),
      )
      .pipe(
        switchMap((localizedText) =>
          this.canDeactivate({
            ...component,
            [DISCARD_MESSAGE]: localizedText,
          }),
        ),
      );
  }

  canDiscardFormOrLeaveEditMode(
    component: IsChanged,
    type: 'form' | 'editMode',
  ): Observable<boolean> {
    return type === 'form'
      ? this.canDiscardForm(component)
      : this.canLeaveEditMode(component);
  }

  canDeactivate(component: IsChanged): Observable<boolean> {
    const changed$ = of(component.changed);
    return changed$.pipe(
      take(1),
      switchMap((isChanged) => {
        if (isChanged && !discarded.has(component)) {
          this.interruptLoadingSignal$.next(true);
          return this.confirmClose(component[DISCARD_MESSAGE]).pipe(
            take(1),
            map((result: { continue: boolean }) => result && result.continue),
            tap({
              next: (discard) => {
                if (discard) {
                  if (component.discardChanges) {
                    component.discardChanges();
                  } else {
                    // TODO kdraba BCORE-358: quick fix - prevent discard changes dialog from occuring multiple times
                    // in case of a redirect during navigation. In the future all components that implement
                    // isChanged should also implement discardChanges
                    discarded.add(component);
                  }
                }

                this.interruptLoadingSignal$.next(false);
              },
              error: () => this.interruptLoadingSignal$.next(false),
              complete: () => this.interruptLoadingSignal$.next(false),
            }),
          );
        } else {
          return of(true);
        }
      }),
    );
  }

  private confirmClose(
    discardMessage: string | undefined,
  ): Observable<{ continue: boolean }> {
    const dialogRef = this.matDialog.open(DiscardChangesDialogComponent, {
      width: '400px',
      height: '210px',
      disableClose: true,
      data: {
        discardMessage,
      },
    });

    return dialogRef
      .afterClosed()
      .pipe(onSubscription({ onUnsubscribe: () => dialogRef.close() }));
  }
}
