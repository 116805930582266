export type ComparatorConfig<T> = {
  [key in keyof T]: (value1: unknown, value2: unknown) => boolean;
};

export function isPropertyEqual<T>(
  properties: ReadonlyArray<keyof Exclude<T, undefined>>,
  comparator: (value1: unknown, value2: unknown) => boolean = (
    value1,
    value2,
  ) => value1 === value2,
): (value1: T, value2: T) => boolean {
  return (value1, value2) =>
    arePropertiesEqual(value1, value2, properties, comparator);
}

export function arePropertiesEqual<T>(
  value1: T,
  value2: T,
  comparatorConfig: ComparatorConfig<Exclude<T, undefined>>,
): boolean;
export function arePropertiesEqual<T>(
  value1: T,
  value2: T,
  properties: ReadonlyArray<keyof Exclude<T, undefined>>,
  comparator?: (
    comparatorValue1: unknown,
    comparatorValue2: unknown,
  ) => boolean,
): boolean;
export function arePropertiesEqual<T>(
  value1: T,
  value2: T,
  propertiesOrComparatorConfig:
    | ReadonlyArray<keyof Exclude<T, undefined>>
    | ComparatorConfig<Exclude<T, undefined>>,
  comparatorOrUndefined?: (
    comparatorValue1: unknown,
    comparatorValue2: unknown,
  ) => boolean,
): boolean {
  const properties: ReadonlyArray<keyof Exclude<T, undefined>> = Array.isArray(
    propertiesOrComparatorConfig,
  )
    ? propertiesOrComparatorConfig
    : Object.keys(propertiesOrComparatorConfig);

  const comparator = Array.isArray(propertiesOrComparatorConfig)
    ? comparatorOrUndefined || ((v1, v2) => v1 === v2)
    : (v1: any, v2: any, key: keyof Exclude<T, undefined>) =>
        (
          propertiesOrComparatorConfig as ComparatorConfig<
            Exclude<T, undefined>
          >
        )[key](v1, v2);

  return (
    value1 === value2 ||
    (isDefined(value1) &&
      isDefined(value2) &&
      properties.every((property) =>
        comparator(value1[property], value2[property], property),
      ))
  );
}

function isDefined<T>(value: T): value is Exclude<T, undefined> {
  return value !== undefined;
}
