import { Injectable } from '@angular/core';
import { mapToVoid } from '@bbraun/shared/util-rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalErrorHandler } from './global-error-handler';
import { OnNavigationEndSearchParamErrorService } from './on-navigation-end-search-param-error.service';

@Injectable({ providedIn: 'root' })
export class OnNavigationEndSearchParamErrorHandlerService {
  readonly connect$: Observable<void>;

  constructor(
    onNavigationEndSearchParamErrorService: OnNavigationEndSearchParamErrorService,
    errorHandler: GlobalErrorHandler,
  ) {
    this.connect$ = onNavigationEndSearchParamErrorService.error$
      .pipe(
        tap((code) =>
          errorHandler.onError(
            { code },
            { timeOut: true, noGenericError: true },
          ),
        ),
      )
      .pipe(mapToVoid());
  }
}
