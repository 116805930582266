import { DxFilterOperator, isDxFilterOperator } from './dx-filter-operator';

export type DxFilterOperation = readonly [string, DxFilterOperator, any];

export function isDxFilterOperation(
  element: any,
): element is DxFilterOperation {
  if (!Array.isArray(element)) {
    return false;
  }

  if (element.length !== 3) {
    return false;
  }

  const [property, operator, value] = element;
  return (
    typeof property === 'string' &&
    isDxFilterOperator(operator) &&
    value !== undefined &&
    value !== null
  );
}
