import { Inject, Injectable } from '@angular/core';
import {
  DxFormDataHandler,
  resolveValidationModeFunction,
} from '@bbraun/shared/util-devexpress';
import { map, Observable } from 'rxjs';
import { REPORT_EDITOR_PROPERTY_VALIDATION_LOOKUP_SERVICE } from '../../../injection-tokens';
import { PropertyValidationLookupService } from '../../../types/property-validation-lookup-service.type';
import { Property } from '../../../types/report-model.types';
import { ReportEditorFormModel } from '../types/report-editor-form-model.type';

@Injectable({
  providedIn: 'root',
})
export class FormDataHandlerFactory {
  constructor(
    @Inject(REPORT_EDITOR_PROPERTY_VALIDATION_LOOKUP_SERVICE)
    private readonly propertyValidationLookupService: PropertyValidationLookupService,
  ) {}

  create(
    defaultValues: ReportEditorFormModel,
    properties: ReadonlyArray<Property>,
  ): Observable<DxFormDataHandler<ReportEditorFormModel>> {
    return this.propertyValidationLookupService.validatorLookup.pipe(
      map((validationLookup) => {
        const validationValues = properties.reduce(
          (acc, { technicalName, validation }) => {
            if (validation) {
              return {
                ...acc,
                [technicalName]: validation.map((currentValidation) =>
                  validationLookup[currentValidation.validator](
                    currentValidation.options,
                  ),
                ),
              };
            } else {
              return acc;
            }
          },
          {},
        );

        const formDataHandler = new DxFormDataHandler<ReportEditorFormModel>(
          defaultValues,
          [],
          [],
          {
            object: [],
            properties: validationValues,
          },
          {
            validationMode: resolveValidationModeFunction('all'),
          },
        );

        return formDataHandler;
      }),
    );
  }
}
