import { InjectionToken } from '@angular/core';

export const IS_PRODUCTION = new InjectionToken<{ production: boolean }>(
  'IS_PRODUCTION',
);

export const UTIL_LANG_NG_AS_TYPE_PIPE_CONVERSION_ERROR = new InjectionToken(
  'UTIL_LANG_NG_AS_TYPE_PIPE_CONVERSION_ERROR',
);

export const UTIL_LANG_NG_AS_TYPE_PIPE_SKIP_TYPE_CHECK = new InjectionToken(
  'UTIL_LANG_NG_AS_TYPE_PIPE_SKIP_TYPE_CHECK',
);
