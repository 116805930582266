type DefinedKeys<T extends { [key: string]: any | undefined }> = {
  [key in keyof T]: T[key] extends undefined
    ? never
    : Exclude<T[key], undefined>;
};

export function removeUndefineds<
  T extends { [attributeName: string]: any | undefined },
>(value: T): DefinedKeys<T> | undefined {
  const initial: Partial<DefinedKeys<T>> = {};
  const result = Object.entries(value).filter((prop) => prop !== undefined);
  return result.length > 0
    ? (result.reduce(
        (
          acc,
          [key, v]: [
            keyof DefinedKeys<T>,
            DefinedKeys<T>[keyof DefinedKeys<T>],
          ],
        ) => {
          acc[key] = v;
          return acc;
        },
        initial,
      ) as DefinedKeys<T>)
    : undefined;
}

export function removeUndefinedsFromArray<T>(
  array: ReadonlyArray<T | undefined>,
): Array<T> | undefined {
  const result = array.filter((value): value is T => value !== undefined);
  return result.length > 0 ? result : undefined;
}
