import { InjectionToken } from '@angular/core';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';
import { ValidatorFactory } from '@bbraun/bav-reporting/ui-report-editor';
import { YearAndMonth } from './types/year-and-month.type';

export const BAV_REPORTING_FEATURE_AIS_REPORTS_TRANSLOCO_MESSAGE_SERVICE =
  new InjectionToken<TranslocoMessageService>(
    'BAV_REPORTING_FEATURE_AIS_REPORTS_TRANSLOCO_MESSAGE_SERVICE',
  );

export const FIXED_CURRENT_DATE = new InjectionToken<YearAndMonth>(
  'FIXED_CURRENT_DATE',
);

export const REPORT_MODEL_VALIDATORS = new InjectionToken<
  ValidatorFactory<unknown>
>('REPORT_MODEL_VALIDATORS');
