import { TypeMeta, UpdateObjectType } from './api-type-meta.type';
import { ApiTypes } from './api-types';
import { mapToCreateParameters } from './create-map-to-create-parameters';
import { JsonApiResourceMeta } from './typed-json-api.types';

function mapToUpdateParameters<
  TTypes extends ApiTypes,
  TTypeMeta extends TypeMeta<any>,
  TTypeName extends keyof TTypeMeta,
>(
  id: string,
  v: UpdateObjectType<TTypeMeta, TTypeName>,
  attributes: ReadonlyArray<
    keyof Exclude<TTypeMeta[TTypeName]['update']['attributes'], undefined>
  >,
  relationships:
    | Record<
        keyof Exclude<
          TTypeMeta[TTypeName]['create']['relationships'],
          undefined
        >,
        keyof TTypes
      >
    | undefined,
): { id: string } & TTypeMeta[TTypeName]['update'] {
  return { id, ...mapToCreateParameters(v, attributes, relationships) };
}

export function createMapToUpdateParameters<
  TTypes extends ApiTypes,
  TTypeName extends keyof TTypes & string,
  TTypeMeta extends TypeMeta<keyof TTypes> = never,
>(type: TTypeName, meta: JsonApiResourceMeta<TTypes, TTypeMeta>) {
  return (id: string, value: UpdateObjectType<TTypeMeta, TTypeName>) =>
    mapToUpdateParameters(
      id,
      value,
      meta[type].update?.attributes || [],
      meta[type].update?.relationships,
    );
}
