import { MessageService } from '@bbraun/shared/util-message-ng';
import { blockingOperationHandler } from '@bbraun/shared/util-rxjs';
import { translate } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { ReportInfo } from '../../types/types';

export const EXPORT_ERROR_ID = Symbol('exportErrorId');

export function createExportXmlHandler(messageService: MessageService) {
  return blockingOperationHandler<{
    exportData: string;
    reportInfo: ReportInfo;
  }>(
    {
      error: (error) => {
        messageService.message(
          translate(
            i18n(
              'bbraunBavReportingFeatureAisReports.reportDetailsComponent.exportError.message',
            ),
          ),
          'error',
          {
            id: EXPORT_ERROR_ID,
            error,
            timeOut: false,
          },
        );
      },
    },
    { autoConnectResult: false, strategy: 'exhaust' },
  );
}
