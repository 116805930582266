import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { combine } from '../combine';

export function or(
  observables: ReadonlyArray<Observable<boolean>>,
): Observable<boolean> {
  return combine(observables).pipe(
    map(({ values }) => values.some((value) => value)),
  );
}
