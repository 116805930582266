import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AccessTokenService } from '@bbraun/shared/data-access-security';
import {
  JsonApiService as JsonApiServiceBase,
  JsonApiMeta,
  ApiDefinition,
  ApiTypes,
  LinkObject,
} from '@bbraun/shared/util-jsonapi';
import { createHttpClientConnector } from './jsonapi-http-client-connector';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const API_META = new InjectionToken<string>('API_META');

@Injectable({
  providedIn: 'root',
})
export class JsonApiNgService<
  TApiDefinition extends ApiDefinition<ApiTypes> & {
    index: {
      meta: { features?: ReadonlyArray<string> };
      links: Partial<{
        [key: string]: LinkObject;
      }>;
    };
  },
> extends JsonApiServiceBase<TApiDefinition> {
  constructor(
    httpClient: HttpClient,
    accessTokenService: AccessTokenService,
    @Inject(API_BASE_URL) baseUrl: string,
    @Optional() @Inject(API_META) apiMeta: JsonApiMeta | null,
  ) {
    super(
      createHttpClientConnector(httpClient),
      accessTokenService,
      baseUrl,
      apiMeta || undefined,
    );
  }
}
