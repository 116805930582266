import { concat, EMPTY, merge, Observable, of, defer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BufferSubject } from '../observable/buffer-subject';
import { onSubscription } from '../operators/on-subscription';
import { StoreEvent, StoreEventConfiguration } from './simple-store.type';

export class SimpleStoreService<
  TState,
  TStoreEventConfiguration extends StoreEventConfiguration<TState, unknown>,
> {
  readonly values: Observable<TState>;
  private readonly subject = new BufferSubject<
    StoreEvent<TStoreEventConfiguration>
  >(Number.MAX_SAFE_INTEGER);

  constructor(
    readonly initialState: TState,
    readonly configuration: TStoreEventConfiguration,
    readonly eventSource: Observable<
      StoreEvent<TStoreEventConfiguration>
    > = EMPTY,
    readonly tearDownLogic: (state: TState) => TState = (s) => s,
  ) {
    let state = initialState;

    this.values = concat(
      defer(() => of(state)),
      merge(this.subject, this.eventSource).pipe(
        map((event) => {
          const handler = this.configuration[event.type];

          if (handler && typeof handler === 'function') {
            state = handler(event.params, state);
            return state;
          } else {
            throw new Error(
              `No event handler registered for event type <${
                event.type
              }>. Registered Events are: [${Object.keys(
                this.configuration,
              ).join(', ')}]`,
            );
          }
        }),
      ),
    )
      .pipe(
        onSubscription({
          onUnsubscribe: () => {
            state = tearDownLogic(state);
          },
        }),
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  dispatchEvent<TEvent extends StoreEvent<TStoreEventConfiguration>>(
    event: TEvent,
  ) {
    this.subject.next(event);
  }
}
