import { defer, fromEvent, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';

// because of missing ios support we can not use Screen.orientation
// https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
// different mechanism to detect orientation:
// https://gist.github.com/darryl-snow/3825198
export const orientation$ = defer(() =>
  of(window.matchMedia('(orientation: portrait)')),
)
  .pipe(
    switchMap((mql) =>
      fromEvent(mql, 'change')
        .pipe(map(() => mql.matches))
        .pipe(startWith(mql.matches))
        .pipe(map((isPortrait) => (isPortrait ? 'portrait' : 'landscape'))),
    ),
  )
  .pipe(distinctUntilChanged())
  .pipe(shareReplay({ bufferSize: 1, refCount: true }));
