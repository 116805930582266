import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TOOLBAR_DATA_TEST } from './toolbar.data-test';

@Component({
  selector: 'bbraun-ui-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  @Output()
  closeSidebar = new EventEmitter<void>();

  @Output()
  logout = new EventEmitter<void>();

  @Input()
  isPrincipalLoggedIn = false;

  @Input()
  principal: { name: string } | null | undefined;

  @Input()
  appName?: string;

  @Input()
  toolbarText: string | null | undefined;

  @Input()
  routerLinkString?: string;

  @Input()
  isSidebarOpen = false;

  @Input()
  logoUrl?: string;

  @Input()
  hideBackButton = false;

  @Input()
  enableLoadingIndicator = false;

  @Input()
  isLoading = false;

  @Input()
  helpLink?: string | false;

  readonly dataTest = TOOLBAR_DATA_TEST;

  constructor(private readonly location: Location) {}

  doLogout() {
    this.logout.emit();
  }

  doToggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.toggleSidebar.emit();
  }

  back() {
    this.location.back();
    this.isSidebarOpen = false;
    this.closeSidebar.emit();
  }

  handleTitleClick() {
    this.isSidebarOpen = false;
    this.closeSidebar.emit();
  }

  openLinkInNewTab(link: string) {
    window.open(link, '_blank');
  }
}
