import { REPORT_MODEL_VALIDATORS } from './injection-tokens';
import { IsBooleanValidatorFactory } from './report-model/validation/factories/is-boolean-validator-factory';
import { IsGreaterOrEqualNumberValidatorFactory } from './report-model/validation/factories/is-greater-or-equal-number-validator-factory';
import { IsGreaterThanZeroWhenOnePropertyIsNumberValidatorFactory } from './report-model/validation/factories/is-greater-than-zero-when-one-of-property-is-number-validator-factory';
import { IsLowerOrEqualToNumberValidatorFactory } from './report-model/validation/factories/is-lower-or-equal-to-number-validator-factory';
import { IsLowerOrEqualToPropertyValidatorFactory } from './report-model/validation/factories/is-lower-or-equal-to-property-validator-factory';
import { IsNumberIntegerValidatorFactory } from './report-model/validation/factories/is-number-integer-validator-factory';
import { IsNumberValidatorFactory } from './report-model/validation/factories/is-number-validator-factory';
import { IsPropertyAndValueNumberOrBothNotNumberValidatorFactory } from './report-model/validation/factories/is-property-and-value-number-or-both-not-number-validator-factory';
import { IsZeroOrEqualToNumberOfChronicHdPatientsValidatorFactory } from './report-model/validation/factories/is-zero-or-equal-to-number-of-chronic-hd-patients-validator-factory';
import { OneHeparinPatientsIsSetInFebruaryAndAugustValidatorFactory } from './report-model/validation/factories/one-heparin-patients-is-set-in-february-and-august-validator-factory';

export const VALIDATION_PROVIDER = [
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsNumberValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsNumberIntegerValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsGreaterOrEqualNumberValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsPropertyAndValueNumberOrBothNotNumberValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: OneHeparinPatientsIsSetInFebruaryAndAugustValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsLowerOrEqualToPropertyValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsZeroOrEqualToNumberOfChronicHdPatientsValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsGreaterThanZeroWhenOnePropertyIsNumberValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsLowerOrEqualToNumberValidatorFactory,
    multi: true,
  },
  {
    provide: REPORT_MODEL_VALIDATORS,
    useClass: IsBooleanValidatorFactory,
    multi: true,
  },
] as const;
