import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { TranslocoModule } from '@ngneat/transloco';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { StartViewComponent } from './components/start-view/start-view.component';

const scope = 'bbraunSharedUiStartView';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule,
    MatButtonModule,
    TranslocoModule,
  ],
  exports: [StartViewComponent],
  declarations: [StartViewComponent],
})
export class UiStartViewModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      scope,
    );
  }
}
