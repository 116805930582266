import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_INTEGER_ID } from '../../../../validation/factories/is-number-integer-validator-factory';

export function createHeparinNotReportedPatientsProperty(): Property {
  return {
    title: 'Not reported',
    technicalName: 'hdKpi_i120',
    dataType: 'calculated',
    unit: null,
    highlighted: false,
    subName: null,
    formula:
      'ownPatientHemoDialysis3Month - heparinStandardPatients - heparinHeparinlessTreatment - ( heparinEnoxLPatients + heparinEnoxGPatients + heparinEnoxAPatients + heparinEnoxCPatients + heparinEnoxEPatients + heparinNadrPatients + heparinDaltPatients + heparinTinzPatients + heparinBemiPatients )',
    validation: [
      { validator: IS_NUMBER_INTEGER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
    ],
  };
}
