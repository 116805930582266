import {
  KeyType,
  MergeResult,
  Options,
  References,
} from './merge-immutable.type';

export function mergeImmutableInternal<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
): MergeResult<TMerge> {
  references = new Map(references);
  const identityCheck = options.identityCheck;

  if (
    path.length >= options.depth ||
    (options.timeoutInMs && options.now() - startTime >= options.timeoutInMs)
  ) {
    return { hasError: false, result: objectToMerge, paths: [], diffs: [] };
  } else if (identityCheck(initial, objectToMerge)) {
    return {
      hasError: false,
      result: objectToMerge,
      ...(options.paths ? { paths: [] } : {}),
      ...(options.diffs ? { diffs: [] } : {}),
    };
  } else {
    const mergeResult = mergeByFunction(
      initial,
      objectToMerge,
      references,
      path,
      diff,
      startTime,
      options,
    );

    if (mergeResult) {
      return mergeResult;
    } else if (options.mode === 'unsafe') {
      return { hasError: false, result: objectToMerge, paths: [], diffs: [] };
    } else {
      if (options.mode === 'safe') {
        return {
          hasError: true,
          error: {
            message: 'Unsupported value type. Value can not be merged.',
            values: {
              path,
              diff,
              initial,
              result: objectToMerge,
              paths: options.paths,
              diffs: options.diffs,
            },
          },
        };
      } else {
        throw new Error(
          `Unsupported value type. Value can not be merged. Type: <${
            objectToMerge === null
              ? null
              : typeof objectToMerge === 'object'
              ? (objectToMerge as unknown as object).constructor?.name
              : typeof objectToMerge
          }>`,
        );
      }
    }
  }
}

function mergeByFunction<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
): MergeResult<TMerge> | false {
  let mergeResult: MergeResult<TMerge> | false = false;

  for (
    let index = 0;
    !mergeResult && index < options.mergeFunctions.length;
    index++
  ) {
    const fn = options.mergeFunctions[index];

    if (fn.accepts(objectToMerge)) {
      mergeResult = fn.merge(
        initial,
        objectToMerge,
        references,
        path,
        diff,
        startTime,
        options,
      ) as MergeResult<TMerge>;
    }
  }

  return mergeResult;
}
