import { BavReportingJsonApiTypes } from '@bbraun/bav-reporting/data-access-ais-reports';
import { TypedFiqlQuery } from '@bbraun/shared/util-fiql';
import {
  createPropertyPathBuilder,
  PropertyPathBuilder,
} from '@bbraun/shared/util-lang';
import { ReportListFilterModel } from '../components/report-list-filter/report-list-filter.store';

type ReportFiqlQuery = TypedFiqlQuery<
  PropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>
>;

export type YearOrMonthFiqlConstraint = TypedFiqlQuery<
  PropertyPathBuilder<BavReportingJsonApiTypes['ais-report'], number>
>;

const reportMonthSelector =
  createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
    'month',
  );

const reportYearSelector =
  createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
    'year',
  );

export function createReportListFilterQuery(
  centerId: string,
  filter: ReportListFilterModel | undefined,
): ReportFiqlQuery {
  const fromFilterQuery =
    filter?.date.from &&
    createFromFilterQuery(extractMonthAndYearFromDate(filter.date.from));
  const toFilterQuery =
    filter?.date.to &&
    createToFilterQuery(extractMonthAndYearFromDate(filter.date.to));
  const reportCenterSelector =
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'centerId',
    );

  const centerFilterQuery = {
    equals: {
      selector: reportCenterSelector,
      args: centerId,
    },
  };

  return {
    and: [
      ...(fromFilterQuery ? [fromFilterQuery] : []),
      ...(toFilterQuery ? [toFilterQuery] : []),
      centerFilterQuery,
    ],
  };
}

function extractMonthAndYearFromDate(date: Date): {
  month: number;
  year: number;
} {
  return {
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

function createFromFilterQuery(fromValues: { month: number; year: number }) {
  const isYearGreaterQuery: YearOrMonthFiqlConstraint = {
    greater_than: {
      selector: reportYearSelector,
      args: fromValues.year,
    },
  };

  const isYearEqualQuery: YearOrMonthFiqlConstraint = {
    equals: {
      selector: reportYearSelector,
      args: fromValues.year,
    },
  };

  const isMonthGreaterOrEqualQuery: YearOrMonthFiqlConstraint = {
    greater_than_or_equal: {
      selector: reportMonthSelector,
      args: fromValues.month,
    },
  };

  return {
    or: [
      isYearGreaterQuery,
      {
        and: [isYearEqualQuery, isMonthGreaterOrEqualQuery],
      },
    ],
  };
}

function createToFilterQuery(fromValues: { month: number; year: number }) {
  const isYearLessQuery: YearOrMonthFiqlConstraint = {
    less_than: {
      selector: reportYearSelector,
      args: fromValues.year,
    },
  };

  const isYearEqualQuery: YearOrMonthFiqlConstraint = {
    equals: {
      selector: reportYearSelector,
      args: fromValues.year,
    },
  };

  const isMonthLessOrEqualQuery: YearOrMonthFiqlConstraint = {
    less_than_or_equal: {
      selector: reportMonthSelector,
      args: fromValues.month,
    },
  };

  return {
    or: [
      isYearLessQuery,
      {
        and: [isYearEqualQuery, isMonthLessOrEqualQuery],
      },
    ],
  };
}
