import { InjectionToken } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocalizedNumberFormatErrorHandler } from './functions/convert-localized-number-to-string';
import { LocalizedDatePipeErrorHandler } from './functions/localize-date';

export const UI_LANGUAGE_TRANSLOCO_SERVICE =
  new InjectionToken<TranslocoService>('UI_LANGUAGE_TRANSLOCO_SERVICE');

export const LOCALIZED_DATE_PIPE_ERROR_HANDLER =
  new InjectionToken<LocalizedDatePipeErrorHandler>(
    'LOCALIZED_DATE_PIPE_ERROR_HANDLER',
  );

export const LOCALIZED_NUMBER_FORMAT_ERROR_HANDLER =
  new InjectionToken<LocalizedNumberFormatErrorHandler>(
    'LOCALIZED_NUMBER_FORMAT_ERROR_HANDLER',
  );

export const UI_LANGUAGE_DISPLAY_TIMEZONE = new InjectionToken<
  string | 'local' | 'unknown'
>('UI_LANGUAGE_DISPLAY_TIMEZONE');
