import { ReportModel } from '../types/report-model.types';
import { FormulaProperties } from '../types/types';

export function extractReportModelFormulaProperties(
  reportModel: ReportModel,
): FormulaProperties {
  let formulas: FormulaProperties = {};
  for (const block of reportModel.blocks) {
    for (const section of block.sections) {
      for (const group of section.propertyGroups) {
        for (const property of group.properties) {
          if (property.dataType === 'calculated' && property.formula) {
            formulas = {
              ...formulas,
              [property.technicalName]: property.formula,
            };
          }
        }
      }
    }
  }
  return formulas;
}
