import { HashMap, TranslocoService } from '@ngneat/transloco';
import { isString, mapValues } from 'lodash';
import { Observable, of } from 'rxjs';
import { concatMap, map, shareReplay, switchMap } from 'rxjs/operators';

export interface TranslateFunction {
  (key: string, params?: HashMap<any> | undefined): string;
  <T>(
    key: string,
    params: HashMap<any> | undefined,
    isOfType: (value: unknown) => value is T,
  ): T;
}

export interface LoadedLang {
  translate: TranslateFunction;
  lang: string;
}

export class ScopedTranslationService {
  readonly loadedLang$: Observable<LoadedLang>;

  private readonly loadedLangs = new Map<string, Observable<LoadedLang>>();

  constructor(
    private readonly scope: string,
    private readonly translocoService: TranslocoService,
  ) {
    this.loadedLang$ = translocoService.langChanges$.pipe(
      concatMap((lang) => loadLang(scope, lang, translocoService)),
    );
  }

  forLang(lang: string): Observable<LoadedLang> {
    let loadedLang$ = this.loadedLangs.get(lang);

    if (!loadedLang$) {
      loadedLang$ = loadLang(this.scope, lang, this.translocoService);
      this.loadedLangs.set(lang, loadedLang$);
    }

    return loadedLang$;
  }

  translate(fullQualifiedKey: string, values?: {}): Observable<string> {
    return this.loadedLang$.pipe(
      map(({ translate }) => translate(fullQualifiedKey, values, isString)),
    );
  }

  translateWithType<T>(
    fullQualifiedKey: string,
    isOfType: (value: unknown) => value is T,
    values?: {},
  ): Observable<
    | { hasError: false; value: T }
    | {
        hasError: true;
        error?: unknown;
      }
  > {
    return this.loadedLang$.pipe(
      switchMap(({ translate }) => {
        try {
          return of({
            hasError: false,
            value: translate(fullQualifiedKey, values, isOfType),
          } as const);
        } catch (error) {
          return of({ hasError: true, error } as const);
        }
      }),
    );
  }

  translateAll<
    TKey extends string,
    TKeyAndParams extends Readonly<{
      [key in TKey]: Readonly<{
        key: string;
        params?: HashMap<any>;
        type?: (v: unknown) => v is any;
      }>;
    }>,
  >(
    keysAndParams: TKeyAndParams,
  ): Observable<{
    [key in keyof TKeyAndParams]: Exclude<
      TKeyAndParams[key]['type'],
      undefined
    > extends (v: any) => v is infer T
      ? T
      : string;
  }> {
    return this.loadedLang$.pipe(
      map(({ translate }) =>
        mapValues(keysAndParams, ({ key, params, type = isString }) =>
          translate(key, params, type),
        ),
      ),
    );
  }
}

function loadLang(
  scope: string,
  lang: string,
  translocoService: TranslocoService,
): Observable<LoadedLang> {
  return translocoService
    .load(`${scope}/${lang}`)
    .pipe(
      map(() => ({
        translate: createTranslateFunction(translocoService, lang),
        lang,
      })),
    )
    .pipe(shareReplay({ bufferSize: 1, refCount: false }));
}

function checkTranslationResult<T>(
  key: string,
  value: unknown,
  isOfType: (value: unknown) => value is T,
): T {
  if (isOfType(value)) {
    return value;
  } else {
    throw new Error(
      `Translation failed for key <${key}>. Translated value <${value}> is of the wrong type.`,
    );
  }
}

function createTranslateFunction(
  translocoService: TranslocoService,
  lang: string,
): TranslateFunction {
  return (
    key: string,
    params?: HashMap<any> | undefined,
    isOfType: (value: unknown) => value is any = isString,
  ) =>
    checkTranslationResult(
      key,
      translocoService.translate(key, params, lang),
      isOfType,
    );
}
