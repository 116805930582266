import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ReportDetailsResponseModel } from '@bbraun/bav-reporting/data-access-ais-reports';
import { first, map, Observable, switchMap, throwError } from 'rxjs';
import { ReportingService } from '../services/reporting.service';
import { ReportInfo } from '../types/types';

@Injectable()
export class EditReportResolver
  implements
    Resolve<{
      reportDetails: ReportDetailsResponseModel;
      reportInfo: ReportInfo;
      doUpdateCalculatedReportValuesExist: boolean;
    }>
{
  constructor(private readonly reportingService: ReportingService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<{
    reportDetails: ReportDetailsResponseModel;
    reportInfo: ReportInfo;
    doUpdateCalculatedReportValuesExist: boolean;
  }> {
    const id = route.paramMap.get('id');

    return id
      ? this.reportingService
          .getReportDetails(id)
          .pipe(
            switchMap((reportDetails) =>
              this.reportingService
                .getCalculatedReport(reportDetails.centerId, {
                  month: reportDetails.month,
                  year: reportDetails.year,
                })
                .pipe(map((value) => !!value))
                .pipe(
                  map((doUpdateCalculatedReportValuesExist) => ({
                    reportDetails,
                    doUpdateCalculatedReportValuesExist,
                  })),
                ),
            ),
          )
          .pipe(
            map(({ reportDetails, doUpdateCalculatedReportValuesExist }) => ({
              reportDetails,
              doUpdateCalculatedReportValuesExist,
              reportInfo: {
                center: reportDetails.centerId,
                date: { year: reportDetails.year, month: reportDetails.month },
              },
            })),
          )
          .pipe(first())
      : throwError(() => new Error('Report id is not defined'));
  }
}
