// THIS FILE IS GENERATED - DO NOT EDIT!

import {
  ExtractSparseFieldTypes,
  ModelObject,
  TypedQueryParameters,
} from '@bbraun/shared/util-jsonapi';
// eslint-disable-next-line no-unused-expressions
// @ts-ignore
import { createPropertyPathBuilder } from '@bbraun/shared/util-lang';

import { BavReportingJsonApiTypes } from '../bav-reporting-json-api-types.json-api.generated';

import { BavReportingJsonApi } from '../bav-reporting-json-api.type';

export const reportListResponseModelFieldsets = {
  'ais-report': [
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'year',
    ).path[0] as 'year',
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'month',
    ).path[0] as 'month',
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'state',
    ).path[0] as 'state',
  ],
} as const;

type ReportListResponseModelFieldsets = ExtractSparseFieldTypes<
  typeof reportListResponseModelFieldsets
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ReportListResponseModel = {
  readonly id: string;
  readonly month: number;
  readonly state: 'Draft' | 'Completed';
  readonly year: number;
} & ModelObject<BavReportingJsonApi, 'ais-report'>;

export const reportListResponseModelIncludes = undefined;

export const reportListResponseModelReadParameters: TypedQueryParameters<
  BavReportingJsonApiTypes,
  ReportListResponseModelFieldsets,
  'ais-report'
> = {
  fieldsets: reportListResponseModelFieldsets,
  include: reportListResponseModelIncludes,
};
