import { DxFilterOperation, isDxFilterOperation } from './dx-filter-operation';

export type DxFilterUnaryOperation = ['!', DxFilterOperation];

export function isDxFilterUnaryOperation(
  element: any,
): element is DxFilterUnaryOperation {
  if (!Array.isArray(element)) {
    return false;
  }

  if (element.length !== 2) {
    return false;
  }

  if (element[0] !== '!') {
    return false;
  }

  if (!isDxFilterOperation(element[1])) {
    return false;
  }

  return true;
}
