import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DxDataGridModule } from 'devextreme-angular';
import { MatCardModule } from '@angular/material/card';
import { UiLanguageModule } from '@bbraun/shared/ui-language';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import {
  TRANSLATION_LOADER_REGISTRY,
  TranslationLoaderRegistry,
} from '@bbraun/shared/data-access-locale';
import { defer } from 'rxjs';
import { createMessageService } from '@bbraun/shared/util-devexpress';
import { TranslocoMessageServiceFactory } from '@bbraun/shared/util-transloco-message';
import { UiInfoBoxModule } from '@bbraun/shared/ui-info-box';
import { UiHideableContainerModule } from '@bbraun/shared/ui-hideable-container';
import { BBraunUiDiscardChangesModule } from '@bbraun/shared/ui-discard-changes';
import { BavReportingUiCommonModule } from '@bbraun/bav-reporting/ui-common';
import fallbackTranslation from '../i18n/en.json';
import { SCOPE } from '../constants';
import { LabDataTestConfigurationComponent } from './containers/lab-data-test-configuration/lab-data-test-configuration.component';
import { UserLogsComponent } from './containers/user-logs/user-logs.component';
import { BAV_REPORTING_ADMINISTRATION_MESSAGE_SERVICE } from './injection-tokens';

@NgModule({
  declarations: [LabDataTestConfigurationComponent, UserLogsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    DxDataGridModule,
    MatCardModule,
    UiLanguageModule,
    TranslocoModule,
    MatButtonModule,
    UiInfoBoxModule,
    UiHideableContainerModule,
    BBraunUiDiscardChangesModule,
    BavReportingUiCommonModule,
  ],
  exports: [LabDataTestConfigurationComponent, UserLogsComponent],
  providers: [
    {
      provide: BAV_REPORTING_ADMINISTRATION_MESSAGE_SERVICE,
      useFactory: createMessageService,
      deps: [TranslocoMessageServiceFactory],
    },
  ],
})
export class BavReportingFeatureAdministrationModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );
  }
}
