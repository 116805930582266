import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LocaleService } from '@bbraun/shared/data-access-locale';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { UI_LANGUAGE_TRANSLOCO_SERVICE } from '../../injection-tokens';

@Component({
  selector: 'bbraun-ui-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TranslocoService,
      useExisting: UI_LANGUAGE_TRANSLOCO_SERVICE,
    },
  ],
})
export class LanguageComponent implements OnInit {
  @Input()
  supportedLanguages: string[] = [];
  @Input()
  currentLanguage$?: Observable<string | undefined>;

  constructor(
    private readonly localeService: LocaleService,
    private readonly location: Location,
  ) {}

  ngOnInit() {
    this.supportedLanguages = this.localeService.getSupportedLocales();
    this.currentLanguage$ = this.localeService.getLocale$();
  }

  aborted() {
    this.location.back();
  }

  languageSelected(locale: string | undefined) {
    if (locale) {
      this.localeService.switchLocale(locale);
    } else {
      this.localeService.switchLocaleToDefault();
    }

    this.location.back();
  }
}
