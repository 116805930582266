import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bbraun-ui-confirm-information-dialog',
  templateUrl: './confirm-information-dialog.component.html',
  styleUrls: ['./confirm-information-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmInformationDialogComponent {
  @Input()
  title?: string;

  @Input()
  informationText?: string;

  @Input()
  confirmButtonText?: string;

  @Input()
  cancelButtonText?: string;
}
