export function normalizeValue<T>(value?: T): T | null {
  if (value === undefined) {
    return null;
  }
  if (typeof value === 'string') {
    return (value.trim() as unknown as T) || null;
  }

  return value;
}
