import { Inject, Injectable } from '@angular/core';
import { TypedJsonApiService } from '@bbraun/shared/util-jsonapi';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  CreateObjectType,
  UpdateObjectType,
} from '@bbraun/shared/util-jsonapi';
import {
  BavReportingJsonApi,
  reportListResponseModelReadParameters,
  ReportDetailsResponseModel,
  calculatedReportDetailsResponseModelReadParameters,
  BavReportingJsonApiTypesMeta,
  BAV_REPORTING_API_SERVICE,
  reportDetailsResponseModelReadParameters,
  ReportListResponseModel,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { expectSingleElementArray } from '@bbraun/shared/util-rxjs';
import { ReportListFilterModel } from '../components/report-list-filter/report-list-filter.store';
import { createReportFilterQuery } from '../functions/create-report-filter-query';
import { createReportListFilterQuery } from '../functions/create-report-list-filter-query';
import { YearAndMonth } from '../types/year-and-month.type';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(
    @Inject(BAV_REPORTING_API_SERVICE)
    private readonly typedApiService: TypedJsonApiService<BavReportingJsonApi>,
    private readonly httpClient: HttpClient,
  ) {}

  getReports(
    centerId: string,
    filterOptions?: ReportListFilterModel,
  ): Observable<ReadonlyArray<ReportListResponseModel>> {
    const filter = createReportListFilterQuery(centerId, filterOptions);

    return this.typedApiService
      .resource('ais-report')
      .query({
        ...reportListResponseModelReadParameters,
        filter,
      })
      .pipe(first());
  }

  getReportDetails(id: string): Observable<ReportDetailsResponseModel> {
    return this.typedApiService
      .resource('ais-report')
      .read(id, reportDetailsResponseModelReadParameters)
      .pipe(first());
  }

  getCalculatedReport(centerId: string, date: YearAndMonth) {
    const filter = createReportFilterQuery(centerId, date);
    return this.typedApiService
      .resource('ais-calculated-report')
      .query({
        ...calculatedReportDetailsResponseModelReadParameters,
        filter,
        page: {
          size: 1,
          number: 1,
        },
      })
      .pipe(expectSingleElementArray())
      .pipe(map((value) => value || false))
      .pipe(first());
  }

  updateReport(
    id: string,
    report: UpdateObjectType<BavReportingJsonApiTypesMeta, 'ais-report'>,
  ): Observable<ReportDetailsResponseModel> {
    return this.typedApiService
      .resource('ais-report')
      .update(id, report, reportDetailsResponseModelReadParameters)
      .pipe(first());
  }

  createReport(
    report: CreateObjectType<BavReportingJsonApiTypesMeta, 'ais-report'>,
  ): Observable<ReportDetailsResponseModel> {
    return this.typedApiService
      .resource('ais-report')
      .create(report, reportDetailsResponseModelReadParameters)
      .pipe(first());
  }

  exportXml(url: string): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'text/xml' }).set(
      'Accept',
      'text/xml',
    );

    return this.httpClient.get(url, {
      headers,
      responseType: 'text',
    });
  }
}
