import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  lookUpError,
  ErrorCodeLookup,
  ErrorObject,
  ErrorCodeIgnore,
} from '@bbraun/shared/util-error';
import {
  hasPropertyOfType,
  isOneTypeOf,
  isString,
  isSymbol,
} from '@bbraun/shared/util-lang';
import { MessageAction } from '@bbraun/shared/util-message-ng';
import { or } from '@bbraun/shared/util-rxjs';
import {
  MessageObject,
  TranslocoMessageService,
} from '@bbraun/shared/util-transloco-message';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { combineLatest, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { ERROR_MESSAGE_SERVICE } from '../injection-tokens';
import { ErrorTranslationService } from './error-translation.service';

type Error = ErrorObject & {
  error?: unknown;
  message?: string | (() => Observable<string>);
};

const GLOBAL_ERROR_MESSAGE_ID = Symbol('globalErrorMessageId');

const isErrorCode = isOneTypeOf(isString, isSymbol);
const hasErrorCode = hasPropertyOfType(
  'code',
  isOneTypeOf(isString, isErrorCode),
);

const isResolverError = hasPropertyOfType(
  'error',
  hasPropertyOfType('rejection', hasErrorCode),
);

function extractError(error: Error): Error {
  return hasErrorCode(error)
    ? error
    : isResolverError(error)
    ? error.error.rejection
    : error;
}

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  private errorCodeLookups: ReadonlyArray<ErrorCodeLookup> = [];
  private errorCodeIgnores: ReadonlyArray<ErrorCodeIgnore> = [];

  constructor(
    @Inject(ERROR_MESSAGE_SERVICE)
    private readonly messageService: TranslocoMessageService,
    private readonly translationService: ErrorTranslationService,
    private readonly router: Router,
  ) {}

  registerErrorCodeLookups(errorCodeLookups: ReadonlyArray<ErrorCodeLookup>) {
    this.errorCodeLookups = [...this.errorCodeLookups, ...errorCodeLookups];
  }

  registerErrorCodeIgnores(errorCodeIgnores: ReadonlyArray<ErrorCodeIgnore>) {
    this.errorCodeIgnores = [...this.errorCodeIgnores, ...errorCodeIgnores];
  }

  handleError(error: unknown): void {
    this.onError({ error });
  }

  remove(messageIds: ReadonlyArray<Symbol>): void {
    this.messageService.remove(messageIds);
  }

  onError(
    errorObject: Error,
    {
      id,
      actions,
      timeOut = false,
      noGenericError = false,
    }: {
      id?: Symbol;
      actions?: ReadonlyArray<MessageAction>;
      timeOut?: number | boolean;
      noGenericError?: boolean;
    } = {},
  ): void {
    const extractedError = extractError(errorObject);
    const errorCode = extractedError.code;

    const lookup = {
      ...lookUpError(extractedError, this.errorCodeLookups),
      ...(extractedError.message ? { message: extractedError.message } : {}),
    };

    if (lookup.message || !noGenericError) {
      this.messageService.message(
        new MessageObject(
          () => {
            const message$ = lookup.message
              ? typeof lookup.message === 'function'
                ? lookup.message()
                : of(lookup.message)
              : this.translationService.translate(
                  i18n('bbraunSharedUtilErrorNg.errors.globalError.generic'),
                );
            return combineLatest([
              message$,
              timeOut ||
              (lookup.reloadMode && lookup.reloadMode === 'no-reload')
                ? of(false)
                : this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd))
                    .pipe(map(() => this.router.url))
                    .pipe(startWith(this.router.url))
                    .pipe(distinctUntilChanged())
                    .pipe(
                      switchMap(() =>
                        this.translationService.translate(
                          i18n(
                            'bbraunSharedUtilErrorNg.errors.globalError.reload',
                          ),
                          { href: this.router.url },
                        ),
                      ),
                    ),
            ]).pipe(
              map(([message, reload]) =>
                reload ? `<p>${message}</p><p>${reload}</p>` : message,
              ),
            );
          },
          'error',
          {
            id: id || (lookup && lookup.messageId) || GLOBAL_ERROR_MESSAGE_ID,
            error: extractedError.error,
            timeOut,
            actions: actions || [{ type: 'update' }],
            enableHtml: true,
            isSkipped$:
              this.errorCodeIgnores.length && errorCode !== undefined
                ? or(
                    this.errorCodeIgnores.map((errorCodeIgnore) =>
                      errorCodeIgnore.isIgnore(errorCode),
                    ),
                  )
                : undefined,
          },
        ),
      );
    }
  }
}
