import { Router } from '@angular/router';
import {
  DataAccessSecurityErrorCodes,
  LoginWithPrincipalService,
} from '@bbraun/shared/data-access-security';
import { Constructor } from '@bbraun/shared/util-lang';
import { GlobalErrorHandler } from '@bbraun/shared/util-error-ng';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export const WITH_LOGIN_ON_INIT = Symbol('WITH_LOGIN_ON_INIT');
export const WITH_LOGIN_ON_DESTROY = Symbol('WITH_LOGIN_ON_DESTROY');

const SUBSCRIPTIONS = Symbol('SUBSCRIPTIONS');

export interface WithLogin<TPrincipal> {
  readonly isPrincipalLoggedIn$: Observable<boolean>;
  readonly isLogoutInProgress$: Observable<boolean>;
  readonly principal$: Observable<TPrincipal | undefined>;

  loginRequested(): void;
  logoutRequested(code?: number): void;

  [WITH_LOGIN_ON_INIT](): void;
  [WITH_LOGIN_ON_DESTROY](): void;
}

export interface WithLoginMixinBase<TPrincipal> {
  readonly loginService: LoginWithPrincipalService<TPrincipal>;
  readonly router: Router;
  readonly errorHandler: GlobalErrorHandler;
}

export function withLoginMixin<
  TBase extends Constructor<WithLoginMixinBase<TPrincipal>>,
  TPrincipal,
>(
  base: TBase,
): TBase & Constructor<WithLoginMixinBase<TPrincipal> & WithLogin<TPrincipal>> {
  return class extends base {
    readonly isPrincipalLoggedIn$: Observable<boolean>;
    readonly isLogoutInProgress$: Observable<boolean>;
    readonly principal$: Observable<TPrincipal | undefined>;

    private readonly [SUBSCRIPTIONS] = new Subscription();

    constructor(...args: any[]) {
      super(...args);
      this.principal$ = this.loginService.principal$;
      this.isPrincipalLoggedIn$ = this.principal$
        .pipe(map((principal) => !!principal))
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
      this.isLogoutInProgress$ = this.loginService.isLogoutInProgress$;
    }

    loginRequested() {
      this.router.navigate(['/app/login']);
    }

    logoutRequested(code?: number) {
      this.loginService.logout(code).catch((error) => {
        this.errorHandler.onError({
          code: DataAccessSecurityErrorCodes.LOGOUT_INCOMPLETE.toString(10),
          error,
        });
      });
    }

    [WITH_LOGIN_ON_INIT]() {
      this[SUBSCRIPTIONS].add(
        this.loginService.principalLoggedOut$.subscribe(() =>
          this.logoutRequested(DataAccessSecurityErrorCodes.SESSION_EXPIRED),
        ),
      );
    }

    [WITH_LOGIN_ON_DESTROY]() {
      this[SUBSCRIPTIONS].unsubscribe();
    }
  };
}
