import { serializeTypedFiqlQuery } from '@bbraun/shared/util-fiql';
import { Observable, of, throwError, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { IndexProvider, IndexResult } from '../index/index-provider';
import { AccessTokenService } from '../access-token-service.type';
import { JsonApiMeta } from '../set-api-meta-headers';
import { JsonApiUrlAdapter } from '../json-api-url-adapter';
import { JsonApiAdapter2 } from '../json-api-adapter2';
import { getResourceLink, getResourceLinks } from '../meta/get-resource-links';
import {
  CollectionLink,
  CollectionTypedJsonApiLinkAdapter,
  InstanceLink,
  InstanceTypedJsonApiLinkAdapter,
  TypedJsonApiLinkedAdapterFromResourceLink,
  TypedJsonApiService,
} from './typed-json-api.service.type';
import { createMapToCreateParameters } from './create-map-to-create-parameters';
import { ApiDefinition, ApiTypes } from './api-types';
import {
  JsonApiResourceMeta,
  TypedJsonApiHttpClientFactory,
} from './typed-json-api.types';
import { TypedJsonApiAdapter } from './typed-json-api-adapter';
import { createMapToUpdateParameters } from './create-map-to-update-parameters';
import { TypedQueryParameters } from './typed-query-parameters';
import { Fieldsets } from './fieldsets';
import { serializeTypedSort } from './serialize-typed-sort';
import { combineTypedQueryParameters } from './combine-typed-query-parameters';
import { TypedJsonApiUrlAdapter } from './typed-json-api-url-adapter';
import { TypedJsonApiLinkedAdapter } from './typed-json-api-linked-adapter';

export const LINK_NOT_FOUND_ERROR_CODE = 'typed-json-api.service:linkNotFound';

export class TypedJsonApiServiceImpl<
  TApiDefinition extends ApiDefinition<ApiTypes>,
> implements TypedJsonApiService<TApiDefinition>
{
  private readonly indexProvider: IndexProvider<TApiDefinition>;

  constructor(
    private readonly apiMeta: JsonApiMeta,
    public readonly jsonApiResourceMeta: JsonApiResourceMeta<
      TApiDefinition['resourceTypes'],
      TApiDefinition['resourceTypesMeta']
    >,
    accessTokenService: AccessTokenService,
    private readonly httpClientFactory: TypedJsonApiHttpClientFactory<
      TApiDefinition['resourceTypes']
    >,
    protected readonly baseUrl: string,
  ) {
    this.indexProvider = new IndexProvider(
      httpClientFactory('index'),
      accessTokenService,
      apiMeta,
      baseUrl,
    );
  }

  public index(): Observable<IndexResult<TApiDefinition> | undefined> {
    return this.indexProvider.index$;
  }

  public resource<
    TTypeName extends keyof TApiDefinition['resourceTypes'] & string,
  >(type: TTypeName) {
    return new TypedJsonApiAdapter(
      this.resourceDocument(type, {}),
      createMapToCreateParameters(type, this.jsonApiResourceMeta),
      createMapToUpdateParameters(type, this.jsonApiResourceMeta),
    );
  }

  public direct<
    TTypeName extends keyof TApiDefinition['resourceTypes'] & string,
  >(
    type: TTypeName,
    queryParameter?: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
      TTypeName
    >,
  ) {
    const jsonApiUrlAdapter = new JsonApiUrlAdapter(
      this.apiMeta,
      type,
      this.httpClientFactory(type),
      serializeTypedFiqlQuery,
      serializeTypedSort,
      combineTypedQueryParameters,
      queryParameter,
    );

    return new TypedJsonApiUrlAdapter(
      jsonApiUrlAdapter,
      createMapToCreateParameters(type, this.jsonApiResourceMeta),
      createMapToUpdateParameters(type, this.jsonApiResourceMeta),
    );
  }

  public link<TTypeName extends keyof TApiDefinition['resourceTypes'] & string>(
    link: CollectionLink<TApiDefinition, TTypeName>,
    queryParameter?: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
      TTypeName
    >,
  ): CollectionTypedJsonApiLinkAdapter<TApiDefinition, TTypeName>;
  public link<TTypeName extends keyof TApiDefinition['resourceTypes'] & string>(
    link: InstanceLink<TApiDefinition, TTypeName>,
    queryParameter?: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
      TTypeName
    >,
  ): InstanceTypedJsonApiLinkAdapter<TApiDefinition, TTypeName>;
  public link<TTypeName extends keyof TApiDefinition['resourceTypes'] & string>(
    link:
      | CollectionLink<TApiDefinition, TTypeName>
      | InstanceLink<TApiDefinition, TTypeName>,
    queryParameter?: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
      TTypeName
    >,
  ): TypedJsonApiLinkedAdapter<
    TApiDefinition,
    TTypeName,
    TApiDefinition['resourceTypesMeta']
  > {
    const type = link.meta['jsonapi:resource'];

    const jsonApiUrlAdapter = new JsonApiUrlAdapter(
      this.apiMeta,
      type,
      this.httpClientFactory(type),
      serializeTypedFiqlQuery,
      serializeTypedSort,
      combineTypedQueryParameters,
      queryParameter,
    );

    return new TypedJsonApiLinkedAdapter(
      link.href,
      jsonApiUrlAdapter,
      createMapToCreateParameters(type, this.jsonApiResourceMeta),
      createMapToUpdateParameters(type, this.jsonApiResourceMeta),
    );
  }

  public resourceLink<
    TTypeName extends keyof TApiDefinition['resourceTypes'] & string,
    TLink extends keyof TApiDefinition['index']['meta']['resources'][TTypeName]['links'] &
      string,
  >(
    type: TTypeName,
    linkName: TLink,
    queryParameter?: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
      TTypeName
    >,
  ): TypedJsonApiLinkedAdapterFromResourceLink<
    TApiDefinition,
    TTypeName,
    TLink
  > {
    const jsonApiUrlAdapter = new JsonApiUrlAdapter(
      this.apiMeta,
      type,
      this.httpClientFactory(type),
      serializeTypedFiqlQuery,
      serializeTypedSort,
      combineTypedQueryParameters,
      queryParameter,
    );

    const link$: Observable<string> = this.index().pipe(
      switchMap((indexResult) => {
        const link = getResourceLink(type, linkName, indexResult);
        return link
          ? of(typeof link === 'string' ? link : link.href)
          : throwError(() => ({
              code: LINK_NOT_FOUND_ERROR_CODE,
              meta: { type, linkName },
            }));
      }),
    );

    return new TypedJsonApiLinkedAdapter(
      link$,
      jsonApiUrlAdapter,
      createMapToCreateParameters(type, this.jsonApiResourceMeta),
      createMapToUpdateParameters(type, this.jsonApiResourceMeta),
    );
  }

  public resourceDocument<
    TTypeName extends keyof TApiDefinition['resourceTypes'] & string,
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
  >(
    type: TTypeName,
    queryParameter?: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName
    >,
  ): JsonApiAdapter2<
    TypedQueryParameters<TApiDefinition['resourceTypes'], TFieldsets, TTypeName>
  > {
    return new JsonApiAdapter2(
      this.apiMeta,
      type,
      this.index()
        .pipe(
          map((result) => {
            const url = getResourceListLink(type, result);
            if (url) {
              return url;
            } else {
              throw new Error(
                `Can not access unknown or restricted resource <${type}>`,
              );
            }
          }),
        )
        .pipe(distinctUntilChanged()),
      this.httpClientFactory(type),
      serializeTypedFiqlQuery,
      serializeTypedSort,
      combineTypedQueryParameters,
      queryParameter,
    );
  }

  public mapToCreateParameters<
    TTypeName extends keyof TApiDefinition['resourceTypes'] & string,
  >(type: TTypeName) {
    return createMapToCreateParameters(type, this.jsonApiResourceMeta);
  }

  public mapToUpdateParameters<
    TTypeName extends keyof TApiDefinition['resourceTypes'] & string,
  >(type: TTypeName) {
    return createMapToUpdateParameters(type, this.jsonApiResourceMeta);
  }
}

function getResourceListLink<TApiDefinition extends ApiDefinition<ApiTypes>>(
  type: keyof TApiDefinition['resourceTypes'],
  result: IndexResult<TApiDefinition> | undefined,
): string | undefined {
  const resourceLinks = getResourceLinks(type, result);
  // TODO kdraba: the used link should depend on the operation, i.e. this check needs to be done in the adapter
  const link =
    resourceLinks.list ||
    resourceLinks.read ||
    resourceLinks.create ||
    resourceLinks.instance;

  return link && (typeof link === 'string' ? link : link.href);
}
