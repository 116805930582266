import { Inject, NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import {
  TranslocoMessageService,
  TranslocoMessageServiceFactory,
} from '@bbraun/shared/util-transloco-message';
import { TranslocoModule } from '@ngneat/transloco';
import { DxButtonModule } from 'devextreme-angular';
import { defer, Observable } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { DxDataGridStatePersistenceDirective } from './directives/dx-data-grid-state-persistence/dx-data-grid-state-persistence.directive';
import {
  DxDataGridSyncSortWithVisibleColumnsDirective, //
} from './directives/dx-data-grid-sync-sort-with-visible-columns/dx-data-grid-sync-sort-with-visible-columns.directive';
import { DevexpressLocaleReadyDirective } from './directives/locale-ready/locale-ready.directive';
import { RepaintOnLocaleChangeDirective } from './directives/repaint-on-locale-change/repaint-on-locale-change.directive';
import { DEVEXPRESS_LOCALE } from './injection-tokens';
import { SHARED_UTIL_DEVEXPRESS_MESSAGE_SERVICE } from './injection-tokens';
import { DevexpressLocaleObservableFactory } from './services/devexpress-locale-observable-factory';

const scope = 'bbraunSharedUtilDevexpress';

export function createLocaleObservable(factory: {
  create: () => Observable<string>;
}) {
  return factory.create();
}

export function createMessageService(
  factory: TranslocoMessageServiceFactory,
): TranslocoMessageService {
  return factory.createForScope(scope);
}

@NgModule({
  declarations: [
    DxDataGridStatePersistenceDirective,
    DxDataGridSyncSortWithVisibleColumnsDirective,
    DevexpressLocaleReadyDirective,
    RepaintOnLocaleChangeDirective,
  ],
  exports: [
    DxDataGridStatePersistenceDirective,
    DxDataGridSyncSortWithVisibleColumnsDirective,
    DevexpressLocaleReadyDirective,
    RepaintOnLocaleChangeDirective,
  ],
  imports: [TranslocoModule, DxButtonModule, MatTooltipModule],
  providers: [
    {
      provide: DEVEXPRESS_LOCALE,
      useFactory: createLocaleObservable,
      deps: [DevexpressLocaleObservableFactory],
    },
    {
      provide: SHARED_UTIL_DEVEXPRESS_MESSAGE_SERVICE,
      useFactory: createMessageService,
      deps: [TranslocoMessageServiceFactory],
    },
  ],
})
export class UtilDevexpressModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      scope,
    );
  }
}
