import {
  FormDataHandlerState,
  normalizeValue,
  resolveValidationModeFunction,
  updateFormDataHandlerState,
} from '@bbraun/shared/util-devexpress';
import { toPairs } from '@bbraun/shared/util-lang';
import { isEqual as _isEqual } from 'lodash-es';
import { CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES } from '../services/report-editor-error-lookup.service';
import { FormulaProperties } from '../types/types';
import {
  CalculatedPropertiesError,
  computeCalculatedProperties,
} from './compute-calculated-properties';

type FormDataHandlerStateCalculationError<TFormData> =
  | {
      code: typeof CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES.cyclicDependencyInCalculatedProperties;
      meta: {
        values: {
          cyclicDependencyProperty: keyof TFormData;
        };
      };
    }
  | {
      code: typeof CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES.calculateProperties;
      meta: {
        values: {
          errors: ReadonlyArray<CalculatedPropertiesError>;
        };
      };
    };

export function calculateReportEditorFormDataHandlerState<
  TFormData extends {
    [property: string]: unknown;
  },
>({
  updatedData,
  viewModel,
  formDataHandlerState,
  onError,
}: {
  updatedData: Partial<TFormData>;
  viewModel: { values: TFormData; propertiesToBeCalculated: FormulaProperties };
  formDataHandlerState: FormDataHandlerState<TFormData>;
  onError: (error: FormDataHandlerStateCalculationError<TFormData>) => void;
}): FormDataHandlerState<TFormData> {
  const values = {
    ...viewModel.values,
    ...updatedData,
  };

  const calculatedValues = computeCalculatedProperties(
    values,
    viewModel.propertiesToBeCalculated,
    (cyclicDependencyProperty) =>
      onError({
        code: CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES.cyclicDependencyInCalculatedProperties,
        meta: {
          values: {
            cyclicDependencyProperty,
          },
        },
      }),
  );

  if (calculatedValues.errors) {
    onError({
      code: CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES.calculateProperties,
      meta: {
        values: {
          errors: calculatedValues.errors,
        },
      },
    });
  }

  const calculatedPropertyKeys: Set<string | number | symbol> = new Set(
    toPairs(viewModel.propertiesToBeCalculated).map(([key]) => key),
  );

  const updatedState = updateFormDataHandlerState(
    toPairs({ ...values, ...calculatedValues.properties }),
    formDataHandlerState,
    {
      isEqual: _isEqual,
      isChanged: (_v1, _v2, { isEqual, property }) =>
        !isEqual && !calculatedPropertyKeys.has(property),
      normalizeValue,
      validationMode: resolveValidationModeFunction('all'),
      updateState: ({ formData }) => formData,
    },
  );

  return updatedState;
}
