import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SwitchLocale } from './locale.actions';
import { LocalePartialState } from './locale.reducer';
import { localeQuery } from './locale.selectors';

@Injectable({ providedIn: 'root' })
export class LocaleFacade {
  constructor(private store: Store<LocalePartialState>) {}

  getLocale$(): Observable<string | undefined> {
    return this.store.select(localeQuery.getLocale);
  }

  switchLocale(locale: string, auto?: boolean, preferredInBrowser?: boolean) {
    this.store.dispatch(new SwitchLocale({ locale, auto, preferredInBrowser }));
  }
}
