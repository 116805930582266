import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { map, shareReplay, take, switchMap, catchError } from 'rxjs/operators';
import prune from 'json-prune';
import { createMessage } from './create-message';
import {
  Message,
  MessageAction,
  MessageOptions,
  MessageSender,
  MessageServiceOptions,
  MessageSeverity,
  ReducedToastrService,
} from './message.service.type';
import { ReactiveToast } from './reactive-toast';

export const TIMEOUT = {
  short: 3000,
  medium: 4000,
  long: 10000,
};

const DEFAULT_TITLE = {
  info: 'Information',
  warning: 'Warning',
  error: 'Error',
  success: 'Success',
  debug: 'Debug',
};

const sanitizerConfig = {
  allowedTags: ['a', 'strong', 'br', 'p'],
  allowedAttributes: {
    a: ['href', 'target'],
  },
  disallowedTagsMode: 'escape' as const,
};

const maxCount = 100;

export const MessageServiceOptionsToken =
  new InjectionToken<MessageServiceOptions>('message-service-options');

@Injectable({
  providedIn: 'root',
})
export class MessageService implements MessageSender, OnDestroy {
  private readonly messageIdToToasts = new Map<Symbol, Set<ReactiveToast>>();
  private readonly subscriptions = new Subscription();
  private readonly options: MessageServiceOptions;

  constructor(
    // Starting with angular 9 injecting the ToastrService directly results in
    // cannot instantiate cyclic dependency! ApplicationRef
    // see: https://github.com/scttcper/ngx-toastr/issues/137#issuecomment-309217168
    @Inject(Injector) private readonly injector: Injector,
    @Optional()
    @Inject(MessageServiceOptionsToken)
    options?: Partial<MessageServiceOptions> | null,
  ) {
    this.options = {
      titles: DEFAULT_TITLE,
      count: {
        max: maxCount,
        title: (count: number, title: string) =>
          `${title}${
            count <= 1
              ? ''
              : count < maxCount
              ? ` (${count})`
              : `(>${maxCount - 1})`
          }`,
      },
      enableDebug: false,
      enableLogging: ['error'],
      ...options,
      timeouts: { ...TIMEOUT, ...(options && options.timeouts) },
    };
  }

  private get toasterService(): ReducedToastrService {
    return this.injector.get(ToastrService);
  }

  message<TValues = undefined>(
    messageOrFunction: Message<TValues>,
    type: MessageSeverity,
    {
      id,
      actions = [{ type: 'push-or-count' }],
      timeOut = true,
      values,
      error,
      enableHtml = false,
      isSkipped$ = of(false),
    }: MessageOptions<TValues> = {},
  ) {
    const shouldLog =
      this.options.enableLogging &&
      (this.options.enableLogging === true ||
        (this.options.enableLogging.length &&
          this.options.enableLogging.includes(type)));
    const isDebugEnabled = this.options.enableDebug;
    const shouldShow = isDebugEnabled || type !== 'debug';

    if (shouldLog || shouldShow) {
      const message$ = createMessage(
        messageOrFunction,
        values,
        enableHtml,
        sanitizerConfig,
      ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

      if (shouldLog) {
        const subscription = message$
          .pipe(take(1))
          .subscribe(({ message, title }) => {
            if (error) {
              console.error(
                ...[type, title, message, error, values].filter((v) => !!v),
              );
            } else {
              console.log(
                ...[type, title, message, error, values].filter((v) => !!v),
              );
            }
          });

        if (!subscription.closed) {
          subscription.add(() => this.subscriptions.remove(subscription));
          this.subscriptions.add(subscription);
        }
      }

      if (shouldShow) {
        let toasts = id && this.messageIdToToasts.get(id);

        for (const action of actions) {
          const toastsWithSameIdExist = toasts && toasts.size > 0;

          if (
            action.type === 'push' ||
            action.type === 'update' ||
            (action.type === 'once' && !toastsWithSameIdExist) ||
            (action.type === 'push-or-count' && !toastsWithSameIdExist) ||
            (action.type === 'single' && !toastsWithSameIdExist)
          ) {
            const toast = new ReactiveToast(
              isDebugEnabled
                ? message$.pipe(
                    map((value) => ({
                      ...value,
                      message: `<div style="overflow: scroll;">${
                        enableHtml ? value.message : escapeHtml(value.message)
                      }
                      ${
                        values
                          ? `<hr>Values:<pre>${escapeHtml(
                              JSON.stringify(values, null, 2),
                            )}</pre>`
                          : ''
                      } ${
                        error
                          ? `<hr>${escapeHtml(
                              error.toString(),
                            )}<hr>Stack:<pre>${
                              error.stack ? escapeHtml(error.stack) : ''
                            }</pre>`
                          : ''
                      }</div>`,
                    })),
                  )
                : message$,
              type,
              enableHtml || isDebugEnabled,
              timeOut,
              isSkipped$,
              this.options,
              isDebugEnabled,
              this.toasterService,
              onTapCopyToClipboard(message$, { type, error, values }),
            );

            if (toasts && action.type === 'update') {
              Array.from(toasts.values()).forEach((toastToRemove) =>
                toastToRemove.remove(),
              );
            }

            if (id) {
              if (!toasts) {
                toasts = new Set();
                this.messageIdToToasts.set(id, toasts);
              }

              toasts.add(toast);
            }

            const subscription = pushToast(toast, this.subscriptions);

            if (!subscription.closed) {
              subscription.add(() =>
                deleteToastFromToasts(toast, action.type, toasts),
              );
            } else {
              deleteToastFromToasts(toast, action.type, toasts);
            }
          } else if (action.type === 'remove') {
            const toastsToRemove =
              this.messageIdToToasts.get(action.messageId) || new Set();
            Array.from(toastsToRemove.values()).forEach((toastToRemove) =>
              toastToRemove.remove(),
            );
          } else if (action.type === 'push-or-count' && toasts) {
            Array.from(toasts.values()).forEach((toastToPush) => {
              pushToast(toastToPush, this.subscriptions);
            });
          }
        }
      }
    }
  }

  remove(messageIds: ReadonlyArray<Symbol>) {
    for (const messageId of messageIds) {
      const toastsToRemove = this.messageIdToToasts.get(messageId) || new Set();
      Array.from(toastsToRemove.values()).forEach((toastToRemove) =>
        toastToRemove.remove(),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.messageIdToToasts.clear();
  }
}

function pushToast(toast: ReactiveToast, subscriptions: Subscription) {
  const subscription = toast.push();

  if (!subscription.closed) {
    subscription.add(() => subscriptions.remove(subscription));
    subscriptions.add(subscription);
  }
  return subscription;
}

function deleteToastFromToasts(
  toast: ReactiveToast,
  actionType: MessageAction['type'],
  toasts?: Set<ReactiveToast>,
) {
  if (actionType !== 'once' && toasts) {
    toasts.delete(toast);
  }
}

function escapeHtml(text: string): string {
  return text.replace(/&/g, '&amp;').replace(/</g, '&lt;');
}

function onTapCopyToClipboard<TValues = undefined>(
  message$: Observable<{ title?: string; message: string }>,
  errorInfo: {
    type: MessageSeverity;
    error: unknown;
    values: TValues;
  },
) {
  return () =>
    message$
      .pipe(
        switchMap((message) =>
          copyToClipboard(
            JSON.stringify(
              JSON.parse(
                prune({ ...message, ...errorInfo }, { allProperties: true }),
              ),
              null,
              2,
            ),
          ),
        ),
      )
      .pipe(
        catchError((error) => {
          console.error('Copy to clipboard failed.', error);
          return EMPTY;
        }),
      );
}

async function copyToClipboard(text: string) {
  if (window.isSecureContext) {
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text);
    } else {
      throw new Error('Browser has no support for native clipboard!');
    }
  } else {
    throw new Error('Clipboard is only available in secure contexts!');
  }
}
