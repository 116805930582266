import { Inject, Injectable } from '@angular/core';
import {
  TypedJsonApiService,
  getHrefFromLink,
} from '@bbraun/shared/util-jsonapi';
import { concatMap, first, map, mergeMap, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EXPERT_LOADER_API_SERVICE } from '../injection-tokens';
import { ExpertLoaderJsonApi } from '../expert-loader-json-api.type';

export type DataTransferResponse =
  | { status: 'success' }
  | { status: 'error'; message: unknown };

@Injectable({
  providedIn: 'root',
})
export class DataTransferService {
  constructor(
    @Inject(EXPERT_LOADER_API_SERVICE)
    private readonly typedApiService: TypedJsonApiService<ExpertLoaderJsonApi>,
    private readonly http: HttpClient,
  ) {}

  start(): Observable<DataTransferResponse> {
    return this.typedApiService
      .index()
      .pipe(map((indexResponse) => indexResponse?.links.startLoad))
      .pipe(
        mergeMap((linkObject) => {
          const link = linkObject ? getHrefFromLink(linkObject) : undefined;

          return link
            ? this.http.post(link, null, {
                observe: 'response',
              })
            : of({
                status: 'error',
                message: 'startLoad link not found',
              } as const);
        }),
      )
      .pipe(
        concatMap((response) =>
          response instanceof HttpResponse && response.status === 200
            ? of({ status: 'success' } as const)
            : response instanceof HttpResponse
            ? of({ status: 'error', message: response } as const)
            : of(response),
        ),
      )
      .pipe(first());
  }
}
