/*
    lookup currency numbers at: https://www.currency-iso.org/dam/downloads/lists/list_one.xml 
*/
import { code, CurrencyCodeRecord, data as currencyData } from 'currency-codes';

const filterSupranationalCurrencies = (
  currencyCodeRecord: CurrencyCodeRecord,
) => !currencyCodeRecord.code.startsWith('X');
const filterBlacklistedIds = (currencyRecord: CurrencyCodeRecord) =>
  !currencyIdsToRemove.has(currencyRecord.number);

const currencyIdsToRemove: Set<string> = new Set([
  // 'Codes specifically reserved for testing purposes'
  '963',
  // 'The codes assigned for transactions where no currency is involved'
  '999',
  // Funds
  '984',
  '990',
  '970',
  '979',
  '947',
  '948',
  '997',
  '940',
]);

export function getCurrencyFromCode(currencyCode: string) {
  return code(currencyCode);
}

export const CURRENCIES = currencyData
  .filter(filterBlacklistedIds)
  .filter(filterSupranationalCurrencies);
