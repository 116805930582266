import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function fromQueryList<T>(queryList: {
  readonly changes: Observable<unknown>;
  toArray(): ReadonlyArray<T>;
}): Observable<ReadonlyArray<T>> {
  return queryList.changes
    .pipe(map(() => queryList.toArray() || []))
    .pipe(startWith(queryList.toArray() || []));
}
