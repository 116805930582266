import { Inject, Injectable } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { LANGUAGE_SELECTION_DISABLED } from '../injection-tokens';
import { LocaleActionTypes, SwitchLocale } from './locale.actions';

@Injectable()
export class LocaleEffects {
  switchLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SwitchLocale>(LocaleActionTypes.SwitchLocale),
        tap((action) => {
          if (action.payload.auto) {
            if (action.payload.preferredInBrowser) {
              if (!this.languageSelectionDisabled) {
                this.messageService.message(
                  'The language has been set according to your preferred browser language. You may change it using the language menu.',
                  'info',
                );
              }
            } else {
              this.messageService.message(
                `The language has been set to english because your preferred browser language is not supported. You may change it using 
            the language menu.`,
                'info',
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private readonly actions$: Actions,
    private readonly messageService: MessageService,
    @Inject(LANGUAGE_SELECTION_DISABLED)
    private languageSelectionDisabled: boolean,
  ) {}
}
