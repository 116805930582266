<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingDesktop';
    read: 'bbraunBavReportingDesktop.appComponent'
  "
>
  <div class="bbraun-container">
    <bbraun-ui-toolbar
      appName="bav-reporting"
      logoUrl="assets/avitum.svg"
      [routerLinkString]="defaultRoute"
      [isSidebarOpen]="isSidebarOpen"
      [hideBackButton]="!!(hideBackButton$ | async)"
      (toggleSidebar)="toggleSidebar()"
      [enableLoadingIndicator]="true"
      [isLoading]="!!(isLoadBarActive$ | async)"
    >
    </bbraun-ui-toolbar>

    <bbraun-ui-sidebar
      (toggled)="sidebarToggled($event)"
      (loginRequested)="loginRequested()"
      (logoutRequested)="logoutRequested()"
      [isPrincipalLoggedIn]="!!(isPrincipalLoggedIn$ | async)"
      [principal]="principal$ | async"
    >
      <div class="bbraun-content-wrapper" ngProjectAs="[page-content]">
        <bav-reporting-ui-common-error
          *ngIf="!(isLoadPanelVisible$ | async) && !isRouterOutletActivated"
        >
        </bav-reporting-ui-common-error>
        <div
          *ngIf="!(isLogoutInProgress$ | async)"
          class="bbraun-router-container"
        >
          <bbraun-ui-discard-changes-changed-guard-router-outlet
            class="bbraun-router-content"
            (activate)="activate()"
            (deactivate)="deactivate()"
          ></bbraun-ui-discard-changes-changed-guard-router-outlet>
        </div>
      </div>
      <mat-nav-list ngProjectAs="[sidebar-content]">
        <mat-list-item *ngIf="isPrincipalLoggedIn$ | async">
          <mat-icon>list_alt</mat-icon>
          <a [routerLink]="['app/reports']">{{
            translate('dashboardNavigation.text')
          }}</a>
        </mat-list-item>
        <mat-accordion
          *ngIf="
            (isPrincipalLoggedIn$ | async) && (canAccessAdministration$ | async)
          "
          class="nested-list"
        >
          <div class="flex-box align-items-center">
            <mat-icon>settings</mat-icon>
            <span class="underline">{{
              translate('administrationNavigationHeader.text')
            }}</span>
          </div>
          <mat-nav-list>
            <mat-list-item
              *ngIf="
                (isPrincipalLoggedIn$ | async) &&
                (canReadLabTestMappingGuard.canActivate$ | async)
              "
            >
              <mat-icon svgIcon="bbraun:lab-value"></mat-icon>
              <a
                [routerLink]="[
                  'app/administration/lab-data-test-configuration'
                ]"
                >{{ translate('labDataTestConfigurationNavigation.text') }}</a
              >
            </mat-list-item>
            <mat-list-item
              *ngIf="
                (isPrincipalLoggedIn$ | async) &&
                (canReadUserLogsGuard.canActivate$ | async)
              "
            >
              <mat-icon>article</mat-icon>
              <a [routerLink]="['app/administration/user-logs']">{{
                translate('userLogsNavigation.text')
              }}</a>
            </mat-list-item>
          </mat-nav-list>
        </mat-accordion>
        <mat-list-item *ngIf="!languageSelectionDisabled">
          <mat-icon>language</mat-icon>
          <a [routerLink]="['app/language']">{{
            translate('languageNavigation.text')
          }}</a>
        </mat-list-item>
      </mat-nav-list>
    </bbraun-ui-sidebar>
  </div>
</ng-container>
<dx-load-panel
  [closeOnOutsideClick]="false"
  [visible]="isLoadPanelVisible$ | async"
  shadingColor="rgba(0, 0, 0, 0.2)"
>
</dx-load-panel>
