import { Observable, of } from 'rxjs';
import { StatePersistenceStrategy } from './state-persistence-strategy';

export class InMemoryStatePersistenceStrategy
  implements StatePersistenceStrategy
{
  private locales = new Map<string, any>();

  get<V>(key: string): Observable<V | undefined> {
    return of(this.locales.get(key));
  }

  store<V>(key: string, value?: V) {
    if (value === null || value === undefined) {
      this.locales.delete(key);
    } else {
      this.locales.set(key, JSON.stringify(value));
    }
  }
}
