import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@bbraun/shared/util-message-ng';
import {
  connectToLoadBarService,
  LoadBarService,
} from '@bbraun/shared/util-navigation';
import {
  blockingOperationHandler,
  CallableOperationFn,
  hasValue,
} from '@bbraun/shared/util-rxjs';
import { translate } from '@ngneat/transloco';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import {
  CanCreateAisReportsGuard,
  CenterConfiguration,
  ReportListResponseModel,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import { CanStartTransferGuard } from '@bbraun/bav-reporting/data-access-administration';
import { FromResolver } from '@bbraun/shared/util-lang-ng';
import moment from 'moment';
import { ReportListFilterModel } from '../../components/report-list-filter/report-list-filter.store';
import { ReportingService } from '../../services/reporting.service';
import { CurrentDateService } from '../../services/current-date.service';
import { getPreviousMonth } from '../../functions/get-previous-month';
import { ReportListResolver } from '../../resolvers/report-list.resolver';

interface ReportListViewModel {
  id: string;
  date: {
    month: number;
    year: number;
  };
  state: 'Draft' | 'Completed';
}

interface ReportListState {
  center: CenterConfiguration | undefined;
  reports: ReadonlyArray<ReportListViewModel>;
  doesReportForLastMonthExist: boolean;
}

const REPORT_LOADING = Symbol('REPORT_LOADING');

@Component({
  selector: 'bav-reporting-feature-ais-reports-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportListComponent implements OnInit, OnDestroy {
  readonly reportListState$: Observable<ReportListState | undefined>;

  private readonly subscriptions = new Subscription();

  private readonly filterChangedSubject = new Subject<{
    filter: ReportListFilterModel;
    centerId: string;
  }>();

  private readonly isReportHandlerRunning$: Observable<boolean>;

  private readonly lastMonthReportId: string | undefined;

  constructor(
    private reportingService: ReportingService,
    private router: Router,
    messageService: MessageService,
    private loadbarService: LoadBarService,
    route: ActivatedRoute,
    currentDateService: CurrentDateService,
    public readonly canStartTransferGuard: CanStartTransferGuard,
    public readonly canCreateAisReportsGuard: CanCreateAisReportsGuard,
  ) {
    const { reportList, centerConfiguration } = route.snapshot.data
      .reports as FromResolver<ReportListResolver>;

    const lastMonth = getPreviousMonth(currentDateService.currentDate());

    const reportForLastMonth = reportList.find(
      (report) =>
        report.month === lastMonth.month && report.year === lastMonth.year,
    );

    const initialState = {
      reports: mapToReportListViewModel(reportList),
      doesReportForLastMonthExist: !!reportForLastMonth,
      center: centerConfiguration,
    };

    this.lastMonthReportId = reportForLastMonth?.id;

    const updateTask$ = this.filterChangedSubject.pipe(
      map(
        ({ centerId, filter: filterOptions }) =>
          () =>
            this.reportingService
              .getReports(centerId, filterOptions)
              .pipe(map((reports) => mapToReportListViewModel(reports))),
      ),
    );

    const updateHandler = createUpdateBlockingOperationHandler(
      messageService,
      updateTask$,
    );

    this.reportListState$ = concat(
      of(initialState),
      updateHandler.results
        .pipe(filter(hasValue))
        .pipe(map(({ value }) => value))
        .pipe(
          map((updatedReports) => ({
            reports: updatedReports,
            doesReportForLastMonthExist:
              initialState.doesReportForLastMonthExist,
            center: initialState.center,
          })),
        ),
    )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.isReportHandlerRunning$ = updateHandler.running;
  }

  ngOnInit() {
    this.subscriptions.add(
      this.isReportHandlerRunning$
        .pipe(
          map((isRunning) => ({
            id: REPORT_LOADING,
            value: isRunning,
          })),
        )
        .pipe(connectToLoadBarService(this.loadbarService))
        .subscribe(),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onRowClick(id: string) {
    this.router.navigate([`app/reports/${id}`]);
  }

  onFilterChanged(
    filterOptions: ReportListFilterModel,
    center: CenterConfiguration | undefined,
  ) {
    if (center) {
      this.filterChangedSubject.next({
        filter: filterOptions,
        centerId: center.id,
      });
    }
  }

  onRowPrepared(rowElement: HTMLElement, id: string | undefined): void {
    if (id && this.lastMonthReportId && id === this.lastMonthReportId) {
      rowElement.classList.add('highlighted-row');
    }
  }

  dateColumnSortingMethod(
    value1: { month: number; year: number },
    value2: { month: number; year: number },
  ) {
    return moment(value1).isBefore(moment(value2)) ? -1 : 1;
  }
}

function createUpdateBlockingOperationHandler(
  messageService: MessageService,
  source: Observable<CallableOperationFn<ReadonlyArray<ReportListViewModel>>>,
) {
  return blockingOperationHandler<ReadonlyArray<ReportListViewModel>>(
    {
      error: (error) => {
        messageService.message(
          translate(
            i18n(
              'bbraunBavReportingFeatureAisReports.reportListComponent.loadReports.failed.message',
            ),
          ),
          'error',
          { error },
        );
      },
    },
    { strategy: 'single', autoConnectResult: false, source },
  );
}

function mapToReportListViewModel(
  reports: ReadonlyArray<ReportListResponseModel>,
): ReadonlyArray<ReportListViewModel> {
  return reports.map((report) => ({
    id: report.id,
    date: { year: report.year, month: report.month },
    state: report.state,
  }));
}
