import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideableContainerComponent } from './components/hideable-container/hideable-container.component';
import { ToggleContainerDirective } from './directives/toggle-container.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HideableContainerComponent, ToggleContainerDirective],
  exports: [HideableContainerComponent, ToggleContainerDirective],
})
export class UiHideableContainerModule {}
