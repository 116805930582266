import { onSubscription } from '@bbraun/shared/util-rxjs';
import { MonoTypeOperatorFunction } from 'rxjs';
import { LoadBarService } from './load-bar.service';

export function connectLoadingBar<T>(
  loadbarService: LoadBarService,
  symbol: Symbol,
): MonoTypeOperatorFunction<T> {
  return (o) =>
    o.pipe(
      onSubscription({
        onSubscribe: () => loadbarService.addLoadingProcess(symbol),
        onUnsubscribe: () => loadbarService.stopLoadingProcess(symbol),
      }),
    );
}
