import { isObservable, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import * as sanitizeHtml from 'sanitize-html';
import { Message } from './message.service.type';

export function createMessage<TValues>(
  messageOrFunction: Message<TValues>,
  values: TValues | undefined,
  enableHtml: boolean,
  sanitizerConfig?: sanitizeHtml.IOptions,
): Observable<{ title?: string; message: string }> {
  return of(
    typeof messageOrFunction === 'function'
      ? messageOrFunction(values)
      : messageOrFunction,
  )
    .pipe(concatMap((v) => (isObservable(v) ? v : of(v))))
    .pipe(
      map((messageAsStringOrObject) =>
        typeof messageAsStringOrObject === 'string'
          ? { message: messageAsStringOrObject, title: undefined }
          : messageAsStringOrObject,
      ),
    )
    .pipe(
      map(({ message, title }) => ({
        message:
          typeof message === 'string' && !enableHtml
            ? message
            : sanitizeHtml(message, sanitizerConfig),
        title,
      })),
    );
}
