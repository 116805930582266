export function splitArrayByLength<T>(
  values: ReadonlyArray<T>,
  chunkSize: number,
): Array<Array<T>> {
  if (chunkSize > 0) {
    const chunks: Array<Array<T>> = [];
    for (let index = 0; index < values.length; index = index + chunkSize) {
      chunks.push(values.slice(index, index + chunkSize));
    }

    return chunks;
  } else {
    throw new Error(
      `Invalid chunk size of ${chunkSize}. Chunk size has to be > 0.`,
    );
  }
}
