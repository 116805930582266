import { Injectable } from '@angular/core';
import { ScopedTranslationService } from '@bbraun/shared/util-transloco-ng';
import { TranslocoService } from '@ngneat/transloco';

export const scope = 'bbraunSharedUiLanguage';

@Injectable({ providedIn: 'root' })
export class ShareUiLanguageTranslationService extends ScopedTranslationService {
  constructor(translocoService: TranslocoService) {
    super(scope, translocoService);
  }
}
