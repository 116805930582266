import { EMPTY, merge, Observable, of, PartialObserver } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

type HasValue<T> = { hasValue: true; value: T } | { hasValue: false };

export type SubscriptionHandlers<T> =
  | {
      onSubscribe?: () => void;
      afterSubscribe?: () => void;
      withLatestValue?: false;
      onUnsubscribe?: () => void;
    }
  | {
      onSubscribe?: () => void;
      afterSubscribe?: () => void;
      withLatestValue: true;
      onUnsubscribe: (value: HasValue<T>) => void;
    };

export function withSubscriptionHandlers<T>(
  observable$: Observable<T>,
  {
    withLatestValue = false,
    onSubscribe = () => {},
    afterSubscribe = () => {},
    onUnsubscribe = () => {},
  }: SubscriptionHandlers<T> = {},
): Observable<T> {
  return new Observable<PartialObserver<T>>((subscriber) => {
    let latestValue: HasValue<T> = { hasValue: false };
    onSubscribe();

    subscriber.next({
      complete: () => subscriber.complete(),
      next: (value: T) => {
        if (withLatestValue) {
          latestValue = { hasValue: true, value };
        }
      },
    });

    return () => {
      onUnsubscribe(latestValue);
    };
  }).pipe(
    switchMap((handlers) =>
      merge(
        observable$.pipe(tap(handlers)),
        of(undefined)
          .pipe(tap(() => afterSubscribe()))
          .pipe(switchMap(() => EMPTY)),
      ),
    ),
  );
}
