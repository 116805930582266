export type AsRequired<T, TKey extends keyof T> = {
  [key in TKey]-?: Exclude<T[key], undefined | null>;
} & {
  [key in Exclude<keyof T, TKey>]: T[key];
};

export function asRequired<T, TKey extends keyof T>(
  value: T,
  requiredKeys: ReadonlyArray<TKey>,
): AsRequired<T, TKey> | false {
  const isValid = requiredKeys.every(
    (key) => value[key] !== null && value[key] !== undefined,
  );
  return isValid && (value as unknown as AsRequired<T, TKey>);
}
