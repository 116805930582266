import {
  Message,
  MessageOptions,
  MessageSeverity,
} from '@bbraun/shared/util-message-ng';
import { TranslocoService } from '@ngneat/transloco';

const messageObjectClassIdentifier = Symbol('messageObjectClassIdentifier');
const classIdentifier = Symbol('classIdentifier');

export class MessageObject<TValues> {
  readonly [classIdentifier] = messageObjectClassIdentifier;

  constructor(
    readonly messageOrFn:
      | Message<TValues>
      | ((
          values: TValues | undefined,
          lang: string,
          translocoService: TranslocoService,
        ) => string | { message: string; title?: string }),
    readonly severity: MessageSeverity,
    readonly options?: MessageOptions<TValues>,
  ) {}
}
