import { Inject, Injectable } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';

const REDIRECT_URL = 'redirectUrl';
const failedToLoadRedirectUrlMessageId = Symbol(
  'redirectUrlAfterLoginService.failedToLoadRedirectUrlMessageId',
);
const failedToSaveRedirectUrlMessageId = Symbol(
  'redirectUrlAfterLoginService.failedToSaveRedirectUrlMessageId',
);
const failedToRemoveRedirectUrlMessageId = Symbol(
  'redirectUrlAfterLoginService.failedToRemoveRedirectUrlMessageId',
);

@Injectable({
  providedIn: 'root',
})
export class RedirectUrlAfterLoginService {
  constructor(
    @Inject(MessageService)
    private readonly messageService: { message: MessageService['message'] },
  ) {}

  getUrl() {
    try {
      const item = sessionStorage.getItem(REDIRECT_URL);
      return item || undefined;
    } catch (error) {
      this.messageService.message(
        'Can not use redirect specific session storage. Failed to load redirect url.',
        'debug',
        {
          id: failedToLoadRedirectUrlMessageId,
          error,
          actions: [{ type: 'push-or-count' }],
        },
      );
      return undefined;
    }
  }

  setUrl(url: string) {
    try {
      sessionStorage.setItem(REDIRECT_URL, url);
    } catch (error) {
      this.messageService.message(
        'Can not use redirect specific session storage. Failed to save redirect url.',
        'debug',
        {
          id: failedToSaveRedirectUrlMessageId,
          error,
          actions: [{ type: 'push-or-count' }],
        },
      );
    }
  }

  removeUrl() {
    try {
      sessionStorage.removeItem(REDIRECT_URL);
    } catch (error) {
      this.messageService.message(
        'Can not use redirect specific session storage. Failed to remove redirect url.',
        'debug',
        {
          id: failedToRemoveRedirectUrlMessageId,
          error,
          actions: [{ type: 'push-or-count' }],
        },
      );
    }
  }
}
