import { Inject, Injectable, Optional } from '@angular/core';
import {
  TypedJsonApiServiceResourceGuard,
  TypedJsonApiServiceResourceGuardOptions,
  TYPED_JSON_API_SERVICE_RESOURCE_GUARD_OPTIONS,
} from '@bbraun/shared/util-jsonapi-ng';
import { ExpertLoaderJsonApi } from '../expert-loader-json-api.type';
import {
  ExpertLoaderApiService,
  EXPERT_LOADER_API_SERVICE,
} from '../injection-tokens';

@Injectable({ providedIn: 'root' })
export class CanSynchronizeLabTestMappingGuard extends TypedJsonApiServiceResourceGuard<
  ExpertLoaderJsonApi,
  'lab-test-mapping'
> {
  constructor(
    @Inject(EXPERT_LOADER_API_SERVICE)
    apiService: ExpertLoaderApiService,
    @Optional()
    @Inject(TYPED_JSON_API_SERVICE_RESOURCE_GUARD_OPTIONS)
    options?: TypedJsonApiServiceResourceGuardOptions,
  ) {
    super(apiService, 'lab-test-mapping', options, 'synchronize');
  }
}
