import { FiqlExpression } from '@bbraun/shared/util-fiql';
import { ConvertOptions } from './convert-options.type';
import { DxFilter } from './dx-filter';
import { DxFilterGroup, isDxFilterGroup } from './dx-filter-group';
import { isDxFilterGroupOperator } from './dx-filter-group-operator';
import { isDxFilterOperation } from './dx-filter-operation';
import { isDxFilterUnaryOperation } from './dx-filter-unary-operation';

export function convertDxFilterGroupToFiql(
  dxFilterGroup: DxFilterGroup,
  options: ConvertOptions,
): FiqlExpression {
  if (!isDxFilterGroup(dxFilterGroup)) {
    throw new Error(`Filter group is invalid!`);
  }

  const { operationToConstraint, groupToExpression } = options;

  let and = true;
  const groupOperators = dxFilterGroup.filter((e) =>
    isDxFilterGroupOperator(e),
  );
  if (groupOperators.length > 0) {
    and = groupOperators.indexOf('and') !== -1;
    const hasOtherOperator = groupOperators.indexOf(and ? 'or' : 'and') !== -1;
    if (hasOtherOperator) {
      throw new Error(
        'Mixing group operators in one group is currently not supported!',
      );
    }
  }

  const operationsAndGroups = dxFilterGroup.filter(
    (e) =>
      isDxFilterOperation(e) ||
      isDxFilterUnaryOperation(e) ||
      isDxFilterGroup(e),
  ) as DxFilter[];

  const convertedExpressions = operationsAndGroups.map((dxFilter) => {
    // cannot extract this code (avoid cyclic dependencies)
    if (isDxFilterOperation(dxFilter)) {
      return operationToConstraint(dxFilter, false);
    } else if (isDxFilterGroup(dxFilter)) {
      return groupToExpression(dxFilter, options);
    } else if (isDxFilterUnaryOperation(dxFilter)) {
      const operation = dxFilter[1];
      return operationToConstraint(operation, true);
    } else {
      throw new Error(`Filter not supported: ${JSON.stringify(dxFilter)}!`);
    }
  });

  if (and) {
    return {
      and: convertedExpressions,
    };
  } else {
    return {
      or: convertedExpressions,
    };
  }
}
