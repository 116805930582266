import {
  PropertyPathBuilder,
  PropertyPathBuilder0,
} from './property-path-builder.type';

class PropertyPathBuilderImpl<TStart, TCurrent>
  implements PropertyPathBuilder<TStart, TCurrent>
{
  constructor(
    readonly path: Array<string | number | symbol>,
    readonly isBroken = false,
  ) {}

  add<TKey extends keyof TCurrent>(segment: TKey) {
    return new PropertyPathBuilderImpl<TStart, Exclude<TCurrent[TKey], null>>(
      [...this.path, segment],
      this.isBroken,
    );
  }

  anyIndex() {
    return new PropertyPathBuilderImpl(this.path, true);
  }

  join(separator: string): string {
    return this.path.join(separator);
  }

  apply(value: TStart): TCurrent {
    if (!this.isBroken) {
      let current: any = value;

      let isNullValue = false;
      for (let i = 0; i < this.path.length && !isNullValue; i++) {
        current = current[this.path[i]];
        isNullValue = current === null || current === undefined;
      }

      return current;
    } else {
      throw new Error(
        'This is a broken path and can not be applied. Maybe the path contains an array but no index access.',
      );
    }
  }
}

export function createPropertyPathBuilder<
  T = never,
>(): PropertyPathBuilder0<T> {
  const b = new PropertyPathBuilderImpl<T, T>([]);
  return b as any;
}
