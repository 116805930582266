import { InjectionToken } from '@angular/core';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';
import { Observable } from 'rxjs';

export const DEVEXPRESS_LOCALE = new InjectionToken<Observable<string>>(
  'DEVEXPRESS_LOCALE',
);

export const SHARED_UTIL_DEVEXPRESS_MESSAGE_SERVICE =
  new InjectionToken<TranslocoMessageService>(
    'SHARED_UTIL_DEVEXPRESS_MESSAGE_SERVICE',
  );
