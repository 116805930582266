import { getConfigFromRouteSnapshot } from './get-config-from-route-snapshot';

export interface Snapshot {
  data: Readonly<{ skipLocationChange?: boolean; replaceUrl?: boolean }>;
  children: ReadonlyArray<Readonly<Snapshot>>;
}

export function isReplaceUrlRoute(
  snapshot: Readonly<Snapshot>,
): boolean | undefined {
  return getConfigFromRouteSnapshot(snapshot, 'replaceUrl');
}
