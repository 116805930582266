<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiSidebar';
    read: 'bbraunSharedUiSidebar.sidebarComponent'
  "
>
  <mat-sidenav-container
    (click)="close()"
    [attr.data-test]="dataTest.sidebar.selector"
  >
    <mat-sidenav
      mode="over"
      [fixedInViewport]="false"
      (keydown.escape)="toggle()"
      disableClose
    >
      <div
        class="principal-container"
        [ngClass]="{ 'logged-in': isPrincipalLoggedIn }"
        [attr.data-test]="dataTest.sidebarContent.selector"
      >
        <mat-icon>account_circle</mat-icon>
        <ng-container *ngIf="isPrincipalLoggedIn">
          <div *ngIf="principal">
            <a
              *ngIf="isLinkEnabled && principal.id; else nameWithoutLink"
              [routerLink]="['/app/users/profile', principal.id]"
              >{{ principal.name }}</a
            >
            <ng-template #nameWithoutLink>
              <span>{{ principal.name }}</span>
            </ng-template>
          </div>
          <div *ngIf="!principal">
            {{ translate('unnamedUser.text') }}
          </div>
        </ng-container>
        <ng-container *ngIf="!isPrincipalLoggedIn">
          <div>
            {{ translate('userLoggedOff.text') }}
          </div>
        </ng-container>
      </div>
      <div class="login-container">
        <button
          mat-button
          *ngIf="isPrincipalLoggedIn"
          (click)="logout()"
          [attr.data-test]="dataTest.logoutButton.selector"
        >
          {{ translate('logoffButton.text') }}
        </button>
        <button
          mat-button
          *ngIf="!isPrincipalLoggedIn"
          (click)="login()"
          color="accent"
        >
          {{ translate('loginButton.text') }}
        </button>
      </div>
      <ng-content select="[sidebar-content]"></ng-content>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content select="[page-content]"></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
