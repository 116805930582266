import { Inject, Injectable } from '@angular/core';
import { JwtConfig } from '../interfaces/jwt-config.type';
import { SECURITY_CONFIG } from '../interfaces/security-config';
import { convertMatcher } from '../utils/convert-matcher';

@Injectable()
export class SecurityConfigService {
  private readonly whiteListedDomains: Array<string | RegExp>;
  private readonly blacklistedRoutes: Array<string | RegExp>;

  constructor(
    @Inject(SECURITY_CONFIG)
    config: Pick<JwtConfig, 'whiteListedDomains' | 'blacklistedRoutes'>,
  ) {
    this.whiteListedDomains = config.whiteListedDomains.map(convertMatcher);
    this.blacklistedRoutes = config.blacklistedRoutes.map(convertMatcher);
  }

  isWhitelistedDomain(url: string): boolean {
    const host = new URL(url, location.origin).host;
    return !host || this.matches(host, this.whiteListedDomains);
  }

  isBlacklistedRoute(url: string): boolean {
    return this.matches(url, this.blacklistedRoutes);
  }

  private matches(search: string, matchers: Array<string | RegExp>): boolean {
    return (
      matchers.findIndex((matcher) =>
        typeof matcher === 'string'
          ? matcher === search
          : matcher instanceof RegExp
          ? matcher.test(search)
          : false,
      ) > -1
    );
  }
}
