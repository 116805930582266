import { Action } from '@ngrx/store';

export enum LocaleActionTypes {
  SwitchLocale = '@bbraun/shared/data-access-locale/switch-locale', // eslint-disable-line @typescript-eslint/no-shadow
}

export class SwitchLocale implements Action {
  readonly type = LocaleActionTypes.SwitchLocale;

  constructor(
    public payload: {
      locale: string;
      auto?: boolean;
      preferredInBrowser?: boolean;
    },
  ) {}
}

export type LocaleAction = SwitchLocale;

export const fromLocaleActions = {
  SwitchLocale,
};
