export function isAllTypesOf<T, T1 extends T, T2 extends T>(
  guard1: (value: T) => value is T1,
  guard2: (value: T) => value is T2,
): (value: T) => value is T1 & T2;
export function isAllTypesOf<T, T1 extends T, T2 extends T, T3 extends T>(
  guard1: (value: T) => value is T1,
  guard2: (value: T) => value is T2,
  guard3: (value: T) => value is T3,
): (value: T) => value is T1 & T2 & T3;
export function isAllTypesOf(
  ...guards: ReadonlyArray<(value: unknown) => boolean>
): (value: unknown) => boolean {
  return (value) => guards.every((guard) => guard(value));
}
