import { ReportDetailsResponseModel } from '@bbraun/bav-reporting/data-access-ais-reports';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { blockingOperationHandler } from '@bbraun/shared/util-rxjs';
import { translate } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';

export function createSetReportStateHandler(messageService: MessageService) {
  return blockingOperationHandler<ReportDetailsResponseModel>(
    {
      complete: (value) => {
        if (value?.state === 'Completed') {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingFeatureAisReports.editReportComponent.setReportState.completed.success.message',
              ),
            ),
            'success',
          );
        } else {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingFeatureAisReports.editReportComponent.setReportState.draft.success.message',
              ),
            ),
            'success',
          );
        }
      },
      error: (error) => {
        messageService.message(
          translate(
            i18n(
              'bbraunBavReportingFeatureAisReports.editReportComponent.setReportState.error.message',
            ),
          ),
          'error',
          { error },
        );
      },
    },
    { strategy: 'single', autoConnectResult: false },
  );
}
