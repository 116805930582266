import { MatExpansionPanel } from '@angular/material/expansion';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({ selector: '[bavReportingUiReportEditorExpandedOnce]' })
export class BavReportingUiReportEditorOnceDirective {
  private hasAlreadyExpanded = false;
  private readonly subscriptions = new Subscription();

  @Input() set bavReportingUiReportEditorExpandedOnce(
    panel: MatExpansionPanel,
  ) {
    this.subscriptions.add(
      panel.expandedChange.asObservable().subscribe((hasExpanded) => {
        if (hasExpanded && !this.hasAlreadyExpanded) {
          this.hasAlreadyExpanded = true;
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }),
    );
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}
}
