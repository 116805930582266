import { ChangeDetectionStrategy, Component, Output } from '@angular/core';
import { distinctUntilChanged, Observable, shareReplay } from 'rxjs';
import {
  createReportListFilterStore,
  ReportListFilterModel,
  ReportListFilterStore,
} from './report-list-filter.store';

@Component({
  selector: 'bav-reporting-feature-ais-reports-report-list-filter',
  templateUrl: './report-list-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./report-list-filter.component.scss'],
})
export class ReportListFilterComponent {
  @Output()
  filterChanged: Observable<ReportListFilterModel>;

  private readonly store: ReportListFilterStore;

  constructor() {
    this.store = createReportListFilterStore();

    this.filterChanged = this.store.values
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  onDateChange({
    dateBoxEvent: { value },
    action,
  }: {
    dateBoxEvent: { value: Date | null };
    action: 'from' | 'to';
  }) {
    if (action === 'from') {
      this.store.dispatchEvent({
        type: 'select-from-date',
        params: { from: value || undefined },
      });
    } else {
      this.store.dispatchEvent({
        type: 'select-to-date',
        params: { to: value || undefined },
      });
    }
  }
}
