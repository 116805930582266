import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { BBraunUiFormModule } from '@bbraun/shared/ui-form';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { defer } from 'rxjs';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import fallbackTranslation from '../i18n/en.json';
import { DiscardChangesDialogComponent } from './components/discard-changes-dialog/discard-changes-dialog.component';
import { DiscardChangesGuard } from './services/discard-changes.guard';
import { ChangedGuardRouterOutletDirective } from './directives/changed-guard-router-outlet.directive';
import { SCOPE } from './constants';
import { DiscardChangesTranslationService } from './services/discard-changes-translation.service';

@NgModule({
  imports: [CommonModule, BBraunUiFormModule, MatButtonModule, TranslocoModule],
  declarations: [
    DiscardChangesDialogComponent,
    ChangedGuardRouterOutletDirective,
  ],
  exports: [DiscardChangesDialogComponent, ChangedGuardRouterOutletDirective],
  providers: [DiscardChangesGuard, DiscardChangesTranslationService],
})
export class BBraunUiDiscardChangesModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );
  }
}
