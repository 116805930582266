<ng-container
  *transloco="
    let translate;
    scope: 'bbraunSharedUiConfirmInformationDialog';
    read: 'bbraunSharedUiConfirmInformationDialog.confirmInformationDialogComponent'
  "
>
  <div class="title-container">
    <div class="title">
      {{ title }}
    </div>
  </div>

  <div *ngIf="informationText" class="body-text-container">
    {{ informationText }}
  </div>
  <ng-content></ng-content>

  <div class="button-container mt-1">
    <button
      type="submit"
      class="align-right"
      mat-raised-button
      color="accent"
      [mat-dialog-close]="true"
    >
      {{
        confirmButtonText ? confirmButtonText : translate('confirmButton.text')
      }}
    </button>
    <button
      type="reset"
      class="align-right"
      mat-raised-button
      color="primary"
      [mat-dialog-close]="false"
    >
      {{ cancelButtonText ? cancelButtonText : translate('cancelButton.text') }}
    </button>
  </div>
</ng-container>
