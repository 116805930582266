import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import {
  combineLatest,
  concat,
  delay,
  distinctUntilChanged,
  EMPTY,
  map,
  Observable,
  of,
  scan,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import {
  CalculatedReportDetailsResponseModel,
  ReportDetailsResponseModel,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import {
  createObservables,
  isDefined,
  NEXT,
  pickDistinctUntilChanged,
} from '@bbraun/shared/util-rxjs';
import {
  fromPairs,
  mapSetToObject,
  toPairs,
  truncNumber,
} from '@bbraun/shared/util-lang';
import { DxFormDataHandler } from '@bbraun/shared/util-devexpress';
import { v4 as uuid } from 'uuid';
import { updatePropertyImmutable } from '@bbraun/shared/util-lang';
import { extractReportModelFormulaProperties } from '../../functions/extract-report-model-formula-properties';
import {
  WithIsChangedSupportComponent,
  WithIsResetSupportComponent,
} from '../../types/types';
import { computeInvalidFormFieldsLookup } from '../../functions/compute-invalid-form-fields-lookup';
import { ReportModel } from '../../types/report-model.types';
import {
  BlockIdentifiableReportModel,
  ReportEditorFormFields,
} from '../../types/report-editor.types';
import { computeBlocksLookup } from '../../functions/compute-blocks-lookup';
import { FormDataHandlerFactory } from './services/create-form-data-handler';
import {
  FormDataHandlerAction,
  UpdateReportEditorFormDataHandlerStateFunctionFactory,
} from './services/update-report-editor-form-data-handler-state-function.factory';
import { ReportEditorFormModel } from './types/report-editor-form-model.type';
import {
  InitialValues,
  ReportEditorViewModel,
} from './types/report-editor-view-model.type';

export type ReportEditorViewMode = 'view' | 'edit' | 'create';

type IsChangedPropertiesLookup = {
  [key in keyof ReportEditorFormFields]?: true;
};

const DEFAULT_REPORT_MODEL = { blocks: [] };

@Component({
  selector: 'bav-reporting-ui-report-editor-report-editor',
  templateUrl: './report-editor.component.html',
  styleUrls: ['./report-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportEditorComponent
  implements
    OnInit,
    OnDestroy,
    WithIsResetSupportComponent,
    WithIsChangedSupportComponent
{
  get isValid(): boolean {
    return this.isReportEditorValid;
  }

  get changed(): boolean {
    return this.isReportEditorChanged;
  }

  @Input() set reportModel(value: ReportModel | undefined | null) {
    this.observables[NEXT]('reportModel', value || DEFAULT_REPORT_MODEL);
  }

  @Input() set staticValues(
    value: Partial<ReportDetailsResponseModel> | undefined | null,
  ) {
    this.observables[NEXT]('staticValues', value || {});
  }

  @Input() set viewMode(value: ReportEditorViewMode | undefined | null) {
    this.observables[NEXT]('viewMode', value || 'view');
  }

  @Output() readonly expandedChanged = new EventEmitter<void>();

  @Output() readonly formChanged: Observable<ReportEditorFormModel>;

  @ViewChildren(MatExpansionPanel)
  expansionPanels?: QueryList<MatExpansionPanel>;

  @ViewChild(MatAccordion, { static: false })
  accordion?: MatAccordion;

  readonly viewModel$: Observable<ReportEditorViewModel>;
  readonly viewMode$: Observable<ReportEditorViewMode>;

  readonly isChangedProperties$: Observable<IsChangedPropertiesLookup>;

  readonly formDataHandler$: Observable<
    DxFormDataHandler<ReportEditorFormModel>
  >;

  private readonly subscriptions = new Subscription();

  private readonly initialValues$: Observable<InitialValues>;

  private readonly observables = createObservables<{
    staticValues: Partial<ReportDetailsResponseModel>;
    viewMode: ReportEditorViewMode;
    reportModel: ReportModel;
  }>({
    staticValues: {},
    viewMode: 'view',
    reportModel: DEFAULT_REPORT_MODEL,
  });

  private readonly formDataHandlerActions =
    new Subject<FormDataHandlerAction>();

  private isReportEditorValid = true;
  private isReportEditorChanged = false;

  constructor(
    private readonly updateReportEditorFormDataHandlerStateFunctionFactory: UpdateReportEditorFormDataHandlerStateFunctionFactory,
    private readonly formDataHandlerFactory: FormDataHandlerFactory,
  ) {
    const reportModelProperties$ = this.observables.reportModel
      .pipe(
        map((reportModel) =>
          reportModel.blocks.flatMap((block) =>
            block.sections.flatMap((section) =>
              section.propertyGroups.flatMap(
                (propertyGroups) => propertyGroups.properties,
              ),
            ),
          ),
        ),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    const defaultValues$ = reportModelProperties$
      .pipe(
        map((properties) =>
          properties.reduce(
            (
              acc: { [key: string]: false | null },
              { technicalName, dataType },
            ) =>
              updatePropertyImmutable(acc, technicalName, () =>
                dataType === 'boolean' ? false : null,
              ),
            {},
          ),
        ),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.formDataHandler$ = combineLatest([
      defaultValues$,
      reportModelProperties$,
    ])
      .pipe(
        switchMap(([defaultValues, properties]) =>
          this.formDataHandlerFactory.create(defaultValues, properties),
        ),
      )
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.initialValues$ = combineLatest([
      defaultValues$,
      this.observables.staticValues,
      this.observables.reportModel,
    ])
      .pipe(
        map(([defaultValues, values, reportModel]) => {
          const propertiesToBeCalculated =
            extractReportModelFormulaProperties(reportModel);
          const blockIdentifiableReportModel = {
            blocks: reportModel.blocks.map((block) => ({
              ...block,
              identifier: uuid(),
            })),
          };

          return {
            reportModel: blockIdentifiableReportModel,
            values: { ...defaultValues, ...values },
            propertiesToBeCalculated,
            technicalNameToBlockIdentifierLookup:
              createTechnicalNameToBlockIdentifierLookup(
                blockIdentifiableReportModel,
              ),
          };
        }),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.viewModel$ = combineLatest([
      this.initialValues$,
      this.formDataHandler$.pipe(switchMap(({ state$ }) => state$)),
    ])
      .pipe(
        map(([initial, formDataHandlerState]) => {
          const { changedBlocks, invalidBlocks } = computeBlocksLookup({
            errors: formDataHandlerState.errors,
            changedProperties: formDataHandlerState.changedProperties,
            blockIdentifierByTechnicalNameLookup:
              initial.technicalNameToBlockIdentifierLookup,
          });
          const valuesWithTruncatedNumbers = fromPairs(
            toPairs(formDataHandlerState.formData).map(([key, value]) => {
              const result: [
                string,
                string | number | boolean | null | undefined,
              ] =
                typeof value === 'number'
                  ? [key, truncNumber(value, 2)]
                  : [key, value];
              return result;
            }),
          );

          return {
            initial,
            reportModel: initial.reportModel,
            values: formDataHandlerState.formData,
            viewValues: valuesWithTruncatedNumbers,
            propertiesToBeCalculated: initial.propertiesToBeCalculated,
            changedBlocks,
            invalidBlocks,
            invalidFields: computeInvalidFormFieldsLookup(formDataHandlerState),
            isHeaderEmptyBySectionIndex: calculateEmptySectionHeaderArray(
              initial.reportModel,
            ),
          };
        }),
      )
      // FIXME kdraba: this is a quick fix, for sections being displayed as changed, after saving the report
      //               the problem may be, that an update of the view model synchronously
      //               updates the form data handler, which synchronously updates the view model
      //               which may result in the latest synchronous update beeing lost in the template
      .pipe(delay(0))
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.viewMode$ = this.observables.viewMode;

    this.formChanged = this.formDataHandler$
      .pipe(switchMap(({ state$ }) => state$))
      .pipe(map(({ formData }) => formData))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.isChangedProperties$ = this.formDataHandler$
      .pipe(switchMap(({ state$ }) => state$))
      .pipe(pickDistinctUntilChanged(['changedProperties']))
      .pipe(
        map(({ changedProperties }) =>
          changedProperties
            ? mapSetToObject(changedProperties, () => true as const)
            : {},
        ),
      )
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  ngOnInit() {
    const updateFormDataHandlerStateFn =
      this.updateReportEditorFormDataHandlerStateFunctionFactory.create();

    const formDataHandlerUpdateState$ = combineLatest([
      this.formDataHandler$,
      this.viewModel$
        .pipe(
          scan(
            (
              prev:
                | { viewModel: ReportEditorViewModel; init: boolean }
                | undefined,
              viewModel,
            ) => {
              if (prev) {
                return {
                  viewModel,
                  init: prev.viewModel.initial !== viewModel.initial,
                };
              } else {
                return { viewModel, init: true };
              }
            },
            undefined,
          ),
        )
        .pipe(isDefined()),
    ]).pipe(
      switchMap(([formDataHandler, { viewModel, init }]) =>
        concat(
          init
            ? of({
                formDataHandler,
                viewModel,
                action: { action: 'init', data: viewModel.initial },
              } as const)
            : EMPTY,
          this.formDataHandlerActions.pipe(
            map((action) => ({ formDataHandler, viewModel, action })),
          ),
        ),
      ),
    );

    this.subscriptions.add(
      formDataHandlerUpdateState$.subscribe(
        ({ formDataHandler, viewModel, action }) => {
          updateFormDataHandlerStateFn({ formDataHandler, viewModel, action });
        },
      ),
    );

    this.subscriptions.add(
      this.formDataHandler$
        .pipe(switchMap((formDataHandler) => formDataHandler.state$))
        .subscribe((state) => (this.isReportEditorValid = state.valid)),
    );

    this.subscriptions.add(
      this.formDataHandler$
        .pipe(switchMap((formDataHandler) => formDataHandler.state$))
        .subscribe((state) => (this.isReportEditorChanged = state.changed)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onExpandedChange() {
    this.expandedChanged.emit();
  }

  updateValue({
    event,
    key,
    value,
  }: {
    value: ReportEditorFormFields[keyof ReportEditorFormFields];
    event: Event | undefined;
    key: keyof ReportEditorFormFields;
  }) {
    if (event) {
      this.formDataHandlerActions.next({
        action: 'updateValue',
        data: {
          value,
          key,
        },
      });
    }
  }

  updateCalculatedReportValues(data: CalculatedReportDetailsResponseModel) {
    this.formDataHandlerActions.next({
      action: 'updateCalculatedValues',
      data,
    });
  }

  reset() {
    this.formDataHandlerActions.next({
      action: 'reset',
    });
  }

  markClean() {
    this.isReportEditorChanged = false;
  }
}

function createTechnicalNameToBlockIdentifierLookup(
  reportModel: BlockIdentifiableReportModel,
): {
  [technicalName: string]: string;
} {
  return reportModel.blocks.reduce((acc, { identifier: blockId, sections }) => {
    const blockTechnicalNames = sections.flatMap(({ propertyGroups }) =>
      propertyGroups.flatMap(({ properties }) =>
        properties.flatMap((property) => property.technicalName),
      ),
    );

    const technicalNamesToBlockIdentifierLookup = blockTechnicalNames.reduce(
      (accumulatedLookup, technicalName) => ({
        ...accumulatedLookup,
        [technicalName]: blockId,
      }),
      {},
    );

    return {
      ...acc,
      ...technicalNamesToBlockIdentifierLookup,
    };
  }, {});
}

function calculateEmptySectionHeaderArray(
  reportModel: ReportModel,
): ReadonlyArray<ReadonlyArray<boolean>> {
  return reportModel.blocks.map((block) =>
    block.sections.map((section) =>
      section.propertyGroups.every(({ title }) => !title),
    ),
  );
}
