import { BavReportingJsonApiTypes } from '@bbraun/bav-reporting/data-access-ais-reports';
import { TypedFiqlQuery } from '@bbraun/shared/util-fiql';
import {
  createPropertyPathBuilder,
  PropertyPathBuilder,
} from '@bbraun/shared/util-lang';
import { YearAndMonth } from '../types/year-and-month.type';
import { YearOrMonthFiqlConstraint } from './create-report-list-filter-query';

type CenterFiqlConstraint = TypedFiqlQuery<
  PropertyPathBuilder<BavReportingJsonApiTypes['ais-report'], string>
>;

export function createReportFilterQuery(
  centerId: string,
  currentDate: YearAndMonth,
): TypedFiqlQuery {
  const reportMonthSelector =
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'month',
    );

  const reportYearSelector =
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'year',
    );

  const reportCenterSelector =
    createPropertyPathBuilder<BavReportingJsonApiTypes['ais-report']>().add(
      'centerId',
    );

  const isYearEqualQuery: YearOrMonthFiqlConstraint = {
    equals: {
      selector: reportYearSelector,
      args: currentDate.year,
    },
  };

  const isMonthEqualQuery: YearOrMonthFiqlConstraint = {
    equals: {
      selector: reportMonthSelector,
      args: currentDate.month,
    },
  };

  const isCenterEqualQuery: CenterFiqlConstraint = {
    equals: {
      selector: reportCenterSelector,
      args: centerId,
    },
  };

  return {
    and: [isCenterEqualQuery, isMonthEqualQuery, isYearEqualQuery],
  };
}
