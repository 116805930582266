<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAisReports';
    read: 'bbraunBavReportingFeatureAisReports.confirmUpdateCalculatedValuesDialog'
  "
>
  <bbraun-ui-confirm-information-dialog
    [title]="translate('title.text')"
    [informationText]="translate('information.text')"
    [cancelButtonText]="'Cancel'"
    [confirmButtonText]="'Confirm'"
  >
  </bbraun-ui-confirm-information-dialog>
</ng-container>
