import { HttpClient, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AccessTokenService } from '@bbraun/shared/data-access-security';
import { TypedJsonApiService } from '@bbraun/shared/util-jsonapi';
import {
  InterceptingHandler,
  TypedJsonApiNgService,
} from '@bbraun/shared/util-jsonapi-ng';
import {
  bavReportingJsonApiTypesProperties,
  bavReportingMeta,
} from './bav-reporting-json-api-types.json-api.generated';
import { BavReportingJsonApi } from './bav-reporting-json-api.type';

export function createTypedJsonApiService(
  baseUrl: string,
  accessTokenService: AccessTokenService,
  httpHandler: HttpHandler,
  httpInterceptors: HttpInterceptor[],
): TypedJsonApiService<BavReportingJsonApi> {
  const httpClient = new HttpClient(
    new InterceptingHandler(httpHandler, httpInterceptors),
  );

  return new TypedJsonApiNgService<BavReportingJsonApi>(
    bavReportingMeta,
    bavReportingJsonApiTypesProperties,
    accessTokenService,
    () => httpClient,
    baseUrl,
  );
}
