import { PropertyPathBuilder } from '../property-path-builder.type';
import {
  ComparatorKeyTypeMap,
  SortComparator,
  SortComparatorFnByName,
  SortSpec,
  SortSpecs,
  SortSpecWithComparator,
} from './sort-spec.types';

export interface SortSpecsBuilder<TModel = unknown> {
  specs: ReadonlyArray<SortSpec<PropertyPathBuilder<TModel>>>;

  add<
    TPath extends PropertyPathBuilder<TModel>,
    TComparator extends SortComparator<ReturnType<TPath['apply']>> | unknown,
  >(
    sortSpec: Readonly<SortSpec<TPath, TComparator>>,
  ): TComparator extends SortComparator<ReturnType<TPath['apply']>>
    ? SortSpecsBuilderWithComparator<
        TModel & Parameters<TPath['apply']>[0],
        TComparator
      >
    : SortSpecsBuilder<TModel & Parameters<TPath['apply']>[0]>;
}

export interface SortSpecsBuilderWithComparator<
  TModel,
  TComparators extends keyof ComparatorKeyTypeMap = keyof ComparatorKeyTypeMap,
> extends SortSpecsBuilder<TModel> {
  specs: ReadonlyArray<
    SortSpecWithComparator<TModel, unknown, PropertyPathBuilder<TModel>>
  >;

  add<
    TPath extends PropertyPathBuilder<TModel>,
    TComparator extends SortComparator<ReturnType<TPath['apply']>> | unknown,
  >(
    sortSpec: Readonly<SortSpec<TPath, TComparator>>,
  ): TComparator extends SortComparator<ReturnType<TPath['apply']>>
    ? SortSpecsBuilderWithComparator<
        TModel & Parameters<TPath['apply']>[0],
        TComparators | TComparator
      >
    : SortSpecsBuilder<TModel & Parameters<TPath['apply']>[0]>;

  withComparators(comparators: {
    [key in TComparators]: SortComparatorFnByName<key>;
  }): SortSpecs<TModel>;
}

class SortSpecsBuilderImpl<
  TModel,
  TComparators extends keyof ComparatorKeyTypeMap,
> implements SortSpecsBuilderWithComparator<TModel, TComparators>
{
  constructor(
    public readonly specs: ReadonlyArray<
      SortSpecWithComparator<
        TModel,
        unknown,
        PropertyPathBuilder<TModel, unknown>,
        TComparators
      >
    >,
  ) {}

  add<
    TValue,
    TPath extends PropertyPathBuilder<TModel, TValue>,
    TComparator extends SortComparator<TValue>,
  >(
    sortSpec: SortSpec<TPath, TComparator>,
  ): TComparator extends SortComparator<ReturnType<TPath['apply']>>
    ? SortSpecsBuilderWithComparator<
        TModel & Parameters<TPath['apply']>[0],
        TComparators | TComparator
      >
    : SortSpecsBuilder<TModel & Parameters<TPath['apply']>[0]> {
    const sortSpecWithComparator: SortSpecWithComparator<
      TModel,
      TValue,
      TPath,
      TComparator
    > = {
      ...sortSpec,
      compare: (value1, value2, comparators) => {
        const comparator = comparators[sortSpec.comparator];
        const propertyValue1 = sortSpec.property.apply(value1);
        const propertyValue2 = sortSpec.property.apply(value2);
        return comparator(propertyValue1, propertyValue2);
      },
    };

    const sortSpecBuilder = new SortSpecsBuilderImpl<
      TModel & Parameters<TPath['apply']>[0],
      TComparators | TComparator
    >([...this.specs, sortSpecWithComparator]);
    return sortSpecBuilder as any; // TODO kdraba: find a way to make this typesafe
  }

  withComparators(comparators: {
    [key in TComparators]: SortComparatorFnByName<key>;
  }): SortSpecs<TModel> {
    return this.specs.map((spec) => ({
      ...spec,
      compare: (value1, value2) => spec.compare(value1, value2, comparators),
    }));
  }
}

export function createSortSpecs<
  TModel,
  TComparators extends keyof ComparatorKeyTypeMap | unknown = unknown,
>(): TComparators extends keyof ComparatorKeyTypeMap
  ? SortSpecsBuilderWithComparator<TModel, TComparators>
  : SortSpecsBuilder<TModel> {
  return new SortSpecsBuilderImpl<TModel, any>([]) as any;
}
