import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { onSubscription } from '@bbraun/shared/util-rxjs';
import { of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { LoginService } from '../interfaces/login-service';
import { SECURITY_CONFIG } from '../interfaces/security-config';
import { LOGIN_SERVICE_TOKEN } from '../services/injection-tokens';

@Injectable({ providedIn: 'root' })
export class SsoLoginGuard implements CanActivate {
  constructor(
    @Inject(LOGIN_SERVICE_TOKEN) private readonly loginService: LoginService,
    private readonly router: Router,
    @Inject(SECURITY_CONFIG)
    private readonly config: {
      routes: {
        default: string;
      };
    },
  ) {}

  canActivate() {
    return this.loginService.loginResult$
      .pipe(
        onSubscription({
          afterSubscribe: () => this.loginService.login('', ''),
        }),
      )
      .pipe(
        map(
          (loginResult) =>
            loginResult.success &&
            this.router.parseUrl(this.config.routes.default),
        ),
      )
      .pipe(catchError(() => of(false)))
      .pipe(share());
  }
}
