import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  defaultIfEmpty,
  firstValueFrom,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { refreshTokenName } from '../../constants';
import { isLoginError, LoginError } from '../../interfaces/login-error';
import { LoginServiceConnector } from '../../interfaces/login-service-connector';
import {
  SecurityConfig,
  SECURITY_CONFIG,
} from '../../interfaces/security-config';
import { isTokenResponse } from '../../interfaces/token-response';
import { DataAccessSecurityErrorCodes } from '../data-access-security-error-codes';
import { createFullUrl } from '../functions/create-full-url';
import { normalizeBaseUrl } from '../functions/normalize-base-url';
import { refreshAccessToken } from '../functions/refresh-access-token';
import { AUTHENTICATION_SERVICE_BASE_URL_TOKEN } from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class BasicAuthLoginServiceConnector implements LoginServiceConnector {
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(AUTHENTICATION_SERVICE_BASE_URL_TOKEN)
    baseUrl: string,
    @Inject(SECURITY_CONFIG) private readonly config: SecurityConfig,
  ) {
    this.baseUrl = normalizeBaseUrl(baseUrl);
  }

  login(
    username: string,
    password: string,
  ): Observable<{ refreshToken: string | undefined }> {
    return this.http
      .post<unknown | LoginError>(
        createFullUrl(this.baseUrl, 'login'),
        { username, password },
        { withCredentials: true },
      )
      .pipe(
        catchError((error) =>
          throwError(() => ({
            code: error.status,
            message: error.statusText,
          })),
        ),
        switchMap((response) => {
          if (isLoginError(response)) {
            return throwError(() => response);
          } else {
            return of({
              refreshToken: isTokenResponse(response)
                ? response.refreshToken
                : undefined,
            });
          }
        }),
      );
  }

  logout(errorCode?: number, refreshToken?: string): Promise<string> {
    return firstValueFrom(
      this.http
        .post(
          createFullUrl(
            this.baseUrl,
            'logout',
            (refreshToken && {
              refreshToken,
              refreshTokenName,
            }) ||
              undefined,
          ),
          null,
          {
            withCredentials: true,
            observe: 'response',
          },
        )
        .pipe(catchError((error: unknown) => of(error)))
        .pipe(
          map((response) => {
            const errorCodes =
              response instanceof HttpResponse && response.status === 200
                ? []
                : [DataAccessSecurityErrorCodes.LOGOUT_INCOMPLETE];
            const logoutUrl = createFullUrl(this.config.routes.start, '', {
              errorCode,
              errorCodes,
            });
            return logoutUrl;
          }),
        )
        .pipe(
          defaultIfEmpty(
            createFullUrl(this.config.routes.start, '', {
              errorCode,
            }),
          ),
        ),
    );
  }

  refresh(refreshToken?: string) {
    return refreshAccessToken(refreshToken, {
      baseUrl: this.baseUrl,
      http: this.http,
    });
  }
}
