export interface Snapshot<TData extends {}> {
  data: Readonly<TData>;
  children: ReadonlyArray<Snapshot<TData>>;
}

export function getConfigFromRouteSnapshot<TData, TKey extends keyof TData>(
  snapshot: Snapshot<TData>,
  key: TKey,
): TData[TKey] | undefined {
  const children = snapshot.children.reduce((acc, s) => {
    const child = getConfigFromRouteSnapshot(s, key);
    return child !== undefined ? acc || child : acc;
  }, undefined as undefined | TData[TKey]);

  return children !== undefined ? children : snapshot.data[key];
}
