import { IndividualConfig } from 'ngx-toastr';
import {
  MessageSeverity,
  ReducedToastrService,
  Toast,
} from './message.service.type';

export function showMessage(
  {
    title,
    message,
    type,
    timeOut,
    enableHtml,
    isDebugEnabled,
  }: {
    title: string;
    message: string;
    type: MessageSeverity;
    timeOut: false | number;
    enableHtml: boolean;
    isDebugEnabled: boolean;
  },
  toasterService: ReducedToastrService,
): Toast {
  let messageFn: (
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>,
  ) => any;

  switch (type) {
    case 'warning':
      messageFn = toasterService.warning.bind(toasterService);
      break;
    case 'error':
      messageFn = toasterService.error.bind(toasterService);
      break;
    case 'success':
      messageFn = toasterService.success.bind(toasterService);
      break;
    case 'info':
    default:
      messageFn = toasterService.info.bind(toasterService);
      break;
  }

  const disableTimeOut =
    (isDebugEnabled && type === 'error') || timeOut === false;

  return messageFn(message, title, {
    disableTimeOut,
    timeOut: timeOut || undefined,
    enableHtml,
    ...(isDebugEnabled
      ? {
          toastClass: 'ngx-toastr debug-mode',
          tapToDismiss: false,
        }
      : {}),
  });
}
