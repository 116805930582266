import { Inject, Injectable, Optional } from '@angular/core';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { FIXED_CURRENT_DATE } from '../injection-tokens';
import { YearAndMonth } from '../types/year-and-month.type';

@Injectable({ providedIn: 'root' })
export class CurrentDateService {
  constructor(
    @Optional()
    @Inject(FIXED_CURRENT_DATE)
    private readonly fixedCurrentDate?: string,
  ) {}

  currentDate(): YearAndMonth {
    if (typeof this.fixedCurrentDate === 'string') {
      const parsedDate = parseISO(this.fixedCurrentDate);

      return {
        month: parsedDate.getMonth() + 1,
        year: parsedDate.getFullYear(),
      };
    } else {
      return {
        month: moment().month() + 1,
        year: moment().year(),
      };
    }
  }
}
