import { Defined } from '@bbraun/shared/util-lang';
import { ModelObject } from '../model-object.type';

export function getFromModelObject<
  TModelObjectKey extends keyof ModelObject<never, never>,
  TValue extends {
    readonly [key in TModelObjectKey]?: Partial<{
      readonly [key: string | symbol | number]: unknown;
    }>;
  },
  TValueKey extends keyof Defined<TValue[TModelObjectKey]>,
>(
  key: TModelObjectKey,
  value: TValue,
  metaName: TValueKey,
): Defined<TValue[TModelObjectKey]>[TValueKey] | undefined {
  const meta = value[key];
  return (
    // TODO kdraba: this cast should not be necessary, because metaName is defined as a keyof links
    ((meta && meta[metaName]) as
      | Defined<TValue[TModelObjectKey]>[TValueKey]
      | undefined) || undefined
  );
}
