import { statePersistenceMetaReducer } from '@bbraun/shared/util-ngrx';
import { MetaReducer } from '@ngrx/store';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { Observable } from 'rxjs';
import { LocaleState } from './+state/locale.reducer';
import { initialState } from './+state/locale.reducer';
import { LocaleService } from './initializers/locale.service';

const persistenceMetaReducer: MetaReducer<LocaleState> =
  statePersistenceMetaReducer('bbraun.locale.state');

export function createNgrxConfig(): StoreConfig<LocaleState> {
  return {
    initialState,
    metaReducers: [persistenceMetaReducer],
  };
}

export function initLocale(initializer: LocaleService): () => Promise<any> {
  return () => initializer.restoreLastLocale();
}

export function createLocaleObservable(factory: {
  create: () => Observable<string>;
}) {
  return factory.create();
}
