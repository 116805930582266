import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportDetailsResponseModel } from '@bbraun/bav-reporting/data-access-ais-reports';
import { WithIsChangedSupportComponent } from '@bbraun/bav-reporting/ui-report-editor';
import { getObjectLink, getHrefFromLink } from '@bbraun/shared/util-jsonapi';
import { PickByDiscriminator } from '@bbraun/shared/util-lang';
import { FromResolver } from '@bbraun/shared/util-lang-ng';
import { MessageService } from '@bbraun/shared/util-message-ng';
import {
  connectToLoadBarService,
  LoadBarService,
} from '@bbraun/shared/util-navigation';
import { hasValue } from '@bbraun/shared/util-rxjs';
import {
  concat,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  of,
  shareReplay,
  Subscription,
} from 'rxjs';
import {
  ReportDetailsComponent,
  ReportDetailsState,
} from '../../components/report-details/report-details.component';
import { getPreviousMonth } from '../../functions/get-previous-month';
import { EditReportResolver } from '../../resolvers/edit-report.resolver';
import { CurrentDateService } from '../../services/current-date.service';
import { ReportingService } from '../../services/reporting.service';
import { createSetReportStateHandler } from './create-set-report-state-handler';

type EditReportState = PickByDiscriminator<
  ReportDetailsState,
  'viewMode',
  'edit' | 'view'
>;

const EDIT_REPORT_BLOCKING_OPERATION = Symbol('EDIT_REPORT_BLOCKING_OPERATION');

@Component({
  selector: 'bav-reporting-feature-ais-reports-edit-report',
  templateUrl: './edit-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditReportComponent
  implements OnInit, OnDestroy, WithIsChangedSupportComponent
{
  get changed(): boolean {
    return !!this.withIsChangedSupportComponents?.some(
      ({ changed }) => changed,
    );
  }

  @ViewChildren(ReportDetailsComponent)
  reportDetailsComponents?: QueryList<ReportDetailsComponent>;

  @ViewChildren('withIsChangedSupport')
  private withIsChangedSupportComponents?: QueryList<WithIsChangedSupportComponent>;

  readonly state$: Observable<EditReportState>;

  private readonly setReportStateHandler = createSetReportStateHandler(
    this.messageService,
  );

  private readonly isBlockingOperationRunning$: Observable<{
    id: symbol;
    value: boolean;
  }>;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly reportService: ReportingService,
    route: ActivatedRoute,
    private readonly loadbarService: LoadBarService,
    private readonly messageService: MessageService,
    currentDateService: CurrentDateService,
  ) {
    const initialEditReportData = route.snapshot.data
      .editReportData as FromResolver<EditReportResolver>;

    const setReportStateResult$ = this.setReportStateHandler.results.pipe(
      filter(hasValue),
    );

    this.state$ = concat(
      of(initialEditReportData),
      setReportStateResult$.pipe(
        map(({ value: reportDetails }) => ({
          reportDetails,
          reportInfo: initialEditReportData.reportInfo,
          doUpdateCalculatedReportValuesExist:
            initialEditReportData.doUpdateCalculatedReportValuesExist,
        })),
      ),
    )
      .pipe(
        map(
          ({
            reportDetails,
            reportInfo,
            doUpdateCalculatedReportValuesExist,
          }) => {
            const exportLink = getObjectLink(reportDetails, 'export');

            const lastMonth = getPreviousMonth(
              currentDateService.currentDate(),
            );

            return {
              id: initialEditReportData.reportDetails.id,
              mode: 'editReport',
              viewMode: 'view',
              exportXmlLink: exportLink
                ? getHrefFromLink(exportLink)
                : undefined,
              reportInfo: {
                ...reportInfo,
                isValid: true,
              },
              reportDetails,
              doUpdateCalculatedReportValuesExist,
              isLastMonthReport:
                reportInfo.date.month === lastMonth.month &&
                reportInfo.date.year === lastMonth.year,
            } as const;
          },
        ),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.isBlockingOperationRunning$ = this.setReportStateHandler.running.pipe(
      map((isRunning) => ({
        id: EDIT_REPORT_BLOCKING_OPERATION,
        value: isRunning,
      })),
    );
  }

  ngOnInit() {
    this.subscriptions.add(
      this.isBlockingOperationRunning$
        .pipe(connectToLoadBarService(this.loadbarService))
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSetReportStateTriggered({
    reportId,
    state,
  }: {
    reportId: string;
    state: ReportDetailsResponseModel['state'];
  }) {
    const operation$ = () =>
      this.reportService.updateReport(reportId, { state }).pipe(first());
    this.setReportStateHandler.next(operation$);
  }
}
