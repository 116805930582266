import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IS_NAVIGATION_IN_PROGRESS_TOKEN } from './injection-tokens';
import { createIsNavigationInProgressTrigger } from './provider-factories';
import { IsNavigationInProgressService } from './services/is-navigation-in-progress.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: IS_NAVIGATION_IN_PROGRESS_TOKEN,
      useFactory: createIsNavigationInProgressTrigger,
      deps: [IsNavigationInProgressService],
    },
  ],
})
export class UtilNavigationModule {}
