<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAisReports';
    read: 'bbraunBavReportingFeatureAisReports.reportListFilter'
  "
>
  <div class="flex-box flex-wrap justify-content-flex-end gap-1 mb-1">
    <div class="flex-box align-items-center gap-1">
      <dx-date-box
        displayFormat="yyyy - MM"
        [placeholder]="translate('dateLabelFrom.text')"
        pickerType="Months"
        type="date"
        id="dateFrom"
        [showClearButton]="true"
        (onValueChanged)="
          onDateChange({ dateBoxEvent: $event, action: 'from' })
        "
      >
        <dxo-calendar-options maxZoomLevel="year" minZoomLevel="century">
        </dxo-calendar-options>
      </dx-date-box>
    </div>

    <div class="flex-box align-items-center gap-1">
      <dx-date-box
        displayFormat="yyyy - MM"
        [placeholder]="translate('dateLabelTo.text')"
        pickerType="Months"
        type="date"
        id="dateTo"
        [showClearButton]="true"
        (onValueChanged)="onDateChange({ dateBoxEvent: $event, action: 'to' })"
      >
        <dxo-calendar-options maxZoomLevel="year" minZoomLevel="century">
        </dxo-calendar-options>
      </dx-date-box>
    </div>
  </div>
</ng-container>
