import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import {
  TranslocoMessageService,
  TranslocoMessageServiceFactory,
} from '@bbraun/shared/util-transloco-message';
import { TranslocoModule } from '@ngneat/transloco';
import { DxFormModule } from 'devextreme-angular';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { UI_LOGIN_MESSAGE_SERVICE } from './injection-tokens';

const scope = 'bbraunSharedUiLogin';

export function createMessageService(
  factory: TranslocoMessageServiceFactory,
): TranslocoMessageService {
  return factory.createForScope(scope);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    DxFormModule,
    TranslocoModule,
  ],
  declarations: [LoginComponent, LoginDialogComponent],
  exports: [LoginComponent, LoginDialogComponent],
  providers: [
    {
      provide: UI_LOGIN_MESSAGE_SERVICE,
      useFactory: createMessageService,
      deps: [TranslocoMessageServiceFactory],
    },
  ],
})
export class UiLoginModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      scope,
    );
  }
}
