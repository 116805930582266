export interface LoginError {
  code: number;
  message: string;
}

export function isLoginError(value: unknown): value is LoginError {
  return (
    value !== undefined &&
    typeof value === 'object' &&
    value !== null &&
    value.hasOwnProperty('code') &&
    value.hasOwnProperty('message')
  );
}
