import { FormDataHandlerState } from '@bbraun/shared/util-devexpress';
import { toPairs } from '@bbraun/shared/util-lang';
import { getValidationMessagesForInvalidProperties } from '@bbraun/shared/util-validation';

export function computeInvalidFormFieldsLookup<T extends {}>({
  errors,
  validators,
}: FormDataHandlerState<T>): {
  [key in keyof T]?: {
    isInvalid: true;
    tooltipErrorMessage: string;
  };
} {
  const invalidPropertiesValidationMessages =
    getValidationMessagesForInvalidProperties(validators, errors);

  return toPairs(invalidPropertiesValidationMessages).reduce(
    (acc, [key, errorMessages]) => ({
      ...acc,
      [key]: {
        isInvalid: true,
        tooltipErrorMessage: errorMessages?.join(`\n`),
      },
    }),
    {},
  );
}
