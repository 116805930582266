import { HttpClient } from '@angular/common/http';
import { CanActivate } from '@angular/router';
import { BavReportingJsonApi } from '@bbraun/bav-reporting/data-access-ais-reports';
import {
  BasicAuthLoginServiceConnector,
  LoginServiceConnector,
  RefreshTokenConfiguration,
  SecurityModuleConfig,
  SsoLoginGuard,
  SsoLoginServiceConnector,
  StartGuard,
} from '@bbraun/shared/data-access-security';
import { LocalizedDatePipeErrorHandler } from '@bbraun/shared/ui-language';
import { TypedJsonApiService } from '@bbraun/shared/util-jsonapi';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { I18nConfiguration } from '@bbraun/shared/util-transloco-ng';
import { merge, pick } from 'lodash-es';
import { map } from 'rxjs';
import { Environment } from '../environments/environment.type';
import { BavReportingConfig } from './injection-token';

export function createLoginServiceConnectorForEnvironment(
  environment: Environment,
  httpClient: HttpClient,
  baseUrl: string,
  securityConfig: SecurityModuleConfig,
): LoginServiceConnector {
  return !environment.production && environment.loginServiceConnectorFactory
    ? environment.loginServiceConnectorFactory({
        httpClient,
        baseUrl,
        securityConfig,
      })
    : isBasicAuthForEnvironment(environment)
    ? new BasicAuthLoginServiceConnector(httpClient, baseUrl, securityConfig)
    : new SsoLoginServiceConnector(httpClient, baseUrl);
}

function isBasicAuthForEnvironment(environment: Environment) {
  return environment.authMode === 'basic';
}

export function createDisplayTimeZone(config: Environment) {
  return config.displayTimeZone;
}

export function createLoginGuardForEnvironment(
  environment: Environment,
  ssoLoginGuard: SsoLoginGuard,
  startGuard: StartGuard,
): CanActivate {
  return isBasicAuthForEnvironment(environment) ? startGuard : ssoLoginGuard;
}

export function createSecurityModuleConfigForEnvironment(
  environment: Environment,
  startRoute: string,
  loginRoute: string,
  defaultRoute: string,
): SecurityModuleConfig {
  return {
    routes: {
      start: startRoute,
      login: isBasicAuthForEnvironment(environment) ? loginRoute : startRoute,
      default: defaultRoute,
    },
    blacklistedRoutes: environment.jwt.blacklistedRoutes,
    whiteListedDomains: environment.jwt.whiteListedDomains,
  };
}
function buildUrl(
  protocol: string | undefined,
  host: string | undefined,
  port: string | undefined,
  contextRoot: string | undefined,
  path?: string | undefined,
) {
  return `${protocol ? `${protocol}://` : ''}${host || ''}${
    port ? `:${port}` : ''
  }${contextRoot || ''}${path || ''}`;
}

export function createApiBaseUrlForEnvironment(environment: Environment) {
  return buildUrl(
    environment.services.bav.protocol,
    environment.services.bav.host,
    environment.services.bav.port,
    environment.services.bav.contextRoot,
  );
}

export function createExpertLoaderApiBaseUrlForEnvironment(
  environment: Environment,
) {
  return buildUrl(
    environment.services.expertloader.protocol,
    environment.services.expertloader.host,
    environment.services.expertloader.port,
    environment.services.expertloader.contextRoot,
  );
}

export function createAuthenticationServiceBaseUrlForEnvironment(
  environment: Environment,
) {
  return buildUrl(
    environment.services.aus.protocol,
    environment.services.aus.host,
    environment.services.aus.port,
    environment.services.aus.contextRoot,
  );
}

export function createLanguageSelectionDisabledFlag(
  environment: Environment,
): boolean {
  return !!environment.languageSelectionDisabled;
}

export function createI18nConfiguration(
  environment: Environment,
): I18nConfiguration {
  return environment.i18n;
}

export function createIsProduction(environment: Environment): {
  production: boolean;
} {
  return { production: environment.production };
}

export function createSupportedLocales(
  config: I18nConfiguration,
): ReadonlyArray<string> {
  return config.supportedLocales;
}

export function createDefaultLocale(config: I18nConfiguration): string {
  return config.defaultLocale;
}

export function createRefreshTokenConfiguration(
  environment: Environment,
): RefreshTokenConfiguration {
  return environment.refreshToken;
}

export function createPrincipalProvider(
  bavReportingApiService: TypedJsonApiService<BavReportingJsonApi>,
) {
  return () =>
    bavReportingApiService
      .index()
      .pipe(map((result) => result && result.data))
      .pipe(
        map((data) => {
          if (data && data.userName) {
            return {
              name: data.userName,
            };
          } else {
            return undefined;
          }
        }),
      );
}

const dateParseError = Symbol('dateParseError');

export function createDateParseErrorHandler(
  messageService: MessageService,
): LocalizedDatePipeErrorHandler {
  return (
    type: Parameters<LocalizedDatePipeErrorHandler>[0],
    { error, ...details }: Parameters<LocalizedDatePipeErrorHandler>[1],
  ) => {
    messageService.message('Failed to parse or format date.', 'debug', {
      id: dateParseError,
      actions: [{ type: 'push-or-count' }],
      values: { errorType: type, ...details },
      error,
    });
    return null;
  };
}

export function createBavReportingConfig(
  environment: Environment,
): BavReportingConfig {
  const overrides = environment.allowOverride;
  const configs = overrides
    ? new URLSearchParams(window.location.search).getAll('config')
    : [];

  return configs
    .map((config) => {
      try {
        return JSON.parse(config);
      } catch {
        return {};
      }
    })
    .reduce(
      (acc, next) =>
        merge(acc, Array.isArray(overrides) ? pick(next, overrides) : next),
      environment,
    );
}

export function createFixedCurrentDate(
  bavReportingConfig: BavReportingConfig,
): string | undefined {
  return bavReportingConfig.testing?.fixedCurrentDate;
}
