import {
  Inject,
  InjectionToken,
  Optional,
  Pipe,
  PipeTransform,
} from '@angular/core';

const IS_TYPE_PIPE_DUMMY_INJECTION_TOKEN = new InjectionToken(
  'LOCALIZED_BASE_PIPE_DUMMY_INJECTION_TOKEN',
);

/**
 * This is a pipe base class that is not intended to be used as a pipe directly.
 * The pipe annotation is required by angular (empty Directive would be enough) and angular TestBed (fails without selector name).
 * This also forces us to define injection tokens for each parameter, even if we never ever want this to be injected anywhere.
 * https://angular.io/guide/migration-undecorated-classes
 * https://github.com/angular/angular/issues/36427
 */
@Pipe({ name: 'bbraunUtilLangIsTypeBase' })
export class IsTypeBase<T extends TInput, TInput = unknown>
  implements PipeTransform
{
  constructor(
    @Optional()
    @Inject(IS_TYPE_PIPE_DUMMY_INJECTION_TOKEN)
    private readonly typeGuard: (value: TInput) => value is T,
  ) {}
  transform(value: TInput): value is T {
    return this.typeGuard(value);
  }
}
