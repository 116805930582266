// THIS FILE IS GENERATED - DO NOT EDIT!

import {
  ExtractSparseFieldTypes,
  TypedQueryParameters,
} from '@bbraun/shared/util-jsonapi';
// eslint-disable-next-line no-unused-expressions
// @ts-ignore
import { createPropertyPathBuilder } from '@bbraun/shared/util-lang';

import { ExpertLoaderJsonApiTypes } from '../expert-loader-json-api-types.json-api.generated';

export const labTestMappingResponseModelFieldsets = {
  'lab-test-mapping': [
    createPropertyPathBuilder<
      ExpertLoaderJsonApiTypes['lab-test-mapping']
    >().add('laboratoryName').path[0] as 'laboratoryName',
    createPropertyPathBuilder<
      ExpertLoaderJsonApiTypes['lab-test-mapping']
    >().add('parameterName').path[0] as 'parameterName',
    createPropertyPathBuilder<
      ExpertLoaderJsonApiTypes['lab-test-mapping']
    >().add('labTestIdExtern').path[0] as 'labTestIdExtern',
    createPropertyPathBuilder<
      ExpertLoaderJsonApiTypes['lab-test-mapping']
    >().add('parameterUnit').path[0] as 'parameterUnit',
    createPropertyPathBuilder<
      ExpertLoaderJsonApiTypes['lab-test-mapping']
    >().add('labTestIdClinicalData').path[0] as 'labTestIdClinicalData',
  ],
} as const;

type LabTestMappingResponseModelFieldsets = ExtractSparseFieldTypes<
  typeof labTestMappingResponseModelFieldsets
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type LabTestMappingResponseModel = {
  readonly id: string;
  readonly labTestIdClinicalData:
    | null
    | (
        | 'NotApplicable'
        | 'HemoglobinGPerDl'
        | 'HemoglobinGPerL'
        | 'HemoglobinMmolPerL'
        | 'FerritinUgPerL'
        | 'FerritinNgPerDl'
        | 'TransferrinSaturationPercent'
        | 'CalciumInSerumMgPerDl'
        | 'CalciumInSerumMmolPerL'
        | 'PhosphateInSerumMgPerDl'
        | 'PhosphateInSerumMmolPerL'
        | 'IntactParathormonPgPerMl'
        | 'IntactParathormonNgPerL'
        | 'IntactParathormonPmolPerL'
        | 'CReactiveProteinMgPerL'
        | 'CReactiveProteinMgPerDl'
        | 'CReactiveProteinNmolPerL'
        | 'AlbuminBromocresolGreenGPerL'
        | 'AlbuminBromocresolGreenGPerDl'
        | 'AlbuminBromocresolPurpleGPerL'
        | 'AlbuminBromocresolPurpleGPerDl'
        | 'SerumCreatinineMgPerDl'
        | 'SerumCreatinineUmolPerL'
      );
  readonly labTestIdExtern: null | string;
  readonly laboratoryName: string;
  readonly parameterName: string;
  readonly parameterUnit: null | string;
};

export const labTestMappingResponseModelIncludes = undefined;

export const labTestMappingResponseModelReadParameters: TypedQueryParameters<
  ExpertLoaderJsonApiTypes,
  LabTestMappingResponseModelFieldsets,
  'lab-test-mapping'
> = {
  fieldsets: labTestMappingResponseModelFieldsets,
  include: labTestMappingResponseModelIncludes,
};
