export interface DataSourceCache<T, K = string> {
  has(id: K): boolean;

  get(id: K): T | undefined;

  put(item: T | T[]): void;
}

export class NoOpCache<T extends { id: K }, K = string>
  implements DataSourceCache<T, K>
{
  get(): T | undefined {
    return undefined;
  }

  has(): boolean {
    return false;
  }

  put(): void {}
}

export class DefaultDataSourceCache<
  TKey extends string,
  T extends { [identifierKey in TKey]: K },
  K extends string = string,
> implements DataSourceCache<T, K>
{
  private readonly items = new Map<K, T>();

  constructor(
    private readonly identifierKey: TKey,
    initialItems: T | T[] = [],
  ) {
    this.put(initialItems);
  }

  has(id: K): boolean {
    return this.items.has(id);
  }

  get(id: K): T | undefined {
    return this.items.get(id);
  }

  put(items: T | T[]) {
    if (Array.isArray(items)) {
      items.forEach((item) => {
        this.items.set(item[this.identifierKey], item);
      });
    } else {
      this.items.set(items[this.identifierKey], items);
    }
  }
}
