import { YearAndMonth } from '../types/year-and-month.type';

export function getPreviousMonth(currentDate: YearAndMonth) {
  const { year, month } =
    currentDate.month === 1
      ? { month: 12, year: currentDate.year - 1 }
      : { month: currentDate.month - 1, year: currentDate.year };
  return {
    year,
    month,
  };
}
