import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { LoginServiceConnector } from '../../interfaces/login-service-connector';
import { createFullUrl } from '../functions/create-full-url';
import { normalizeBaseUrl } from '../functions/normalize-base-url';
import { refreshAccessToken } from '../functions/refresh-access-token';
import { AUTHENTICATION_SERVICE_BASE_URL_TOKEN } from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class SsoLoginServiceConnector implements LoginServiceConnector {
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(AUTHENTICATION_SERVICE_BASE_URL_TOKEN)
    baseUrl: string,
  ) {
    this.baseUrl = normalizeBaseUrl(baseUrl);
  }

  login(): Observable<{ refreshToken: string | undefined }> {
    try {
      window.location.assign(createFullUrl(this.baseUrl, 'login'));
      return timer(5000)
        .pipe(first())
        .pipe(
          concatMap(() =>
            throwError(() => ({
              code: 0,
              message: 'timeout: could not redirect to login',
            })),
          ),
        );
    } catch (error) {
      return throwError(() => ({ success: false, error }));
    }
  }

  logout(errorCode?: number): Promise<string> {
    return Promise.resolve(
      createFullUrl(this.baseUrl, 'logout', { errorCode }),
    );
  }

  refresh(refreshToken?: string) {
    return refreshAccessToken(refreshToken, {
      baseUrl: this.baseUrl,
      http: this.http,
    });
  }
}
