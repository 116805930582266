import {
  Entries,
  mergeImmutable,
  Nullable,
  updatePropertiesImmutable,
  updatePropertyImmutable,
  updateSetImmutable,
} from '@bbraun/shared/util-lang';
import { isValid, validate } from '@bbraun/shared/util-validation';
import { isEqual as _isEqual } from 'lodash-es';
import {
  FormDataHandlerOptions,
  FormDataHandlerState,
} from './form-data-handler.type';
import { PartialFormData } from './partial-form-data.type';

export function updateFormDataHandlerState<TFormData extends {} = string>(
  inputData: Entries<Nullable<TFormData>>,
  currentState: FormDataHandlerState<TFormData>,
  {
    isEqual,
    isChanged,
    normalizeValue,
    validationMode,
    updateState,
  }: FormDataHandlerOptions<TFormData>,
): FormDataHandlerState<TFormData> {
  const updatedFormData = inputData.reduce((acc, [key, value]) => {
    const currentValue = normalizeValue(value);
    const oldValue = normalizeValue(currentState.formData[key]);

    return !isEqual(currentValue, oldValue, { property: key })
      ? updateState({
          formData: updatePropertyImmutable(acc, key, () => value),
          event: {
            oldValue,
            currentValue,
            property: key,
          },
        })
      : acc;
  }, currentState.formData);

  const changedProperties =
    currentState.changedProperties &&
    inputData.reduce(
      (originalSet, [key, value]) =>
        updateSetImmutable({
          originalSet,
          action: isChanged(value, currentState.original[key], {
            isEqual: isEqual(
              normalizeValue(value),
              normalizeValue(currentState.original[key]),
              { property: key },
            ),
            property: key,
          })
            ? 'add'
            : 'delete',
          value: key,
        }),
      currentState.changedProperties,
    );

  const dirtyProperties = inputData.reduce(
    (originalSet, [key, value]) =>
      isChanged(value, currentState.original[key], {
        isEqual: isEqual(value, currentState.original[key], { property: key }),
        property: key,
      })
        ? updateSetImmutable({ originalSet, action: 'add', value: key })
        : originalSet,
    currentState.dirtyProperties,
  );

  const validationState = {
    original: currentState.original,
    formData: updatedFormData,
    changed: changedProperties && changedProperties.size > 0,
    changedProperties,
    dirtyProperties,
    validators: currentState.validators,
    errors: currentState.errors,
  };

  const validationResult = mergeImmutable(
    currentState.errors,
    currentState.validators &&
      validate<PartialFormData<TFormData>>(
        updatedFormData,
        currentState.validators,
        validationMode(validationState),
      ),
  ).result;

  const updatedState = updatePropertiesImmutable(currentState, {
    original: () => validationState.original,
    formData: () => validationState.formData,
    changed: () =>
      validationState.changedProperties &&
      validationState.changedProperties.size > 0,
    changedProperties: () => validationState.changedProperties,
    dirtyProperties: () => validationState.dirtyProperties,
    validators: () => validationState.validators,
    valid: () =>
      validationResult === currentState.errors
        ? currentState.valid
        : isValid(validationResult),
    errors: () => validationResult,
  });

  return updatedState;
}
