import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
  CanReadAisReportsGuard,
  DefaultCenterService,
  ReportListResponseModel,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import { CenterConfiguration } from 'libs/bav-reporting/data-access-ais-reports/src/lib/types';
import { first, map, of, Observable, switchMap } from 'rxjs';
import { ReportingService } from '../services/reporting.service';

@Injectable()
export class ReportListResolver
  implements
    Resolve<{
      reportList: ReadonlyArray<ReportListResponseModel>;
      centerConfiguration: CenterConfiguration | undefined;
    }>
{
  constructor(
    private readonly defaultCenterService: DefaultCenterService,
    private readonly reportingService: ReportingService,
    private readonly canReadAisReportsGuard: CanReadAisReportsGuard,
  ) {}

  resolve(): Observable<{
    reportList: ReadonlyArray<ReportListResponseModel>;
    centerConfiguration: CenterConfiguration | undefined;
  }> {
    return this.canReadAisReportsGuard.canActivate$
      .pipe(
        switchMap((canActivate) =>
          canActivate
            ? this.defaultCenterService.getDefaultCenter().pipe(
                switchMap((centerConfiguration) =>
                  (centerConfiguration
                    ? this.reportingService.getReports(centerConfiguration.id)
                    : of([])
                  ).pipe(
                    map((reportList) => ({
                      reportList,
                      centerConfiguration,
                    })),
                  ),
                ),
              )
            : of({ reportList: [], centerConfiguration: undefined }),
        ),
      )
      .pipe(first());
  }
}
