import { InjectionToken } from '@angular/core';
import { ErrorCodeIgnore, ErrorCodeLookup } from '@bbraun/shared/util-error';
import { TranslocoMessageService } from '@bbraun/shared/util-transloco-message';

export const ERROR_CODE_IGNORE = new InjectionToken<ErrorCodeIgnore>(
  'ErroCodeIgnore',
);

export const ERROR_CODE_LOOKUP = new InjectionToken<ErrorCodeLookup>(
  'ErroCodeLookup',
);

export const ERROR_MESSAGE_SERVICE =
  new InjectionToken<TranslocoMessageService>('ErrorMessageService');
