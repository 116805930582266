import { hasProperty } from './has-property';
import { propertyIsOfType } from './property-is-of-type';

export function hasPropertyOfType<
  TPropertyName extends string | number | symbol,
  TPropertyType,
>(
  propertyName: TPropertyName,
  isPropertyType: (propertyValue: unknown) => propertyValue is TPropertyType,
): <T>(value: T) => value is T & { [key in TPropertyName]: TPropertyType } {
  return <T>(
    value: T,
  ): value is T & { [key in TPropertyName]: TPropertyType } =>
    hasProperty(propertyName)(value) &&
    propertyIsOfType(propertyName, isPropertyType)(value);
}
