import { isInstanceOf } from '../../type-guards/is-instance-of';
import { mergeArray } from './merge-functions/merge-array';
import { mergeMap } from './merge-functions/merge-map';
import { mergeObject } from './merge-functions/merge-object';
import { mergePlainObject } from './merge-functions/merge-plain-object';
import { mergeSet } from './merge-functions/merge-set';
import { mergeImmutableInternal } from './merge-immutable-internal';
import {
  isObjectType,
  isPlainObjectType,
  KeyType,
  MergeFunctionEntry,
  Options,
} from './merge-immutable.type';

export const DEFAULT_MERGE_FUNCTIONS: ReadonlyArray<MergeFunctionEntry<any>> = [
  new MergeFunctionEntry(
    (value): value is ReadonlyArray<unknown> => Array.isArray(value),
    mergeArray,
  ),
  new MergeFunctionEntry(isPlainObjectType, mergePlainObject),
  new MergeFunctionEntry(isInstanceOf(Set), mergeSet),
  new MergeFunctionEntry(isInstanceOf(Map), mergeMap),
  new MergeFunctionEntry(
    (value): value is unknown =>
      value === null ||
      value === undefined ||
      typeof value === 'boolean' ||
      typeof value === 'number' ||
      typeof value === 'string' ||
      typeof value === 'symbol' ||
      typeof value === 'bigint',
    (_base, objectToMerge) => ({
      hasError: false,
      result: objectToMerge,
      paths: [],
      diffs: [],
    }),
  ),
];

const UNSAFE_MERGE_FUNCTIONS: ReadonlyArray<MergeFunctionEntry<any>> =
  DEFAULT_MERGE_FUNCTIONS.concat([
    new MergeFunctionEntry(isObjectType, mergeObject),
  ]);

export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  options: { paths?: false; mode: 'safe'; diffs?: false } & Partial<
    Omit<Options, 'paths' | 'mode' | 'diffs'>
  >,
):
  | {
      hasError: false;
      result: TMerge;
    }
  | {
      hasError: true;
      error: Error;
    };
export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  options?: {
    paths?: false;
    mode?: 'throw';
    diffs?: false;
  } & Partial<Omit<Options, 'paths' | 'mode' | 'diffs'>>,
): {
  hasError: false;
  result: TMerge;
};
/**
 * @deprecated Unsafe is only for backward compatibility. Use throw (default) or safe instead.
 * @param initial
 * @param objectToMerge
 * @param options
 */
export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  options?: {
    paths?: false;
    mode: 'unsafe';
    diffs?: false;
  } & Partial<Omit<Options, 'paths' | 'mode' | 'diffs'>>,
): {
  hasError: false;
  result: TMerge;
};
export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  options: { paths: true; mode: 'safe'; diffs: true } & Partial<
    Omit<Options, 'paths' | 'mode' | 'diffs'>
  >,
):
  | {
      hasError: false;
      result: TMerge;
      paths: ReadonlyArray<ReadonlyArray<KeyType>>;
      diffs: ReadonlyArray<ReadonlyArray<KeyType>>;
    }
  | {
      hasError: true;
      error: Error;
    };
export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  options?: { paths: true; mode?: 'throw'; diffs: true } & Partial<
    Omit<Options, 'paths' | 'mode' | 'diffs'>
  >,
): {
  hasError: false;
  result: TMerge;
  paths: ReadonlyArray<ReadonlyArray<KeyType>>;
  diffs: ReadonlyArray<ReadonlyArray<KeyType>>;
};
/**
 * @deprecated Unsafe is only for backward compatibility. Use throw (default) or safe instead.
 * @param initial
 * @param objectToMerge
 * @param options
 */
export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  options?: { paths: true; mode: 'unsafe'; diffs: true } & Partial<
    Omit<Options, 'paths' | 'mode' | 'diffs'>
  >,
): {
  hasError: false;
  result: TMerge;
  paths: ReadonlyArray<ReadonlyArray<KeyType>>;
  diffs: ReadonlyArray<ReadonlyArray<KeyType>>;
};
export function mergeImmutable<TMerge>(
  initial: unknown,
  objectToMerge: TMerge,
  {
    paths = false,
    diffs = false,
    depth = Number.MAX_SAFE_INTEGER,
    timeoutInMs = 0,
    now = performance.now.bind(performance),
    identityCheck = (value1, value2) => Object.is(value1, value2),
    mode = 'unsafe',
    mergeFunctions = mode === 'unsafe'
      ? UNSAFE_MERGE_FUNCTIONS
      : DEFAULT_MERGE_FUNCTIONS,
  }: Partial<Options> = {},
): {
  hasError?: boolean;
  result?: TMerge;
  paths?: ReadonlyArray<ReadonlyArray<KeyType>>;
  diffs?: ReadonlyArray<ReadonlyArray<KeyType>>;
  error?: unknown;
} {
  return mergeImmutableInternal(
    initial,
    objectToMerge,
    new Map(),
    [],
    [],
    timeoutInMs ? now() : 0,
    {
      paths,
      diffs,
      depth,
      timeoutInMs,
      now,
      identityCheck,
      mergeFunctions,
      mode,
    },
  );
}
