import {
  KeyType,
  MergeResult,
  Options,
  References,
} from '../merge-immutable.type';
import { mergeKeyValue } from './merge-key-value';

export function mergeMap<TMerge extends Map<any, any>>(
  base: unknown,
  mergeObj: TMerge,
  references: References,
  path: ReadonlyArray<KeyType>,
  diff: ReadonlyArray<KeyType>,
  startTime: number,
  options: Options,
): MergeResult<TMerge> {
  return mergeKeyValue(
    base,
    mergeObj,
    references,
    path,
    diff,
    startTime,
    options,
    {
      getValue: (data, key) => data.get(key),
      setValue: (data, key, value) => {
        data.set(key, value);
        return data;
      },
      isSameType: (data): data is TMerge => data instanceof Map,
      getKeys: (data) => Array.from(data.keys()),
      createInstance: () => new Map() as TMerge,
    },
  );
}
