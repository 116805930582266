import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import {
  Event,
  Navigation,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { SECURITY_CONFIG } from '@bbraun/shared/data-access-security';
import { isDefined, mapToVoid } from '@bbraun/shared/util-rxjs';
import { Observable } from 'rxjs';
import { filter, map, scan, share } from 'rxjs/operators';
import {
  isReplaceUrlRoute as defaultIsReplaceUrlRoute,
  Snapshot,
} from './is-replace-url-route';
import { LocationService } from './location.service';
import { RedirectUrlAfterLoginService } from './redirect-url-after-login.service';

export const IS_REPLACE_URL_ROUTE = new InjectionToken<
  typeof defaultIsReplaceUrlRoute
>('isReplaceUrlRoute');

export type CurrentNavigation = Pick<Navigation, 'extras'>;

const cancelReasonRedirectRegexp =
  /NavigationCancelingError: Redirecting to "([^"])*"/;

function isRedirect(event: NavigationCancel) {
  return cancelReasonRedirectRegexp.test(event.reason);
}

@Injectable({ providedIn: 'root' })
export class InAppNavigationRoutingService {
  public readonly connect$: Observable<void>;

  constructor(
    @Inject(Router)
    router: Pick<Router, 'events' | 'navigate'> & {
      routerState: Readonly<{
        root: Readonly<{ snapshot: Snapshot }>;
      }>;
      getCurrentNavigation: () => CurrentNavigation;
    },
    @Inject(RedirectUrlAfterLoginService)
    redirectUrlAfterLoginService: Pick<
      RedirectUrlAfterLoginService,
      'getUrl' | 'setUrl' | 'removeUrl'
    >,
    @Inject(SECURITY_CONFIG)
    config: {
      routes: {
        start: string;
      };
    },
    @Inject(LocationService)
    location: Pick<
      LocationService,
      'path' | 'back' | 'getState' | 'forward' | 'replaceState'
    >,
    @Optional()
    @Inject(IS_REPLACE_URL_ROUTE)
    isReplaceUrlRoute?: (snapshot: Readonly<Snapshot>) => boolean | undefined,
  ) {
    const isReplaceUrlRouteOrDefault =
      isReplaceUrlRoute || defaultIsReplaceUrlRoute;

    this.connect$ = router.events
      .pipe(
        map((event) => {
          const currentNavigation = router.getCurrentNavigation();
          return currentNavigation ? { event, currentNavigation } : undefined;
        }),
      )
      .pipe(isDefined())
      .pipe(
        scan<
          { event: Event; currentNavigation: CurrentNavigation },
          {
            replaceWithLocationBack: boolean;
            isFirstNavigation: boolean;
            previousState: any;
            startState: any;
            previousUrl: string;
            direction: 'none' | 'forward' | 'backward';
            trigger?: Navigation['trigger'];
          }
        >(
          (acc, { currentNavigation, event }) => {
            if (
              event instanceof RoutesRecognized &&
              event.urlAfterRedirects === `/${config.routes.start}`
            ) {
              const redirectUrlAfterLogin =
                redirectUrlAfterLoginService.getUrl();
              if (redirectUrlAfterLogin) {
                redirectUrlAfterLoginService.removeUrl();
                router.navigate([redirectUrlAfterLogin], {
                  replaceUrl: true,
                  state: acc.previousState,
                });
              }
              return acc;
            } else if (
              event instanceof RoutesRecognized &&
              acc.trigger === 'popstate'
            ) {
              const state = location.getState() || {};

              currentNavigation.extras.state = {
                ...currentNavigation.extras.state,
                historyId:
                  state.historyId !== undefined ? state.historyId : event.id,
              };

              const direction =
                acc.previousState.historyId <
                currentNavigation.extras.state.historyId
                  ? 'forward'
                  : acc.previousState.historyId >
                    currentNavigation.extras.state.historyId
                  ? 'backward'
                  : 'none';

              return {
                ...acc,
                direction,
              };
            } else if (
              event instanceof RoutesRecognized &&
              acc.trigger !== 'popstate'
            ) {
              let replaceWithLocationBack = false;

              const state = location.getState() || {};
              const previousNavigationUrl: string | undefined =
                state.previousNavigation &&
                state.previousNavigation.locationPath;

              const isSourceReplaceUrl =
                !!(
                  currentNavigation.extras.state &&
                  currentNavigation.extras.state.replaceUrl
                ) ||
                isReplaceUrlRouteOrDefault(router.routerState.root.snapshot);

              if (
                isSourceReplaceUrl &&
                previousNavigationUrl &&
                event.urlAfterRedirects === previousNavigationUrl
              ) {
                currentNavigation.extras.skipLocationChange = true;
                replaceWithLocationBack = true;
              } else if (
                isSourceReplaceUrl &&
                !currentNavigation.extras.replaceUrl
              ) {
                currentNavigation.extras.replaceUrl = true;
              }

              if (!acc.isFirstNavigation) {
                currentNavigation.extras.state = {
                  ...currentNavigation.extras.state,
                  previousNavigation:
                    currentNavigation.extras.replaceUrl ||
                    currentNavigation.extras.skipLocationChange
                      ? state.previousNavigation
                      : {
                          navigationId: state.navigationId,
                          locationPath: location.path(false),
                        },
                };
              }

              currentNavigation.extras.state = {
                ...currentNavigation.extras.state,
                historyId:
                  currentNavigation.extras.replaceUrl ||
                  currentNavigation.extras.skipLocationChange
                    ? acc.previousState.historyId
                    : event.id,
              };

              return {
                ...acc,
                direction:
                  currentNavigation.extras.replaceUrl ||
                  currentNavigation.extras.skipLocationChange
                    ? 'none'
                    : 'forward',
                replaceWithLocationBack,
              };
            } else if (event instanceof NavigationStart) {
              const trigger = event.navigationTrigger;

              // see https://stackoverflow.com/a/58332130
              if (trigger === 'popstate' && event.restoredState) {
                currentNavigation.extras.state = event.restoredState || {};
              }

              return {
                ...acc,
                replaceWithLocationBack: false,
                startState: currentNavigation.extras.state,
                trigger,
              };
            } else if (event instanceof NavigationEnd) {
              if (acc.replaceWithLocationBack) {
                location.back();
              }
              if (acc.startState?.finalUrl) {
                redirectUrlAfterLoginService.setUrl(acc.startState.finalUrl);
              } else {
                redirectUrlAfterLoginService.removeUrl();
              }

              return {
                replaceWithLocationBack: false,
                isFirstNavigation: false,
                previousState: location.getState() || {},
                previousUrl: event.urlAfterRedirects,
                startState: {},
                direction: 'none',
              };
            } else if (
              !acc.isFirstNavigation &&
              event instanceof NavigationCancel
            ) {
              // restore the initial history state of the current navigation,
              // see: https://github.com/angular/angular/issues/13586
              if (acc.trigger === 'popstate' && acc.direction === 'backward') {
                // after a backward popstate we are already on the backward history entry,
                // even if the navigation gets canceled
                // 1) restore the state of the backward history entry,
                //    angular router may already have overwritten it with its new navigation id
                //    and may have deleted all other state associated with this history entry
                location.replaceState(event.url, '', acc.startState);
                // 2) go to the location where we originally came from, e.g. the last successfully loaded route
                location.forward();
              } else if (
                acc.trigger === 'popstate' &&
                acc.direction === 'forward'
              ) {
                // after a forward popstate we are already on the forward target history entry,
                // even if the navigation gets canceled
                // 1) restore the state of the history entry,
                //    angular router may already have overwritten it with its new navigation id
                //    and may have deleted all other state associated with this history entry
                location.replaceState(event.url, '', acc.startState);
                // 2) go back to where we came from
                location.back();
              } else if (acc.trigger !== 'popstate') {
                // restore the state of the current history entry,
                // angular router may already have overwritten it with its new navigation id
                // and may have deleted all other state associated with this history entry
                location.replaceState(acc.previousUrl, '', acc.previousState);

                if (!isRedirect(event)) {
                  // An imperative navigation that gets canceled, e.g. because of a can deactivate guard, followed
                  // by a popstate (history.back) navigation is ignored by the angular router because of the following code line
                  // https://github.com/angular/angular/blob/9.1.2/packages/router/src/router.ts#L1116
                  // Therefore we trigger a navigation on the same url, in order to reset the `lastTransition`.
                  router.navigate([acc.previousUrl], {
                    replaceUrl: true,
                    state: acc.previousState,
                  });
                }
              }
              return acc;
            } else {
              return acc;
            }
          },
          {
            replaceWithLocationBack: false,
            isFirstNavigation: true,
            previousState: {},
            previousUrl: '',
            startState: {},
            direction: 'none',
          },
        ),
      )
      .pipe(filter(() => false))
      .pipe(mapToVoid())
      .pipe(share());
  }
}
