import { isDefined, onSubscription } from '@bbraun/shared/util-rxjs';
import { OperatorFunction } from 'rxjs';
import { map, scan, tap } from 'rxjs/operators';
import { LoadBarService } from './load-bar.service';

export function connectToLoadBarService(
  loadBarService: LoadBarService,
): OperatorFunction<{ id: unknown; value: boolean }, boolean> {
  return (o) =>
    o
      .pipe(
        scan(
          (
            acc:
              | { running: Set<unknown>; id: unknown; value: boolean }
              | undefined,
            { id, value },
          ) => {
            const running = acc ? acc.running : new Set();
            if (value) {
              running.add(id);
            } else {
              running.delete(id);
            }

            return { running, id, value };
          },
          undefined,
        ),
      )
      .pipe(isDefined())
      .pipe(
        onSubscription({
          withLatestValue: true,
          onUnsubscribe: (latestValue) => {
            if (latestValue.hasValue) {
              latestValue.value.running.forEach((id) =>
                loadBarService.stopLoadingProcess(id),
              );
            }
          },
        }),
      )
      .pipe(
        tap(({ id, value }) => {
          if (value) {
            loadBarService.addLoadingProcess(id);
          } else {
            loadBarService.stopLoadingProcess(id);
          }
        }),
      )
      .pipe(map(({ running }) => running.size > 0));
}
