import { hasPropertyOfType, isDefined } from '@bbraun/shared/util-lang';

// https://jsonapi.org/format/1.0/#document-top-level
export type JsonApiFetchResponseBody =
  | JsonApiMetaResponseBody
  | JsonApiErrorResponseBody
  | JsonApiDataResponseBody;

export interface JsonApiMetaResponseBody {
  readonly meta: MetaObject;
  readonly jsonapi?: JsonApiObject;
  readonly links?: { readonly [rel: string]: LinkObject };
}

export interface JsonApiErrorResponseBody {
  readonly errors: ErrorObject[];
  readonly meta?: MetaObject;
  readonly jsonapi?: JsonApiObject;
  readonly links?: { readonly [rel: string]: LinkObject };
}

export type JsonApiDataResponseBody =
  | JsonApiDataObjectResponseBody
  | JsonApiDataObjectCollectionResponseBody;

// also return type of create, read and update - https://jsonapi.org/format/1.0/#crud-creating-responses and https://jsonapi.org/format/#crud-updating
export interface JsonApiDataObjectResponseBody {
  readonly data: DataObject;
  readonly included?: ResourceObject[];
  readonly meta?: MetaObject;
  readonly jsonapi?: JsonApiObject;
  readonly links?: { [rel: string]: LinkObject };
}

export interface JsonApiDataObjectCollectionResponseBody {
  readonly data: DataObjectCollection;
  readonly included?: ResourceObject[];
  readonly meta?: MetaObject;
  readonly jsonapi?: JsonApiObject;
  readonly links?: { [rel: string]: LinkObject };
}

export function isJsonApiDataResponseBody(
  document: unknown,
): document is JsonApiDataResponseBody {
  return hasPropertyOfType('data', isDefined)(document);
}

export function isJsonApiDataObjectCollectionResponseBody(
  document: unknown,
): document is JsonApiDataObjectCollectionResponseBody {
  return isJsonApiDataResponseBody(document) && Array.isArray(document.data);
}

export function isJsonApiDataObjectResponseBody(
  document: unknown,
): document is JsonApiDataObjectResponseBody {
  return (
    isJsonApiDataResponseBody(document) &&
    !isJsonApiDataObjectCollectionResponseBody(document)
  );
}

export function isJsonApiErrorResponseBody(
  document: unknown,
): document is JsonApiErrorResponseBody {
  return (document as any).errors !== undefined;
}

export function isJsonApiMetaResponseBody(
  document: unknown,
): document is JsonApiMetaResponseBody {
  return (
    !isJsonApiDataResponseBody(document) &&
    !isJsonApiErrorResponseBody(document)
  );
}

export type DataObject = null | ResourceObject | ResourceIdentifierObject;
export type DataObjectCollection =
  | ResourceObject[]
  | ResourceIdentifierObject[];

// https://jsonapi.org/format/1.0/#document-jsonapi-object
export interface JsonApiObject {
  readonly version?: string;
  readonly meta: MetaObject;
}

// https://jsonapi.org/format/1.0/#errors
export interface ErrorObject {
  readonly id?: string;
  readonly links?: { readonly about: LinkObject };
  readonly status?: string;
  readonly code?: string;
  readonly title?: string;
  readonly detail?: string;
  readonly source?: {
    readonly pointer?: string;
    readonly parameter?: string;
  };
  readonly meta?: MetaObject;
}

// https://jsonapi.org/format/1.0/#document-resource-identifier-objects
export interface ResourceIdentifierObject {
  readonly id: string;
  readonly type: string;
  readonly meta?: MetaObject;
}

// https://jsonapi.org/format/1.0/#document-resource-objects
export interface ResourceObject extends ResourceIdentifierObject {
  readonly attributes?: AttributeObject;
  readonly relationships?: {
    readonly [attributeName: string]: RelationshipObject;
  };
  readonly links?: { readonly [rel: string]: LinkObject };
}

// https://jsonapi.org/format/1.0/#document-resource-object-attributes
export type AttributeObject = {
  readonly [attributeName in Exclude<string, 'links' | 'relationships'>]: any;
};

// https://jsonapi.org/format/1.0/#document-resource-object-relationships
export type RelationshipLinks = { readonly [rel: string]: LinkObject } & (
  | { readonly self: string }
  | { readonly related: string }
);

export interface RelationshipObject {
  readonly links?: RelationshipLinks;
  readonly data?: ResourceLinkage | ResourceLinkageCollection;
  readonly meta?: MetaObject;
}

// https://jsonapi.org/format/1.0/#document-meta
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type MetaObject = { readonly [key: string]: any };

// https://jsonapi.org/format/1.0/#document-links
export type LinkObject =
  | string
  | { readonly href: string; readonly meta?: MetaObject };

// https://jsonapi.org/format/1.0/#document-resource-object-linkage
export type ResourceLinkage = null | ResourceIdentifierObject;
export type ResourceLinkageCollection = ReadonlyArray<ResourceIdentifierObject>;

// https://jsonapi.org/format/1.0/#crud-creating
export interface CreateResourceRequestBody {
  readonly data: {
    readonly type: string;
    readonly id?: string;
    readonly attributes?: AttributeObject;
    readonly relationships?: {
      readonly [attributeName: string]: {
        data: ResourceLinkage | ResourceLinkageCollection;
      };
    };
  };
}

// https://jsonapi.org/format/#crud-updating
export interface UpdateResourceRequestBody {
  readonly data: {
    readonly type: string;
    readonly id: string;
    readonly attributes?: AttributeObject;
    readonly relationships?: {
      readonly [attributeName: string]: {
        data: ResourceLinkage | ResourceLinkageCollection;
      };
    };
  };
}
