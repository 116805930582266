import {
  StoreEventConfiguration,
  StoreEventHandler,
} from './simple-store.type';

export class SimpleStoreConfigurationBuilder<
  TState,
  TStoreEventConfiguration extends StoreEventConfiguration<TState, unknown>,
> {
  constructor(public readonly configuration: TStoreEventConfiguration) {}

  registerEvent<TKey extends string, TPayload>(
    key: TKey,
    handler: StoreEventHandler<TState, TPayload>,
  ): SimpleStoreConfigurationBuilder<
    TState,
    TStoreEventConfiguration & {
      [eventKey in TKey]: StoreEventHandler<TState, TPayload>;
    }
  > {
    const configuration = {
      ...this.configuration,
      [key]: handler,
    } as TStoreEventConfiguration & {
      [eventKey in TKey]: StoreEventHandler<TState, TPayload>;
    };
    return new SimpleStoreConfigurationBuilder(configuration);
  }
}
