import { range } from '@bbraun/shared/util-lang';
import { map, merge, Observable, scan } from 'rxjs';

/**
 * This observable factory is similar to combineLatest, but emits a value,
 * every time one of the observables emits, even if not all observables,
 * have emitted a value.
 *
 * @param obs The observables to combine.
 * @param initial The initial values.
 */
export function combine<T1, T2, T3>(
  obs: readonly [Observable<T1>, Observable<T2>, Observable<T3>],
): Observable<{
  index?: 0 | 1 | 2;
  values: [T1 | undefined, T2 | undefined, T3 | undefined];
}>;
export function combine<T1, T2, T3, T4>(
  obs: readonly [
    Observable<T1>,
    Observable<T2>,
    Observable<T3>,
    Observable<T4>,
  ],
): Observable<{
  index?: 0 | 1 | 2 | 3;
  values: [T1 | undefined, T2 | undefined, T3 | undefined, T4 | undefined];
}>;
export function combine<T1, T2, T3, T1Initial, T2Initial, T3Initial>(
  obs: readonly [Observable<T1>, Observable<T2>, Observable<T3>],
  initial: [T1Initial, T2Initial, T3Initial],
): Observable<{
  index?: 0 | 1 | 2;
  values: [T1 | T1Initial, T2 | T2Initial, T3 | T3Initial];
}>;
export function combine<T1, T2>(
  obs: readonly [Observable<T1>, Observable<T2>],
): Observable<{ index?: 0 | 1; values: [T1 | undefined, T2 | undefined] }>;
export function combine<T1, T2, T1Initial, T2Initial>(
  obs: readonly [Observable<T1>, Observable<T2>],
  initial: [T1Initial, T2Initial],
): Observable<{ index?: 0 | 1; values: [T1 | T1Initial, T2 | T2Initial] }>;
export function combine<T, TInitial>(
  obs: ReadonlyArray<Observable<T>>,
  initial: ReadonlyArray<T | TInitial>,
): Observable<{ index?: number; values: ReadonlyArray<T | TInitial> }>;
export function combine<T>(
  obs: ReadonlyArray<Observable<T>>,
): Observable<{ index?: number; values: ReadonlyArray<T | undefined> }>;
export function combine<T>(
  obs: ReadonlyArray<Observable<T>>,
  initial?: ReadonlyArray<T | undefined>,
): Observable<{ index?: number; values: ReadonlyArray<T | undefined> }> {
  const inputs$ = merge(
    ...obs.map((o, index) => o.pipe(map((value) => ({ index, value })))),
  );
  return inputs$.pipe(
    scan(
      (
        { values }: { values: ReadonlyArray<T | undefined> },
        { index, value },
      ) => ({
        index,
        values: [...values.slice(0, index), value, ...values.slice(index + 1)],
      }),
      {
        values: initial || range(obs.length).map(() => undefined),
      },
    ),
  );
}
