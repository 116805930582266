import { fromChunks } from '@bbraun/shared/util-rxjs';
import { Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import { JsonApiHttpClient } from './http.type';
import { CreateObject, UpdateObject } from './json-api-adpater.type';
import { JsonApiUrlAdapter } from './json-api-url-adapter';
import {
  JsonApiDataObjectCollectionResponseBody,
  JsonApiDataObjectResponseBody,
  JsonApiErrorResponseBody,
  JsonApiMetaResponseBody,
} from './json-api.types';
import { JsonApiMeta } from './set-api-meta-headers';
import {
  QueryParameter,
  TypedQueryParameters,
} from './typed/typed-query-parameters';

export class JsonApiAdapter2<
  TQueryParameters extends
    | QueryParameter
    | TypedQueryParameters<any, any, any, any, any> = QueryParameter,
> {
  private readonly jsonApiUrlAdapter: JsonApiUrlAdapter<TQueryParameters>;

  constructor(
    apiMeta: JsonApiMeta,
    type: string,
    private readonly resourceUrl: Observable<string>,
    httpClient: JsonApiHttpClient,
    fiqlSerializer: (
      parameters: Exclude<TQueryParameters['filter'], undefined | string>,
      encoder: (value: string | number | boolean) => string,
    ) => string | false,
    sortSerializer: (
      sort: Exclude<TQueryParameters['sort'], undefined>,
    ) => string,
    queryParametersCombiner: (
      param1: TQueryParameters,
      param2: TQueryParameters,
    ) => TQueryParameters,
    queryParameter?: TQueryParameters,
  ) {
    this.jsonApiUrlAdapter = new JsonApiUrlAdapter(
      apiMeta,
      type,
      httpClient,
      fiqlSerializer,
      sortSerializer,
      queryParametersCombiner,
      queryParameter,
    );
  }

  public get isConnected() {
    return this.resourceUrl
      .pipe(map(() => true))
      .pipe(catchError(() => of(false)))
      .pipe(distinctUntilChanged());
  }

  public query(
    queryParameter: TQueryParameters,
  ): Observable<
    JsonApiErrorResponseBody | JsonApiDataObjectCollectionResponseBody
  > {
    return this.resourceUrl.pipe(
      switchMap((resourceUrl) =>
        this.jsonApiUrlAdapter.query(resourceUrl, queryParameter),
      ),
    );
  }

  public queryMany(
    queryParameters: ReadonlyArray<TQueryParameters>,
  ): Observable<
    | {
        state: 'value';
        value:
          | JsonApiErrorResponseBody
          | JsonApiDataObjectCollectionResponseBody;
      }
    | { state: 'start' | 'done' }
  > {
    return this.resourceUrl.pipe(
      switchMap((resourceUrl) =>
        fromChunks(
          queryParameters.map((queryParameter) =>
            this.jsonApiUrlAdapter.query(resourceUrl, queryParameter),
          ),
        ),
      ),
    );
  }

  public read(
    id: string,
    queryParameter: TQueryParameters = {} as TQueryParameters,
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    return this.resourceUrl.pipe(
      switchMap((resourceUrl) =>
        this.jsonApiUrlAdapter.read(`${resourceUrl}/${id}`, queryParameter),
      ),
    );
  }

  public create(
    data: CreateObject,
    queryParameter: TQueryParameters = {} as TQueryParameters,
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    return this.resourceUrl
      .pipe(first())
      .pipe(
        switchMap((resourceUrl) =>
          this.jsonApiUrlAdapter.create(resourceUrl, data, queryParameter),
        ),
      );
  }

  public update(
    data: UpdateObject,
    queryParameter: TQueryParameters = {} as TQueryParameters,
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    return this.resourceUrl
      .pipe(first())
      .pipe(
        switchMap((resourceUrl) =>
          this.jsonApiUrlAdapter.update(
            `${resourceUrl}/${data.id}`,
            data,
            queryParameter,
          ),
        ),
      );
  }

  public delete(
    id: string,
  ): Observable<JsonApiErrorResponseBody | JsonApiMetaResponseBody | void> {
    return this.resourceUrl
      .pipe(first())
      .pipe(
        switchMap((resourceUrl) =>
          this.jsonApiUrlAdapter.delete(`${resourceUrl}/${id}`),
        ),
      );
  }
}
