import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IsNavigationInProgressService {
  public readonly isNavigationInProgress$: Observable<boolean>;

  constructor(private readonly router: Router) {
    this.isNavigationInProgress$ = this.router.events.pipe(
      switchMap((event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            return of(true);
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            return of(false);
          }
          default: {
            return EMPTY;
          }
        }
      }),
    );
  }
}
