import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const APP_STATE_PERSISTENCE =
  new InjectionToken<StatePersistenceStrategy>('APP_STATE_PERSISTENCE');

export type StatePersistenceStrategyType = 'localStorage';

export interface StatePersistenceStrategy {
  get<V>(key: string): Observable<V | undefined>;

  store<V>(key: string, value?: V): void;
}
