import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { Observable } from 'rxjs';
import { LocaleState } from './+state/locale.reducer';
import { TranslationLoaderRegistry } from './translation-loader-registry.type';
import { TranslationServiceConnector } from './translation-service-connector.type';

export const SUPPORTED_LOCALES = new InjectionToken<string[]>(
  'SUPPORTED_LOCALES',
);

export const LANGUAGE_SELECTION_DISABLED = new InjectionToken<boolean>(
  'LANGUAGE_SELECTION_DISABLED',
);

export const TRANSLATION_SERVICE_CONNECTOR =
  new InjectionToken<TranslationServiceConnector>(
    'TRANSLATION_SERVICE_CONNECTOR',
  );

export const TRANSLATION_LOADER_REGISTRY =
  new InjectionToken<TranslationLoaderRegistry>('TRANSLATION_LOADER_REGISTRY');

export const ANGULAR_LOCALE = new InjectionToken<Observable<string>>(
  'ANGULAR_LOCALE',
);

export const NGRX_CONFIG_TOKEN = new InjectionToken<StoreConfig<LocaleState>>(
  'NgrxLocaleStateConfig',
);
