import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Buildinfo } from './buildinfo';

const defaultBuildinfo: Buildinfo = {
  id: 'development',
  name: '',
  hash: '',
};

@Injectable({
  providedIn: 'root',
})
export class BuildinfoService {
  constructor(private readonly http: HttpClient) {}

  get(): Observable<Buildinfo> {
    return this.http
      .get<Buildinfo>('assets/buildinfo.json', {
        headers: { 'cache-control': 'no-cache' },
      })
      .pipe(
        map((buildinfo) => {
          if (this.isBuildinfo(buildinfo)) {
            return this.shortenBuildinfo(buildinfo);
          } else {
            console.warn('Buildinfo contents is invalid', buildinfo);
            return defaultBuildinfo;
          }
        }),
        catchError((err) => {
          console.warn('Could not load buildinfo', err);
          return of(defaultBuildinfo);
        }),
      );
  }

  private isBuildinfo(value: any): value is Buildinfo {
    return (
      value &&
      ['id', 'name', 'hash'].filter((property) =>
        value.hasOwnProperty(property),
      ).length === 3
    );
  }

  private shortenBuildinfo(buildinfo: Buildinfo): Buildinfo {
    let { name, hash } = buildinfo;
    if (typeof name === 'string' && name.startsWith('refs/heads/')) {
      name = name.replace('refs/heads/', '');
    }
    if (typeof hash === 'string' && hash.length > 7) {
      hash = hash.substring(0, 7);
    }
    return {
      ...buildinfo,
      name,
      hash,
    };
  }
}
