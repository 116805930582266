export function createOwnPatientHemoDialysisThreeMonthsReferenceProperty(
  identifier: string,
) {
  return {
    title: 'NUMBER OF PATIENTS',
    technicalName: `ownPatientHemoDialysisThreeMonths-${identifier}`,
    dataType: 'calculated',
    unit: null,
    highlighted: false,
    subName: null,
    formula: 'ownPatientHemoDialysis3Month',
  } as const;
}
