interface LocalizedNumberBasicOptions {
  localeMatcher?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

export type LocalizedNumberDecimalOptions = LocalizedNumberBasicOptions & {
  style?: 'decimal' | undefined;
};

export type LocalizedNumberCurrencyOptions = LocalizedNumberBasicOptions & {
  style: 'currency';
  currency: string;
  currencyDisplay?: string;
  currencySign?: string;
};

export type LocalizedNumberPercentOptions = LocalizedNumberBasicOptions & {
  style: 'percent';
  showSign: boolean;
};

export type LocalizedNumberOptions =
  | LocalizedNumberDecimalOptions
  | LocalizedNumberCurrencyOptions
  | LocalizedNumberPercentOptions;

export type LocalizedNumberFormatErrorHandler = (errorDetails: {
  error: unknown;
  value: number | undefined;
  locale: string;
  options: Partial<LocalizedNumberOptions>;
}) => string | null | undefined;

export function convertLocalizedNumberToString(
  locale: string,
  value: number,
  options: LocalizedNumberOptions,
  onError: LocalizedNumberFormatErrorHandler,
): string | null | undefined {
  try {
    const formattedValue = new Intl.NumberFormat(locale, {
      ...options,
      style: options.style === 'percent' ? 'decimal' : options.style,
    }).format(value);
    return formattedValue && options.style === 'percent' && options.showSign
      ? `${formattedValue}%`
      : formattedValue;
  } catch (error) {
    return onError({
      value,
      locale,
      error,
      options,
    });
  }
}
