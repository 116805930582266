import { NgModule } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { IsBooleanPipe } from './pipes/is-boolean.pipe';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { IncorrectNotNullNumberTypePipe } from './pipes/incorrect-not-null-number-type.pipe';
import { IncorrectNotNullStringTypePipe } from './pipes/incorrect-not-null-string-type.pipe';
import { AsNumberOrUndefinedPipe } from './pipes/as-number-or-undefined-type.pipe';
import { AsBooleanOrUndefinedPipe } from './pipes/as-boolean-or-undefined-type.pipe';
import { UTIL_LANG_NG_AS_TYPE_PIPE_CONVERSION_ERROR } from './injection-tokens';
import { IncorrectNotNullBooleanTypePipe } from './pipes/incorrect-not-null-boolean-type.pipe';

const asTypePipeConversionError = Symbol('asTypePipeConversionError');

function createAsTypePipeConversionErrorHandler(
  messageService: MessageService,
): <T>(valueOnError: T) => (error: unknown, details?: unknown) => T {
  return (valueOnError) => (error, details) => {
    messageService.message('Failed to convert unknown value to type', 'error', {
      id: asTypePipeConversionError,
      actions: [{ type: 'push-or-count' }],
      values: details,
      error,
      timeOut: false,
    });

    return valueOnError;
  };
}

@NgModule({
  imports: [],
  declarations: [
    IsNumberPipe,
    IsBooleanPipe,
    IncorrectNotNullBooleanTypePipe,
    IncorrectNotNullNumberTypePipe,
    IncorrectNotNullStringTypePipe,
    AsNumberOrUndefinedPipe,
    AsBooleanOrUndefinedPipe,
  ],
  exports: [
    IsNumberPipe,
    IsBooleanPipe,
    IncorrectNotNullBooleanTypePipe,
    IncorrectNotNullNumberTypePipe,
    IncorrectNotNullStringTypePipe,
    AsNumberOrUndefinedPipe,
    AsBooleanOrUndefinedPipe,
  ],
  providers: [
    {
      provide: UTIL_LANG_NG_AS_TYPE_PIPE_CONVERSION_ERROR,
      useFactory: createAsTypePipeConversionErrorHandler,
      deps: [MessageService],
    },
  ],
})
export class BBraunUtilLangNgModule {}
