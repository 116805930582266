import { Injectable } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { mapToVoid } from '@bbraun/shared/util-rxjs';
import { Observable, switchMap, tap } from 'rxjs';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { GlobalErrorHandler } from './global-error-handler';
import { OnNavigationErrorService } from './on-navigation-error.service';
import { ErrorTranslationService } from './error-translation.service';

const ON_NAVIGATION_ERROR_ID = Symbol('ON_NAVIGATION_ERROR_ID');

@Injectable({ providedIn: 'root' })
export class OnNavigationErrorHandlerService {
  readonly connect$: Observable<void>;

  constructor(
    onNavigationErrorService: OnNavigationErrorService,
    errorHandler: GlobalErrorHandler,
    messageService: MessageService,
    translationService: ErrorTranslationService,
  ) {
    this.connect$ = onNavigationErrorService.error$
      .pipe(
        switchMap((error) =>
          translationService
            .translate(
              i18n(
                'bbraunSharedUtilErrorNg.onNavigationErrorHandlerService.error.message',
              ),
            )
            .pipe(
              tap((errorMessage) => {
                messageService.message(errorMessage, 'error', {
                  error,
                  id: ON_NAVIGATION_ERROR_ID,
                  timeOut: false,
                });

                errorHandler.onError(error, {
                  timeOut: true,
                  noGenericError: true,
                });
              }),
            ),
        ),
      )
      .pipe(mapToVoid());
  }
}
