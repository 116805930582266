<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAdministration';
    read: 'bbraunBavReportingFeatureAdministration.labDataTestConfiguration'
  "
>
  <div class="bav-reporting-page-header mb-1 mt-1">
    {{ translate('header.text') }}
    <mat-icon
      id="infoIcon"
      class="bbraun-info-icon ml-1"
      [bbraunUiHideableContainerToggle]="infoBoxContainer"
      >info</mat-icon
    >
  </div>

  <bbraun-ui-hideable-container [show]="false" #infoBoxContainer>
    <bbraun-ui-info-box
      *ngIf="infoBoxItems$ | async as infoBoxItems"
      [infoBoxItems]="infoBoxItems"
      (closeInfoBoxClick)="infoBoxContainer.show = false"
    >
    </bbraun-ui-info-box>
  </bbraun-ui-hideable-container>
  <mat-card class="bbraun-flat">
    <dx-data-grid
      #dataGrid
      [dataSource]="dataSource"
      class="bav-reporting-datagrid-table bbraun-clickable-cursor"
      [showRowLines]="true"
      [showColumnLines]="false"
      [errorRowEnabled]="false"
      [allowColumnResizing]="true"
      (onEditorPreparing)="setDropdownWidth($event)"
      (onEditingStart)="startEditing()"
      (onKeyDown)="cancelEditingWithEscape($event, dataGrid)"
      (pagingChange)="cancelEditing(dataGrid)"
    >
      <dxo-editing
        mode="row"
        [allowUpdating]="
          (canSynchronizeLabTestMappingGuard.canActivate$ | async) || false
        "
        [allowAdding]="false"
      >
      </dxo-editing>
      <dxo-load-panel [enabled]="true" [showPane]="true" [showIndicator]="true">
      </dxo-load-panel>

      <dxi-column
        dataField="labTestIdClinicalData"
        width="5%"
        alignment="left"
        caption=""
        cssClass="is-set-icon"
        [allowEditing]="false"
        cellTemplate="isSetTemplate"
      ></dxi-column>
      <dxi-column
        dataField="laboratoryName"
        width="20%"
        alignment="left"
        [caption]="translate('laboratory.caption')"
        [allowEditing]="false"
      ></dxi-column>
      <dxi-column
        dataField="parameterName"
        width="20%"
        dataType="string"
        alignment="left"
        [caption]="translate('parameterName.caption')"
        [allowEditing]="false"
      ></dxi-column>
      <dxi-column
        dataField="labTestIdExtern"
        width="15%"
        dataType="string"
        alignment="left"
        [caption]="translate('id.caption')"
        [allowEditing]="false"
      ></dxi-column>
      <dxi-column
        dataField="parameterUnit"
        width="15%"
        dataType="string"
        alignment="left"
        [caption]="translate('unit.caption')"
        [allowEditing]="false"
      ></dxi-column>
      <dxi-column
        dataField="labTestIdClinicalData"
        width="25%"
        dataType="string"
        alignment="left"
        [caption]="translate('labTestIdClinicalData.caption')"
        [editorOptions]="{
          placeholder: translate('labTestIdClinicalData.placeholder')
        }"
      >
        <dxo-lookup
          [dataSource]="(parameterSelectionOptions$ | async) || []"
          valueExpr="parameterName"
          displayExpr="displayName"
        ></dxo-lookup>
      </dxi-column>

      <dxi-column type="buttons">
        <dxi-button name="edit"></dxi-button>
        <dxi-button name="save" template="saveButton">
          <div *dxTemplate="let data of 'saveButton'">
            <button
              (click)="saveChanges(dataGrid)"
              class="command-button dx-link-icon dx-link-save"
            >
              <mat-icon class="icon">check</mat-icon>
            </button>
          </div>
        </dxi-button>
        <dxi-button name="cancel" template="cancelButton">
          <div *dxTemplate="let data of 'cancelButton'">
            <button
              (click)="cancelEditing(dataGrid)"
              class="command-button dx-link-icon"
            >
              <mat-icon class="icon">close</mat-icon>
            </button>
          </div>
        </dxi-button>
      </dxi-column>

      <div *dxTemplate="let data of 'isSetTemplate'">
        <mat-icon
          *ngIf="data.value !== null"
          [ngClass]="{
            'not-applicable-icon': data.value === 'NotApplicable'
          }"
          >check</mat-icon
        >
      </div>
    </dx-data-grid>
  </mat-card>
</ng-container>
