import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { DefaultCenterService } from '../services/default-center.service';
import { CenterConfiguration } from '../types';

@Injectable()
export class DefaultCenterResolver
  implements Resolve<CenterConfiguration | undefined>
{
  constructor(private readonly defaultCenterService: DefaultCenterService) {}

  resolve(): Observable<CenterConfiguration> {
    return this.defaultCenterService
      .getDefaultCenter()
      .pipe(
        switchMap((centerConfiguration) =>
          centerConfiguration
            ? of(centerConfiguration)
            : throwError(() => new Error('Default center is not defined.')),
        ),
      );
  }
}
