<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingFeatureAdministration';
    read: 'bbraunBavReportingFeatureAdministration.userLogs'
  "
>
  <div class="flex-box full-width space-between flex-wrap gap-1 mb-1 mt-1">
    <span class="bav-reporting-page-header">
      {{ translate('header.text') }}
      <mat-icon
        id="infoIcon"
        class="bbraun-info-icon ml-1"
        [bbraunUiHideableContainerToggle]="infoBoxContainer"
      >
        info
      </mat-icon>
    </span>
    <bav-reporting-ui-common-start-data-transfer-button
      class="start-data-transfer-button flex-item no-flex-grow"
      [canActivate]="canStartTransferGuard.canActivate$ | async"
    ></bav-reporting-ui-common-start-data-transfer-button>
  </div>
  <bbraun-ui-hideable-container [show]="false" #infoBoxContainer>
    <bbraun-ui-info-box
      *ngIf="infoBoxItems$ | async as infoBoxItems"
      [infoBoxItems]="infoBoxItems"
      (closeInfoBoxClick)="infoBoxContainer.show = false"
    >
    </bbraun-ui-info-box>
  </bbraun-ui-hideable-container>
  <mat-card class="bbraun-flat">
    <dx-data-grid
      [dataSource]="dataSource"
      class="bav-reporting-datagrid-table bbraun-clickable-cursor"
      [showRowLines]="true"
      [showColumnLines]="false"
      [allowColumnResizing]="true"
      [remoteOperations]="{ paging: true }"
    >
      <dxi-column
        dataField="severity"
        width="10%"
        alignment="left"
        [caption]="translate('severity.caption')"
        cellTemplate="isSetTemplate"
      ></dxi-column>
      <dxi-column
        dataField="message"
        width="40%"
        alignment="left"
        [caption]="translate('message.caption')"
      ></dxi-column>
      <dxi-column
        dataField="createdAt"
        dataType="date"
        format="shortDateShortTime"
        width="15%"
        alignment="left"
        [caption]="translate('createdAt.caption')"
      ></dxi-column>
      <dxi-column
        dataField="messageCode"
        width="15%"
        alignment="left"
        [caption]="translate('messageCode.caption')"
      ></dxi-column>
      <dxi-column
        dataField="jobType"
        width="12%"
        alignment="left"
        [caption]="translate('jobType.caption')"
      ></dxi-column>
      <dxi-column
        dataField="jobIdentifier"
        width="8%"
        alignment="left"
        [caption]="translate('jobIdentifier.caption')"
      ></dxi-column>

      <div *dxTemplate="let data of 'isSetTemplate'">
        <mat-icon
          [ngClass]="{
            info: data.value === 'Information',
            warning: data.value === 'Warning',
            error: data.value === 'Error',
            fatal: data.value === 'Fatal'
          }"
          >{{
            data.value === 'Error'
              ? 'warning'
              : data.value === 'Fatal'
              ? 'priority_high'
              : ''
          }}</mat-icon
        >
      </div>
    </dx-data-grid>
  </mat-card>
</ng-container>
