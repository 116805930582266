import { Inject, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { defer } from 'rxjs';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import fallbackTranslation from '../i18n/en.json';
import { ConfirmInformationDialogComponent } from './components/confirm-information-dialog/confirm-information-dialog.component';

const SCOPE = 'bbraunSharedUiConfirmInformationDialog';

@NgModule({
  imports: [
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    TranslocoModule,
  ],
  declarations: [ConfirmInformationDialogComponent],
  exports: [ConfirmInformationDialogComponent],
})
export class UiConfirmInformationDialogModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );
  }
}
