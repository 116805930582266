import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_INTEGER_ID } from '../../../../validation/factories/is-number-integer-validator-factory';

export function createNutritionSgaBProperty(): Property {
  return {
    title: 'Grade B',
    technicalName: 'nutritionSgaB',
    dataType: 'number',
    unit: null,
    highlighted: false,
    subName: null,
    formula: null,
    validation: [
      { validator: IS_NUMBER_INTEGER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
    ],
  };
}
