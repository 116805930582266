import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { InfoBoxComponent } from './components/info-box/info-box.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [InfoBoxComponent],
  exports: [InfoBoxComponent],
})
export class UiInfoBoxModule {}
