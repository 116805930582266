import { Observable, of, OperatorFunction, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ModelObject } from '../model-object.type';
import { readJsonApiCollectionResponseUnsafe } from '../read-json-api-collection-response-unsafe';
import { ApiDefinition, ApiTypes } from '../typed/api-types';

export function mapToJsonApiCollectionResponseUnsafe<
  T,
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
>(): OperatorFunction<
  {} | null,
  Array<T & ModelObject<TApiDefinition, TTypeName>>
> {
  return (o) =>
    o.pipe(
      concatMap((value) =>
        mapDocumentToJsonApiCollectionResponseUnsafe<
          T,
          TApiDefinition,
          TTypeName
        >(value),
      ),
    );
}

export function mapDocumentToJsonApiCollectionResponseUnsafe<
  T,
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
>(
  value: {} | null,
): Observable<Array<T & ModelObject<TApiDefinition, TTypeName>>> {
  try {
    return of(
      readJsonApiCollectionResponseUnsafe<T, TApiDefinition, TTypeName>(value)
        .result,
    );
  } catch (exception) {
    return throwError(() => ({ document: value, exception }));
  }
}
