import { LocaleAction, LocaleActionTypes } from './locale.actions';

export const LOCALE_FEATURE_KEY = 'locale';

export interface LocaleState {
  locale?: string;
}

export interface LocalePartialState {
  readonly [LOCALE_FEATURE_KEY]: LocaleState;
}

export const initialState: LocaleState = {};

export function localeReducer(
  state: LocaleState = initialState,
  action: LocaleAction,
): LocaleState {
  switch (action.type) {
    case LocaleActionTypes.SwitchLocale: {
      state = {
        ...state,
        locale: action.payload.locale,
      };
      break;
    }
  }
  return state;
}
