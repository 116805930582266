import { parse, round, MathNode, ConstantNode, SymbolNode } from 'mathjs';
import { clone, isEqual } from 'lodash-es';
import { ReportDetailsResponseModel } from '@bbraun/bav-reporting/data-access-ais-reports';
import {
  CalculatedProperties,
  CalculatedPropertyValue,
  FormulaProperties,
} from '../types/types';

export interface CalculatedPropertiesError {
  error: unknown;
  details: {
    formula: string;
  };
}

function isSymbolNode(node: MathNode): node is SymbolNode {
  return node.type === 'SymbolNode';
}

export function computeCalculatedProperties(
  reportDetails: Partial<ReportDetailsResponseModel> | undefined,
  formulaProperties: FormulaProperties,
  cyclicDependencyCallback: (technicalName: string) => void,
): {
  properties: CalculatedProperties;
  errors?: ReadonlyArray<CalculatedPropertiesError>;
} {
  const formulasBasedOnCalculatedValues: {
    technicalName: string;
    expressionTree: MathNode;
  }[] = [];

  const { formulasWithExpressionTree, errors: formulaTransformationErrors } =
    transformFormulaToFormulaWithExpressionTree(formulaProperties);

  const isPropertyFormulaBasedOnCalculatedValuesDictionary =
    getIsPropertyFormulaBasedOnCalculatedValuesDictionary(
      formulasWithExpressionTree,
    );

  let calculatedProperties: {
    properties: CalculatedProperties;
    errors: ReadonlyArray<CalculatedPropertiesError>;
  } = {
    properties: {},
    errors: formulaTransformationErrors,
  };
  for (const technicalName of Object.keys(
    isPropertyFormulaBasedOnCalculatedValuesDictionary,
  )) {
    const { expressionTree } =
      isPropertyFormulaBasedOnCalculatedValuesDictionary[technicalName];

    const symbolNodes = expressionTree.filter((node) =>
      isSymbolNode(node),
    ) as SymbolNode[];

    const formulaContainsCalculatedValues = symbolNodes.some(
      (symbol) =>
        isPropertyFormulaBasedOnCalculatedValuesDictionary[symbol.name],
    );

    if (formulaContainsCalculatedValues) {
      formulasBasedOnCalculatedValues.push({
        technicalName,
        expressionTree,
      });
    } else {
      const calculatedFormula = calculateFormulaFromReportDetails(
        reportDetails,
        expressionTree,
      );

      calculatedProperties = {
        ...calculatedProperties,
        properties: {
          ...calculatedProperties.properties,
          [technicalName]: calculatedFormula.value,
        },
        errors: [...calculatedProperties.errors, ...calculatedFormula.errors],
      };
    }
  }

  let cyclicDependencyIndex = 0;
  let previousFormulasArray: {
    technicalName: string;
    expressionTree: MathNode;
  }[] = [];
  while (formulasBasedOnCalculatedValues.length !== 0) {
    if (
      formulasHaveNotChangedInLastIteration(
        previousFormulasArray,
        formulasBasedOnCalculatedValues,
      )
    ) {
      cyclicDependencyIndex++;
      if (cyclicDependencyIndex === formulasBasedOnCalculatedValues.length) {
        for (const formula of formulasBasedOnCalculatedValues) {
          cyclicDependencyCallback(formula.technicalName);
          calculatedProperties = {
            ...calculatedProperties,
            properties: {
              ...calculatedProperties.properties,
              [formula.technicalName]: null,
            },
          };
        }
        break;
      }
    } else {
      cyclicDependencyIndex = 0;
    }

    const currentFormula = formulasBasedOnCalculatedValues[0];

    const symbolNodes = currentFormula.expressionTree.filter((node) =>
      isSymbolNode(node),
    ) as SymbolNode[];

    const canBeCalculatedFromCalculatedPropertiesAndReportDetails =
      symbolNodes.every(
        (symbol) =>
          !isPropertyFormulaBasedOnCalculatedValuesDictionary[
            symbol.name as string
          ] || (symbol.name as string) in calculatedProperties.properties,
      );

    previousFormulasArray = clone(formulasBasedOnCalculatedValues);

    if (canBeCalculatedFromCalculatedPropertiesAndReportDetails) {
      const calculatedFormula =
        calculateFormulaFromReportDetailsAndCalculatedProperties(
          reportDetails,
          calculatedProperties.properties,
          currentFormula.expressionTree,
        );

      calculatedProperties = {
        ...calculatedProperties,
        properties: {
          ...calculatedProperties.properties,
          [currentFormula.technicalName]: calculatedFormula.value,
        },
        errors: [...calculatedProperties.errors, ...calculatedFormula.errors],
      };

      formulasBasedOnCalculatedValues.shift();
    } else {
      const firstElement = formulasBasedOnCalculatedValues.shift();
      if (firstElement) {
        formulasBasedOnCalculatedValues.push(firstElement);
      }
    }
  }

  return {
    properties: calculatedProperties.properties,
    errors:
      calculatedProperties.errors.length > 0
        ? calculatedProperties.errors
        : undefined,
  };
}

function sortAlphabetically(a: string, b: string): 1 | -1 | 0 {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

function formulasHaveNotChangedInLastIteration(
  previousValues: {
    technicalName: string;
    expressionTree: MathNode;
  }[],
  currentValues: {
    technicalName: string;
    expressionTree: MathNode;
  }[],
) {
  if (previousValues.length !== currentValues.length) {
    return false;
  }
  const compareArr1 = clone(previousValues).sort((a, b) =>
    sortAlphabetically(a.technicalName, b.technicalName),
  );
  const compareArr2 = clone(currentValues).sort((a, b) =>
    sortAlphabetically(a.technicalName, b.technicalName),
  );

  return isEqual(compareArr1, compareArr2);
}

function transformFormulaToFormulaWithExpressionTree(
  formulaProperties: FormulaProperties,
) {
  return Object.keys(formulaProperties).reduce(
    (
      acc: {
        formulasWithExpressionTree: {
          [technicalName: string]: {
            formula: string;
            expressionTree: MathNode;
          };
        };
        errors: ReadonlyArray<CalculatedPropertiesError>;
      },
      technicalName,
    ) => {
      const formula = formulaProperties[technicalName];
      if (formula) {
        try {
          const expressionTree = parse(formula);

          return {
            ...acc,
            formulasWithExpressionTree: {
              ...acc.formulasWithExpressionTree,
              [technicalName]: {
                formula,
                expressionTree,
              },
            },
          };
        } catch (error) {
          return {
            ...acc,
            errors: [
              ...acc.errors,
              {
                error,
                details: {
                  formula,
                },
              },
            ],
          };
        }
      } else {
        return acc;
      }
    },
    { formulasWithExpressionTree: {}, errors: [] },
  );
}

function getIsPropertyFormulaBasedOnCalculatedValuesDictionary(formulaWithExpressionTree: {
  [technicalName: string]: {
    formula: string;
    expressionTree: MathNode;
  };
}): {
  [technicalName: string]: {
    formula: string;
    expressionTree: MathNode;
    isFormulaBasedOnCalculatedValues: boolean;
  };
} {
  let result: ReturnType<
    typeof getIsPropertyFormulaBasedOnCalculatedValuesDictionary
  > = {};
  for (const technicalName of Object.keys(formulaWithExpressionTree)) {
    const { formula, expressionTree } =
      formulaWithExpressionTree[technicalName];
    const formulaSymbolNodes = formulaWithExpressionTree[
      technicalName
    ].expressionTree.filter((node) => isSymbolNode(node)) as SymbolNode[];
    result = {
      ...result,
      [technicalName]: {
        formula,
        expressionTree,
        isFormulaBasedOnCalculatedValues: formulaSymbolNodes.some(
          (symbol) => (symbol.name as string) in formulaWithExpressionTree,
        ),
      },
    };
  }

  return result;
}

function calculateFormulaFromReportDetails(
  reportDetails: Partial<ReportDetailsResponseModel> | undefined,
  formulaExpressionTree: MathNode,
): {
  value?: CalculatedPropertyValue;
  errors: ReadonlyArray<CalculatedPropertiesError>;
} {
  const transformedTree = formulaExpressionTree.transform((node) => {
    if (node.type === 'SymbolNode') {
      const existsReportDetailsValue = reportDetails
        ? (reportDetails[node.name as keyof ReportDetailsResponseModel] as
            | number
            | null)
        : undefined;

      const value = existsReportDetailsValue || 0;
      return new ConstantNode(value);
    }
    return node;
  });

  try {
    const compiled = transformedTree.compile();
    const result = round(compiled.evaluate(), 2);
    return {
      value: isFinite(result) ? result : null,
      errors: [],
    };
  } catch (error) {
    return {
      errors: [
        {
          error,
          details: {
            formula: formulaExpressionTree.toString(),
          },
        },
      ],
    };
  }
}

function calculateFormulaFromReportDetailsAndCalculatedProperties(
  reportDetails: Partial<ReportDetailsResponseModel> | undefined,
  calculatedProperties: CalculatedProperties,
  formulaExpressionTree: MathNode,
): {
  value?: CalculatedPropertyValue;
  errors: ReadonlyArray<CalculatedPropertiesError>;
} {
  const transformedTree = formulaExpressionTree.transform((node) => {
    if (node.type === 'SymbolNode') {
      const existsReportDetailsValue = reportDetails
        ? (reportDetails[node.name as keyof ReportDetailsResponseModel] as
            | number
            | null)
        : undefined;

      const existsCalculatedValue = node.name
        ? (calculatedProperties[node.name] as number | null)
        : undefined;

      const value = existsCalculatedValue || existsReportDetailsValue || 0;
      return new ConstantNode(value);
    }
    return node;
  });

  try {
    const compiled = transformedTree.compile();
    const result = round(compiled.evaluate(), 2);
    return {
      value: isFinite(result) ? result : null,
      errors: [],
    };
  } catch (error) {
    return {
      errors: [
        {
          error,
          details: {
            formula: formulaExpressionTree.toString(),
          },
        },
      ],
    };
  }
}
