import { InjectionToken } from '@angular/core';
import { JwtConfig } from './jwt-config.type';

export const SECURITY_CONFIG = new InjectionToken<SecurityConfig & JwtConfig>(
  'SECURITY_CONFIG',
);

export interface SecurityConfig {
  routes: {
    start: string;
    login: string;
    default: string;
  };
}
