import { Property } from '@bbraun/bav-reporting/ui-report-editor';
import { IS_GREATER_OR_EQUAL_NUMBER_ID } from '../../../../validation/factories/is-greater-or-equal-number-validator-factory';
import { IS_NUMBER_ID } from '../../../../validation/factories/is-number-validator-factory';

export function createDialysisFreqMeanProperty(): Property {
  return {
    title: 'Dialysis Frequency - Mean',
    technicalName: 'dialysisFreqMean',
    dataType: 'number',
    unit: 'per week',
    highlighted: false,
    subName: null,
    formula: null,
    validation: [
      { validator: IS_NUMBER_ID },
      {
        validator: IS_GREATER_OR_EQUAL_NUMBER_ID,
        options: {
          config: {
            minimum: 0,
          },
        },
      },
    ],
  };
}
