import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isDefined } from '@bbraun/shared/util-rxjs';
import { from, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OnNavigationEndSearchParamErrorService {
  readonly error$: Observable<string>;

  constructor(private readonly router: Router) {
    this.error$ = this.router.events
      .pipe(
        map((event) => {
          if (event instanceof NavigationEnd) {
            const currentUrl = new URL(window.location.href);
            const errorCodes = currentUrl.searchParams.getAll('error');
            currentUrl.searchParams.delete('error');

            history.replaceState(history.state, '', currentUrl.href);

            return errorCodes.length > 0
              ? {
                  state: history.state,
                  title: '',
                  url: window.location.pathname,
                  errorCodes,
                }
              : undefined;
          } else {
            return undefined;
          }
        }),
      )
      .pipe(isDefined())
      .pipe(concatMap(({ errorCodes }) => from(errorCodes)));
  }
}
