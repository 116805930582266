import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'bbraun-ui-hideable-container',
  templateUrl: './hideable-container.component.html',
  styleUrls: ['./hideable-container.component.scss'],
})
export class HideableContainerComponent {
  @Input()
  show = true;

  @HostBinding('class.hidden')
  get hidden() {
    return !this.show;
  }

  toggle() {
    this.show = !this.show;
  }
}
