import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { ERROR_CODE_LOOKUP } from '@bbraun/shared/util-error-ng';
import { TranslocoModule } from '@ngneat/transloco';
import { DxCheckBoxModule, DxNumberBoxModule } from 'devextreme-angular';
import { defer } from 'rxjs';
import fallbackTranslation from '../i18n/en.json';
import { ReportEditorComponent } from './components/report-editor/report-editor.component';
import { BavReportingUiReportEditorOnceDirective } from './directives/expanded-once.directive';
import { ReportEditorErrorLookupService } from './services/report-editor-error-lookup.service';
import { UpdateReportEditorFormDataHandlerStateFunctionFactory } from './components/report-editor/services/update-report-editor-form-data-handler-state-function.factory';
import { SCOPE } from './constants';

@NgModule({
  declarations: [
    ReportEditorComponent,
    BavReportingUiReportEditorOnceDirective,
  ],
  imports: [
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: ERROR_CODE_LOOKUP,
      multi: true,
      useExisting: ReportEditorErrorLookupService,
    },
    UpdateReportEditorFormDataHandlerStateFunctionFactory,
  ],
  exports: [ReportEditorComponent],
})
export class BavReportingUiReportEditorModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );
  }
}
