import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  IS_LOGIN_IN_PROGRESS_TOKEN,
  IS_LOGOUT_IN_PROGRESS_TOKEN,
} from '@bbraun/shared/data-access-security';
import { Subject } from 'rxjs';
import { IS_NAVIGATION_IN_PROGRESS_TOKEN } from '@bbraun/shared/util-navigation';
import { INTERRUPT_LOADING_SIGNAL } from './services/load-panel-visible.service';
import { LOAD_PANEL_VISIBLE_TRIGGERS } from './services/load-panel-visible.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: INTERRUPT_LOADING_SIGNAL, useValue: new Subject<boolean>() },
    {
      provide: LOAD_PANEL_VISIBLE_TRIGGERS,
      useExisting: IS_LOGIN_IN_PROGRESS_TOKEN,
      multi: true,
    },
    {
      provide: LOAD_PANEL_VISIBLE_TRIGGERS,
      useExisting: IS_LOGOUT_IN_PROGRESS_TOKEN,
      multi: true,
    },
    {
      provide: LOAD_PANEL_VISIBLE_TRIGGERS,
      useExisting: IS_NAVIGATION_IN_PROGRESS_TOKEN,
      multi: true,
    },
  ],
})
export class UiAppModule {}
