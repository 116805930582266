import { MatDialog } from '@angular/material/dialog';
import { CalculatedReportDetailsResponseModel } from '@bbraun/bav-reporting/data-access-ais-reports';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { blockingOperationHandler } from '@bbraun/shared/util-rxjs';
import { translate } from '@ngneat/transloco';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { ReportingService } from '../../services/reporting.service';
import { ReportInfo } from '../../types/types';
import { ConfirmUpdateCalculatedValuesDialogComponent } from '../confirm-update-calculated-values-dialog/confirm-update-calculated-values-dialog.component';

function allCalculatedValuesPropertiesNull(
  updatedCalculatedData: CalculatedReportDetailsResponseModel,
): boolean {
  const { id, ...nullableValues } = updatedCalculatedData;
  return Object.values(nullableValues).every((property) => property === null);
}

export function createUpdateCalculatedReportValuesHandler({
  messageService,
  triggerUpdateCalculatedReportValuesSubject,
  matDialog,
  reportingService,
}: {
  messageService: MessageService;
  triggerUpdateCalculatedReportValuesSubject: Observable<ReportInfo>;
  matDialog: MatDialog;
  reportingService: ReportingService;
}) {
  return blockingOperationHandler<CalculatedReportDetailsResponseModel | false>(
    {
      complete: (data) => {
        if (isUpdateCalculatedValuesDataValid(data || false)) {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingFeatureAisReports.updateCalculatedValuesBlockingOperationHandler.updateCalculatedValuesComplete.message',
              ),
            ),
            'success',
          );
        } else {
          messageService.message(
            translate(
              i18n(
                'bbraunBavReportingFeatureAisReports.updateCalculatedValuesBlockingOperationHandler.updateCalculatedValuesError.404.message',
              ),
            ),
            'warning',
          );
        }
      },
      error: (error) => {
        messageService.message(
          translate(
            i18n(
              'bbraunBavReportingFeatureAisReports.updateCalculatedValuesBlockingOperationHandler.updateCalculatedValuesError.general.message',
            ),
          ),
          'error',
          { error },
        );
      },
    },
    {
      strategy: 'single',
      autoConnectResult: false,
      source: createUpdateCalculatedReportValuesSource(
        triggerUpdateCalculatedReportValuesSubject,
        matDialog,
        reportingService,
      ),
    },
  );
}

function createUpdateCalculatedReportValuesSource(
  triggerUpdateCalculatedReportValuesSubject: Observable<ReportInfo>,
  matDialog: MatDialog,
  reportingService: ReportingService,
) {
  return triggerUpdateCalculatedReportValuesSubject.pipe(
    switchMap((reportInfo) =>
      matDialog
        .open(ConfirmUpdateCalculatedValuesDialogComponent, {
          hasBackdrop: true,
          panelClass: 'bbraun-dialog',
        })
        .afterClosed()
        .pipe(
          switchMap((hasConfirmed) => {
            if (hasConfirmed) {
              return of(() =>
                reportingService.getCalculatedReport(
                  reportInfo.center,
                  reportInfo.date,
                ),
              );
            } else {
              return EMPTY;
            }
          }),
        ),
    ),
  );
}

export function isUpdateCalculatedValuesDataValid(
  value: CalculatedReportDetailsResponseModel | false,
): value is CalculatedReportDetailsResponseModel {
  return !!(value && !allCalculatedValuesPropertiesNull(value));
}
