import { MessageService } from '@bbraun/shared/util-message-ng';
import { TranslocoService } from '@ngneat/transloco';
import { concat, defer, EMPTY, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LocaleService } from '../initializers/locale.service';
import { sendUnsupportedLocaleMessage } from './send-unsupported-locale-message';

export class LocaleObservableFactory {
  private readonly observable: Observable<string>;

  constructor(
    localeService: LocaleService,
    messageService: MessageService,
    translocoService: TranslocoService,
    helpers: {
      createImport: (lang: string) => Promise<unknown>;
      createFallback: (lang: string) => unknown;
      registerData: (data: unknown, lang: string) => void;
    },
  ) {
    let latestLang: string | undefined;

    this.observable = concat(
      latestLang ? of(latestLang) : EMPTY,
      localeService.getLocale$(),
    )
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((lang) =>
          defer(() => helpers.createImport(lang))
            .pipe(first())
            .pipe(
              catchError((error) => {
                sendUnsupportedLocaleMessage(
                  lang,
                  error,
                  messageService,
                  translocoService,
                );
                return of(helpers.createFallback(lang));
              }),
            )
            .pipe(tap((data) => helpers.registerData(data, lang)))
            .pipe(map(() => lang)),
        ),
      )
      .pipe(tap((lang) => (latestLang = lang)))
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  create() {
    return this.observable;
  }
}
