import { JsonApiHttpHeaders } from './http.type';

const API_VERSION_HEADER = 'Api-Version';
const API_ID_HEADER = 'Api-Id';

export interface JsonApiMeta {
  id?: string;
  version?: string;
}

export function setApiMetaHeaders(
  headers: JsonApiHttpHeaders,
  { id, version }: JsonApiMeta,
): JsonApiHttpHeaders {
  headers = version ? { ...headers, [API_VERSION_HEADER]: version } : headers;
  headers = id ? { ...headers, [API_ID_HEADER]: id } : headers;

  return headers;
}
