import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'bbraun-ui-form-submit-cancel-form',
  templateUrl: './submit-cancel-form.component.html',
  styleUrls: ['./submit-cancel-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitCancelFormComponent {
  @Input()
  formId?: string;

  @Input()
  bindEscapeKeyToForm = true;

  @Output()
  readonly submit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('form')
  form?: ElementRef<HTMLFormElement>;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  onSubmit(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.submit.next();
  }

  onCancel() {
    this.cancel.next();
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (this.form) {
      const isEscapeKey =
        !event.altKey &&
        !event.shiftKey &&
        !event.metaKey &&
        ['Escape', 'Esc'].includes(event.key);
      if (isEscapeKey) {
        const activeElement = this.document.activeElement || undefined;
        if (
          !this.bindEscapeKeyToForm ||
          (hasForm(activeElement) &&
            activeElement.form === this.form.nativeElement)
        ) {
          this.onCancel();
        }
      }
    }
  }
}

function hasForm<TElement extends Element>(
  element?: TElement & Readonly<{ form?: HTMLFormElement }>,
): element is TElement & Readonly<{ form: HTMLFormElement }> {
  return !!(element && element.form);
}
