import { HttpClient, HttpParams } from '@angular/common/http';
import {
  JsonApiHttpClient,
  JsonApiHttpHeaders,
  JsonApiHttpParams,
} from '@bbraun/shared/util-jsonapi';

export const HTTP_PARAMS_NO_ENCODING = {
  encoder: {
    decodeKey: (v: string) => v,
    encodeKey: (v: string) => v,
    decodeValue: (v: string) => v,
    encodeValue: (v: string) => v,
  },
};

function toHttpParams(params: JsonApiHttpParams): HttpParams {
  return new HttpParams(HTTP_PARAMS_NO_ENCODING).appendAll(params);
}

export function createHttpClientConnector(
  httpClient: HttpClient,
): JsonApiHttpClient {
  return {
    get(
      url: string,
      options?: {
        params?: JsonApiHttpParams;
        headers?: JsonApiHttpHeaders;
        responseType?: 'json';
      },
    ) {
      return httpClient.get(url, {
        ...options,
        params: options?.params && toHttpParams(options?.params),
      });
    },

    post<T>(
      url: string,
      body: unknown,
      options?: {
        params?: JsonApiHttpParams;
        headers?: JsonApiHttpHeaders;
        responseType?: 'json';
      },
    ) {
      return httpClient.post<T>(url, body, {
        ...options,
        params: options?.params && toHttpParams(options?.params),
      });
    },

    patch(
      url: string,
      body: unknown,
      options?: {
        params?: JsonApiHttpParams;
        headers?: JsonApiHttpHeaders;
        responseType?: 'json';
      },
    ) {
      return httpClient.patch(url, body, {
        ...options,
        params: options?.params && toHttpParams(options?.params),
      });
    },

    delete(
      url: string,
      options?: {
        params?: JsonApiHttpParams;
        headers?: JsonApiHttpHeaders;
        responseType?: 'json';
      },
    ) {
      return httpClient.delete(url, {
        ...options,
        params: options?.params && toHttpParams(options?.params),
      });
    },
  };
}
