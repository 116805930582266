import { normalizeCurrencyCode } from '../normalize-currency-code';
import { NumericValue } from '../numeric-value';

type MoneyData = Readonly<
  | { state: 'zero' }
  | { state: 'invalid'; error: string }
  | { state: 'value'; amount: number; currency: string }
>;

export const EXCHANGE_RATE_MISSING = 'exchange rate missing';
const CURRENCY_MISMATCH = 'currency mismatch';
const DIVIDED_BY_ZERO = 'divided by zero';

export class Money {
  static readonly zero = new Money({ state: 'zero' });

  private readonly data: MoneyData;

  static from({
    amount,
    currency,
  }: {
    amount: number;
    currency: string;
  }): Money {
    if (currency) {
      return new Money({
        state: 'value',
        amount,
        currency,
      });
    } else {
      throw new Error('A currency code is required.');
    }
  }

  get value(): number {
    if (this.data.state === 'zero') {
      return 0;
    } else if (this.data.state === 'value') {
      return this.data.amount;
    } else {
      throw new Error(this.data.error);
    }
  }

  get currency(): string | undefined {
    if (this.data.state === 'zero') {
      return undefined;
    } else if (this.data.state === 'value') {
      return this.data.currency;
    } else {
      throw new Error(this.data.error);
    }
  }

  get error() {
    return this.data.state === 'invalid' ? this.data.error : undefined;
  }

  private constructor(data: MoneyData) {
    this.data =
      data.state === 'value'
        ? { ...data, currency: normalizeCurrencyCode(data.currency) }
        : data;
  }

  add(money: Money): Money {
    if (this.data.state === 'invalid') {
      return this;
    } else if (money.data.state === 'invalid') {
      return money;
    } else if (money.data.state === 'zero' || money.data.amount === 0) {
      return this;
    } else if (this.data.state === 'zero' || this.data.amount === 0) {
      return money;
    } else if (this.data.currency === money.data.currency) {
      return new Money({
        state: 'value',
        amount: this.data.amount + money.data.amount,
        currency: normalizeCurrencyCode(this.data.currency),
      });
    } else {
      return new Money({
        state: 'invalid',
        error: CURRENCY_MISMATCH,
      });
    }
  }

  subtract(money: Money): Money {
    if (this.data.state === 'invalid') {
      return this;
    } else if (money.data.state === 'invalid') {
      return money;
    } else if (money.data.state === 'zero' || money.data.amount === 0) {
      return this;
    } else if (
      this.data.state === 'zero' ||
      (this.data.amount === 0 && money.data.amount !== 0)
    ) {
      return new Money({
        state: 'value',
        amount: -money.data.amount,
        currency: money.data.currency,
      });
    } else if (money.data.currency === this.data.currency) {
      return new Money({
        state: 'value',
        amount: this.data.amount - money.data.amount,
        currency: this.data.currency,
      });
    } else {
      return new Money({
        state: 'invalid',
        error: CURRENCY_MISMATCH,
      });
    }
  }

  multiplyBy(value: number): Money {
    if (this.data.state === 'invalid') {
      return this;
    } else if (
      this.data.state === 'zero' ||
      (this.data.state === 'value' && this.data.amount === 0)
    ) {
      return this;
    } else {
      return new Money({
        state: 'value',
        amount: this.data.amount * value,
        currency: this.data.currency,
      });
    }
  }

  divideBy(value: number): Money {
    if (this.data.state === 'invalid') {
      return this;
    } else if (value === 0) {
      return new Money({ state: 'invalid', error: DIVIDED_BY_ZERO });
    } else if (this.data.state === 'zero' || this.data.amount === 0) {
      return this;
    } else {
      return new Money({
        state: 'value',
        amount: this.data.amount / value,
        currency: this.data.currency,
      });
    }
  }

  divideByMoney(money: Money): NumericValue {
    if (this.data.state === 'invalid') {
      return NumericValue.invalid(this.data.error);
    } else if (money.data.state === 'invalid') {
      return NumericValue.invalid(money.data.error);
    } else if (money.data.state === 'zero' || money.data.amount === 0) {
      return NumericValue.invalid(DIVIDED_BY_ZERO);
    } else if (this.data.state === 'zero' || this.data.amount === 0) {
      return NumericValue.from(0);
    } else if (money.data.currency === this.data.currency) {
      return NumericValue.from(this.data.amount / money.data.amount);
    } else {
      return NumericValue.invalid(CURRENCY_MISMATCH);
    }
  }

  abs(): Money {
    if (this.data.state === 'invalid') {
      return this;
    } else if (this.data.state === 'zero' || this.data.amount >= 0) {
      return this;
    } else {
      return new Money({
        state: 'value',
        amount: Math.abs(this.data.amount),
        currency: this.data.currency,
      });
    }
  }
  convert(toCurrency: string, exchangeRateLookup: ExchangeRateLookup): Money;
  convert<TError>(
    toCurrency: string,
    exchangeRateLookup: ExchangeRateLookup,
    errorValue: TError,
  ): Money | TError;
  convert<TError>(
    ...rest: [string, ExchangeRateLookup] | [string, ExchangeRateLookup, TError]
  ): Money | TError {
    const { exchangeRateLookup, toCurrency, errorMode } =
      rest.length === 2
        ? ({
            toCurrency: rest[0],
            exchangeRateLookup: rest[1],
            errorMode: { isErrorValue: false },
          } as const)
        : ({
            toCurrency: rest[0],
            exchangeRateLookup: rest[1],
            errorMode: { isErrorValue: true, errorValue: rest[2] },
          } as const);
    if (this.data.state === 'invalid') {
      return this;
    } else if (this.data.state === 'zero' || this.data.amount === 0) {
      return new Money({ state: 'value', amount: 0, currency: toCurrency });
    } else {
      const exchangeRate = exchangeRateLookup(
        this.data.currency,
        normalizeCurrencyCode(toCurrency),
      );
      if (exchangeRate.found) {
        return new Money({
          state: 'value',
          amount: this.data.amount * exchangeRate.value,
          currency: toCurrency,
        });
      } else if (errorMode.isErrorValue) {
        return errorMode.errorValue;
      } else {
        return new Money({ state: 'invalid', error: EXCHANGE_RATE_MISSING });
      }
    }
  }

  /**
   *
   * @param zeroDefaultCurrency The currency to use in case of a zero money object without a specific currency.
   * @param errorValue Optional error value. This value will be returned in case of an invalid money object.
   * @returns Amount and currency in an object.
   */
  toObject(
    zeroDefaultCurrency: string,
  ):
    | { isValid: true; amount: number; currency: string }
    | { isValid: false; error: string };
  toObject<TErrorValue>(
    zeroDefaultCurrency: string,
    errorValue: TErrorValue,
  ): { amount: number; currency: string } | TErrorValue;
  toObject<TErrorValue>(
    ...rest: [string] | [string, TErrorValue]
  ):
    | { isValid: true; amount: number; currency: string }
    | { isValid: false; error: string }
    | { amount: number; currency: string }
    | TErrorValue {
    const zeroDefaultCurrency = normalizeCurrencyCode(rest[0]);

    if (rest.length === 1) {
      if (this.data.state === 'invalid') {
        return { isValid: false, error: this.data.error };
      } else if (this.data.state === 'value') {
        return {
          isValid: true,
          amount: this.data.amount,
          currency: this.data.currency,
        };
      } else {
        return { isValid: true, amount: 0, currency: zeroDefaultCurrency };
      }
    } else {
      if (this.data.state === 'invalid') {
        return rest[1];
      } else if (this.data.state === 'value') {
        return { amount: this.data.amount, currency: this.data.currency };
      } else {
        return { amount: 0, currency: zeroDefaultCurrency };
      }
    }
  }

  isValid(): boolean {
    return this.data.state !== 'invalid';
  }

  isZeroAmount(): boolean {
    return (
      this.data.state === 'zero' ||
      (this.data.state === 'value' && this.data.amount === 0)
    );
  }

  isNegativeAmount() {
    return this.data.state === 'value' && this.data.amount < 0;
  }

  isPositiveAmount() {
    return this.data.state === 'value' && this.data.amount > 0;
  }

  isCurrency(currency: string) {
    return (
      this.data.state === 'zero' ||
      (this.data.state === 'value' &&
        (this.data.amount === 0 ||
          this.data.currency === normalizeCurrencyCode(currency)))
    );
  }
}

export interface ExchangeRateLookup {
  (toCurrency: string): { found: false } | { found: true; value: number };
  (fromCurrency: string, toCurrency: string):
    | { found: false }
    | { found: true; value: number };
}

export function isMoney(value: unknown): value is Money {
  return value instanceof Money;
}

export class MissingExchangeRateError extends Error {
  constructor(fromCurrency?: string, toCurrency?: string, context?: string) {
    super(
      `Exchange Rate for converting ${
        fromCurrency ? 'from ' + fromCurrency : ''
      } ${toCurrency ? 'to ' + toCurrency : ''} is missing, but is required ${
        context ? 'for ' + context : ''
      }`,
    );
  }
}
