import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { SecurityConfig, SECURITY_CONFIG } from '../interfaces/security-config';
import { LoginWithPrincipalService } from '../services/login-with-principal.service';
import { IsWhitelistedRouteService } from './is-whitelisted-route.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivateChild {
  private readonly loginRoute: UrlTree;

  constructor(
    private readonly isWhitelistedRouteService: IsWhitelistedRouteService,
    private readonly principalService: LoginWithPrincipalService<unknown>,
    private readonly router: Router,
    @Inject(SECURITY_CONFIG)
    config: {
      routes: Pick<SecurityConfig['routes'], 'login'>;
    },
  ) {
    const { login } = config.routes;
    this.loginRoute = router.parseUrl(login);
  }

  canActivateChild(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;

    return (
      this.isWhitelistedRouteService.isWhitelistedRoute(url) ||
      this.principalService.isPrincipalLoggedIn$
        .pipe(first())
        .pipe(
          tap((isLoggedIn) => {
            // see: https://github.com/angular/angular/issues/27148#issuecomment-537975077
            if (!isLoggedIn) {
              console.log(
                `Route ${url} is not whitelisted, redirecting to login ${this.loginRoute}`,
              );
              this.router.navigateByUrl(this.loginRoute, {
                state: { finalUrl: url },
              });
            }
          }),
        )
        .pipe(
          catchError((error) => {
            console.log(
              `Route ${url} failed, redirecting to login ${this.loginRoute}`,
              error,
            );
            return of(this.loginRoute);
          }),
        )
    );
  }
}
