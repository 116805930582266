import { Inject, NgModule } from '@angular/core';
import {
  TranslationLoaderRegistry,
  TRANSLATION_LOADER_REGISTRY,
} from '@bbraun/shared/data-access-locale';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UiStartViewModule } from '@bbraun/shared/ui-start-view';
import { TranslocoModule } from '@ngneat/transloco';
import { defer } from 'rxjs';
import { UiLanguageModule } from '@bbraun/shared/ui-language';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import fallbackTranslation from '../i18n/en.json';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { StartComponent } from './components/start/start.component';
import { ErrorComponent } from './components/error/error.component';
import { StartDataTransferButtonComponent } from './components/start-data-transfer-button/start-data-transfer-button.component';

const SCOPE = 'bbraunBavReportingUiCommon';

@NgModule({
  imports: [
    UiStartViewModule,
    TranslocoModule,
    UiLanguageModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    StartComponent,
    ConfirmDialogComponent,
    ErrorComponent,
    StartDataTransferButtonComponent,
  ],
  exports: [StartComponent, ErrorComponent, StartDataTransferButtonComponent],
  providers: [],
})
export class BavReportingUiCommonModule {
  constructor(
    @Inject(TRANSLATION_LOADER_REGISTRY)
    translationLoaderRegistry: TranslationLoaderRegistry,
    sanitizer: DomSanitizer,
    matIconRegistry: MatIconRegistry,
  ) {
    translationLoaderRegistry.addTranslationLoader(
      (lang: string) =>
        defer(() => import(`../i18n/${lang}.json`).then((v) => v.default)),
      fallbackTranslation,
      SCOPE,
    );

    matIconRegistry.addSvgIconInNamespace(
      'bbraun',
      'invalid',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/invalid-icon_v2022-12-12.svg',
      ),
    );
  }
}
