import { isMoney } from '../money/money';
import { hasPropertyOfType } from '../type-guards/has-property-of-type';
import { isOneTypeOf } from '../type-guards/is-one-type-of';
import {
  isNull,
  isNumber,
  isString,
  isUndefined,
  isUnknown,
} from '../type-guards/type-guards';

export const isNumberOrNullOrUndefined = isOneTypeOf(
  isNull,
  isUndefined,
  isNumber,
);

export const isStringOrNullOrUndefined = isOneTypeOf(
  isNull,
  isUndefined,
  isString,
);

export function isValueObjectOrNullOrUndefined<T>(
  valueType: (value: unknown) => value is T,
) {
  return isOneTypeOf(
    isNull,
    isUndefined,
    hasPropertyOfType('value', valueType || isUnknown),
  );
}

export function hasNumberProperty<TPropertyName extends string>(
  propertyName: TPropertyName,
) {
  return hasPropertyOfType(propertyName, isNumber);
}

export function hasMoneyProperty<TPropertyName extends string>(
  propertyName: TPropertyName,
) {
  return hasPropertyOfType(propertyName, isMoney);
}
