import { MonoTypeOperatorFunction } from 'rxjs';
import {
  SubscriptionHandlers,
  withSubscriptionHandlers,
} from '../observable/with-subscription-handlers';

export function onSubscription<T>(
  handlers: SubscriptionHandlers<T> = {},
): MonoTypeOperatorFunction<T> {
  return (o) => withSubscriptionHandlers(o, handlers);
}
