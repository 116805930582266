<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingUiCommon';
    read: 'bbraunBavReportingUiCommon.startComponent'
  "
>
  <bbraun-ui-start-view>
    <p>
      {{ translate('description.text') }}
    </p>
  </bbraun-ui-start-view>
</ng-container>
