export function fromPairs<TValue, TKey extends string | number | symbol>(
  pairs: ReadonlyArray<readonly [TKey, TValue]>,
): Record<TKey, TValue>;
export function fromPairs<
  TValue,
  TKey extends string | number | symbol,
  TDefault,
>(
  pairs: ReadonlyArray<readonly [TKey, TValue]>,
  defaultValue: TDefault,
): Record<TKey, TValue> | TDefault;
export function fromPairs<
  TValue,
  TKey extends string | number | symbol,
  TDefault,
>(
  ...args:
    | [ReadonlyArray<readonly [TKey, TValue]>]
    | [ReadonlyArray<readonly [TKey, TValue]>, TDefault]
): Record<TKey, TValue> | TDefault {
  const initial: Partial<Record<TKey, TValue>> = {};
  const [pairs, defaultValue] =
    args.length > 1
      ? [args[0], args[1] as TDefault]
      : [args[0], {} as TDefault];

  return pairs.length > 0
    ? (pairs.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, initial) as Record<TKey, TValue>)
    : defaultValue;
}
