export type UpdatePropertiesImmutableFunctions<
  T,
  TKey extends keyof T = keyof T,
> = Partial<{
  readonly [key in TKey]: (value: T[key]) => T[key];
}>;

export function updatePropertyImmutable<
  T,
  TKey extends keyof Exclude<T, undefined>,
>(
  value: T,
  property: TKey,
  update: (value: Exclude<T, undefined>[TKey]) => Exclude<T, undefined>[TKey],
): T {
  return updatePropertiesImmutable(value, {
    [property]: update,
  } as unknown as UpdatePropertiesImmutableFunctions<Exclude<T, undefined>, TKey>);
}

export function updatePropertiesImmutable<
  T,
  TKey extends keyof Exclude<T, undefined>,
>(
  value: T,
  updates: UpdatePropertiesImmutableFunctions<Exclude<T, undefined>, TKey>,
): T {
  if (value !== null && value !== undefined) {
    const updateEntries = Object.entries(updates) as unknown as ReadonlyArray<
      [keyof T, (key: T[keyof T]) => T[keyof T]]
    >;
    return updateEntries.reduce((acc: T, [key, update]) => {
      const currentPropertyValue = value[key];
      const updatedPropertyValue = update(currentPropertyValue);
      if (updatedPropertyValue !== currentPropertyValue) {
        acc = acc !== value ? acc : { ...value };
        acc[key] = updatedPropertyValue;
      }

      return acc;
    }, value);
  } else {
    return value;
  }
}
