import { extractSearchParams } from '@bbraun/shared/util-url';
import { JsonApiHttpParams } from './http.type';

export function appendFilterToHttpParams(
  resourceUrl: string,
  httpParams: JsonApiHttpParams,
): { rawResourceUrl: string; httpParams: JsonApiHttpParams } {
  const resourceUrlAsUrl = new URL(resourceUrl);

  const resourceUrlFilterArray = extractSearchParams(resourceUrlAsUrl.search)
    .filter(([name]) => name === 'filter')
    .map(([_, value]) => value)
    .map((value) => value.split(';'))
    .flat();

  resourceUrlAsUrl.searchParams.delete('filter');
  const rawResourceUrl = resourceUrlAsUrl.href;

  let filterHttpParams = httpParams.filter;

  if (resourceUrlFilterArray && resourceUrlFilterArray.length > 0) {
    const extraFilterArrayParams = resourceUrlFilterArray
      .map((v) => `(${v})`)
      .join(';');

    filterHttpParams = filterHttpParams
      ? `${extraFilterArrayParams};(${filterHttpParams})`
      : extraFilterArrayParams;
  }

  return {
    rawResourceUrl,
    httpParams: filterHttpParams
      ? {
          ...httpParams,
          filter: filterHttpParams,
        }
      : httpParams,
  };
}
