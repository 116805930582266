import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { MessageService } from '@bbraun/shared/util-message-ng';
import { isDefined } from '@bbraun/shared/util-rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LocaleFacade } from '../+state/locale.facade';
import {
  SUPPORTED_LOCALES,
  TRANSLATION_SERVICE_CONNECTOR,
} from '../injection-tokens';
import { sendUnsupportedLocaleMessage } from '../services/send-unsupported-locale-message';
import {
  APP_STATE_PERSISTENCE,
  StatePersistenceStrategy,
} from '../state-persistence/state-persistence-strategy';
import { TranslationServiceConnector } from '../translation-service-connector.type';
import { loadIntlDateTimeFormatLocaleData } from './load-intl-datetimeformat-locale-data';
import { loadIntlPolyfills } from './load-intl-polyfills';

export const APP_DEFAULT_LOCALE = new InjectionToken<string>(
  'APP_DEFAULT_LOCALE',
);

@Injectable({ providedIn: 'root' })
export class LocaleService {
  private static STORAGE_KEY = 'bbraun.app.lastLocale';
  private readonly locale$: Observable<string>;

  constructor(
    private readonly localeFacade: LocaleFacade,
    @Inject(TRANSLATION_SERVICE_CONNECTOR)
    private readonly translateService: TranslationServiceConnector,
    @Inject(APP_DEFAULT_LOCALE)
    private readonly defaultLocale: string,
    @Inject(SUPPORTED_LOCALES)
    private readonly supportedLocales: string[],
    @Inject(APP_STATE_PERSISTENCE)
    private readonly statePersistence: StatePersistenceStrategy,
    private readonly messageService: MessageService,
    private readonly injector: Injector,
  ) {
    this.locale$ = loadIntlPolyfills(messageService)
      .pipe(shareReplay({ bufferSize: 1, refCount: false }))
      .pipe(switchMap(() => this.localeFacade.getLocale$()))
      .pipe(isDefined())
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((v) =>
          of(v).pipe(
            loadIntlDateTimeFormatLocaleData(
              messageService,
              this.translocoService,
            ),
          ),
        ),
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  private get translocoService(): TranslocoService {
    return this.injector.get(TranslocoService);
  }

  switchLocale(locale: string, auto?: boolean, preferredInBrowser?: boolean) {
    this.localeFacade.switchLocale(locale, auto, preferredInBrowser);
    if (locale && this.isSupportedLocale(locale)) {
      try {
        this.translateService.use(locale);
        if (!auto) {
          this.statePersistence.store(LocaleService.STORAGE_KEY, locale);
        }
      } catch (error) {
        sendUnsupportedLocaleMessage(
          locale,
          error,
          this.messageService,
          this.translocoService,
        );
      }
    } else {
      this.translateService.use(this.defaultLocale);
      this.statePersistence.store(LocaleService.STORAGE_KEY);
    }
  }

  switchLocaleToDefault() {
    this.switchLocale(this.defaultLocale);
  }

  public getLocale$(): Observable<string> {
    return this.locale$;
  }

  restoreLastLocale(): Promise<any> {
    const localeFromUrl = new URLSearchParams(window.location.search).get(
      'locale',
    );

    return firstValueFrom(
      (localeFromUrl
        ? of(localeFromUrl)
        : this.statePersistence.get<string>(LocaleService.STORAGE_KEY)
      ).pipe(
        map((lastLocale) => {
          if (lastLocale && this.isSupportedLocale(lastLocale)) {
            return { locale: lastLocale };
          } else {
            const browserLocale = this.translateService.getBrowserLang();
            if (browserLocale && this.isSupportedLocale(browserLocale)) {
              return {
                locale: browserLocale,
                auto: true,
                preferredInBrowser: true,
              };
            } else {
              return {
                locale: this.defaultLocale,
                auto: true,
                preferredInBrowser: true,
              };
            }
          }
        }),
        tap(({ locale, auto, preferredInBrowser }) => {
          this.localeFacade.switchLocale(locale, auto, preferredInBrowser);
          this.translateService.use(locale);
        }),
      ),
    );
  }

  getSupportedLocales(): string[] {
    return this.supportedLocales;
  }

  isSupportedLocale(locale: string): boolean {
    return this.getSupportedLocales().indexOf(locale) !== -1;
  }
}
