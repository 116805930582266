import { HttpErrorResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ErrorObject } from '@bbraun/shared/util-error';
import {
  IndexResult,
  ApiDefinition,
  ApiTypes,
  getResourceLinks,
} from '@bbraun/shared/util-jsonapi';
import { Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

export interface TypedJsonApiServiceResourceGuardOptions {
  onActivationNotAllowed: () => void;
  onError: (error: ErrorObject & { error?: unknown }) => void;
}

export const TYPED_JSON_API_SERVICE_RESOURCE_GUARD_OPTIONS =
  new InjectionToken<TypedJsonApiServiceResourceGuardOptions>(
    'TypedJsonApiServiceResourceGuard.TYPED_JSON_API_SERVICE_RESOURCE_GUARD_OPTIONS',
  );

export const TYPED_JSON_API_SERVICE_RESOURCE_GUARD_ERROR_CODE = Symbol(
  'TYPED_JSON_API_SERVICE_RESOURCE_GUARD_ERROR',
);

export class TypedJsonApiServiceResourceGuard<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TResource extends keyof TApiDefinition['index']['meta']['resources'],
> implements CanActivate
{
  readonly canActivate$: Observable<boolean>;

  constructor(
    private readonly apiService: {
      index: () => Observable<IndexResult<TApiDefinition> | undefined>;
    },
    private readonly resource: keyof TApiDefinition['index']['meta']['resources'],
    private readonly options:
      | TypedJsonApiServiceResourceGuardOptions
      | undefined
      | null,
    action: keyof TApiDefinition['index']['meta']['resources'][TResource]['links'] = 'list',
    preCondition$: Observable<boolean> = of(true),
  ) {
    this.canActivate$ = preCondition$
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((isPrecondition) =>
          isPrecondition
            ? this.apiService
                .index()
                .pipe(
                  map(
                    (result) =>
                      !!getResourceLinks(this.resource, result)[action],
                  ),
                )
                .pipe(
                  catchError((error: unknown) => {
                    try {
                      if (
                        options &&
                        options.onError &&
                        (!(error instanceof HttpErrorResponse) ||
                          !error.status ||
                          error.status >= 500)
                      ) {
                        options.onError({
                          code: TYPED_JSON_API_SERVICE_RESOURCE_GUARD_ERROR_CODE,
                          error,
                        });
                      }
                      return of(false);
                    } catch {
                      return of(false);
                    }
                  }),
                )
            : of(false),
        ),
      )
      .pipe(distinctUntilChanged());
  }

  canActivate() {
    return this.canActivate$.pipe(
      tap({
        next: (canActivate) => {
          if (!canActivate && this.options) {
            this.options.onActivationNotAllowed();
          }
        },
      }),
    );
  }
}
