<ng-container
  *transloco="
    let translate;
    scope: 'bbraunBavReportingUiCommon';
    read: 'bbraunBavReportingUiCommon.confirmDialogComponent'
  "
>
  <ng-container *ngIf="confirmData; else saveDialog">
    <div mat-dialog-content>
      <p>{{ translate('createDialog.text') }}</p>
      <p>
        {{ translate('center.text') }} <b>{{ confirmData?.center }}</b>
      </p>
      <p>
        {{ translate('month.text') }}
        <b>
          {{ confirmData.date.year }} -
          {{ confirmData.date.month | bbraunUiLanguageLocalizedNumber: '2.0' }}
        </b>
      </p>
    </div>
  </ng-container>

  <ng-template #saveDialog>
    <div mat-dialog-content>
      <p>{{ translate('saveDialog.text') }}</p>
    </div>
  </ng-template>

  <p class="text-right mb-0">
    <button mat-raised-button color="accent" (click)="onConfirm()">
      {{ translate('confirm.text') }}
    </button>
    <button
      type="button"
      mat-raised-button
      color="warn"
      class="ml-1"
      (click)="cancel()"
    >
      {{ translate('return.text') }}
    </button>
  </p>
</ng-container>
