import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InfoBoxItem } from '../../types';
import { SanitizedInfoBoxItem } from './../../types';

interface PositionedInfoBoxItem extends SanitizedInfoBoxItem {
  column: number;
}

@Component({
  selector: 'bbraun-ui-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxComponent implements OnChanges {
  @Input()
  infoBoxItems: InfoBoxItem[] = [];

  @Output()
  closeInfoBoxClick = new EventEmitter<Boolean>();

  private positionedInfoBoxItems: PositionedInfoBoxItem[] = [];

  constructor(private readonly domSanitizer: DomSanitizer) {}

  ngOnChanges(changes: { infoBoxItems: SimpleChange }) {
    if (changes.infoBoxItems) {
      if (this.infoBoxItems.length >= 2) {
        this.positionedInfoBoxItems = mapInfoBoxItemsToColumns(
          this.domSanitizer,
          this.infoBoxItems,
        );
      } else {
        this.positionedInfoBoxItems = [];
      }
    }
  }

  closeInfoBox() {
    this.closeInfoBoxClick.emit(true);
  }

  getInfoBoxItemsForColumn(
    columnIndex: number,
  ): PositionedInfoBoxItem[] | undefined {
    return this.positionedInfoBoxItems.filter((i) => i.column === columnIndex);
  }
}

function mapInfoBoxItemsToColumns(
  sanitizer: DomSanitizer,
  items: InfoBoxItem[],
): PositionedInfoBoxItem[] {
  return items.map<PositionedInfoBoxItem>((item: InfoBoxItem, index) => {
    const isInFirstColumn = index < Math.round(items.length / 2);
    return {
      title: item.title,
      text:
        item.type === 'html'
          ? sanitizer.bypassSecurityTrustHtml(item.text)
          : item.text,
      column: isInFirstColumn ? 0 : 1,
    };
  });
}
