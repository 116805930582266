import { InjectionToken } from '@angular/core';
import { TypedJsonApiService } from '@bbraun/shared/util-jsonapi';
import { ExpertLoaderJsonApi } from './expert-loader-json-api.type';

export type ExpertLoaderApiService = TypedJsonApiService<ExpertLoaderJsonApi>;

export const EXPERT_LOADER_API_SERVICE =
  new InjectionToken<ExpertLoaderApiService>('EXPERT_LOADER_API_SERVICE');

export const EXPERT_LOADER_API_BASE_URL = new InjectionToken<
  string | undefined
>('EXPERT_LOADER_API_BASE_URL');
