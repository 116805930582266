import {
  TranslocoMessageService,
  TranslocoMessageServiceFactory,
} from '@bbraun/shared/util-transloco-message';

export const scope = 'bbraunSharedUtilErrorNg';

export function createMessageService(
  factory: TranslocoMessageServiceFactory,
): TranslocoMessageService {
  return factory.createForScope(scope);
}
