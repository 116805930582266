import { Injectable } from '@angular/core';
import {
  ErrorCodeLookup,
  ErrorCodeLookupResult,
  ErrorObject,
} from '@bbraun/shared/util-error';
import { marker as i18n } from '@ngneat/transloco-keys-manager/marker';

export const CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES = {
  calculateProperties: 'CALCULATE_PROPERTIES',
  cyclicDependencyInCalculatedProperties:
    'CYCLIC_DEPENDENCY_IN_CALCULATED_PROPERTIES',
};

@Injectable({ providedIn: 'root' })
export class ReportEditorErrorLookupService implements ErrorCodeLookup {
  private readonly lookup: {
    [code: string]: (error: ErrorObject) => ErrorCodeLookupResult;
  };

  constructor() {
    const lookUpEntries: ReadonlyArray<{
      code: string;
      messageId?: symbol;
      fn: (error: ErrorObject) => ErrorCodeLookupResult;
    }> = [
      {
        code: CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES.calculateProperties,
        fn: (error: ErrorObject) => ({
          error,
          message: i18n(
            'bbraunBavReportingUiReportEditor.reportEditorErrorLookupService.calculateProperties.error.message',
          ),
        }),
      },
      {
        code: CALCULATE_FORM_DATA_HANDLER_STATE_ERROR_CODES.cyclicDependencyInCalculatedProperties,
        fn: (error: ErrorObject) => ({
          error,
          message: i18n(
            'bbraunBavReportingUiReportEditor.reportEditorErrorLookupService.cyclicDependencyInCalculatedProperties.error.message',
          ),
        }),
      },
    ];

    this.lookup = lookUpEntries.reduce((acc, { code, messageId, fn }) => {
      if (!acc[code]) {
        acc[code] = (...args) => ({
          messageId,
          ...fn(...args),
        });
        return acc;
      } else {
        throw new Error(
          `Failed to create error code lookup. Duplicate entry for error code <${code}> found.`,
        );
      }
    }, {} as { [code: string]: (error: ErrorObject) => ErrorCodeLookupResult });
  }

  get(code: string) {
    return this.lookup[code];
  }
}
