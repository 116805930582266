import { compareAsc, parseISO } from 'date-fns';
const invalidDate = new Date(NaN);

export const sortComparators = {
  string: (
    a: string | null | undefined,
    b: string | null | undefined,
  ): number => (a || '').localeCompare(b || ''),
  number: (
    a: number | null | undefined,
    b: number | null | undefined,
  ): number => (a || 0) - (b || 0),
  date: (a: string | null | undefined, b: string | null | undefined): number =>
    compareAsc(a ? parseISO(a) : invalidDate, b ? parseISO(b) : invalidDate),
  boolean: (
    a: boolean | null | undefined,
    b: boolean | null | undefined,
  ): number =>
    a === false && b === true
      ? -1
      : b === true && a === false
      ? 1
      : a === b
      ? 0
      : !a
      ? 1
      : -1,
};

export function localizedStringSortComparator(collator: Intl.Collator) {
  return {
    string: (
      a: string | null | undefined,
      b: string | null | undefined,
    ): number => collator.compare(a || '', b || ''),
  };
}
