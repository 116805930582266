import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LANGUAGE_SELECTION_DISABLED } from '@bbraun/shared/data-access-locale';
import {
  LoginWithPrincipalService,
  LOGIN_SERVICE_TOKEN,
} from '@bbraun/shared/data-access-security';
import {
  INTERRUPT_LOADING_SIGNAL,
  LoadPanelVisibleService,
  withHideBackButtonMixin,
  withLoginMixin,
  WITH_LOGIN_ON_DESTROY,
  WITH_LOGIN_ON_INIT,
} from '@bbraun/shared/ui-app';
import { SidebarComponent } from '@bbraun/shared/ui-sidebar';
import { MixinBuilder } from '@bbraun/shared/util-lang';
import {
  GlobalErrorHandler,
  OnNavigationEndSearchParamErrorHandlerService,
  OnNavigationErrorHandlerService,
} from '@bbraun/shared/util-error-ng';
import {
  InAppNavigationRoutingService,
  IsNavigationInProgressService,
  LoadBarService,
} from '@bbraun/shared/util-navigation';
import { or } from '@bbraun/shared/util-rxjs';
import {
  distinctUntilChanged,
  Observable,
  shareReplay,
  Subject,
  Subscription,
} from 'rxjs';
import config from 'devextreme/core/config';
import {
  CanReadAisReportsGuard,
  CanReadUserLogsGuard,
} from '@bbraun/bav-reporting/data-access-ais-reports';
import { CanReadLabTestMappingGuard } from '@bbraun/bav-reporting/data-access-administration';
import { DEFAULT_ROUTE } from './injection-token';

interface Principal {
  name: string;
}

class AppComponentBase {
  constructor(
    readonly loginService: LoginWithPrincipalService<Principal>,
    readonly router: Router,
    readonly errorHandler: GlobalErrorHandler,
    readonly isNavigationInProgressService: IsNavigationInProgressService,
    readonly interruptLoadingSignal$: Subject<boolean>,
  ) {}
}

const appComponentBase = new MixinBuilder(AppComponentBase)
  .addMixin((base) => withLoginMixin<typeof base, Principal>(base))
  .addMixin((base) =>
    withHideBackButtonMixin(base, ['/app/reports', '/app/start']),
  ).result;

@Component({
  selector: 'bav-reporting-desktop-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent
  extends appComponentBase
  implements OnInit, OnDestroy
{
  @ViewChild(SidebarComponent)
  sidebar?: SidebarComponent;
  isSidebarOpen = false;

  isRouterOutletActivated = false;

  readonly canAccessAdministration$: Observable<boolean>;

  readonly isLoadBarActive$: Observable<boolean>;
  readonly isLoadPanelVisible$: Observable<boolean>;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    @Inject(LOGIN_SERVICE_TOKEN)
    loginService: LoginWithPrincipalService<Principal>,
    router: Router,
    @Inject(DEFAULT_ROUTE) public defaultRoute: string,
    isNavigationInProgressService: IsNavigationInProgressService,
    @Inject(INTERRUPT_LOADING_SIGNAL) interruptLoadingSignal$: Subject<boolean>,
    private readonly inAppNavigationRoutingService: InAppNavigationRoutingService,
    private readonly onNavigationEndSearchParamErrorHandlerService: OnNavigationEndSearchParamErrorHandlerService,
    private readonly onNavigationErrorHandlerService: OnNavigationErrorHandlerService,
    @Inject(LANGUAGE_SELECTION_DISABLED)
    public readonly languageSelectionDisabled: boolean,
    public readonly canReadAisReportsGuard: CanReadAisReportsGuard,
    public readonly canReadLabTestMappingGuard: CanReadLabTestMappingGuard,
    public readonly canReadUserLogsGuard: CanReadUserLogsGuard,
    errorHandler: GlobalErrorHandler,
    loadBarService: LoadBarService,
    loadPanelVisibleService: LoadPanelVisibleService,
  ) {
    super(
      loginService,
      router,
      errorHandler,
      isNavigationInProgressService,
      interruptLoadingSignal$,
    );

    this.isLoadBarActive$ = loadBarService.isLoading$;

    config({
      editorStylingMode: 'filled',
    });

    this.isLoadPanelVisible$ = loadPanelVisibleService.isLoadPanelVisible$
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.canAccessAdministration$ = or([
      this.canReadLabTestMappingGuard.canActivate$,
      this.canReadUserLogsGuard.canActivate$,
    ])
      .pipe(distinctUntilChanged())
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  ngOnInit() {
    const loadingContainer = document.querySelector('.bbraun-loading');
    if (loadingContainer) {
      loadingContainer.remove();
    }

    this.subscription.add(
      this.inAppNavigationRoutingService.connect$.subscribe(),
    );

    this.subscription.add(
      this.onNavigationEndSearchParamErrorHandlerService.connect$.subscribe(),
    );

    this.subscription.add(
      this.onNavigationErrorHandlerService.connect$.subscribe(),
    );

    this[WITH_LOGIN_ON_INIT]();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    this[WITH_LOGIN_ON_DESTROY]();
  }

  activate() {
    this.isRouterOutletActivated = true;
  }

  deactivate() {
    this.isRouterOutletActivated = false;
  }

  toggleSidebar() {
    if (this.sidebar) {
      this.sidebar.toggle();
    }
  }

  closeSidebar() {
    if (this.sidebar) {
      this.sidebar.close();
    }
  }

  sidebarToggled(event: MatDrawerToggleResult) {
    this.isSidebarOpen = event === 'open';
  }
}
