import { TypedFiqlQuery } from '@bbraun/shared/util-fiql';
import {
  PropertyPathBuilder,
  SortSpecsBuilder,
  splitArrayByLength,
} from '@bbraun/shared/util-lang';
import { reduceChunked } from '@bbraun/shared/util-rxjs';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { FiqlQuery } from '@bbraun/shared/util-fiql';
import {
  isJsonApiErrorResponseBody,
  JsonApiDataObjectCollectionResponseBody,
  JsonApiDataObjectResponseBody,
  JsonApiErrorResponseBody,
} from '../json-api.types';
import { JsonApiAdapter2 } from '../json-api-adapter2';
import {
  mapDocumentToJsonApiCollectionResponseUnsafe,
  mapToJsonApiCollectionResponseUnsafe,
} from '../rxjs/map-to-json-api-collection-response-unsafe';
import {
  mapDocumentToJsonApiObjectResponseUnsafe,
  mapToJsonApiObjectResponseUnsafe,
} from '../rxjs/map-to-json-api-object-response-unsafe';
import { ApiDefinition, ApiTypes } from './api-types';
import { PickSimpleFieldsetsDeep } from './pick-fieldsets-deep';
import { TypedQueryParameters } from './typed-query-parameters';
import { Fieldsets } from './fieldsets';
import { ResultItem } from './result-item.type';
import {
  CreateObjectType,
  TypeMeta,
  UpdateObjectType,
} from './api-type-meta.type';

export type UpdateAttributesItem<
  TTypes extends ApiDefinition<ApiTypes>['resourceTypes'],
  TTypeName extends keyof TTypes,
> = PickSimpleFieldsetsDeep<TTypes[TTypeName]>;

export type QueryParameterInput<
  TQueryParameter extends TypedQueryParameters<
    any,
    any,
    any,
    any,
    SortSpecsBuilder<any>
  >,
> = TQueryParameter;

export type QueryParameterResult<
  TQueryParameter extends TypedQueryParameters<any, any, any, any, any>,
> = TQueryParameter;

export interface SortedResult<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TFieldsets,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
> {
  data: Array<ResultItem<TApiDefinition, TFieldsets, TTypeName>>;
  queryParameter: QueryParameterResult<
    TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    >
  >;
}

export type JsonApiDocumentData = Pick<
  JsonApiDataObjectCollectionResponseBody,
  'meta' | 'jsonapi' | 'links'
>;

export class TypedJsonApiAdapter<
  TApiDefinition extends ApiDefinition<ApiTypes>,
  TTypeName extends keyof TApiDefinition['resourceTypes'],
  TTypeMeta extends TypeMeta<TTypeName>,
> {
  constructor(
    private readonly adapter: JsonApiAdapter2<
      TypedQueryParameters<
        TApiDefinition['resourceTypes'],
        Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
        TTypeName,
        any,
        SortSpecsBuilder<any>
      >
    >,
    private readonly mapToCreateParameters: (
      v: CreateObjectType<TTypeMeta, TTypeName>,
    ) => TTypeMeta[TTypeName]['create'],

    private readonly mapToUpdateParameters: (
      id: string,
      v: UpdateObjectType<TTypeMeta, TTypeName>,
    ) => { id: string } & TTypeMeta[TTypeName]['update'],
  ) {}

  query<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder> | string,
      TSortSpecs
    >,
  ): Observable<Array<ResultItem<TApiDefinition, TFieldsets, TTypeName>>> {
    return this.adapter
      .query(queryParameter)
      .pipe(mapToJsonApiCollectionResponseUnsafe());
  }

  queryRaw<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder> | FiqlQuery | string,
      TSortSpecs
    >,
  ): Observable<
    JsonApiErrorResponseBody | JsonApiDataObjectCollectionResponseBody
  > {
    return this.adapter.query(queryParameter);
  }

  queryObject<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder> | string,
      TSortSpecs
    >,
  ): Observable<
    SortedResult<TApiDefinition, TFieldsets, TTypeName> & JsonApiDocumentData
  > {
    return this.adapter.query(queryParameter).pipe(
      concatMap((document) =>
        mapDocumentToJsonApiCollectionResponseUnsafe<
          ResultItem<TApiDefinition, TFieldsets, TTypeName>,
          TApiDefinition,
          TTypeName
        >(document).pipe(
          map((result) => ({
            data: result,
            queryParameter,
            links: document.links,
            meta: document.meta,
            jsonapi: document.jsonapi,
          })),
        ),
      ),
    );
  }

  readRaw<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
  >(
    id: string,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    > = {},
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    return this.adapter.read(id, queryParameter);
  }

  read<TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>>(
    id: string,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    > = {},
  ): Observable<ResultItem<TApiDefinition, TFieldsets, TTypeName>> {
    return this.adapter
      .read(id, queryParameter)
      .pipe(mapToJsonApiObjectResponseUnsafe());
  }

  readMany<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
    TPropertyPathBuilder extends PropertyPathBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
    TSortSpecs extends SortSpecsBuilder<
      TApiDefinition['resourceTypes'][TTypeName]
    >,
  >(
    ids: ReadonlyArray<string>,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      TypedFiqlQuery<TPropertyPathBuilder>,
      TSortSpecs
    > = {},
    { splitIdsByCount = 30 } = {},
  ): Observable<Array<ResultItem<TApiDefinition, TFieldsets, TTypeName>>> {
    const splitQueryParamters = splitArrayByLength(ids, splitIdsByCount).map(
      (idsChunk) => {
        const idsSelector = {
          or: idsChunk.map((id) => ({
            equals: { selector: '$id' as const, args: id },
          })),
        } as const;

        const queryByIdsFilter: TypedQueryParameters<
          TApiDefinition['resourceTypes'],
          TFieldsets,
          TTypeName,
          TypedFiqlQuery<TPropertyPathBuilder>,
          TSortSpecs
        > = {
          ...queryParameter,
          filter: queryParameter.filter
            ? {
                and: [queryParameter.filter, idsSelector],
              }
            : idsSelector,
        };

        return queryByIdsFilter;
      },
    );

    return this.adapter
      .queryMany(splitQueryParamters)
      .pipe(
        concatMap((result) =>
          result.state === 'value'
            ? mapDocumentToJsonApiCollectionResponseUnsafe<
                ResultItem<TApiDefinition, TFieldsets, TTypeName>,
                TApiDefinition,
                TTypeName
              >(result.value).pipe(
                map((value) => ({ state: 'value', value } as const)),
              )
            : of(result),
        ),
      )
      .pipe(
        reduceChunked(
          (
            acc: Array<ResultItem<TApiDefinition, TFieldsets, TTypeName>>,
            next,
          ) => acc.concat(...next),
          [],
        ),
      );
  }

  readObject<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>>,
  >(
    id: string,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    > = {},
  ): Observable<
    {
      data: ResultItem<TApiDefinition, TFieldsets, TTypeName>;
    } & JsonApiDocumentData
  > {
    return this.adapter.read(id, queryParameter).pipe(
      concatMap((document) =>
        mapDocumentToJsonApiObjectResponseUnsafe<
          ResultItem<TApiDefinition, TFieldsets, TTypeName>,
          TApiDefinition,
          TTypeName
        >(document).pipe(
          map((result) => ({
            data: result,
            queryParameter,
            links: document.links,
            meta: document.meta,
            jsonapi: document.jsonapi,
          })),
        ),
      ),
    );
  }

  updateRaw<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>> = {},
  >(
    id: string,
    resourceObject: UpdateObjectType<TTypeMeta, TTypeName>,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    > = {},
  ): Observable<JsonApiErrorResponseBody | JsonApiDataObjectResponseBody> {
    try {
      return this.adapter.update(
        this.mapToUpdateParameters(id, resourceObject),
        queryParameter,
      );
    } catch (error) {
      return throwError(() => error);
    }
  }

  update<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>> = {},
  >(
    id: string,
    resourceObject: UpdateObjectType<TTypeMeta, TTypeName>,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    > = {},
  ): Observable<ResultItem<TApiDefinition, TFieldsets, TTypeName>> {
    try {
      return this.adapter
        .update(this.mapToUpdateParameters(id, resourceObject), queryParameter)
        .pipe(mapToJsonApiObjectResponseUnsafe());
    } catch (error) {
      return throwError(() => error);
    }
  }

  create<
    TFieldsets extends Partial<Fieldsets<TApiDefinition['resourceTypes']>> = {},
  >(
    resourceObject: CreateObjectType<TTypeMeta, TTypeName>,
    queryParameter: TypedQueryParameters<
      TApiDefinition['resourceTypes'],
      TFieldsets,
      TTypeName,
      | TypedFiqlQuery<
          PropertyPathBuilder<TApiDefinition['resourceTypes'][TTypeName]>
        >
      | string
    > = {},
  ): Observable<ResultItem<TApiDefinition, TFieldsets, TTypeName>> {
    try {
      return this.adapter
        .create(this.mapToCreateParameters(resourceObject), queryParameter)
        .pipe(mapToJsonApiObjectResponseUnsafe());
    } catch (error) {
      return throwError(() => error);
    }
  }

  delete(id: string): Observable<true> {
    try {
      return this.adapter
        .delete(id)
        .pipe(
          concatMap((v) =>
            v && isJsonApiErrorResponseBody(v)
              ? throwError(() => 'Failed to delete item.')
              : of(true as const),
          ),
        );
    } catch (error) {
      return throwError(() => error);
    }
  }
}
