import { findFirstInArray } from '@bbraun/shared/util-lang';
import { ErrorCodeLookup } from '../services/error-code-lookup.type';
import { ErrorObject } from '../services/error-object.type';

export function lookUpError(
  errorObject: ErrorObject,
  errorCodeLookups: ReadonlyArray<ErrorCodeLookup>,
) {
  const lookup = findFirstInArray(errorCodeLookups, (errorCodeLookup) => {
    const lookupFn = errorObject.code && errorCodeLookup.get(errorObject.code);
    return lookupFn && lookupFn(errorObject);
  });
  return lookup || undefined;
}
